export const displayValuesForSearchHistoryKeyFilters: {
  [key: string]: string;
} = {
  nameWithSurname: 'name surname',
  telno: 'telno',
  nameWithMiddleAndSurname: 'name middle surname',
  name: 'full name',
  username: 'username',
  url: 'url',
  photo: 'photo',
  email: 'email',
  curp: 'CURP',
  hashtag: 'keyword',
  nik: 'NIK',
  imei: 'IMEI',
  searchTypes: 'Search types',
  userTypes: 'Search by user',
};
