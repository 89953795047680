import { Injectable } from '@angular/core';
import { Profile } from 'datalayer/models/social/profile';
import {
  BaseService,
  EmptyCacheService,
  ModelStore,
  RequestOptions,
} from 'datalayer/services/base';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileApiService } from 'src/app/modules/data-layer/services/social/profile/profile-api-service';
import { ProfileDTO } from 'src/app/modules/data-layer/services/social/profile/profile-dto';
import {
  ActiveModule,
  FormatDateOfBirth,
  TypeOfDob,
} from 'src/app/shared/models/date-of-birth.enum';
import { map } from 'rxjs/operators';
import { DataSource } from 'datalayer/models/platform-models';
import { cloneDeep, uniq } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService<
  Profile,
  ProfileDTO,
  ProfileApiService,
  EmptyCacheService<Profile>
> {
  aboutData = new BehaviorSubject({});
  readonly regexDobNoYear = /^\d+\/\d+$/g;
  readonly regexDobOnlyYear = /^\d{4}$/g;

  private plainAboutData = {
    names: [],
    usernames: [],
    dateOfBirth: null,
    gender: null,
    addresses: [],
    maritalStatus: null,
    about: [],
    telnos: [],
    telnoProviders: [],
    placeOfBirth: [],
  };

  constructor(
    apiService: ProfileApiService,
    localCacheService: EmptyCacheService<Profile>
  ) {
    super(apiService, localCacheService);
  }

  clearAboutData(): void {
    this.plainAboutData = {
      names: [],
      usernames: [],
      dateOfBirth: null,
      gender: null,
      addresses: [],
      maritalStatus: null,
      about: [],
      telnos: [],
      telnoProviders: [],
      placeOfBirth: [],
    };

    this.aboutData.next(this.plainAboutData);
  }

  setAboutData(profiles: Profile[]): void {
    if (!Array.isArray(profiles)) {
      return;
    }

    profiles.forEach((profile) => {
      if (!profile) {
        return;
      }

      const {
        name,
        gender,
        username,
        dob,
        currentCity,
        nameLocation,
        location,
        relationshipStatus,
        about,
        telno,
        telecomProvider,
        placeOfBirth,
      } = profile;
      const address = currentCity?.title || nameLocation?.title || location;

      if (telno && !this.plainAboutData.telnos.includes(telno)) {
        this.plainAboutData.telnos.push(telno);
      }

      if (
        placeOfBirth &&
        !this.plainAboutData.placeOfBirth.includes(placeOfBirth)
      ) {
        this.plainAboutData.placeOfBirth.push(placeOfBirth);
      }

      if (
        telecomProvider &&
        this.plainAboutData.telnoProviders.findIndex(
          (provider) => provider?.value === telno
        ) < 0
      ) {
        this.plainAboutData.telnoProviders.push({
          value: telno,
          operator: telecomProvider,
          country: '',
        });
      }

      if (name && !this.plainAboutData.names.includes(name)) {
        this.plainAboutData.names.push(name);
      }

      if (relationshipStatus && !this.plainAboutData.maritalStatus) {
        this.plainAboutData.maritalStatus = relationshipStatus;
      }

      if (username && !this.plainAboutData.usernames.includes(username)) {
        this.plainAboutData.usernames.push(username);
      }

      if (about && !this.plainAboutData.about.includes(about)) {
        this.plainAboutData.about.push(about);
      }

      if (gender && !this.plainAboutData.gender) {
        this.plainAboutData.gender =
          gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase();
      }

      if (dob && !this.plainAboutData.dateOfBirth) {
        const dateFormat = this.formatDateOfBirth(dob, ActiveModule.ABOUT);
        if (dateFormat.dateOfBirth) {
          this.plainAboutData.dateOfBirth = new Date(dateFormat.dateOfBirth);
        }
      }

      if (address && !this.plainAboutData.addresses.includes(address)) {
        this.plainAboutData.addresses.push(address);
      }
    });

    this.aboutData.next(this.plainAboutData);
  }

  gettingRelationsComplete: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  formatDateOfBirth(birthday: string, module?: string): FormatDateOfBirth {
    let dateOfBirth: string = birthday;
    let typeOfBirthday: TypeOfDob;

    if (birthday.match(this.regexDobNoYear)) {
      dateOfBirth =
        module && module === ActiveModule.ABOUT ? undefined : birthday;
      typeOfBirthday = TypeOfDob.NO_YEAR;
    } else if (birthday.match(this.regexDobOnlyYear)) {
      dateOfBirth =
        module && module === ActiveModule.ABOUT ? undefined : birthday;
      typeOfBirthday = TypeOfDob.ONLY_YEAR;
    }

    return { dateOfBirth, typeOfBirthday };
  }
}
