<div
  class="gauge-area"
  angulartics2On="click"
  [angularticsAction]="matomo.actions.viewCreditsConcurrent"
  [angularticsCategory]="matomo.categories.navBar"
  [class.gauge-area-mobile]="isMobileResolution"
  [matMenuTriggerFor]="creditMenu"
  (click)="creditMenuListClicked()"
>
  <ngx-gauge
    class="credit-meter"
    [size]="gaugeOptions.size"
    [thick]="gaugeOptions.strokeWeight"
    [type]="gaugeOptions.gaugeType"
    [backgroundColor]="gaugeOptions.backgroundColor"
    [foregroundColor]="gaugeOptions.foregroundColor"
    [value]="gaugeOptions.gaugeValue"
    [thresholds]="gaugeOptions.thresholdConfig"
    [append]="gaugeOptions.gaugeAppendText"
    [thick]="gaugeOptions.thick"
    [duration]="800"
  >
  </ngx-gauge>
</div>

<mat-menu class="credit-menu" #creditMenu="matMenu">
  <ng-container
    *ngIf="
      (userBillingService.isTenantExpired() | async) === false;
      else creditsExpired
    "
  >
    <ng-container
      *ngIf="userBillingService.isDistributedBalance(); else enableConcurrent"
    >
      <div class="gauge-body">
        <span class="usage-label">{{ 'Remaining quota' | translate }}</span>
        <div class="vertical-divider"></div>
        <ng-template
          *ngTemplateOutlet="
            creditsStatusBarData;
            context: {
              availableCredits: availableConcurrentCredits
            }
          "
        ></ng-template>

        <div class="vertical-divider"></div>
        <ng-container
          *ngIf="concurrentLimitBalance$ | async as concurrentLimitBalance"
        >
          <ng-template
            *ngTemplateOutlet="
              creditsStatusBarData;
              context: {
                availableCredits: concurrentLimitBalance
              }
            "
          ></ng-template>
        </ng-container>

        <ng-container *ngIf="concurrentWarnings$ | async as concurrentWarnings">
          <app-credits-targets-cases-warning
            [concurrentWarnings]="concurrentWarnings"
          ></app-credits-targets-cases-warning>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</mat-menu>

<ng-template #creditsExpired>
  <span class="no-quota-message">{{ 'Credits are expired' | translate }}</span>
</ng-template>

<ng-template #enableConcurrent>
  <span class="no-quota-message">{{
    'Please enable concurrent targets.' | translate
  }}</span>
</ng-template>

<ng-template #creditsStatusBarData let-availableCredits="availableCredits">
  <app-credits-gauge-bars
    [availableCredits]="availableCredits"
  ></app-credits-gauge-bars>
</ng-template>
