import { Injectable } from '@angular/core';
import { StoredModalDataField } from 'src/app/modules/search-intel/models/search-intel.model';

@Injectable({ providedIn: 'root' })
export class StoredModalDataService<T = Record<string, any> | undefined> {
  private static readonly fieldsDict: Record<StoredModalDataField, string> = {
    [StoredModalDataField.SearchFilters]: 'storedSearchFilters',
    [StoredModalDataField.ArticleFilters]: 'storedArticleFilters',
    [StoredModalDataField.SearchResult]: 'storedSearchResult',
    [StoredModalDataField.SearchDashboard]: 'storedSearchDashboard',
    [StoredModalDataField.Queue]: 'storedQueue',
    [StoredModalDataField.SearchHistory]: 'searchHistory',
    [StoredModalDataField.SearchHistoryFilters]: 'searchHistoryFilters',
  };

  private storedSearchFilters: T;

  private storedArticleFilters: T;

  private storedSearchResult: T;

  private storedSearchDashboard: T;

  private storedQueue: T;

  private searchHistory: T;

  private searchHistoryFilters: T;

  private url: string | undefined;

  private scrollTop: number;

  public setScrollTop(scrollTop: number | undefined): void {
    this.scrollTop = scrollTop;
  }

  public getScrollTop(): number {
    return this.scrollTop;
  }

  public storeUrl(url: string): void {
    this.url = url;
  }

  public getUrl(): string {
    return this.url;
  }

  public store(field: StoredModalDataField, data: T): void {
    this[StoredModalDataService.fieldsDict[field]] = data;
  }

  public restore(field: StoredModalDataField): T {
    return this[StoredModalDataService.fieldsDict[field]];
  }

  public clear(field: StoredModalDataField): void {
    delete this[StoredModalDataService.fieldsDict[field]];
  }

  public clearAll(): void {
    Object.keys(StoredModalDataService.fieldsDict).forEach(
      (field: string): void => {
        delete this[StoredModalDataService.fieldsDict[field]];
      }
    );

    delete this.scrollTop;
    delete this.url;
  }
}
