<div class="wrapper" *ngIf="items?.length">
  <button
    class="menu-trigger assistant-font-family flex-vert-center"
    [matMenuTriggerFor]="menu"
  >
    <span class="capitalize fs-16">
      {{ activeSort?.title || 'Choose' | translate }}
    </span>
    <span *ngIf="activeSort?.direction">- {{ activeSort.direction }}</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      class="capitalize"
      mat-menu-item
      (click)="changeSort(sort)"
      *ngFor="let sort of items"
    >
      {{ sort.title | translate }}
    </button>
  </mat-menu>
</div>
