export enum EventType {
  /* Query generated from hosts which are clients to the backend infra
   */
  Query = 'Query',

  /* Query generated from Collector services
   */
  CollectorQuery = 'CollectorQuery',

  /* Query generated from Data Spider Services
   */
  SpiderQuery = 'SpiderQuery',

  /* Event generated from Collector services to announce Collected Data
   */
  CollectedData = 'CollectedData',

  /* Event generated from Data Platform-->Transformation/ Query services to announce Available Data
   */
  AvailableData = 'AvailableData',
}
