import { Entity } from '../base';

/*
 * Photo, Video, Audio, will be represented by this entity
 */
export class Media extends Entity {
  public url: string;
  public thumbnailUrl: string;
  public title: string;
  public description: string;
  public content: string;
  public sourceUrl: string;
  public countLikes?: number;
  public countShares?: number;
  public countComments?: number;
  public countViews?: number;
  public duration?: number;
  public muted?: boolean;
  public hashtags?: string[];
  public mentionType?: string;
  public publishedAt?: Date;
  public profileImage?: string;
  public profileUrl?: string;
  public albumId?: string;
  public subSource?: string;
}
