export const notificationTypeByPlatform: Record<string, string> = {
  person: 'searching_social_profiles',
  socialsearch: 'searching_social_profiles',
  dbsearch: 'searching_social_databases',
  twitter: 'searching_social_profiles',
  facebook: 'searching_social_profiles',
  instagram: 'searching_social_profiles',
  linkedin: 'searching_social_profiles',
  whatsapp: 'searching_instant_message_profiles',
  telegram: 'searching_instant_message_profiles',
  skype: 'searching_instant_message_profiles',
  truecaller: 'searching_instant_message_profiles',
  opensearch: 'searching_open_web',
  darkweb: 'searching_dark_web',
  inemx: 'searching_closed_databases',
  database: 'searching_closed_databases',
  microsoft: 'searching_social_profiles',
  trg: 'searching_trg_data_lake',
  analysis: 'analyzing_results',
};
