import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { skip, tap } from 'rxjs';
import { BillingService } from 'src/app/services/billing/billing.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { User } from 'src/app/services/user/user.model';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import {
  BalanceType,
  BillingActions,
  BillingActionType,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { CreditPoolTypes } from '../../models/credit-pools.model';

@Component({
  selector: 'app-credit-cost-button',
  templateUrl: './credit-cost-button.component.html',
  styleUrls: ['./credit-cost-button.component.scss'],
})
export class CreditCostButtonComponent
  extends BaseComponent
  implements OnChanges, OnInit
{
  @Input() action: string | string[];
  @Input() buttonText: string;
  @Input() active: boolean;
  @Output() clicked = new EventEmitter<void>();
  @Input() stylingClasses: string;
  @Input() countOfActionsCharged?: number = 1;
  @Input() creditGuardEnabled = false;
  @Input() creditPoolType: CreditPoolTypes = CreditPoolTypes.WEBINT;
  isActive = false;
  isDisabled = false;
  cost = 0;
  currentCredits = 0;

  constructor(
    private billingService: BillingService,
    private changeDetectorRef: ChangeDetectorRef,
    private ledgerService: LedgerService,
    private userBillingService: UserBillingService,
    private translationService: TranslationService
  ) {
    super();
  }

  private get billingPlan(): BillingPlan<BillingActions, BillingActionType> {
    return this.billingService.getBillingPlan().getValue();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.ledgerService
        .getCurrentUserLedgerItem()
        .pipe(
          tap((user: User) => {
            this.currentCredits =
              user.balanceType === BalanceType.DISTRIBUTED
                ? user.currentBalance[this.creditPoolType]
                : this.userBillingService.getUserCurrentBalance(user);
          })
        )
        .subscribe()
    );
    this.subscriptions.push(
      this.billingService
        .getBillingPlan()
        .pipe(
          skip(1),
          tap(() => this.updateStatus())
        )
        .subscribe()
    );
  }

  ngOnChanges(): void {
    if (this.billingPlan) {
      this.updateStatus();
    }
  }

  private updateStatus(): void {
    this.isActive = this.active && this.hasBillingPlanActions;
    this.isDisabled = !this.active || !this.hasBillingPlanActionsWithCost;

    if (this.isActive) {
      this.cost = this.totalOfActions * this.countOfActionsCharged;
    }

    this.changeDetectorRef.markForCheck();
  }

  private get hasBillingPlanActions(): boolean {
    if (typeof this.action === 'string') {
      return this.action in this.billingPlan;
    }
    if (Array.isArray(this.action)) {
      return this.action.every((action) => action in this.billingPlan);
    }

    return false;
  }

  private get hasBillingPlanActionsWithCost(): boolean {
    if (typeof this.action === 'string') {
      return this.billingPlan[this.action]?.cost >= 0;
    }
    if (Array.isArray(this.action)) {
      return this.action.every((action) => this.billingPlan[action]?.cost >= 0);
    }

    return false;
  }

  private get totalOfActions(): number {
    if (typeof this.action === 'string') {
      return this.billingPlan[this.action]?.cost ?? 0;
    }

    if (Array.isArray(this.action)) {
      return this.action.reduce(
        (acc, curr) => acc + this.billingPlan[curr]?.cost ?? 0,
        0
      );
    }

    return 0;
  }

  public onAction(eventValue: any) {
    if (this.currentCredits < this.cost && this.creditGuardEnabled) {
      this.showMessage(this.translationService.translate('Not enough credits'));
      return;
    }
    this.clicked.emit(eventValue);
  }
}
