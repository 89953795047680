import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { BillingService } from 'src/app/services/billing/billing.service';
import { Observable } from 'rxjs';
import {
  BillingActions,
  BillingActionType,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';

import { RecommendationResponseBody } from '../models/socket-responses.interface';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';

@Component({
  selector: 'app-request-call-log-modal',
  templateUrl: './request-call-log-modal.component.html',
  styleUrls: ['./request-call-log-modal.component.scss'],
})
export class RequestCallLogModalComponent {
  public billingPlan$: Observable<
    BillingPlan<BillingActions, BillingActionType>
  >;

  constructor(
    public modalRef: MatDialogRef<RequestCallLogModalComponent>,
    private billingService: BillingService,
    private userBillingService: UserBillingService,
    @Inject(MAT_DIALOG_DATA)
    public data: { telno: string; recommendations: RecommendationResponseBody }
  ) {}

  selectedDays: string[] = [];

  ngOnInit(): void {
    this.billingPlan$ = this.billingService.getBillingPlan().asObservable();
  }

  onImport(cost: number): void {
    if (cost === 0) {
      this.modalRef.close({ selectedDays: this.selectedDays });
    } else if (
      this.userBillingService.userHasEnoughCredits(
        this.selectedDays.map(() => BillingActions.IMPORT_HISTORICAL_LOCATIONS)
      )
    ) {
      this.modalRef.close({ selectedDays: this.selectedDays });
    }
  }

  onDatePickerSelect(days: string[]) {
    this.selectedDays = days;
  }
}
