import { Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from 'src/app/providers/app-config.service';

@Pipe({
  name: 'callLogPermitted',
})
export class CallLogPermittedPipe implements PipeTransform {
  constructor(private appConfig: AppConfigService) {}

  transform(telnoData: string[] | string): string[] | string {
    const enabledNewCallLogsRequest = this.appConfig.getConfigVariable(
      'enabledNewCallLogsRequest'
    );
    if (Array.isArray(telnoData)) {
      return telnoData.filter(
        (telno) =>
          this.appConfig.isCallLogPermitted(telno) && enabledNewCallLogsRequest
      );
    } else if (
      this.appConfig.isCallLogPermitted(telnoData) &&
      enabledNewCallLogsRequest
    ) {
      return telnoData;
    }

    return '';
  }
}
