<app-lightbox-component>
  <div class="advancedGeoLocationConfirmation">
    <app-header-content-footer-dialog
      title="{{ titles[data.type].h1 | translate }}"
      [showClose]="!showLoader"
      [showFooter]="!showLoader"
    >
      <ng-container ngProjectAs="detailsContent">
        <div class="row center-xs relative content">
          <ng-container *ngIf="!showLoader">
            <div class="col-12 content-subtitle">
              <span>
                {{ titles[data.type].h2 | translate }}
              </span>
              <span>
                {{
                  data.query && data.query.queryArgs
                    ? data.query.queryArgs.telno
                    : ''
                }}?
              </span>
              <span *ngIf="titles[data.type].extraContent">
                {{ titles[data.type].extraContent | translate }}
              </span>
            </div>
            <div class="countdown" *ngIf="counterValue > 0">
              <mat-progress-spinner
                class="countdown-spinner"
                mode="determinate"
                [value]="progress"
                strokeWidth="5"
                diameter="70"
              >
              </mat-progress-spinner>
              <div class="txt" *ngIf="counterValue > 0 && !showLoader">
                <span *ngIf="counterValue < 10">0</span>{{ counterValue }} s
              </div>
            </div>
            <div class="platform-wrp" *ngIf="data.type !== 'activeLocate'">
              <mat-checkbox
                class="example-margin"
                (change)="this.accurateLocation = $event.checked"
              >
                {{
                  'Always obtain accurate geolocation information when available'
                    | translate
                }}</mat-checkbox
              >
            </div>
          </ng-container>
          <div *ngIf="showLoader" class="loading-txt">
            <img src="assets/static/images/profiler/loader.svg" alt="loading" />
            <span>{{ 'Loading' | translate }}...</span>
          </div>
        </div>
      </ng-container>
      <ng-container ngProjectAs="footerContent">
        <ng-container *ngIf="!showLoader">
          <button
            *ngIf="data?.type === 'activeLocate'"
            class="cp-btn-primary"
            mat-button
            (click)="closeDialog()"
          >
            {{ 'Cancel' | translate }}
          </button>
          <app-credit-cost-button
            *ngIf="!accurateLocation"
            [action]="titles[data.type].action"
            [buttonText]="'Locate'"
            [active]="true"
            [stylingClasses]="'cp-btn-primary cp-search-btn active'"
            (clicked)="getData()"
          ></app-credit-cost-button>
          <app-credit-cost-button
            *ngIf="accurateLocation && data.type !== 'activeLocate'"
            [action]="titles[data.type].action"
            [buttonText]="'Save and Locate'"
            [active]="true"
            [stylingClasses]="'cp-btn-primary cp-search-btn active'"
            (clicked)="getData()"
          ></app-credit-cost-button>
        </ng-container>
      </ng-container>
    </app-header-content-footer-dialog>
  </div>
</app-lightbox-component>
