import { Themes } from '@shared/models/skins.model';
export const knowledgeBaseTooltips: { [key in Themes]: string } = {
  [Themes.GEOLOC]: 'Geoloc Knowledge Base',
  [Themes.WHITE]: 'Knowledge Base',
  [Themes.GEO]: 'Knowledge Base',
  [Themes.FRESHVALE]: 'Intellectus Knowledge Base',
  [Themes.UNLIMITED]: 'Intellectus Knowledge Base',
  [Themes.GENERSHIELD]: '',
  [Themes.CLARITY]: '',
};
