import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { FaProModule } from '@fe-platform/fa-pro';
import {
  SelectControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { InputSize } from '../input/input.model';

interface SelectOption {
  value: string | number;
  label: string;
}

@Component({
  selector: 'intellectus-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FaProModule, FormsModule, NgIf, NgFor],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent
  extends SelectControlValueAccessor
  implements OnChanges
{
  @Input() disabled = false;
  @Input() dropdownValue = '';
  @Input() errorMessage = '';
  @Input() hint = '';
  @Input() label = '';
  @Input() optionalLabel = '';
  @Input() options: SelectOption[] = [];
  @Input() placeholder = '';
  @Input() size = InputSize.STANDARD;

  @Input() formControlName = '';

  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  selectedValue = '';

  readonly selectSizeEnum = InputSize;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dropdownValue']?.currentValue) {
      this.selectedValue = changes['dropdownValue']?.currentValue;
    }
  }

  onValueChange(): void {
    this.valueChange.emit(this.selectedValue);
  }
}
