import { Injectable, TemplateRef } from '@angular/core';
import {
  DataSource,
  EntityRelationType,
  EntityType,
  getDataSourceName,
  ImageUrl,
} from 'datalayer/models/platform-models';
import { Post } from 'datalayer/models/social/post';
import { Profile } from 'datalayer/models/social/profile';
import { ModelStore, RequestOptions } from 'datalayer/services/base';
import { PlaceService } from 'datalayer/services/social/place/place.service';
import { uniq } from 'lodash-es';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PostService as SocialPostService } from 'src/app/modules/data-layer/services/social/post/post.service';
import { ProfileService as SocialProfilesService } from 'src/app/modules/data-layer/services/social/profile/profile.service';
import { IconMarker } from 'src/app/modules/mapV2/models/map.models';
import { InvestigationInfoWindowRef } from 'src/app/modules/visual-investigation/models/investigation.model';
import { countries as ContryCodes } from 'src/app/pipes/country.pipe';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { ProfilerIconMarker } from '../../../shared/models/profiler-icon-marker';
import { MarkerIcon } from '../models/marker-icons.enum';
import { Place } from 'datalayer/models';
import { PersonService } from 'datalayer/services/person/person/person.service';
import { getCheckinsFromPersons } from 'src/app/modules/profiler/helpers/get-checkins-from-persons';
import { v4 } from 'uuid';
import { Person } from 'datalayer/models';
import { CheckInMedia } from 'src/app/modules/search-intel/models/search-intel.model';
import { AboutTargetService } from '../../about-target/about-target.service';

@Injectable({
  providedIn: 'root',
})
export class ProfilerMapDataService {
  constructor(
    private aboutTargetService: AboutTargetService,
    private socialProfilesService: SocialProfilesService,
    private socialPostService: SocialPostService,
    private translationService: TranslationService,
    private placeService: PlaceService,
    private personService: PersonService
  ) {}

  getPostLocations(
    targetId,
    markerTemplate: TemplateRef<InvestigationInfoWindowRef>
  ): Observable<any> {
    const filters: RequestOptions = {
      filters: {
        source: [...Object.values(DataSource)],
        targetId,
        type: EntityType.Post,
      },
    };

    return this.socialPostService.getAll(filters).pipe(
      map((posts) => {
        const coordinatesArray = [];
        return Object.values(posts)
          .map((post: Post, index) => {
            post = transformSnakeToCamel(post);
            try {
              const coordinates = post.coordinates
                ? post.coordinates.coordinates.map((coords) => Number(coords))
                : post.place.boundingBox.coordinates[0][0];

              const coordinatesString: string = JSON.stringify(coordinates);

              if (
                coordinates &&
                !coordinatesArray.includes(coordinatesString)
              ) {
                coordinatesArray.push(coordinatesString);
                const [lng, lat] = coordinates;

                const markerInterface: InvestigationInfoWindowRef = {
                  post: post,
                  identity: post?.id,
                  createdAt: post?.publishedAt,
                  sameLocationMarkers: [],
                  sameLocationMarkersCount: 0,
                  groupId: post?.sourceEntity?.id,
                };

                return new ProfilerIconMarker({
                  id: `coordinates+${lat}+${lng}+${post.id}`,
                  lat,
                  lng,
                  date: new Date(post?.sourceEntity?.createdAt),
                  source: post.source,
                  iconUrl:
                    post.source === DataSource.Twitter
                      ? MarkerIcon.Twitter
                      : MarkerIcon.Default,
                  getPopupEmbeddedView: () =>
                    markerTemplate.createEmbeddedView(markerInterface),
                  isPopupWindowOpen: true,
                });
              }
            } catch (e) {
              return null;
            }
          })
          .filter((marker) => !!marker);
      })
    );
  }

  getSMLocations(
    targetId: string
  ): Observable<{ id: string; source: DataSource; addresses: string[] }[]> {
    const filters: RequestOptions = {
      filters: {
        source: [DataSource.Somedus],
        targetId,
        type: EntityType.Person,
      },
    };

    return this.personService.getAll(filters).pipe(
      map((persons) => {
        if (persons) {
          return Object.values(persons).map((person) => {
            const personLocations =
              this.aboutTargetService.parseOriginCountries({
                originCountries: person.originCountries,
              });
            const personAddresses = personLocations.addresses;
            return {
              id: person.personId,
              source: person.source,
              addresses: personAddresses,
            };
          });
        }
      })
    );
  }

  getProfileLocations(targetId: string): Observable<IconMarker[]> {
    const filters: RequestOptions = {
      filters: {
        source: [...Object.values(DataSource)],
        targetId,
        type: EntityType.Profile,
        relationType: [EntityRelationType.Plain],
      },
    };

    return this.socialProfilesService.getAll(filters).pipe(
      map((profiles) => {
        if (profiles) {
          return Object.values(profiles)
            .map((profile) => {
              const markers = [];

              const hometown = this.getHometown(profile);
              const currentCity = this.getCurrentCity(profile);
              const profileLocation = this.getProfileLocation(profile);

              markers.push(hometown);
              markers.push(currentCity);
              markers.push(profileLocation);

              return markers;
            })
            .reduce((acc, cur) => {
              acc = [...acc, ...cur];

              return acc;
            }, [])
            .filter((marker) => !!marker);
        }
      })
    );
  }

  private getProfileLocation(profile: Profile): IconMarker {
    try {
      const [lng, lat] = profile.nameLocation.location.coordinates;
      return new ProfilerIconMarker({
        id: `nameLocation${lat}+${lng}+${profile.profileId}`,
        lat,
        lng,
        popupHTML:
          `${getDataSourceName(profile.source)}` +
          ' ' +
          this.translationService.translate('profile location'),
        isPopupWindowOpen: true,
        source: profile.source,
        iconUrl: MarkerIcon.Default,
      });
    } catch (e) {
      return null;
    }
  }

  private getHometown(profile: Profile): IconMarker {
    try {
      const [lng, lat] = profile.hometown.location.coordinates;

      return new ProfilerIconMarker({
        id: `hometown${lat}+${lng}+${profile.profileId}`,
        lat,
        lng,
        popupHTML: 'Hometown',
        isPopupWindowOpen: true,
        source: profile.source,
        iconUrl: MarkerIcon.Facebook,
      });
    } catch (e) {
      return null;
    }
  }

  private getCurrentCity(profile: Profile): IconMarker {
    try {
      const [lng, lat] = profile.currentCity.location.coordinates;

      new ProfilerIconMarker({
        id: `currentCity${lat}+${lng}+${profile.profileId}`,
        lat,
        lng,
        popupHTML: 'Current city',
        isPopupWindowOpen: true,
        source: profile.source,
        iconUrl: MarkerIcon.Facebook,
      });
    } catch (e) {
      return null;
    }
  }

  public contryCodeToCountryName(targetAdresses: string[]): string[] {
    return targetAdresses.map((address: string) =>
      address.includes(',')
        ? uniq(
            address.split(',').map((addr) => ContryCodes[addr.trim()] || addr)
          ).join(', ')
        : address
    );
  }

  public getGoogleCheckinMarkers(
    target: TargetItem,
    markerTemplate: TemplateRef<any>
  ): Observable<ProfilerIconMarker[]> {
    const googleReqOptions: RequestOptions = {
      filters: {
        source: [DataSource.DBSearch],
        targetId: target.id,
        type: EntityType.Person,
      },
    };

    return this.personService.getAll(googleReqOptions).pipe(
      map((result: Record<string, Person>): Place[] =>
        getCheckinsFromPersons(
          Object.values(result).filter(
            (item: Person): boolean => !!item.checkIn
          ),
          target.id
        )
      ),
      map((places: Place[]): ProfilerIconMarker[] => {
        return places.map((place: Place): ProfilerIconMarker => {
          const post: Post = this.googlePlaceToPost(place);

          const [lng, lat] = post.coordinates.coordinates;
          const markerInterface: InvestigationInfoWindowRef = {
            post: post,
            identity: post?.id,
            createdAt: post?.publishedAt,
            sameLocationMarkers: [],
            sameLocationMarkersCount: 0,
            groupId: post?.sourceEntity?.id,
          };

          return new ProfilerIconMarker({
            id: `coordinates+${lat}+${lng}+${post.id}`,
            lat,
            lng,
            date: new Date(post?.publishedAt),
            source: post.source,
            iconUrl: MarkerIcon.Default,
            getPopupEmbeddedView: () =>
              markerTemplate.createEmbeddedView(markerInterface),
            isPopupWindowOpen: true,
          });
        });
      })
    );
  }

  public getFacebookCheckinMarkers(
    target: TargetItem,
    markerTemplate: TemplateRef<any>
  ): Observable<IconMarker[]> {
    const fbReqOptions: RequestOptions = {
      filters: {
        source: [DataSource.Facebook, DataSource.Instagram],
        targetId: target.id,
        type: EntityType.Place,
      },
    };

    const googleReqOptions: RequestOptions = {
      filters: {
        source: [DataSource.DBSearch],
        targetId: target.id,
        type: EntityType.Person,
      },
    };

    const googleCheckinsRequest: Observable<Place[]> = this.personService
      .getAll(googleReqOptions)
      .pipe(
        map((result: Record<string, Person>) =>
          Object.values(result).filter(
            (item: Person): boolean => !!item.checkIn
          )
        ),
        map((persons: Person[]): Place[] =>
          getCheckinsFromPersons(persons, target.id)
        )
      );

    const fbCheckinsRequest: Observable<ModelStore<Place>> =
      this.placeService.getAll(fbReqOptions);

    return forkJoin([googleCheckinsRequest, fbCheckinsRequest]).pipe(
      map(([googlePlaces, fbPlaces]: [Place[], ModelStore<Place>]): Place[] => {
        return [...googlePlaces, ...Object.values(fbPlaces)];
      }),
      map((checkins: Place[]) => {
        return checkins.map((checkin) => {
          if (!checkin.coordinates) {
            return null;
          }

          const markerPopupInterface = {
            target,
            checkin,
          };
          const { lat, lng } = checkin.coordinates;
          return new IconMarker({
            id: checkin.id,
            lat,
            lng,
            date: checkin.visitedAt,
            iconUrl: MarkerIcon.Default,
            getPopupEmbeddedView: () =>
              markerTemplate.createEmbeddedView(markerPopupInterface),
            isPopupWindowOpen: true,
          });
        });
      })
    );
  }

  private googlePlaceToPost(place: Place): Post {
    let url: string | undefined = undefined;
    if (place?.sourceEntity?.id) {
      const googleID: string = place.sourceEntity.id.split('@')[0];
      url = `https://www.google.com/maps/contrib/${googleID}`;
    }
    return new Post({
      coordinates: {
        type: 'Point',
        coordinates: [place.coordinates.lng, place.coordinates.lat],
      },
      source: place.source,
      title: place.name,
      body: place.description,
      targetId: place.targetId,
      sourceEntity: place.sourceEntity,
      publishedAt: place.visitedAt,
      type: EntityType.Post,
      id: v4(),
      url,
      images: place?.media?.map((img: CheckInMedia): ImageUrl => {
        return {
          imageUrl: img.image_url,
        };
      }),
    });
  }
}
