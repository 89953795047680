import { Type } from '@angular/core';
import { Pagination } from 'src/app/shared/classes/pagination.class';
import { environment } from '../../../../environments/environment';
import { PagedData } from '../../../shared/classes/paged-data.class';

export class BaseProxyService<T> {
  baseUrl: string;
  apiToken: string;
  resourcePath: string;
  classType: Type<T>;

  get url(): string {
    return this.baseUrl + this.resourcePath;
  }

  constructor() {
    this.baseUrl = environment.proxyAPIUri;
  }

  getResourcePath(path: string): string {
    return this.url + path;
  }

  protected getPageQueryString(page: Pagination) {
    return `?page=${page.currentPage}&limit=${page.perPage}`;
  }

  protected deserialize(data: PagedData<T>): PagedData<T> | Array<T> | T | any {
    // Handle pagination data
    if (data['data'] && data['pagination']) {
      const pagination = data.pagination;
      const objects = data.data;

      return new PagedData({
        data: objects,
        pagination: pagination,
      });
    }
    return data;
  }
}
