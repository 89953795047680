import { BaseApiService } from 'datalayer/services/base';
import { DataLayerModule } from 'datalayer/data-layer.module';
import { Place } from 'datalayer/models';
import { PlaceDTO } from 'src/app/modules/data-layer/services/social/place/place-dto';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: DataLayerModule,
})
export class PlaceApiService extends BaseApiService<Place, PlaceDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient, PlaceDTO);
  }

  public getModelName(): string {
    return Place.name;
  }
}
