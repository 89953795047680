<!-- Log Table Action icons -->
<div *ngIf="!isMobileResolution" class="log-actions flex-wrp">
  <div class="table-name" *ngIf="!(groupId || showTelnoHistory)">
    <p>Log</p>
  </div>

  <div class="back-wrp flex-wrp" *ngIf="groupId || showTelnoHistory">
    <mat-icon class="back-btn" (click)="refreshQueries()"
      >keyboard_arrow_left</mat-icon
    >
    <div class="history-detail">
      <span *ngIf="groupId" class="group-id"
        >{{ 'Group ID' | translate }}: {{ groupId }}</span
      >
      <span *ngIf="showTelnoHistory && !groupId" class="history-id"
        >{{ 'History' | translate }}: {{ historyViewTitle }}</span
      >
    </div>
  </div>

  <div class="actions-wrp flex-wrp">
    <div
      class="expanding-search action-icon"
      [class.geoloc-search]="isGeolocTheme"
      [class.keep-open]="searchText.length"
      matTooltip="{{
        'Search by' | translate
      }} Alias, MSISDN, IMSI, hashtag, IMEI"
      matTooltipPosition="below"
    >
      <input #searchInput type="search" />
    </div>

    <!-- Query Selection -->
    <button
      mat-icon-button
      class="query-selection action-icon"
      *ngIf="!(showTelnoHistory || groupId)"
      (click)="querySelected()"
      matTooltip="{{ 'Advanced Geo Query' | translate }}"
      matTooltipPosition="below"
    >
      <mat-icon [class.query-selected-icon]="multiSelect?.length"
        >gps_fixed</mat-icon
      >
    </button>

    <!-- Refersh Action Button -->
    <span
      class="refresh-icon clickable action-icon"
      *ngIf="!(showTelnoHistory || groupId)"
    >
      <mat-icon
        (click)="refreshQueries(true)"
        matTooltip="{{ 'Remove filters and restore queries' | translate }}"
        matTooltipPosition="below"
      >
        refresh
      </mat-icon>
    </span>

    <!-- DatePicker -->
    <div class="datetimepicker action-icon">
      <app-datetimepicker [dateTimeRange]="dateTimeRange" [maxDate]="todayDate">
      </app-datetimepicker>
    </div>

    <!-- Remove Filter Button -->
    <div class="remove-filter action-icon">
      <mat-icon
        mat-icon-button
        class="clickable"
        matTooltip="{{ 'Remove filters' | translate }}"
        matTooltipPosition="below"
        (click)="refreshQueries(true)"
      >
        <img
          [src]="basicImageSrc + 'remove_filters.svg'"
          alt=""
          height="20"
          width="20"
        />
      </mat-icon>
    </div>

    <!-- Export Action -->
    <button
      mat-icon-button
      class="action-icon export-action"
      [matMenuTriggerFor]="menu"
      matTooltip="{{ 'Export' | translate }}"
      matTooltipPosition="below"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let option of exportOptions"
        [value]="option.value"
        (click)="generateReport(option.label)"
      >
        <span>{{ option.label }}</span>
      </button>
    </mat-menu>
  </div>
</div>

<div *ngIf="!isMobileResolution" class="users-wrp">
  <div *ngIf="isAdmin || isSupport || isPower" class="flex-wrp user-select">
    <div class="global-select-label user-label">{{ 'User' | translate }}:</div>
    <mat-select
      class="global-select dashboard-select"
      placeholder="{{ 'Select' | translate }}"
      [(value)]="selectedUser"
      (selectionChange)="filtering()"
    >
      <mat-option [value]="null">---</mat-option>
      <mat-option *ngFor="let username of usernames" [value]="username">{{
        username
      }}</mat-option>
    </mat-select>
  </div>
</div>

<!-- User, history and batch queries titles-->
<div *ngIf="!isMobileResolution" class="row querylist-filter-titles"></div>

<!-- Headers/Labels -->
<div
  class="row query-list-labels-row"
  [class.querylist-mobile]="isMobileResolution"
>
  <div class="checkbox-wrp col-separator" *ngIf="!isMobileResolution">
    <mat-checkbox
      (change)="onSelectAll($event)"
      [checked]="selectAll"
    ></mat-checkbox>
  </div>
  <div
    *ngFor="let label of tableLabels"
    [ngStyle]="{ width: label.width }"
    class="{{ label.class }} col-separator"
  >
    <span class="col-label">{{ label.label | translate }}</span>
    <span
      class="sort-icon clickable"
      *ngIf="label.sort"
      (click)="sortQueries()"
    >
      <img
        alt=""
        src="assets/static/images/sort2.svg"
        height="12"
        width="12"
        matTooltip="{{ 'Sort' | translate }}"
      />
    </span>

    <!-- Filter for Actions Header-->
    <button
      *ngIf="label.filter"
      mat-icon-button
      [matMenuTriggerFor]="actionFilter"
    >
      <mat-icon>filter_alt</mat-icon>
    </button>
    <mat-menu #actionFilter="matMenu">
      <button mat-menu-item *ngFor="let filter of filters">
        <mat-checkbox
          [checked]="selectedFilter === filter.value"
          (change)="queryStatusFilterChanged($event, filter.value)"
        >
          <img
            alt=""
            src="{{ basicImageSrc }}{{ filter.icon }}"
            height="16"
            class="query-status-filter-icon"
          />{{ filter.name | translate }}
        </mat-checkbox>
      </button>
    </mat-menu>
  </div>
</div>

<mat-progress-bar
  *ngIf="showQueriesLoader"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>
<!-- Queries -->
<div
  [class.fill-available]="!isMobileResolution"
  appGuesHeight
  (afterGues)="doInitialQuery($event)"
  [class.mobile-queries-container]="isMobileResolution"
>
  <ng-container *ngFor="let query of paginatedQueries; let i = index">
    <app-query-list-item
      (emittedQuery)="onSelectedQuery($event)"
      (emittedMultiselectQuery)="onMultiselectedQuery($event)"
      (refreshMap)="onRefreshMap()"
      (paginatorEvent)="resetPaginator($event)"
      [query]="query"
      (emitQuerySelection)="querySelection = $event"
      (clearDateTimeRange)="dateTimeRange = []"
      [even]="i % 2 === 0"
      [selectAll]="selectAll"
    >
    </app-query-list-item>
  </ng-container>

  <div
    *ngIf="enableInvestigationModule && multiSelect?.length"
    class="col-12 group-actions"
  >
    <button class="assistant-font" (click)="redirectToInvestigation()">
      {{ 'Start Investigation' | translate }}
      <div class="beta-sign-label">BETA</div>
    </button>
  </div>
</div>

<!-- Paginator -->
<mat-paginator
  *ngIf="paginatedQueries.length"
  [class.geo-paginator]="!isMobileResolution"
  [length]="paginator.totalSize"
  [pageIndex]="paginator.currentPage"
  [hidePageSize]="isMobileResolution"
  [pageSize]="paginator.pageSize"
  (page)="onPaginatePageChange($event)"
  [class.paginator]="!isMobileResolution"
>
</mat-paginator>
<div
  *ngIf="showLogMessage && paginatedQueries.length === 0"
  class="no-queries-msg"
>
  {{ 'No queries' | translate }}.
</div>
