import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PurchaseNotificationResponse,
  PurchaseNotificationType,
} from 'datalayer/models/background-jobs/purchase-notifications';
import { uniqBy, last } from 'lodash-es';
import { ActiveToast } from 'ngx-toastr';
import { Observable, timer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';
import { PurchaseNotificationsStore } from './purchase-notifications.store';

@Component({
  selector: 'app-purchase-notification',
  templateUrl: './purchase-notification.component.html',
  styleUrls: ['./purchase-notification.component.scss'],
})
export class PurchaseNotificationComponent implements OnInit {
  public toasts$: Observable<PurchaseNotificationResponse[]>;
  public toast: ActiveToast<any>;
  id: string;
  inProgress: boolean;

  constructor(
    private purchaseNotificationsStore: PurchaseNotificationsStore,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.toasts$ = this.purchaseNotificationsStore.notifications$.pipe(
      tap((ids: { [id: string]: PurchaseNotificationResponse[] }) => {
        const purchaseNotification: PurchaseNotificationResponse[] = uniqBy(
          ids[this.id],
          'id'
        );
        timer(3000).subscribe({
          complete: () =>
            (this.inProgress = purchaseNotification.some(
              (notification) =>
                notification.notificationType ===
                PurchaseNotificationType.PurchaseCompleted
            )),
        });
      }),
      map((ids) => {
        return [last(ids[this.id])];
      })
    );
  }

  public removeNotification() {
    this.toast.toastRef.close();
  }

  navigateToClOverview(callLogRequestId: string): void {
    if (!callLogRequestId) {
      return;
    }
    this.router
      .navigate([
        `/${ApplicationMainPageUrls.CORE}/call-log-analysis`,
        callLogRequestId,
      ])
      .then(() => {
        window.location.reload();
      });
    this.removeNotification();
  }
}
