import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export abstract class BaseStore<T> {
  protected constructor(defaultValue: T = null) {
    this.obs = new BehaviorSubject<T>(defaultValue);

    if (defaultValue) {
      this.empty$.next(this.isEmpty());
    }

    this.list$ = this.obs.asObservable();
  }

  protected updating$ = new BehaviorSubject<boolean>(false);
  protected obs: BehaviorSubject<T>;
  public list$: Observable<T>;
  protected empty$ = new BehaviorSubject<boolean>(null);

  abstract isEmpty(): boolean;

  protected get value() {
    return this.obs.getValue();
  }

  /**
   *
   * @param newValue ClCallsStatistic
   */
  protected set value(newValue: T) {
    this.obs.next(newValue);
  }

  public isUpdating$() {
    return this.updating$.asObservable();
  }

  /**
   *
   * @param isUpdating boolean
   */
  public setUpdating(isUpdating: boolean) {
    this.updating$.next(isUpdating);
  }

  public hasData(): T {
    return this.value;
  }

  public isEmpty$() {
    return this.empty$.asObservable().pipe(distinctUntilChanged());
  }

  /**
   *
   * @param value ClCallsStatistic
   */
  public update(value: T) {
    this.value = value;
    this.setUpdating(false);

    // null means that response doesn't received yet
    this.empty$.next(!this.value ? null : this.isEmpty());
  }

  public getValue(): T {
    return this.value;
  }
}
