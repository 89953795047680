import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CaseCallLogs,
  ReportTemplate,
  TargetCallLogs,
  TargetSummary,
} from '@report-service-ts/data-models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportGenerationService {
  constructor(private httpClient: HttpClient) {}

  public generateTargetCallLogsReport(data: TargetCallLogs): Observable<Blob> {
    return this.request(ReportTemplate.targetCallLogs, data);
  }

  public generateCaseCallLogsReport(data: CaseCallLogs): Observable<Blob> {
    return this.request(ReportTemplate.caseCallLogs, data);
  }

  public generateTargetSummaryReport(data: TargetSummary): Observable<Blob> {
    return this.request(ReportTemplate.targetSummary, data);
  }

  private request(uri: ReportTemplate, data: any): Observable<Blob> {
    return this.httpClient.post(`${environment.reportV2APIUri}/${uri}`, data, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
    });
  }
}
