<div class="verify-wrp">
  <div class="field-group" *ngIf="currentPassword === ''">
    <label>{{ 'Current password' | translate }}</label>
    <mat-form-field class="input-round">
      <input
        class="assistant-font"
        matInput
        [type]="visibility ? 'text' : 'password'"
        [formControl]="currentUserPassword"
        name="current-password"
        autocomplete="current-password"
        required
      />

      <mat-icon
        class="icon eye-icon"
        (click)="this.visibility = !this.visibility"
      >
        {{
          this.visibility === true ? 'visibility_off' : 'visibility'
        }}</mat-icon
      >
    </mat-form-field>
  </div>

  <p class="assistant-font title">
    <ng-container *ngIf="!isOTPEnabled">{{
      'Activate 2-step verification' | translate
    }}</ng-container>
    <ng-container *ngIf="isOTPEnabled">{{
      'Deactivate 2-step verification' | translate
    }}</ng-container>
  </p>

  <div class="assistant-font">
    <p class="input-desc">
      {{
        "A second step after entering your password verifies it's you signing in."
          | translate
      }}
    </p>

    <div class="field-group" *ngIf="!QRscan">
      <label>{{ 'Current password' | translate }}</label>
      <mat-form-field class="input-round">
        <input
          class="assistant-font"
          matInput
          [type]="visibility ? 'text' : 'password'"
          [formControl]="passwordInput"
          required
          [title]="'Please fill out this field' | translate"
        />

        <mat-icon
          class="icon eye-icon"
          (click)="this.visibility = !this.visibility"
        >
          {{
            this.visibility === true ? 'visibility_off' : 'visibility'
          }}</mat-icon
        >
      </mat-form-field>
      <span class="error" *ngIf="pwdError">{{
        this.errorMsg | translate
      }}</span>
    </div>

    <div class="scan-wrp" *ngIf="!isOTPEnabled">
      <p>{{ 'Scan QR with your device to generate OTP codes' | translate }}</p>
      <div class="QR-img">
        <img [src]="QRImage" alt="QR Code" />
      </div>

      <div class="field-group">
        <label>{{ 'Enter the OTP code from your device' | translate }}</label>
        <mat-form-field class="input-round">
          <input
            class="assistant-font"
            matInput
            [type]="visibility ? 'text' : 'password'"
            [formControl]="otpInput"
            name="otp"
            autocomplete="new-password"
            required
            (keydown.enter)="activateTwoStepVerification()"
          />

          <mat-icon
            class="icon eye-icon"
            (click)="this.visibility = !this.visibility"
          >
            {{
              this.visibility === true ? 'visibility_off' : 'visibility'
            }}</mat-icon
          >
        </mat-form-field>
        <span class="error" *ngIf="!pwdError && this.errorMsg">{{
          this.errorMsg | translate
        }}</span>
      </div>
    </div>
  </div>

  <div class="action-wrp">
    <button
      class="cp-btn-primary"
      mat-button
      *ngIf="!isOTPEnabled"
      [disabled]="!otpInput.value"
      (click)="activateTwoStepVerification()"
    >
      {{ 'Activate' | translate }}
    </button>
    <button
      class="cp-btn-primary"
      mat-button
      *ngIf="isOTPEnabled"
      (click)="deactivateTwoStepVerification()"
    >
      {{ 'Deactivate' | translate }}
    </button>
  </div>
</div>
