<app-dialog class="export-container">
  <div header>{{ title | translate }}</div>
  <div body>
    <div class="row no-gutters height-100">
      <div class="col-md-9 col-sm-6 col-12 no-padding preview-section">
        <ng-content select="leftContent"></ng-content>
      </div>
      <div class="col-md-3 col-sm-6 col-12 no-padding settings-section">
        <div class="settings-container">
          <h1>{{ 'Select report type' | translate }}</h1>

          <ng-content select="rightContent"></ng-content>

          <section class="form-buttons">
            <button
              mat-button
              class="cp-btn-ghost"
              (click)="onCancel()"
              type="button"
            >
              {{ 'Cancel' | translate }}
            </button>
            <ng-content select="button"></ng-content>
          </section>
        </div>
      </div>
    </div>
  </div>
</app-dialog>
