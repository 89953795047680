import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { flatten } from 'lodash-es';
import moment from 'moment';
import { Observable, forkJoin } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { RequestLocationHistoryDialogComponent } from 'src/app/components/request-location-history-dialog/request-location-history-dialog.component';
import {
  SearchFilters,
  SearchIntelModel,
  SearchLabels,
} from 'src/app/modules/search-intel/models/search-intel.model';
import { InputTypeGuesserService } from 'src/app/modules/search-intel/services/input-type-guesser.service';
import { SearchIntelService } from 'src/app/modules/search-intel/services/search-intel.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import {
  DashboardService,
  DashboardView,
} from 'src/app/services/dashboard/dashboard.service';
import { ImageService } from 'src/app/services/image/image.service';
import { InstantMessagesStore } from 'src/app/services/instant-messages.store';
import { IntelSearchTrackerService } from 'src/app/services/intel-search-tracker/intel-search-tracker.service';
import { QueryService } from 'src/app/services/query/query.service';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { CostInfoActionDialogComponent } from 'src/app/shared/components/cost-info-action-dialog/cost-info-action-dialog.component';
import { IntelSearchImagePopupComponent } from 'src/app/shared/components/intel-search-image-popup/intel-search-image-popup.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { CostInfoActionDialogModel } from 'src/app/shared/models/cost-info-action-dialog.model';
import {
  IntervalMinutes,
  Query,
  QueryType,
} from 'src/app/shared/models/query-item.model';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';

@Component({
  selector: 'app-query-list-item',
  templateUrl: './query-list-item.component.html',
  styleUrls: ['./query-list-item.component.scss'],
})
export class QueryListItemComponent extends BaseComponent implements OnInit {
  KILOMETER = 1000;
  selectedTarget;
  isLogIntelTabActive = false;
  selectedQuery;
  isMobileResolution: boolean;
  queryHistory = [];
  skin;
  showTelnoHistory = false;
  multipinsClass = false;
  multipinsClick = false;
  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';
  showCheckbox: boolean;
  checkBoxEnabled = false;
  menuIsOpened: boolean;
  theme: string;
  isGeolocTheme = false;
  isWhiteTheme = false;
  basicImageSrc = 'assets/static/images/';
  @ViewChild(MatMenuTrigger) queryMenu: MatMenuTrigger;

  @Input() query: Query;
  @Input() even: boolean;
  @Input() selectAll: boolean;

  @Output() paginatorEvent = new EventEmitter<number>();
  @Output() emittedQuery = new EventEmitter<Query>();
  @Output() refreshMap = new EventEmitter<boolean>();
  @Output() emittedMultiselectQuery = new EventEmitter<{
    query: Query;
    checked: boolean;
  }>();
  @Output() emitQuerySelection = new EventEmitter<boolean>();
  @Output() clearDateTimeRange = new EventEmitter();

  // IntelSearch Query
  targetMsisdn: string;
  isAdmin = false;
  isSupport = false;
  isPower = false;
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private targetService: TargetService,
    private queryService: QueryService,
    private applicationStateService: ApplicationStateService,
    private translationService: TranslationService,
    private dashboardService: DashboardService,
    private router: Router,
    private appConfigService: AppConfigService,
    private searchIntelService: SearchIntelService,
    private inputTypeGuesserService: InputTypeGuesserService,
    private roleManagementService: RoleManagementService,
    private instantMessagesStore: InstantMessagesStore,
    private imageService: ImageService,
    private angulartics2: Angulartics2,
    private userBillingService: UserBillingService,
    private readonly intelSearchTrackerService: IntelSearchTrackerService
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.isMobileResolution =
      this.applicationStateService.getIsMobileResolution();
    this.skin = this.applicationStateService.getSkin();
    this.showCheckbox = false;
  }

  ngOnInit() {
    this.query = {
      ...this.query,
      ...(this.query?.createdAt && {
        createdAt: moment(this.query.createdAt).toDate(),
      }),
      ...(this.query?.locationReceivedAt && {
        locationReceivedAt: moment(this.query.locationReceivedAt).toDate(),
      }),
    };
    this.isAdmin = this.roleManagementService.userIsAdmin();
    this.isSupport = this.roleManagementService.userIsSupportUser();
    this.isPower = this.roleManagementService.userIsPowerUser();

    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
      this.basicImageSrc = `${this.basicImageSrc}geoloc_`;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    const selectedTargetSubscription =
      this.targetService.selectedTarget.subscribe((data: any) => {
        this.selectedTarget = data;
      });

    const selectedQuerySubscription =
      this.queryService.onQuerySelection.subscribe((data: any) => {
        this.selectedQuery = data;
      });

    const showTelnoHistorySubscription =
      this.queryService.showTelnoHistory.subscribe((data: any) => {
        this.showTelnoHistory = data;
      });

    const showIntelTabContentSubscription =
      this.dashboardService.showIntelTabContent.subscribe((flag) => {
        if (flag) {
          this.isLogIntelTabActive = true;
        }
      });

    this.subscriptions.push(
      this.queryService.gpsQuery.subscribe((query: Query) => {
        if (query && query.gps && query.id === this.query.id) {
          this.query.gps = query.gps;
          this.selectQuery(this.query);
        }
      })
    );

    this.subscriptions.push(
      this.queryService.nmrQuery.subscribe((query: Query) => {
        if (query && query.nmr && query.id === this.query.id) {
          this.query.nmr = query.nmr;
          this.selectQuery(this.query);
        }
      })
    );

    this.subscriptions.push(
      this.queryService.selectNewQuery.subscribe((query: Query) => {
        if (query && query.id === this.query.id) {
          this.selectQuery(this.query);
        }
      })
    );

    const locateQuerySubscription = this.queryService.locateQuery.subscribe(
      (query) => {
        if (query && query.provider && query.id === this.query.id) {
          this.resubmitQuery(query);
        }
      }
    );
    const intelSearchQuerySubscription = this.queryService.intelQuery.subscribe(
      (query) => {
        if (query && query.provider && query.id === this.query.id) {
          this.intelSearchQuery(query);
        }
      }
    );

    this.subscriptions.push(
      selectedTargetSubscription,
      selectedQuerySubscription,
      showTelnoHistorySubscription,
      showIntelTabContentSubscription,
      locateQuerySubscription,
      intelSearchQuerySubscription
    );
  }

  public selectQuery(query: Query) {
    this.dashboardService.componentsView.next(DashboardView.LOG);
    // if multiselect mode is enabled execute multiselect functionality only
    if (this.queryService.multiquerySelection.getValue().length) {
      this.multiselect(true, query);
      return;
    }
    this.geoTabView(query);
  }

  private geoTabView(query: Query) {
    this.queryService.onQuerySelection.next(query);
    this.dashboardService.showLogGeoTab.next(true);
    if (this.multipinsClick) {
      this.multipinsClick = false;
    } else {
      this.emittedQuery.emit(query);
    }
  }

  public resubmitQuery(query) {
    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.QUERY_LOCATION,
      ])
    ) {
      const queries = [];
      queries.push({
        telno: query.queryArgs.telno ? query.queryArgs.telno : '',
        imsi: query.queryArgs.imsi ? query.queryArgs.imsi : '',
      });
      const resubmitQuerySubscription = this.queryService
        .resubmitQuery(query.id)
        .subscribe(
          (queryResponse: { result: Query[] }) => {
            if (query?.queryArgs?.telno) {
              this.instantMessagesStore.fetchAllImPlatforms(
                query.queryArgs.telno,
                queryResponse.result[0].id,
                true
              );
            }
            this.showMessage(
              this.translationService.translate(
                'Query successfully resubmitted!'
              )
            );
            this.queryService.refreshLogQueries.next(true);
            this.refreshMap.emit(true);
            resubmitQuerySubscription.unsubscribe();
          },
          () => {
            this.showMessage(
              this.translationService.translate('Resubmit Cancelled')
            );
            resubmitQuerySubscription.unsubscribe();
          }
        );
    }
  }

  public openActiveLocateQueryDialog(query: Query) {
    const dialog = this.dialog.open(CostInfoActionDialogComponent, {
      data: <CostInfoActionDialogModel>{
        title: 'Active Locate',
        message: `${this.translationService.translate(
          'This action might leave anonymous traces on target’s phone.'
        )}`,
        action: BillingActions.QUERY_LOCATION,
        actionBtnLabel: 'Locate',
        footerText: '',
      },
      panelClass: 'renew-credit',
    });

    this.subscriptions.push(
      dialog.afterClosed().subscribe((action) => {
        if (action?.key === 'success') {
          this.angulartics2.eventTrack.next({
            action: matomoActions.activeLocateQuery,
            properties: {
              category: matomoCategories.gioQueryLog,
            },
          });
          this.activeLocateQuery(query);
        }
      })
    );
  }

  private activeLocateQuery(query: Query) {
    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.QUERY_LOCATION,
      ])
    ) {
      this.subscriptions.push(
        this.queryService.submitActiveLocateQuery(query.id).subscribe(
          () => {
            this.showMessage(
              this.translationService.translate(
                'Active locate query submitted successfully!'
              )
            );
          },
          () => {
            this.showMessage(
              this.translationService.translate('Resubmit Cancelled')
            );
          }
        )
      );
    }
  }

  showMessage(
    msg: string,
    okText = 'OK',
    preferredDuration?: number,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition
  ) {
    this.snackBar.open(msg, okText, {
      duration: preferredDuration || 3000,
      horizontalPosition: preferredHorizontalPosition,
      verticalPosition: preferredVerticalPosition,
      panelClass: ['custom-snackbar'],
    });
  }

  showNumberHistory(event, query) {
    this.refreshMap.emit(true);
    const telno = query.queryArgs.telno;
    const imsi = query.queryArgs.imsi;
    const target = query.alias || '';

    if (!this.isMobileResolution) {
      event.stopPropagation();
    }
    this.queryService.historyItem.next({ telno, imsi, target });
    this.queryService.showTelnoHistory.next(true);
    const paginatorCurrentPage = 0;
    this.paginatorEvent.emit(paginatorCurrentPage);
    this.queryService.getTelnoQueries(telno, imsi).subscribe();
    this.clearDateTimeRange.emit();
    this.queryService.multiquerySelection.next([]);
  }

  showBatchQuery(groupId) {
    this.queryService.getBatchQueries(groupId).subscribe();
    event.stopPropagation();
  }

  multiselect(checkboxIsChecked, query) {
    if (!this.queryService.multiquerySelection.getValue().length) {
      this.refreshMap.emit(true);
      this.queryService.onQuerySelection.next(null);
    }
    if (this.queryService.multiquerySelection.getValue().length < 2) {
      this.emitQuerySelection.emit(true);
    }

    if (checkboxIsChecked) {
      this.emitQuerySelection.emit(false);
      // add query to multipins
      this.multipinsClass = true;
      if (query.location) {
        this.emittedMultiselectQuery.emit({
          query,
          checked: checkboxIsChecked,
        });
      }
      this.checkBoxEnabled = true;
      this.queryService.addToMultiqueryGeolocationDetails(query);
    } else {
      // remove query from multipins
      this.checkBoxEnabled = false;
      this.multipinsClass = false;
      this.selectedQuery = null;
      if (query.location) {
        this.emittedMultiselectQuery.emit({
          query,
          checked: checkboxIsChecked,
        });
      }
      this.queryService.removeFromMultiqueryGeolocationDetails(query.id);
    }
  }

  getDataFromQuery(query: Query): {
    telnos: string[];
    imsis: string[];
    imeis: string[];
  } {
    const telno = query.queryArgs.telno ? [query.queryArgs.telno] : [];
    const imsi = query.queryArgs.imsi ? [query.queryArgs.imsi] : [];
    const imei = query.device
      ? query.device.imei
        ? [query.device.imei]
        : []
      : [];
    return {
      telnos: telno,
      imsis: imsi,
      imeis: imei,
    };
  }

  intelSearchQuery(query) {
    this.targetMsisdn = query.provider.telno;
    this.queryService.getLatestQueryById(query.id).subscribe((data) => {
      this.buildImageSearchData(data);
    });
  }

  getImageUrls(
    queryPhotoUrls: string[],
    names: string[]
  ): Observable<
    | {
        value: string;
        label: SearchLabels;
        photoUrl?: string;
        filename?: string;
      }[]
    | undefined
  > {
    const requests = [];
    const fileData = [];
    for (const url of queryPhotoUrls) {
      requests.push(this.imageService.getImageUrl(url, false));
    }

    return new Observable<
      | {
          value: string;
          label: SearchLabels;
          photoUrl?: string;
          filename?: string;
        }[]
      | undefined
    >((obs) => {
      forkJoin(requests).subscribe((urls: string[]) => {
        if (!urls) {
          obs.next(undefined);
          obs.complete();
          return;
        }

        for (const url of urls) {
          for (const name of names) {
            for (const queryPhotoUrl of queryPhotoUrls) {
              fileData.push({
                value: name,
                label: SearchLabels.IMAGE,
                photoUrl: url,
                filename: queryPhotoUrl,
              });
            }
          }
        }
        obs.next(fileData);
        obs.complete();
      });
    });
  }

  buildImageSearchData(query: Query) {
    let imageSearch = false;
    let fileData: {
      value: string;
      label: string;
      photoUrl?: string;
      filename?: string;
    }[] = [
      {
        value: query.provider.telno,
        label: SearchLabels.PHONE,
      },
    ];

    if (query.instantMessageProfiles && query.instantMessageProfiles.length) {
      const queryNames: string[] = flatten(
        query.instantMessageProfiles.map((profile) => profile.names)
      );
      const queryPhotoUrls: string[] = flatten(
        query.instantMessageProfiles.map((profile) => profile.photos)
      )
        .filter((photo: { source: string; image: string }) => !!photo.source)
        .map((photo: { source: string; image: string }) => photo.source);

      if (queryNames.length && queryPhotoUrls.length) {
        imageSearch = true;
        this.getImageUrls(queryPhotoUrls, queryNames).subscribe((res) => {
          if (!res) {
            return;
          }
          fileData = fileData.concat(res);
          this.openIntelDialog(this.targetMsisdn, {
            data: fileData,
            imageSearch,
          });
        });
        return;
      }
    }

    this.openIntelDialog(this.targetMsisdn, { data: fileData, imageSearch });
  }

  openImageSearchIntelDialog(data) {
    const dialogRef = this.dialog.open(IntelSearchImagePopupComponent, {
      data: data,
      width: '600px;',
    });

    dialogRef.afterClosed().subscribe(() => {
      dialogRef.close();
    });

    dialogRef.componentInstance.onSave.subscribe(
      (searchFilters: SearchFilters) => {
        this.intelQuery(this.imageSearchIntelQuery(data));
      }
    );
  }

  openIntelDialog(targetMsisdn, imageSearchData) {
    const intelDialog = this.dialog.open(CostInfoActionDialogComponent, {
      panelClass: 'renew-credit',
      data: <CostInfoActionDialogModel>{
        message: `${this.translationService.translate(
          'Uncover usernames, open web mentions, email and social media accounts that are associated with'
        )} ${targetMsisdn}. ${this.translationService.translate(
          'Credits will be deducted only if accurate information is found.'
        )}`,
        action: BillingActions.INTEL_SEARCH,
        title: 'Find profiles',
        actionBtnLabel: 'Find profiles',
        footerText: '',
      },
    });

    intelDialog.afterClosed().subscribe((action) => {
      if (action?.key === 'success') {
        if (!imageSearchData.imageSearch) {
          this.createIntelQuery();
        } else {
          this.searchIntelService.searchText.next(this.targetMsisdn);
          this.intelQuery(this.imageSearchIntelQuery(imageSearchData.data));
        }
      }
    });
  }

  imageSearchIntelQuery(data) {
    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.INTEL_SEARCH,
      ])
    ) {
      this.queryService.hideGeoSearch.next(false);
      return this.imageService.buildIntelSearchQueryArgs(data);
    }
  }

  createIntelQuery() {
    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.INTEL_SEARCH,
      ])
    ) {
      this.queryService.hideGeoSearch.next(false);
      const searchArgs = [];
      const input = this.inputTypeGuesserService.guessInputType(
        this.targetMsisdn
      );

      Object.keys(input).forEach((key) => {
        searchArgs.push({
          arg_type: input[key].key,
          arg_value: input[key].value,
        });
      });
      this.searchIntelService.searchText.next(this.targetMsisdn);
      this.intelQuery(searchArgs);
    }
  }

  intelQuery(searchArgs): void {
    const tempSearchID: string =
      this.intelSearchTrackerService.insertTempSearch(searchArgs);
    this.intelSearchTrackerService.minimize();
    this.searchIntelService
      .createSearchIntel(searchArgs)
      .pipe(
        tap((search: SearchIntelModel): void => {
          if (search) {
            this.searchIntelService.currentSearchIntel.next({
              search: search,
              noResultSearch: false,
            });
            this.intelSearchTrackerService.insertSearch(search.id, searchArgs);
          }
        }),
        finalize(() =>
          this.intelSearchTrackerService.removeSearch(tempSearchID)
        )
      )
      .subscribe();
  }

  mobileHistory(query) {
    const telno = query.queryArgs.telno;
    const imsi = query.queryArgs.imsi;
    const paginatorCurrentPage = 0;
    this.paginatorEvent.emit(paginatorCurrentPage);
    this.queryService.getTelnoQueries(telno, imsi).subscribe();
  }

  addHashtag(hashtag, query) {
    this.queryService.addHashtag(hashtag, query).subscribe(
      () => {
        this.showMessage(
          this.translationService.translate('Hashtag added successfully!')
        );
      },
      () => {
        this.showMessage(
          this.translationService.translate('Hashtag has not been added')
        );
      }
    );
  }

  addTelnoToIntelQueries(query) {
    const telno = query.queryArgs.telno;
    const intelQueries = JSON.parse(localStorage.getItem('intelQueries')) || [];
    if (!intelQueries.length) {
      try {
        localStorage.setItem('intelQueries', JSON.stringify([telno]));
      } catch (e) {
        console.log('Local Storage is full!!');
      }
    } else if (!intelQueries.includes(telno)) {
      intelQueries.push(telno);
      try {
        localStorage.setItem('intelQueries', JSON.stringify(intelQueries));
      } catch (e) {
        console.log('Local Storage is full!!');
      }
    }
    this.dashboardService.showIntelTab.next(true);
  }

  requestLocationHistory(event, query) {
    event.stopPropagation();
    const data = {
      target: query.queryArgs.telno || query.queryArgs.imsi,
    };
    this.dialog.open(RequestLocationHistoryDialogComponent, {
      width: '30vw',
      height: 'auto',
      data,
    });
  }

  quickLogonQuery(query) {
    const startAt = new Date();
    let endAt = new Date(startAt);
    endAt = new Date(endAt.setDate(startAt.getDate() + 7));
    if (this.appConfigService.getConfigVariable('forceLowFrequencySchedules')) {
      endAt = new Date();
      endAt.setHours(startAt.getHours() + 6);
    }
    const data = {
      queryType: QueryType.LOGON,
      frequency: IntervalMinutes.HIGH,
      startAt,
      endAt,
    };
    this.queryService.numbersToBeQueried.next([query.queryArgs]);
    const logonQuerySubscription = this.queryService
      .createLogonQuery(data)
      .subscribe(
        () => {
          this.showMessage(
            this.translationService.translate('Query created successfully!')
          );
        },
        () => {
          this.showMessage(
            this.translationService.translate('Query has not been created')
          );
        }
      );
    this.subscriptions.push(logonQuerySubscription);
  }
}
