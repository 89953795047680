import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatLegacyChip as MatChip,
  MatLegacyChipList as MatChipList,
  MatLegacyChipSelectionChange as MatChipSelectionChange,
} from '@angular/material/legacy-chips';
import { FilterStatus } from '../../models/intel-results-view.model';
import { SelectChipOption } from './multi-select-chip.model';
@Component({
  selector: 'app-multi-select-chip',
  templateUrl: './multi-select-chip.component.html',
  styleUrls: ['./multi-select-chip.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiSelectChipComponent,
      multi: true,
    },
  ],
})
export class MultiSelectChipComponent
  implements AfterViewInit, OnChanges, ControlValueAccessor
{
  @ViewChild(MatChipList)
  chipList!: MatChipList;

  @Input() options: SelectChipOption[] = [];
  @Output() emitSelectedValues: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Input() value: string[] = [];

  onChange!: (value: string[]) => void;
  onTouch: any;
  filterStatuses = FilterStatus;
  disabled = false;

  ngOnChanges(): void {
    if (this.chipList) {
      this.selectChips(this.value);
    }
  }

  writeValue(value: string[]): void {
    // When form value set when chips list initialized
    if (this.chipList && value) {
      this.selectChips(value);
    } else if (value) {
      // When chips not initialized
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngAfterViewInit() {
    this.selectChips(this.value);
  }

  propagateChange(value: string[]): void {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  selectChips(value: string[]): void {
    this.chipList.chips.forEach((c) => {
      if (value.includes(c.value)) {
        c.select();
      } else {
        c.deselect();
      }
    });
  }

  toggleSelection(chip: MatChip): void {
    if (!this.disabled) {
      chip.toggleSelected();
    }
  }

  onSelectionChange(chip: MatChipSelectionChange): void {
    if (chip.source.selected) {
      if (this.value.includes(chip.source.value)) {
        return;
      }
      this.value = [...this.value, chip.source.value];
    } else {
      this.value = this.value?.filter((o) => o !== chip.source.value) || [];
    }
    this.propagateChange(this.value);
    this.emitSelectedValues.emit(this.value);
  }
}
