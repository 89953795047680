import { Component, Input } from '@angular/core';
import { ConcurrentPoolFeaturesLabelsMap } from 'src/app/shared/models/credit-pools.model';
import { AvailableConcurrentCredits } from '../gauge-types';
@Component({
  selector: 'app-credits-gauge-bars',
  templateUrl: './credits-gauge-bars.component.html',
  styleUrls: ['./credits-gauge-bars.component.scss'],
})
export class CreditsGaugeBarsComponent {
  creditLabels = ConcurrentPoolFeaturesLabelsMap;
  @Input() availableCredits: AvailableConcurrentCredits[] = [];
}
