import { Injectable } from '@angular/core';
import {
  defaultCountries,
  mexicoCoords,
} from 'src/app/components/map/map.options';
import {
  Button,
  ButtonState,
  Circle,
  ControlPosition,
  Point,
  Polygon,
  PopupPosition,
} from 'src/app/modules/mapV2/models/map.models';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Query } from 'src/app/shared/models/query-item.model';

@Injectable({
  providedIn: 'root',
})
export class MapHelperService {
  DEFAULT_ZOOM_LEVEL = 5;
  DEFAULT_HEATMAP_TO_CLUSTER_ZOOM_LEVEL = 3;
  private accuracyCirclesCache: { [key: string]: Circle[] } = {};

  constructor(private translationService: TranslationService) {}

  getTrafficButton() {
    return this.createButton(
      'traffic',
      'traffic_disable.svg',
      'traffic_enable.svg',
      'Disable traffic layer',
      'Enable traffic layer',
      ButtonState.Deactivated,
      true,
      ControlPosition.TOP_LEFT
    );
  }

  getProfilerResizeButton() {
    return this.createButton(
      'maximize',
      'profiler/fullscreen_disable.svg',
      'profiler/fullscreen_enable.svg',
      this.translationService.translate('Minimize map'),
      this.translationService.translate('Maximize map'),
      ButtonState.Deactivated,
      true,
      ControlPosition.RIGHT_BOTTOM
    );
  }

  getTelegramNearByLocationsButton() {
    return this.createButton(
      'telegram-nearby-locations',
      'telegram_disable.svg',
      'telegram_enable.svg',
      'Hide nearby Telegram users',
      'Show nearby Telegram users',
      ButtonState.Deactivated,
      true,
      ControlPosition.TOP_RIGHT
    );
  }

  createButton(
    id: string,
    activatedIconUrl: string,
    deactivatedIconUrl: string,
    activatedTitle: string,
    deactivatedTitle: string,
    state: ButtonState,
    toggleable: boolean,
    position: ControlPosition,
    callback?: any
  ) {
    const button: Button = {
      iconUrl: { Activated: activatedIconUrl, Deactivated: deactivatedIconUrl },
      altText: {
        Activated: this.translationService.translate(activatedTitle),
        Deactivated: this.translationService.translate(deactivatedTitle),
      },
      state,
      toggleable,
      id,
      position,
      callback,
    };
    return button;
  }

  getDefaultZoomLevel() {
    return this.DEFAULT_ZOOM_LEVEL;
  }

  getDefaultCenterLocation() {
    let center;
    const mapCountry = localStorage.getItem('mapCoordinates')
      ? localStorage.getItem('mapCoordinates')
      : 'none';
    if (!mapCountry || mapCountry === 'none') {
      center = { lat: mexicoCoords.lat, lng: mexicoCoords.lon };
    } else {
      for (const country of defaultCountries) {
        if (country.name === mapCountry) {
          center = { lat: country.lat, lng: country.lon };
        }
      }
    }
    return center;
  }

  showQueryAccuracyCircles(
    queryData: Query,
    extendMapBounds?: boolean
  ): Circle[] {
    const defaultCenter: { lat: number; lng: number } =
      this.getDefaultCenterLocation();
    const center = {
      lat: queryData.location
        ? queryData.location.coordinates[1]
        : defaultCenter.lat,
      lng: queryData.location
        ? queryData.location.coordinates[0]
        : defaultCenter.lng,
    };
    const accuracyCirclesHash: string = this.calculateAccuracyCirclesHash(
      center.lat,
      center.lng,
      queryData.accuracyMeters
    );
    if (!this.accuracyCirclesCache[accuracyCirclesHash]) {
      this.accuracyCirclesCache[accuracyCirclesHash] = [
        {
          accuracyRadius: 1,
          fillColor: '#0C97FF',
          fillOpacity: 0.2,
          strokeColor: 'white',
          type: 'accurate-area',
        },
        {
          accuracyRadius: 1.5,
          fillColor: '#0C97FF',
          fillOpacity: 0.14,
          strokeColor: 'white',
          type: 'broad-radius',
        },
      ].reduce((accumulator, c) => {
        const circle: Circle = new Circle({
          center: center,
          radiusMeters: queryData.accuracyMeters * c.accuracyRadius,
          extendMapBounds: false,
          fillOpacity: c.fillOpacity,
          fillColor: c.fillColor,
          strokeColor: c.strokeColor,
          strokeWeight: 4,
          strokeOpacity: 1,
          id: `${c.type}-${queryData.id}`,
        });
        circle.extendMapBounds = extendMapBounds !== false;
        accumulator.push(circle);
        return accumulator;
      }, []);
    }
    return this.accuracyCirclesCache[accuracyCirclesHash];
  }

  private calculateAccuracyCirclesHash(
    lat: number,
    lng: number,
    accuracyMeters: number
  ): string {
    return `${lat}|${lng}|${accuracyMeters}`;
  }

  showQueryHexagonForLAC(queryData: Query): Polygon {
    const defaultCenter: { lat: number; lng: number } =
      this.getDefaultCenterLocation();

    const position: Point = {
      lat: queryData.location
        ? queryData.location.coordinates[1]
        : defaultCenter.lat,
      lng: queryData.location
        ? queryData.location.coordinates[0]
        : defaultCenter.lng,
    };

    return new Polygon({
      points: this.calculateHexagonPoints(queryData.accuracyMeters, position),
      extendMapBounds: true,
      strokeColor: '#D2B180',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: '#c6e300',
      fillOpacity: 0.3,
      popupHTML: 'LAC',
      isPopupWindowOpen: true,
      popupPosition: PopupPosition.BOTTOM,
      id: queryData.id,
    });
  }

  showQueryHexagonForIP(queryData: Query, infoWindowTemplateRef): Polygon {
    const defaultCenter: { lat: number; lng: number } =
      this.getDefaultCenterLocation();

    const position: Point = {
      lat: queryData.location
        ? queryData.location.coordinates[1]
        : defaultCenter.lat,
      lng: queryData.location
        ? queryData.location.coordinates[0]
        : defaultCenter.lng,
    };

    return new Polygon({
      points: this.calculateHexagonPoints(queryData.accuracyMeters, position),
      extendMapBounds: true,
      strokeColor: '#396aff',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: '#6465df',
      fillOpacity: 0.3,
      getPopupEmbeddedView: () =>
        infoWindowTemplateRef.createEmbeddedView({
          data: queryData,
        }),
      isPopupWindowOpen: true,
      popupPosition: PopupPosition.BOTTOM,
      id: queryData.id,
    });
  }

  // TODO INT-3686 (this needs to be copied to map module v2);
  private calculateHexagonPoints(radiusMeters: number, center: Point): Point[] {
    const coordinates = [];
    for (let angle = -120; angle < 270; angle += 60) {
      coordinates.push(
        google.maps.geometry.spherical.computeOffset(
          new google.maps.LatLng(center),
          radiusMeters,
          angle
        )
      );
    }

    return coordinates.map((latLng) => {
      return { lat: latLng.lat(), lng: latLng.lng() };
    });
  }

  // TODO INT-3686 (this needs to be copied to map module v2);
  public generateFakeGeoPoint(
    lat: number,
    long: number,
    distance: number,
    bearingDegrees: number
  ): [number, number] {
    const pointA = new google.maps.LatLng(lat, long);
    const pointB = google.maps.geometry.spherical.computeOffset(
      pointA,
      distance,
      bearingDegrees
    );
    return [pointB.lat(), pointB.lng()];
  }
}
