export enum DataProvider {
  TRG = 'Trg',
  TwitterAPI = 'TwApi',
  SocialNet = 'SocialNet',
  SocialLinks = 'SocialLinks',
  Pipl = 'Pipl',
  Searx = 'Searx',
  SixGill = 'SixGill',
  Gita = 'Gita',
}
