export enum CaseDashboardSectionsLabels {
  OVERVIEW = 'Overview',
  LINK_ANALYSIS = 'Link analysis',
  Discovery = 'Discovery',
  CALL_LOG = 'Call log analysis',
  LINK_ANALYSIS_V2 = 'Link analysis v2',
  GROUP_ANALYSER = 'Group Analysis',
}

export enum CaseDashboardSectionsRoutes {
  OVERVIEW = 'overview',
  LINK_ANALYSIS = 'link-analysis',
  Discovery = 'discovery',
  CALL_LOG = 'case-call-log-analysis',
  LINK_ANALYSIS_V2 = 'link-analysis-v2',
  GROUP_ANALYSER = 'group-analysis',
}

export enum CaseDashboardSectionsValues {
  OVERVIEW = 'Overview',
  LINK_ANALYSIS = 'LinkAnalysis',
  Discovery = 'Discovery',
  CALL_LOG = 'CallLogAnalysis',
  LINK_ANALYSIS_V2 = 'LinkAnalysisV2',
  GROUP_ANALYSER = 'GroupAnalyser',
}
