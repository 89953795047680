<div id="newImageIntelQueryPopup">
  <h2 mat-dialog-title class="title">{{ 'New image query' | translate }}</h2>
  <mat-dialog-content>
    <div class="search-query" *ngFor="let item of data">
      <p class="content">
        <ng-container *ngIf="item.label === 'Image'">
          <span class="field-value">{{ item.photoUrl }}</span>
          <span class="field-label">{{ item.label | translate }}</span>
          <span class="field-value">{{ item.value }}</span>
          <span class="field-label">{{ 'Full Name' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="item.label !== 'Image'">
          <span class="field-value">{{ item.value }}</span>
          <span class="field-label">{{ item.label | translate }}</span>
        </ng-container>
      </p>
    </div>
    <div class="query-filters">
      <app-intel-filters
        (emitFilters)="importFilters = $event"
        (click)="$event.stopPropagation()"
      ></app-intel-filters>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button class="close" mat-button [mat-dialog-close]="false">
      <img src="/assets/static/images/search-intel/close.svg" />
    </button>
    <app-credit-cost-button
      [action]="'intelSearch'"
      [buttonText]="'Search'"
      [active]="data.length !== 0"
      [stylingClasses]="'cp-btn-primary cp-search-btn active'"
      (clicked)="onSubmit()"
    ></app-credit-cost-button>
  </mat-dialog-actions>
</div>
