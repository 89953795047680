import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'intellectus-base-tooltip',
  templateUrl: './base-tooltip.component.html',
  styleUrls: ['./base-tooltip.component.scss'],
})
export class BaseTooltipComponent {
  @Input() contentTemplate!: TemplateRef<object>;
  @Input() contentData!: object;
}
