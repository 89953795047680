import { Component } from '@angular/core';
import { LimitType } from 'src/app/shared/models/billing-action.model';
import { CreditUsage } from '../../models/credit-details.model';
import { CreditDetailsService } from '../../services/credit-details.service';

@Component({
  selector: 'app-credit-bar-tooltip',
  templateUrl: './credit-bar-tooltip.component.html',
  styleUrls: ['./credit-bar-tooltip.component.scss'],
})
export class CreditBarTooltipComponent {
  public readonly LimitTypeUsageRestricted = LimitType.MAXIMUM;
  constructor(public creditDetailsService: CreditDetailsService) {}
}

export interface CreditBarTooltip {
  title: string;
  positionX: string;
  positionY: string;
  creditsUsage: CreditUsage[];
}
