import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';
import { isValidPhoneFormValidator } from '@app/validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/base/base.component';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { User } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { OTPDialogState } from '../user-settings.models';
import { OTPChannels } from './../user-settings.models';
import { ActivateOtpModalComponent } from './activate-otp-modal/activate-otp-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-otp-section',
  templateUrl: './otp-section.component.html',
  styleUrls: ['./otp-section.component.scss'],
})
export class OtpSectionComponent extends BaseComponent implements OnInit {
  protected readonly emailPattern = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$';

  showPhoneInput = false;
  showEmailInput = false;
  otpChannel: OTPChannels;
  phoneControl = new UntypedFormControl('', [
    Validators.required,
    isValidPhoneFormValidator(),
  ]);
  emailControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(this.emailPattern),
  ]);

  constructor(
    public userService: UserService,
    protected dialog: MatDialog,
    protected translationService: TranslationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe((user: User) => {
        if (user) {
          this.otpChannel = user.otpChannel;
          if (
            user.otpEnabled &&
            ((this.otpChannel === OTPChannels.OtpPhone && !user.otpPhone) ||
              (this.otpChannel === OTPChannels.Email && !user.email))
          ) {
            this.handleOtpRadioChange(user, OTPChannels.None);
          }
        }
      });
  }

  handleOTPChange($event: MatSlideToggle): void {
    const state: OTPDialogState = OTPDialogState.PasswordScreen;
    const user = this.userService.currentUser$.value;

    this.showPhoneInput = false;

    const otpChangeDialog = this.dialog.open(ActivateOtpModalComponent, {
      data: { state, user, isActivated: $event.checked },
      width: '460px',
      autoFocus: true,
    });

    otpChangeDialog.afterClosed().subscribe((result: boolean | undefined) => {
      this.userService.getCurrentUser().subscribe();
      if (result !== undefined) {
        $event.checked = result;
      } else {
        $event.checked = !$event.checked;
        if (!$event.checked) {
          this.handleOtpRadioChange(user, OTPChannels.None);
        }
      }
    });
  }

  changeOTPChannel($otpChannelEvent: MatRadioChange): void {
    const user = this.userService.currentUser$.value;
    const currentChannelValue = user.otpChannel;
    const otpChannel = $otpChannelEvent.value;

    if (!otpChannel) {
      return;
    }

    this.showPhoneInput = false;

    const otpChangeDialog = this.dialog.open(ActivateOtpModalComponent, {
      data: {
        state: OTPDialogState.PasswordScreenChangeMethod,
        user,
        channel: otpChannel,
        isActivated: true,
        isPasswordChange: true,
      },
      width: '460px',
      autoFocus: true,
    });

    otpChangeDialog.afterClosed().subscribe((result: boolean | undefined) => {
      this.userService.getCurrentUser().subscribe();
      if (result === undefined) {
        this.handleOtpRadioChange(user, currentChannelValue);
        this.otpChannel = currentChannelValue;
      }
    });
  }

  cancelEdit(): void {
    this.showPhoneInput = false;
    this.phoneControl.reset();
    this.phoneControl.setErrors(null);
    this.phoneControl.updateValueAndValidity();

    this.showEmailInput = false;
    this.emailControl.reset();
    this.emailControl.setErrors(null);
    this.emailControl.updateValueAndValidity();
  }

  handleOtpRadioChange(user: User, channel: OTPChannels): void {
    let otpParams: {};
    if (!channel) {
      otpParams = { otpEnabled: false };
    } else {
      otpParams = { otpEnabled: true, otpChannel: channel };
    }

    this.userService.editUser(user, otpParams, true).subscribe({
      next: (result: User) => {
        /* eslint-disable-next-line no-empty-function */
      },
      error: (error) =>
        this.showMessage(
          this.translationService.translate(error ?? 'Could not save')
        ),
    });
  }

  submitForm(): void {
    if (this.phoneControl.invalid && this.emailControl.invalid) {
      return;
    }

    const userPayload: Partial<User> = {};
    if (this.phoneControl.valid) {
      userPayload.otpPhone = this.phoneControl.value;
    }
    if (this.emailControl.valid) {
      userPayload.email = this.emailControl.value;
    }

    this.userService
      .editUser(this.userService.currentUser$.value, userPayload, true)
      .subscribe(
        (user: User) => {
          this.showMessage(
            `${this.translationService.translate('User edited successfully!')}`
          );
          this.cancelEdit();
        },
        (error) => {
          this.showMessage(
            this.translationService.translate(
              error.message ? error.message : error
            )
          );
          this.cancelEdit();
        }
      );
  }
}
