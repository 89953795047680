import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-multi-stage-loader',
  templateUrl: './multi-stage-loader.component.html',
  styleUrls: ['./multi-stage-loader.component.scss'],
})
export class MultiStageLoaderComponent {
  @Input() title: string;
  @Input() query: string;
  @Input() progressBarValue = 0;
  @Input() resultsCount = 0;
  @Input() progressBarElements: { name: string; value: boolean }[] = [];
  @Input() showResultsCount = false;
}
