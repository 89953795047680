import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { LocalStorageService } from '../storage/local-storage.service';
import { environment } from 'environment/environment';
import { catchError, delay, retry, retryWhen, switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class PlatformWsService {
  private baseURL: string = environment.platformWSManagerUri;
  private latestToken: string;
  connection$: WebSocketSubject<any> | null = null;

  constructor(private localStorageService: LocalStorageService) {}

  getConnection(): Observable<any> {
    if (!this.connection$ || this.connection$.closed || this.hasNewToken) {
      this.latestToken = this.localStorageService.getAuthToken();
      this.connection$ = webSocket({
        url: `${this.baseURL}`,
        protocol: ['access_token', this.latestToken],
      });
    }
    return this.connection$.pipe(
      catchError((_) => {
        console.warn('WS connection closed abnormally, reconnecting');
        return timer(5000).pipe(switchMap(() => this.getConnection()));
      })
    );
  }

  private get hasNewToken() {
    return this.latestToken !== this.localStorageService.getAuthToken();
  }

  disconnect() {
    this.connection$?.complete();
    this.connection$ = null;
  }
}
