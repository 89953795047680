<div *ngIf="!isMobileResolution" class="row justify-content-end">
  <mat-icon
    class="tasks-close-btn clickable"
    matTooltip="{{ 'Close' | translate }}"
    (click)="onClose()"
    >close</mat-icon
  >
</div>

<mat-tab-group
  class="tasks-tabs"
  mat-dialog-title
  *ngIf="(skin === 'GEO4' || skin === 'CVTP') && !isMobileResolution"
  (selectedTabChange)="onTabChange($event)"
  [selectedIndex]="0"
>
  <mat-tab label="{{ 'Live tasks' | translate }}"></mat-tab>
  <mat-tab label="{{ 'Past tasks' | translate }}"></mat-tab>
</mat-tab-group>

<!-- GEO4 -->
<div
  mat-dialog-content
  *ngIf="(skin === 'GEO4' || skin === 'CVTP') && !isMobileResolution"
  class="geo-tasks"
>
  <div class="row tasks-table">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="msisdn">
        <th mat-header-cell *matHeaderCellDef>MSISDN</th>
        <td mat-cell *matCellDef="let row">
          {{ row.payload.queryArgs.telno || row.payload.queryArgs.imsi }}
        </td>
      </ng-container>
      <ng-container
        *ngFor="let col of geoColumns"
        [matColumnDef]="col.columnDef"
      >
        <th mat-header-cell *matHeaderCellDef class="left-col">
          {{ col.header }}
        </th>
        <td mat-cell *matCellDef="let row" class="left-col">
          {{ col.cell(row) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button
            *ngIf="flag === 'false'"
            matTooltip="{{ 'Cancel' | translate }}"
            (click)="cancelRobot(row)"
            mat-icon-button
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsGeo"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsGeo"></tr>
    </table>
    <mat-paginator
      *ngIf="tasks.length"
      [length]="paginator.totalSize"
      [pageIndex]="paginator.currentPage"
      [pageSize]="paginator.pageSize"
      (page)="onPaginatePageChange($event)"
    >
    </mat-paginator>
  </div>
</div>

<mat-sidenav-container
  *ngIf="(skin === 'GEO4' || skin === 'CVTP') && isMobileResolution"
  [class.geo4-mobile-tasks]="isMobileResolution"
>
  <mat-sidenav #sidenav mode="over" [(opened)]="sidenavIsOpen">
    <app-mobile-sidenav></app-mobile-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <img
      class="burger-menu"
      (click)="sidenav.toggle()"
      src="assets/static/images/burger_menu.svg"
      height="30px"
    />
    <mat-tab-group
      class="tasks-tabs"
      *ngIf="(skin === 'GEO4' || skin === 'CVTP') && isMobileResolution"
      (selectedTabChange)="onTabChange($event)"
      [selectedIndex]="0"
    >
      <mat-tab label="{{ 'Live tasks' | translate }}"></mat-tab>
      <mat-tab label="{{ 'Past tasks' | translate }}"></mat-tab>
    </mat-tab-group>
    <div class="tasks-table">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="msisdn" sticky>
          <th mat-header-cell *matHeaderCellDef>MSISDN</th>
          <td mat-cell *matCellDef="let row">
            {{ row.payload.queryArgs.telno || row.payload.queryArgs.imsi }}
          </td>
        </ng-container>
        <ng-container
          *ngFor="let col of geoColumns"
          [matColumnDef]="col.columnDef"
        >
          <th mat-header-cell *matHeaderCellDef>{{ col.header }}</th>
          <td mat-cell *matCellDef="let row">{{ col.cell(row) }}</td>
        </ng-container>
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngIf="flag === 'false'"
              matTooltip="{{ 'Cancel' | translate }}"
              (click)="cancelRobot(row)"
              mat-icon-button
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsGeo"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsGeo"></tr>
      </table>
    </div>
    <mat-paginator
      *ngIf="tasks.length"
      [length]="paginator.totalSize"
      [pageIndex]="paginator.currentPage"
      [pageSize]="paginator.pageSize"
      (page)="onPaginatePageChange($event)"
    >
    </mat-paginator>
  </mat-sidenav-content>
</mat-sidenav-container>
