import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByDay',
})
export class SortByDayPipe implements PipeTransform {
  private days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  transform(value: Array<{ key: string; value: any }>, ...args: any[]): any {
    return value.sort(
      (a, b) => this.days.indexOf(a.key) - this.days.indexOf(b.key)
    );
  }
}
