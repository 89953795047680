import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { Notification } from 'src/app/components/notifications-list/notification.model';
import { AudioService } from '../../services/audio/audio.service';
import { BaseService } from '../../services/base.service';
import { NotificationService } from '../../services/notification/notification.service';
import { NotificationType } from '../../services/notification/types';

@Injectable({
  providedIn: 'root',
})
export class NotificationListService extends BaseService {
  notificationsCount = 0;
  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    public notificationService: NotificationService,
    private audioService: AudioService
  ) {
    super(router, snackBar);
  }

  public getNotificationMessages() {
    return this.notificationService.messages$.pipe(
      map((notifications: Notification[]) => {
        const systemNotifications = this.getSystemNotifications(notifications);
        const regularNotifications =
          this.getRegularNotifications(notifications);
        const regularNotificationsRead =
          this.getReadRegularNotifications(regularNotifications);

        const totalNotificationsCount =
          regularNotificationsRead.length + systemNotifications.length;

        const notificationIdsForDelete = regularNotifications.map((n) => n.id);
        const notificationIdsForRead = regularNotificationsRead.map(
          (n) => n.id
        );

        if (
          totalNotificationsCount > this.notificationsCount &&
          !this.notificationService.hasNotificationToneAlreadyRang
        ) {
          // play sound only when a new notification is added
          this.audioService.playNotification();
          this.notificationService.hasNotificationToneAlreadyRang = true;
        }
        this.notificationsCount = totalNotificationsCount;

        return {
          count: this.notificationsCount,
          idsForDelete: notificationIdsForDelete,
          idsForRead: notificationIdsForRead,
        };
      })
    );
  }

  getSystemNotifications(notifications: Notification[]): Notification[] {
    return notifications.filter(
      (n) => n.notificationType === NotificationType.SYSTEM
    );
  }

  getRegularNotifications(notifications: Notification[]): Notification[] {
    const excludeFromRegular: string[] = [
      NotificationType.SYSTEM,
      NotificationType.MARKETING_BANNER,
    ];

    return notifications.filter(
      (n) => !excludeFromRegular.includes(n.notificationType)
    );
  }

  getReadRegularNotifications(notification: Notification[]): Notification[] {
    return notification.filter((n) => !n.isRead);
  }
}
