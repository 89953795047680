import { NouiFormatter } from 'ng2-nouislider';

export class TimeFormatter implements NouiFormatter {
  to(value: number): string {
    value = Math.round(value);
    let timeString = '';
    if (value < 10) {
      timeString += '0';
    }
    timeString += `${value}:00`;
    return timeString;
  }

  from(value: string): number {
    const v = value.split(':').map(parseInt);
    return v[0];
  }
}
