export interface InvestigationRequest {
  createdBy: string;
  investigationType: string;
}

export interface Investigation {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  createdBy: string;
  queriesCounter: number;
}

export enum InvestigationStates {
  ChooseInvestigation = 'ChooseInvestigation',
  SendInvestigation = 'SendInvestigation',
  None = 'None',
}

export enum AnimationStates {
  Hide = 'hide',
  Show = 'show',
}

export enum InvestigationTypes {
  Homicide = 'Homicide',
  Violence = 'Violence',
  Robbery = 'Robbery',
  Kidnapping = 'Kidnapping',
  FinancialCrime = 'Financial crime',
  Fraud = 'Fraud',
  DrugsAndIllegalSubstances = 'Drugs and illegal substances',
  Other = 'Other',
}
