import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  InputSize,
  InputState,
  InputStateIconType,
  InputStateType,
} from './input.model';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { FaProModule } from '@fe-platform/fa-pro';

@Component({
  selector: 'intellectus-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [FaProModule, FormsModule, NgIf, NgSwitch, NgSwitchCase],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnChanges, OnInit, ControlValueAccessor {
  @Input() disabled = false;
  @Input() hint = '';
  @Input() label = '';
  @Input() optionalLabel = '';
  @Input() placeholder = '';
  @Input() size: InputSize = InputSize.STANDARD;
  @Input() state: InputState | null = null;
  @Input() value = '';
  @Input() width = '';

  @Output() keyUp: EventEmitter<string> = new EventEmitter();
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  inputValue = '';
  stateIcon: string | null = null;

  readonly inputSizeEnum = InputSize;
  readonly inputStateTypeEnum = InputStateType;

  onChange!: (value: string) => void;
  onTouch!: (value: string) => void;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['state']?.currentValue) {
      this.setStateMessageIcon(changes['state'].currentValue);
    }

    if (changes['value']?.currentValue) {
      this.inputValue = changes['value']?.currentValue;
    }
  }

  ngOnInit() {
    this.inputValue = this.value;
  }

  onKeyUp(): void {
    this.keyUp.emit(this.inputValue);
  }

  onValueChange(): void {
    this.valueChange.emit(this.inputValue);
    this.onChange(this.inputValue);
    this.onTouch(this.inputValue);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(newValue: string): void {
    this.inputValue = newValue;
  }

  private setStateMessageIcon(state: InputState): void {
    this.stateIcon =
      state.type === InputStateType.SUCCESS
        ? InputStateIconType.CIRCLE_CHECK
        : InputStateIconType.CIRCLE_EXCLAMATION;
  }
}
