import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  group,
  stagger,
} from '@angular/animations';

export const Animations = {
  openClose: trigger('openClose', [
    state(
      'open',
      style({
        height: '*',
        opacity: 1,
        visibility: 'visible',
      })
    ),
    state(
      'closed',
      style({
        height: '100px',
        opacity: 0,
      })
    ),
    transition('open => closed', [animate('0.3s')]),
    transition('closed => open', [animate('0.3s')]),
    transition('* => closed', [animate('0.5s')]),
    transition('* => open', [animate('0.2s')]),
    transition('open <=> closed', [animate('0.3s')]),
    transition('* => *', [animate('0.3s')]),
  ]),
  slideInAnimation: trigger('routeAnimations', [
    transition('* <=> *', [
      query(
        ':enter, :leave',
        style({
          position: 'fixed',
          width: '100%',
        }),
        { optional: true }
      ),
      group([
        query(
          ':enter',
          [
            style({ transform: 'translateX(100%)' }),
            animate('.8s ease-in-out', style({ transform: 'translateX(0%)' })),
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            style({ transform: 'translateX(0%)' }),
            animate(
              '.8s ease-in-out',
              style({ transform: 'translateX(-100%)' })
            ),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ]),

  fadeAnimation: trigger('fadeAnimation', [
    transition('* => *', [
      query(':enter', [style({ opacity: 0 })], { optional: true }),
      query(
        ':leave',
        [style({ opacity: 1 }), animate('1s', style({ opacity: 0 }))],
        { optional: true }
      ),
      query(
        ':enter',
        [style({ opacity: 0 }), animate('1s', style({ opacity: 1 }))],
        { optional: true }
      ),
    ]),
  ]),

  filterAnimate: trigger('filterAnimation', [
    transition(':enter, * => 0, * => -1', []),
    transition(':increment', [
      query(
        ':enter',
        [
          style({ opacity: 0, width: '0px' }),
          stagger(80, [
            animate('200ms ease-out', style({ opacity: 1, width: '*' })),
          ]),
        ],
        { optional: true }
      ),
    ]),
    transition(':decrement', [
      query(':leave', [
        stagger(80, [
          animate('200ms ease-out', style({ opacity: 0, width: '0px' })),
        ]),
      ]),
    ]),
  ]),

  slideInOut: trigger('slideInOut', [
    state(
      'false',
      style({
        width: '*',
        opacity: '1',
      })
    ),
    state(
      'true',
      style({
        width: '*',
        opacity: '1',
      })
    ),
    transition('true => false', [
      style({ transform: 'translateX(150px)' }),
      animate('500ms ease-in'),
    ]),
    transition('false => true', [
      style({ transform: 'translateX(-150px)' }),
      animate('500ms ease-out'),
    ]),
  ]),
  listItems: trigger('listItems', [
    transition(':enter', [
      style({ transform: 'translateX(-50px)', opacity: 0 }),
      animate(
        '500ms cubic-bezier(0.1, 0.7, 0.6, 0.9)',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),
    ]),
    transition(':leave', [
      style({ transform: 'translateX(50px)', opacity: 1, height: '*' }),
      animate(
        '.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        style({
          transform: 'translateX(0%)',
          opacity: 0,
          height: '0px',
          margin: '0px',
        })
      ),
    ]),
  ]),

  enterAnimation: trigger('enterAnimation', [
    transition(':enter', [
      style({ transform: 'translateX(10%)', opacity: 0 }),
      animate('400ms', style({ transform: 'translateX(0%)', opacity: 0.8 })),
    ]),
  ]),

  enterFromBottomAnimation: trigger('enterFromBottomAnimation', [
    transition(':enter', [
      style({ transform: 'translateY(10%)', opacity: 0 }),
      animate('200ms', style({ transform: 'translateY(0%)', opacity: 0.8 })),
    ]),
  ]),
  openCloseLoginPanel: trigger('openCloseLoginPanel', [
    state(
      'open',
      style({
        height: '*',
        opacity: 1,
        visibility: 'visible',
      })
    ),
    state(
      'closed',
      style({
        height: '0px',
        opacity: 0,
        margin: '0px',
        visibility: 'hidden',
      })
    ),
    transition('open => closed', [animate('0.3s')]),
    transition('closed => open', [animate('0.3s')]),
    transition('* => closed', [animate('0.5s')]),
    transition('* => open', [animate('0.2s')]),
    transition('open <=> closed', [animate('0.3s')]),
    transition('* => *', [animate('0.3s')]),
  ]),
};
