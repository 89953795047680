<div class="select-container">
  <div
    class="select-label-container"
    [class.flex-end]="!label && optionalLabel"
    [class.flex-start]="label && !optionalLabel"
    [class.space-between]="label && optionalLabel"
  >
    <label *ngIf="label">{{ label }}</label>
    <span *ngIf="optionalLabel" class="optional">{{ optionalLabel }}</span>
  </div>

  <div
    class="select-field-container"
    [class.disabled]="disabled"
    [class.small]="size === selectSizeEnum.SMALL"
  >
    <select
      #selectElement
      [(ngModel)]="selectedValue"
      [disabled]="disabled"
      [class.error-state]="errorMessage !== ''"
      [class.select-placeholder]="placeholder && !selectedValue"
      (change)="onValueChange()"
    >
      <option
        *ngIf="placeholder && !selectedValue"
        class="select-option-placeholder"
        value=""
        disabled
        selected
      >
        {{ placeholder }}
      </option>
      <option
        *ngFor="let option of options"
        [value]="option.value"
        class="select-option"
      >
        {{ option.label }}
      </option>
    </select>
  </div>

  <div *ngIf="hint" class="hint-msg">{{ hint }}</div>

  <ng-container *ngIf="errorMessage">
    <div class="state-message-container error-msg">
      <fe-platform-fa-pro-icon
        [family]="'regular'"
        [name]="'circle-exclamation'"
      ></fe-platform-fa-pro-icon>
      <span>{{ errorMessage }}</span>
    </div>
  </ng-container>
</div>
