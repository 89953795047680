import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';
import {
  MatLegacyListOption as MatListOption,
  MatLegacySelectionList as MatSelectionList,
} from '@angular/material/legacy-list';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutService } from '@fe-platform/shared-ui/intellectus';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdIdLocationHistoryDto } from '@trg-commons/gio-data-models-ts';
import { Angulartics2 } from 'angulartics2';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { head } from 'lodash-es';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { AdIdService } from 'src/app/modules/ad-ids/shared/ad-id.service';
import { isValidIfa } from 'src/app/modules/ad-ids/shared/helpers';
import { InitiatedSearchEvent } from 'src/app/modules/search-intel/models/initiated-search-event.interface';
import { IntelSearchArgTypes } from 'src/app/modules/search-intel/models/intel-search-arg-types.model';
import {
  IntelSearchField,
  IntelSearchMode,
  SearchIntelModel,
} from 'src/app/modules/search-intel/models/search-intel.model';
import { InputTypeGuesserService } from 'src/app/modules/search-intel/services/input-type-guesser.service';
import { IntelResultsInvestigationService } from 'src/app/modules/search-intel/services/intel-results-investigation.service';
import { SearchIntelService } from 'src/app/modules/search-intel/services/search-intel.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { AppHighlighterService } from 'src/app/services/app-highlighter/app-highlighter.service';
import { HighlightClassEnum } from 'src/app/services/app-highlighter/enum/highlight-class.enum';
import { BillingService } from 'src/app/services/billing/billing.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import {
  DashboardService,
  DashboardView,
} from 'src/app/services/dashboard/dashboard.service';
import { InstantMessagesStore } from 'src/app/services/instant-messages.store';
import { IntelSearchTrackerService } from 'src/app/services/intel-search-tracker/intel-search-tracker.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Animations } from 'src/app/shared/animations/animations';
import { WebintDisabledService } from 'src/app/shared/components/webint-disabled-modal/webint-disabled.service';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';
import {
  BillingActionType,
  BillingActions,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { NavbarIdentifier } from 'src/app/shared/models/navbar-identifier.enum';
import { Themes } from 'src/app/shared/models/skins.model';
import { checkUrlForSpecificSubsequence } from 'src/app/shared/util/helper';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import {
  IntelSearchType,
  SearchFilters,
  SearchIntelResult,
  SearchLabels,
} from '../../modules/search-intel/models/search-intel.model';
import {
  SearchByImageModalPayload,
  SearchbyImageComponent,
} from '../searchby-image/searchby-image.component';
import { InputTypes, searchInputTypes } from './input-types.model';
import { SearchFields } from './search-fields.model';
import { SearchIntelNavbarHelperService } from './search-intel-navbar-helper.service';
import { SearchIntelNavbarNumberValidatorService } from './search-intel-navbar-number-validator.service';
import { SearchIntelNavbarService } from './search-intel-navbar.service';
import { NavbarSearchType } from './search-type.model';
@UntilDestroy()
@Component({
  selector: 'app-search-intel-navbar',
  templateUrl: './search-intel-navbar.component.html',
  styleUrls: ['./search-intel-navbar.component.scss'],
  providers: [
    SearchIntelNavbarNumberValidatorService,
    SearchIntelNavbarHelperService,
  ],
  animations: [Animations.openClose],
})
export class SearchIntelNavbarComponent implements OnInit, OnChanges {
  @Output()
  emitIsInitialSearch = new EventEmitter<boolean>();

  @Output()
  emitSearchInfo = new EventEmitter<InitiatedSearchEvent>();

  @Output()
  emitIntelNavbarClick = new EventEmitter<boolean>();

  @Output()
  emitQueryInfo = new EventEmitter<{
    btnActive: boolean;
    searchInputType?: any[]; //IntelSearchArgTypes[];
    searchInputValue?: string;
  }>();

  @Output()
  emitSearchFields = new EventEmitter<IntelSearchField[]>();

  @Input()
  importFilters: SearchFilters;

  @Input()
  importQueryArgs: { argValue: string; argType: any }[];

  @Input()
  navBarIdentifier: NavbarIdentifier;

  @Input()
  public noResult = false;

  @ViewChild('searchList')
  searchList: MatSelectionList;

  @ViewChild('creditsMenuTrigger') creditsMenuTrigger: MatMenuTrigger;

  searchLabels = SearchLabels;
  showLoader = false;
  queryPlaceholder: string;
  searchDrop = this.numberValidatorService.searchDrop;
  searchHistory: SearchIntelModel[] = [];
  searchFocus = false;
  loaderValue = false;
  searchText = new UntypedFormControl();
  searchInputValue: string;
  searchType: NavbarSearchType = 'GE0';
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  theme: Themes;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  adintFlavourEnabled: boolean;
  searchFields: SearchFields[] = [];
  IntelSearchType = IntelSearchType;
  searchIntelLoading = false;
  searchInputTypes: InputTypes = searchInputTypes;
  @ViewChild('searchFieldInput') searchFieldInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  btnActive: boolean;
  inputTypes: { [key: string]: any }[] = [];
  queryFilters: SearchFilters;
  showFilter = true;
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };

  inputIsTelno = false;

  enableIntelResultsV2: boolean;
  enableCovid19MX: boolean;
  typingEffect = true;
  appLanguage: Observable<string>;
  isLocatingDisabled = false;
  @ViewChild('matFormField') matFormField: MatFormField;
  public availableNavBarIdentifier = NavbarIdentifier;

  public billingPlan$: Observable<
    BillingPlan<BillingActions, BillingActionType>
  >;
  public isMobile = false;
  public animationLocked = false;

  constructor(
    public routerHelperService: SearchIntelNavbarHelperService,
    private searchIntelNavbarService: SearchIntelNavbarService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private searchIntelService: SearchIntelService,
    private queryService: QueryService,
    private translationService: TranslationService,
    private dashboardService: DashboardService,
    private adIdService: AdIdService,
    private appConfigService: AppConfigService,
    private instantMessagesFetcher: InstantMessagesStore,
    private angulartics2: Angulartics2,
    private billingService: BillingService,
    private intelResultsService: IntelResultsInvestigationService,
    private webintDisabledService: WebintDisabledService,
    private changeDetectorRef: ChangeDetectorRef,
    private inputTypeGuesserService: InputTypeGuesserService,
    private userBillingService: UserBillingService,
    private numberValidatorService: SearchIntelNavbarNumberValidatorService,
    private readonly intelSearchTrackerService: IntelSearchTrackerService,
    private readonly layoutService: LayoutService,
    private readonly appHighlighterService: AppHighlighterService
  ) {
    this.subscribeToRouterEvents();
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.adintFlavourEnabled =
      this.appConfigService.getConfigVariable('enableAdintFlavour');
    this.enableIntelResultsV2 = this.appConfigService.getConfigVariable(
      'enableIntelResultsV2'
    );
    this.enableCovid19MX =
      this.appConfigService.getConfigVariable('enableCovid19MX');
    this.isLocatingDisabled = this.appConfigService.getConfigVariable(
      'disableLocatingFunctionality'
    );

    this.router.events.subscribe((url) => {
      if (url instanceof NavigationEnd) {
        this.searchDrop = false;
        this.emitIntelNavbarClick.emit(false);
      }
    });

    this.appLanguage = this.translationService.languageChange;
    this.layoutService.isMobile$
      .pipe(untilDestroyed(this))
      .subscribe((isMobile: boolean): void => {
        this.isMobile = isMobile;
      });
  }

  ngOnInit() {
    this.getSearchTypeBaseUrl(this.router.url);
    this.queryPlaceholder =
      this.searchIntelNavbarService.setQueryInputPlaceHolder(this.searchType);
    this.searchIntelService.changeHistory
      .pipe(untilDestroyed(this))
      .subscribe(() => this.getHistory());

    this.getHistory();

    this.searchText.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((text) => {
        if (text) {
          this.searchInputValue = text.trim();
        }

        this.handleInputText(text);
      });

    if (history.state?.searchState) {
      this.searchIntelNavbarService.searchFields$
        .pipe(take(1))
        .subscribe((searchFields) => {
          this.searchFields = searchFields;
          this.btnActive = !!(
            this.searchText.value || this.searchFields.length
          );
          if (searchFields[0]) {
            this.searchInputValue = searchFields[0].value;
            this.search({ keyCode: ENTER } as KeyboardEvent, '');
          }
        });
      history.state.searchState = null;
    }

    this.searchIntelService.searchBtnListner
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(
        (action: {
          event: PointerEvent;
          filters: SearchFilters;
          searchMode: IntelSearchMode;
          navBarIdentifier: string;
          noResultSearch?: boolean;
          skipLocate?: boolean;
        }) => {
          const {
            event,
            noResultSearch,
            skipLocate,
            filters,
            navBarIdentifier,
            searchMode,
          } = action;
          if (navBarIdentifier === this.navBarIdentifier) {
            this.queryFilters = filters;
            this.handleIncomingInput({
              event,
              noResultSearch,
              skipLocate: skipLocate || this.isLocatingDisabled,
              searchMode,
            });
          }
        }
      );

    if (this.importQueryArgs) {
      this.createQueryLabels();
    }

    this.billingPlan$ = this.billingService
      .getBillingPlan()
      .asObservable()
      .pipe(
        map((plan) => this.userBillingService.mapOfflineToOnlineCosts(plan))
      );

    this.showFilter = this.navBarIdentifier !== NavbarIdentifier.INTELSEARCHBAR;
  }

  ngOnChanges(): void {
    this.checkForSearchByKeyword();
  }

  createQueryLabels() {
    this.importQueryArgs.forEach((argData) => {
      const label = this.searchIntelService.getQueryArgLabel(argData.argType);
      if (label === SearchLabels.PHONE) {
        this.searchText.setValue(argData.argValue);
        this.searchInputValue = argData.argValue;
        const countryCode = this.numberValidatorService.getCountryCode(
          this.searchText.value
        );
        this.searchFields.push({
          value: this.searchText.value,
          label,
          countryCode: countryCode
            ? `iti__flag iti__${countryCode}`
            : undefined,
          countryFlag: countryCode,
        });
      } else if (label === SearchLabels.IMAGE) {
        this.searchInputValue = 'image-search';
        this.searchText.setValue('');
        Object.keys(argData.argValue).forEach((key) => {
          if (key === 'photoUrl') {
            this.searchFields.push({
              value: argData.argValue[key],
              label: SearchLabels.IMAGE,
              filename: argData.argValue['filename'],
            });
          } else if (key === 'name' && argData.argValue[key]) {
            this.searchFields.push({
              value: argData.argValue[key],
              label: SearchLabels.NAME,
            });
          }
        });
      } else if (
        label === SearchLabels.POSSIBLE_TELNO &&
        this.searchText.value
      ) {
        this.searchInputValue = argData.argValue;
        this.searchText.setValue(argData.argValue);
        this.searchFields.push({
          value: this.searchText.value,
          label,
        });
      } else {
        this.searchInputValue = argData.argValue;
        this.searchText.setValue(argData.argValue);
        this.searchFields.push({
          value: this.searchText.value,
          label,
        });
      }

      this.searchIntelService.searchText.next(this.searchText.value);
      this.onKeyUp();
      this.searchText.reset();
    });
  }

  getHistory() {
    const queryArgs = {
      limit: 5,
      page: 1,
    };

    this.searchIntelService
      .getAllSearchIntelWithPagination(queryArgs)
      .subscribe((res: { result: SearchIntelResult }) => {
        this.searchHistory = res.result.queries;
        this.changeDetectorRef.markForCheck();
        setTimeout((): void => {
          this.animationLocked = false;
        });
      });
  }

  onKeyUp(keyEvent?: KeyboardEvent) {
    this.removeSearchTextLeadingSpace();
    if (keyEvent && keyEvent.key === 'Enter') {
      if (this.isMobile) {
        this.searchFieldInput?.nativeElement?.blur();
      }
      // if we clicked enter select the first input suggestion and set the value to the searchInputValue variable
      if (this.inputIsTelno) {
        this.searchInputValue = this.inputTypes[0].value;
      }

      this.handleIncomingInput({
        event: keyEvent,
        skipLocate: false,
        searchMode: IntelSearchMode.Search,
      });
    }
  }

  onCountryChange(event) {
    const number = this.searchFields[0].value;
    const parsedNumber = this.phoneNumberUtil.parse(number, '');
    const countryCode = parsedNumber.getCountryCode();

    if (event?.dialCode) {
      this.searchFields[0].value = this.searchFields[0].value.replace(
        countryCode.toString(),
        event.dialCode
      );
      const parsed = this.phoneNumberUtil.parse(this.searchFields[0].value, '');
      const isValidNumber = this.phoneNumberUtil.isValidNumber(parsed);
      this.btnActive = isValidNumber;
      if (!isValidNumber) {
        this.showMessage(
          this.translationService.translate(
            'The requested phone number is not valid with this country code. Please select a different country code from the dropdown menu and try again.'
          )
        );
      } else {
        this.searchInputValue = this.searchFields[0].value;
      }
      this.emitQueryInfoData();
    }
  }

  handleInputText(searchText: string): void {
    this.btnActive = !!(searchText || this.searchFields.length);
    if (searchText) {
      this.inputTypes = this.inputTypeGuesserService.guessInputType(searchText);
    }

    this.inputIsTelno = this.inputTypes.some((input) =>
      input['key'].toLowerCase().includes('telno')
    );

    this.emitQueryInfoData();
  }

  onKeyDown(event) {
    if (event && [38, 40].includes(event.keyCode) && this.searchList) {
      this.searchList.focus();
    } else if (
      event &&
      [8, 'Backspace'].includes(event.keyCode) &&
      this.searchFields?.length &&
      this.isMobile
    ) {
      this.remove(0);
    } else {
      this.searchFields.forEach((label) => {
        if (label.label === SearchLabels.IMAGE) {
          if (this.searchFields.length === 2) {
            event.preventDefault();
          }
        } else if (this.searchFields.length === 1) {
          event.preventDefault();
        }
      });
      this.onSearchDrop();
    }
  }

  public onFocus(): void {
    this.appHighlighterService.update(HighlightClassEnum.DARK_SMOOTH);
  }

  public onBlur(): void {
    this.appHighlighterService.reset();
  }

  onSearchDrop() {
    this.searchDrop = true;
    if (this.searchType === IntelSearchType.OSINT) {
      this.emitIntelNavbarClick.emit(this.searchDrop);
    }
    event.stopPropagation();
  }

  clickedOutside() {
    this.searchDrop = false;
    this.emitIntelNavbarClick.emit(this.searchDrop);
  }

  private checkForValidMsisdn(): string | boolean {
    return this.numberValidatorService.checkForValidMsisdn(
      this.searchInputValue
    );
  }

  private handleIncomingInput(params: {
    event: KeyboardEvent | PointerEvent;
    searchMode?: IntelSearchMode;
    noResultSearch?: boolean;
    skipLocate?: boolean;
  }): void {
    const { event, noResultSearch, skipLocate, searchMode } = params;

    if (!this.loaderValue) {
      if (!this.btnActive) {
        return;
      }

      if (this.hasNavbarSearchTriggered(event)) {
        const autoSelectOptions =
          this.searchIntelNavbarService.searchListAutoSelect(
            this.inputTypes,
            this.searchList,
            this.searchType
          );

        if (autoSelectOptions) {
          this.onClickSearchItem(autoSelectOptions);
          return;
        }
      }

      if (
        !this.searchIntelNavbarService.userHasEnoughCredits(
          this.searchIntelNavbarService.isTelno(
            this.searchInputValue,
            skipLocate
          ),
          this.searchType
        )
      ) {
        return;
      }

      if (
        this.searchIntelNavbarService.isTelno(
          this.searchInputValue,
          skipLocate
        ) &&
        this.searchType === IntelSearchType.OSINT
      ) {
        const validPhone = this.checkForValidMsisdn();
        if (validPhone) {
          this.guessSearchIntelType(noResultSearch, searchMode, (): void =>
            this.createDiscoveryQuery(<string>validPhone)
          );
        } else {
          this.showMessage(
            this.translationService.translate('Enter a valid number')
          );
        }
      } else {
        this.guessSearchIntelType(noResultSearch, searchMode);
      }
    }
  }

  private guessSearchIntelType(
    noResultSearch: boolean,
    searchMode?: IntelSearchMode,
    callbackAfterSearch?: () => void
  ): void {
    this.angulartics2.eventTrack.next({
      action: matomoActions.search,
      properties: { category: matomoCategories.landingPage },
    });
    switch (this.searchType) {
      case IntelSearchType.GE0:
        if (
          this.numberValidatorService.isValidImsi(
            this.searchInputValue,
            this.searchText
          )
        ) {
          return;
        }
        this.numberValidatorService.isValidPhone(
          this.searchInputValue,
          this.searchText
        );
        this.searchInputValue = '';
        break;
      case IntelSearchType.OSINT:
        this.searchDrop = false;
        if (!this.webintDisabledService.webintActionsDisabled) {
          const valid = this.checkForValidMsisdn();
          const isLocate: boolean = searchMode === IntelSearchMode.Locate;
          if (valid && isLocate) {
            this.locate();
          }

          if (
            [IntelSearchMode.SearchAndLocate, IntelSearchMode.Search].includes(
              searchMode
            )
          ) {
            this.createQuery(noResultSearch, searchMode, callbackAfterSearch);
          }
        } else {
          this.webintDisabledService.openWebintDisabledModal();
        }
        break;
      case IntelSearchType.ADINT: {
        if (!this.webintDisabledService.handleWebintAvailability()) {
          return;
        }

        const ifas = this.searchInputValue.trim().split(',');
        const validIfas = [];

        ifas.forEach((ifa) => {
          if (isValidIfa(ifa)) {
            validIfas.push(ifa);
          }
        });

        if (validIfas.length < ifas.length) {
          this.showMessage(
            this.translationService.translate(
              'You have entered some invalid ad ids.'
            )
          );
        } else {
          this.showLoader = true;
          this.adIdService
            .createLocationHistoryRequest(
              new AdIdLocationHistoryDto({
                ifas: validIfas,
                startTime: moment().subtract(1, 'months').toDate(),
                endTime: moment().toDate(),
              })
            )
            .subscribe(() => {
              this.searchText.setValue(null);
              this.showLoader = false;
            });
        }
        break;
      }
    }
  }

  createQuery(
    noResultSearch = false,
    searchMode?: IntelSearchMode,
    callbackAfterSearch?: () => void
  ): void {
    noResultSearch = this.router.url.includes('/webint/results');
    this.searchDrop = false;
    if (!this.enableIntelResultsV2) {
      this.loaderValue = true;
    }

    let searchArgs = [];
    if (!this.searchFields.length) {
      searchArgs = searchArgs.concat(
        this.buildQueryArgs(this.searchInputValue)
      );
      this.searchIntelService.searchText.next(this.searchInputValue);
    } else {
      const searchArgsImageObject = {
        arg_type: IntelSearchArgTypes.PHOTO,
        arg_value: {
          name: undefined,
          photoUrl: '',
          filename: '',
        },
      };
      const checkForImage = this.searchFields.every(
        (entry) => entry.label !== SearchLabels.IMAGE
      );

      this.searchFields.forEach((field) => {
        if (checkForImage) {
          searchArgs = searchArgs.concat(
            this.searchIntelService.buildQueryArgsByLabel(field)
          );
          this.searchIntelService.searchText.next(searchArgs[0].arg_value);
        } else {
          searchArgsImageObject.arg_value[
            field.label === SearchLabels.IMAGE
              ? IntelSearchArgTypes.PHOTO_URL
              : IntelSearchArgTypes.NAME
          ] = String(field.value).trim();
          if (field.label === SearchLabels.IMAGE) {
            searchArgsImageObject.arg_value['filename'] = String(
              field.filename
            );
          }
          this.searchIntelService.searchText.next(
            `${searchArgsImageObject.arg_value.name} and ${
              searchArgsImageObject.arg_value.filename
                ? searchArgsImageObject.arg_value.filename
                : searchArgsImageObject.arg_value.photoUrl
            }`
          );
        }
      });
      if (!checkForImage) {
        searchArgs.push(searchArgsImageObject);
      }
    }

    if (this.router.url.includes('/webint/results') && this.noResult) {
      this.searchIntelNavbarService.searchFields$.next(this.searchFields);
      this.router.navigate(['webint'], {
        state: { searchState: 'webint' },
      });
      return;
    }

    if (this.enableIntelResultsV2) {
      this.intelResultsService
        .createSearchIntel(searchArgs, this.queryFilters)
        .subscribe((search: SearchIntelModel) => {
          this.intelResultsService.createListenerForSearchResult(search);
          this.routerHelperService.navigateToResultsView(search);
        });
    } else {
      const tempSearchID: string =
        this.intelSearchTrackerService.insertTempSearch(searchArgs);
      if (
        this.navBarIdentifier === NavbarIdentifier.MAINNAVBAR ||
        this.isMobile ||
        (this.navBarIdentifier === NavbarIdentifier.INTELSEARCHBAR &&
          searchArgs[0].arg_type === 'telno' &&
          [IntelSearchMode.Locate, IntelSearchMode.SearchAndLocate].includes(
            searchMode
          ))
      ) {
        this.intelSearchTrackerService.minimize();
      }
      const copiedSearchFields = [...this.searchFields];
      const copiedSearchArgs = [...searchArgs];
      this.searchFields = [];
      this.searchText.setValue('');
      this.emitIntelNavbarClick.emit(false);
      this.emitIsInitialSearch.emit(true);

      this.searchFieldInput?.nativeElement?.blur();
      if (
        searchArgs[0].arg_type === 'url' &&
        this.inputTypeGuesserService.isPossibleUrl(searchArgs[0].arg_value)
      ) {
        searchArgs[0].arg_value = `https://${searchArgs[0].arg_value}`;
      }
      this.emitSearchFields.emit(copiedSearchFields);
      this.searchIntelService
        .createSearchIntel(searchArgs, this.queryFilters)
        .subscribe(
          (search: SearchIntelModel) => {
            searchArgs = [];
            if (search) {
              if (this.navBarIdentifier === NavbarIdentifier.INTELSEARCHBAR) {
                this.emitSearchInfo.emit({
                  search,
                  searchFields: copiedSearchFields,
                  searchMode,
                });
              } else {
                this.intelSearchTrackerService.insertSearch(
                  search.id,
                  copiedSearchArgs
                );
              }
              this.intelSearchTrackerService.removeSearch(tempSearchID);

              if (callbackAfterSearch) {
                callbackAfterSearch();
              }
            }

            this.emitIsInitialSearch.emit(false);
            this.searchInputValue = '';
            this.getHistory();
            this.loaderValue = false;
            this.changeDetectorRef.markForCheck();
          },
          (error: { errors: { message?: string; msg?: string }[] }) => {
            this.searchFields = copiedSearchFields;
            this.intelSearchTrackerService.removeSearch(tempSearchID);
            const searchField = head(this.searchFields);
            const err = head(error.errors);
            if (err?.message) {
              this.showMessage(this.translationService.translate(err.message));
            }
            if (err?.msg) {
              this.showMessage(
                `${searchField.value} ${this.translationService.translate(
                  'is not a valid'
                )} ${searchField.label}`
              );
            }
            this.emitIsInitialSearch.emit(false);
            this.loaderValue = false;
          }
        );
    }
  }

  private buildQueryArgs(
    value: string
  ): { arg_type: string; arg_value: string }[] {
    const searchArgs = [];
    const input = this.inputTypeGuesserService.guessInputType(value);
    Object.keys(input).forEach((key) => {
      searchArgs.push({ arg_type: key, arg_value: String(input[key]).trim() });
    });

    const telnoMatch = input.find(
      (type) => type.key === IntelSearchArgTypes.TELNO
    );
    if (telnoMatch) {
      this.searchIntelService.searchText.next(telnoMatch.value);
    } else {
      const searchText = Object.keys(input)
        .map((key) => input[key])
        .join(', ');
      this.searchIntelService.searchText.next(searchText);
    }

    return searchArgs;
  }

  submitQuickQuery(queries) {
    this.searchDrop = false;
    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.QUERY_LOCATION,
      ])
    ) {
      const quickQuerySubscription = this.queryService
        .quickQuery(queries)
        .subscribe({
          next: (response: { result: any }) => {
            const [query] = response.result;
            if (query.query_args.telno) {
              this.instantMessagesFetcher.fetchAllImPlatforms(
                query.query_args.telno,
                query.id,
                true
              );
            }
            this.showMessage(
              this.translationService.translate('Query created successfully!')
            );
            this.dashboardService.componentsView.next(DashboardView.LOG);
            this.dashboardService.showLogTab.next(true);
            quickQuerySubscription.unsubscribe();
          },
          error: (err) => {
            this.queryService.handleQueryError(err);
            quickQuerySubscription.unsubscribe();
          },
        });
    }
  }

  showPreviousSearch(search: SearchIntelModel) {
    this.searchDrop = false;
    this.routerHelperService.navigateToResultsView(search);
    this.clearSearchInput();
  }

  addImageDialog() {
    const dialogRef = this.dialog.open(SearchbyImageComponent, {
      width: '720px',
    });

    dialogRef.afterClosed().subscribe((result: SearchByImageModalPayload) => {
      if (result?.imageUrl) {
        this.searchText.setValue('');
        this.btnActive = false;
        this.searchIntelService.searchText.next(this.searchText.value);
        this.searchInputValue = 'image-search';
        this.searchFields = [];
        Object.keys(result).forEach((key) => {
          if (key === 'imageUrl') {
            this.searchFields.push({
              value: result[key],
              label:
                key === 'imageUrl' ? SearchLabels.IMAGE : SearchLabels.NAME,
              filename: result['filename'],
              displayName: result['filename'],
            });
          } else if (key === 'name' && !!result.name) {
            this.searchFields.push({
              value: result[key],
              label: SearchLabels.NAME,
            });
          }
        });
        this.search({ keyCode: ENTER } as KeyboardEvent, 'image-search');
      }
    });
  }

  remove(index: any): void {
    if (index >= 0) {
      const imageSearch = this.searchFields.some(
        (x) => x.label == SearchLabels.IMAGE
      );
      if (imageSearch) {
        this.searchFields = [];
      } else {
        this.searchFields.splice(index, 1);
      }
    }
  }

  makeFavorite(flag: boolean, id: string): void {
    this.animationLocked = true;
    this.searchIntelService
      .makeSearchHistoryAsFavorite(id, flag)
      .subscribe(() => {
        this.getHistory();
      });
  }

  onClickSearchItem(data: MatListOption[]) {
    const label = this.searchIntelNavbarService.getSearchLabel(
      data,
      this.searchInputTypes
    );

    this.searchInputValue = this.searchIntelNavbarService.getSearchValue(data);

    this.navBarIdentifier === NavbarIdentifier.INTELSEARCHBAR &&
      this.clickedOutside();

    this.createSearchFieldsBasedOnLabels(label, data);

    this.searchText.reset();
    this.emitSearchFields.next(this.searchFields);
    event.stopPropagation();
  }

  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe((location: NavigationEnd) => {
        this.getSearchTypeBaseUrl(location.url);
        this.queryPlaceholder =
          this.searchIntelNavbarService.setQueryInputPlaceHolder(
            this.searchType
          );
        this.showFilter = location.url !== `/${ApplicationMainPageUrls.WEBINT}`;
      });
  }

  private createSearchFieldsBasedOnLabels(
    label: SearchLabels,
    data: MatListOption[]
  ): void {
    if (this.searchIntelNavbarService.isLabelPhone(label, this.searchFields)) {
      const phoneSearchField =
        this.numberValidatorService.createPhoneSearchField(this.inputTypes);

      this.searchFields.push(phoneSearchField);
    } else if (
      this.searchIntelNavbarService.isLabelPossiblePhone(
        label,
        this.searchFields
      )
    ) {
      this.searchText.setValue(data[0].value.value);
      const possiblePhoneSearchField =
        this.numberValidatorService.createPossiblePhoneSearchField(data);

      this.searchFields.push(possiblePhoneSearchField);
    } else if (
      this.searchIntelNavbarService.checkExistingLabels(this.searchFields) &&
      this.searchIntelNavbarService.isLabelName(label, this.searchFields)
    ) {
      const nameSearchField = this.numberValidatorService.createNameSearchField(
        this.searchText.value
      );

      this.searchFields.push(nameSearchField);
    } else if (
      !this.searchIntelNavbarService.checkExistingLabels(this.searchFields) &&
      !this.searchFields.length
    ) {
      if (
        label === SearchLabels.URL &&
        data.length > 1 &&
        !checkUrlForSpecificSubsequence(this.searchText.value)
      ) {
        label = data[1].value;
      }
      this.searchFields.push({
        value: this.searchText.value,
        label: label,
      });
    }
  }

  private getSearchTypeBaseUrl(url: string) {
    if (this.adintFlavourEnabled) {
      this.searchType = IntelSearchType.ADINT;

      return;
    }

    if (url === '/discovery' || url.startsWith('/discovery(modal')) {
      this.searchType = IntelSearchType.GE0;
    } else if (url.includes('adint')) {
      this.searchType = IntelSearchType.ADINT;
    } else {
      this.searchType = IntelSearchType.OSINT;
    }
  }

  public search(event: KeyboardEvent | PointerEvent, action: string): void {
    if (!this.webintDisabledService.handleWebintAvailability()) {
      return;
    }
    this.searchDrop = false;
    if (this.importFilters && (!action || action !== 'image-search')) {
      this.searchIntelService.searchBtnListner.next({
        event: event as PointerEvent,
        filters: this.importFilters,
        searchMode: IntelSearchMode.Search,
        navBarIdentifier: this.navBarIdentifier,
        skipLocate: true,
      });
    }
    this.handleIncomingInput({
      event,
      skipLocate: true,
      searchMode: IntelSearchMode.Search,
    });
    this.clearSearchInput();
  }

  public searchAndLocate(event: PointerEvent): void {
    if (!this.webintDisabledService.handleWebintAvailability()) {
      return;
    }
    this.searchDrop = false;
    this.handleIncomingInput({
      event,
      skipLocate: false,
      searchMode: IntelSearchMode.SearchAndLocate,
    });
    this.clearSearchInput();
    if (this.creditsMenuTrigger) {
      this.creditsMenuTrigger.closeMenu();
    }
  }

  private clearSearchInput(): void {
    this.searchText.setValue('');
    this.emitIntelNavbarClick.emit(false);
  }

  private createQuickQuery(telno: string): void {
    this.queryService.quickQuery([{ telno }]).subscribe({
      error: (err) => {
        this.queryService.handleQueryError(err);
      },
    });
  }

  public locate(): void {
    const validPhone = this.checkForValidMsisdn();
    if (!validPhone) {
      this.showMessage(
        this.translationService.interpolate(
          `Enter the complete phone number including country code. Example: #{example}`,
          {
            example:
              this.numberValidatorService.getRandomPhoneNumberForTenant(),
          }
        )
      );
      return;
    }

    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.QUERY_LOCATION,
      ])
    ) {
      this.resetSearchInputs();
      const telnos = [{ telno: <string>validPhone }];
      // if we locate from navbar while we are on new discovery module we should close the search navbar modal
      if (this.router.url.includes('/account/discovery')) {
        this.clickedOutside();
      }
      this.queryService
        .isUserOnDiscoveryV2()
        .subscribe((isUserOnDiscoveryV2) => {
          if (isUserOnDiscoveryV2) {
            this.queryService.locateDiscoveryV2(telnos).subscribe({
              next: () => {
                // add state on navigation to pass selected target telno in order to display target's details on new discovery
                this.router.navigate(['discovery'], {
                  state: { selectedTarget: { telno: telnos[0].telno } },
                });
              },
            });
          } else {
            this.createQuickQuery(<string>validPhone);
            this.router.navigate(['discovery']);
          }
        });
    }
  }

  /**
   * @param  {string} msg
   * @param  {} okText='OK'
   */
  showMessage(msg: string, okText = 'OK') {
    this.snackBar.open(msg, okText, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar'],
    });
  }

  searchByKeyword(): void {
    const searchArgs = [];
    searchArgs.push({ arg_type: 'hashtag', arg_value: this.searchInputValue });
    this.searchIntelService.keywordSearchInprogress.next({
      loading: true,
      value: this.searchInputValue,
    });
    this.searchIntelLoading = true;
    this.searchIntelService.createSearchIntelForPosts(searchArgs).subscribe(
      (search: SearchIntelModel) => {
        this.resetSearchInputs();
        this.searchIntelService.currentSearchIntel.next({
          search: search,
          noResultSearch: false,
        });
        this.searchIntelLoading = false;
        this.router.navigateByUrl(
          `/${ApplicationMainPageUrls.WEBINT}/post-results`
        );
      },
      (error) => {
        this.searchIntelLoading = false;
        this.showMessage(this.translationService.translate(error.message));
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  getIntelFilters(filters: SearchFilters): void {
    this.importFilters = filters;
    this.checkForSearchByKeyword();
  }

  private checkForSearchByKeyword(): void {
    if (this.importFilters && 'searchByKeyword' in this.importFilters) {
      this.resetSearchInputs();
    }
  }

  onKeywordsEntry(event: KeyboardEvent) {
    const shouldEnableSearchDrop =
      event.keyCode !== 13 ||
      (event.type !== 'click' && !this.searchText.value);

    if (shouldEnableSearchDrop) {
      this.searchDrop = true;
      return;
    }

    this.searchDrop = false;

    if (this.importFilters.searchByKeyword && this.searchFields.length === 1) {
      return;
    }

    if (this.importFilters.searchByKeyword) {
      this.searchFields.push({
        value: this.searchText.value,
        label: SearchLabels.HASHTAG,
      });
      this.searchText.reset();
      this.emitSearchFields.next(this.searchFields);
      event.stopPropagation();
      this.searchDrop = false;
    }
  }

  resetSearchInputs(): void {
    this.searchText.setValue('');
    this.searchFields = [];
    this.searchInputValue = '';
    this.emitQueryInfoData();
  }

  public hideTypingEffect() {
    this.typingEffect = false;
  }

  private emitQueryInfoData(): void {
    this.emitQueryInfo.emit({
      btnActive: this.btnActive,
      searchInputType: this.inputTypes,
      searchInputValue: this.searchInputValue,
    });
  }

  private hasNavbarSearchTriggered(event: KeyboardEvent | PointerEvent) {
    return (
      !!(('key' in event && event.key === 'Enter') || event.type === 'click') &&
      this.searchInputValue
    );
  }

  private removeSearchTextLeadingSpace(): void {
    const value: string = this.searchText.value;
    if (value && this.hasLeadingSpace(value)) {
      this.searchText.setValue(value.substring(1, value.length));
    }
  }

  private hasLeadingSpace(value: string | null): boolean {
    return !!value?.startsWith(' ');
  }

  private createDiscoveryQuery(phone: string): void {
    const telnos = [{ telno: phone }];
    this.queryService.isUserOnDiscoveryV2().subscribe((isUserOnDiscoveryV2) => {
      if (isUserOnDiscoveryV2) {
        this.queryService.locateDiscoveryV2(telnos).subscribe({
          next: () => {
            // add state on navigation to pass selected target telno in order to display target's details on new discovery
            this.router.navigateByUrl('/discovery', {
              state: { selectedTarget: { telno: telnos[0].telno } },
            });
          },
        });
      } else {
        this.createQuickQuery(phone);
        this.router.navigate(['discovery']);
      }
    });
  }
}
