import { OTPChannels } from 'src/app/components/user-settings/user-settings.models';
import { TransientUserWithJWTClaims } from './types';

class SessionData {
  current_user?: TransientUserWithJWTClaims = undefined;
  current_session_id?: string = undefined;
  total_sessions?: number = undefined;
  session_expires_at?: string = undefined;
  session_email?: string = undefined;
  token?: string = undefined;
}

class WarningData {
  request_2fa_enablement?: boolean = undefined;
  warning_password_will_expire_in_days?: number = undefined;
}

export class ErrorData {
  force_2fa_enablement?: boolean = undefined;
  otp_code_required?: boolean = undefined;
  otp_channel?: OTPChannels = undefined;
  otp_recipient?: string = undefined;
  request_password_change?: boolean = undefined;
  support_email_required?: boolean = undefined;
  can_reset_2fa?: boolean = undefined;
}

export interface SignInErrors extends ErrorData {
  message?: string;
}

export interface AuthResponse extends SessionData, WarningData, SignInErrors {}
