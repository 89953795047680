import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import {
  BehaviorSubject,
  filter,
  map,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AuthState } from '../authentication/auth-state.enum';
import { AuthService } from '../authentication/auth.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { UserSettingsService } from '../user/user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryVersionService {
  private readonly discoveryNavigationLinks = {
    oldDiscovery: '/discovery',
    newDiscovery: '/account/discovery/0',
  };
  private discoveryV2EnabledSrc: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  public discoveryV2Enabled$ = this.discoveryV2EnabledSrc.asObservable();
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private readonly userSettingsService: UserSettingsService,
    private readonly localStorageService: LocalStorageService,
    private appConfigService: AppConfigService,
    private authService: AuthService
  ) {
    this.authService.authState$
      .pipe(
        filter((val) => val != AuthState.unknown),
        tap((val) => {
          if (val === AuthState.authenticated) {
            this.init();
          } else if (val === AuthState.unauthenticated) {
            this.destroy();
          }
        })
      )
      .subscribe();
  }

  init(): void {
    this.appConfigService.authenticatedConfigLoaded$
      .pipe(
        filter(Boolean),
        map((configsLoaded) => {
          const discoveryV2EnabledInConfig =
            this.appConfigService.getConfigVariable('enableDiscoveryV2');
          if (!discoveryV2EnabledInConfig) {
            this.discoveryV2EnabledSrc.next(false);
            this.toggleDiscoveryModule(true, false);
            return;
          }

          const discoveryV2EnabledInSession =
            this.localStorageService.getDiscoveryV2Enabled();
          if (this.discoveryV2EnabledSrc.getValue() == null) {
            if (discoveryV2EnabledInSession == null) {
              // this run only once after login where discoveryV2Enabled not exists in local storage, after logout local storage is cleared
              this.discoveryV2EnabledSrc.next(true);
              this.toggleDiscoveryModule(false, false);
            } else {
              this.discoveryV2EnabledSrc.next(discoveryV2EnabledInSession);
              this.toggleDiscoveryModule(!discoveryV2EnabledInSession, false);
            }
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  destroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.discoveryV2EnabledSrc.next(null);
  }

  toggleDiscoveryModule(
    discoveryV2Enabled: boolean,
    redirectToDiscovery = true
  ): void {
    this.userSettingsService
      .setUserSettingsValue({ discoveryV2Enabled: !discoveryV2Enabled })
      .pipe(
        map((userSettings) => userSettings.discoveryV2Enabled),
        tap((discoveryV2Enabled) => {
          this.localStorageService.setShowDiscoveryOnboarding(
            discoveryV2Enabled
          );
          this.localStorageService.setDiscoveryV2Enabled(discoveryV2Enabled);
          const toggleActiveNavigationLink = discoveryV2Enabled
            ? this.discoveryNavigationLinks.newDiscovery
            : this.discoveryNavigationLinks.oldDiscovery;
          this.discoveryV2EnabledSrc.next(discoveryV2Enabled as boolean);
          if (
            [
              this.discoveryNavigationLinks.oldDiscovery,
              this.discoveryNavigationLinks.newDiscovery,
            ].some((url) => this.router.url.includes(url))
          ) {
            this.userSettingsService.discoveryV2Enabled.next(
              discoveryV2Enabled
            );
            if (!redirectToDiscovery) {
              return;
            }
            this.router.navigateByUrl(toggleActiveNavigationLink, {
              state: {
                showOnboarding:
                  toggleActiveNavigationLink ===
                  this.discoveryNavigationLinks.newDiscovery,
              },
            });
          }
        }),
        take(1)
      )
      .subscribe();
  }
}
