import { Injectable } from '@angular/core';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { User } from 'src/app/services/user/user.model';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class UsersUtilService {
  constructor(private translationService: TranslationService) {}
  async confirmEmailRecipientDialog(user: Partial<User>) {
    const { email } = user;
    if (!email) {
      return false;
    }
    const result = await Swal.fire<boolean>({
      text: this.translationService.interpolate(
        'The password will be sent to the email address #{email}. Is this address correct?',
        { email }
      ),
      confirmButtonText: this.translationService.translate('Confirm'),
      cancelButtonText: this.translationService.translate(
        'No, remove address from account'
      ),
      showCancelButton: true,
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: true,
    });
    return result.isConfirmed;
  }
  async displayTemporaryPasswordDialog(
    temporary_password: string,
    username: string
  ): Promise<SweetAlertResult<boolean>> {
    return Swal.fire<boolean>({
      title: this.translationService.interpolate(
        'Temporary password for #{username}',
        { username }
      ),
      text: temporary_password,
      confirmButtonText: this.translationService.translate('Confirm'),
    });
  }
}
