import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exclusion',
})
export class ExclusionPipe implements PipeTransform {
  transform(items: string[], exclusion: string[]): string[] {
    if (!Array.isArray(exclusion)) {
      return items;
    }

    if (!Array.isArray(items)) {
      return [];
    }

    return items.filter((item) => !exclusion.includes(item));
  }
}
