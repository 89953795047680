import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgIf } from '@angular/common';
import { FaProModule } from '@fe-platform/fa-pro';
import { TextareaSize } from './textarea.model';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'intellectus-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  standalone: true,
  imports: [FaProModule, FormsModule, NgIf],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent
  implements OnChanges, OnInit, ControlValueAccessor
{
  @Input() characterLimit = 0;
  @Input() disabled = false;
  @Input() errorMessage = '';
  @Input() hint = '';
  @Input() label = '';
  @Input() optionalLabel = '';
  @Input() placeholder = '';
  @Input() size: TextareaSize = TextareaSize.STANDARD;
  @Input() value = '';
  @Input() width = '';

  @Output() keyUp: EventEmitter<string> = new EventEmitter();
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  maxHeight = '180px';
  textareaValue = '';
  isOverflowing = false;
  remainingCharacters!: number;

  readonly textareaSizeEnum = TextareaSize;

  onChange!: (value: string) => void;
  onTouch!: (value: string) => void;

  get characterLimitHint() {
    return `${this.remainingCharacters} ${this.hint}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']?.currentValue) {
      this.textareaValue = changes['value']?.currentValue;
    }

    if (changes['characterLimit']?.currentValue) {
      this.remainingCharacters =
        changes['characterLimit'].currentValue - this.textareaValue.length;
    }

    if (changes['size']?.currentValue) {
      this.maxHeight = this.size === TextareaSize.SMALL ? '160px' : '180px';
    }
  }

  ngOnInit() {
    this.textareaValue = this.value;
  }

  onKeyUp(): void {
    this.keyUp.emit(this.textareaValue);
    this.updateRemainingCharacters();
    this.onChange(this.textareaValue);
    this.onTouch(this.textareaValue);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(newValue: string): void {
    this.textareaValue = newValue;
  }

  onValueChange(): void {
    this.valueChange.emit(this.textareaValue);
    this.updateRemainingCharacters();
  }

  adjustHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = 'auto';

    const newHeight = textarea.scrollHeight;

    if (newHeight <= parseInt(this.maxHeight, 10)) {
      textarea.style.height = `${newHeight}px`;
      this.isOverflowing = false;
    } else {
      textarea.style.height = this.maxHeight;
      this.isOverflowing = true;
    }
  }

  private updateRemainingCharacters() {
    if (this.characterLimit && this.characterLimit > 0) {
      this.remainingCharacters =
        this.characterLimit - this.textareaValue.length;
    }
  }
}
