import { Injector } from '@angular/core';

export class DependencyInjectionService {
  private static injector: Injector | null = null;

  static setInjector(injector: Injector) {
    DependencyInjectionService.injector = injector;
  }

  static getInjector(): Injector | null {
    return DependencyInjectionService.injector;
  }
}
