import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import { NotificationListService } from './notification-list.service';
@UntilDestroy()
@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  @Input()
  public isMobile: boolean;
  isMobileResolution: boolean;
  notificationIdsForRead = [];
  notificationIdsForDelete = [];
  skin;
  isGeolocTheme = false;
  isWhiteTheme = false;
  theme: string;
  notificationsCount = 0;
  sortedNotifications$ = this.notificationService.messages$.pipe(
    map((notifications) => {
      return notifications.sort((a, b) => {
        const dateA = `${a.createdAt}`;
        const dateB = `${b.createdAt}`;
        return new Date(dateB).getTime() - new Date(dateA).getTime();
      });
    })
  );
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };

  constructor(
    public dialog: MatDialog,
    public notificationService: NotificationService,
    public notificationListService: NotificationListService,
    private applicationStateService: ApplicationStateService,
    private appConfigService: AppConfigService
  ) {
    this.isMobileResolution =
      this.applicationStateService.getIsMobileResolution();
    this.skin = this.applicationStateService.getSkin();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    this.isGeolocTheme = this.theme === 'GEOLOC';
    this.isWhiteTheme = this.theme === 'WHITE';

    this.notificationListService
      .getNotificationMessages()
      .pipe(untilDestroyed(this))
      .subscribe((notificationsData) => {
        this.notificationIdsForRead = notificationsData.idsForRead;
        this.notificationIdsForDelete = notificationsData.idsForDelete;
        this.notificationsCount = notificationsData.count;
      });
  }

  clearNotifications() {
    if (this.notificationIdsForDelete.length) {
      this.notificationService.clearNotifications();
    }
  }

  markAllRead() {
    if (this.notificationIdsForRead.length) {
      this.notificationService.markNotificationsAsRead(
        this.notificationIdsForRead
      );
    }
  }
}
