import { AvatarActivityLog } from 'src/app/modules/data-layer/models/avatar/avatar-activity-log';
import { AvatarActivityLogState } from 'src/app/modules/data-layer/models/avatar/avatar-activity-log-state.enum';
import { AvatarSocialType } from 'src/app/modules/data-layer/models/avatar/avatar-social-type.enum';
import { BaseDTO } from '../base';

export class AvatarActivityLogDTO extends BaseDTO<
  AvatarActivityLog,
  AvatarActivityLogDTO
> {
  private activity: AvatarActivityLogState;
  private social: AvatarSocialType;
  private startedAt: string;
  private completedAt: string;

  constructor(model: Partial<AvatarActivityLogDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: AvatarActivityLog): AvatarActivityLogDTO {
    return this;
  }

  toModel(): AvatarActivityLog {
    const activityLog: AvatarActivityLog = new AvatarActivityLog({
      activity: AvatarActivityLogState[this.activity],
      social: this.social,
      startedAt: new Date(this.startedAt),
      completedAt: new Date(this.completedAt),
    });

    return activityLog;
  }
}
