import {
  CheckIn,
  CheckInMedia,
} from 'src/app/modules/search-intel/models/search-intel.model';
import { Media, Person } from 'datalayer/models';
import { DataSource, EntityType } from 'datalayer/models/platform-models';
import { v4 } from 'uuid';
import { PlatformImages } from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';

export function getMediaFromGoogleCheckin(
  person: Person,
  checkIn: CheckIn,
  image: CheckInMedia,
  targetId: string
): Media {
  const googleID: string = person.sourceEntity.id.split('@')[0];
  const sourceUrl = `https://www.google.com/maps/contrib/${googleID}`;

  return new Media({
    description: checkIn.review,
    title: checkIn.name,
    url: image.image_url as string,
    thumbnailUrl: image.image_url as string,
    sourceEntity: person.sourceEntity,
    source: DataSource.Google,
    targetId,
    id: v4(),
    publishedAt: checkIn.visited_at,
    sourceUrl,
    type: EntityType.Photo,
    platformImage: PlatformImages.GG,
  });
}
