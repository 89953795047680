import { Injectable } from '@angular/core';
import { ImProfile } from 'datalayer/models/platform-models/im-profiles/im-profile';
import {
  ImProfilesReqArgsList,
  OsintService,
} from 'datalayer/services/osint/osint.service';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, mergeAll, take, tap } from 'rxjs/operators';
import { PlatformImages } from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';
import { BaseStore } from 'src/app/shared/classes/base-store.class';
import { Platform } from 'src/app/shared/schemas/common/platforms';
import { normalizeToArray } from 'src/app/shared/util/helper';

@Injectable({
  providedIn: 'root',
})
export class InstantMessagesStore extends BaseStore<{
  [key: string]: ImProfile[];
}> {
  constructor(private osintService: OsintService) {
    super({});
    this.stream();
  }

  private platformsToFetch = [
    Platform.WHATSAPP,
    Platform.TELEGRAM,
    // FIXME: TEMPORARY disable fetching IM-profile for truecaller platform (INT-10665)
    // Platform.TRUECALLER,
    Platform.CallerID,
    Platform.GetContact,
  ];
  private fetchingStream$ = new Subject<Observable<ImProfile[]>>();
  private pendingReqs: { [key: string]: boolean } = {};

  public isEmpty(): boolean {
    return Object.keys(this.value).length > 0;
  }

  /**
   *
   * @param msisdn string
   * @param queryId string
   * @param skipCache boolean
   */
  public fetchAllImPlatforms(
    msisdn: string,
    queryId: string = null,
    skipCache = false
  ): Observable<ImProfile[]> {
    const args: ImProfilesReqArgsList[] = this.platformsToFetch
      .filter((pl) => pl !== Platform.TELEGRAM && pl !== Platform.TRUECALLER)
      .map((pl) => ({
        arg_value: msisdn,
        arg_type: 'telno',
        platform: pl.toString(),
      }));

    this.fetchImProfile(args, queryId, msisdn, skipCache);

    return this.filterByMsisdn(msisdn);
  }

  /**
   *
   * @param msisdn string
   */
  public filterByMsisdn(msisdn: string): Observable<ImProfile[]> {
    return this.list$.pipe(map((v) => v[msisdn]));
  }

  /**
   *
   * @param platform Platform
   */
  getPlatformImage(platform: Platform) {
    switch (platform) {
      case Platform.TELEGRAM:
        return PlatformImages.TG;

      case Platform.WHATSAPP:
        return PlatformImages;

      case Platform.SKYPE:
        return PlatformImages.SK;

      case Platform.TRUECALLER:
        return PlatformImages.TC;
    }
  }

  /**
   *
   * @param imProfiles ImProfile[]
   */
  private updateImProfile(imProfiles: ImProfile[] | ImProfile): void {
    imProfiles = normalizeToArray(imProfiles) as ImProfile[];

    if (imProfiles?.length === 0) {
      return;
    }

    const curVal = this.value;
    const telno = imProfiles[0].telno;

    this.update({
      ...curVal,
      [telno]: imProfiles,
    });
  }

  /**
   *
   * @param args ImProfilesArgsList[]
   * @param queryId string
   * @param msisdn string
   * @param skipCache boolean
   * @private
   */
  private fetchImProfile(
    args: ImProfilesReqArgsList[],
    queryId: string,
    msisdn: string,
    skipCache: boolean
  ): void {
    const isPending = this.pendingReqs[msisdn];
    const isAlreadyFetched = this.isAlreadyFetched(msisdn);

    if (!skipCache && isPending) {
      return;
    }

    if (!skipCache && isAlreadyFetched) {
      return;
    }

    const obs = this.osintService.fetchInstantMessagesProfile(
      args,
      queryId
    ) as Observable<ImProfile[]>;

    this.addToFetchingStream(
      obs.pipe(
        take(1),
        tap((_) => (this.pendingReqs[msisdn] = false)),
        catchError((e) => {
          return EMPTY;
        })
      ),
      msisdn
    );
  }

  /**
   *
   * @param obs Observable<ImProfile>
   * @param msisdn string
   * @private
   */
  private addToFetchingStream(
    obs: Observable<ImProfile[]>,
    msisdn: string
  ): void {
    this.pendingReqs[msisdn] = true;

    this.fetchingStream$.next(obs);
  }

  private stream(): void {
    this.fetchingStream$.pipe(mergeAll()).subscribe((data: ImProfile[]) => {
      this.updateImProfile(data);
    });
  }

  /**
   *
   * @param msisdn string
   * @private
   */
  private isAlreadyFetched(msisdn: string): ImProfile[] {
    return this.value[msisdn];
  }
}
