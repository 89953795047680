import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  FormControlStatus,
  Validators,
} from '@angular/forms';
import { BaseComponent } from 'src/app/base/base.component';
import { OTPChannels } from 'src/app/components/user-settings/user-settings.models';
import { User } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { isValidPhoneFormValidator } from '../../validators/phone-number';
import { InputStatus } from './../../../components/user-settings/user-settings.models';

@Component({
  selector: 'app-otp-selection-radio',
  templateUrl: './otp-selection-radio.component.html',
  styleUrls: ['./otp-selection-radio.component.scss'],
})
export class OtpSelectionRadioComponent
  extends BaseComponent
  implements OnInit
{
  private readonly emailPattern = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$';

  @Input() mandatoryEnablement?: boolean = true;
  @Input() enableEdit = false;
  otpChannel: OTPChannels;
  channels = OTPChannels;
  phoneControl = new UntypedFormControl('', [
    Validators.required,
    isValidPhoneFormValidator(),
  ]);
  emailControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(this.emailPattern),
  ]);
  showPhoneInput = false;
  showEmailInput = false;
  @Output() channelChange = new EventEmitter<string>();
  @Output() visibilityChange = new EventEmitter<boolean>();
  @Output() phoneChange = new EventEmitter<InputStatus>();
  @Output() emailChange = new EventEmitter<InputStatus>();
  currentUser: User;
  radioVisibility?: boolean = false;

  constructor(
    private userService: UserService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.userService.getCurrentUser().subscribe((user) => {
        this.currentUser = user;
        this.changeRadioVisibility(
          this.mandatoryEnablement || user?.otpEnabled
        );
        this.cdRef.markForCheck();
      }),
      this.phoneControl.statusChanges.subscribe((val: FormControlStatus) => {
        if (val !== 'VALID') {
          if (this.otpChannel === OTPChannels.OtpPhone) {
            this.otpChannel = OTPChannels.None;
            this.changeChannel(this.otpChannel);
          }
        }

        this.phoneChange.emit({
          value: this.phoneControl.value,
          valid: val === 'VALID',
        });
      }),
      this.emailControl.statusChanges.subscribe((val: FormControlStatus) => {
        if (val !== 'VALID') {
          if (this.otpChannel === OTPChannels.Email) {
            this.otpChannel = OTPChannels.None;
            this.changeChannel(this.otpChannel);
          }
        }

        this.emailChange.emit({
          value: this.emailControl.value,
          valid: val === 'VALID',
        });
      })
    );
  }

  changeChannel($event: OTPChannels): void {
    this.channelChange.emit($event);
  }

  changeRadioVisibility(status = !this.radioVisibility) {
    this.radioVisibility = status;
    this.visibilityChange.emit(this.radioVisibility);
  }

  removeEmailInput(): void {
    this.showEmailInput = false;
    this.emailControl.reset();
  }

  removePhoneInput(): void {
    this.showPhoneInput = false;
    this.phoneControl.reset();
  }
}
