import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '@app/config';
import { BaseComponent } from 'src/app/base/base.component';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';
import { StatisticsService } from 'src/app/services/statistics/statistics.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent extends BaseComponent implements OnInit {
  isAdmin = false;
  isSupport = false;
  isPower = false;
  showUsers = false;
  usernames: string[];
  selectedUser;
  uniqueEntries = true;
  showLoader = false;
  statistics = [];
  grafanaDashboardUrl = '';
  isGrafanaDashboardEnabled = false;

  periods = [
    {
      label: 'Last hour',
      value: 1,
    },
    {
      label: 'Last 24 hours',
      value: 24,
    },
    {
      label: 'Last week',
      value: 168,
    },
  ];
  selectedPeriod = this.periods[1].value;
  periodLabel = this.periods[1].label;
  allQueriescharts = [];
  providersCharts = [];

  constructor(
    private roleManagementService: RoleManagementService,
    private userService: UserService,
    private statisticsService: StatisticsService,
    private translationService: TranslationService,
    private appConfigService: AppConfigService
  ) {
    super();

    this.grafanaDashboardUrl = environment.grafanaDashboardUrl;
    this.isGrafanaDashboardEnabled = this.appConfigService.getConfigVariable(
      'enableGrafanaDashboard'
    );
  }

  ngOnInit() {
    this.isAdmin = this.roleManagementService.userIsAdmin();
    this.isSupport = this.roleManagementService.userIsSupportUser();
    this.isPower = this.roleManagementService.userIsPowerUser();
    this.filter();

    const getUsersSubscription = this.userService
      .getAllUsernames()
      .subscribe((usernames: string[]) => {
        this.usernames = usernames;
      });

    this.subscriptions.push(getUsersSubscription);
  }

  onTabChange(event) {
    this.showUsers = event.index === 0 ? false : true;
  }

  filter() {
    this.showLoader = true;
    this.providersCharts = [];
    this.allQueriescharts = [];
    this.statistics = [];
    const dateRange = [
      new Date(new Date().getTime() - this.selectedPeriod * 1000 * 60 * 60),
      new Date(),
    ];
    this.statisticsService
      .filter(dateRange, this.selectedUser, this.uniqueEntries)
      .subscribe((statistics) => {
        this.showLoader = false;
        if (statistics.length) {
          this.statistics = this.formatAllData(statistics);
          this.createAllQueriesCharts(statistics);
          this.createProvidersCharts();
        }
      });
  }

  createAllQueriesCharts(statistics) {
    this.allQueriescharts = [
      {
        name: 'found',
        header: 'ALL QUERIES FOUND',
        type: 'PieChart',
        options: {
          colors: [
            '#c4d839',
            '#33691e',
            '#00bfa5',
            '#673fb4',
            '#ffd700',
            '#2b98f0',
            '#01e5ff',
            '#f44336',
            '#d9dd81',
            '#ff9801',
            '#795548',
          ],
        },
        data: this.formatAllQueriesChartsData(statistics[0]),
        width: 600,
        height: 400,
      },
      {
        name: 'unreachable',
        header: 'ALL UNREACHABLE QUERIES',
        type: 'PieChart',
        options: {
          colors: [
            '#c4d839',
            '#33691e',
            '#00bfa5',
            '#673fb4',
            '#ffd700',
            '#2b98f0',
            '#01e5ff',
            '#f44336',
            '#d9dd81',
            '#ff9801',
            '#795548',
          ],
        },
        data: this.formatAllQueriesChartsData(statistics[1]),
        width: 600,
        height: 400,
      },
    ];
  }

  createProvidersCharts() {
    for (const provider of this.statistics) {
      const chart = {
        header: provider.providerName,
        type: 'PieChart',
        options: {
          colors: ['#97a3a8', '#314a46'],
        },
        data: [
          [this.translationService.translate('Found'), provider.found],
          [
            this.translationService.translate('Unreachable'),
            provider.unreachable,
          ],
        ],
        width: 600,
        height: 400,
      };
      this.providersCharts.push(chart);
    }
  }

  formatAllQueriesChartsData(data) {
    const results = [];
    Object.entries(data).forEach(([key, value]) => {
      results.push([key, value]);
    });
    return results;
  }

  setPeriodLabel(event) {
    switch (event.value) {
      case 1:
        this.periodLabel = 'Last hour';
        break;
      case 24:
        this.periodLabel = 'Last 24 Hours';
        break;
      case 168:
        this.periodLabel = 'Last week';
        break;
      default:
        this.periodLabel = '';
    }
  }

  formatAllData(data) {
    const results = [];
    // format found
    Object.entries(data[0]).forEach(([key, value]) => {
      const result = {
        providerName: key,
        found: value,
        unreachable: 0,
      };
      results.push(result);
    });
    // format unreachable
    Object.entries(data[1]).forEach(([key, value]) => {
      for (const entry of results) {
        if (key === entry.providerName) {
          entry.unreachable = value;
          return;
        }
      }
      const result = {
        providerName: key,
        found: 0,
        unreachable: value,
      };
      results.push(result);
    });
    return results;
  }

  openGrafanaDashboard() {
    window.open(this.grafanaDashboardUrl, '_blank');
  }
}
