export enum SearchIntelFields {
  searching_social_profiles = 'Searching social profiles',
  searching_instant_message_profiles = 'Searching instant message profiles',
  searching_dark_web = 'Searching dark web',
  searching_open_web = 'Searching open web',
  searching_closed_databases = 'Searching closed databases',
  searching_social_databases = 'Searching open databases',
  analyzing_results = 'Analyzing Results',
  searching_social_media = 'Searching social media',
}
