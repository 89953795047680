import { Injectable } from '@angular/core';
import { cloneDeep, flatten } from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';
import { bufferTime, filter, map, switchMap } from 'rxjs/operators';
import { BaseStore } from 'src/app/shared/classes/base-store.class';
import {
  IntelResults,
  ResultsSmartFilter,
  SelectedSmartFilter,
} from '../models/intel-results-view.model';

@Injectable({ providedIn: 'root' })
export class IntelResultsStore extends BaseStore<IntelResults[]> {
  correlationId: string;
  private filters = new BehaviorSubject<SelectedSmartFilter[]>([]);
  filters$ = this.filters.asObservable();
  private filteredEntityStream: Subject<IntelResults[]> = new Subject();
  filteredStream$ = this.filteredEntityStream.asObservable().pipe(
    bufferTime(1000),
    switchMap((x) => x),
    filter((v) => (Array.isArray(v) ? v.length > 0 : true)),
    map((batch) => {
      if (Array.isArray(batch)) {
        return flatten(batch);
      }
      return batch;
    })
  );

  constructor() {
    super([]);
  }

  public reset() {
    this.value = [];
  }

  public isEmpty(): boolean {
    return this.value?.length === 0;
  }

  public append(queryResult: IntelResults) {
    this.value = { ...this.value, ...queryResult };
    this.filteredEntityStream.next(this.value);
  }

  public removeSearchId(searchId: string) {
    this.value = this.value.filter((i) => i.searchId !== searchId);
    this.filteredEntityStream.next(this.value);
  }

  public updateBySearchId(queryResult: IntelResults, searchId) {
    const index = this.value.findIndex((intel) => intel.searchId === searchId);
    if (index !== -1) {
      this.value[index] = { ...this.value[index], ...queryResult };
    } else {
      this.value.push(...[queryResult]);
    }
    this.filteredEntityStream.next(this.value);
  }

  public filter(filters: ResultsSmartFilter, RESET = false) {
    if (RESET) {
      this.setRefindSmartFilters(filters.id, undefined);
    } else {
      this.setRefindSmartFilters(filters.id, filters.filters);
    }
    const entities = cloneDeep(this.value);
    const persons: string[] = this.flatternPersons(filters.filters);
    const index = this.value.findIndex((i) => i.searchId === filters.id);
    const filterValues = filters.filters;

    if (filterValues.filter((i) => i.id === 'TargetFilter').length > 0) {
      persons.push(
        ...entities[index].persons
          .filter((i) => i.targets.length > 0)
          .map((p) => this.head(p.mergedPersonKeys))
      );
    }
    if (persons.length) {
      entities[index].persons = entities[index].persons.filter((i) =>
        persons.includes(this.head(i.mergedPersonKeys))
      );
    }
    this.filteredEntityStream.next(entities);
  }

  public setRefindSmartFilters(
    searchId: string,
    smartFilters: SelectedSmartFilter[]
  ): void {
    const index = this.value.findIndex((intel) => intel.searchId === searchId);
    if (index !== -1) {
      this.value[index].filteredSmartFilters = smartFilters?.length
        ? JSON.parse(JSON.stringify(smartFilters))
        : smartFilters;
    }
  }

  clear() {
    this.value = [];
  }

  private flatternPersons(arrayValues) {
    return Array.prototype.concat.apply(
      [],
      arrayValues.map((i) => Array.prototype.concat.apply([], i.persons))
    );
  }

  public head = (arr) => (Array.isArray(arr) ? arr[0] : undefined);
}
