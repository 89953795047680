import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import {
  Investigation,
  InvestigationRequest,
} from 'src/app/shared/models/investigations.model';
import { camelToSnakeCase } from 'src/app/shared/util/helper';

@Injectable({
  providedIn: 'root',
})
export class ApiQuestionnaireService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  getInvestigation(): Observable<{ result: Investigation | null }> {
    return this.httpClient
      .get<any>(`${this.fastAPIurl}/investigations/active`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  postInvestigation(
    requestbody: InvestigationRequest
  ): Observable<{ result: Investigation | null }> {
    const investigationRequestBody: object = {};
    Object.keys(requestbody).forEach(
      (key) =>
        (investigationRequestBody[camelToSnakeCase(key)] = requestbody[key])
    );

    return this.httpClient
      .post<any>(`${this.fastAPIurl}/investigations`, investigationRequestBody)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  updateInvestigation(
    investigationId: string,
    requestbody: Partial<Investigation>
  ): Observable<{ result: Investigation | null }> {
    const investigationRequestBody: object = {};
    Object.keys(requestbody).forEach(
      (key) =>
        (investigationRequestBody[camelToSnakeCase(key)] = requestbody[key])
    );

    return this.httpClient
      .put<any>(
        `${this.fastAPIurl}/investigations/${investigationId}`,
        investigationRequestBody
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  deleteInvestigation(
    investigationId: string
  ): Observable<{ result: { deleted: string } }> {
    return this.httpClient
      .delete<any>(`${this.fastAPIurl}/investigations/${investigationId}`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }
}
