export const DiscoveryColorClassToColorHex: { [id: string]: string } = {
  c1: '#1FD0BF',
  c2: '#EB648B',
  c3: '#F8C753',
  c4: '#8A31C4',
  c5: '#D5001A',
  c6: '#A93790',
  c7: '#42B3D5',
  c8: '#049587',
  c9: '#1553B6',
  c10: '#EB7E30',
  c11: '#8B572A',
  c12: '#2F8DFA',
  default: '#1fd0bf',
};
