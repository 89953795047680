import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ImageType } from 'src/app/shared/models/image-type.enum';

@Directive({
  selector: '[reportSectionAttribute]',
})
export class ReportAttributeDirective<T_SECTION_IDENTIFIER> implements OnInit {
  @Input() public reportSectionAttribute: string;
  @Input() public toImageType: ImageType = ImageType.JPG;
  @Input() public sectionIdentifier: T_SECTION_IDENTIFIER = null;
  @Input() public ignoreClassElements?: string[] = [];
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'section',
      this.reportSectionAttribute
    );
    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'toImageType',
      this.toImageType
    );
    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'sectionIdentifier',
      this.sectionIdentifier as unknown as string
    );
  }

  getElementRef(): ElementRef {
    return this.elRef;
  }
}
