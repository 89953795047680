<p *ngIf="showMessageWithOldChannel; else simpleMessages" class="body-desc">
  {{ userSelectionMessage | translate }}: &ensp;
  <strong>
    {{
      otpRecipient
        ? otpRecipient
        : otpChannel === channels.Email
        ? currentUser?.email
        : currentUser?.otpPhone
    }}
  </strong>
</p>

<ng-template #simpleMessages>
  <p class="body-desc">
    {{
      'We sent a verification code to the ' + otpChannelLabel[otpChannel] + ':'
        | translate
    }}
    &ensp;
    <strong>
      {{
        otpRecipient
          ? otpRecipient
          : otpChannel === channels.Email
          ? currentUser?.email
          : currentUser?.otpPhone
      }}
    </strong>
  </p>
</ng-template>

<div class="form-wrp">
  <div class="field-group">
    <label class="assistant-font">{{
      'Enter the code received via ' +
        (otpChannel === channels.Email ? 'email' : 'SMS') | translate
    }}</label>
    <div class="relative">
      <mat-form-field class="input-round">
        <input
          data-qa="OTP_CODE_INPUT"
          class="assistant-font"
          matInput
          [type]="visibility ? 'text' : 'password'"
          [formControl]="otpControl"
          required
        />
        <intellectus-icon-text-button
          class="eye-icon"
          [buttonIcon]="
            this.visibility
              ? fAavailableIcons.eyeVisible
              : fAavailableIcons.eyeHidden
          "
          [matTooltip]="'Show code' | translate"
          buttonSize="xsmall"
          (buttonClick)="this.visibility = !this.visibility"
        >
        </intellectus-icon-text-button>
      </mat-form-field>
    </div>
  </div>
  <mat-error
    data-qa="OTP_ERROR"
    class="activate-otp-modal-error"
    *ngIf="errorMsg"
    >{{ errorMsg | translate }}</mat-error
  >
</div>
