import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { Observable, of, Subscriber } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { FinishedHttpCacheService } from './finished-http-cache.service';
import { PendingHttpCacheService } from './pending-http-cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(
    private finishedCacheService: FinishedHttpCacheService,
    private pendingCacheService: PendingHttpCacheService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.requestIsCacheable(req)) {
      return next.handle(req);
    }

    const key = this.buildKey(req);

    const cachedResponse: HttpResponse<any> =
      this.finishedCacheService.get(key);
    if (cachedResponse) {
      return of(cloneDeep(cachedResponse)).pipe(delay(100));
    }

    const requestIsInFlight = this.pendingCacheService.get(key) !== undefined;
    if (requestIsInFlight) {
      const toReturn: Observable<HttpEvent<unknown>> = new Observable<
        HttpEvent<unknown>
      >((observer) => {
        const subscribers = this.pendingCacheService.get(key);

        if (undefined === subscribers) {
          this.handleCacheIntegrityFailure(req, next, observer);

          return;
        }

        subscribers.push(observer);
      });
      return toReturn;
    }

    this.pendingCacheService.put(key, []);

    const toReturn: Observable<HttpEvent<unknown>> = new Observable<
      HttpEvent<unknown>
    >((observer) => {
      const subscribers = this.pendingCacheService.get(key);

      if (!subscribers) {
        this.handleCacheIntegrityFailure(req, next, observer);

        return;
      }

      subscribers.push(observer);

      next.handle(req).subscribe({
        next: (event) => {
          const subscribers = this.pendingCacheService.get(key);
          if (event instanceof HttpResponse) {
            this.finishedCacheService.put(key, event);
          }

          if (this.handleNoSubscribersError(subscribers, key, event)) {
            return;
          }

          for (const subscriber of subscribers) {
            subscriber.next(cloneDeep(event));
          }
        },
        complete: () => {
          const subscribers = this.pendingCacheService.get(key);
          this.pendingCacheService.remove(key);

          if (this.handleNoSubscribersError(subscribers, key)) {
            return;
          }

          for (const subscriber of subscribers) {
            subscriber.complete();
          }
        },
        error: (error) => {
          const subscribers = this.pendingCacheService.get(key);
          this.pendingCacheService.remove(key);

          if (this.handleNoSubscribersError(subscribers, key)) {
            return;
          }

          for (const subscriber of subscribers) {
            subscriber.error(error);
          }
        },
      });
    });

    return toReturn;
  }

  private handleCacheIntegrityFailure(req, next, observer) {
    console.warn(
      'Item was in cache when we first checked it, but now, when this is evaluated, it was moved out of the cache best we can do is try again with the same request from the beginning'
    );
    this.intercept(req, next).pipe(tap(observer)).subscribe();
  }

  private requestIsCacheable(req: HttpRequest<any>) {
    return req.method === 'GET';
  }

  private buildKey(req: HttpRequest<any>) {
    const headers: { [headerName: string]: string } = {};
    req.headers
      .keys()
      .sort()
      .forEach(
        (headerName) => (headers[headerName] = req.headers.get(headerName))
      );

    const key = `${JSON.stringify(headers)};${req.urlWithParams}`;
    return key;
  }

  private handleNoSubscribersError(
    subscribers: Subscriber<HttpEvent<unknown>>[],
    key: string,
    event?: HttpEvent<unknown>
  ): boolean {
    if (undefined === subscribers) {
      console.error(
        'Expected to have some subscribers for pending cache.',
        key,
        event
      );
      return true;
    }
  }
}
