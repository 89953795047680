import { Injectable } from '@angular/core';
import { KEYS_ARRAY } from '../constants/constant';

@Injectable({
  providedIn: 'root',
})
export class CdrQueryParamsService {
  private data: any = null;

  formatAndStoreData(queryParams: any): void {
    const formattedQueryParams = {};

    for (const key of KEYS_ARRAY) {
      if (queryParams[key]) {
        formattedQueryParams[key] = Array.isArray(queryParams[key])
          ? queryParams[key]
          : [queryParams[key]];
      }
    }

    if (queryParams.returnUrl) {
      formattedQueryParams['returnUrl'] = queryParams.returnUrl;
    }

    this.data = { queryParams: formattedQueryParams };
  }

  getData() {
    return this.data;
  }
}
