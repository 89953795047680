import { AvailableConcurrentCredits, CreditGaugeColors } from '../gauge-types';
export const createAvailableCreditBalance = (
  width: number,
  label: string,
  currentBalance: number,
  maxBalance: number,
  sublabel?: string
): AvailableConcurrentCredits => {
  return {
    label,
    currentBalance,
    maxBalance,
    sublabel,
    width: width + '%',
    color: setBarBackgroundColor(width),
  };
};

const setBarBackgroundColor = (width: number): CreditGaugeColors => {
  if (width > 40 && width <= 100) {
    return CreditGaugeColors.GREEN;
  } else if (width > 20 && width <= 40) {
    return CreditGaugeColors.ORANGE;
  } else if (width >= 10 && width <= 20) {
    return CreditGaugeColors.REDWARNING;
  } else {
    return CreditGaugeColors.RED;
  }
};
