import { Tracker } from '../../shared/models/Tracker.model';
import { Pagination } from './Pagination.model';
import { Query } from './Query.model';

export interface QueriesHistoryAPI extends Pagination {
  docs: QueryHistory[] | null;
}

export interface QueriesHistoryAPIParams {
  page_size: number;
  page: number;
  sort_by: string;
  sort_order: 'asc' | 'desc' | '';
  msisdn_list?: string[];
  imsi_list?: string[];
  imei?: string;
  mcc?: string;
  network?: string;
  from?: number;
  to?: number;
  search?: string;
  calculateInsights: boolean;
}

export interface QueryHistory {
  msisdn: string;
  imsi: string;
  queries: Query[];
  queriesFiltered?: Query[];
  target_alias: string;
  target_id: string;
  total_queries: number;
  total_successful: number;
  total_roaming: number;
  imsis: string[];
  imeis: string[];
  devices: string[];
  number_of_locations: any;
  country: CountryResponse;
  network: string;
  date: number;
  last_known_location: {
    id: string;
    type: string;
    coordinates: [number, number];
    confidence: number;
    antenna_location_sources: any;
  };
  instant_message_profiles: Array<any>;
  active_tracker: Tracker;
  label: LabelResponse;
  createdBy: string;
  createdByList: string[];
}

export interface IMProfile {
  image: string;
  names: Array<string>;
  photos: Array<{ image: string; thumbnail: string; source: string }>;
  platform: string;
  source_url: string;
  status: string;
  telno: string;
  thumbnail: string;
  usernames: Array<string>;
  /**
   * @deprecated use @param lastSeen instead.
   */
  last_seen?: string;
  lastSeen?: string;
}

export interface CountryResponse {
  hasLocation: any;
  name: string;
  code: number;
  mcc: number;
  alpha2Code: string;
  alpha3Code: string;
}

export interface LabelResponse {
  id: string;
  label: string;
}

export interface LabelRequest {
  tag: string;
}

export enum QueryHistoryListActionsKeys {
  AddToMission = 'addToMission',
  Locate = 'locate,',
  ManageLabel = 'manageLabel,',
  Terminate = 'terminateTracker',
  ViewTrackerInfo = 'viewTrackerInfo',
}

export const defaultHistoryAPIParams: QueriesHistoryAPIParams = {
  page_size: 9,
  page: 1,
  sort_by: 'LATEST_SEARCH_DATE',
  sort_order: 'desc',
  calculateInsights: false,
};
