import { Person, Profile } from 'datalayer/models';
import { DataSource, EntityType } from 'datalayer/models/platform-models';
import { environment } from 'environment/environment';

export function personToProfile(person: Person, targetId: string): Profile {
  return new Profile({
    type: EntityType.Profile,
    sourceEntity: person.sourceEntity,
    targetId: targetId,
    name: person.names[0],
    source: DataSource.Google,
    image: person.image,
    profileId: person.personId,
    imageUrl: person.image
      ? `${environment.fileManagerUri}${person.image.url}`
      : '',
    id: person.id,
    email: person.emails?.length ? person.emails[0] : undefined,
    username: person.usernames.length ? person.usernames[0] : undefined,
    url: person.urls?.length ? person.urls[0] : undefined,
    telno: person.telnos?.length ? person.telnos[0] : undefined,
  });
}
