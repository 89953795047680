import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'intellectus-state-indicator',
  templateUrl: './state-indicator.component.html',
  styleUrls: ['./state-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateIndicatorComponent {
  @Input() isLoading = false;
  @Input() stateText = '';
}
