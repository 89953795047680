import { Circle, Polygon } from 'src/app/modules/mapV2/models/map.models';
import { ProfilerIconMarker } from './profiler-icon-marker';

export class TargetLocationIconMarker extends ProfilerIconMarker {
  constructor(model: Partial<TargetLocationIconMarker>) {
    super(model);
    Object.assign(this, model);
  }
  sectors: Polygon[];
  circles: Circle[];
}
