import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { Themes } from 'src/app/shared/models/skins.model';
import { AppConfigService } from 'src/app/providers/app-config.service';

import {
  BillingActions,
  BillingActionType,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { BillingService } from 'src/app/services/billing/billing.service';
import { RecommendationResponseBody } from 'src/app/shared/modules/recommendations/models/socket-responses.interface';
import { Observable } from 'rxjs';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';

@Component({
  selector: 'app-recommendations-popup',
  templateUrl: './recommendations-popup.component.html',
  styleUrls: ['./recommendations-popup.component.scss'],
})
export class RecommendationsPopupComponent
  extends BaseComponent
  implements OnInit
{
  public billingPlan$: Observable<
    BillingPlan<BillingActions, BillingActionType>
  >;

  constructor(
    private appConfigService: AppConfigService,
    private billingService: BillingService,
    private userBillingService: UserBillingService
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  @Input() recommendations: RecommendationResponseBody;
  @Input() telno: string;

  @Output() import = new EventEmitter<{
    telno: string;
    selectedDays: string[];
  }>();

  selectedDays: string[] = [];
  theme: Themes;

  ngOnInit(): void {
    this.billingPlan$ = this.billingService.getBillingPlan().asObservable();
  }

  onImport(cost: number) {
    if (cost === 0) {
      this.import.emit({ telno: this.telno, selectedDays: this.selectedDays });
    } else if (
      this.userBillingService.userHasEnoughCredits(
        this.selectedDays.map(() => BillingActions.IMPORT_HISTORICAL_LOCATIONS)
      )
    ) {
      this.import.emit({ telno: this.telno, selectedDays: this.selectedDays });
    }
  }

  onDatePickerSelect(days: string[]) {
    this.selectedDays = days;
  }
}
