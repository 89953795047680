import { Injectable } from '@angular/core';
import { Avatar } from 'datalayer/models/avatar';
import { AvatarActivityLog } from 'datalayer/models/avatar/avatar-activity-log';
import { AvatarActivityTask } from 'datalayer/models/avatar/avatar-activity-task';
import { AvatarPerson } from 'datalayer/models/avatar/avatar-person';
import { AvatarRemoteView } from 'datalayer/models/avatar/avatar-remote-view';
import { AvatarRemoteViewStatus } from 'datalayer/models/avatar/avatar-remote-view-status.enum';
import { AvatarSocialType } from 'datalayer/models/avatar/avatar-social-type.enum';
import {
  BaseService,
  DataChangeNotification,
  EmptyCacheService,
  RequestOptions,
} from 'datalayer/services/base';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { AvatarActivity } from 'src/app/modules/avatar/models/avatar-activities.class';
import { AvatarCreateInteraction } from 'src/app/modules/avatar/models/avatar-create-interaction.interface';
import { AvatarApiService } from './avatar-api.service';
import { AvatarDataChangeType } from './avatar-data-change-motification';
import { AvatarDTO } from './avatar-dto';
import { AvatarErrorMessages } from '../../models/avatar/avatar-error-messages.enum';
import {
  AvatarImportRequestBody,
  AvatarImportStatus,
} from 'datalayer/models/avatar/avatar-import';

@Injectable({
  providedIn: 'root',
})
export class AvatarService extends BaseService<
  Avatar,
  AvatarDTO,
  AvatarApiService,
  EmptyCacheService<Avatar>,
  AvatarDataChangeType
> {
  constructor(
    private apiService: AvatarApiService,
    emptyCacheService: EmptyCacheService<Avatar>
  ) {
    super(apiService, emptyCacheService);
  }

  public onAvatarCreated(avatar): Observable<Avatar> {
    return this.apiService.create(avatar).pipe(
      take(1),
      tap((response) => {
        super.onResourceChanged({
          models: [response],
          type: AvatarDataChangeType.Create,
        });
      }),
      catchError(() =>
        throwError(() => {
          return new Error(AvatarErrorMessages.CreateError);
        })
      )
    );
  }

  public onAvatarUpdated(avatar): Observable<Avatar> {
    return this.apiService.bindToUser(avatar).pipe(
      take(1),
      tap((response) => {
        if (!response) {
          throwError(() => new Error(AvatarErrorMessages.CreateError));
        }
        super.onResourceChanged({
          models: [avatar],
          type: AvatarDataChangeType.Create,
        });
      }),
      map(() => avatar)
    );
  }

  public getAvatarActivityTasks(): Observable<AvatarActivityTask[]> {
    return this.apiService.getAvatarActivityTasks();
  }

  public getActivityLog(avatarId: string): Observable<AvatarActivityLog[]> {
    return this.apiService.getAvatarActivityLog(avatarId);
  }

  public onStopAction(
    activity: AvatarActivity
  ): Observable<AvatarActivityTask> {
    return this.apiService.onStopAction(activity);
  }
  public onRunAction(activity: AvatarActivity): Observable<AvatarActivityTask> {
    return this.apiService.onRunAction(activity);
  }
  public availableAvatars(options: RequestOptions): Observable<Avatar[]> {
    return this.apiService.availableAvatars(options);
  }

  public generateCredentials(
    options: RequestOptions
  ): Observable<{ name: string; lastName: string }> {
    return this.apiService.generateCredentials(options);
  }

  public getPersons(options?: RequestOptions): Observable<AvatarPerson[]> {
    return this.apiService.getPersons(options);
  }

  public reserveRemoteView(
    avatarId: string,
    socialPlatform: AvatarSocialType,
    options?: RequestOptions
  ): Observable<AvatarRemoteView> {
    return this.apiService.reserveRemoteView(avatarId, socialPlatform, options);
  }

  public checkRemoteViewStatus(
    avatarId: string,
    socialPlatform: AvatarSocialType
  ): Observable<AvatarRemoteView> {
    return this.apiService.checkRemoteViewStatus(avatarId, socialPlatform);
  }

  public releaseRemoteView(
    avatarId: string,
    socialPlatform: AvatarSocialType
  ): Observable<AvatarRemoteViewStatus> {
    return this.apiService.releaseRemoteView(avatarId, socialPlatform);
  }

  protected onResourceChanged(
    res: DataChangeNotification<Avatar, AvatarDataChangeType>
  ): void {
    switch (res.type) {
      case AvatarDataChangeType.RemoteViewEnded: {
        this.externalDataChangeNotification.next({
          type: AvatarDataChangeType.RemoteViewEnded,
          models: res.models,
        });
        super.onResourceChanged({
          type: AvatarDataChangeType.Update,
          models: res.models,
        });
        break;
      }
      case AvatarDataChangeType.RemoteViewStarted: {
        this.externalDataChangeNotification.next({
          type: AvatarDataChangeType.RemoteViewStarted,
          models: res.models,
        });
        super.onResourceChanged({
          type: AvatarDataChangeType.Update,
          models: res.models,
        });
        break;
      }
      default: {
        super.onResourceChanged(res);
        break;
      }
    }
  }
  public createInteraction(
    model: AvatarCreateInteraction
  ): Observable<AvatarActivityTask> {
    return this.apiService.createInteraction(model);
  }
  public renew(avatar: Avatar): Observable<boolean> {
    return this.apiService.renew(avatar).pipe(
      take(1),
      tap((response) => {
        if (!response) {
          return throwError(() => new Error(AvatarErrorMessages.RenewError));
        }
        super.onResourceChanged({
          models: [avatar],
          type: AvatarDataChangeType.Update,
        });
      })
    );
  }
  public decode(dto: AvatarDTO): Avatar {
    return this.apiService.decode(dto);
  }

  public import(body: AvatarImportRequestBody): Observable<Avatar> {
    return this.apiService.import(body).pipe(
      map((result: AvatarDTO[]): Avatar => this.apiService.decode(result[0])),
      tap((avatar) => {
        super.onResourceChanged({
          models: [avatar],
          type: AvatarDataChangeType.Create,
        });
      })
    );
  }

  public getImportStatus(
    avatarId: string,
    platform: AvatarSocialType
  ): Observable<AvatarImportStatus> {
    return this.apiService.getImportStatus(avatarId, platform);
  }

  public setUserActivityResult(
    avatarId: string,
    platform: AvatarSocialType,
    payload: string
  ): Observable<void> {
    return this.apiService.setUserActivityResult(avatarId, platform, payload);
  }
}
