import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tagtypeui' })
export class TagTypeUiPipe implements PipeTransform {
  transform(tag_type: string) {
    if (tag_type === 'telno') {
      return 'msisdn';
    }
    if (tag_type === 'tag') {
      return 'Hashtag';
    }
    return tag_type;
  }
}
