import { BaseApiService } from 'datalayer/services/base';
import { DataLayerModule } from 'datalayer/data-layer.module';
import { Company } from 'datalayer/models';
import { CompanyDTO } from 'src/app/modules/data-layer/services/social/company/company-dto';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: DataLayerModule,
})
export class CompanyApiService extends BaseApiService<Company, CompanyDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient, CompanyDTO);
  }

  public getModelName(): string {
    return Company.name;
  }
}
