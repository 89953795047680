import { Themes } from '@shared/models/skins.model';

export class ThemeHelper {
  public static getLogo(theme: Themes, dark = true): string {
    const basicImageSrc = 'assets/static/images/';
    if (theme === Themes.FRESHVALE) {
      return basicImageSrc + 'freshvale-logo.png';
    }
    if (theme === Themes.GEOLOC) {
      return basicImageSrc + 'geoloc_logo.svg';
    }
    if (theme === Themes.GENERSHIELD) {
      return basicImageSrc + 'genershield-logo.png';
    }
    if (theme === Themes.CLARITY) {
      return (
        basicImageSrc + (dark ? 'clarity-logo.svg' : 'clarity-logo-white.svg')
      );
    }

    return (
      basicImageSrc +
      (dark ? 'intellectus-logo.png' : 'intellectus-logo-white.png')
    );
  }
}
