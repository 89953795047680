import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appScrollIntoView]',
})
export class ScrollIntoViewDirective implements OnChanges {
  @Input() appScrollIntoView: boolean | undefined;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges() {
    if (coerceBooleanProperty(this.appScrollIntoView)) {
      (this.elementRef.nativeElement as HTMLInputElement).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }
}
