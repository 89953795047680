import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-target-delete',
  templateUrl: './target-delete.component.html',
  styleUrls: ['./target-delete.component.scss'],
})
export class TargetDeleteComponent {
  constructor(
    public dialogRef: MatDialogRef<TargetDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onClose(): void {
    this.dialogRef.close(false);
  }

  delete(): void {
    this.dialogRef.close(true);
  }
}
