import { Component, OnDestroy, OnInit } from '@angular/core';
import { QueryService } from 'src/app/services/query/query.service';
import { ConversationItem } from 'src/app/shared/models/conversation-item.model';
import {
  Marker,
  Point,
  Button,
  MapOptions,
  IconMarker,
} from 'src/app/modules/mapV2/models/map.models';
import { ImsiCatcherItem } from 'src/app/shared/models/imsi-catcher-item.model';
import { LawEnforcementItem } from 'src/app/shared/models/law-enforcement-item.model';
import { MapHelperService } from 'src/app/services/map-helper/map-helper.service';
import { MapService } from '../../../modules/mapV2/vanilla-google-map/shared/map.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tactical-dashboard',
  templateUrl: './tactical-dashboard.component.html',
  styleUrls: ['./tactical-dashboard.component.scss'],
})
export class TacticalDashboardComponent implements OnInit, OnDestroy {
  showMap = true;
  tabIndex = 0;
  markers: Marker[] = [];
  zoom = 5;
  center: Point;
  circles = [];
  polygon = [];
  heatmapEnabled = false;
  historyTimelineEnabled = false;
  clusteringEnabled = false;
  enableDrawing = false;
  trafficEnabled = false;
  buttons: Button[] = [];
  trafficButton: Button;
  destroyed$ = new Subject();

  mapOptions: MapOptions = new MapOptions({
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      position: google.maps.ControlPosition.TOP_LEFT,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM,
    },
    scaleControl: false,
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM,
    },
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
  });

  constructor(
    private queryService: QueryService,
    private mapHelperService: MapHelperService,
    private mapService: MapService
  ) {}

  ngOnInit() {
    this.queryService.resetVariablesState();
    this.refreshMap();

    this.trafficButton = this.mapHelperService.getTrafficButton();
    this.buttons.push(this.trafficButton);
    this.trafficButton.callback = () => {
      this.trafficEnabled = !this.trafficEnabled;
    };
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }

  selectedConversation(conversation: ConversationItem) {
    // remove previously selected row
    this.markers = this.markers.filter((c) =>
      c.id.indexOf('selected-conversation')
    );

    const marker = new IconMarker({
      id: 'selected-conversation' + conversation.conversationId.toString(),
      lat: conversation.location.coordinates[1],
      lng: conversation.location.coordinates[0],
      popupHTML: conversation.conversationId.toString(),
      isPopupWindowOpen: true,
    });

    this.markers.push(marker);
    this.markers = [...this.markers];
  }

  multiselectConversation(data: {
    conversation: ConversationItem;
    new: boolean;
  }) {
    // remove previously selected row (not from checkbox)
    this.markers = this.markers.filter((c) =>
      c.id.indexOf('selected-conversation')
    );
    if (data.new) {
      const marker = new IconMarker({
        id: data.conversation.conversationId.toString(),
        lat: data.conversation.location.coordinates[1],
        lng: data.conversation.location.coordinates[0],
        popupHTML: data.conversation.conversationId.toString(),
        isPopupWindowOpen: true,
      });

      this.markers.push(marker);
    } else {
      this.markers = this.markers.filter(
        (marker) => marker.id !== data.conversation.conversationId.toString()
      );
    }

    this.markers = [...this.markers];
  }

  selectedImsiCatcher(imsiCatcher: ImsiCatcherItem) {
    // remove previously selected row
    this.markers = this.markers.filter((ic) =>
      ic.id.indexOf('selected-imsi-catcher')
    );

    const marker = new IconMarker({
      id: 'selected-imsi-catcher' + imsiCatcher.id.toString(),
      lat: imsiCatcher.location.coordinates['latitude'],
      lng: imsiCatcher.location.coordinates['longitude'],
      popupHTML: imsiCatcher.id.toString(),
      isPopupWindowOpen: true,
    });

    this.markers.push(marker);
    this.markers = [...this.markers];
  }

  multiselectImsiCatcher(data: { imsiCatcher: ImsiCatcherItem; new: boolean }) {
    // remove previously selected row (not from checkbox)
    this.markers = this.markers.filter((ic) =>
      ic.id.indexOf('selected-imsi-catcher')
    );
    if (data.new) {
      const marker = new IconMarker({
        id: data.imsiCatcher.id.toString(),
        lat: data.imsiCatcher.location.coordinates[1],
        lng: data.imsiCatcher.location.coordinates[0],
        popupHTML: data.imsiCatcher.id.toString(),
        isPopupWindowOpen: true,
      });

      this.markers.push(marker);
    } else {
      this.markers = this.markers.filter(
        (marker) => marker.id !== data.imsiCatcher.id.toString()
      );
    }

    this.markers = [...this.markers];
  }

  selectedLawEnforcement(lawEnforcement: LawEnforcementItem) {
    // remove previously selected row
    this.markers = this.markers.filter((le) =>
      le.id.indexOf('selected-law-enforcement')
    );

    const marker = new IconMarker({
      id: 'selected-law-enforcement' + lawEnforcement.id.toString(),
      lat: lawEnforcement.location.coordinates[1],
      lng: lawEnforcement.location.coordinates[0],
      popupHTML: lawEnforcement.id.toString(),
      isPopupWindowOpen: true,
    });

    this.markers.push(marker);
    this.markers = [...this.markers];
  }

  multiselectLawEnforcement(data: {
    lawEnforcement: LawEnforcementItem;
    new: boolean;
  }) {
    // remove previously selected row (not from checkbox)
    this.markers = this.markers.filter((le) =>
      le.id.indexOf('selected-law-enforcement')
    );
    if (data.new) {
      const marker = new IconMarker({
        id: data.lawEnforcement.id.toString(),
        lat: data.lawEnforcement.location.coordinates[1],
        lng: data.lawEnforcement.location.coordinates[0],
        popupHTML: data.lawEnforcement.id.toString(),
        isPopupWindowOpen: true,
      });

      this.markers.push(marker);
    } else {
      this.markers = this.markers.filter(
        (marker) => marker.id !== data.lawEnforcement.id.toString()
      );
    }

    this.markers = [...this.markers];
  }

  toggleMap() {
    this.showMap = !this.showMap;
  }

  onTabChange(event) {
    this.tabIndex = event.index;
  }

  refreshMap() {
    this.markers = [];
    this.mapService
      .getDefaultCenter()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((point) => {
        this.center = point;
      });
    this.zoom = this.mapHelperService.getDefaultZoomLevel();
    this.buttons = [];
    this.heatmapEnabled = false;
    this.historyTimelineEnabled = false;
    this.circles = [];
  }
}
