import { BaseDTO } from 'datalayer/services/base';

export class AvatarActivityTask {
  activity: string;
  activityParams: { [key: string]: string };
  avatarIds: string[];
  avatarResults?: { [key: string]: { state: string } };
  completedAt: Date;
  createdAt: Date;
  social: string;
  state: string;
  isRandom: boolean;
  updatedAt: Date;
  id: string;
  actions: string[];
  constructor(model: Partial<AvatarActivityTask>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}

export class AvatarActivityTaskDTO extends BaseDTO<
  AvatarActivityTask,
  AvatarActivityTaskDTO
> {
  activity: string;
  activityParams: { [key: string]: string };
  avatarIds: string[];
  avatarResults?: { [key: string]: { state: string } };
  completedAt: Date;
  createdAt: Date;
  social: string;
  state: string;
  isRandom: boolean;
  updatedAt: Date;
  __v: number;
  _id: string;
  actions: string[];

  constructor(model: Partial<AvatarActivityTaskDTO>) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: AvatarActivityTask): AvatarActivityTaskDTO {
    return this;
  }

  toModel(): AvatarActivityTask {
    const activityLog: AvatarActivityTask = new AvatarActivityTask({
      activity: this.activity,
      activityParams: this.activityParams,
      avatarIds: this.avatarIds,
      avatarResults: this.avatarResults,
      createdAt: new Date(this.createdAt),
      completedAt: new Date(this.completedAt),
      social: this.social,
      state: this.state,
      isRandom: this.isRandom,
      updatedAt: this.updatedAt,
      id: this._id,
      actions: this.actions,
    });
    return activityLog;
  }
}
