import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'intellectus-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  @Input()
  public withBorder = false;
}
