import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { first } from 'lodash-es';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-upload-file-selector',
  templateUrl: './upload-file-selector.html',
  styleUrls: ['./upload-file-selector.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFileSelectorComponent {
  @Output() selectedFile: EventEmitter<File | undefined> = new EventEmitter();
  @Input() acceptedFileTypes = '.csv, .xlsx';
  @Input() acceptMultipleFiles = false;
  @Input() acceptDirectorySelect = false;
  selectedFileInfo: File;
  onDropped(files: NgxFileDropEntry[]) {
    const fileEntry = first(files).fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      this.selectedFileInfo = file;
      this.selectedFile.next(file);
    });
  }
}
