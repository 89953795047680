import { Injectable } from '@angular/core';
import {
  ActiveToast,
  ComponentType,
  IndividualConfig,
  Toast,
  ToastrService,
} from 'ngx-toastr';
import { Subscription, merge, timer } from 'rxjs';
import { BaseToasterIndividualConfig } from './base-toaster-individual-config';
import { BaseToasterComponent } from './base-toaster.component';
import { IntellectusBaseToasterConfig } from './base-toaster.model';
import { ToastersEventService } from './toaster-events.service';

@Injectable({
  providedIn: 'root',
})
export class BaseToasterService {
  public openToastersCount = 0;

  public listOfOpenToasters: {
    toaster: ActiveToast<unknown>;
    timeout: number;
    remainingTime: number;
    timerSubscription?: Subscription;
    disableTimeout?: boolean;
  }[] = [];

  private readonly DEFAULT_TOASTER_TIMEOUT = 5000;

  constructor(
    private toastrService: ToastrService,
    private toasterEvents: ToastersEventService
  ) {}

  show(
    toaster: IntellectusBaseToasterConfig,
    config?: Partial<IndividualConfig>
  ): ActiveToast<unknown> {
    const toasterRef = this.toastrService.show(
      toaster.actionTitle,
      toaster.title,
      {
        ...BaseToasterIndividualConfig,
        ...config,
        timeOut: 0, // overriding the initial timeout, we will change it programatically
        toastComponent: BaseToasterComponent,
        payload: {
          icon: toaster.icon,
          actions: toaster.actions,
        },
      }
    );

    this.listOfOpenToasters.push({
      toaster: toasterRef,
      timeout: config?.timeOut || this.DEFAULT_TOASTER_TIMEOUT,
      remainingTime: config?.timeOut || this.DEFAULT_TOASTER_TIMEOUT,
    });

    this.incrementToasterCount();
    this.handleToasterRef(toasterRef);

    return toasterRef;
  }

  showFromComponent(
    toastComponent: ComponentType<Toast>,
    config?: Partial<IndividualConfig>
  ): ActiveToast<unknown> {
    const toasterRef = this.toastrService.show(undefined, undefined, {
      ...BaseToasterIndividualConfig,
      ...config,
      timeOut: 0, // overriding the initial timeout, we will change it programatically
      toastComponent: toastComponent,
      payload: config?.payload,
    });

    this.listOfOpenToasters.push({
      toaster: toasterRef,
      timeout: config?.timeOut ?? this.DEFAULT_TOASTER_TIMEOUT,
      remainingTime: config?.timeOut ?? this.DEFAULT_TOASTER_TIMEOUT,
      disableTimeout: !!config?.disableTimeOut,
    });

    this.incrementToasterCount();
    this.handleToasterRef(toasterRef);

    return toasterRef;
  }

  private incrementToasterCount() {
    this.openToastersCount++;
    this.toasterEvents.notifyToasterOpened();
    this.manageToasterTimers();
  }

  private decrementToasterCount() {
    this.openToastersCount--;
    this.toasterEvents.notifyToasterClosed();
  }

  private handleToasterRef(toastRef: ActiveToast<unknown>) {
    merge(toastRef.onHidden, toastRef.onTap, toastRef.onAction).subscribe(
      () => {
        this.decrementToasterCount();
        this.removeToasterFromList(toastRef);
        this.manageToasterTimers();
      }
    );
  }

  private manageToasterTimers(): void {
    this.listOfOpenToasters.forEach((toaster, index) => {
      if (toaster.disableTimeout) {
        return;
      }
      if (toaster.timerSubscription && index !== 0) {
        toaster.timerSubscription.unsubscribe();
      }
      if (index === 0) {
        toaster.timerSubscription = timer(toaster.remainingTime).subscribe(
          () => {
            this.toastrService.clear(toaster.toaster.toastId);
          }
        );
      } else {
        toaster.remainingTime = toaster.timeout;
      }
    });
  }

  private removeToasterFromList(toastRef: ActiveToast<unknown>): void {
    const index = this.listOfOpenToasters.findIndex(
      (t) => t.toaster.toastId === toastRef.toastId
    );
    if (index !== -1) {
      this.listOfOpenToasters.splice(index, 1);
    }
  }
}
