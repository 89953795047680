import { Component } from '@angular/core';

@Component({
  selector: 'app-darkweb-dashboard',
  templateUrl: './darkweb-dashboard.component.html',
  styleUrls: ['./darkweb-dashboard.component.scss'],
})
export class DarkwebDashboardComponent {
  hideLogo = true;
  // dwUrl: 'https://portal.cybersixgill.com/';
  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  onFrameLoad() {
    this.removeSlogan();
  }

  removeSlogan() {
    const frame = document.getElementById('dwIFrame');
    if (this.isIFrame(frame) && frame.contentWindow) {
      this.hideLogo = false;
      const doc = frame.contentWindow;
      const sloganSpan = doc.document.getElementsByClassName('slogan')[0];
      sloganSpan.parentElement.removeChild(sloganSpan);
    }
  }
}
