import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, race, Subject, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { IMapService } from 'src/app/modules/mapV2/models/map-service.interface';
import { Point } from 'src/app/modules/mapV2/models/map.models';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { DEFAULT_COUNTRIES } from '../../../../components/settings/shared/default-countries';
import { SiteSettingsService } from '../../../../services/site-settings.service';

@Injectable({
  providedIn: 'root',
})
export class MapService implements IMapService {
  constructor(
    private configService: AppConfigService,
    private translationService: TranslationService,
    private siteSettingsService: SiteSettingsService,
    private http: HttpClient
  ) {}

  memo = {
    streetView: {},
    locations: {},
  };

  getDefaultCenter(): Observable<Point> {
    return this.siteSettingsService.getSelectedCountry().pipe(
      switchMap((country) => {
        const settingsLocation = this.getUserCountryCoordinates(country);
        if (settingsLocation) {
          return of(settingsLocation);
        }
        const configLocation = this.getUserCountryCoordinates(
          this.configService.getConfigVariable('country')
        );
        return from(this.getBrowserLocation()).pipe(
          switchMap((position) =>
            position ? of(position) : of(configLocation)
          ),
          catchError(() => of(configLocation))
        );
      })
    );
  }

  private getBrowserLocation(): Promise<Point | null> {
    return new Promise((resolve) => {
      if (window.navigator?.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => resolve(null)
        );
      } else {
        resolve(null);
      }
    });
  }

  getIconPath(): string {
    throw new Error('Method not implemented.');
  }

  getDefaultMarkerIcon(): string {
    return '/assets/static/images/geo_default_pin.svg';
  }

  getStreetViewStaticImageUrl(point: Point): Observable<string> {
    const id = `${point.lng}_${point.lat}`;
    if (this.memo.streetView[id]) {
      return of(this.memo.streetView[id]);
    }

    const sv = new google.maps.StreetViewService();
    const obs$ = new Subject<string>();
    // ! Todo: Add api key from environment when systems enable the api
    const url = `https://maps.googleapis.com/maps/api/streetview?size=300x160&location=${point.lat},${point.lng}
    &fov=80&heading=70&pitch=0
    &key=AIzaSyBKfBv0GVwEahX_F_zdZKqZFFg7ay50-fw`;
    const location = new google.maps.LatLng(point);
    sv.getPanorama({ location, radius: 50 }, (_, status) => {
      if (status === google.maps.StreetViewStatus.OK) {
        obs$.next(url);
        this.memo.streetView[id] = url;
      } else {
        obs$.next(google.maps.StreetViewStatus.ZERO_RESULTS);
        this.memo.streetView[id] = google.maps.StreetViewStatus.ZERO_RESULTS;
      }
    });

    return obs$.asObservable();
  }

  reverseGeocoding(point: Point): Observable<string> {
    const id = `${point.lng}_${point.lat}`;
    if (this.memo.locations[id]) {
      return of(this.memo.locations[id]);
    }

    // ! Todo: Add environment variable when system deploy the nominatim service
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${point.lat}&lon=${point.lng}&zoom=18&addressdetails=1`;
    const timeout$ = of(408).pipe(
      delay(7000),
      switchMap((v) => throwError(v))
    );
    const request$ = this.http.get<any>(url).pipe(
      map((result) => result.display_name),
      tap((address) => {
        this.memo.locations[id] = address;
      })
    );

    return race(timeout$, request$).pipe(
      catchError((_) => {
        const lat = point.lat.toFixed(4);
        const lng = point.lng.toFixed(4);
        console.error('Nominatim server timeout');
        return of(
          `${this.translationService.translate(
            'Latitude'
          )}: ${lat}, ${this.translationService.translate('Longitude')}: ${lng}`
        );
      })
    );
  }

  reverseGeocodingGetPlace(point: Point): Observable<string> {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${point.lat}&lon=${point.lng}&zoom=18&addressdetails=1`;
    return this.http.get<any>(url);
  }

  private getUserCountryCoordinates(userCountry: string): Point {
    if (!userCountry) {
      return null;
    }
    const countryInfo = DEFAULT_COUNTRIES.find(
      (country) => country.value === userCountry
    );

    if (!countryInfo || countryInfo.value === 'none') {
      return null;
    }

    return { lat: countryInfo.lat, lng: countryInfo.lon };
  }
}
