import { Injectable } from '@angular/core';
import { AvatarApiService } from 'datalayer/services/avatar/avatar-api.service';
import { AvatarDataChangeType } from 'datalayer/services/avatar/avatar-data-change-motification';
import { AvatarDTO } from 'datalayer/services/avatar/avatar-dto';
import { Subject } from 'rxjs';
import {
  AvatarPayload,
  Notification,
} from 'src/app/components/notifications-list/notification.model';
import { v4 as uniqueId } from 'uuid';
import { MessageSubject } from './message-subject.model';
import { ServerPyWsService } from './server-py-websocket.service';
import { Message } from './websocket.class';

@Injectable({
  providedIn: 'root',
})
export class DataLayerWsManagerService {
  private appNotification: Subject<Notification> = new Subject();
  public appNotification$ = this.appNotification.asObservable();
  constructor(
    private serverPyWsService: ServerPyWsService,
    private avatarApiService: AvatarApiService
  ) {}

  public connect(): void {
    this.serverPyWsService.getMessage().subscribe((message: Message) => {
      const messageSubjectsToHandle = [
        MessageSubject.AvatarUpdate,
        MessageSubject.AvatarRemoteViewEnded,
        MessageSubject.AvatarRemoteViewStarted,
        MessageSubject.AvatarExpiring,
      ];
      if (messageSubjectsToHandle.includes(message.subject)) {
        this.dispatchMessage(message.subject, message.body);
      }
    });
  }

  private dispatchMessage(
    subject,
    body: {
      correlationId: string;
      payload: { avatar?: AvatarDTO; payload?: AvatarPayload; msg?: string };
    }
  ): void {
    switch (subject) {
      case MessageSubject.AvatarUpdate: {
        this.avatarApiService.onResourceChanged({
          type: AvatarDataChangeType.Update,
          dto: body.payload.avatar,
          message: body.payload.msg,
        });
        break;
      }
      case MessageSubject.AvatarRemoteViewEnded: {
        this.avatarApiService.onResourceChanged({
          type: AvatarDataChangeType.RemoteViewEnded,
          dto: body.payload.avatar,
        });
        break;
      }
      case MessageSubject.AvatarRemoteViewStarted: {
        this.avatarApiService.onResourceChanged({
          type: AvatarDataChangeType.RemoteViewStarted,
          dto: body.payload.avatar,
        });
        break;
      }
      case MessageSubject.AvatarExpiring: {
        const notification = {
          id: `${uniqueId()}-forcedNotification`,
          content: { payload: body.payload as AvatarPayload },
          createdAt: new Date(),
          notificationType: 'avatar:expired',
          isRead: false,
        };
        this.appNotification.next(notification);
        break;
      }
    }
  }
}
