import { BaseDTO } from 'datalayer/services/base';
import { Post } from 'datalayer/models/social/post';
import {
  EntityType,
  DataSource,
  ImageUrl,
  FeedbackStats,
} from 'datalayer/models/platform-models';
import { Place } from 'src/app/shared/models/place-interface';
import { Coordinates } from 'src/app/shared/models/coordinates-interface';
import { Injectable } from '@angular/core';
import { environment } from 'environment/environment';
@Injectable({ providedIn: 'root' })
export class PostDTO extends BaseDTO<Post, PostDTO> {
  private id: any;
  private type: EntityType;
  private source: DataSource;
  private created_at: Date;
  private updated_at?: Date;
  private source_entity?: {
    id: string;
    created_at: Date;
    parent_id: string;
  };
  private target_id?: string;
  private title?: string;
  private body?: string;
  private url?: string;
  private language?: string;
  private agent?: string;
  private coordinates?: Coordinates;
  private place?: Place;
  private mention_type?: string;
  private published_at?: Date;
  private valid_at?: Date;
  private feedback_stats?: FeedbackStats;
  private images: ImageUrl[];
  private platform: string;
  private media: string[];
  private description: string;
  private location?: Coordinates;

  constructor(model?: Partial<PostDTO>) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Post): PostDTO {
    this.id = model.id;
    this.type = model.type;
    this.source = model.source;
    this.created_at = model.createdAt;
    this.updated_at = model.updatedAt;
    this.source_entity = {
      id: model.sourceEntity.id,
      created_at: model.sourceEntity.createdAt,
      parent_id: model.sourceEntity.parentId,
    };
    this.target_id = model.targetId;
    this.title = model.title;
    this.body = model.body;
    this.url = model.url;
    this.language = model.language;
    this.agent = model.agent;
    this.coordinates = model.coordinates;
    this.place = model.place;
    this.mention_type = model.mentionType;
    this.published_at = new Date(model.publishedAt);
    this.valid_at = new Date(model.validAt);
    this.feedback_stats = model.feedbackStats;
    this.images = model.images;

    return this;
  }

  toModel(): Post {
    const post: Post = new Post({
      id: this.id,
      type: this.type,
      source: this.source,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      sourceEntity: {
        id: this.source_entity.id,
        createdAt: this.source_entity.created_at,
        parentId: this.source_entity.parent_id,
      },
      targetId: this.target_id,
      title: this.title,
      body: this.body || this.description,
      url: this.url,
      language: this.language,
      agent: this.agent,
      coordinates: this.coordinates,
      place: this.place,
      mentionType: this.mention_type,
      publishedAt: this.published_at,
      validAt: this.valid_at,
      feedbackStats: {
        ...(this.feedback_stats.likesCount && {
          likesCount: this.feedback_stats.likesCount,
        }),
        ...(this.feedback_stats.commentsCount && {
          commentsCount: this.feedback_stats.commentsCount,
        }),
        ...(this.feedback_stats.resharesCount && {
          resharesCount: this.feedback_stats.resharesCount,
        }),
        ...(this.feedback_stats.reactorsCount && {
          reactorsCount: this.feedback_stats.reactorsCount,
        }),
      },
      // TODO
      // when the upgrade to angular 14 will happen
      // use ImageService getPhotoUrl to fetch proper image thumbnail
      // with simple inject()
      images:
        this.images?.map((image) => ({
          imageUrl: `${this.filemanagerUri}${image.imageUrl}`,
        })) ||
        this.media?.map((media) => ({
          imageUrl: `${this.filemanagerUri}${media}`,
        })),
      platform: this.platform,
      ...(this.location &&
        this.location.coordinates && {
          locationData: {
            lat: this.location.coordinates[1],
            lng: this.location.coordinates[0],
          },
        }),
    });

    return post;
  }

  private filemanagerUri = environment.fileManagerUri;
}
