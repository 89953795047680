import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appFallbackImg]',
})
export class FallbackImgDirective {
  @Input() @HostBinding() src: string;
  @Input() appFallbackImg: string;

  @HostBinding('class') className;

  @HostListener('error') updateUrl() {
    this.src = this.appFallbackImg;
  }
}
