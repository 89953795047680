import { Injectable } from '@angular/core';
import { PurchaseNotificationResponse } from 'datalayer/models/background-jobs/purchase-notifications';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PurchaseNotificationsStore {
  private obs$ = new BehaviorSubject<{
    [id: string]: PurchaseNotificationResponse[];
  }>({});
  public notifications$ = this.obs$.asObservable();

  private get value() {
    return this.obs$.getValue();
  }

  /**
   *
   * @param value Notifications
   */
  private set value(value: { [id: string]: PurchaseNotificationResponse[] }) {
    this.obs$.next(value);
  }

  /**
   *
   * @param newNotification PurchaseNotificationResponse
   */
  public addNotification(newNotification: PurchaseNotificationResponse) {
    const curNotificationsById = this.value[newNotification.content.msisdn];

    this.value = {
      ...this.value,
      [newNotification.content.msisdn]: curNotificationsById
        ? [...curNotificationsById, newNotification]
        : [newNotification],
    };
  }

  /**
   *
   * @param id string
   */
  cleanNotificationsById(id: string) {
    this.value = {};
  }

  /**
   *
   * @param id string
   */
  hasNotificationWithId(id: string) {
    return Object.prototype.hasOwnProperty.call(this.value, id);
  }
}
