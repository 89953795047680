export const matomoCategories = {
  navBar: 'NavBar',
  landingPage: 'LandingPage',
  landingPageFilters: 'LandingPage-Filters',
  gioQueryLog: 'GIO-QueryLog',
  gioQueryDetails: 'GIO-QueryDetails',
  gioAdvancedQuery: 'GIO-AdvancedQuery',
  gioMapPin: 'GIO-MapPin',
  gioMap: 'GIO-Map',
  caseAndTargets: 'CasesAndTargets',
  targetOverview: 'TargetOverview',
  targetOverviewMap: 'TargetOverviewMap',
  callLogAnalysis: 'CallLogAnalysis',
  intelSearchResults: 'IntelSearchResults',
  nearbyLocations: 'NearbyLocations',
  extraLocationInformation: 'ExtraLocationInformation',
};

export const matomoActions = {
  discovery: 'Discovery',
  casesAndTargets: 'CasesAndTargets',
  adint: 'Adint',
  searchBar: 'SearchBar',
  openCreditGauge: 'OpenCreditGauge',
  openNotifications: 'OpenNotifications',
  search: 'Search',
  searchByImage: 'SearchByImage',
  location: 'Location',
  age: 'Age',
  more: 'More',
  queryResubmit: 'QueryResubmit',
  intelSearch: 'IntelSearch',
  createNewTarget: 'CreateNewTarget',
  updateExistingTarget: 'UpdateExistingTarget',
  refresh: 'Refresh',
  exportPDF: 'ExportPDF',
  exportCSV: 'ExportCSV',
  addToTarget: 'AddToTarget',
  viewAvatar: 'ViewAvatar',
  submitScheduleQuery: 'SubmitScheduleQuery',
  submitLogOnQuery: 'SubmitLogOnQuery',
  submitGeofenceQuery: 'SubmitGeofenceQuery',
  submitProximityQuery: 'SubmitProximityQuery',
  submitAdvancedQuery: 'SubmitAdvancedQuery',
  createGeofenceTask: 'CreateGeofenceTask',
  createScheduleTask: 'CreateScheduleTask',
  neighbourCells: 'NeighbourCells',
  viewTimeline: 'ViewTimeline',
  viewHeatmap: 'ViewHeatmap',
  viewPredictedLocation: 'ViewPredictedLocation',
  jumpToDiscovery: 'JumpToDiscovery',
  createNewCase: 'CreateNewCase',
  advancedOsint: 'AdvancedOsint',
  deepOsint: 'DeepOsint',
  locate: 'Locate',
  createGeofence: 'CreateGeofence',
  editAbout: 'EditAbout',
  editSocialProfiles: 'EditSocialProfiles',
  viewFullProfile: 'ViewFullProfile',
  viewTargetSummary: 'ViewTargetSummary',
  viewLastSeen: 'ViewLastSeen',
  viewPlacesOfInterest: 'ViewPlacesOfInterest',
  requestCallLog: 'RequestCallLog',
  uploadCallLog: 'UploadCallLog',
  exportCallLog: 'ExportCallLog',
  overview: 'Overview',
  map: 'Map',
  searchAndGeolocatePhone: 'SearchAndGeolocatePhone',
  geolocatePhone: 'GeolocatePhone',
  locationHistory: 'LocationHistory',
  viewOverview: 'ViewOverview',
  viewMap: 'ViewMap',
  topAssociatesIntelSearch: 'TopAssociates-IntelSearch',
  topAssociatesCreateTarget: 'TopAssociates-CreateTarget',
  topAssociatesLocate: 'TopAssociates-Locate',
  angularticsCategory: 'IntelSearchResults',
  addProfileToMyTarget: 'AddProfileToMyTarget',
  addToNewTarget: 'AddToNewTarget',
  addToExistingTarget: 'AddToExistingTarget',
  seedAddToTarget: 'SeedAddToTarget',
  seedSearch: 'SeedSearch',
  seedCopy: 'SeedCopy',
  seedLocate: 'SeedLocate',
  viewCredits: 'ViewCredits',
  viewCreditsConcurrent: 'ViewCreditsConcurrent',
  viewNotifications: 'ViewNotifications',
  clearNotifications: 'ClearNotifications',
  recentSearchItem: 'RecentSearchItem',
  viewSearchHistory: 'ViewSearchHistory',
  applyTrilateration: 'ApplyTrilateration',
  telegramNearby: 'TelegramNearby',
  activeLocateQuery: 'ActiveLocateQuery',
  knowledgeBase: 'KnowledgeBase',
};
