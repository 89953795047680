import { Injectable } from '@angular/core';
import { environment } from 'environment/environment';
import { HttpClient } from '@angular/common/http';
import { DataSource } from 'datalayer/models/platform-models';
import { Observable } from 'rxjs';
import {
  ImGroup,
  ImGroupChatMessage,
  ImGroupMedia,
  ImGroupMediaResponse,
  ImGroupMember,
  NamedEntity,
  SocialGroupStats,
} from 'src/app/modules/case-group-analyser/models/case-group-analyser.model';
import { map } from 'rxjs/operators';
import { ImageService } from 'src/app/services/image/image.service';

@Injectable({ providedIn: 'root' })
export class ImGroupAnalyserApiService {
  private readonly apiURI: string = environment.entityQueryServiceUri;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly imageService: ImageService
  ) {}

  public loadGroup(id: string, source: DataSource): Observable<ImGroup> {
    return this.httpClient
      .get<ImGroup>(`${this.apiURI}/groups/${id}`, {
        params: { source },
      })
      .pipe(
        map(
          (group: ImGroup): ImGroup => ({
            ...group,
            avatarFileId: group.avatarFileId
              ? (this.imageService.getPhotoUrl(
                  `/file/${group.avatarFileId}`
                ) as string)
              : 'assets/static/images/group_placeholder.svg',
            externalId: id,
          })
        )
      );
  }

  public loadMembers(
    id: string,
    source: DataSource
  ): Observable<ImGroupMember[]> {
    return this.httpClient
      .get<{ result: ImGroupMember[] }>(
        `${this.apiURI}/groups/${id}/profiles`,
        {
          params: { source },
        }
      )
      .pipe(
        map((data: { result: ImGroupMember[] }): ImGroupMember[] =>
          data.result.map((member: ImGroupMember): ImGroupMember => {
            const stats: SocialGroupStats = member.socialGroupsStats.find(
              (s: SocialGroupStats) => s.groupId === id
            );
            return {
              ...member,
              avatarFile: member.avatarFileId
                ? (this.imageService.getPhotoUrl(
                    `/file/${member.avatarFileId}`
                  ) as string)
                : 'assets/static/images/avatar.svg',
              messagesCount: stats?.messagesCount || 0,
              firstMessageDate: stats?.firstMessageDate || null,
              lastMessageDate: stats?.lastMessageDate || null,
            };
          })
        )
      );
  }

  public loadChatMessages(
    groupId: string,
    source: DataSource
  ): Observable<ImGroupChatMessage[]> {
    return this.httpClient
      .get<ImGroupChatMessage[]>(`${this.apiURI}/groups/${groupId}/posts`, {
        params: { source },
      })
      .pipe(
        map((data: ImGroupChatMessage[]): ImGroupChatMessage[] => {
          return data
            .map((message) => {
              return {
                ...message,
                fileIds: message.fileIds.map((fileId) => {
                  return this.imageService.getPhotoUrl(
                    `/file/${fileId}`
                  ) as string;
                }),
                authorFile: message.authorAvatarFileId
                  ? (this.imageService.getPhotoUrl(
                      `/file/${message.authorAvatarFileId}`
                    ) as string)
                  : 'assets/static/images/avatar.svg',
              };
            })
            .sort(
              (a, b) =>
                new Date(a.sourceEntity?.createdAt).getTime() -
                new Date(b.sourceEntity.createdAt).getTime()
            );
        })
      );
  }

  public loadMedia(
    groupId: string,
    source: DataSource,
    page: number,
    limit: number
  ): Observable<ImGroupMediaResponse> {
    return this.httpClient
      .get<ImGroupMediaResponse>(`${this.apiURI}/groups/${groupId}/files`, {
        params: { source, page, limit },
      })
      .pipe(
        map((data: ImGroupMediaResponse): ImGroupMediaResponse => {
          return {
            ...data,
            result: data.result.map((media: ImGroupMedia): ImGroupMedia => {
              return {
                ...media,
                filePath: media.filePath
                  ? (this.imageService.getPhotoUrl(
                      `/file/${media.filePath}`
                    ) as string)
                  : 'assets/static/images/image_placeholder.svg',
              };
            }),
          };
        })
      );
  }

  public loadNamedEntities(
    groupId: string,
    source: DataSource
  ): Observable<NamedEntity[]> {
    return this.httpClient.get<NamedEntity[]>(
      `${this.apiURI}/groups/${groupId}/named_entities`,
      {
        params: { source },
      }
    );
  }
}
