import { Injectable } from '@angular/core';
import { BillingService } from 'src/app/services/billing/billing.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { take, tap } from 'rxjs/operators';
import { User } from 'src/app/services/user/user.model';
import {
  BillingPlan,
  BillingActions,
  BillingActionType,
  BalanceType,
} from 'src/app/shared/models/billing-action.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CreditPoolTypes } from '@shared/models/credit-pools.model';

@Injectable({
  providedIn: 'root',
})
export class CreditCalculationService {
  private currentCredits = new BehaviorSubject<number>(0);

  constructor(
    private billingService: BillingService,
    private ledgerService: LedgerService,
    private userBillingService: UserBillingService
  ) {
    this.initializeCurrentCredits();
  }

  private get billingPlan(): BillingPlan<BillingActions, BillingActionType> {
    return this.billingService.getBillingPlan().getValue();
  }

  private initializeCurrentCredits() {
    this.ledgerService
      .getCurrentUserLedgerItem()
      .pipe(
        tap((user: User) => {
          const credits =
            user.balanceType === BalanceType.DISTRIBUTED
              ? user.currentBalance[CreditPoolTypes.WEBINT]
              : this.userBillingService.getUserCurrentBalance(user);
          this.currentCredits.next(credits);
        }),
        take(1)
      )
      .subscribe();
  }

  public getCurrentCredits(): Observable<number> {
    return this.currentCredits.asObservable();
  }

  public getCost(action: string | string[]): number {
    if (typeof action === 'string') {
      return this.billingPlan[action]?.cost ?? 0;
    }

    if (Array.isArray(action)) {
      return action.reduce(
        (acc, curr) => acc + (this.billingPlan[curr]?.cost ?? 0),
        0
      );
    }

    return 0;
  }

  public hasSufficientCredits(
    action: string | string[],
    countOfActionsCharged = 1
  ): boolean {
    const cost = this.getCost(action) * countOfActionsCharged;
    return this.currentCredits.value >= cost;
  }
}
