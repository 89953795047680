<svg
  class="line-chart"
  width="100%"
  height="25px"
  aria-labelledby="chartTitle"
  role="img"
>
  <defs>
    <ng-container *ngFor="let data of lineChartData; let i = index">
      <linearGradient [attr.id]="'gradient' + i">
        <stop offset="0%" [attr.stop-color]="gradientColors[i][0]" />
        <stop offset="100%" [attr.stop-color]="gradientColors[i][1]" />
      </linearGradient>
    </ng-container>
  </defs>
  <g *ngFor="let data of lineChartData; let i = index; trackBy: trackByIndex">
    <rect
      [attr.x]="i * (lineChartWidth / 12)"
      y="0"
      [attr.width]="lineChartWidth / 12"
      height="8"
      [attr.fill]="'url(#gradient' + i + ')'"
      role="presentation"
    >
      <title>
        {{ data.hourRange }} - {{ data.count }} {{ 'messages' | translate }}
      </title>
    </rect>
    <text [attr.x]="i * (lineChartWidth / 12) + lineChartWidth / 24" y="20">
      {{ data.hourRange }}
    </text>
  </g>
</svg>
