import { Component, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BaseToasterService } from '@fe-platform/shared-ui/intellectus';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'fe-platform-copy-to-clipboard-chip',
  templateUrl: './copy-to-clipboard-chip.component.html',
  styleUrls: ['./copy-to-clipboard-chip.component.scss'],
})
export class CopyToClipboardChipComponent {
  @Input() text: string;
  @Input() maxWidth: number;

  constructor(
    private clipboard: Clipboard,
    private toasterService: BaseToasterService,
    private translationService: TranslationService
  ) {}

  onCopyClick() {
    this.clipboard.copy(this.text);
    this.toasterService.show({
      title: this.translationService.translate('Copied!'),
    });
  }
}
