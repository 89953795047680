<div
  class="wrapper"
  [class.is-mobile]="isMobile"
  [class.centered]="config.centeredTitle"
>
  <div class="info">
    <fe-platform-fa-pro-icon
      id="breadcrumb-icon"
      class="icon"
      [class.light]="config?.breadcrumbs?.length > 1"
      [family]="config?.icon?.family"
      [name]="config?.icon?.name"
    ></fe-platform-fa-pro-icon>

    <div class="title" *ngIf="config?.title">{{ config.title }}</div>

    <div class="breadcrumbs" *ngIf="config?.breadcrumbs">
      <ng-container
        *ngFor="
          let breadcrumb of config.breadcrumbs;
          let last = last;
          let i = index
        "
      >
        <div
          class="breadcrumb"
          [class.active]="last"
          (click)="breadcrumbClick.emit(i)"
        >
          {{ breadcrumb.title }}
        </div>

        <div class="delimiter" *ngIf="!last">
          <fe-platform-fa-pro-icon
            [family]="delimiterIcon.family"
            [name]="delimiterIcon.name"
          ></fe-platform-fa-pro-icon>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="actions">
    <intellectus-icon-text-button
      class="action"
      *ngFor="let action of config?.actions"
      [buttonIcon]="action.icon"
      [buttonSize]="'xsmall'"
      (buttonClick)="action.action()"
    ></intellectus-icon-text-button>

    <intellectus-icon-text-button
      id="close"
      class="action"
      [buttonIcon]="isMobile ? backCloseIcon : closeIcon"
      [buttonSize]="isMobile ? 'default' : 'xsmall'"
      (buttonClick)="closeClick.emit()"
    ></intellectus-icon-text-button>
  </div>
</div>
