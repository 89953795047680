<div class="row geofencing-top-row">
  <div
    class="col-12 remove-padding-from-left-col"
    [class.col-md-4]="skin === 'GEO4' || skin === 'CVTP'"
  >
    <div>
      <app-datetimepicker [minDate]="todayDate"></app-datetimepicker>
    </div>
  </div>
  <div class="col-12" [class.col-md-4]="skin === 'GEO4' || skin === 'CVTP'">
    <div>
      <app-frequency></app-frequency>
    </div>
  </div>
  <div
    class="col-12 remove-padding-from-left-col checkbox"
    [class.col-md-4]="skin === 'GEO4' || skin === 'CVTP'"
  >
    <div>
      <mat-checkbox [formControl]="outside">{{
        'Outside of area' | translate
      }}</mat-checkbox>
    </div>
  </div>
</div>
<mat-tab-group
  mat-align-tabs="start"
  class="geofencing-tabs"
  (selectedTabChange)="setGeofenceType($event.index)"
>
  <mat-tab
    *ngIf="skin === 'GEO4' || skin === 'CVTP'"
    label="{{ 'Country' | translate }}"
  >
    <!-- Country Form -->
    <form [formGroup]="countryForm" (ngSubmit)="onSubmitCountry()">
      <mat-form-field class="country">
        <mat-label>{{ 'Select country' | translate }}</mat-label>
        <input
          formControlName="country"
          matInput
          type="text"
          ng2TelInput
          [ng2TelInputOptions]="{ initialCountry: '', allowDropdown: true }"
          (countryChange)="onCountryChange($event)"
        />
      </mat-form-field>
    </form>
  </mat-tab>
  <mat-tab>
    <ng-template *ngIf="skin === 'GEO4' || skin === 'CVTP'" mat-tab-label>
      {{ 'Area of interest' | translate }}
    </ng-template>
    <!-- Area of interest Form -->
    <form [formGroup]="areaOfInterestForm">
      <div class="row area-of-interest"></div>
      <div class="row">
        <div
          class="remove-padding-from-left-col"
          [class.col-md-2]="skin === 'GEO4' || skin === 'CVTP'"
        >
          <div class="global-select-label">{{ 'Radius' | translate }}(km)</div>
          <input
            class="global-input"
            type="number"
            formControlName="radius"
            #radiusInput="matInput"
            matInput
            autocomplete="off"
            (input)="onChange($event.target.value)"
          />
        </div>
        <div [class.col-md-2]="skin === 'GEO4' || skin === 'CVTP'">
          <div class="global-select-label">Lat</div>
          <input
            class="global-input"
            type="number"
            min="-90"
            max="90"
            #latInput="matInput"
            formControlName="lat"
            matInput
            autocomplete="off"
            (input)="onChange($event.target.value)"
          />
        </div>
        <div [class.col-md-2]="skin === 'GEO4' || skin === 'CVTP'">
          <div class="global-select-label">Lon</div>
          <input
            class="global-input"
            type="number"
            min="-180"
            max="180"
            #lngInput="matInput"
            formControlName="lng"
            matInput
            autocomplete="off"
            (input)="onChange($event.target.value)"
          />
        </div>
        <!-- <div class="col-md-3 end-md">
          <button mat-button (click)="savePlace()">
            <mat-icon class="clickable">save</mat-icon> {{'Save area' | translate}}
          </button>
        </div> -->
        <div [class.col-md-2]="skin === 'GEO4' || skin === 'CVTP'">
          <button class="draw-place" mat-button (click)="drawPlace()">
            <img
              src="assets/static/images/geo_fence.svg"
              height="27"
              width="30"
            />
            {{ 'Draw area' | translate }}
          </button>
        </div>
      </div>
    </form>
  </mat-tab>
  <!-- <mat-tab label="Point of Interest">
  </mat-tab> -->
</mat-tab-group>
<div *ngIf="showError" class="mat-error">
  {{ 'Please fill in all fields' | translate }}
</div>
