<div class="progress-loader">
  <div class="main-loader">
    <app-multi-stage-loader
      [title]="displayText"
      [query]="searchValue || ('Intelligence search' | translate)"
      [progressBarValue]="progressBarValue$ | async"
      [progressBarElements]="progressBarElements"
      [resultsCount]="resultsCount"
      [showResultsCount]="keywordsLoader ? false : true"
    ></app-multi-stage-loader>
  </div>
</div>
