import { Component, Input } from '@angular/core';
import { BadgeIconType, BadgeType } from './badge.model';

@Component({
  selector: 'intellectus-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() type: BadgeType = BadgeType.DEFAULT;
  @Input() icon = '';
  @Input() iconType: BadgeIconType = BadgeIconType.REGULAR;
}
