<div class="geo-marker">
  <div class="custom-geo-marker-toolbox">
    <div
      (click)="toggleGeofencingTask()"
      angulartics2On="click"
      [angularticsAction]="matomo.actions.createGeofence"
      [angularticsCategory]="matomo.categories.gioMapPin"
      class="pin-toolbox-action third"
      [class.disabled-toolbox-action]="!showGeoMarkerToolbox"
      [class.follow-enabled]="geofencingIsEnabled"
      [title]="
        (geofencingIsEnabled ? 'Stop Geofencing Task' : geofencingTitle)
          | translate
      "
    >
      <img
        class="geofence-icon"
        src="{{ imagesPath }}toolbox_aoi.svg"
        height="100"
      />
    </div>
    <div
      (click)="showBTS()"
      angulartics2On="click"
      [angularticsAction]="matomo.actions.neighbourCells"
      [angularticsCategory]="matomo.categories.gioMapPin"
      class="pin-toolbox-action third"
      [class.disabled-toolbox-action]="!showGeoMarkerToolbox"
      [class.cached-antennas]="cachedAntennas"
      title="{{ cellsTitle }}"
    >
      <img
        class="cells-icon"
        src="{{ imagesPath }}toolbox_cell-tower.svg"
        height="110"
      />
    </div>
    <div
      class="pin-toolbox-action third"
      angulartics2On="click"
      [angularticsAction]="matomo.actions.createScheduleTask"
      [angularticsCategory]="matomo.categories.gioMapPin"
      appDebounceClick
      (debounceClick)="toggleScheduleTask()"
      [debounceTime]="4000"
      [class.disabled-toolbox-action]="!showGeoMarkerToolbox"
      [class.follow-enabled]="followIsEnabled"
      [ngClass]="{ disabled: pendingSchedulerAction }"
      [title]="
        (followIsEnabled ? 'Stop following' : schedulerTitle) | translate
      "
    >
      <img
        class="schedule-icon"
        src="{{ imagesPath }}toolbox_schedule.svg"
        height="100"
      />
    </div>
    <div
      (click)="toggleGeoMarkerToolbox()"
      class="pin-toolbox-action pin-icon"
      [class.enabled-toolbox-pin]="showGeoMarkerToolbox"
      [title]="
        (showGeoMarkerToolbox ? 'Hide options' : 'Click for more options')
          | translate
      "
    >
      <img class="location-image" src="{{ imagesPath + defaultMarkerSrc }}" />
    </div>
  </div>
</div>
