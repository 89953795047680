import { Component } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { QueryService } from 'src/app/services/query/query.service';
import { Platform } from 'src/app/shared/schemas/common/platforms';

@Component({
  selector: 'app-geolocation-im-tooltip',
  templateUrl: './geolocation-im-tooltip.component.html',
  styleUrls: ['./geolocation-im-tooltip.component.scss'],
})
export class GeolocationImTooltipComponent {
  constructor(public queryService: QueryService) {}
  allPlatforms = Platform;
}

export interface ImTooltip {
  name?: string;
  images?: string[] | SafeResourceUrl[];
  lastSeen?: string;
  platform?: string;
}
