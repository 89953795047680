import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { INTELLECTUS_STEPPER_OPTIONS, StepperOptions } from '../stepper.conf';

@Component({
  selector: 'intellectus-stepper-nav',
  standalone: true,
  imports: [NgFor, NgIf],
  templateUrl: './stepper-nav.component.html',
  styleUrls: ['./stepper-nav.component.scss'],
})
export class StepperNavComponent {
  @Input() currentStep!: number;
  @Output() selectStep = new EventEmitter<number>();
  @Input() stepTitles!: Array<string | undefined>;

  constructor(
    @Optional()
    @Inject(INTELLECTUS_STEPPER_OPTIONS)
    public stepperOptions?: StepperOptions
  ) {}

  onStepSelect(index: number) {
    if (!this.stepperOptions?.blockDirectNavigation) {
      this.selectStep.emit(index);
    }
  }
}
