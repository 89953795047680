<div class="row center-xs reset-password-content">
  <div class="col-12">
    <div class="reset-password-title">{{ 'Reset Password' | translate }}</div>
    <form [formGroup]="resetPasswordForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Password' | translate }}</mat-label>
        <input
          formControlName="currentPassword"
          matInput
          type="password"
          required
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'New Password' | translate }}</mat-label>
        <input
          formControlName="newPassword"
          matInput
          type="password"
          minlength="8"
          required
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Confirm New Password' | translate }}</mat-label>
        <input
          formControlName="confirmNewPassword"
          matInput
          type="password"
          minlength="8"
          required
        />
      </mat-form-field>
      <button
        mat-button
        type="button"
        class="reset-password-btn"
        (click)="onResetPassword()"
      >
        {{ 'Submit' | translate }}
      </button>
    </form>
  </div>
</div>
