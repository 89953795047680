<div class="purchase-info-popup assistant-font" id="purchase-info">
  <div class="dialog-header flex-wrp">
    <p class="title">{{ title || 'Confirmation Required' }}</p>
    <mat-icon (click)="dialogRef.close()">close</mat-icon>
  </div>
  <div class="dialog-body">
    <p>{{ message }}</p>
  </div>

  <div class="btn-wrp">
    <button class="cp-btn-ghost" mat-button (click)="close()">
      {{ 'Cancel' }}
    </button>
    <app-credit-cost-button
      [action]="action"
      [buttonText]="dialogType"
      [active]="true"
      [stylingClasses]="'cp-btn-primary cp-search-btn active'"
      (clicked)="onSuccess()"
    ></app-credit-cost-button>
  </div>
</div>
