import { Media } from 'datalayer/models/media/media';
import {
  Education as PlatformEducation,
  EntityRelationType,
} from 'datalayer/models/platform-models';
import { BaseTargetEntity } from 'datalayer/models/platform-models/base/base-target-entity';

export class Education extends PlatformEducation implements BaseTargetEntity {
  targetId: string;
  profileId?: string;
  relationType?: EntityRelationType;
  image?: Partial<Media>;
  imageUrl?: string;

  constructor(model?: Partial<Education>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }
}
