<app-core-toastr [class.open]="!isHidden">
  <app-core-toastr-header (close)="removeGroup()">
    <div class="target-toastr__title font-medium flex-wrp">
      <span>{{ osintType$ | async | translate }}</span>
      <span>{{ targetAlias$ | async }}</span>
    </div>
    <mat-icon class="arrow" (click)="isHidden = !isHidden">play_arrow</mat-icon>
  </app-core-toastr-header>

  <app-core-toastr-body
    *ngIf="toasts$ | async as toasts"
    [class.hidden]="isHidden"
  >
    <div class="target-toastr__toasts">
      <ng-container
        *ngIf="
          (toasts$ | async) && (inProgress$ | async) === true;
          else doneTemplate
        "
      >
        <div
          class="target-toastr__toast-item flex-wrp"
          *ngFor="let toast of toasts"
        >
          <div class="target-toastr__source">
            {{
              toast.platform === 'database'
                ? ('closed databases' | translate)
                : toast.platform
            }}
          </div>
          <div class="target-toastr__progress">
            <mat-progress-bar
              mode="indeterminate"
              color="accent"
            ></mat-progress-bar>
          </div>
        </div>
      </ng-container>
    </div>
  </app-core-toastr-body>
</app-core-toastr>

<ng-template #doneTemplate>
  <div class="target-toastr__osint-done flex-vert-center">
    <mat-icon class="target-toastr__done-icon">check_circle</mat-icon>
    {{ 'Completed successfully' | translate }} &nbsp;
    <a
      class="target-toastr__overview-link cursor-pointer"
      (click)="goToTargetPage()"
      >{{ redirectionText$ | async | translate }}</a
    >
  </div>
</ng-template>
