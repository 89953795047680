import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: string, keywords: string[]): any {
    if (!value || !keywords || !Array.isArray(keywords)) {
      return value;
    }
    keywords = keywords.filter((keyword) => !!keyword?.trim());
    if (!keywords.length) {
      return value;
    }
    return keywords.reduce((result, word) => {
      const reg = new RegExp(this.escapeRegExp(word), 'gi');
      result = result.replace(reg, '<mark>$&</mark>');
      return result;
    }, value.toString());
  }

  private escapeRegExp(string) {
    return string?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
