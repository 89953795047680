import {
  BaseService,
  EmptyCacheService,
  ModelStore,
  RequestOptions,
} from 'datalayer/services/base';
import { Company } from 'datalayer/models/social/company';
import { CompanyApiService } from 'src/app/modules/data-layer/services/social/company/company-api.service';
import { Injectable } from '@angular/core';
import { CompanyDTO } from 'src/app/modules/data-layer/services/social/company/company-dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService<
  Company,
  CompanyDTO,
  CompanyApiService,
  EmptyCacheService<Company>
> {
  constructor(
    apiService: CompanyApiService,
    localCacheService: EmptyCacheService<Company>
  ) {
    super(apiService, localCacheService);
  }

  getAll(options?: RequestOptions): Observable<ModelStore<Company>> {
    options.filters = {
      sort: 'started_at',
      sortBy: -1,
      ...options.filters,
    };
    return super.getAll(options);
  }
}
