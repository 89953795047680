<div class="tactical-dashboard-content">
  <div class="col-12 map" [class.hidden]="!showMap">
    <app-map
      requestedMap="vanilla-google"
      [zoom]="zoom"
      [center]="center"
      [markers]="markers"
      [circles]="circles"
      [heatmapEnabled]="heatmapEnabled"
      [enableClustering]="clusteringEnabled"
      [buttons]="buttons"
      [trafficEnabled]="trafficEnabled"
      [showExpandBtn]="false"
      [mapOptions]="mapOptions"
      [polygons]="polygon"
      [enableDrawing]="enableDrawing"
    >
    </app-map>
  </div>
  <div class="col-12 center-xs toggle">
    <img
      src="assets/static/images/toggle_view_line.svg"
      class="clickable"
      height="5"
      matTooltip="{{ 'Toggle map' | translate }}"
      (click)="toggleMap()"
    />
  </div>
  <div class="col-12">
    <div>
      <mat-tab-group
        class="dashboard-tabs"
        (selectedTabChange)="onTabChange($event)"
      >
        <mat-tab label="{{ 'Satellite' | translate }}"></mat-tab>
        <mat-tab label="IMSI {{ 'Catcher' | translate }}"></mat-tab>
        <!-- <mat-tab label="L.Ι."></mat-tab> -->
      </mat-tab-group>
    </div>
  </div>
  <app-si-log-table
    *ngIf="tabIndex === 0"
    [fullScreen]="!showMap"
    (emittedMultiselectConversation)="multiselectConversation($event)"
    (emittedSelectedConversation)="selectedConversation($event)"
    (refreshMap)="refreshMap()"
  >
  </app-si-log-table>
  <app-ic-log-table
    *ngIf="tabIndex === 1"
    [fullScreen]="!showMap"
    (emittedMultiselectImsiCatcher)="multiselectImsiCatcher($event)"
    (emittedSelectedImsiCatcher)="selectedImsiCatcher($event)"
    (refreshMap)="refreshMap()"
  >
  </app-ic-log-table>
  <!-- <app-li-log-table
    *ngIf="tabIndex === 2"
    [fullScreen]="!showMap"
    (emittedMultiselectLawEnforcement)="multiselectLawEnforcement($event)"
    (emittedSelectedLawEnforcement)="selectedLawEnforcement($event)"
    (refreshMap)="refreshMap()">
  </app-li-log-table> -->
</div>
