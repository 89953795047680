<svg
  role="img"
  width="1000"
  height="600"
  aria-labelledby="loading-aria"
  viewBox="0 0 500 300"
  preserveAspectRatio="none"
>
  <title id="loading-aria">Loading...</title>
  <rect
    x="0"
    y="0"
    width="100%"
    height="100%"
    clip-path="url(#clip-path)"
    style="fill: url('#fill')"
  ></rect>
  <defs>
    <clipPath id="clip-path">
      <circle cx="174" cy="293" r="4" />
      <rect x="179" y="290" rx="2" ry="2" width="27" height="7" />
      <circle cx="216" cy="293" r="4" />
      <rect x="222" y="290" rx="2" ry="2" width="27" height="7" />
      <circle cx="261" cy="293" r="4" />
      <rect x="266" y="290" rx="2" ry="2" width="27" height="7" />
      <circle cx="303" cy="293" r="4" />
      <rect x="308" y="290" rx="2" ry="2" width="27" height="7" />
      <circle cx="346" cy="293" r="4" />
      <rect x="350" y="290" rx="2" ry="2" width="27" height="7" />
      <circle cx="388" cy="293" r="4" />
      <rect x="393" y="290" rx="2" ry="2" width="27" height="7" />
      <circle cx="291" cy="107" r="24" />
      <circle cx="167" cy="34" r="13" />
      <circle cx="329" cy="12" r="11" />
      <circle cx="96" cy="197" r="13" />
      <circle cx="268" cy="179" r="11" />
      <circle cx="346" cy="209" r="9" />
      <circle cx="438" cy="120" r="10" />
      <circle cx="458" cy="191" r="10" />
      <rect x="119" y="131" rx="0" ry="0" width="0" height="99" />
    </clipPath>
    <linearGradient id="fill">
      <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
        <animate
          attributeName="offset"
          values="-2; -2; 1"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
      <stop offset="1.59996" stop-color="#dedede" stop-opacity="1">
        <animate
          attributeName="offset"
          values="-1; -1; 2"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
      <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
        <animate
          attributeName="offset"
          values="0; 0; 3"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
    </linearGradient>
  </defs>
</svg>
