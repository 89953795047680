import { TitleCasePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ChartData, Link, Node } from '@trg-ui/link-analysis';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  customDBEdge,
  DBEntity,
  DBGraph,
} from 'src/app/modules/link-analysis/shared/custom-entities.model';
import {
  EntityAttributes,
  linkTypes,
  nodeTypes,
} from 'src/app/modules/link-analysis/shared/link-analysis.model';
import { BaseService } from 'src/app/services/base.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';
import { ProxyWsService } from '../../ad-ids/shared/proxy-ws.service';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class LinkAnalysisDataService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private proxyService: ProxyWsService,
    public titleCasePipe: TitleCasePipe
  ) {
    super(router, snackBar);
  }

  getCallLogData(
    telno: string
  ): Observable<{ items: (Node | Link)[]; targetTelno: string; type: string }> {
    return this.httpClient
      .get<any>(`${environment?.fastAPIUri}/cdr/${telno}/graph`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data.data);
        }),
        catchError((error) => of(error))
      );
  }

  private handleNotFoundError(error: HttpErrorResponse) {
    if (error.status === 404) {
      return of({ items: [] });
    }
  }

  saveGraphState(
    chart: ChartData,
    filters: string,
    image: string,
    entityId: string
  ) {
    return this.httpClient
      .post<any>(`${this.url}/${entityId}/link-analysis-graph`, {
        chart,
        filters,
        image,
      })
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getGraphState(entityId: string) {
    return this.httpClient
      .get<any>(`${this.url}/${entityId}/link-analysis-graph`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data.data);
        }),
        catchError(this.handleNotFoundError)
      );
  }

  getMutualFriends(user1Id: string, user2Id: string): Observable<any> {
    return this.httpClient
      .get<any>(
        `${this.url}/intel/facebook/mutual-friends/${user1Id}/${user2Id}`
      )
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getLinkedinCompanyEmployees(companyUrl: string): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/intel/linkedin/company-employees/${companyUrl}`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  public moveOsintDataToNewTarget(
    temporaryTargetId: string,
    newTargetId: string
  ): Observable<any> {
    return this.httpClient
      .put<any>(
        `${this.url}/move-entities/${temporaryTargetId}/${newTargetId}`,
        {}
      )
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  public createFilterQuery(
    targetId: string,
    subject: MessageSubject,
    limits?: number[],
    fileId?: string,
    postId?: string
  ): string {
    const uuid4 = uuidv4();
    const message = {
      subject,
      body: {
        targetId: targetId,
        correlationId: uuid4,
      },
    };
    if (limits) {
      message.body['degreeFloor'] = limits[0];
      message.body['degreeUpper'] = limits[1];
    }
    if (fileId) {
      message.body['fileId'] = fileId;
    }
    if (postId) {
      message.body['postId'] = postId;
    }
    this.proxyService.sendMessage(message);
    return uuid4;
  }

  public getSimsFromSim(key: string): Observable<DBGraph> {
    return this.httpClient
      .get(`${environment.fastAPIUri}/link-analysis/sims/${key}/sims`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError(this.handleError)
      );
  }

  public getLocationFromSim(key: string): Observable<DBGraph> {
    return this.httpClient
      .get(`${environment.fastAPIUri}/link-analysis/sims/${key}/locations`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError(this.handleError)
      );
  }

  public getProfileFromSim(key: string): Observable<DBGraph> {
    return this.httpClient
      .get(`${environment.fastAPIUri}/link-analysis/sims/${key}/profiles`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError(this.handleError)
      );
  }

  public getSimFromProfile(key: string): Observable<DBGraph> {
    return this.httpClient
      .get(`${environment.fastAPIUri}/link-analysis/profiles/${key}/sims`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError(this.handleError)
      );
  }

  public getSocialConnectionsFromProfile(key: string): Observable<DBGraph> {
    return this.httpClient
      .get(`${environment.fastAPIUri}/link-analysis/profiles/${key}/profiles`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError(this.handleError)
      );
  }

  public getSimsFromDevice(key: string): Observable<DBGraph> {
    return this.httpClient
      .get(`${environment.fastAPIUri}/link-analysis/devices/${key}/sims`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError(this.handleError)
      );
  }

  public getDevicesFromSim(key: string): Observable<DBGraph> {
    return this.httpClient
      .get(`${environment.fastAPIUri}/link-analysis/sims/${key}/devices`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError(this.handleError)
      );
  }

  public createNode(node: Node): Observable<DBEntity> {
    const params = this.getParamsPerNodeType(node);
    return this.httpClient
      .post(`${environment.fastAPIUri}/link-analysis/graph/nodes`, params)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError(this.handleError)
      );
  }

  private getParamsPerNodeType(node: Node): {
    entityType: string;
    entityAttributes: EntityAttributes;
  } {
    switch (node.d.type) {
      case nodeTypes.MSISDN:
        return {
          entityType: 'Sim',
          entityAttributes: {
            msisdn: node.id,
          },
        };
      case nodeTypes.LOCATION:
        return {
          entityType: 'Location',
          entityAttributes: {
            lat: node.pos.lat,
            lon: node.pos.lng,
          },
        };
      case nodeTypes.SOCIAL_PROFILE:
        return {
          entityType: 'Profile',
          entityAttributes: {
            source: this.titleCasePipe.transform(node.d.relation),
            url: node.d.url,
            sourceId: node.id.substring(0, node.id.indexOf('@')),
          },
        };
      case nodeTypes.PHONE:
        return {
          entityType: 'Device',
          entityAttributes: {
            imei: node.id,
          },
        };
    }
  }

  public deleteNode(node: Node): Observable<{}> {
    return this.httpClient
      .delete(
        `${environment.fastAPIUri}/link-analysis/graph/nodes/${node.d.key}?entity_type=${node.d.entityType}`
      )
      .pipe(catchError(this.handleError));
  }

  public createEdge(
    fromNode: Node,
    toNode: Node,
    link: Link
  ): Observable<customDBEdge> {
    const linkType =
      link.d.type === linkTypes.SOCIAL_PROFILE ? 'AssociatedWith' : link.d.type;
    const params = {
      fromEntity: {
        entityKey: fromNode.d.key,
        entityType: fromNode.d.entityType,
      },
      toEntity: {
        entityKey: toNode.d.key,
        entityType: toNode.d.entityType,
      },
      relationshipType: linkType,
    };
    return this.httpClient
      .post(`${environment.fastAPIUri}/link-analysis/graph/edges`, params)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError(this.handleError)
      );
  }

  public deleteEdge(fromEntityType: string, toEntityType: string, key: string) {
    return this.httpClient
      .delete(
        `${environment.fastAPIUri}/link-analysis/graph/edges/${key}?from_entity_type=${fromEntityType}?to_entity_type=${toEntityType}`
      )
      .pipe(catchError(this.handleError));
  }

  public getCdrInteractions(msisdns: string[]): Observable<string[]> {
    return this.httpClient
      .post(`${environment.fastAPIUri}/cdr/interactions-msisdns`, { msisdns })
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError(this.handleError)
      );
  }
}
