import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaProIconComponent } from './fa-pro-icon/fa-pro-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FaProIconComponent],
  exports: [FaProIconComponent],
})
export class FaProModule {}
