import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ImageOverlayComponent } from '../components/image-overlay/image-overlay.component';

@Directive({
  selector: '[imageOverlay]',
  exportAs: 'overlayDirective',
})
export class ImageOverlayDirective implements OnInit {
  @Input('imageOverlay') text = '';
  @Input() imageOverlayDisabled: boolean;
  @Input() overlayWidth: string;
  @Input() overlayHeight: string;
  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -8,
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  show() {
    if (this.imageOverlayDisabled) {
      return;
    }

    const tooltipRef: ComponentRef<ImageOverlayComponent> =
      this.overlayRef.attach(new ComponentPortal(ImageOverlayComponent));
    tooltipRef.instance.text = this.text;

    if (this.overlayWidth) {
      tooltipRef.instance.width = this.overlayWidth;
    }
    if (this.overlayHeight) {
      tooltipRef.instance.height = this.overlayHeight;
    }
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }
}
