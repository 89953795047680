<div class="avatars mr-20" *ngIf="avatars.length">
  <div class="avatars-inner">
    <div
      class="main-avatar"
      (click)="showRestAvatars()"
      (clickOutside)="hideRestAvatars()"
    >
      <ng-container
        [ngTemplateOutlet]="avatars[0].customTpl ? custom : defaultTpl"
        [ngTemplateOutletContext]="{ $implicit: avatars[0] }"
      >
      </ng-container>
      <ng-template #defaultTpl>
        <img
          [src]="avatars[0].src"
          overlayWidth="170px"
          overlayHeight="auto"
          [imageOverlay]="avatars[0].src"
          [imageOverlayDisabled]="avatars[0].overlayDisabled"
          (error)="onError(avatars[0])"
          appFallbackImg="/assets/static/images/linked-person.svg"
          alt="avatar"
        />
        <img
          class="platform"
          [src]="getPlatformImage(avatars[0].platform)"
          alt=""
          [matTooltip]="avatars[0].platform | titlecase"
        />
        <span class="avatars-count" *ngIf="avatars.length > 1">{{
          avatars.length
        }}</span>
      </ng-template>
      <ng-template #custom let-avatar>
        <ng-container
          [ngTemplateOutlet]="customMainAvatarTplRef"
          [ngTemplateOutletContext]="{ $implicit: avatar }"
        ></ng-container>
        <span class="avatars-count" *ngIf="avatars.length > 1">{{
          avatars.length
        }}</span>
      </ng-template>
    </div>

    <div class="rest-avatars" *ngIf="showAllThumbnails && avatars.length > 1">
      <ng-container *ngFor="let avatar of avatars.slice(1)">
        <div class="avatar-container">
          <img
            class="rest-avatar-item"
            [src]="avatar.src"
            [imageOverlay]="avatar.src"
            [imageOverlayDisabled]="avatar.overlayDisabled"
            #overlayDir="overlayDirective"
            overlayWidth="170px"
            overlayHeight="auto"
            appFallbackImg="/assets/static/images/linked-person.svg"
            (error)="onError(avatar)"
            [alt]="avatar.platform"
          />
          <img
            class="platform"
            [src]="getPlatformImage(avatar.platform)"
            alt=""
            [matTooltip]="avatar.platform | titlecase"
          />
        </div>
      </ng-container>
    </div>
  </div>
</div>
