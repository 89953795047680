<div *ngIf="show" class="map-overview-slider">
  <div *ngIf="showPlaybackControls" class="history-player">
    <div class="controls">
      <button
        *ngIf="playerState === 'stopped' || playerState === 'paused'"
        mat-icon-button
        matTooltip="Play"
        (click)="playerAction('playing')"
      >
        <mat-icon>play_arrow</mat-icon>
      </button>

      <button
        *ngIf="playerState === 'finished'"
        mat-icon-button
        matTooltip="Replay"
        (click)="playerAction('replay'); playerAction('playing')"
      >
        <mat-icon>replay</mat-icon>
      </button>

      <button
        *ngIf="playerState === 'playing' || playerState === 'replay'"
        mat-icon-button
        matTooltip="Pause"
        (click)="playerAction('paused')"
      >
        <mat-icon>pause</mat-icon>
      </button>

      <button
        *ngIf="playerState === 'playing' || playerState === 'paused'"
        mat-icon-button
        matTooltip="Stop"
        (click)="playerAction('stopped')"
      >
        <mat-icon>stop</mat-icon>
      </button>
    </div>
  </div>

  <app-minimalist-chart
    class="minimalist-chart"
    [bars]="bars"
  ></app-minimalist-chart>
  <nouislider
    #timelineSlider
    [config]="sliderConfig"
    (change)="onSliderChange($event)"
  ></nouislider>
</div>
