import { DataSource } from 'datalayer/models/platform-models';
import { Person } from 'datalayer/models';
import { Place } from 'datalayer/models';
import { CheckIn } from 'src/app/modules/search-intel/models/search-intel.model';
import { Platforms } from '@shared/models/radical-monitoring-options.model';
import { getMediaFromGoogleCheckin } from 'src/app/modules/profiler/helpers/get-media-from-google-checkin';

export function getCheckinsFromPersons(
  persons: Person[],
  targetID: string
): Place[] {
  return persons.reduce((acc: Place[], person: Person): Place[] => {
    return [
      ...acc,
      ...person.checkIn.map((checkIn: CheckIn): Place => {
        return new Place({
          targetId: targetID,
          coordinates: {
            lng: checkIn.coordinates.coordinates[0],
            lat: checkIn.coordinates.coordinates[1],
          },
          name: checkIn.name,
          address: checkIn?.address,
          visitedAt: new Date(checkIn.visited_at),
          sourceEntity: person.sourceEntity,
          source: DataSource.Google,
          platform: Platforms.GOOGLE,
          media: checkIn.media,
          description: checkIn.review,
          image: checkIn?.media.length
            ? getMediaFromGoogleCheckin(
                person,
                checkIn,
                checkIn.media[0],
                targetID
              )
            : undefined,
        });
      }),
    ];
  }, []);
}
