import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Post } from 'datalayer/models/social';
import { PlatformNames } from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';
import { ImageService } from 'src/app/services/image/image.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { InvestigationTooltipHelpers } from '../../../visual-investigation/helpers/investigation-tooltip.helpers';
import { InvestigationStylesService } from '../../../visual-investigation/services/investigation-styles.service';

@Component({
  selector: 'app-investigation-social-post',
  templateUrl: './investigation-social-post.component.html',
  styleUrls: ['./investigation-social-post.component.scss'],
  providers: [InvestigationTooltipHelpers],
})
export class InvestigationSocialPostComponent implements OnChanges {
  entityEventSocialPost: { [key: string]: string | {} };
  @Input() post: Post;
  constructor(
    private targetService: TargetService,
    private tooltipHelper: InvestigationTooltipHelpers,
    private imageService: ImageService,
    private datepipe: DatePipe,
    private styleService: InvestigationStylesService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.post?.currentValue) {
      this.getSocialPostData(this.post);
    }
  }

  private getSocialPostData(post: Post) {
    this.targetService
      .getTarget(post.targetId)
      .subscribe((target: TargetItem) => {
        this.entityEventSocialPost = {
          action: this.tooltipHelper.checkEventActionType(
            post.type.toLocaleLowerCase()
          ),
          owner: target.alias,
          text: post.body,
          ownerImage: target?.photos?.length
            ? <string>this.imageService.getPhotoUrl(target.photos[0], true)
            : '',
          url: post.url,
          date: post.publishedAt
            ? this.datepipe.transform(
                new Date(post.publishedAt),
                'd MMMM y, HH:mm'
              )
            : null,
          platform: PlatformNames[post.source]?.toLocaleLowerCase(),
          color: `#${
            this.styleService.getGroupAndColor(
              post.sourceEntity.parentId?.toLowerCase()
            ).color
          }`,
          data: {
            post,
            type: post.type,
            targetId: target.id,
          },
        };
      });
  }
}
