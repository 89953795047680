import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profileIdExtractor',
})
export class ProfileIdExtractorPipe implements PipeTransform {
  private readonly separator: string = '@';

  public transform(value: string): string {
    if (!value?.includes(this.separator)) {
      return value;
    }

    return value.split(this.separator)[0];
  }
}
