<ngx-file-drop
  dropZoneLabel="Drop files here"
  [multiple]="acceptMultipleFiles"
  (onFileDrop)="onDropped($event)"
  [accept]="acceptedFileTypes"
  [directory]="acceptDirectorySelect"
>
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector"
  >
    <div class="upload-content">
      {{ 'Drag file here' | translate }}
      <div class="mt-10">{{ 'or' | translate }}</div>
      <button
        mat-button
        class="cp-btn-primary mt-20"
        (click)="openFileSelector()"
      >
        {{ 'Choose file to upload' | translate }}
      </button>
    </div>
  </ng-template>
</ngx-file-drop>
<div class="info row file-info" *ngIf="selectedFileInfo">
  <div class="mt-20">
    {{ 'File name' | translate}} : {{ selectedFileInfo.name }} <br />
    {{ 'File size' | translate}} : {{ selectedFileInfo.size | fileSize }}
  </div>
</div>
