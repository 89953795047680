import { ImProfile } from 'datalayer/models/platform-models/im-profiles/im-profile';

export enum QueryStatus {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  DONE = 'DONE',
  FAILED = 'FAILED',
  LOCATION_PENDING = 'LOCATION PENDING',
  NO_LOCATION = 'NO LOCATION',
  NOT_ENOUGH_CREDITS = 'NOT ENOUGH CREDITS',
}

export enum IntervalMinutes {
  MAX = 1,
  SAFE_CORPORATE_EMERGENCY = 5,
  HIGH = 15,
  LOW = 360,
  MEDIUM = 60,
}

export enum QueryFrequency {
  HIGH = 'high',
  LOW = 'low',
  MEDIUM = 'medium',
}

export enum LogFilters {
  LOCATED = 'located',
  NOT_LOCATED = 'notLocated',
}

export enum QueryType {
  LOGON = 'LOGON',
  SIMPLE = 'SIMPLE',
  FENCED_AREA = 'FENCED_AREA',
  FENCED_QUARANTINE = 'FENCED_QUARANTINE',
  FENCED_COUNTRY = 'FENCED_COUNTRY',
  SCHEDULED = 'SCHEDULED',
  NEAR_POI = 'NEAR_POI',
  PROXIMITY = 'PROXIMITY',
}

export const enum GeoPointTypes {
  POINT = 'Point',
  LINESTRING = 'LineString',
  POLYGON = 'Polygon',
  MULTIPOINT = 'MultiPoint',
  MULTILINESTRING = 'MultiLineString',
  MULTIPOLYGON = 'MultiPolygon',
}
export interface GeoJSONPoint {
  type: GeoPointTypes;
  coordinates: number[];
}

export interface QueryArgs {
  telno?: string;
  imsi?: string;
}

interface Provider {
  name?: string;
  telno?: string;
  imsi?: string;
  mcc?: string;
  mnc?: string;
  roamingName?: string;
  subscriptionStatus?: SubscriptionStatus;
}

export interface Device {
  imei?: string;
  phoneModel?: string;
  networkStatus?: NetworkStatus;
  softwareInfo?: string;
}

export interface Schedule {
  id: string;
  scheduleType?: string;
  startsAt?: Date;
  endsAt?: Date;
  intervalMinutes?: number;
  nextCallAt?: Date;
  canceledAt?: Date;
  payload: any;
}

interface Cell {
  mcc?: string;
  mnc?: string;
  lac?: number;
  cellId?: number;
  cellType?: number;
  enbid?: number;
  lcid?: number;
  zone?: string;
}

interface CallInfo {
  callDirection: string;
  oncallNumber: string;
  billingId?: string;
}

export enum CallInfoDirection {
  OUTGOING = 'OUTGOING',
  INCOMING = 'INCOMING',
}

interface Interception {
  conversationId: string;
  satelliteId: string;
  satellite: string;
  direction?: string;
  conversationType: string;
  caller?: string;
  callee?: string;
  area?: string;
  receivedQuality?: number;
  snr?: number;
  beamId?: string;
  beamLongitude?: number;
  beamLatitude?: number;
  downlinkFrequency?: number;
  uplinkFrequency?: number;
  conversationAnswered?: boolean;
  startTime?: Date;
  endTime?: Date;
  file?: string;
  smsText?: string;
  audioText?: string;
}

interface GPS {
  locationType: 'GPS' | 'AGPS';
  deviceLocation: GeoJSONPoint;
  billingId?: string;
}

export interface NMR {
  trialterationLocation?: GeoJSONPoint;
  trialterationAccuracyMeters?: number;
  sectorSize?: number;
  combasBearing?: number;
  bearingDirection?: string;
  distance?: number;
  sectorLocation?: GeoJSONPoint;
  billingId?: string;
}

export interface Azimuth {
  combasBearing?: number;
  bearingDirection?: string;
  sectorLocation?: GeoJSONPoint;
  sectorSize: number;
}

export interface Query {
  id: string;
  parentId: string;
  status: QueryStatus;
  schedule: Schedule;
  queryArgs: QueryArgs;

  callInfo?: CallInfo;
  device?: Device;
  provider?: Provider;
  cell?: Cell;

  location?: GeoJSONPoint;
  accuracyMeters?: number;
  azimuth?: Azimuth;
  gps?: GPS;
  nmr?: NMR;

  ageOfLocationInMinutes?: number;
  locationReceivedAt?: Date;

  queryCount?: number;
  historyLocationCount?: number;
  roaming?: boolean; // can be yes/no (bool)

  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;

  address?: string;
  type?: string;
  interception?: Interception;
  alias?: string;
  targetId?: string;
  instantMessageProfiles?: ImProfile[];
  supportActiveQuery?: boolean;
  lastKnownLocation?: boolean;
  ipAddress?: string;
}

export enum SortMode {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  UNKNOWN = 'UNKNOWN',
  BLOCKED = 'BLOCKED',
}

export enum NetworkStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  BUSY = 'BUSY',
  UNKNOWN = 'UNKNOWN',
}

export const deviceStateDetails: {
  [key in SubscriptionStatus | NetworkStatus]: { text: string; color: string };
} = {
  [SubscriptionStatus.ACTIVE]: {
    text: 'Target’s subscription is active',
    color: 'green-font',
  },
  [SubscriptionStatus.DEACTIVATED]: {
    text: 'Target’s subscription is not activated',
    color: 'red-font',
  },
  [SubscriptionStatus.BLOCKED]: {
    text: 'Target’s subscription is blocked',
    color: 'red-font',
  },
  [NetworkStatus.ONLINE]: {
    text: 'Target is connected to the network',
    color: 'green-font',
  },
  [NetworkStatus.OFFLINE]: {
    text: 'Target’s subscription is active but currently not connected to the network',
    color: 'red-font',
  },
  [NetworkStatus.BUSY]: {
    text: 'Target is currently on a call',
    color: 'green-font',
  },
  [NetworkStatus.UNKNOWN]: {
    text: 'Could not determine the target’s status',
    color: 'black-font',
  },
};

export enum QuerySchedulerDurationHours {
  SIX_HOURS = 6,
  TWELVE_HOURS = 12,
  ONE_DAY = 24,
  TWO_DAYS = 48,
  ONE_WEEK = 168,
}

export enum QuerySchedulerFrequencyMinutes {
  ONE_HOUR = 60,
  FIFTEEN_MINUTES = 15,
  THIRTY_MINUTES = 30,
}
