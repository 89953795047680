<app-core-toastr>
  <app-core-toastr-header (close)="toast.toastRef.close()">
    <div class="flex-wrp">
      {{ 'Importing historical data' | translate }}
      <span>&nbsp;{{ data?.telno }}</span>
    </div>
  </app-core-toastr-header>

  <app-core-toastr-body>
    <div [ngTemplateOutlet]="isImported ? doneTpl : progressTpl"></div>
  </app-core-toastr-body>
</app-core-toastr>

<ng-template #progressTpl>
  <div class="import-in-progress flex-wrp">
    <span>{{ 'Importing data' | translate }}</span>
    <mat-progress-bar
      class="progress"
      mode="indeterminate"
      color="accent"
    ></mat-progress-bar>
  </div>
</ng-template>

<ng-template #doneTpl>
  <div class="done-wrapper">
    <mat-icon class="done-icon">check_circle</mat-icon>
    {{ 'Imported successfully' | translate }}
  </div>
</ng-template>
