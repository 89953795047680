import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
  LayoutService,
} from '@fe-platform/shared-ui/intellectus';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { IntelSearchTrackerService } from 'src/app/services/intel-search-tracker/intel-search-tracker.service';
import { NotificationListService } from '../../notifications-list/notification-list.service';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';
import { ApplicationMainPageUrls } from '@shared/models/application-main-page-urls.enum';

@UntilDestroy()
@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavbarComponent implements AfterViewInit, OnInit {
  @Input()
  public title: string;

  @Input()
  public clickableTitle = false;

  @Input()
  public titleButtonState = false;

  @Input()
  public highlightClass: string;

  @Input()
  public showKnowledgeBase = false;

  @Output()
  public readonly onTitleClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly onMenuClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly onSearchIconClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly onNotificationListClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly onShowKnowledgeBaseClicked: EventEmitter<void> = new EventEmitter<void>();

  public readonly hamburgerIcon: FontAwesomeIcon = {
    name: 'bars',
    family: FontAwesomeFamily.REGULAR,
  };

  public readonly knowledgeBaseIcon: FontAwesomeIcon = {
    name: 'circle-question',
    family: FontAwesomeFamily.REGULAR,
  };

  public readonly notificationIcon: FontAwesomeIcon = {
    name: 'bell',
    family: FontAwesomeFamily.REGULAR,
  };

  public readonly collapseIcon: FontAwesomeIcon = {
    name: 'circle-chevron-down',
    family: FontAwesomeFamily.REGULAR,
  };

  public readonly backCloseIcon: FontAwesomeIcon = {
    name: 'chevron-left',
    family: FontAwesomeFamily.REGULAR,
  };

  public isMobileDevice: boolean;

  public notificationsCount = 0;

  public showBackButtonOnMobile = false;

  public readonly allSearchCount$: Observable<number> =
    this.intelSearchTrackerService.getSearchCount();

  public readonly activeSearchCount$: Observable<number> =
    this.intelSearchTrackerService.getSearchCount(true);

  constructor(
    private readonly intelSearchTrackerService: IntelSearchTrackerService,
    private readonly layoutService: LayoutService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly notificationListService: NotificationListService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.listenNotifications();
    this.listenRouterEvents();
  }

  public ngAfterViewInit(): void {
    this.isMobileDevice = this.layoutService.isMobileDevice();
  }

  public notificationClicked(): void {
    this.onNotificationListClicked.emit();
  }

  public knowledgeBaseClicked(): void {
    this.onShowKnowledgeBaseClicked.emit();
  }

  public goBack(): void {
    history.back();
  }

  private listenNotifications(): void {
    this.notificationListService
      .getNotificationMessages()
      .pipe(untilDestroyed(this))
      .subscribe(({ count }): void => {
        this.notificationsCount = count;
        this.cdRef.markForCheck();
      });
  }

  private listenRouterEvents(): void {
    this.router.events
      .pipe(
        filter(
          (event: NavigationEnd): boolean => event instanceof NavigationEnd
        ),
        startWith(this.router),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((event: NavigationEnd): void => {
        this.showBackButtonOnMobile = event.url.includes(
          `/${ApplicationMainPageUrls.WEBINT}/results`
        );
      });
  }
}
