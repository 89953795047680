<div class="svg-container" *ngFor="let loader of numberOfRowsArray">
  <svg
    role="img"
    aria-labelledby="loading-aria"
    width="361"
    height="176"
    viewBox="0 0 361 176"
    preserveAspectRatio="none"
  >
    <title id="loading-aria">Loading...</title>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      clip-path="url(#clip-path)"
      style="fill: url('#fill')"
    ></rect>
    <rect
      x="0"
      y="0"
      width="360"
      height="174.251"
      rx="3.5"
      fill="none"
      stroke="#ecebeb"
    />
    <defs>
      <clipPath id="clip-path">
        <rect x="38.8301" y="14.4746" width="135" height="20.9282" />
        <rect
          x="20"
          y="136.041"
          width="105.291"
          height="24.2832"
          rx="12.1416"
        />
        <rect
          x="132.333"
          y="135.909"
          width="105.291"
          height="24.2832"
          rx="12.1416"
        />
        <rect x="11.2384" y="14.4746" width="17.5232" height="20.9282" />
        <rect x="208.902" y="81.9746" width="135" height="20.9282" />
        <rect x="309.33" y="14.4746" width="32.0566" height="20.9282" />
        <rect x="266.972" y="14.4746" width="32.0566" height="20.9282" />
        <line x1="11.997" y1="120.403" x2="348.997" y2="118.409" />
        <rect x="237.624" y="63.5278" width="106.278" height="9.375" />
        <rect x="38.8301" y="63.5278" width="106.278" height="9.375" />
        <rect x="38.8301" y="87.7512" width="74.4326" height="9.375" />
      </clipPath>
      <linearGradient id="fill">
        <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          ></animate>
        </stop>
        <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          ></animate>
        </stop>
        <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          ></animate>
        </stop>
      </linearGradient>
    </defs>
  </svg>
</div>
