import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import {
  EnhanceProfileAction,
  EnhanceProfilePopupModel,
} from '../../models/search-intel.model';
import { QuestionnaireService } from 'src/app/services/questionnaire/questionnaire.service';
import { Observable } from 'rxjs';
import {
  BillingActions,
  BillingActionType,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';

@Component({
  selector: 'app-enhance-profile-popup',
  templateUrl: './enhance-profile-popup.component.html',
  styleUrls: ['./enhance-profile-popup.component.scss'],
})
export class EnhanceProfilePopupComponent
  extends BaseComponent
  implements OnInit
{
  onEnhanceProfile: EventEmitter<string> = new EventEmitter<string>();
  enhanceProfileAction = EnhanceProfileAction;
  deepOsintEnabled = false;
  targetCreditsChargesEnabled = false;
  expireTargetDays = 0;
  targetCreditsMessage: string;
  public billingPlan$: Observable<
    BillingPlan<BillingActions, BillingActionType>
  >;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EnhanceProfilePopupModel,
    private appConfigService: AppConfigService,
    private translateService: TranslationService,
    private billingService: BillingService,
    private questionnaireService: QuestionnaireService
  ) {
    super();
    this.deepOsintEnabled =
      this.appConfigService.getConfigVariable('enableDeepOsint');
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
    this.expireTargetDays =
      this.appConfigService.getConfigVariable('expireTargetDays');
  }
  ngOnInit(): void {
    this.targetCreditsMessage = this.translateService.interpolate(
      'Management for a new target is free of charge for #{days} days',
      { days: this.expireTargetDays.toString() }
    );
    this.billingPlan$ = this.billingService.getBillingPlan().asObservable();
  }

  onClickEnhance(type: EnhanceProfileAction, enabled) {
    if (enabled) {
      this.questionnaireService.shouldShowQuestionnaire();
      this.onEnhanceProfile.emit(type);
    }
  }
}
