import { Pipe, PipeTransform } from '@angular/core';
import { stringToColor } from '../util/helper';

@Pipe({
  name: 'stringColor',
  pure: true,
})
export class StringColorPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return stringToColor(value);
  }
}
