<div class="state-indicator-wrapper">
  <ng-container *ngIf="isLoading; else successState">
    <fe-platform-fa-pro-icon
      class="sky-blue"
      [family]="'solid'"
      [name]="'circle-notch'"
      [spin]="true"
    ></fe-platform-fa-pro-icon>
  </ng-container>
  <ng-template #successState>
    <fe-platform-fa-pro-icon
      class="light-green"
      [family]="'solid'"
      [name]="'check-circle'"
    ></fe-platform-fa-pro-icon>
  </ng-template>
  <span>{{ stateText }}</span>
</div>
