<form
  [formGroup]="emailSettings"
  (ngSubmit)="submitForm()"
  class="email-settings"
>
  <div class="email-title">
    {{ 'Email' | translate }} {{ 'Settings' | translate }}
  </div>
  <div class="row">
    <div class="col-6 labels">
      <label *ngFor="let label of labels">{{ label.name }}</label>
    </div>
    <div class="col-6 inputs">
      <input
        *ngFor="let input of inputs"
        matInput
        [formControlName]="input.name"
        [type]="input.type"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-6 col-md-3 labels">
      <label>STARTTLS</label>
    </div>
    <div class="col-6 col-md-3 end-md">
      <mat-slide-toggle formControlName="startTls"></mat-slide-toggle>
    </div>
  </div>
  <div class="col-12 center-xs">
    <button mat-raised-button (click)="submitForm()">
      {{ 'Submit' | translate }}
    </button>
  </div>
</form>
