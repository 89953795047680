import {
  AfterContentInit,
  Component,
  ContentChildren,
  Inject,
  Input,
  OnDestroy,
  Optional,
  QueryList,
  TemplateRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  StepperBtnDirective,
  StepperButtonAction,
} from '../stepper-button/stepper-button.directive';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { INTELLECTUS_STEPPER_OPTIONS, StepperOptions } from '../stepper.conf';

@Component({
  selector: 'intellectus-stepper-item',
  standalone: true,
  imports: [StepperBtnDirective],
  templateUrl: './stepper-item.component.html',
})
export class StepperItemComponent implements AfterContentInit, OnDestroy {
  @Input() set stepControl(stepControl: AbstractControl) {
    this._stepControl = stepControl;

    // Re-watch for status changes if the form has been manipulated
    if (this._stepChangesSubscription) {
      this._stepChangesSubscription.unsubscribe();
      this.watchFormValidity();
      this._stepControl.updateValueAndValidity();
    }
  }
  @Input() title?: string;

  @ViewChild('stepContent', { static: true })
  template!: TemplateRef<never>;

  @ContentChildren(forwardRef(() => StepperBtnDirective), { descendants: true })
  actionBtns?: QueryList<StepperBtnDirective>;

  private _stepControl?: AbstractControl;
  private _stepChangesSubscription?: Subscription;

  constructor(
    @Optional()
    @Inject(INTELLECTUS_STEPPER_OPTIONS)
    public stepperOptions?: StepperOptions
  ) {}

  ngAfterContentInit(): void {
    if (this.stepperOptions?.enableFormValidation) {
      this.watchFormValidity();
    }
  }

  ngOnDestroy(): void {
    this._stepChangesSubscription?.unsubscribe();
  }
  watchFormValidity() {
    const nextButton = this.actionBtns?.find(
      (btn) => btn.type === StepperButtonAction.NEXT
    );
    if (nextButton) {
      /**
       * Intentionally preferred manual subscription ref over a takeUntil approach.
       * That's because this subscription needs to be destroyed not just on ngOnDestroy hook,
       * but also when abstractControl gets manipulated from parent components.
       */
      this._stepChangesSubscription = this._stepControl?.statusChanges
        .pipe(distinctUntilChanged())
        .subscribe((status) => {
          nextButton.disabled$.next(status === 'INVALID');
        });
    }
  }
}
