<div
  class="accordion-header d-flex flex-row align-items-center justify-content-between"
>
  <ng-content></ng-content>

  <intellectus-icon-text-button
    *ngIf="withIcon"
    [buttonIcon]="icon"
    [rotate]="state"
    (buttonClick)="stateToggled.emit()"
  ></intellectus-icon-text-button>
</div>
