export enum BadgeIconType {
  REGULAR = 'regular',
  SOLID = 'solid',
  LIGHT = 'light',
  DUOTONE = 'duotone',
  THIN = 'thin',
}

export enum BadgeType {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARN = 'warn',
  ERROR = 'error',
  PENDING = 'pending',
}
