import { Injectable } from '@angular/core';
import { CQRSBaseEvent, EventChannel } from '@trg-commons/gio-data-models-ts';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { WebsocketManagerService } from 'src/app/services/websocket/websocket-manager.service';
import { Message } from 'src/app/services/websocket/websocket.class';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ProxyWsService {
  private messageStream: Subject<any> = new Subject();

  constructor(
    private wsManager: WebsocketManagerService,
    private localStorageService: LocalStorageService
  ) {
    this.wsManager.getServerTsConnection().subscribe((socket) => {
      socket.on('message', (message) => {
        this.messageStream.next(message);
      });
    });
  }

  getMessage(): Observable<any> {
    return this.messageStream.asObservable().pipe(share());
  }

  sendMessage(message: Message): void {
    const user = this.localStorageService.getCurrentUser();
    const userId = user.identity;
    const correlationId = uuidv4();
    const eventChannel = message.subject.split('.')[0];
    const event = new CQRSBaseEvent({
      userId: userId,
      channel: eventChannel as EventChannel,
      body: message,
      correlationId: correlationId,
    });
    this.wsManager.getServerTsConnection().subscribe((socket) => {
      if (!socket) {
        console.error('Socket ts not established');
      }
      socket.emit('WebsocketFromUser', event);
    });
  }
}
