import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includesObjectProp',
})
export class IncludesObjectPropPipe implements PipeTransform {
  transform<T>(array: Array<T>, itemToCheck: T): boolean {
    if (!Array.isArray(array)) {
      return false;
    }

    return array.some((elem) => elem['key'] === itemToCheck);
  }
}
