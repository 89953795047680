import { Person as PlatformPerson } from 'datalayer/models/platform-models';
import { BaseTargetEntity } from 'datalayer/models/platform-models/base/base-target-entity';

export class Person extends PlatformPerson implements BaseTargetEntity {
  targetId: string;
  personId: string;

  constructor(model?: Partial<Person>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }
}
