<form [formGroup]="requestClForm" class="request-cl-form">
  <mat-radio-group formControlName="clParameterType">
    <ng-container *ngFor="let param of clParameterType">
      <div
        class="param-group"
        *ngIf="
          availableClOptions[clParameterDataTypeMapper[param]]?.length ||
          editable
        "
      >
        <mat-radio-button class="radio-btn" [value]="param">
          <span class="btn-name">{{ param }}</span>
        </mat-radio-button>
        <div class="input-wrp">
          <div class="available-list" *ngIf="!editable">
            <mat-select
              *ngIf="
                availableClOptions[clParameterDataTypeMapper[param]]?.length > 1
              "
              formControlName="clParameter"
              class="global-select"
              placeholder="{{ 'Select' | translate }}"
            >
              <mat-option
                *ngFor="
                  let request of availableClOptions[
                    clParameterDataTypeMapper[param]
                  ]
                "
                [disabled]="
                  request?.disabled ||
                  requestClForm.controls['clParameterType'].value !== param
                "
                [value]="request.value"
              >
                {{ request.value }}
              </mat-option>
            </mat-select>
            <span
              *ngIf="
                availableClOptions[clParameterDataTypeMapper[param]].length ===
                1
              "
              class="single-value"
              >{{
                availableClOptions[clParameterDataTypeMapper[param]][0].value
              }}</span
            >
          </div>
          <div
            class="typeable-input"
            *ngIf="
              editable &&
              requestClForm.controls['clParameterType'].value === param
            "
          >
            <input type="text" name="number" formControlName="clParameter" />
          </div>
        </div>
      </div>
    </ng-container>
    <div class="form-error-info" *ngIf="formErrorMessage">
      <p class="error">
        {{ formErrorMessage }}
        <!-- This needs to be part of separate component a HINT component -->
        <ng-container *ngIf="formHintValue">
          <ng-container [ngSwitch]="editable">
            <ng-container *ngSwitchCase="true">
              <a
                class="link"
                (click)="patchFormWithValue(formHintValue, 'clParameter')"
                >{{ formHintValue }}</a
              >
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ formHintValue }}
            </ng-container>
          </ng-container>
        </ng-container>
      </p>
    </div>
  </mat-radio-group>
  <ng-container *ngIf="!hidePeriodsSelection">
    <p>{{ 'Please select the time period' | translate }}</p>
    <mat-button-toggle-group formControlName="period">
      <ng-container *ngFor="let period of availablePeriods">
        <mat-button-toggle [value]="period.value" appearance="'legacy'"
          >{{ period.display }}
          <ng-container *ngIf="period.value == '1_MONTHS'">
            {{ 'month' | translate }}
          </ng-container>
          <ng-container *ngIf="period.value !== '1_MONTHS'">
            {{ 'months' | translate }}
          </ng-container>
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
    <p class="periods-note" *ngIf="hidePeriodsNote">
      {{
        'Note: Time periods are not available for Upload call logs ' | translate
      }}
    </p>
  </ng-container>
</form>

<ng-template #other>
  {{ 'months' | translate }}
</ng-template>
