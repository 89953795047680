import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const toastAnimationsSlideToTop = trigger('toastAnimation', [
  // Default animation
  state(
    'default-false',
    style({
      width: '350px',
      minWidth: '350px',
      top: '20px',
    })
  ),
  state(
    'default-true',
    style({
      width: '400px',
      minWidth: '400px',
      top: '0',
    })
  ),
  transition('default-false <=> default-true', [animate('250ms ease-in-out')]),

  // Mobile animation
  state(
    'mobile-false',
    style({
      width: '88%',
      minWidth: '88%',
      top: '20px',
    })
  ),
  state(
    'mobile-true',
    style({
      width: '94%',
      minWidth: '94%',
      top: '0px',
    })
  ),
  transition('mobile-false <=> mobile-true', [animate('200ms ease-in-out')]),
]);
