<div id="msisdnConfirmationPopup">
  <p class="ubuntu-font flex-wrp title" mat-dialog-title>
    {{ 'Confirmation required' | translate }}
    <mat-icon [mat-dialog-close]="false">close</mat-icon>
  </p>
  <mat-dialog-content class="content">
    <p>{{ 'This action will locate the' | translate }} {{ data }}</p>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="billingPlan$ | async as billingPlan" align="start">
    <button
      mat-button
      class="primary-button"
      (click)="onLocateTelnos()"
      [mat-dialog-close]="true"
      cdkFocusInitial
      [disabled]="!billingPlan?.queryLocation?.cost"
    >
      <span class="btn-txt">{{ 'Locate' | translate }}</span>
      <span *ngIf="billingPlan?.queryLocation?.cost" class="credits">{{
        billingPlan.queryLocation.cost * data.length
      }}</span>
    </button>

    <button
      mat-button
      [mat-dialog-close]="false"
      class="cancel-button"
      cdkFocusInitial
    >
      {{ 'Cancel' | translate }}
    </button>
  </mat-dialog-actions>
</div>
