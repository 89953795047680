import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { BaseDialogComponent } from 'src/app/shared/classes/base-dialog.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { Themes } from 'src/app/shared/models/skins.model';
import { Action } from '../../classes/action.class';
import { CostInfoDialogType } from '../../models/cost-info-dialog-type.model';
@Component({
  selector: 'app-expired-dialog',
  templateUrl: './expired-dialog.component.html',
  styleUrls: ['./expired-dialog.component.scss'],
})
export class ExpiredDialogComponent
  extends BaseDialogComponent
  implements OnInit
{
  targetMsisdn: string;
  dialogType: CostInfoDialogType;
  message: string;
  title: string;
  action: BillingActions;
  theme: Themes;
  countOfActionsCharged: number;
  constructor(
    public dialogRef: MatDialogRef<ExpiredDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      message: string;
      action: BillingActions;
      countOfActionsCharged?: number;
    },
    router: Router,
    private appConfigService: AppConfigService,
    private userBillingService: UserBillingService
  ) {
    super(dialogRef, router);
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    this.title = this.data.title;
    this.action = this.data.action;
    this.message = this.data.message;
    this.countOfActionsCharged = this.data.countOfActionsCharged;
  }

  onSuccess(action: Action = new Action({ key: 'success' })) {
    if (this.userBillingService.userHasEnoughCredits([this.action])) {
      this.dialog.close(action);
    }
  }
}
