import { Injectable } from '@angular/core';
import { NetworkCommunicationType } from '@report-service-ts/data-models/dist/target-summary/enums/network-communication-type.enum';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators';
import {
  DeepOsintLink,
  DeepOsintProfile,
} from 'src/app/modules/link-analysis/shared/deep-osint-event.model';
import { ImageService } from 'src/app/services/image/image.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  ProfilesWithTargetInfo,
  TargetSocialNetworkAssociates,
} from 'src/app/modules/profiler/shared/models/related-people-filter.model';
import { uniq } from 'lodash';
import { ServerPyWsService } from 'src/app/services/websocket/server-py-websocket.service';

@Injectable({
  providedIn: 'root',
})
export class TargetTopRelatedPeopleService {
  private readonly fastAPIurl: string = environment.fastAPIUri;

  constructor(
    private httpClient: HttpClient,
    private serverPyWsService: ServerPyWsService,
    private imageService: ImageService
  ) {}

  generateSocialTopAssociates(
    target: TargetItem
  ): Observable<TargetSocialNetworkAssociates[]> {
    return target.deepOsintStatus === JobStatus.DONE
      ? this.serverPyWsService
          .call({
            subject: MessageSubject.DeepOsintTopConnections,
            body: {
              targetId: target.id,
            },
          })
          .pipe(
            map(({ body }) =>
              this.formatSocialNetworkTopAssociates(
                body.payload.edges,
                body.payload.nodes,
                target
              )
            )
          )
      : of(undefined);
  }

  formatSocialNetworkTopAssociates(
    edges: DeepOsintLink[],
    nodes: DeepOsintProfile[],
    target: TargetItem
  ): TargetSocialNetworkAssociates[] {
    edges = edges.length ? transformSnakeToCamel(edges) : [];
    nodes = nodes.length ? transformSnakeToCamel(nodes) : [];
    const relations: TargetSocialNetworkAssociates[] = [];
    const targetProfileIds = this.findProfileIds(target);

    for (const edge of edges) {
      let userId: string = edge.toUserId;
      let parentUserId: string = edge.fromUserId;
      if (targetProfileIds.includes(edge.toUserId)) {
        userId = edge.fromUserId;
        parentUserId = edge.toUserId;
      }

      const targetProfile = nodes.find((item) => item.userId === userId);
      if (!targetProfile) {
        continue;
      }
      const targetPhoto = targetProfile?.photos?.length
        ? this.imageService.getPhotoUrl(
            '/file/' + targetProfile.photos[0],
            true
          )
        : '';
      const socialNetworkRelations = this.generateSocialNetworkRelations(
        edge,
        userId
      );
      relations.push({
        name: targetProfile.profile.name,
        image: targetPhoto as string,
        platform: [edge.source],
        relations: socialNetworkRelations,
        userIds: this.defineUserIds(socialNetworkRelations),
        fromUserId: parentUserId,
      });
    }

    return relations;
  }

  private findProfileIds(target: TargetItem): string[] {
    const userIds: string[] = [];

    if (!target?.socialProfiles?.length) {
      return [];
    }

    for (const socialProfile of target.socialProfiles) {
      if (socialProfile?.userId) {
        userIds.push(socialProfile.userId);
      }
    }

    return userIds;
  }

  private defineUserIds(
    relations: {
      count_no: number;
      type: NetworkCommunicationType;
      user_id: string;
    }[]
  ): string[] {
    const userIds: string[] = [];
    for (const relation of relations) {
      userIds.push(relation.user_id);
    }

    return uniq(userIds);
  }

  generateSocialNetworkRelations(
    edge: DeepOsintLink,
    userId: string
  ): { count_no: number; type: NetworkCommunicationType; user_id: string }[] {
    const {
      commentedPhotosCount,
      commentedPostsCount,
      likedPhotosCount,
      likedPostsCount,
      mutualFriendsCount,
      sharedPhotosCount,
      sharedPostsCount,
      taggedCount,
    } = edge;
    const relations: {
      count_no: number;
      type: NetworkCommunicationType;
      user_id: string;
    }[] = [
      {
        count_no: commentedPhotosCount || 0,
        type: NetworkCommunicationType.comment_photo,
        user_id: userId,
      },
      {
        count_no: commentedPostsCount || 0,
        type: NetworkCommunicationType.comment_post,
        user_id: userId,
      },
      {
        count_no: likedPhotosCount || 0,
        type: NetworkCommunicationType.like_photo,
        user_id: userId,
      },
      {
        count_no: likedPostsCount || 0,
        type: NetworkCommunicationType.like_post,
        user_id: userId,
      },
      {
        count_no: mutualFriendsCount || 0,
        type: NetworkCommunicationType.mutual_friend,
        user_id: userId,
      },
      {
        count_no: sharedPhotosCount || 0,
        type: NetworkCommunicationType.shared_photos,
        user_id: userId,
      },
      {
        count_no: sharedPostsCount || 0,
        type: NetworkCommunicationType.shared_posts,
        user_id: userId,
      },
      {
        count_no: taggedCount || 0,
        type: NetworkCommunicationType.tag,
        user_id: userId,
      },
    ];
    return relations;
  }

  matchProfileToTarget(
    profiles: TargetSocialNetworkAssociates[],
    targetId: string
  ): Observable<ProfilesWithTargetInfo[]> {
    if (profiles && profiles.length > 0) {
      return this.httpClient
        .post<any>(`${this.fastAPIurl}/entities/top-related/target-match`, {
          profiles,
          target_id: targetId,
        })
        .pipe(
          map((data: ProfilesWithTargetInfo[]) => {
            data = transformSnakeToCamel(data);
            return data;
          })
        );
    }
  }
}
