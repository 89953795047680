export enum AnalysisActionsListModel {
  TARGET_SEARCH = 'feature-action-target-search',
  CASE_SEARCH = 'feature-action-case-search',
  REQUEST_CALL_LOGS = 'feature-action-request-call-logs',
  UPLOAD_CALL_LOGS = 'feature-action-upload-call-logs',
  FULFILL_CALLLOG_REQUEST = 'feature-action-fulfill-call-log-request',
  LOCATE = 'feature-action-locate',
  EXPORT_PDF = 'feature-action-generate-pdf',
  ADVANCED_OSINT = 'feature-action-advanced-osint',
  DELETE_TARGET = 'feature-action-delete-target',
  REMOVE_TARGET_FROM_CASE = 'feature-action-remove-target-from-case',
  REFRESH_DATA = 'feature-action-refresh',
  REFRESH_SELECTED_ROWS = 'feature-action-refresh-rows',
  CREATE_TARGET = 'feature-action-create-target',
  ADD_TO_CASE = 'feature-action-add-to-case',
  CASE_LOCATE = 'feature-action-case-locate',
  DELETE_CASE = 'feature-action-delete-case',
  CASE_ADVANCED_OSINT = 'feature-action-case-advanced-osint',
  CASE_OPEN_IN_DISCOVERY = 'feature-action-case-open-in-discovery',
  ADD_TARGET = 'feature-action-add-target',
  CREATE_CASE = 'feature-action-create-case',
  NEW_MSISDN = 'feature-action-new-msisdn',
  ADD_TO_TARGET = 'feature-action-add-to-target',
  CLEAR_ALL_FILTERS = 'feature-action-clear-all-filters',
  CALL_LOG_EXPORT = 'feature-action-call-log-export',
  CALL_LOG_CSV_EXPORT = 'feature-action-call-log-cvs-export',
  CRYPTO_LOCATE = 'feature-action-crypto-locate',
  CRYPTO_TRANSACTION_SUMMARY = 'feature-action-crypto-transaction-summary',
  CRYPTO_ENHANCE_PROFILE = 'feature-action-crypto-enhance-profile',
  CRYPTO_REFRESH = 'feature-action-crypto-refresh',
  CRYPTO_TOGGLE_AS_BLACKLISTED = 'feature-action-crypto-toggle-blacklisted',
  CRYPTO_KYC_PHOTOS = 'feature-action-crypto-kyc-photos',
}

export enum LastModifiedActions {
  LOCATE = 'Locate',
  ADVANCED_OSINT = 'Enhance Profile',
  ADD_SEED = 'Added Seed',
  ADD_DETAILS = 'Added Details',
  ADD_NOTE = 'Added Note',
  ADD_PHOTO = 'Added Photo',
  ASSIGNED_TARGET_CHANGED = 'Assigned targets changed',
  TARGET_ASSIGNED = 'Targets assigned',
  TARGET_UNASSIGNED = 'Targets unassigned',
  NOTE_CHANGED = 'Note changed',
  CASE_NAME_CHANGED = 'Case name changed',
  CASE_COLOUR_CHANGED = 'Case colour changed',
}
