import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { BillingActions } from 'src/app/shared/models/billing-action.model';

export enum LocateButtonStatus {
  PENDING = 'Locating',
  DONE = 'Locate',
}

export enum LocateButtonIcon {
  PENDING = 'location_pending.svg',
  DONE = 'pin.svg',
}

export enum LiveSessionButtonStatus {
  PENDING = 'Pending',
  DONE = 'Live Session',
}

export enum ProfilerDashboardSectionsLabels {
  OVERVIEW = 'Overview',
  FEED = 'Feed',
  PHONE_LINKS = 'Phone links',
  RADICAL_MONITORING = 'Radical monitoring',
  LINK_ANALYSIS = 'Link analysis',
  DISCOVERED_PERSONS = 'Discovered persons',
  TEXT_ANALYSIS = 'Text analysis',
  NEW_CALL_LOG = 'Call log analysis',
  INSTANT_MESSAGING = 'Instant messaging',
  SOCIAL_ACTIVITY = 'Social activity',
}

export enum ProfilerDashboardSectionsValues {
  OVERVIEW = 'Overview',
  FEED = 'Feed',
  PHONE_LINKS = 'PhoneLinks',
  CALL_LOG = 'CallLogAnalysis',
  RADICAL_MONITORING = 'RadicalMonitoring',
  LINK_ANALYSIS = 'LinkAnalysis',
  DISCOVERED_PERSONS = 'DiscoveredPersons',
  TEXT_ANALYSIS = 'TextAnalysis',
  NEW_CALL_LOG = 'NewCallLog',
  INSTANT_MESSAGING = 'InstantMessaging',
  SOCIAL_ACTIVITY = 'SocialActivity',
}

export enum ProfilerDashboardSectionsRoutes {
  OVERVIEW = 'overview',
  FEED = 'feed',
  PHONE_LINKS = 'phone-links',
  RADICAL_MONITORING = 'radical-monitoring',
  LINK_ANALYSIS = 'link-analysis',
  DISCOVERED_PERSONS = 'discovered-persons',
  TEXT_ANALYSIS = 'text-analysis',
  NEW_CALL_LOG = 'new-call-log-analysis',
  INSTANT_MESSAGING = 'instant-messaging',
  SOCIAL_ACTIVITY = 'social-activity',
}

export enum ProfilerOverviewHeaderMapOptions {
  MAP = 'Last Seen',
  HEATMAP = 'Heatmap',
  TIMELINE = 'Timeline',
  PREDICTEDLOCATIONS = 'Predicted locations',
  POI = 'Places of interest',
  SUMMARY = 'Target summary',
  HEATMAPLASTSEEN = 'Heatmap and last seen',
  FEEDMAP = 'feedMap',
}

export enum PlatformImages {
  FB = 'assets/static/images/facebook-round.svg',
  IG = 'assets/static/images/instagram-round.svg',
  TW = 'assets/static/images/twitter_color.svg',
  TG = 'assets/static/images/telegram-round.svg',
  WA = 'assets/static/images/whatsapp-round.svg',
  SK = 'assets/static/images/skype-round.svg',
  TC = 'assets/static/images/truecaller_color.svg',
  VB = 'assets/static/images/viber-round.svg',
  WC = 'assets/static/images/wechat-round.svg',
  TIN = 'assets/static/images/tinder_color.svg',
  SS = 'assets/static/images/photo-round.svg',
  TT = 'assets/static/images/tiktok_color.svg',
  DBS = 'assets/static/images/photo-round.svg',
  CI = 'assets/static/images/search-intel/callid3-color.svg',
  GG = 'assets/static/images/google-round.svg',
  GC = 'assets/static/images/getcontact-color.svg',
}

export enum SomedusAction {
  NOACTION = '',
  DOWNLOAD = 'Download',
  VIEW = 'View',
}

export enum IMPlatformImages {
  telegram = 'assets/static/images/telegram_color.svg',
  whatsapp = 'assets/static/images/whatsapp_color.svg',
  skype = 'assets/static/images/skype_color.svg',
  truecaller = 'assets/static/images/truecaller_color.svg',
  viber = 'assets/static/images/viber_color.svg',
  wechat = 'assets/static/images/wechat.svg',
  SS = 'assets/static/images/photos_color.svg',
}

export enum PlatformNames {
  FB = 'Facebook',
  IG = 'Instagram',
  TW = 'Twitter',
  TG = 'Telegram',
  WA = 'Whatsapp',
  SK = 'Skype',
  TC = 'Truecaller',
  VB = 'Viber',
  WC = 'Wechat',
  TIN = 'Tinder',
  TT = 'Tiktok',
  SS = 'Social Search',
  DBS = 'Social Search',
  GG = 'Google',
  GC = 'GetContact',
}

export class SomedusCredits {
  facebook: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_FACEBOOK_VIEW,
    BillingActions.SOMEDUS_FACEBOOK_DOWNLOAD
  );
  telegram: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_TELEGRAM_VIEW,
    BillingActions.SOMEDUS_TELEGRAM_DOWNLOAD
  );
  whatsapp: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_WHATSAPP_VIEW,
    BillingActions.SOMEDUS_WHATSAPP_DOWNLOAD
  );
  line: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_LINE_VIEW,
    BillingActions.SOMEDUS_LINE_DOWNLOAD
  );
  twitter: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_TWITTER_VIEW,
    BillingActions.SOMEDUS_TWITTER_DOWNLOAD
  );
  instagram: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_INSTAGRAM_VIEW,
    BillingActions.SOMEDUS_INSTAGRAM_DOWNLOAD
  );
  yahoo: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_YAHOO_VIEW,
    BillingActions.SOMEDUS_YAHOO_DOWNLOAD
  );
  hotmail: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_HOTMAIL_VIEW,
    BillingActions.SOMEDUS_HOTMAIL_DOWNLOAD
  );
  outlook: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_OUTLOOK_VIEW,
    BillingActions.SOMEDUS_OUTLOOK_DOWNLOAD
  );
  skype: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_SKYPE_VIEW,
    BillingActions.SOMEDUS_SKYPE_DOWNLOAD
  );
  tiktok: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_TIKTOK_VIEW,
    BillingActions.SOMEDUS_TIKTOK_DOWNLOAD
  );
  tokopedia: SomedusActions = new SomedusActions(
    BillingActions.SOMEDUS_TOKOPEDIA_VIEW,
    BillingActions.SOMEDUS_TOKOPEDIA_DOWNLOAD
  );
}

class SomedusActions {
  View: BillingActions;
  Download: BillingActions;

  constructor(view, download) {
    this.View = view;
    this.Download = download;
  }
}

export enum SomedusPlatform {
  FACEBOOK = 'facebook',
  TELEGRAM = 'telegram',
  WHATSAPP = 'whatsapp',
  LINE = 'line',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  HOTMAIL = 'hotmail',
  YAHOO = 'yahoo',
  TIKTOK = 'tiktok',
  TOKOPEDIA = 'tokopedia',
  AOL = 'aol',
  SKYPE = 'skype',
  EMAIL = 'email',
  NONE = '',
}

export class SomedusRequestObj {
  phoneNumbers: string[];
  emailAddresses?: string[];
  platform: SomedusPlatform;
  action: SomedusAction;
  url?: string;
  name?: string;
  username?: string;
  email?: string;
  imageUrl?: string;
  targetId: string;
  requestId: string;

  msisdn?: string;
  status?: JobStatus;
  entityProfileId?: string;
  isRunning?: boolean;
  isArchived?: boolean;
  createdAt?: string;
  updatedAt?: string;
  targetInfo?: {
    alias: string;
    photos: string[];
    names: string[];
    deletedAt?: string;
  };
  urgent?: boolean;
  targetFeedId?: string;
  response?: SomedusRequestResponseObj;
}

export class SomedusDialogData {
  msisdn: string[] = [];
  emails: string[] = [];
  data: SomedusRequestObj[] = [];
  targetId: string;
  requestId = '';
  targetAlias = '';
  urgent = false;
  preSelectedData?: SomedusRequestObj;
}

export interface SomedusRequestViewModel extends SomedusRequestObj {
  expirationStatus?: string;
  isExpired?: boolean;
}

class SomedusRequestResponseObj {
  request_id: string;
  url: string;
  has_response: boolean;
  ip?: string;
  username?: string;
  password?: string;
  errorCode?: number;
  error?: string;
}
