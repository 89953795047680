import { AfterViewInit, Component, HostBinding } from '@angular/core';
import { LayoutFacade } from '@fe-platform/core/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActiveToast, Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { merge, tap } from 'rxjs';
import { DrawerComponent } from '../drawer';
import { ButtonSize } from '../icon-text-button/button-size.enum';
import { FontAwesomeFamily } from '../icons/fa-icon-family.enum';
import { FontAwesomeIcon } from '../icons/fa-icon.interface';
import { BaseToasterAction } from './base-toaster.model';
import { toastAnimationsSlideToTop } from './toast-animations';
import { ToastersEventService } from './toaster-events.service';
@UntilDestroy()
@Component({
  selector: 'intellectus-base-toaster',
  templateUrl: './base-toaster.component.html',
  styleUrls: ['./base-toaster.component.scss'],
  providers: [LayoutFacade],
  animations: [toastAnimationsSlideToTop],
})
export class BaseToasterComponent extends Toast implements AfterViewInit {
  public closeIcon: FontAwesomeIcon = {
    family: FontAwesomeFamily.REGULAR,
    name: 'xmark',
  };

  buttonSizes = ButtonSize;
  currentlyVisibleToasts: ActiveToast<unknown>[] = [];
  isTheTopToast = false;
  isMobile = false;

  @HostBinding('class') toasterClass = '';
  @HostBinding('class.top-toaster-is-extended') isTopToasterExtended = false;
  @HostBinding('@toastAnimation') get toastAnimationState() {
    return `${this.isMobile ? 'mobile' : 'default'}-${this.isTheTopToast}`;
  }

  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
    private layouFacade: LayoutFacade,
    private toasterEvents: ToastersEventService
  ) {
    super(toastrService, toastPackage);
    this.subscribeToIsMobile();
    this.subscribeToToasterEvents();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setToastOrder();
    });
  }

  onAction(action: BaseToasterAction) {
    action.callback();
    this.remove();
  }

  close() {
    this.remove();
  }

  private subscribeToIsMobile(): void {
    this.layouFacade.isMobile$
      .asObservable()
      .pipe(
        tap((isMobile) => {
          this.isMobile = isMobile;
          this.toasterClass += isMobile ? 'is-mobile ' : '';
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private subscribeToToasterEvents(): void {
    merge(this.toasterEvents.toasterOpened$, this.toasterEvents.toasterClosed$)
      .pipe(
        tap(() => {
          setTimeout(() => {
            this.setToastOrder();
          });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private setToastOrder() {
    this.currentlyVisibleToasts = this.getListOfToasts();

    const topToast = this.currentlyVisibleToasts.reduce(
      (min, toast) => (toast.toastId < min.toastId ? toast : min),
      this.currentlyVisibleToasts[0]
    );

    this.isTheTopToast =
      this.toastPackage.toastId === topToast.toastId ||
      this.currentlyVisibleToasts.length === 1;

    this.updateToasterClass();
    this.checkIfTopToastIsExtended(topToast);
  }

  private getListOfToasts(): ActiveToast<unknown>[] {
    return this.toastrService.toasts.filter((toast: ActiveToast<unknown>) => {
      return !(toast.toastRef.componentInstance instanceof DrawerComponent);
    });
  }

  private updateToasterClass(): void {
    const topClass = 'toaster-top-wrapper';
    const bottomClass = 'toaster-bottom-wrapper';

    this.toasterClass = this.toasterClass.replace(
      this.isTheTopToast ? bottomClass : topClass,
      ''
    );

    const targetClass = this.isTheTopToast ? topClass : bottomClass;

    if (!this.toasterClass.includes(targetClass)) {
      this.toasterClass += `${targetClass} `;
    }
  }

  private checkIfTopToastIsExtended(topToast: ActiveToast<unknown>): void {
    const hasMessage = topToast.message;
    this.isTopToasterExtended = !!hasMessage && !this.isTheTopToast;
  }
}
