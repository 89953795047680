import {
  Place as PlatformPlace,
  EntityRelationType,
  Media,
  ImageUrl,
} from 'datalayer/models/platform-models';
import { BaseTargetEntity } from 'datalayer/models/platform-models/base/base-target-entity';
import { Platforms } from 'src/app/shared/models/radical-monitoring-options.model';
import { CheckInMedia } from 'src/app/modules/search-intel/models/search-intel.model';

export class Place extends PlatformPlace implements BaseTargetEntity {
  targetId: string;
  platform: Platforms;
  profileId?: string;
  relationType?: EntityRelationType;
  image?: Partial<Media>;
  imageUrl?: string;
  description?: string;
  media?: CheckInMedia[];

  constructor(model?: Partial<Place>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }
}
