import {
  ActivityPattern,
  ValueOnlyActivityCell,
} from 'src/app/modules/trg-common/components/activity-pattern/activity-pattern.component';
import { Injectable } from '@angular/core';
import {
  DayEnum,
  EnumDictionary,
  EveryTwoHoursEnum,
} from '@trg-commons/data-models-ts';

@Injectable({
  providedIn: 'root',
})
export class ActivityPatternModelTransformerService {
  addActivityToActivityPattern(
    activityPattern: ActivityPattern,
    activity: EnumDictionary<
      DayEnum,
      EnumDictionary<EveryTwoHoursEnum, { value?: number }>
    >
  ): void {
    for (const dayAsString in activity) {
      const day = dayAsString as DayEnum;
      for (const hourString in activity[day]) {
        const hour = hourString as EveryTwoHoursEnum;
        if (!activityPattern[day]) {
          activityPattern[day] = {};
        }
        if (!activityPattern[day][hour]) {
          activityPattern[day][hour] = new ValueOnlyActivityCell(0);
        }
        activityPattern[day][hour].value += activity[day][hour].value || 0;
      }
    }
  }
}
