import { BaseService, EmptyCacheService } from 'datalayer/services/base';
import { Group } from 'datalayer/models/social/group';
import { GroupApiService } from 'src/app/modules/data-layer/services/social/group/group-api-service';
import { Injectable } from '@angular/core';
import { GroupDTO } from 'src/app/modules/data-layer/services/social/group/group-dto';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends BaseService<
  Group,
  GroupDTO,
  GroupApiService,
  EmptyCacheService<Group>
> {
  constructor(
    apiService: GroupApiService,
    localCacheService: EmptyCacheService<Group>
  ) {
    super(apiService, localCacheService);
  }
}
