import { Injectable } from '@angular/core';
import { Chat } from 'datalayer/models/chat';
import {
  ChatType,
  DataSource,
  Relation,
} from 'datalayer/models/platform-models';
import { Profile } from 'datalayer/models/social';
import { Platform } from 'src/app/shared/schemas/common/platforms';

export enum RelatedPeopleActionModel {
  CREATE_TARGET = 'related-people-add-to-target',
  ADVANCED_OSINT = 'related-people-advanced-osint',
  INVESTIGATE_LEAD = 'investigare-lead',
}

export interface RelatedPeopleDialogModel {
  profile: Profile;
  hasParent?: boolean;
  chats?: Chat[];
  moreDetails?: boolean;
  groupDetails?: boolean;
  isChatBackButton?: boolean;
  isGroupBackButton?: boolean;
}

@Injectable()
export class RelatedPeopleHelper {
  public generateChatSummaryData(chats: Chat[], profile: Profile): Chat {
    let chatSummary: Chat;
    chats.forEach((chat) => {
      if (chat.messages?.length && chat.chatType === ChatType.PERSONAL_CHAT) {
        const messagesExchanged = chat.messages.filter(
          (msg) =>
            msg.from && profile.name && msg.from.trim() === profile.name.trim()
        );
        if (messagesExchanged.length) {
          chat.messages = messagesExchanged;
          chatSummary = chat;
        }
      }
    });
    return chatSummary;
  }

  public createProfileUrl(profileRelations: Relation[]): {
    profileUrl: string;
    source: DataSource;
  } {
    const relation: Relation = profileRelations[0];
    let profileUrl: string;
    let source: DataSource;

    if (relation && Object.keys(relation).length) {
      const userId = relation.user_id.split('@')[0];
      const platform = relation.user_id.split('@')[1];
      if (platform === Platform.FACEBOOK) {
        profileUrl = `https://www.facebook.com/${userId}`;
        source = DataSource.Facebook;
      }
    }

    return { profileUrl, source };
  }
}
