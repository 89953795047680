<intellectus-stepper-nav
  [stepTitles]="stepTitles"
  [currentStep]="currentStep"
  (selectStep)="selectStep($event)"
></intellectus-stepper-nav>
<div
  class="step-block"
  *ngFor="let step of steps; index as i"
  [class.d-none]="i !== currentStep"
>
  <ng-container
    [ngTemplateOutlet]="step.template"
    [ngTemplateOutletContext]="{ step: step, i: i }"
  ></ng-container>
</div>
