<intellectus-modal
  #modalComponent
  [config]="config"
  (modalClose)="modalClose($event)"
  (breadcrumbClick)="onBreadcrumbClick($event)"
>
  <ng-container modal-body>
    <router-outlet></router-outlet>
  </ng-container>
</intellectus-modal>
