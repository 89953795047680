<app-dialog>
  <div header>
    {{ title | translate }}
  </div>

  <div body>
    {{ description }}
  </div>

  <div footer>
    <button
      *ngIf="enableCancelBtn"
      (click)="cancel()"
      mat-button
      class="btn-default mr-15"
    >
      {{ cancelText | translate }}
    </button>
    <button (click)="proceed()" mat-button class="btn-primary">
      {{ okText | translate }}
    </button>
  </div>
</app-dialog>
