import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appOverflowTooltip]',
})
export class OverflowTooltipDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const element = this.el.nativeElement;
    if (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    ) {
      this.renderer.setAttribute(element, 'title', element.innerText);
    } else {
      this.renderer.removeAttribute(element, 'title');
    }
  }
}
