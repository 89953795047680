import { Injectable } from '@angular/core';
import { PlatformWsService } from './platform-ws.service';
import { AuthService } from '../authentication/auth.service';
import { AuthState } from '../authentication/auth-state.enum';
import { AppConfigService } from '@app/config';
import { AdIntWSService } from 'src/app/account/ad-int/services/ad-int-ws.service';
import { AdIntWSMessage } from 'src/app/account/ad-int/models/AdIntWSMessage.model';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformWsManagerService {
  private destroyed$ = new Subject();

  constructor(
    private authService: AuthService,
    private platformWsService: PlatformWsService,
    private appConfigService: AppConfigService,
    private adIntWSService: AdIntWSService,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService
  ) {}

  init() {
    this.authService.authState$
      .pipe(
        filter((s) => s !== AuthState.unknown),
        distinctUntilChanged()
      )
      .subscribe((state) => {
        if (state === AuthState.authenticated) {
          this.wsSubscription();
        } else {
          this.destroyed$.next(true);
          this.platformWsService.disconnect();
        }
      });
  }

  private wsSubscription() {
    const user = this.localStorageService.getCurrentUser().identity;
    this.platformWsService
      .getConnection()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((msg: unknown) => {
        if (
          msg['type']?.startsWith('ADINT_') &&
          this.appConfigService.getConfigVariable('enableAdIntV2')
        ) {
          this.adIntWSService.handleWSMsg(msg as AdIntWSMessage<any>);
        } else if (
          msg['type'] === 'NOTIFICATION' &&
          user === msg['payload']?.createdBy
        ) {
          this.notificationService.pushNotification(msg['payload']);
        }
      });
  }
}
