import { Component, OnInit } from '@angular/core';
import { ActiveToast } from 'ngx-toastr';
import { TargetService } from 'src/app/services/target/target.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { OsintSearchNotificationResponse } from 'src/app/modules/data-layer/models/background-jobs/osint-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-deep-osint-notification',
  templateUrl: './deep-osint-notification.component.html',
  styleUrls: ['./deep-osint-notification.component.scss'],
})
export class DeepOsintNotificationComponent implements OnInit {
  public toast: ActiveToast<any>;
  public data: OsintSearchNotificationResponse;
  public target: TargetItem;
  isHidden = false;

  constructor(private targetService: TargetService, private router: Router) {}

  ngOnInit(): void {
    this.targetService.getTarget(this.data.target_id).subscribe((target) => {
      this.target = target;
    });
  }
  public close() {
    this.toast.toastRef.close();
  }

  public visitLinkAnalysisTab() {
    this.router.navigateByUrl(`targets/${this.data.target_id}/link-analysis`);
    this.close();
  }
}
