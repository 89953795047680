import { Entity } from '../base';
import { Coordinates } from 'src/app/shared/models/coordinates-interface';
import { Place } from 'src/app/shared/models/place-interface';
import { Point } from 'src/app/modules/mapV2/models/map.models';
import { SafeResourceUrl } from '@angular/platform-browser';

export class Post extends Entity {
  public title?: string;
  public body?: string;
  public url?: string;
  public language?: string;
  public agent?: string;
  public coordinates?: Coordinates;
  public place?: Place;
  public profileId?: string;
  public mentionType?: string;
  public publishedAt?: Date;
  public validAt?: Date;
  public feedbackStats?: FeedbackStats;
  public images?: ImageUrl[];
  public platform?: string;
  public locationData?: Point;
}

export interface FeedbackStats {
  [FeedbackStatsKeys.LIKESCOUNT]?: number;
  [FeedbackStatsKeys.COMMENTSCOUNT]?: number;
  [FeedbackStatsKeys.RESHARESCOUNT]?: number;
  [FeedbackStatsKeys.REACTORSCOUNT]?: number;
}

export enum FeedbackStatsKeys {
  LIKESCOUNT = 'likesCount',
  COMMENTSCOUNT = 'commentsCount',
  RESHARESCOUNT = 'resharesCount',
  REACTORSCOUNT = 'reactorsCount',
}

export interface ImageUrl {
  imageUrl: string | SafeResourceUrl;
}
