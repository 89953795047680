import { CreditPools } from './credit-pools.model';

export enum BillingActions {
  ADVANCED_GEOLOCATION = 'advancedGeolocation',
  ADVANCED_OSINT = 'advancedOsint',
  BASIC_OSINT = 'basicOsint',
  CALL_INFO = 'callInfo',
  CALL_LOG_MANAGEMENT = 'callLogManagement',
  CASE_MANAGEMENT = 'caseManagement',
  DEEP_OSINT = 'deepOsint',
  FILE_TEXT_ANALYSIS = 'fileTextAnalysis',
  GPS = 'gps',
  IMPORT_CDR = 'importCdr',
  IMPORT_HISTORICAL_LOCATIONS = 'importHistoricalLocations',
  INTEL_SEARCH = 'intelSearch',
  LOCATION_PROBABILITY = 'locationProbability',
  MSISDN_EXTRACTION = 'msisdnExtraction',
  NEIGHBOUR_CELL_TOWERS = 'neighbourCellTowers',
  NMR = 'nmr',
  QUERY_FOREIGH_LOCATION = 'queryForeignLocation',
  QUERY_INTELLIGENCE = 'queryIntelligence',
  QUERY_LOCATION = 'queryLocation',
  REQUEST_CDR = 'requestCdr',
  SIM_DATA_IMPORT = 'simDataImport',
  TARGET_MANAGEMENT = 'targetManagement',
  TRILATERATION_NEARBY_PEOPLE = 'trilaterationNearbyPeople',
  SOMEDUS_FACEBOOK_DOWNLOAD = 'somedusFacebookDownload',
  SOMEDUS_FACEBOOK_OFFER = 'somedusFacebookOffer',
  SOMEDUS_FACEBOOK_VIEW = 'somedusFacebookView',
  SOMEDUS_HOTMAIL_DOWNLOAD = 'somedusHotmailDownload',
  SOMEDUS_OUTLOOK_DOWNLOAD = 'somedusOutlookDownload',
  SOMEDUS_HOTMAIL_OFFER = 'somedusHotmailOffer',
  SOMEDUS_HOTMAIL_VIEW = 'somedusHotmailView',
  SOMEDUS_OUTLOOK_VIEW = 'somedusOutlookView',
  SOMEDUS_INSTAGRAM_DOWNLOAD = 'somedusInstagramDownload',
  SOMEDUS_INSTAGRAM_OFFER = 'somedusInstagramOffer',
  SOMEDUS_INSTAGRAM_VIEW = 'somedusInstagramView',
  SOMEDUS_LINE_DOWNLOAD = 'somedusLineDownload',
  SOMEDUS_LINE_OFFER = 'somedusLineOffer',
  SOMEDUS_LINE_VIEW = 'somedusLineView',
  SOMEDUS_SKYPE_DOWNLOAD = 'somedusSkypeDownload',
  SOMEDUS_SKYPE_OFFER = 'somedusSkypeOffer',
  SOMEDUS_SKYPE_VIEW = 'somedusSkypeView',
  SOMEDUS_TELEGRAM_DOWNLOAD = 'somedusTelegramDownload',
  SOMEDUS_TELEGRAM_OFFER = 'somedusTelegramOffer',
  SOMEDUS_TELEGRAM_VIEW = 'somedusTelegramView',
  SOMEDUS_TIKTOK_DOWNLOAD = 'somedusTiktokDownload',
  SOMEDUS_TIKTOK_OFFER = 'somedusTiktokOffer',
  SOMEDUS_TIKTOK_VIEW = 'somedusTiktokView',
  SOMEDUS_TWITTER_DOWNLOAD = 'somedusTwitterDownload',
  SOMEDUS_TWITTER_OFFER = 'somedusTwitterOffer',
  SOMEDUS_TWITTER_VIEW = 'somedusTwitterView',
  SOMEDUS_WHATSAPP_DOWNLOAD = 'somedusWhatsappDownload',
  SOMEDUS_WHATSAPP_OFFER = 'somedusWhatsappOffer',
  SOMEDUS_WHATSAPP_VIEW = 'somedusWhatsappView',
  SOMEDUS_YAHOO_DOWNLOAD = 'somedusYahooDownload',
  SOMEDUS_YAHOO_OFFER = 'somedusYahooOffer',
  SOMEDUS_YAHOO_VIEW = 'somedusYahooView',
  SOMEDUS_TOKOPEDIA_VIEW = 'somedusTokopediaView',
  SOMEDUS_TOKOPEDIA_DOWNLOAD = 'somedusTokopediaDownload',
  PURCHASE_CALL_LOGS = 'callLogPurchase',
  CALL_LOG_UPLOAD_IMEI = 'callLogUploadImei',
  CALL_LOG_UPLOAD_IMSI = 'callLogUploadImsi',
  CALL_LOG_UPLOAD_MSISDN = 'callLogUploadMsisdn',
  CALL_LOG_REQUEST_MSISDN_1_MONTH = 'callLogRequestMsisdn1Months',
  CALL_LOG_REQUEST_MSISDN_2_MONTHS = 'callLogRequestMsisdn2Months',
  CALL_LOG_REQUEST_MSISDN_3_MONTHS = 'callLogRequestMsisdn3Months',
  CALL_LOG_REQUEST_IMSI_3_MONTHS = 'callLogRequestImsi3Months',
  CALL_LOG_REQUEST_IMEI_3_MONTHS = 'callLogRequestImei3Months',
  CALL_LOG_REQUEST_MSISDN_6_MONTHS = 'callLogRequestMsisdn6Months',
  CALL_LOG_REQUEST_IMSI_6_MONTHS = 'callLogRequestImsi6Months',
  CALL_LOG_REQUEST_IMEI_6_MONTHS = 'callLogRequestImei6Months',
  CALL_LOG_REQUEST_MSISDN_12_MONTHS = 'callLogRequestMsisdn12Months',
  CALL_LOG_REQUEST_IMSI_12_MONTHS = 'callLogRequestImsi12Months',
  CALL_LOG_REQUEST_IMEI_12_MONTHS = 'callLogRequestImei12Months',
  CALL_LOG_REQUEST_MSISDN_24_MONTHS = 'callLogRequestMsisdn24Months',
  CALL_LOG_REQUEST_IMSI_24_MONTHS = 'callLogRequestImsi24Months',
  CALL_LOG_REQUEST_IMEI_24_MONTHS = 'callLogRequestImei24Months',
  OFFLINE_ADVANCED_OSINT = 'offlineAdvancedOsint',
  OFFLINE_INTEL_SEARCH = 'offlineIntelSearch',
  OFFLINE_DEEP_OSINT = 'offlineDeepOsint',
}

export const onlineToOfflineWebintActions = {
  [BillingActions.INTEL_SEARCH]: BillingActions.OFFLINE_INTEL_SEARCH,
  [BillingActions.ADVANCED_OSINT]: BillingActions.OFFLINE_ADVANCED_OSINT,
  [BillingActions.DEEP_OSINT]: BillingActions.OFFLINE_DEEP_OSINT,
};

export type BillingPlan<
  T extends BillingActions,
  U extends BillingActionType
> = {
  [action in T]: { cost: number; type: U };
};

export interface TenantBillingDetailsDTO {
  balance: CreditPools | number;
  balance_type: BalanceType;
  total_credits_reserved: number;
  unassign_user_credits: CreditPools | number;
  concurrent_limits_balance: { targets: number; cases: number };
}

export enum SubscriptionBillingType {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  DAILY = 'DAILY',
}

export interface TenantSubscriptionsDTO {
  callLog: SubscriptionBillingType;
  foreignGeolocation: SubscriptionBillingType;
  geolocation: SubscriptionBillingType;
  offlineWebint: SubscriptionBillingType;
  somedus: SubscriptionBillingType;
  webint: SubscriptionBillingType;
}

export interface TenantBillingDetails {
  balance: CreditPools | number;
  initialBalance?: CreditPools | number;
  balanceType: BalanceType;
  totalCreditsReserved: number;
  unassignUserCredits: CreditPools | number;
  concurrentLimitsBalance?: ConcurrentLimitsBalance;
}

export interface ConcurrentLimitsBalance {
  initialTargetLimit: number;
  currentTargetCount: number;
  initialCaseLimit: number;
  currentCaseCount: number;
}

export enum BillingActionType {
  GEOLOCATION = 'geolocation',
  WEBINT = 'webint',
  CALL_LOG = 'callLog',
  SOMEDUS = 'somedus',
}

export enum BillingPolicies {
  USAGE_LIMITS = 'USAGE_LIMITS',
  MAX_USAGE = 'MAX_USAGE',
}

export enum BalanceType {
  DISTRIBUTED = 'distributed',
  SINGLE = 'single',
}

export enum LimitType {
  DAILY = 'Daily',
  MONTHLY = 'Monthly',
  MAXIMUM = 'Maximum',
  FIXED = 'Fixed',
}

export enum BillingLimitType {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  MAXIMUM = 'MAXIMUM',
  FIXED = 'FIXED',
}
