export enum UserSettingsKeys {
  DISCOVERYV2ENABLED = 'discoveryV2Enabled',
  DISCOVERYV2ONBOARDINGSCREENVIEWED = 'discoveryV2OnBoardingScreenViewed',
}

export type UserSettings = { [key in UserSettingsKeys]: string | boolean };

export const DefaultUserSettings: UserSettings = {
  discoveryV2Enabled: undefined,
  discoveryV2OnBoardingScreenViewed: false,
};
