import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { User } from 'src/app/services/user/user.model';
import { BaseComponent } from 'src/app/base/base.component';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { TargetService } from 'src/app/services/target/target.service';
import { TacticalService } from 'src/app/services/tactical/tactical.service';

@Component({
  selector: 'app-law-enforcement-dialog',
  templateUrl: './law-enforcement-dialog.component.html',
  styleUrls: ['./law-enforcement-dialog.component.scss'],
})
export class LawEnforcementDialogComponent
  extends BaseComponent
  implements OnInit
{
  selectable = true;
  removable = true;
  addOnBlur = true;
  msisdns: string[] = [];
  separatorKeyCodes: number[] = [ENTER, COMMA];

  @ViewChild('msisdnsInput', { static: true })
  msisdnsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  lawEnforcmentDialogForm: UntypedFormGroup;
  msisdnsCtrl = new UntypedFormControl();

  constructor(
    private tacticalService: TacticalService,
    private targetService: TargetService,
    public dialogRef: MatDialogRef<LawEnforcementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value.trim() && this.validChip(value)) {
      this.msisdns.push(value.trim());
    }
    input.value = '';

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  validChip(value) {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    let valid = false;
    if (this.updateValidPhone(phoneNumberUtil, value)) {
      valid = true;
    }
    return valid;
  }

  updateValidPhone(phoneNumberUtil, msisdn) {
    if (!msisdn) {
      return false;
    }

    try {
      if (this.targetService.getValidPhone(phoneNumberUtil, msisdn)) {
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  remove(msisdn: string): void {
    const index = this.msisdns.indexOf(msisdn);

    if (index >= 0) {
      this.msisdns.splice(index, 1);
    }
  }

  private initForm(): void {
    this.lawEnforcmentDialogForm = new UntypedFormGroup({});
  }

  onSubmit(): void {
    this.tacticalService.clearSelectedLawEnforcement();
    this.tacticalService.getAllLawEnforcementData(this.msisdns).subscribe();
    this.dialogRef.close();
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
