export const customIconList = [
  {
    iconName: 'batch-query',
    iconUrl: './assets/static/images/batch_query.svg',
  },
  {
    iconName: 'location-pending',
    iconUrl: './assets/static/images/location_pending.svg',
  },
  {
    iconName: 'location-roaming',
    iconUrl: './assets/static/images/location_roaming.svg',
  },
  { iconName: 'cases-menu', iconUrl: './assets/static/images/cases.svg' },
  {
    iconName: 'geolocation',
    iconUrl: './assets/static/images/geolocation.svg',
  },
  {
    iconName: 'avatars',
    iconUrl: './assets/static/images/investigation/avatars.svg',
  },
  {
    iconName: 'app-info',
    iconUrl: './assets/static/images/call-logs/info-icon.svg',
  },
  {
    iconName: 'app-chevron-up',
    iconUrl: './assets/static/images/call-logs/app-chevron-up.svg',
  },
  {
    iconName: 'app-chevron-down',
    iconUrl: './assets/static/images/call-logs/app-chevron-down.svg',
  },
  {
    iconName: 'app-chevron-left',
    iconUrl: './assets/static/images/call-logs/app-chevron-left.svg',
  },
  {
    iconName: 'app-chevron-right',
    iconUrl: './assets/static/images/call-logs/app-chevron-right.svg',
  },
  {
    iconName: 'app-chevron-end-left',
    iconUrl: './assets/static/images/call-logs/app-chevron-end-left.svg',
  },
  {
    iconName: 'app-chevron-end-right',
    iconUrl: './assets/static/images/call-logs/app-chevron-end-right.svg',
  },
  {
    iconName: 'app-no-location',
    iconUrl: './assets/static/images/no-location.svg',
  },
  {
    iconName: 'action-locate',
    iconUrl: './assets/static/images/search-intel/msisdn-locate-color.svg',
  },
  {
    iconName: 'action-locate-grey',
    iconUrl: './assets/static/images/search-intel/msisdn-locate-grey.svg',
  },
  {
    iconName: 'action-search',
    iconUrl: './assets/static/images/feature/search.svg',
  },
  {
    iconName: 'action-export',
    iconUrl: './assets/static/images/feature/export-pdf.svg',
  },
  {
    iconName: 'action-call-log',
    iconUrl: './assets/static/images/feature/call-log.svg',
  },
  {
    iconName: 'action-advanced-osint',
    iconUrl: './assets/static/images/feature/advanced-osint.svg',
  },
  {
    iconName: 'action-add-to-target',
    iconUrl: './assets/static/images/search-intel//target.svg',
  },
  {
    iconName: 'action-search-grey',
    iconUrl: './assets/static/images/feature/search-grey.svg',
  },
  {
    iconName: 'analysis-menu',
    iconUrl: './assets/static/images/investigation/analysis-menu.svg',
  },
  {
    iconName: 'target-menu',
    iconUrl: './assets/static/images/investigation/target-menu.svg',
  },
  {
    iconName: 'investigation-menu',
    iconUrl: './assets/static/images/investigation/investigation-menu.svg',
  },
  {
    iconName: 'webint-menu',
    iconUrl: './assets/static/images/investigation/webint.svg',
  },
  {
    iconName: 'call-logs-menu',
    iconUrl: './assets/static/images/investigation/call-logs.svg',
  },
  {
    iconName: 'adint-map',
    iconUrl: './assets/static/images/investigation/timeline-area.svg',
  },
  {
    iconName: 'wallet',
    iconUrl: './assets/static/images/investigation/wallet.svg',
  },
];
