<div class="row center-xs advanced-geolocation-query-confirmation-dialog">
  <div class="advanced-geolocation-query-title">
    {{ 'Advanced Information Request' | translate }}
  </div>
  <div class="col-12">
    <div class="advanced-geolocation-query-subtitle">
      {{ 'AT&T post-paid only' | translate }}
    </div>
  </div>
  <div class="col-12">
    <div class="advanced-geolocation-query-value">
      {{
        'This action will deduct 4 credits. This may take up to 30 min.'
          | translate
      }}
    </div>
  </div>
</div>
<div
  class="row center-xs advanced-geolocation-query-confirmation-dialog-actions"
>
  <button
    class="advanced-geolocation-query-cancel"
    type="button"
    (click)="onClose()"
    mat-raised-button
  >
    {{ 'Cancel' | translate }}
  </button>
  <button
    class="advanced-geolocation-query-ok"
    type="button"
    (click)="requestAdvancedGeolocationQuery()"
    mat-raised-button
  >
    {{ 'Proceed' | translate }}
  </button>
</div>
