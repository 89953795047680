<div
  intellectusIsMobile
  class="wrapper"
  [class.mobile-device]="isMobileDevice"
  *ngIf="searchIDs.length"
>
  <div class="content">
    <intellectus-accordion
      *ngFor="let searchID of searchIDs | slice: 0:3; let last = last"
      [withBorder]="!last"
    >
      <intellectus-accordion-header
        [state]="!state[searchID].hidden"
        [withIcon]="!completedSearches[searchID] && !state[searchID].temp"
        (stateToggled)="
          onChangeSearchVisibility(searchID, !state[searchID].hidden)
        "
      >
        <div class="header-content">
          <app-intel-search-drawer-header
            [state]="state[searchID]"
            [id]="searchID"
            [completed]="completedSearches[searchID]"
            (viewClicked)="onViewClickedHandler(searchID)"
          ></app-intel-search-drawer-header>
        </div>
      </intellectus-accordion-header>

      <intellectus-accordion-content
        *ngIf="
          !state[searchID].hidden &&
          !completedSearches[searchID] &&
          !state[searchID].temp
        "
      >
        <app-intel-search-drawer-content
          [statuses]="state[searchID].statuses"
        ></app-intel-search-drawer-content>
      </intellectus-accordion-content>
    </intellectus-accordion>
  </div>

  <div class="show-all" *ngIf="searchIDs?.length > 3">
    <span class="link" (click)="onViewAllQueriesHandler()">{{
      'View all queries' | translate
    }}</span>

    <div class="icon">
      <fe-platform-fa-pro-icon
        [family]="faChevronRight.family"
        [name]="faChevronRight.name"
      ></fe-platform-fa-pro-icon>
    </div>
  </div>
</div>
