<div class="calendar-wrapper">
  <mat-calendar
    #calendar
    [maxDate]="maxDate"
    [minDate]="minDate"
    [startAt]="dates[0]"
    [dateFilter]="myFilter"
    (selectedChange)="selectDate($event)"
    [dateClass]="isSelected"
  ></mat-calendar>
  <button class="select-all fs-14" (click)="selectAllAvailableDays()">
    {{ 'Select all available' | translate }}
  </button>
</div>
