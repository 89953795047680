import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { zipObject, flow, groupBy, toPairs, map } from 'lodash-es';
import { BaseComponent } from 'src/app/base/base.component';
import { TacticalService } from 'src/app/services/tactical/tactical.service';
import { ConversationItem } from 'src/app/shared/models/conversation-item.model';

@Component({
  selector: 'app-si-log-table',
  templateUrl: './si-log-table.component.html',
  styleUrls: ['./si-log-table.component.scss'],
})
export class SiLogTableComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  conversations: ConversationItem[];
  filteredConversations: ConversationItem[] = [];
  paginatedConversations: ConversationItem[] = [];
  checked;
  groupBy;
  groupByView = false;
  groupedData = [];
  loader: boolean;

  paginator = {
    pageSize: 6,
    currentPage: 0,
    totalSize: 0,
  };

  sorterSettings = {
    field: undefined,
    sortOrder: 1,
  };

  groupByFields = [
    {
      label: 'Satellite',
      value: 'satellite',
    },
    {
      label: 'Conversation Type',
      value: 'conversationType',
    },
    {
      label: 'Caller',
      value: 'caller',
    },
    {
      label: 'Callee',
      value: 'callee',
    },
    {
      label: 'IMSI',
      value: 'imsi',
    },
    {
      label: 'TMSI',
      value: 'tmsi',
    },
  ];

  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef<HTMLInputElement>;
  @Input() fullScreen;

  @Output() emittedSelectedConversation = new EventEmitter<ConversationItem>();
  @Output() emittedMultiselectConversation = new EventEmitter<{
    conversation: ConversationItem;
    new: boolean;
  }>();
  @Output() refreshMap = new EventEmitter<boolean>();

  constructor(private tacticalService: TacticalService) {
    super();
  }

  ngOnInit() {
    this.loader = false;
    const conversationsSubscription =
      this.tacticalService.siConversationData.subscribe(
        (data: ConversationItem[]) => {
          this.conversations = data;
          this.paginator.currentPage = 0;
          this._iteratePaginator();
        }
      );

    const getLocationQueryLoader =
      this.tacticalService.tacticalLoader.subscribe((loader) => {
        this.loader = loader;
      });
    this.subscriptions.push(conversationsSubscription, getLocationQueryLoader);
    this.tacticalService.getAllSiConversations().subscribe();
    this.tacticalService.clearSelectedConversation();
  }

  ngOnChanges() {
    if (this.fullScreen) {
      this.paginator.pageSize = 13;
      this.paginator.currentPage = 0;
      this._iteratePaginator();
    } else {
      this.paginator.pageSize = 6;
      this.paginator.currentPage = 0;
      this._iteratePaginator();
    }
  }

  onSelectedConversation(conversation: ConversationItem) {
    this.emittedSelectedConversation.emit(conversation);
  }

  onMultiselectConversation(data: {
    conversation: ConversationItem;
    checked: boolean;
  }) {
    this.emittedMultiselectConversation.emit({
      conversation: data.conversation,
      new: data.checked ? true : false,
    });
  }

  refreshConversations() {
    this.tacticalService.getAllSiConversations().subscribe();
    this.tacticalService.clearSelectedConversation();
    this.groupByView = false;
    this.groupBy = null;
    this.refreshMap.emit(true);
  }

  onPaginatePageChange(event) {
    this.paginator.currentPage = event.pageIndex;
    this._iteratePaginator();
  }

  public _iteratePaginator() {
    if (this.conversations) {
      const conversations = this.searchInput.nativeElement.value
        ? this.filteredConversations
        : this.conversations;
      this.paginator.totalSize = conversations.length;
      this.paginatedConversations = conversations.slice(
        this.paginator.currentPage * this.paginator.pageSize,
        (this.paginator.currentPage + 1) * this.paginator.pageSize
      );
    }
  }

  onKeyUp(event) {
    if (this.conversations) {
      this.filteredConversations.length = 0;
      this.paginatedConversations.length = 0;
      const filterValue = this.searchInput.nativeElement.value.toLowerCase();
      for (const conversation of this.conversations) {
        for (const key of Object.keys(conversation)) {
          if (
            conversation[key] &&
            conversation[key].toString().toLowerCase().includes(filterValue)
          ) {
            this.filteredConversations.push(conversation);
            break;
          }
        }
      }
      this.paginator.currentPage = 0;
      this._iteratePaginator();
    }
  }

  sort(field) {
    this.sorterSettings.sortOrder =
      field === this.sorterSettings.field
        ? (this.sorterSettings.sortOrder *= -1)
        : 1;
    this.sorterSettings.field = field;
    this.conversations.sort(
      this.arraySort(field, this.sorterSettings.sortOrder)
    );
    this.filteredConversations.sort(
      this.arraySort(field, this.sorterSettings.sortOrder)
    );
    this._iteratePaginator();
  }

  arraySort(property, sortOrder) {
    return function (a, b) {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  // multiselect all the entries. Currently not used. If we want to add this functionality for the future
  // we need to reimplemented to work with the module map.
  // multiselect(event) {
  //   this.checked = event.checked;
  //   if (event.checked) {
  //     for (const conversation of this.conversations) {
  //       // constract the object that the map function requires.
  //       // TODO: fix the map function to accept a general type object
  //       const markerData = {
  //         location: conversation.location,
  //         queryArgs: {
  //           telno: conversation.conversationId
  //         },
  //         id: conversation.id
  //       };
  //       this.mapService.addToMultipins(markerData);
  //     }
  //   } else {
  //     this.mapService.refreshMap();
  //   }
  // }

  filterView() {
    this.groupByView = true;
    this.groupedData = this.groupByFilter(
      this.conversations,
      this.groupBy.toString(),
      'groupName',
      'items'
    );
  }

  groupByFilter(
    dataToGroupOn,
    fieldNameToGroupOn,
    fieldNameForGroupName,
    fieldNameForChildren
  ) {
    return flow(
      (value) => groupBy(value, fieldNameToGroupOn),
      toPairs,
      (value) =>
        map(value, (curVal) =>
          zipObject([fieldNameForGroupName, fieldNameForChildren], curVal)
        )
    )(dataToGroupOn);
  }

  getGroupHeight() {
    return this.fullScreen ? '50vh' : '18vh';
  }
}
