import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { WsService } from 'src/app/services/websocket/ws.service';
import { ConversationItem } from 'src/app/shared/models/conversation-item.model';
import { ImsiCatcherItem } from 'src/app/shared/models/imsi-catcher-item.model';
import { LawEnforcementItem } from 'src/app/shared/models/law-enforcement-item.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';

@Injectable({
  providedIn: 'root',
})
export class TacticalService extends BaseService {
  siConversationData = new Subject<ConversationItem[]>();
  selectedConversation = new Subject<ConversationItem>();
  imsiCatcherData = new Subject<ImsiCatcherItem[]>();
  selectedImsiCatcher = new Subject<ImsiCatcherItem>();
  lawEnforcementData = new Subject<LawEnforcementItem[]>();
  selectedLawEnforcement = new Subject<LawEnforcementItem>();
  tacticalLoader = new BehaviorSubject<boolean>(false);

  constructor(
    protected router: Router,
    private httpClient: HttpClient,
    private websocketService: WsService,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
    this.initializeSubscriptions();
  }

  getAllSiConversations() {
    this.tacticalLoader.next(true);
    return this.httpClient.get<any>(`${this.url}/satellite-interception`).pipe(
      map((data) => {
        this.tacticalLoader.next(false);
        const result = transformSnakeToCamel(data.result);
        this.updateConversations(result);
      }),
      catchError((error) => this.handleError(error))
    );
  }

  clearSelectedConversation() {
    this.selectedConversation.next(null);
  }

  updateConversations(conversations) {
    conversations.forEach((conversation) => {
      if (conversation.endTime) {
        const startTime = new Date(conversation.startTime);
        const endTime = new Date(conversation.endTime);
        const timeDiff = Math.abs(endTime.getTime() - startTime.getTime());
        conversation.durationSeconds = Math.ceil(timeDiff / 1000);
      }
    });
    this.siConversationData.next(conversations);
  }

  tacticalQuery(value) {
    return this.httpClient
      .post<any>(`${this.url}/tactical-query/${value}`, {})
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  private async initializeSubscriptions() {
    this.websocketService
      .onEvent('state-update-satellite')
      .subscribe((data) => {
        this.updateConversations(data);
      });
  }

  getAllImsiCatcherData() {
    this.tacticalLoader.next(true);
    return this.httpClient.get<any>(`${this.url}/imsi-catcher`).pipe(
      map((data) => {
        this.tacticalLoader.next(false);
        const result = transformSnakeToCamel(data.result);
        this.imsiCatcherData.next(result);
      }),
      catchError((error) => this.handleError(error))
    );
  }

  clearSelectedImsiCatcher() {
    this.selectedImsiCatcher.next(null);
  }

  getAllLawEnforcementData(msisdns) {
    this.tacticalLoader.next(true);
    return this.httpClient
      .post<any>(`${this.url}/law-enforcement-interception`, { msisdns })
      .pipe(
        map((data) => {
          this.tacticalLoader.next(false);
          const result = transformSnakeToCamel(data.result);
          this.lawEnforcementData.next(result);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  clearSelectedLawEnforcement() {
    this.selectedLawEnforcement.next(null);
  }
}
