<app-base-modal>
  <div header>{{ 'Importing historical data' | translate }}</div>

  <div class="body" body>
    <p class="m-0 fs-16">
      {{ 'We found available historical data for' | translate }}
      <strong>{{ data.telno }}</strong>
    </p>

    <p class="available-data-info m-0 fs-16 mb-15">
      <span class="fs-16">
        <strong class="fs-16">{{ 'starting' | translate }} </strong>
        <strong class="fs-16">{{
          data.recommendations.dates[0] | date
        }}</strong>
        {{ 'to' | translate }}
        <strong class="fs-16">{{
          data.recommendations.dates[data.recommendations.dates.length - 1]
            | date
        }}</strong>
      </span>
    </p>
    <p class="m-0 fs-16 mb-10">
      {{ 'To import, please select one or multiple days' | translate }}.
    </p>

    <div class="range-calendar">
      <app-recommendation-datepicker
        (selected$)="onDatePickerSelect($event)"
        [dates]="data.recommendations.dates"
      ></app-recommendation-datepicker>
      <p class="mt-15 fs-16 selected-label">
        {{ 'Selected' | translate }}:
        <strong class="font-medium fs-16" [ngPlural]="selectedDays.length">
          <ng-template ngPluralCase="=0"
            >0 {{ 'days' | translate }}</ng-template
          >
          <ng-template ngPluralCase="=1"
            >{{ selectedDays.length }} {{ 'day' | translate }}</ng-template
          >
          <ng-template ngPluralCase="other"
            >{{ selectedDays.length }} {{ 'days' | translate }}</ng-template
          >
        </strong>
      </p>
    </div>
  </div>

  <div
    *ngIf="billingPlan$ | async as billingPlan"
    footer
    class="flex-hor-center"
  >
    <button
      mat-button
      class="cp-btn-primary cp-search-btn active"
      (click)="onImport(billingPlan?.importHistoricalLocations?.cost)"
      [class.disabled]="selectedDays.length === 0"
      [disabled]="selectedDays.length === 0"
    >
      <span class="btn-txt">{{ 'Import' | translate }}</span>
      <ng-container>
        <span>|</span>
        <span class="credits assistant-font">{{
          selectedDays.length * billingPlan.importHistoricalLocations?.cost
        }}</span>
      </ng-container>
    </button>
  </div>
</app-base-modal>
