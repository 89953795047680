<button
  type="button"
  class="icon-text-button"
  #button
  [ngClass]="[
    buttonSize,
    isLoading ? 'loading' : '',
    !buttonText ? 'icon-only' : ''
  ]"
  [disabled]="isLoading || isDisabled"
  (click)="onClick($event); button.blur()"
  [attr.aria-label]="buttonText || 'Icon Button'"
>
  <div class="content">
    <ng-container *ngIf="!isLoading; else loadingSpinner">
      <fe-platform-fa-pro-icon
        [family]="buttonIcon.family"
        [name]="buttonIcon.name"
        [class.rotate]="rotate"
        [class.transition-rotate]="rotateWithTransition"
      ></fe-platform-fa-pro-icon>
    </ng-container>
    <ng-template #loadingSpinner>
      <fe-platform-fa-pro-icon
        [family]="'solid'"
        [name]="'spinner'"
        [spin]="true"
      ></fe-platform-fa-pro-icon>
    </ng-template>
    <span *ngIf="buttonText">{{ buttonText }}</span>
    <div *ngIf="badge" class="badge">{{ badge }}</div>
  </div>
</button>
