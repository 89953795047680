import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  map,
  Observable,
  of,
  tap,
} from 'rxjs';
import {
  DefaultUserSettings,
  UserSettings,
  UserSettingsKeys,
} from './user-settings.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environment/environment';
import { UserService } from './user.service';
import { AuthService } from '../authentication/auth.service';
import { AuthState } from '../authentication/auth-state.enum';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private baseURL: string = environment.iamAPIUri;
  private serverApiURL = environment.serverAPIUri;
  private userSettings: BehaviorSubject<UserSettings | null> =
    new BehaviorSubject(null);
  public discoveryV2Enabled: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  public discoveryV2OnBoardingScreenViewed: BehaviorSubject<boolean> =
    new BehaviorSubject(null);

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private authService: AuthService
  ) {
    this.authService.authState$
      .pipe(
        filter((val) => val === AuthState.unauthenticated),
        tap(() => this.destroy())
      )
      .subscribe();
  }

  destroy() {
    this.userSettings.next(null);
  }

  public getUserSettings(): Observable<UserSettings> {
    if (this.userSettings.getValue()) {
      return this.userSettings.asObservable();
    }
    return this.fetchUserSettings().pipe(
      tap((userSettings) => this.userSettings.next(userSettings))
    );
  }
  private fetchUserSettings(): Observable<any> {
    const url = `${this.serverApiURL}/user`;
    return this.userService.getCurrentUser().pipe(
      map((user) =>
        user.userAttributes.reduce(
          (acc, attribute) => (
            (acc[attribute.key] = this.mapUserSettingsKeyToValue(
              attribute.key,
              attribute.value
            )),
            acc
          ),
          DefaultUserSettings
        )
      ),
      tap((userSettings) => this.userSettings.next(userSettings)),
      catchError(() => of(DefaultUserSettings))
    );
  }

  public setUserSettingsValue(
    settings: Partial<UserSettings>
  ): Observable<any> {
    const url = `${this.baseURL}/user/attributes`;
    const [key, value] = Object.entries(settings)[0];
    return this.httpClient.post(url, { key, value: value.toString() }).pipe(
      map(() => ({ ...this.userSettings.getValue(), ...settings })),
      tap((userSettings) => this.userSettings.next(userSettings))
    );
  }

  private mapUserSettingsKeyToValue(
    key: UserSettingsKeys | string,
    value: string
  ): string | boolean {
    switch (key) {
      case UserSettingsKeys.DISCOVERYV2ENABLED:
        return value === 'true';
      case UserSettingsKeys.DISCOVERYV2ONBOARDINGSCREENVIEWED:
        return value === 'true';
      default:
        return value;
    }
  }
}
