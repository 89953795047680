<div class="geloc-target-actions">
  <div
    class="action"
    appDebounceClick
    matTooltip="{{ 'Get current location of this MSISDN' | translate }}"
    (debounceClick)="onLocate(query)"
  >
    <div class="fa-icon-wrapper">
      <fe-platform-fa-pro-icon
        class="fa-icon-btn"
        [family]="'regular'"
        [name]="'location-crosshairs'"
      ></fe-platform-fa-pro-icon>
    </div>
    <span class="txt">{{ 'Locate' | translate }}</span>
  </div>

  <div
    [ngClass]="{ disabled: !(query?.provider.telno || query?.queryArgs.telno) }"
    class="action"
    (click)="intelSearchQuery(query)"
    matTooltip="{{
      'Uncover usernames, open web mentions, email and social media accounts'
        | translate
    }}"
  >
    <div
      class="fa-icon-wrapper"
      [ngClass]="{
        disabled: !(query?.provider.telno || query?.queryArgs.telno)
      }"
    >
      <fe-platform-fa-pro-icon
        class="fa-icon-btn"
        [family]="'regular'"
        [name]="'search'"
      ></fe-platform-fa-pro-icon>
    </div>
    <span class="txt">{{ 'Find profiles' | translate }} </span>
  </div>

  <div
    class="action"
    (click)="generatePdf()"
    matTooltip="{{ 'Export detailed geolocation report' | translate }}"
  >
    <div class="fa-icon-wrapper">
      <fe-platform-fa-pro-icon
        class="fa-icon-btn"
        [family]="'regular'"
        [name]="'file-chart-pie'"
      ></fe-platform-fa-pro-icon>
    </div>
    <span class="txt">{{ 'Export' | translate }}</span>
  </div>

  <div
    class="action"
    *ngIf="telno | callLogPermitted"
    [ngClass]="{ disabled: clInProgress }"
    [matTooltipDisabled]="!clInProgress"
    matTooltip="{{ 'Pending Request' | translate }}"
    (click)="requestCallLog()"
  >
    <div class="fa-icon-wrapper">
      <fe-platform-fa-pro-icon
        class="fa-icon-btn"
        [family]="'kit'"
        [name]="'call-logs'"
      ></fe-platform-fa-pro-icon>
    </div>
    <span class="txt">{{
      (clInProgress ? 'Pending call log' : 'Request Call Log') | translate
    }}</span>
  </div>

  <div
    class="action"
    (click)="openAdvancvedOSINTDialog()"
    matTooltip="{{
      target
        ? ('Find possible associates, visited places, connections on social media, groups and more.'
          | translate)
        : ('Create a new target and find possible associates, visited places, connections on social media, groups and more.'
          | translate)
    }}"
  >
    <div class="fa-icon-wrapper">
      <fe-platform-fa-pro-icon
        class="fa-icon-btn"
        [family]="'regular'"
        [name]="'user-magnifying-glass'"
      ></fe-platform-fa-pro-icon>
    </div>
    <span class="txt">{{ 'Enhance profile' | translate }}</span>
  </div>
</div>
