import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class UserBehaviorService extends BaseService {
  constructor(
    protected router: Router,
    private httpClient: HttpClient,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  userBehavior(counter) {
    return this.httpClient
      .post<any>(`${this.url}/user-behavior`, {
        counter,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }
}
