import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationFormat',
  pure: true,
})
export class DurationFormatPipe implements PipeTransform {
  transform(value: any, showSeconds = true): any {
    return value ? this.formatDuration(value, showSeconds) : '00:00';
  }

  private formatDuration(
    durationInSeconds: number,
    showSeconds: boolean
  ): string {
    const hours = Math.floor(durationInSeconds / (60 * 60));
    const remainingMinutes = durationInSeconds % (60 * 60);
    const minutes = Math.floor(remainingMinutes / 60);
    const remainingSeconds = remainingMinutes % 60;

    const normalizeToTwoDigits = (value: number) => {
      return value.toString().length === 1 ? `0${value}` : value;
    };

    let result = `${normalizeToTwoDigits(hours)}:${normalizeToTwoDigits(
      minutes
    )}`;

    if (showSeconds) {
      result += `:${normalizeToTwoDigits(remainingSeconds)}`;
    }

    return result;
  }
}
