import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import {
  ImGroup,
  ImGroupChatMessage,
  ImGroupMediaResponse,
  ImGroupMember,
  NamedEntity,
} from 'src/app/modules/case-group-analyser/models/case-group-analyser.model';
import { take, tap } from 'rxjs/operators';
import { ImGroupAnalyserApiService } from 'src/app/modules/case-group-analyser/services/im-group-analyser-api.service';
import { CaseSocialEntity } from '@shared/models/case.model';
import { DataSource } from 'datalayer/models/platform-models';

@Injectable({ providedIn: 'root' })
export class ImGroupAnalyserService {
  private readonly groupsBS: BehaviorSubject<ImGroup[]> = new BehaviorSubject<
    ImGroup[]
  >(undefined);

  private readonly membersBS: BehaviorSubject<Map<string, ImGroupMember[]>> =
    new BehaviorSubject<Map<string, ImGroupMember[]>>(
      new Map<string, ImGroupMember[]>()
    );

  private groupPullingTimeout: any;

  private readonly selectedGroupFromOverview: Subject<ImGroup> =
    new Subject<ImGroup>();

  private readonly chatScrollPosition: Record<string, number> = {};

  constructor(private readonly apiService: ImGroupAnalyserApiService) {}

  public loadGroupsByIds(entities: CaseSocialEntity[]): void {
    forkJoin(
      entities.map((entity: CaseSocialEntity): Observable<ImGroup> => {
        return this.apiService.loadGroup(entity.id, entity.source);
      })
    )
      .pipe(take(1))
      .subscribe((groups: ImGroup[]): void => {
        this.updateGroups(groups);
      });
  }

  public loadParticipants(): void {
    const participant: Map<string, ImGroupMember[]> = new Map<
      string,
      ImGroupMember[]
    >();
    forkJoin(
      this.groupsBS.value.map((group: ImGroup): Observable<any> => {
        return this.apiService.loadMembers(group.externalId, group.source).pipe(
          tap((members: ImGroupMember[]): void => {
            participant.set(group.externalId, members);
          })
        );
      })
    )
      .pipe(
        take(1),
        tap((): void => this.updateMembers(participant))
      )
      .subscribe();
  }

  public loadChatMessages(
    groupId: string,
    source: DataSource
  ): Observable<ImGroupChatMessage[]> {
    return this.apiService.loadChatMessages(groupId, source);
  }

  public loadMedia(
    groupId: string,
    source: DataSource,
    page: number,
    limit: number
  ): Observable<ImGroupMediaResponse> {
    return this.apiService.loadMedia(groupId, source, page, limit);
  }

  public loadNamedEntities(
    groupId: string,
    source: DataSource
  ): Observable<NamedEntity[]> {
    return this.apiService.loadNamedEntities(groupId, source);
  }

  public get groups(): Observable<ImGroup[]> {
    return this.groupsBS.asObservable();
  }

  public get members(): Observable<Map<string, ImGroupMember[]>> {
    return this.membersBS.asObservable();
  }

  public clearGroupsMembersBS(): void {
    this.updateGroups([]);
    this.updateMembers(new Map());
  }

  public clearGroupPullInterval(): void {
    clearTimeout(this.groupPullingTimeout);
  }

  public triggerGroupPulling(groupId: string, source: DataSource): void {
    this.runGroupPull(groupId, source);
  }

  public setChatScrollPosition(
    caseId: string,
    groupId: string,
    scrollPosition: number
  ): void {
    this.chatScrollPosition[`${caseId}_${groupId}`] = scrollPosition;
  }

  public getChatScrollPosition(
    caseId: string,
    groupId: string
  ): number | undefined {
    return this.chatScrollPosition[`${caseId}_${groupId}`];
  }

  private updateGroups(groups?: ImGroup[]): void {
    this.groupsBS.next(groups);
  }

  private updateMembers(members: Map<string, ImGroupMember[]>): void {
    this.membersBS.next(members);
  }

  private runGroupPull(groupId: string, source: DataSource): void {
    this.clearGroupPullInterval();

    this.apiService
      .loadGroup(groupId, source)
      .pipe(take(1))
      .subscribe((result: ImGroup): void => {
        if (!result.sentimentStats) {
          this.groupPullingTimeout = setTimeout(
            () => this.runGroupPull(groupId, source),
            10000
          );

          return;
        }

        this.updateGroup(result);
      });
  }

  private updateGroup(groupToUpdate: ImGroup): void {
    const groups: ImGroup[] = this.groupsBS.value;
    const indexToUpdate: number = groups.findIndex(
      (group: ImGroup): boolean => group.externalId === groupToUpdate.externalId
    );
    groups[indexToUpdate] = {
      ...groupToUpdate,
    };

    this.updateGroups(groups);
  }

  public removeGroup(groupId: string): void {
    const groups: ImGroup[] = this.groupsBS.value;
    this.updateGroups(groups.filter((group) => group.externalId !== groupId));
  }

  public selectGroupFromOverview(group: ImGroup): void {
    this.selectedGroupFromOverview.next(group);
  }

  public getSelectedGroupFromOverview(): Observable<ImGroup> {
    return this.selectedGroupFromOverview.asObservable();
  }
}
