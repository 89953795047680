import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input()
  showHeader = true;

  @Input()
  showFooter = true;

  @Input()
  bodyHeight: number;
}
