<div class="extract-peer-popup" *ngIf="popUpState.requestExtractPeerPopup">
  <div class="popup-title">
    <span class="title">{{ 'Call log record' | translate }}</span>
    <button class="close-extract-popup" (click)="togglePopUp()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="popup-description">
    <p>
      {{ 'Do you want to extract details about the Call Peer' | translate }}?
    </p>
  </div>

  <div class="btn-wrp">
    <app-credit-cost-button
      [action]="'callInfo'"
      [buttonText]="'Get information'"
      [active]="true"
      [stylingClasses]="'cp-btn-ghost cp-search-btn locate-btn active'"
      (clicked)="emitAction(availableActions.getInfo)"
    ></app-credit-cost-button>

    <app-credit-cost-button
      [action]="['callInfo', 'queryLocation']"
      [buttonText]="'Get information & geolocate'"
      [active]="true"
      [stylingClasses]="'cp-btn-primary cp-search-btn active'"
      (clicked)="emitAction(availableActions.getInfoAndLocate)"
    ></app-credit-cost-button>
  </div>
</div>

<div class="info-popup" *ngIf="popUpState.showPeerInfo">
  <span>+{{ query?.callInfo?.oncallNumber }}</span>
</div>

<div class="marker-template" (click)="togglePopUp()">
  <img class="marker-image" [src]="markerPath" alt="" />
  <span class="marker-image-text">{{ 'No location data' | translate }}</span>
</div>
