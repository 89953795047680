import { Component } from '@angular/core';
import { MyDetailsComponent } from 'src/app/components/user-settings/my-details/my-details.component';
import { MyDetailsService } from 'src/app/components/user-settings/my-details/my-details.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-mobile-my-details',
  templateUrl: './mobile-my-details.component.html',
  styleUrls: ['./mobile-my-details.component.scss'],
})
export class MobileMyDetailsComponent extends MyDetailsComponent {
  constructor(
    public userService: UserService,
    protected translationService: TranslationService,
    protected myDetailsService: MyDetailsService,
    protected applicationStateService: ApplicationStateService
  ) {
    super(
      userService,
      translationService,
      myDetailsService,
      applicationStateService
    );
  }
}
