import { Post } from '../platform-models/social/post';
import { FeedType } from './feed-type.enum';
import { GeoQuery } from './geo-query';
import { Image } from './image';
import { Note } from './note';
import { ReferenceCollection } from './reference-collection.enum';

export class Feed {
  id: string;
  targetId: string;
  referenceId: string;
  referenceCollection: ReferenceCollection;
  queryDate: Date;
  feedType: FeedType;
  feedData: Post | Note | GeoQuery | Image | undefined;
  notes: Note[];
  publishedAt: Date;
  showPublishMonth: boolean;
  createdAt: Date;
  updatedAt: Date;
  collectedBy: string;
  collectedDate: Date;
  createdBy: string;
  // this needs to be clarified, i presume that this is set according to above feedType property
  constructor(model: Partial<Feed>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}
