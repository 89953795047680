import { BaseDTO } from '../base/base-dto';
import { Chat } from '../../models/chat';
import { transformSnakeToCamel } from '../../../../shared/util/helper';

export class ChatDTO extends BaseDTO<Chat, ChatDTO> {
  public query_args: {
    arg_type: string;
    arg_value: string;
    platform: string;
  }[];
  public target_id: string;
  public source: string;
  public chat_id: string;
  public type: string;
  public chat_type: string;
  public name: string;
  public members: string[];
  public published_at: string;
  public owner: string;
  public owner_id: string;
  public messages: {
    id: string;
    type: string;
    date: string;
    date_unixtime: string;
    from: string;
    from_id: string;
    text: string;
  }[];

  constructor(model?: Partial<ChatDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Chat): ChatDTO {
    return new ChatDTO();
  }

  toModel(): Chat {
    return new Chat(transformSnakeToCamel(this));
  }
}
