<div class="row logon-row">
  <div class="col-12" [class.col-md-4]="skin === 'GEO4' || skin === 'CVTP'">
    <div>
      <app-datetimepicker [minDate]="todayDate"></app-datetimepicker>
    </div>
  </div>
  <div class="col-12" [class.col-md-4]="skin === 'GEO4' || skin === 'CVTP'">
    <div>
      <form [formGroup]="logonForm" (ngSubmit)="onSubmit()">
        <app-frequency></app-frequency>
        <!-- <mat-radio-group formControlName="queryType" class="type-radio-group" [(ngModel)]="selectedType">
          <mat-radio-button class="type-radio-button" *ngFor="let type of types" [value]="type.queryType">
            {{type.label}}
          </mat-radio-button>
        </mat-radio-group> -->
      </form>
    </div>
  </div>
</div>
<div *ngIf="showError" class="mat-error">
  {{ 'Please fill in all fields' | translate }}
</div>
