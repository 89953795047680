import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { User } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { OTPDialogState } from './../../../user-settings/user-settings.models';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs';
import { OtpSectionComponent } from 'src/app/components/user-settings/otp-section/otp-section.component';
import { OTPChannels } from 'src/app/components/user-settings/user-settings.models';
import { MobileActivateOtpModalComponent } from './mobile-activate-otp-modal/mobile-activate-otp-modal.component';

export interface ModalParams {
  dialogState: OTPDialogState;
  user: User;
  isActivated?: boolean;
  $event?: MatSlideToggleChange | null;
  channel?: OTPChannels;
  isPasswordChange?: boolean;
  currentChannelValue?: OTPChannels;
}
@UntilDestroy()
@Component({
  selector: 'app-mobile-otp-section',
  templateUrl: './mobile-otp-section.component.html',
  styleUrls: ['./mobile-otp-section.component.scss'],
})
export class MobileOtpSectionComponent
  extends OtpSectionComponent
  implements OnInit, AfterViewInit
{
  otpEnabled: boolean;

  @ViewChild('emailSlider', { static: false }) emailSlider: MatSlideToggle;
  @ViewChild('phoneSlider', { static: false }) phoneSlider: MatSlideToggle;
  @ViewChild('appSlider', { static: false }) appSlider: MatSlideToggle;
  @ViewChild('otpSlider', { static: false }) otpSlider: MatSlideToggle;

  constructor(
    public userService: UserService,
    protected dialog: MatDialog,
    protected translationService: TranslationService,
    private cdRef: ChangeDetectorRef
  ) {
    super(userService, dialog, translationService);
  }

  // overriding the ngOnInit from the parent component so we dont subscribe to currentUser$ twice
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.userService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe((user: User) => {
        if (user) {
          this.otpEnabled = user.otpEnabled;
          this.otpChannel = user.otpChannel;
          this.setInitialOtpValue();
        }
      });
  }

  handleOTPChange($event: MatSlideToggle): void {
    const state: OTPDialogState = OTPDialogState.PasswordScreen;
    const user = this.userService.currentUser$.value;
    this.showPhoneInput = false;

    const otpChangeDialog = this.dialog.open(MobileActivateOtpModalComponent, {
      data: { state, user, isActivated: $event.checked },
      width: '460px',
      autoFocus: true,
      panelClass: 'bottom-draw-otp',
    });

    otpChangeDialog
      .afterClosed()
      .pipe(
        switchMap(() => {
          return this.userService.getCurrentUser();
        })
      )
      .subscribe();
  }

  setInitialOtpValue() {
    this.otpSlider.checked = this.otpEnabled;

    if (this.emailSlider && this.otpChannel === OTPChannels.Email) {
      this.emailSlider.checked = true;
      this.phoneSlider.checked = false;
      this.appSlider.checked = false;
    }

    if (this.phoneSlider && this.otpChannel === OTPChannels.OtpPhone) {
      this.phoneSlider.checked = true;
      this.emailSlider.checked = false;
      this.appSlider.checked = false;
    }

    if (this.appSlider && this.otpChannel === OTPChannels.App) {
      this.appSlider.checked = true;
      this.phoneSlider.checked = false;
      this.emailSlider.checked = false;
    }
    this.cdRef.detectChanges();
  }

  changeMobileOtpChannel(
    $event: MatSlideToggleChange,
    value: OTPChannels
  ): void {
    const user = this.userService.currentUser$.value;
    const currentChannelValue = user.otpChannel;
    const otpChannel = value;

    if (!otpChannel) {
      return;
    }

    this.showPhoneInput = false;

    if ($event.checked === false) {
      this.activateOtpModalChange({
        dialogState: OTPDialogState.PasswordScreen,
        user: user,
        isActivated: $event.checked,
        $event: $event,
      });
    } else {
      this.activateOtpModalChange({
        dialogState: OTPDialogState.PasswordScreenChangeMethod,
        user: user,
        isActivated: true,
        channel: otpChannel,
        isPasswordChange: true,
        currentChannelValue: currentChannelValue,
      });
    }
  }

  private activateOtpModalChange(params: ModalParams): void {
    const { dialogState, user, isActivated, channel, isPasswordChange } =
      params;

    const otpChangeDialog = this.dialog.open(MobileActivateOtpModalComponent, {
      data: {
        state: dialogState,
        user,
        isActivated,
        channel,
        isPasswordChange,
      },
      width: '460px',
      autoFocus: true,
      panelClass: 'bottom-draw-otp',
    });

    otpChangeDialog
      .afterClosed()
      .pipe(
        switchMap(() => {
          return this.userService.getCurrentUser();
        })
      )
      .subscribe();
  }
}
