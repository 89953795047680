import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { BaseComponent } from 'src/app/base/base.component';
import { NotesService } from 'src/app/services/notes/notes.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';

@Component({
  selector: 'app-target-note-form',
  templateUrl: './target-note-form.component.html',
  styleUrls: ['./target-note-form.component.scss'],
})
export class TargetNoteFormComponent extends BaseComponent implements OnInit {
  title = 'Add note';
  targetNoteForm: UntypedFormGroup;
  noteSubmitted = false;

  constructor(
    public dialogRef: MatDialogRef<TargetNoteFormComponent>,
    @Inject(MAT_DIALOG_DATA) public target: TargetItem,
    private notesService: NotesService,
    private translationService: TranslationService
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm(): void {
    const title = '';
    const description = '';

    this.targetNoteForm = new UntypedFormGroup({
      title: new UntypedFormControl(title, Validators.required),
      description: new UntypedFormControl(description, Validators.required),
      entityType: new UntypedFormControl('target'),
      entityId: new UntypedFormControl(this.target.id),
    });
  }

  onSubmit() {
    // added this flag to prevent from submitting the note twice
    this.noteSubmitted = false;
    const addNoteSubscription = this.notesService
      .createNoteProfiler(this.targetNoteForm.getRawValue())
      .subscribe(
        () => {
          this.noteSubmitted = true;
          this.showMessage(
            this.translationService.translate('Note created successfully!')
          );
          addNoteSubscription.unsubscribe();
          this.onClose();
        },
        (error: string) => {
          this.showMessage(
            this.translationService.translate('Note has not been created')
          );
          addNoteSubscription.unsubscribe();
        }
      );
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
