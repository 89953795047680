import {
  ComponentRef,
  Injectable,
  Injector,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';

/**
 * This service tracks a list of containers that will host module-foreign content
 * The general idea is that we might want to render some domain-specific content under 
 * a domain-agnostic container (e.g a navigation bar). In a traditional way we would couple
 * two or more domains to do that. In order to avoid that, the workflow goes like this:
 * - In the domain-agnostic area (e.g navigation bar) we mark that our container should be eligible to render 
 *   foreign content
 *    <ng-container *dynamicElementRef="'navtitle'">
       <div class="title">{{ title | translate }}</div>
      </ng-container>
   - If any foreign module (e.g discovery) wants to add some domain-specific content to that container we can simply call:
     `this.dynRefService.renderComponentTo('navtitle', NetworkStatusComponent);`

   Final result of the example above: Discovery renders the desired content on the navigation bar
   without causing any coupling.
 */
@Injectable({
  providedIn: 'root',
})
export class DynamicElementRefService {
  dynamicContainers: Map<string, ViewContainerRef> = new Map();
  defaultContent: Map<string, TemplateRef<any>> = new Map();
  dynamicComponentRefs: Map<string, ComponentRef<unknown>> = new Map();

  addContainer(
    key: string,
    container: ViewContainerRef,
    defaultContent: TemplateRef<any>
  ) {
    if (this.dynamicContainers.has(key)) {
      console.warn(`Container ${key} already exists`);
      return;
    }
    // Render default content
    container.createEmbeddedView(defaultContent);

    /**
     * Cache the default content (if any) so that we can fallback to it
     * if we remove the dynamic content
     **/
    this.defaultContent.set(key, defaultContent);

    // Cache container in order to render the dynamic content later on
    this.dynamicContainers.set(key, container);
  }

  removeContainer(key: string) {
    if (!this.dynamicContainers.has(key)) {
      console.warn(`Container ${key} does not exist`);
      return;
    }
    this.dynamicContainers.delete(key);
    this.defaultContent.delete(key);
    const componentRef = this.dynamicComponentRefs.get(key);
    componentRef?.destroy();
    this.dynamicComponentRefs.delete(key);
  }

  renderComponentTo(
    key: string,
    component: Type<unknown>,
    injector?: Injector
  ) {
    if (!this.dynamicContainers.has(key)) {
      console.warn(`Container ${key} does not exist`);
      return;
    }

    const container = this.dynamicContainers.get(key);

    // Clear pre-existing ng-container content (default content)
    container?.clear();

    // Create the desired component and attach it to the desired container
    const componentRef = container?.createComponent(component, {
      injector,
    });

    // Ensure the newly created dynamic component will be marked for cd
    componentRef.changeDetectorRef.markForCheck();

    // Preserve component's ref so we can later destroy it (if we need to do so by hand)
    this.dynamicComponentRefs.set(key, componentRef);
  }

  unrenderComponent(key: string) {
    if (
      !this.dynamicContainers.has(key) ||
      !this.dynamicComponentRefs.has(key)
    ) {
      console.warn(`Container ${key} has already been destroyed`);
      return;
    }

    const componentRef = this.dynamicComponentRefs.get(key);
    componentRef?.destroy();

    // Rollback to initial content (if any)
    this.dynamicContainers
      .get(key)
      .createEmbeddedView(this.defaultContent.get(key));

    this.dynamicComponentRefs.delete(key);
  }
}
