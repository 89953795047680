<app-base-modal-component [headerDetails]="headerDetails" class="height-100">
  <ng-container ngProjectAs="coverContent">
    <div *ngIf="imageUrl" class="image-details-dialog">
      <img
        class="blur-img"
        [src]="imageUrl"
        appFallbackImg="assets/static/images/no_photo.svg"
      />
      <img
        class="bg-img"
        [src]="imageUrl"
        appFallbackImg="assets/static/images/no_photo.svg"
      />
    </div>
  </ng-container>
  <ng-container ngProjectAs="detailsContent">
    <div class="col-12">
      <div *ngIf="trimmedProfileName" class="item-description">
        <span>
          <b>{{ 'Name' | translate }}:</b> {{ profile.name }}
        </span>
      </div>
      <div
        *ngIf="profile.source === 'WA' && profile?.image?.publishedAt"
        class="item-description"
      >
        <span>
          <b>{{ 'Photo update time' | translate }}:</b>
          {{ profile.image.publishedAt | date: 'dd MMM yyyy, HH:mm' }}
        </span>
      </div>
      <div *ngIf="profile.about" class="item-description">
        <span>
          <b>{{ 'About' | translate }}:</b> {{ profile.about }}
        </span>
      </div>
      <div *ngIf="profile.username" class="item-description">
        <span>
          <b>{{ 'Username' | translate }}:</b> {{ profile.username }}
        </span>
      </div>
      <div *ngIf="profile.telno" class="item-description">
        <span>
          <b>{{ 'Phone' | translate }}:</b> {{ profile.telno }}
        </span>
      </div>
      <div *ngIf="profile.dob" class="item-description">
        <span>
          <b>{{ 'Date of birth' | translate }}:</b>
          {{ profile.dob | date: 'MMM dd yyyy' }}
        </span>
      </div>
      <div *ngIf="profile.placeOfBirth" class="item-description">
        <span>
          <b>{{ 'Place of birth' | translate }}:</b> {{ profile.placeOfBirth }}
        </span>
      </div>
      <div *ngIf="profile.location" class="item-description">
        <span>
          <b>{{ 'Address' | translate }}:</b> {{ profile.location }}
        </span>
      </div>
      <div *ngIf="profile.nik" class="item-description">
        <span> <b>NIK:</b> {{ profile.nik }} </span>
      </div>
      <div *ngIf="profile.registrationDate" class="item-description">
        <span>
          <b>{{ 'Registration date' | translate }}:</b>
          {{ profile.registrationDate | date: 'MMM dd yyyy' }}
        </span>
      </div>
      <div *ngIf="profile.telecomProvider" class="item-description">
        <span>
          <b>{{ 'Provider' | translate }}:</b> {{ profile.telecomProvider }}
        </span>
      </div>
      <div *ngIf="profile.lastseen" class="item-description">
        <span>
          <b>{{ 'Last seen online' | translate }}:</b>
          {{ profile.lastseen | date: 'EEE MMM dd yyyy H:mm:ss' }}
        </span>
      </div>
      <div *ngIf="profile.status" class="item-description">
        <span>
          <b>{{ 'Status' | translate }}:</b> {{ profile.status }}
        </span>
      </div>
    </div>
    <div
      *ngIf="profile.sourceEntity?.createdAt"
      class="query-date assistant-font"
    >
      {{ 'Data collected at' | translate }}:
      {{ profile.sourceEntity?.createdAt | date: 'dd/MM/yyyy HH:mm' }}
    </div>
  </ng-container>
</app-base-modal-component>
