import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapLoaderService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url =
        'https://maps.googleapis.com/maps/api/js?key=' +
        environment.googleAPIKey +
        '&loading=async&callback=Function.prototype' +
        '&libraries=places,geometry,drawing,visualization';
      const script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.text = ``;
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      this.document.body.appendChild(script);
    });
  }
}
