import * as _ from 'lodash-es';

enum Opacity {
  VISIBLE = 1,
  INVISIBLE = 0.3,
}

const REDUCER = 0.15;

export class WordCloudItem {
  constructor(params: {
    text: string;
    category: string;
    subCategory: string;
    count: number;
  }) {
    this.text = params.text;
    this.category = params.category;
    this.subCategory = params.subCategory;
    this.count = params.count;
  }
  /**
   * D3-cloud library will extend WordCloudItem object with this calculated params.
   * <BEGIN OF THE D3-CLOUD EXTENDED PARAMS>
   */
  public x: number;
  public y: number;
  public size: number;
  public rotate: number;
  /**
   * </END OF THE D3-CLOUD EXTENDED PARAMS>
   */

  public category: string;
  public subCategory: string;
  public active: boolean;
  public text: string;
  public textFill = '#404040';
  public id = _.uniqueId('wc_');
  public count: number;
  public opacity = Opacity.VISIBLE;

  public disable() {
    this.opacity = Opacity.INVISIBLE;
  }

  public enable() {
    this.opacity = Opacity.VISIBLE;
  }

  /**
   *
   * @param text HTMLElement
   */
  calcPointXPosition(text: HTMLElement) {
    const { width } = text.getBoundingClientRect();
    return width / 2 + this.size * REDUCER;
  }

  /**
   *
   * @param text HTMLElement
   */
  calcPointYPosition(text: HTMLElement) {
    const { height } = text.getBoundingClientRect();
    return -(height / 2) - this.size * REDUCER;
  }

  onClick() {
    this.active = !this.active;
    this.enable();
  }

  /**
   *
   * @param status boolean
   */
  setActiveStatus(status: boolean) {
    this.active = status;
  }

  getPointRadius() {
    return this.size * REDUCER;
  }
}
