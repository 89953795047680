<div *ngIf="concurrentWarnings" class="warning-container">
  <div>
    <img
      class="warning-icon"
      src="assets/static/images/target_case_limit_warning.svg"
      alt="Warning Icon"
      height="16"
    />
  </div>
  <div class="text">
    {{ concurrentWarnings }}
  </div>
</div>
