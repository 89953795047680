import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ActivateOtpModalComponent } from 'src/app/components/user-settings/otp-section/activate-otp-modal/activate-otp-modal.component';
import { OTPModalsData } from 'src/app/components/user-settings/user-settings.models';
import { AuthDataService } from 'src/app/services/authentication/auth-data.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-mobile-activate-otp-modal',
  templateUrl: './mobile-activate-otp-modal.component.html',
  styleUrls: ['./mobile-activate-otp-modal.component.scss'],
})
export class MobileActivateOtpModalComponent extends ActivateOtpModalComponent {
  constructor(
    public userService: UserService,
    protected authService: AuthDataService,
    protected dialogRef: MatDialogRef<
      MobileActivateOtpModalComponent | ActivateOtpModalComponent
    >,
    @Inject(MAT_DIALOG_DATA)
    protected data: OTPModalsData
  ) {
    super(userService, authService, dialogRef, data);
  }
}
