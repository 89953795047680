import { DataSource, EntityType } from '@trg-commons/data-models-ts';
import { getProperty } from 'src/app/shared/util/helper';
import { AdIntWsEntity } from './models/ad-int-ws-entity.model';

export function isValidIfa(value: string): boolean {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return value.length && regex.test(value);
}

export function isTypeOf(type: EntityType, entity: AdIntWsEntity): boolean {
  return entity.type === type;
}

export function isDataSourceOf(
  source: DataSource,
  entity: AdIntWsEntity
): boolean {
  return entity.source === source;
}

export function entityUniqueKey(entity: AdIntWsEntity): string {
  const keyByEventEventTypePath = {
    [EntityType.AdRtb]: 'device.ifa',
    [EntityType.ProximityAdRtb]: 'device.ifa',
    [EntityType.Cdr]: 'msisdn',
    [EntityType.Cell]: 'msisdn',
  };

  let entityType = entity.type;
  if (entityType === EntityType.Heatmap) {
    if (isDataSourceOf(DataSource.Cdr, entity)) {
      entityType = EntityType.Cdr;
    } else {
      entityType = EntityType.AdRtb;
    }
  }
  const path = keyByEventEventTypePath[entityType];
  return getProperty(path, entity) as string;
}
export function entityUniqueKeyType(entityType: EntityType): string {
  const keyTypes = {
    [EntityType.AdRtb]: 'ifas',
    [EntityType.Cdr]: 'msisdns',
    [EntityType.Cell]: 'msisdns',
    [EntityType.ProximityAdRtb]: 'ifas',
  };
  return keyTypes[entityType];
}
