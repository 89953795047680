import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FontAwesomeFamily } from '../icons/fa-icon-family.enum';
import { FontAwesomeIcon } from '../icons/fa-icon.interface';
import { ButtonSize } from './button-size.enum';

@Component({
  selector: 'intellectus-icon-text-button',
  templateUrl: './icon-text-button.component.html',
  styleUrls: ['./icon-text-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconTextButtonComponent {
  @Input() badge?: number;
  @Input() buttonText = '';
  @Input() buttonIcon: FontAwesomeIcon = {
    name: 'question',
    family: FontAwesomeFamily.SOLID,
  };
  @Input() isLoading = false;
  @Input() buttonSize: ButtonSize = ButtonSize.Default;
  @Input() isDisabled = false;
  @Input() rotate = false;
  @Input() rotateWithTransition = true;
  @Output() buttonClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  onClick(event: MouseEvent): void {
    if (!this.isDisabled && !this.isLoading) {
      this.buttonClick.emit(event);
    }
  }
}
