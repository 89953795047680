<div
  *ngIf="userService.currentUser$ | async; let currentUser"
  class="my-details-wrapper"
>
  <div
    class="my-details-header d-flex align-items-center justify-content-between"
  >
    <p class="title">
      {{ 'My details' | translate }}
    </p>
    <div class="edit d-flex align-items-center">
      <intellectus-icon-text-button
        [buttonIcon]="fAavailableIcons.edit"
        buttonSize="xsmall"
        class="clickable icon"
        (click)="setForm(userFormState.All, currentUser)"
        [matTooltip]="'Edit' | translate"
      ></intellectus-icon-text-button>
      <span>{{ 'Edit' | translate }}</span>
    </div>
  </div>

  <div class="my-details-body">
    <form *ngIf="userForm" [formGroup]="userForm" class="add-user-form">
      <div class="username-wrapper">
        <div class="subwrapper username-wrapper">
          <p class="username label">{{ 'Username' | translate }}</p>
          <p class="username text">
            <span *ngIf="currentUser.username">{{ currentUser.username }}</span>
          </p>
        </div>
      </div>

      <div
        class="email-and-error-wrapper"
        *ngIf="!currentUser.roles.includes('support')"
      >
        <div class="subwrapper email-wrapper">
          <p class="email label">{{ 'Email' | translate }}</p>
          <p *ngIf="!this.userForm.contains('email')" class="email text">
            <span *ngIf="currentUser.email">{{ currentUser.email }}</span>
            <span
              class="add-span"
              *ngIf="!currentUser.email && !this.userForm.contains('email')"
              (click)="setForm(userFormState.Email, currentUser)"
              >{{ 'Add' | translate }}</span
            >
          </p>
          <div
            *ngIf="this.userForm.contains('email')"
            class="input-style-round"
          >
            <input
              matInput
              formControlName="email"
              type="text"
              placeholder="{{ currentUser.email }}"
              autocomplete="off"
            />
          </div>
        </div>
        <mat-error
          class="my-details-error"
          *ngIf="
            userForm.controls.email?.dirty &&
            (userForm.controls.email?.hasError('required') ||
              userForm.controls.email?.hasError('pattern'))
          "
          >{{ 'Please provide your email address' | translate }}</mat-error
        >
      </div>

      <div
        class="phone-and-error-wrapper"
        *ngIf="!currentUser.roles.includes('support')"
      >
        <div class="subwrapper phone-wrapper">
          <p class="phone label">{{ 'Phone Number' | translate }}</p>
          <p *ngIf="!this.userForm.contains('otpPhone')" class="phone text">
            <span *ngIf="currentUser.otpPhone">{{ currentUser.otpPhone }}</span>
            <span
              class="add-span"
              *ngIf="
                !currentUser.otpPhone && !this.userForm.contains('otpPhone')
              "
              (click)="setForm(userFormState.Phone, currentUser)"
              >{{ 'Add' | translate }}</span
            >
          </p>
          <div *ngIf="userForm.contains('otpPhone')" class="input-style-round">
            <input
              matInput
              formControlName="otpPhone"
              type="text"
              placeholder="{{ currentUser.otpPhone }}"
              autocomplete="off"
            />
          </div>
        </div>
        <mat-error
          class="my-details-error"
          *ngIf="
            userForm.controls.otpPhone?.dirty &&
            userForm.controls.otpPhone?.hasError('invalidPhone')
          "
          >{{ 'Enter a valid phone' | translate }}</mat-error
        >
      </div>

      <div class="subwrapper password-wrapper">
        <p class="password label">
          {{
            userForm.contains('password')
              ? ('Current Password' | translate)
              : ('Password' | translate)
          }}
        </p>
        <p
          *ngIf="!userForm.contains('password')"
          class="password text d-flex align-items-center"
        >
          *********

          <span
            class="change-span"
            (click)="setForm(userFormState.Passwords, currentUser)"
            >{{ 'change' | translate }}</span
          >
        </p>

        <div *ngIf="userForm.contains('password')" class="input-style-round">
          <input
            matInput
            type="text"
            formControlName="password"
            [type]="visibility ? 'text' : 'password'"
            autocomplete="off"
          />
          <mat-icon
            class="icon eye-icon"
            (click)="this.visibility = !this.visibility"
          >
            {{ visibility ? 'visibility_off' : 'visibility' }}</mat-icon
          >
        </div>
      </div>

      <div class="confirm-password-and-strength-wrapper">
        <div
          *ngIf="userForm.contains('newPassword')"
          class="subwrapper password-wrapper"
        >
          <p class="password label">{{ 'New Password' | translate }}</p>
          <div class="input-style-round">
            <input
              matInput
              [type]="visibility ? 'text' : 'password'"
              formControlName="newPassword"
              autocomplete="off"
            />
            <mat-icon
              class="icon eye-icon"
              (click)="this.visibility = !this.visibility"
            >
              {{ visibility ? 'visibility_off' : 'visibility' }}</mat-icon
            >
          </div>
        </div>
        <mat-error
          class="my-details-error"
          *ngIf="
            userForm.controls.newPassword?.dirty &&
            userForm.controls.newPassword?.hasError('passwordAtLeast8Chars')
          "
          >{{
            'Password must be at least 8 characters.' | translate
          }}</mat-error
        >
        <mat-error
          class="my-details-error"
          *ngIf="
            userForm.controls.newPassword?.dirty &&
            userForm.controls.newPassword?.hasError('passwortAtLeastUppercase')
          "
          >{{
            'Password does not contain upper case letters.' | translate
          }}</mat-error
        >
        <mat-error
          class="my-details-error"
          *ngIf="
            userForm.controls.newPassword?.dirty &&
            userForm.controls.newPassword?.hasError('passwordAtLeastLowercase')
          "
          >{{
            'Password does not contain lower case letters.' | translate
          }}</mat-error
        >
        <mat-error
          class="my-details-error"
          *ngIf="
            userForm.controls.newPassword?.dirty &&
            userForm.controls.newPassword?.hasError('passwordAtLeastDigits')
          "
          >{{ 'Password does not contain any digits.' | translate }}</mat-error
        >
      </div>
    </form>

    <div *ngIf="areControlsEnabled()" class="button-wrapper">
      <button
        mat-button
        class="margin-right10 cp-btn-ghost"
        [disabled]="isLoading"
        (click)="cancelEdit()"
      >
        {{ 'Cancel' | translate }}
      </button>
      <button
        mat-button
        class="margin-right10 cp-btn-primary"
        [disabled]="isLoading"
        (click)="submitForm()"
      >
        {{ 'Save' | translate }}
      </button>
    </div>
  </div>
  <ng-container *ngIf="!currentUser.roles.includes('support')">
    <div class="divider partial"></div>
    <div class="my-details-footer">
      <p class="footer-notification">{{ 'Notifications' | translate }}</p>
      <div class="notification-checkbox justify-content-between">
        <span class="notification-label email">{{ 'Email' | translate }}</span>
        <mat-slide-toggle
          [disabled]="
            !currentUser.email || isLoading || notificationsAreDisabled
          "
          (click)="$event.stopPropagation()"
          [checked]="currentUser.emailNotifications"
          (change)="
            handleNotificationUpdate(currentUser, {
              emailNotifications: $event.checked
            })
          "
        >
        </mat-slide-toggle>
      </div>
      <div class="divider"></div>

      <div class="notification-checkbox justify-content-between">
        <span class="notification-label sms">SMS</span>
        <mat-slide-toggle
          [disabled]="
            !currentUser.otpPhone || isLoading || notificationsAreDisabled
          "
          (click)="$event.stopPropagation()"
          [checked]="currentUser.smsNotifications"
          (change)="
            handleNotificationUpdate(currentUser, {
              smsNotifications: $event.checked
            })
          "
        >
        </mat-slide-toggle>
      </div>
      <div class="divider"></div>
    </div>
  </ng-container>
</div>
