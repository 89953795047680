<ng-container *ngrxLet="discoveryV2Enabled$ as discoveryV2Enabled">
  <mat-slide-toggle
    class="toggle"
    [checked]="discoveryV2Enabled"
    [labelPosition]="'before'"
    (toggleChange)="
      discoveryVersionService.toggleDiscoveryModule(discoveryV2Enabled)
    "
  >
    <span class="toggle-label" *ngIf="displayLabel">{{
      'New Discovery' | translate
    }}</span>
  </mat-slide-toggle>
</ng-container>
