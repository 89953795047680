<div *ngIf="link">
  <ng-container [ngSwitch]="link.d.type">
    <ng-container *ngSwitchCase="'facebookMutualFriends'">
      <div class="link-section-title">
        {{ 'Mutual Facebook friends' | translate }}
      </div>
      <div class="mutual-friends">
        <div *ngFor="let friend of link.d.mutualFriends" class="friend">
          <a [href]="friend.profile?.url" target="_blank">
            <img
              height="80"
              class="friend-image"
              src="{{ fileManagerUrl }}/file/{{
                friend.photos[0]
              }}?useThumbnail=yes"
              loading="lazy"
              onerror="this.src='assets/static/images/user_kl.svg'"
            />
          </a>
          <span class="friend-text text-ellipsis">{{
            friend.profile?.name
          }}</span>
          <span class="friend-text text-ellipsis">{{
            friend.profile?.username
          }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
