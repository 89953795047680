import { IntelSearchArgTypes } from './../../modules/search-intel/models/intel-search-arg-types.model';
import { SearchLabels } from './../../modules/search-intel/models/search-intel.model';

export interface InputTypes {
  [key: string]: { value: string; label: string };
}

export const searchInputTypes: InputTypes = {
  [IntelSearchArgTypes.TELNO]: {
    value: SearchLabels.PHONE,
    label: 'Phone number',
  },
  [IntelSearchArgTypes.POSSIBLE_TELNO]: {
    value: SearchLabels.POSSIBLE_TELNO,
    label: 'Phone number',
  },
  [IntelSearchArgTypes.NAME_WITH_SURNAME]: {
    value: SearchLabels.NAME_WITH_SURNAME,
    label: 'Name Surname',
  },
  [IntelSearchArgTypes.NAME_WITH_MIDDLE_AND_SURNAME]: {
    value: SearchLabels.NAME_WITH_MIDDLE_AND_SURNAME,
    label: 'Name Middle Surname',
  },
  [IntelSearchArgTypes.NAME]: {
    value: SearchLabels.NAME,
    label: 'Full Name',
  },
  [IntelSearchArgTypes.EMAIL]: { value: SearchLabels.EMAIL, label: 'Email' },
  [IntelSearchArgTypes.URL]: {
    value: SearchLabels.URL,
    label: 'Profile url for Facebook, Twitter, Instagram, Linkedin, Tiktok',
  },
  [IntelSearchArgTypes.USERNAME]: {
    value: SearchLabels.USERNAME,
    label: 'Username',
  },
  [IntelSearchArgTypes.NIK]: {
    value: SearchLabels.NIK,
    label: 'NIK',
  },
  [IntelSearchArgTypes.HASHTAG]: {
    value: SearchLabels.HASHTAG,
    label: 'Twitter hashtag',
  },
  [IntelSearchArgTypes.CURP]: { value: SearchLabels.CURP, label: 'CURP' },
  [IntelSearchArgTypes.IMEI]: {
    value: SearchLabels.IMEI,
    label: 'IMEI',
  },
};
