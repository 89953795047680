<div class="generic-activity-cell">
  <div *ngIf="!activityCell.value">
    <span class="no-data">{{ 'No data' | translate }}</span>
  </div>

  <div *ngIf="activityCell.value">
    <ul class="cell-list">
      <ng-container *ngFor="let cell of activityCell.details | keyvalue">
        <li>
          {{ cell.key | translate }}: <span>{{ cell.value }}</span>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
