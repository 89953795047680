<app-dialog>
  <div header class="header-inner">
    <span class="title">
      {{ 'Start your investigation' | translate }}
    </span>
  </div>

  <div body [formGroup]="filters">
    <p class="body-description">
      {{ 'You are investigating' | translate }}
      <strong>{{ msisdnsString }}</strong
      >{{ ' call logs.' | translate }}
      {{
        'From the available CDR files, select the data you would like to load into the Explorer'
          | translate
      }}.
    </p>

    <section class="section-wrapper-vertical">
      <div class="flex-child-vertical">
        <h4 class="section-title">
          {{ 'Interactions/ Relations' | translate }}
        </h4>
      </div>
      <div class="flex-child-vertical checkboxes">
        <mat-checkbox
          formControlName="directInteractions"
          value="direct-connection"
          [disabled]="idsArray?.length < 2"
          >{{ 'Show direct interactions between selected MSISDNs' | translate }}
        </mat-checkbox>
        <mat-checkbox formControlName="topAssociates" value="top-associates"
          >{{ 'Show top associates' | translate }}
        </mat-checkbox>
        <mat-checkbox
          formControlName="internationalInteractions"
          value="international-interactions"
          >{{ 'Show international interactions' | translate }}
        </mat-checkbox>
      </div>
    </section>
    <div class="section-wrapper">
      <section class="section-wrapper-column">
        <div class="flex-child">
          <h4 class="section-title">{{ 'Select date range' | translate }}</h4>
        </div>
        <div class="flex-child">
          <mat-form-field class="input-wrp">
            <input
              matInput
              [owlDateTimeTrigger]="dt10"
              [owlDateTime]="dt10"
              [selectMode]="'range'"
              name="dates"
              autocomplete="off"
              placeholder="{{ 'From - To' | translate }}"
              class="clickable"
              (dateTimeChange)="dateTimeChange($event.value)"
            />
            <owl-date-time
              class="timer"
              panelClass="timer"
              #dt10
            ></owl-date-time>
          </mat-form-field>
        </div>
      </section>

      <section class="section-wrapper-column">
        <div class="flex-child">
          <h4 class="section-title">{{ 'Type of data' | translate }}</h4>
        </div>
        <div class="flex-child">
          <form class="checkboxes" formGroupName="limitInteractionTypes">
            <mat-checkbox formControlName="voice" value="voice"
              >{{ 'Voice' | translate }}
            </mat-checkbox>
            <mat-checkbox formControlName="sms" value="sms"
              >{{ 'SMS' | translate }}
            </mat-checkbox>
            <mat-checkbox formControlName="mobileData" value="mobileData"
              >{{ 'Mobile Data' | translate }}
            </mat-checkbox>
          </form>
        </div>
      </section>
    </div>
    <div class="section-wrapper">
      <section class="section-wrapper-column" *ngIf="countries.length > 0">
        <div class="flex-child">
          <h4 class="section-title">{{ 'Select country' | translate }}</h4>
        </div>
        <div class="flex-child">
          <mat-form-field class="input-wrp">
            <mat-select [formControl]="country">
              <mat-option
                *ngFor="let country of countries"
                [value]="country.countryCode"
              >
                {{ country.countryName }} ({{ country.countryCode }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </section>
      <section class="section-wrapper-column" *ngIf="regionList.length > 0">
        <div class="flex-child">
          <h4 class="section-title">{{ 'Select region' | translate }}</h4>
        </div>
        <div class="flex-child">
          <mat-form-field class="input-wrp">
            <mat-select multiple [formControl]="regions">
              <mat-select-trigger>
                {{ regions.value?.[0] || '' }}
                <span
                  *ngIf="(regions.value?.length || 0) > 1"
                  class="example-additional-selection"
                >
                  (+{{ (regions.value?.length || 0) - 1 }}
                  {{ regions.value?.length === 2 ? 'other' : 'others' }})
                </span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let region of regionList"
                [value]="region.regionCode"
              >
                {{ region.regionNames[0] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </section>
    </div>
  </div>

  <div footer>
    <div class="btn-wrp">
      <button
        mat-button
        class="cp-btn-primary cp-search-btn active"
        [disabled]="!filters.dirty"
        (click)="applyFilters(false)"
      >
        <span>
          <span class="btn-txt">{{ 'Load selected data' | translate }}</span>
        </span>
      </button>
      <button mat-flat-button (click)="applyFilters(true)">
        <span>
          <span class="btn-txt">{{ 'Skip filtering' | translate }}</span>
        </span>
      </button>
    </div>
  </div>
</app-dialog>
