import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { BaseTooltipComponent } from './base-tooltip.component';

@Directive({
  selector: '[intellectusCustomTooltip]',
})
export class CustomTooltipDirective implements OnInit, OnDestroy {
  private overlayRef!: OverlayRef;
  @Input() intellectusCustomTooltip!: TemplateRef<{ data: unknown }>;
  @Input() tooltipData!: object;
  @Input() showTooltip = true;
  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    if (!this.showTooltip) {
      return;
    }
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 11,
          panelClass: 'ct-position-bottom',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -11,
          panelClass: 'ct-position-top',
        },
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          offsetX: 11,
          panelClass: 'ct-position-right',
        },
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: 11,
          panelClass: 'ct-position-left',
        },
      ]);
    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  @HostListener('mouseenter')
  show() {
    if (!this.showTooltip) {
      return;
    }
    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<BaseTooltipComponent> =
        this.overlayRef.attach(new ComponentPortal(BaseTooltipComponent));
      tooltipRef.instance.contentTemplate = this.intellectusCustomTooltip;
      tooltipRef.instance.contentData = this.tooltipData;
    }
  }

  ngOnDestroy(): void {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
