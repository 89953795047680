import { Entity } from '../base';
import { ChatType } from '../enums';

export interface Message {
  id: string;
  type: string;
  date: string;
  dateUnixtime: string;
  from: string;
  fromId: string;
  text: string;
  file?: string;
}

export class Chat extends Entity {
  public queryArgs: { argType: string; argValue: string; platform: string }[];
  public targetId: string;
  public chatId: string;
  public chatType: ChatType;
  public name: string;
  public members: string[];
  public publishedAt: string;
  public owner: string;
  public ownerId: string;

  public messages: Message[];

  keywords?: string[];
  chatSummary?: string;
  chatSentimentAnalysis?: string;
}
