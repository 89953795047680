<app-dialog>
  <div header>
    <span class="title">
      {{ 'Import files' | translate }}
    </span>
  </div>

  <div body>
    <p class="main-title">
      {{ 'Enrich target with Somedus data' | translate }}
    </p>
    <div class="row select-section">
      <div class="col-md-12">
        <mat-select
          class="select-field"
          [(ngModel)]="platform"
          placeholder="{{ 'Select platform' | translate }}"
          disabled
        >
          <mat-option value="telegram">Telegram</mat-option>
        </mat-select>
      </div>
    </div>

    <section class="drop-file-section">
      <ngx-file-drop
        *ngIf="status === 'EMPTY'"
        dropZoneLabel="Drop files here"
        [multiple]="false"
        (onFileDrop)="onDropped($event)"
        [accept]="'.zip, .7zip'"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          <div class="upload-content">
            <p class="title">
              <strong>{{
                'Drag and Drop file here or click to browse' | translate
              }}</strong>
            </p>
            <p class="description">
              {{
                'Make sure that the file is in Zip format and contains a JSON file'
                  | translate
              }}
            </p>
            <button
              mat-button
              class="cp-btn-ghost"
              (click)="openFileSelector()"
            >
              {{ 'Browse' | translate }}
            </button>
          </div>
        </ng-template>
      </ngx-file-drop>

      <div
        *ngIf="
          (status === 'SELECTED_FILE' || status === 'UPLOADING') &&
          !!selectedFileInfo
        "
        class="mt-20 row flex-wrp space-between"
      >
        <p>
          <fe-platform-fa-pro-icon
            class="fa-icon-btn"
            [family]="'regular'"
            [name]="'file'"
          ></fe-platform-fa-pro-icon>
          {{ selectedFileInfo?.name }}
        </p>
        <p>
          {{ selectedFileInfo?.size | fileSize }}
          <fe-platform-fa-pro-icon
            *ngIf="status === 'SELECTED_FILE'"
            class="fa-icon-btn"
            [family]="'regular'"
            [name]="'xmark'"
            (click)="removeFile($event)"
          ></fe-platform-fa-pro-icon>
        </p>
      </div>

      <div
        *ngIf="status === 'SELECTED_FILE' || status === 'UPLOADING'"
        class="row flex-wrp info-message-wrapper"
      >
        <fe-platform-fa-pro-icon
          class="fa-icon-btn info-icon"
          [family]="'light'"
          [name]="'triangle-exclamation'"
        ></fe-platform-fa-pro-icon>
        <p class="warning-msg">
          <ng-container *ngIf="estimatedTimeUpload">
            {{ 'This process might take approximately' | translate }}
            {{ estimatedTimeUpload }}.
          </ng-container>
          {{
            'We will notify you once the enrichment is completed' | translate
          }}.
          <br />
          <b>
            {{
              'During the importing process, please do not log out, close this tab or transition to another website'
                | translate
            }}. {{ 'Otherwise, your progress will not be saved' | translate }}.
          </b>
        </p>
      </div>
      <div
        class="loaders-container"
        *ngIf="loadingState?.isLoading && status === 'UPLOADING'"
      >
        <mat-progress-bar
          mode="indeterminate"
          color="accent"
        ></mat-progress-bar>
      </div>
    </section>
  </div>

  <div footer>
    <button
      mat-button
      class="cp-btn-primary"
      (click)="uploadFile()"
      [disabled]="
        !platform || !selectedFileInfo?.name || status === 'UPLOADING'
      "
    >
      {{ 'Enrich Profile' | translate }}
    </button>
  </div>
</app-dialog>
