import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  filter(dateRange, user, unique) {
    const params = {};
    let endpoint = 'unique';
    if (dateRange) {
      params['from_date'] = dateRange[0].toISOString();
      params['to_date'] = dateRange[1].toISOString();
    }

    if (user) {
      params['created_by'] = user;
    }

    if (!unique) {
      endpoint = 'all';
    }
    params['limit'] = 1000;

    return this.httpClient
      .get<any>(`${this.url}/statistics/geo/${endpoint}`, { params })
      .pipe(
        map((data) => {
          return data.data;
        }),
        catchError((error) => this.handleError(error))
      );
  }
}
