<div class="textarea-container" [style.width]="width">
  <div
    class="textarea-label-container"
    [class.flex-end]="!label && optionalLabel"
    [class.flex-start]="label && !optionalLabel"
    [class.space-between]="label && optionalLabel"
  >
    <label *ngIf="label">{{ label }}</label>
    <span *ngIf="optionalLabel" class="optional">{{ optionalLabel }}</span>
  </div>

  <div
    class="textarea-field-container"
    [class.disabled]="disabled"
    [class.small]="size === textareaSizeEnum.SMALL"
  >
    <textarea
      #textareaElement
      [(ngModel)]="textareaValue"
      [attr.maxlength]="characterLimit !== 0 ? characterLimit : null"
      [class.error-state]="errorMessage !== ''"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [style.max-height]="maxHeight"
      [style.overflow-y]="isOverflowing ? 'auto' : 'hidden'"
      [style.width]="width"
      (input)="adjustHeight(textareaElement)"
      (change)="onValueChange()"
      (keyup)="onKeyUp()"
    ></textarea>
  </div>

  <div *ngIf="hint" class="hint-msg">
    {{ characterLimit ? characterLimitHint : hint }}
  </div>

  <ng-container *ngIf="errorMessage">
    <div class="state-message-container error-msg">
      <fe-platform-fa-pro-icon
        [family]="'regular'"
        [name]="'circle-exclamation'"
      ></fe-platform-fa-pro-icon>
      <span>{{ errorMessage }}</span>
    </div>
  </ng-container>
</div>
