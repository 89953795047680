import { FeedType } from './feed-type.enum';
import { Post } from '../platform-models/social/post';
import { PostDTO } from '../../services/social/post/post-dto';
import { BaseDTO } from '../../services/base';
import { Feed } from './feed';
import { ReferenceCollection } from './reference-collection.enum';
import { NoteDTO } from './note-dto';
import { ImageDTO } from './image-dto';
import { GeoQueryDTO } from './geo-query-dto';
export class FeedDTO extends BaseDTO<Feed, FeedDTO> {
  private id: string;
  private target_id: string;
  private reference_id: string;
  private reference_collection: ReferenceCollection;
  private query_date: string;
  private feed_type: FeedType;
  // this needs to be clarified,
  // i presume that this is set according to above feed_type
  private feed: PostDTO | NoteDTO | ImageDTO | unknown;
  private notes?: NoteDTO[];
  constructor(model: Partial<FeedDTO>) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }
  fromModel(feed: Feed): FeedDTO {
    return this;
  }
  toModel(): Feed {
    return new Feed({
      id: this.id,
      targetId: this.target_id,
      referenceId: this.reference_id,
      referenceCollection: this.reference_collection,
      queryDate: new Date(this.query_date),
      feedType: this.feed_type,
      feedData: this.returnFeedByType(this.feed_type),
      ...(this.notes?.length && {
        notes: this.notes.map((note) => new NoteDTO(note).toModel()),
      }),
      ...(this.feed['published_at'] && {
        publishedAt: new Date(this.feed['published_at']),
      }),
    });
  }

  private returnFeedByType(feedType: FeedType): Post | unknown {
    switch (feedType) {
      case FeedType.IMAGE:
        return new ImageDTO(this.feed as ImageDTO).toModel();
      case FeedType.NOTE:
        return new NoteDTO(this.feed as NoteDTO).toModel();
      case FeedType.POST:
        return new PostDTO(this.feed as PostDTO).toModel();
      case FeedType.LOCATION:
        return new GeoQueryDTO(this.feed as GeoQueryDTO).toModel();
      default:
        console.warn(
          'FeedDTO unknown feed type skipping result: ',
          feedType,
          this.feed
        );
        break;
    }
  }
}
