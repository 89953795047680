import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, fromEvent } from 'rxjs';

@Injectable()
export class LayoutFacade {
  isMobile$ = new BehaviorSubject<boolean>(false);
  isVisible$ = new BehaviorSubject<boolean>(true);
  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(distinctUntilChanged())
      .subscribe((isMobile) => {
        this.isMobile$.next(isMobile.matches);
      });
    fromEvent(document, 'visibilitychange').subscribe(() =>
      this.isVisible$.next(!this.isVisible$.getValue())
    );
  }
}
