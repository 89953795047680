<app-lightbox-component>
  <div class="row base-content-modal">
    <div class="header-section">
      <div mat-dialog-title class="dialog-title">
        <div class="col-md-12 header">
          <div class="title">
            <h1 class="assistant-font">
              {{ title }}
            </h1>
          </div>
        </div>
        <button
          *ngIf="showClose"
          class="close-icon"
          (click)="close()"
          mat-icon-button
          aria-label="close dialog"
          mat-dialog-close
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="details-section">
      <ng-content select="detailsContent"></ng-content>
    </div>
    <div *ngIf="showFooter" class="footer-section">
      <div class="actions" mat-dialog-actions>
        <ng-content select="footerContent"></ng-content>
      </div>
    </div>
  </div>
</app-lightbox-component>
