<div
  class="row query-item"
  (click)="selectQuery(query)"
  [class.selected-geo4]="selectedQuery && query.id === selectedQuery.id"
  [class.multipins]="multipinsClass"
  [class.new]="'new'"
  [class.even-item]="even"
  [class.odd-item]="!even"
  [class.query-item-mobile-geo]="isMobileResolution"
  (mouseover)="showCheckbox = true"
  (mouseleave)="
    checkBoxEnabled ? (showCheckbox = true) : (showCheckbox = false)
  "
>
  <div class="center-md checkbox-wrp col-separator" *ngIf="!isMobileResolution">
    <div class="query-checkbox">
      <mat-checkbox
        class="query-check-box-geo4"
        (click)="multipinsClick = true"
        (change)="multiselect($event.checked, query)"
        [checked]="selectAll"
        (click)="$event.stopPropagation()"
      >
      </mat-checkbox>
    </div>
  </div>

  <!-- Table Target -->
  <div class="col-separator telno col-telno" [class.col-5]="isMobileResolution">
    <div class="query-text">
      <span class="telno">
        {{
          query.alias
            ? query.alias
            : query.queryArgs.telno
            ? query.queryArgs.telno
            : query.queryArgs.imsi
        }}
      </span>
      <span
        class="hashtag clickable"
        [swal]="hashtagSwal"
        matTooltip="{{ 'Query hashtag' | translate }}"
        >#{{ query['tag'] || '' }}</span
      >
      <mat-icon
        *ngIf="!showTelnoHistory && query.queryCount > 1 && !isMobileResolution"
        class="clickable history"
        (click)="showNumberHistory($event, query)"
        matTooltip="{{ 'Show history' | translate }}"
      >
        <img
          *ngIf="
            (!selectedQuery ||
              (selectedQuery && query.id !== selectedQuery.id)) &&
            !multipinsClass
          "
          src="assets/static/images/log_history.svg"
          height="15"
          width="15"
        />
        <img
          *ngIf="
            (selectedQuery && query.id === selectedQuery.id) || multipinsClass
          "
          src="assets/static/images/log_history_inverted.svg"
          height="15"
          width="15"
        />
      </mat-icon>
    </div>
  </div>

  <!-- Table Date -->
  <div
    class="start-md col-separator col-date"
    [class.col-4]="isMobileResolution"
  >
    <div class="query-text">
      <span *ngIf="query.locationReceivedAt; else query_created_at">
        {{ query.locationReceivedAt | date: 'dd.MM.yyyy HH:mm' }}
      </span>
      <ng-template #query_created_at>
        {{
          query.createdAt ? (query.createdAt | date: 'dd.MM.yyyy HH:mm') : ''
        }}
      </ng-template>
    </div>
  </div>

  <!-- Table Type -->
  <div
    class="col-separator hide col-type global-text-overflow-wrap"
    matTooltip="{{
      (query.type
        ? query.type
        : query.schedule
        ? query.schedule.scheduleType
        : ''
      ) | querytypeui
    }}"
  >
    <div
      class="query-text"
      [class.emergency]="query.type && query.type === 'emergency'"
    >
      {{
        (query.type
          ? query.type
          : query.schedule
          ? query.schedule.scheduleType
          : ''
        ) | querytypeui
      }}
    </div>
  </div>

  <!-- Query user. Hidden for standard users -->
  <div
    class="col-separator hide global-text-overflow-wrap col-user"
    *ngIf="isAdmin || isPower || isSupport"
    [matTooltip]="query.createdBy"
  >
    <div class="query-text">
      {{ query.createdBy }}
    </div>
  </div>

  <!-- Table Actions -->
  <div
    class="end-xs table-actions col-actions"
    [class.col-2]="isMobileResolution"
  >
    <div class="location-wrp flex-wrp">
      <div
        *ngIf="query['group']"
        class="location-status"
        (click)="showBatchQuery(query.group); multipinsClick = true"
      >
        <mat-icon
          svgIcon="batch-query"
          [class.activeStatus]="
            (selectedQuery && query.id === selectedQuery.id) || multipinsClass
          "
          matTooltip="{{ 'Show batch query' | translate }}"
          aria-label="batch-query"
        ></mat-icon>
      </div>

      <div
        *ngIf="
          query.status === 'DONE' &&
          (!query.roaming || query.cell.mcc === query.provider.mcc) &&
          (!!query.location?.coordinates || query?.cell?.cellId)
        "
        class="location-status"
      >
        <mat-icon
          [class.activeStatus]="
            (selectedQuery && query.id === selectedQuery.id) || multipinsClass
          "
          matTooltip="{{ 'Location found' | translate }}"
          aria-label="location-found"
          >location_on</mat-icon
        >
      </div>

      <div
        *ngIf="
          query.status === 'DONE' &&
          !query.location?.coordinates &&
          !query?.cell?.cellId
        "
        class="location-status"
      >
        <mat-icon
          class="material-icons-outlined"
          [class.activeStatus]="
            (selectedQuery && query.id === selectedQuery.id) || multipinsClass
          "
          matTooltip="{{ 'No location' | translate }}"
          aria-label="no-location"
          >location_off</mat-icon
        >
      </div>

      <div
        *ngIf="
          query.status === 'PENDING' || query.status === 'LOCATION PENDING'
        "
        class="location-status"
      >
        <mat-icon
          svgIcon="location-pending"
          [class.activeStatus]="
            (selectedQuery && query.id === selectedQuery.id) || multipinsClass
          "
          matTooltip="{{ 'Query pending' | translate }}"
          aria-label="location-pending"
        ></mat-icon>
      </div>

      <div
        *ngIf="
          query.status === 'EXPIRED' ||
          query.status === 'NOT ENOUGH CREDITS' ||
          query.status === 'NO LOCATION'
        "
        class="location-status"
      >
        <mat-icon
          class="material-icons-outlined"
          [class.activeStatus]="
            (selectedQuery && query.id === selectedQuery.id) || multipinsClass
          "
          matTooltip="{{ 'No location' | translate }}"
          aria-label="no-location"
          >location_off</mat-icon
        >
      </div>

      <div
        *ngIf="query.status === 'FAILED'"
        class="location-status"
        [ngSwitch]="!!query.cell?.lac || !!query.location?.coordinates"
      >
        <mat-icon
          *ngSwitchCase="true"
          [class.activeStatus]="
            (selectedQuery && query.id === selectedQuery.id) || multipinsClass
          "
          matTooltip="{{ 'Location found' | translate }}"
          aria-label="location-found"
          >location_on</mat-icon
        >
        <mat-icon
          *ngSwitchCase="false"
          class="material-icons-outlined"
          [class.activeStatus]="
            (selectedQuery && query.id === selectedQuery.id) || multipinsClass
          "
          matTooltip="{{ 'No location' | translate }}"
          aria-label="no-location"
          >location_off</mat-icon
        >
      </div>

      <div
        class="clickable"
        *ngIf="
          query.status === 'DONE' &&
          query.roaming &&
          query.cell.mcc !== query.provider.mcc &&
          !!query.location?.coordinates
        "
        class="location-status"
      >
        <mat-icon
          svgIcon="location-roaming"
          [class.activeStatus]="
            (selectedQuery && query.id === selectedQuery.id) || multipinsClass
          "
          matTooltip="{{ 'Roaming' | translate }}"
          aria-label="location-roaming"
        ></mat-icon>
      </div>

      <swal
        #hashtagSwal
        [title]="'Add hashtag for this query' | translate"
        [text]="
          'Note: Please leave input box empty to remove current hashtag'
            | translate
        "
        input="text"
        [inputAttributes]="{
          maxlength: 10
        }"
        [showCancelButton]="true"
        (confirm)="addHashtag($event, query)"
      ></swal>

      <div class="query-actions" *ngIf="!isMobileResolution">
        <button
          (click)="$event.stopPropagation()"
          mat-icon-button
          class="flex-wrp"
          [matMenuTriggerFor]="queryMenu"
          matTooltip="{{ 'Options' | translate }}"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #queryMenu="matMenu" class="query-menu">
          <button
            mat-menu-item
            angulartics2On="click"
            [angularticsAction]="matomo.actions.queryResubmit"
            [angularticsCategory]="matomo.categories.gioQueryLog"
            (click)="resubmitQuery(query)"
            matTooltip="{{ 'Get current location of this MSISDN' | translate }}"
          >
            <fe-platform-fa-pro-icon
              class="locate-icon"
              [family]="'regular'"
              [name]="'location-crosshairs'"
            ></fe-platform-fa-pro-icon>
            {{ 'Locate' | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="query.status === 'EXPIRED' || query.status === 'NO LOCATION'"
            matTooltip="{{ 'Log on task' | translate }}"
            (click)="quickLogonQuery(query)"
          >
            <img [src]="basicImageSrc + 'log_on.svg'" height="20" />{{
              'Log on task' | translate
            }}
          </button>

          <button
            mat-menu-item
            *ngIf="query.supportActiveQuery"
            (click)="openActiveLocateQueryDialog(query)"
            matTooltip="{{ 'Active locate' | translate }}"
          >
            <fe-platform-fa-pro-icon
              class="locate-icon"
              [family]="'regular'"
              [name]="'location-crosshairs'"
            ></fe-platform-fa-pro-icon>
            {{ 'Active locate' | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
