<div
  *ngIf="marketingBannerImage"
  (click)="storeInteraction()"
  innerHTML="{{ notification?.content?.marketingBanner?.text }}"
></div>
<div class="container" *ngIf="!marketingBannerImage">
  <div class="header">
    {{ notification?.content?.marketingBanner?.subject }}
  </div>
  <div
    class="content"
    (click)="storeInteraction()"
    innerHTML="{{ notification?.content?.marketingBanner?.text }}"
  ></div>
</div>
