export enum ApplicationMainPageUrls {
  ADMIN = '/admin',
  TARGETS = '/targets',
  USEROPTIONS = '/user-options',
  STATISTICS = '/statistics',
  INVESTIGATION = '/case-investigation',
  CORE = 'core',
  WEBINT = 'webint',
  LOGIN = 'login',
  WEBINTMODAL = 'webint-modal',
}
