import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AppConfigService } from '@app/config';
import { transformSnakeToCamel } from '@shared/util/helper';
import { EntityType } from 'datalayer/models/platform-models';
import { Observable, Subscription, of, tap } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { FeatureTableRow } from 'src/app/modules/analysis/shared/models/feature-table.model';
import { CaseDashboardSectionsRoutes } from 'src/app/modules/case/models/case-view.model';
import { IMGroup } from 'src/app/modules/search-intel/models/group-analyser.model';
import { BillingService } from 'src/app/services/billing/billing.service';
import { CaseService } from 'src/app/services/case/case.service';
import { RedirectSnackBarService } from 'src/app/services/snack-bar.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Action } from 'src/app/shared/classes/action.class';
import {
  BillingActionType,
  BillingActions,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { AddToCaseModalData, Case } from 'src/app/shared/models/case.model';
import { Themes } from 'src/app/shared/models/skins.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ActionService } from 'src/app/shared/services/action.service';
import { AnalysisActionsListModel } from '../../models/analysis-actions.model';
import { MatChipInputEvent } from '@angular/material/chips';
import { BaseToasterService } from '@fe-platform/shared-ui/intellectus';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-to-case',
  templateUrl: './add-to-case.component.html',
  styleUrls: ['./add-to-case.component.scss'],
})
export class AddToCaseComponent extends BaseComponent implements OnInit {
  @ViewChild('caseInput') caseInput: ElementRef<HTMLInputElement>;

  readonly titleAlreadyExistsError = 'Title exists. Please use another title';

  addToCaseForm: UntypedFormGroup;
  casesList$: Observable<Case[]>;
  selectedCases: Case[] = [];

  visible = true;
  selectable = true;
  removable = true;
  caseCreditsChargesEnabled: boolean;
  creditsForExpired: number;
  billingPlan: BillingPlan<BillingActions, BillingActionType>;
  isUnlimitedTheme = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  caseCreditsMessage: string;
  expireCaseDays: number;

  targets: FeatureTableRow[];
  group: IMGroup;

  private allCasesSearched: Case[] = [];
  private page = 1;
  private limit = 100;

  constructor(
    private caseService: CaseService,
    @Inject(MAT_DIALOG_DATA)
    public data: AddToCaseModalData,
    private localStorageService: LocalStorageService,
    private translationService: TranslationService,
    public dialogRef: MatDialogRef<AddToCaseComponent>,
    private actionService: ActionService,
    private appConfigService: AppConfigService,
    private billingService: BillingService,
    private readonly toasterService: BaseToasterService,
    private readonly router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.targets = this.data.targets;
    this.group = this.data.group;
    this.caseCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForCase'
    );
    this.isUnlimitedTheme =
      this.appConfigService.getConfigVariable('theme') === Themes.UNLIMITED;
    this.expireCaseDays =
      this.appConfigService.getConfigVariable('expireCaseDays');
    this.caseCreditsMessage = this.translationService.interpolate(
      'Management for a new case is free of charge for #{days} days',
      { days: this.expireCaseDays.toString() }
    );
    this.initForm();
    this.billingPlan = this.billingService.getBillingPlan().getValue();
    this.casesList$ = this.getCasesList().pipe(
      tap(
        (cases) =>
          (this.allCasesSearched = [...this.allCasesSearched, ...cases])
      )
    );
  }

  initForm() {
    this.addToCaseForm = new UntypedFormGroup({
      caseName: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/),
      ]),
    });
  }

  /**
   * @param  {string} value
   * @returns Case
   */
  private _filter(value: string): Observable<Case[]> {
    return this.getCases(value, this.page, this.limit).pipe(
      catchError(() => {
        return of([]);
      })
    );
  }

  private getCases(
    caseName: string,
    page: number,
    limit: number
  ): Observable<Case[]> {
    return this.caseService
      .getPaginatedCases({ limit, page, filterArg: caseName })
      .pipe(
        map((data) => {
          return data.result;
        })
      );
  }

  private getCasesList() {
    return this.addToCaseForm.controls['caseName'].valueChanges.pipe(
      startWith(''),
      debounceTime(250),
      distinctUntilChanged(),
      filter((value) => typeof value === 'string'),
      map((value) => value.toLowerCase()),
      switchMap((searchKey: string | null) =>
        this.group ? this._filter(searchKey) : of([])
      )
    );
  }

  caseSelected(e: MatAutocompleteSelectedEvent): void {
    const selectedExistingCase = <Case>e.option.value;

    if (this.checkIfAlreadySelected(selectedExistingCase.caseName)) {
      return;
    }

    this.selectedCases.push(selectedExistingCase);
    this.caseInput.nativeElement.value = '';

    this.checkRenewalCreditsCount();
  }

  caseRemoved(caseItem: Case): void {
    const index = this.selectedCases.indexOf(caseItem);
    if (index >= 0) {
      this.selectedCases.splice(index, 1);
    }
    this.checkRenewalCreditsCount();
  }

  displayFn(caseItem: Case): string {
    return caseItem && caseItem.caseName ? caseItem.caseName : '';
  }

  /**
   * This method processes all cases, new and already existing ones to the BE.
   */
  addTargetToCases() {
    this.selectedCases.forEach((selectedCase: Case) => {
      if (selectedCase.id) {
        this.addToCase(selectedCase);
        return;
      }

      const existsInSearchResults = this.checkIfAlreadyInSearchResults(
        selectedCase.caseName
      );
      if (existsInSearchResults) {
        this.addToCase(existsInSearchResults);
        return;
      }

      this.newCase(selectedCase);
    });
  }

  /**
   * Updates an already existing case with the new target.
   * @param selectedCase One of the selected cases from the dropdown.
   * @returns
   */
  addToCase(selectedCase: Case): void {
    if (this.group) {
      this.updateCaseWithSocialEntities(selectedCase);
      return;
    }

    const targetIds = this.targets.map((i) => i.cells.id.content);
    const existingTargetIds = selectedCase.assignedTargets.map((i) => i.id);
    const assignedTargets = [...new Set(targetIds.concat(existingTargetIds))];
    if (this.caseCreditsChargesEnabled && selectedCase.expired) {
      this.renewCase(selectedCase).subscribe((isRenewed) => {
        if (!isRenewed) {
          return;
        }
        this.updateCaseDetails(selectedCase, assignedTargets);
      });
    } else {
      this.updateCaseDetails(selectedCase, assignedTargets);
    }
  }

  newCase(newCase: Case): void {
    if (this.group) {
      this.createCaseAndAddSocialEntities(newCase.caseName);
      return;
    }
    const targetIds = this.targets.map((i) => i.cells.id.content);

    if (newCase.caseName && targetIds.length) {
      this.createCase(newCase.caseName, targetIds);
    }
  }

  public createCaseAndAddSocialEntities(caseName: string): void {
    let createdCase: Case;
    this.createEmptyCase(caseName)
      .pipe(
        switchMap((result: any): Observable<any> => {
          createdCase = transformSnakeToCamel(result);
          return this.addSocialEntitiesToCase(result.id);
        }),
        take(1)
      )
      .subscribe(
        (result) => {
          if (result) {
            this.openSnackbarWithButton(
              this.translationService.interpolate(
                `The group has been saved to case "#{caseName}".`,
                {
                  caseName: createdCase.caseName,
                }
              ),
              createdCase.id
            );
            this.dialogRef.close();
            this.actionService.publishAction(
              new Action({
                key: AnalysisActionsListModel.REFRESH_DATA,
                data: null,
              })
            );
          }
        },
        (error: any) => {
          this.handleCreateError(error, caseName);
        }
      );
  }

  private handleCreateError(error, caseName: string) {
    // This check is for a BE error in the case of a case already existing. If the BE response changes we need to alter this check.
    if (error?.detail === this.titleAlreadyExistsError) {
      this.openSnackbarWithButton(
        this.translationService.interpolate(
          `Case "#{caseName}" already exists. Please try again and select it from the list.`,
          {
            caseName: caseName,
          }
        )
      );
      return;
    }

    this.openSnackbarWithButton(
      error.messages ||
        this.translationService.interpolate(
          `Case "#{caseName}" has not been created.`,
          { caseName: caseName }
        )
    );
  }

  private createEmptyCase(caseName: string): Observable<any> {
    const newCase: Case = {
      caseName: caseName,
      caseColor: '#005CFF',
      caseDescription: '',
      assignedTargets: [],
      assignedUsers: [this.localStorageService.getCurrentUser().identity],
    };

    return this.caseService.createCase(newCase);
  }

  private addSocialEntitiesToCase(caseId: string): Observable<any> {
    return this.caseService.addSocialEntitiesToCase({
      case_ids: [caseId],
      social_entities: [
        {
          id: this.group.id,
          type: EntityType.Group,
          source: this.group.source,
        },
      ],
    });
  }

  private updateCaseWithSocialEntities(selectedCase: Case): void {
    this.addSocialEntitiesToCase(selectedCase.id)
      .pipe(take(1))
      .subscribe(
        (result): void => {
          if (result) {
            this.openSnackbarWithButton(
              this.translationService.interpolate(
                `The group has been saved to case "#{caseName}".`,
                {
                  caseName: selectedCase.caseName,
                }
              ),
              selectedCase.id
            );
            this.dialogRef.close();
            this.actionService.publishAction(
              new Action({
                key: AnalysisActionsListModel.REFRESH_DATA,
                data: null,
              })
            );
          }
        },
        (error: any) => {
          this.openSnackbarWithButton(
            error.messages ||
              this.translationService.interpolate(
                `Case "#{caseName}" has not been updated.`,
                { caseName: selectedCase.caseName }
              )
          );
        }
      );
  }

  createCase(caseName: string, assignedTargets: TargetItem[]): void {
    const obj: Case = {
      caseName: caseName,
      caseColor: '#005CFF',
      caseDescription: '',
      assignedTargets: assignedTargets,
      assignedUsers: [this.localStorageService.getCurrentUser().identity],
    };

    this.caseService.createCase(obj).subscribe(
      (result) => {
        if (result) {
          this.openSnackbarWithButton(
            this.translationService.translate('Case created successfully!')
          );
          this.dialogRef.close();
          this.actionService.publishAction(
            new Action({
              key: AnalysisActionsListModel.REFRESH_DATA,
              data: null,
            })
          );
        }
      },
      (error: any) => {
        this.handleCreateError(error, caseName);
      }
    );
  }

  updateCaseDetails(caseItem: Case, assignedTargets: TargetItem[]): void {
    const obj: Case = {
      ...caseItem,
      assignedTargets: assignedTargets,
    };

    this.caseService
      .addTargetsToCase(obj)
      .pipe(
        tap((result) => {
          if (result) {
            this.openSnackbarWithButton(
              this.translationService.translate('Case updated successfully!')
            );
            this.dialogRef.close();
            this.actionService.publishAction(
              new Action({
                key: AnalysisActionsListModel.REFRESH_DATA,
                data: null,
              })
            );
          }
        }),
        catchError((error: any) => {
          this.openSnackbarWithButton(
            this.translationService.translate(
              error.messages ? error.messages : 'Case has not been edited'
            )
          );
          return of(error);
        })
      )
      .subscribe();
  }

  checkRenewalCreditsCount(): void {
    const expiredTargetsCount = this.selectedCases.filter(
      (i) => i.expired
    ).length;
    this.creditsForExpired =
      expiredTargetsCount *
      this.billingPlan[BillingActions.CASE_MANAGEMENT].cost;
  }

  /**
   * This runs on enter of the input so it adds the input value as a new case if it is valid and not already in the list.
   * @param input
   */
  addChip(input: MatChipInputEvent): void {
    const inputCase = (input.value || '').trim();

    if (
      this.addToCaseForm.valid &&
      inputCase &&
      !this.checkIfAlreadySelected(inputCase)
    ) {
      this.selectedCases.push({
        caseName: inputCase,
        caseColor: '',
        caseDescription: '',
        assignedTargets: [],
        assignedUsers: [],
      });
    }

    this.caseInput.nativeElement.value = '';
  }

  checkIfAlreadySelected(caseName: string) {
    return this.selectedCases.some(
      (selectedCase) => selectedCase.caseName === caseName
    );
  }

  checkIfAlreadyInSearchResults(caseName: string) {
    return this.allCasesSearched.find((c) => c.caseName === caseName);
  }

  renewCase(selectedCase: Case): Observable<boolean> {
    return new Observable<boolean>((observable) => {
      const subscription: Subscription = this.caseService
        .renewCase(selectedCase.id)
        .subscribe(
          () => {
            this.openSnackbarWithButton(
              this.translationService.translate('Case renewed successfully!')
            );
            selectedCase.expired = false;
            observable.next(true);
          },
          (error) => {
            this.openSnackbarWithButton(
              this.translationService.translate(
                error.messages ? error.messages : 'Case has not been renewed'
              )
            );
            observable.next(false);
          },
          () => {
            observable.complete();
          }
        );
      this.subscriptions.push(subscription);
    });
  }

  private openSnackbarWithButton(message: string, targetCaseId?: string): void {
    this.toasterService.show(
      {
        title: message,
        actionTitle: targetCaseId
          ? this.translationService.translate('Click view to go to the case.')
          : '',
        actions: targetCaseId
          ? [
              {
                label: this.translationService.translate('View'),
                callback: () =>
                  this.router.navigateByUrl(
                    `/case/${targetCaseId}/${CaseDashboardSectionsRoutes.GROUP_ANALYSER}`
                  ),
              },
            ]
          : [],
      },
      {
        timeOut: 5000,
      }
    );
  }
}
