import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreToastrHeaderComponent } from './core-toastr-header/core-toastr-header.component';
import { CoreToastrBodyComponent } from './core-toastr-body/core-toastr-body.component';
import { CoreToastrComponent } from './core-toastr/core-toastr.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    CoreToastrHeaderComponent,
    CoreToastrBodyComponent,
    CoreToastrComponent,
  ],
  imports: [CommonModule, MatIconModule],
  exports: [
    CoreToastrHeaderComponent,
    CoreToastrBodyComponent,
    CoreToastrComponent,
  ],
})
export class CoreToastrModule {}
