import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DataSource } from '@trg-commons/data-models-ts';
import { Profile } from 'datalayer/models/platform-models';
import { flattenDeep, head, uniqBy } from 'lodash-es';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { BaseService } from 'src/app/services/base.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Platforms } from 'src/app/shared/models/radical-monitoring-options.model';
import { TargetLink } from 'src/app/shared/models/social-media.model';
import {
  TargetItem,
  TargetItemDTO,
  TargetItemMapper,
} from 'src/app/shared/models/target-item.model';
import {
  addPrefixUrl,
  isNumber,
  removeIllegalSignsFromUrl,
  removePrefixUrl,
  transformSnakeToCamel,
} from 'src/app/shared/util/helper';
import { SocialProfileImageDialogComponent } from '../components/social-profile-image-dialog/social-profile-image-dialog.component';
import { BreachData } from '../shared/models/breach-data.model';

@Injectable({
  providedIn: 'root',
})
export class ProfilerService extends BaseService {
  targetId = new BehaviorSubject<string>('');
  aboutIsActive: BehaviorSubject<{ editMode: boolean; isActive: boolean }> =
    new BehaviorSubject<{
      editMode: boolean;
      isActive: boolean;
    }>({ editMode: false, isActive: false });
  relatedPeopleIsActive: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  mediaIsActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  employmentIsActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  targetIntelData: Subject<any> = new Subject<any>();
  targetData: BehaviorSubject<TargetItem | null> =
    new BehaviorSubject<TargetItem | null>(null);
  maximizedMap = new BehaviorSubject<boolean>(false);
  selectedOverviewHeaderSection = new Subject<string>();
  groupIsActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  membersIsActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  checkInToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshTargetOnEditingForm: Subject<boolean> = new Subject<boolean>();
  refreshAboutAlias: Subject<Partial<TargetItem>> = new Subject<
    Partial<TargetItem>
  >();
  targetCreditsChargesEnabled = false;
  recoveryIsActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  mediaPhotoCount: BehaviorSubject<
    Partial<{ [k in DataSource | 'total']: { count: number; name: string } }>
  > = new BehaviorSubject({});
  hideTargetItemSearch = new Subject<boolean>();

  constructor(
    private httpClient: HttpClient,
    protected override router: Router,
    protected override snackBar: MatSnackBar,
    private translationService: TranslationService,
    private appConfigService: AppConfigService,
    public dialog: MatDialog
  ) {
    super(router, snackBar);
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
  }

  getTargetData(targetId: string): Observable<TargetItem | undefined> {
    if (!targetId) {
      return of();
    }

    this.targetData.next(null);

    return this.httpClient
      .get<{ result: TargetItemDTO }>(`${this.url}/targets/${targetId}`)
      .pipe(
        map(({ result }) => TargetItemMapper.map(result)),
        map((target: TargetItem) => {
          if (this.isTargetExpired(target)) {
            this.showMessage(
              this.translationService.translate('Target is Expired')
            );
            this.router.navigate(['/discovery']);
            return;
          } else {
            this.targetData.next(target);
            return target;
          }
        }),
        catchError((error) => this.handleError(error))
      );
  }

  checkIfTargetHasCdr(msisdns: string[]): Observable<{ hasCdrs: boolean }> {
    return this.httpClient
      .get<any>(`${this.proxyApiUrl}/call-log-request/check-cdrs/${msisdns}`)
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getLiveSessionStatus(targetId: string) {
    return this.httpClient
      .get<any>(`${this.url}/live-session/${targetId}`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  getLiveSession(msisdn: string, username: string, targetId: string) {
    return this.httpClient
      .post<any>(`${this.url}/live-session/${targetId}`, { msisdn, username })
      .pipe(catchError((error) => this.handleError(error)));
  }

  restoreDashboardSections() {
    this.aboutIsActive.next({ editMode: false, isActive: false });
    this.employmentIsActive.next(false);
    this.mediaIsActive.next(false);
    this.relatedPeopleIsActive.next(false);
    this.maximizedMap.next(false);
    this.recoveryIsActive.next(false);
  }

  isTargetExpired(target: TargetItem): boolean {
    return !!(
      target &&
      target.expired &&
      window.location.pathname.includes('/targets/') &&
      this.targetCreditsChargesEnabled
    );
  }

  openSocialProfileDialog(profile: Profile): void {
    this.dialog.open(SocialProfileImageDialogComponent, {
      data: profile,
      panelClass: 'social-profile-image-dialog',
    });
  }

  public getUniqueUrls(socialUrls: TargetLink[]): TargetLink[] {
    const platforms = Object.values(Platforms);
    const splitedLinks = platforms.reduce(
      (acc: Record<string, TargetLink[]>, platform: string) => {
        if (platform === Platforms.FACEBOOK) {
          acc[Platforms.FACEBOOK] = socialUrls
            .filter((socialUrl) => socialUrl.platform === platform)
            .map((targetLink) => ({
              ...targetLink,
              link: removePrefixUrl(targetLink.link),
            }));

          const newSocialLinks: TargetLink[] = [];
          for (const targetLink of this.sortSocialLinksByUsername(
            acc[Platforms.FACEBOOK]
          )) {
            const userId = targetLink.userId.split('@')[0];
            const userIdNotExist =
              newSocialLinks.findIndex(
                (innerTargetLink) =>
                  innerTargetLink.userId === targetLink.userId
              ) === -1;
            const linkNotExist =
              newSocialLinks.findIndex(
                (innerTargetLink) => innerTargetLink.link === targetLink.link
              ) === -1;

            if (
              isNumber(userId) &&
              !targetLink.link.includes(userId) &&
              userIdNotExist
            ) {
              newSocialLinks.push(targetLink);
            }

            if (
              isNumber(userId) &&
              targetLink.link.includes(userId) &&
              userIdNotExist
            ) {
              newSocialLinks.push(targetLink);
            }

            if (
              !isNumber(userId) &&
              targetLink.link.includes(userId) &&
              linkNotExist
            ) {
              newSocialLinks.push(targetLink);
            }

            if (!newSocialLinks.length && userIdNotExist) {
              newSocialLinks.push(targetLink);
            }
          }

          const numberUserIds = newSocialLinks.filter((innerLink) =>
            isNumber(head(innerLink.userId?.split('@')))
          );
          const stringUserIds = newSocialLinks.filter(
            (innerLink) => !isNumber(head(innerLink.userId?.split('@')))
          );

          stringUserIds.forEach((stringUserIdLinks) => {
            if (
              numberUserIds.findIndex(
                (numberUserIds) => numberUserIds.link === stringUserIdLinks.link
              ) === -1
            ) {
              numberUserIds.push(stringUserIdLinks);
            }
          });
          acc[Platforms.FACEBOOK] = numberUserIds.map((targetLink) => ({
            ...targetLink,
            link: addPrefixUrl(targetLink.link),
          }));
        } else {
          acc[platform] = uniqBy(
            socialUrls.filter((socialUrl) => socialUrl.platform === platform),
            (url) => url.userId
          );
        }
        return acc;
      },
      {}
    );

    return flattenDeep(Object.values(splitedLinks)).map(
      (targetLink: TargetLink) => ({
        ...targetLink,
        link: removeIllegalSignsFromUrl(targetLink.link),
      })
    );
  }

  public getBreachData(email: string): Observable<BreachData[]> {
    return this.httpClient
      .get<BreachData[]>(`${this.fastAPIurl}/entities/breach/${email}`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private sortSocialLinksByUsername(socialLinks: TargetLink[]): TargetLink[] {
    return [
      ...socialLinks.filter(
        (socialUrl) =>
          !isNumber(
            head(
              socialUrl.link
                .split(this.getSplitArgument(socialUrl.link))
                .reverse()
            )
          )
      ),
      ...socialLinks.filter((socialUrl) =>
        isNumber(
          head(
            socialUrl.link
              .split(this.getSplitArgument(socialUrl.link))
              .reverse()
          )
        )
      ),
    ];
  }

  private getSplitArgument(url: string): string {
    return url.includes('/people') ? '.com/people/_/' : '.com/';
  }
}
