import { Component, OnInit } from '@angular/core';
import { CreditDetailsService } from './services/credit-details.service';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import {
  CreditDetails,
  DetailsType,
  InitDetailsResult,
} from './models/credit-details.model';
import { InitDetailsService } from './services/init-details.service';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'app-credit-details',
  templateUrl: './credit-details.component.html',
  styleUrls: ['./credit-details.component.scss'],
})
export class CreditDetailsComponent implements OnInit {
  private creditDetails$: Observable<CreditDetails>;
  public initDetails$: Observable<boolean>;

  constructor(
    private creditDetailsService: CreditDetailsService,
    private initDetailsService: InitDetailsService
  ) {}

  ngOnInit(): void {
    this.creditDetails$ = this.creditDetailsService.getCreditDetails();

    const userCreditDetails$: Observable<InitDetailsResult> =
      this.creditDetails$.pipe(
        filter(
          (creditDetails: CreditDetails) =>
            DetailsType.USER === creditDetails?.detailsType
        ),
        map((creditDetails: CreditDetails) =>
          this.initDetailsService.buildUserInfoByBalanceType(creditDetails)
        ),
        tap((initDetailsResult: InitDetailsResult) =>
          this.setDetails(initDetailsResult)
        )
      );

    const tenantCreditDetails$ = this.creditDetails$.pipe(
      filter(
        (creditDetails: CreditDetails) =>
          DetailsType.TENANT === creditDetails?.detailsType
      ),
      map((creditDetails: CreditDetails) =>
        this.initDetailsService.buildTenantInfoByBalanceType(creditDetails)
      ),
      tap((initDetailsResult: InitDetailsResult) =>
        this.setDetails(initDetailsResult)
      )
    );

    this.initDetails$ = merge(userCreditDetails$, tenantCreditDetails$).pipe(
      distinctUntilChanged(isEqual),
      map((detailsInfo: InitDetailsResult) => !!detailsInfo)
    );
  }

  private setDetails({ bulletsInfo, tooltipInfo, barInfo }: InitDetailsResult) {
    this.creditDetailsService.setCreditBarTooltip(tooltipInfo);
    this.creditDetailsService.setCreditUsageBullets(bulletsInfo);
    this.creditDetailsService.setCreditUsageBar(barInfo);
  }
}
