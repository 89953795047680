export enum ActiveModule {
  ABOUT = 'about',
}

export enum TypeOfDob {
  ONLY_YEAR = 'only-year',
  NO_YEAR = 'no-year',
}

export interface FormatDateOfBirth {
  dateOfBirth: string;
  typeOfBirthday?: TypeOfDob;
}
