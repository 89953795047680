import { Component } from '@angular/core';
import { CreditDetailsService } from '../../services/credit-details.service';

@Component({
  selector: 'app-credit-usage-bullets',
  templateUrl: './credit-usage-bullets.component.html',
  styleUrls: ['./credit-usage-bullets.component.scss'],
})
export class CreditUsageBulletsComponent {
  constructor(public creditDetailsService: CreditDetailsService) {}
}

export interface CreditUsageBullet {
  title: string;
  value: number;
  color: BulletColor;
}

export enum BulletColor {
  BLUE = '#396aff',
  GREEN = '#0fd400',
  GRAY = '#dfdfdf',
}
