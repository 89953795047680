import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { LineChartData } from '@shared/types/line-chart-data.type';

@Component({
  selector: 'app-line-chart',
  templateUrl: './activity-line-chart.component.html',
  styleUrls: ['./activity-line-chart.component.scss'],
})
export class ActivityLineChartComponent implements OnInit, OnChanges {
  @Input() public lineChartData: LineChartData[] = [];
  @Input() public lineChartWidth: number;
  @Input() public maxCount: number;

  public gradientColors: string[][];

  ngOnInit() {
    this.calculateGradientColors();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lineChartData || changes.maxCount) {
      this.calculateGradientColors();
    }
  }

  private calculateGradientColors() {
    if (!this.lineChartData || !this.maxCount) {
      return;
    }

    this.gradientColors = this.lineChartData.map((data, index) => {
      const startColor = this.getGradientColor(index, true);
      const endColor = this.getGradientColor(index, false);
      return [startColor, endColor];
    });
  }

  private getGradientColor(index: number, isStart: boolean): string {
    const dataCount = this.lineChartData[index].count;
    const nextDataCount =
      index < this.lineChartData.length - 1
        ? this.lineChartData[index + 1].count
        : dataCount;
    const prevDataCount =
      index > 0 ? this.lineChartData[index - 1].count : dataCount;

    const count = isStart
      ? (dataCount + prevDataCount) / 2
      : (dataCount + nextDataCount) / 2;
    return this.getStrokeColor(count, this.maxCount);
  }

  private getStrokeColor(count: number, maxCount: number): string {
    const startColor = { r: 236, g: 236, b: 236 };
    const endColor = { r: 0, g: 141, b: 246 };
    const ratio = count / maxCount;

    const r = this.interpolateColorComponent(startColor.r, endColor.r, ratio);
    const g = this.interpolateColorComponent(startColor.g, endColor.g, ratio);
    const b = this.interpolateColorComponent(startColor.b, endColor.b, ratio);

    return `rgb(${r}, ${g}, ${b})`;
  }

  private interpolateColorComponent(
    startComponent: number,
    endComponent: number,
    ratio: number
  ): number {
    return Math.round(startComponent + ratio * (endComponent - startComponent));
  }

  trackByIndex(index: number): number {
    return index;
  }
}
