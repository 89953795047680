import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { PhotoCarouselModalComponent } from '../photo-carousel-modal/photo-carousel-modal.component';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'trg-ui-kyc-modal',
  templateUrl: './kyc-modal.component.html',
  styleUrls: ['./kyc-modal.component.scss'],
})
export class KycModalComponent implements OnInit {
  index = 0;
  speed = 1000;
  infinite = true;
  direction: 'left' | 'right' = 'right';
  autoplay = false;
  photos = [];
  imgWidth: number;
  constructor(
    public dialogRef: MatDialogRef<PhotoCarouselModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      photos: { image: string }[];
      index: number;
      name: string;
      userId: string;
      issuedId: string;
      idType: string;
      idCountry: string;
      birthday: string;
      address: string;
    }
  ) {}

  ngOnInit() {
    this.photos = this.data.photos;
    if (this.data) {
      this.setIndex();
    }
  }

  moveSlider(i) {
    this.imgDimensions(this.photos[i].image);
  }

  imgDimensions(imgSrc) {
    const img = new Image();
    img.src = imgSrc;

    fromEvent(img, 'load')
      .pipe(take(1))
      .subscribe(() => {
        this.imgWidth = img.width;
      });
  }

  private setIndex(): void {
    this.index = this.photos.findIndex((img) =>
      typeof this.data.index === 'number'
        ? img.image === this.photos[this.data.index].image
        : img.image === this.data.index
    );
  }

  onClose() {
    this.dialogRef.close();
  }
}
