import { Entity, Gender } from '../platform-models';

export class AvatarPerson extends Entity {
  name: string;
  lastName: string;
  gender?: Gender;
  countryCode?: string;
  image?: string;
  featureImages?: string[];
  birthDate?: Date;
  cityName?: string;
  fullName?: string;

  constructor(model: Partial<AvatarPerson>) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }
}
