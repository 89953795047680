import { Injectable } from '@angular/core';
import { invert } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from 'src/app/services/translation/dictionary.model';
import { es as SpanishTranslations } from 'src/app/services/translation/translations/es';
import { fr as FrenchTranslations } from 'src/app/services/translation/translations/fr';
import { id as IndonesianTranslations } from 'src/app/services/translation/translations/id';
import { LanguageOptions } from 'src/app/shared/models/site-settings.model';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public language: LanguageOptions = LanguageOptions.EN;
  public languageChange: BehaviorSubject<string> = new BehaviorSubject(
    LanguageOptions.EN
  );

  private dictionary: { [key: string]: Dictionary } = Object.assign(
    {},
    {
      es: { languange: 'es', values: SpanishTranslations },
      fr: { languange: 'fr', values: FrenchTranslations },
      id: { languange: 'id', values: IndonesianTranslations },
    }
  );

  /**
   *
   * @param key string
   * @param reverse boolean
   * @param wrapMissing
   */
  translate(key: string, reverse = false, wrapMissing = true): string {
    if (this.language === 'en' || !this.dictionary[this.language]) {
      return key;
    }

    const dictionary = reverse
      ? invert(this.dictionary[this.language].values as any)
      : this.dictionary[this.language].values;

    if (dictionary[key] === '') {
      return key;
    }
    return dictionary[key] || (!wrapMissing ? key : `[${key}]`);
  }

  /**
   *  Translate and interpolate given string with custom data.
   *  example: interpolate('my interpolated string with custom ${variable}', {variable: '__interpolated variable__'}) =>
   *   => 'my interpolated string with custom __interpolated variable__'
   * @param key string
   * @param data object
   */
  interpolate(key: string, data: { [key: string]: string }) {
    const translation = this.translate(key);

    return translation.replace(
      /#{(.*?)}/g,
      (match: string, clearString: string) => {
        return data[clearString] || match;
      }
    );
  }

  /**
   * Translates a key to its corresponding text in the current language, choosing between singular or plural forms based on a count.
   * This method is useful for handling dynamic content that may change in quantity and requires appropriate linguistic agreement in terms of number.
   *
   * @param {string} keySingular The translation key for the singular form.
   * @param {string} keyPlural The translation key for the plural form.
   * @param {number} count The number that determines whether the singular or plural form should be used.
   * @returns {string} The translated text in the current language, correctly applying singular or plural forms based on the provided count.
   *
   * @example
   * // {
   * //   "apple": "apple",
   * //   "apples": "apples"
   * // }
   * // If current language is set to English.
   * translateWithPlural('apple', 'apples', 1); // Returns "apple"
   * translateWithPlural('apple', 'apples', 2); // Returns "apples"
   */
  translateWithPlural(
    keySingular: string,
    keyPlural: string,
    count: number
  ): string {
    const key = count === 1 ? keySingular : keyPlural;
    return this.translate(key);
  }
}
