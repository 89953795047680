import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { FeatureNotificationsStore } from '@shared/components/feature-group-notifications/feature-notifications.store';
import { Platform } from '@shared/schemas/common/platforms';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { FeatureNotificationResponse } from 'datalayer/models/background-jobs/feature-notification';
import { first, isEqual } from 'lodash-es';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { distinctUntilChanged, filter, mergeMap } from 'rxjs';
import { SomedusRequestObj } from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';
import { FeaturesService } from 'src/app/services/features/features.service';
import { Action } from 'src/app/shared/classes/action.class';
import { BaseDialogComponent } from 'src/app/shared/classes/base-dialog.component';
import { UploadFileDialogStore } from './upload-file-dialog.store';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss'],
})
export class UploadFileDialogComponent
  extends BaseDialogComponent
  implements OnInit
{
  selectedFileInfo: File;
  platform: Platform = Platform.TELEGRAM;
  status: 'EMPTY' | 'SELECTED_FILE' | 'UPLOADING' = 'EMPTY';
  loadingState: {
    estimatedTimeUpload: string;
    isLoading: boolean;
    file: File;
  } = null;
  estimatedTimeUpload = '';
  constructor(
    private featuresService: FeaturesService,
    private featureNotificationsStore: FeatureNotificationsStore,
    private uploadFileDialogStore: UploadFileDialogStore,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<UploadFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      targetId: string;
    },
    router: Router
  ) {
    super(dialogRef, router);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.featuresService
        .getSomedusStatus(this.data.targetId)
        .pipe(
          mergeMap((features) => features),
          filter(
            (feature: SomedusRequestObj) =>
              JobStatus.UPLOADING === feature.status
          ),
          distinctUntilChanged(isEqual)
        )
        .subscribe((feature: SomedusRequestObj) => {
          if (
            !this.uploadFileDialogStore.loaderAlreadyStarted(this.data.targetId)
          ) {
            this.uploadFileDialogStore.addNewLoader(
              this.data.targetId,
              '',
              null
            );
          }
          this.loadingState =
            this.uploadFileDialogStore.getTargetSpecificLoaderStateValue(
              this.data.targetId
            );
          this.estimatedTimeUpload = this.loadingState.estimatedTimeUpload;
          this.selectedFileInfo = this.loadingState.file;
          this.status = 'UPLOADING';
          this.cdr.markForCheck();
        })
    );

    this.subscriptions.push(
      this.featureNotificationsStore
        .listenTargetSpecificNotification(this.data.targetId, [
          JobStatus.DONE,
          JobStatus.ABANDONED,
        ])
        .pipe(
          filter(
            (notifications: FeatureNotificationResponse[]) =>
              !!notifications.length
          )
        )
        .subscribe(() => {
          this.uploadFileDialogStore.removeLoaderStateByTargetId(
            this.data.targetId
          );
          this.removeFile();
        })
    );
  }

  public onDropped(files: NgxFileDropEntry[]): void {
    this.status = 'SELECTED_FILE';
    const fileEntry = first(files).fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => (this.selectedFileInfo = file));
    this.estimatedTimeUpload = this.uploadFileDialogStore.calculateUploadTime(
      this.selectedFileInfo.size
    );
  }

  public uploadFile(): void {
    this.status = 'UPLOADING';
    const action: Action = new Action({ key: 'success' });
    action.data = {
      platform: this.platform,
      file: this.selectedFileInfo,
    };

    if (!this.uploadFileDialogStore.loaderAlreadyStarted(this.data.targetId)) {
      this.uploadFileDialogStore.addNewLoader(
        this.data.targetId,
        this.estimatedTimeUpload,
        this.selectedFileInfo
      );
      this.loadingState =
        this.uploadFileDialogStore.getTargetSpecificLoaderStateValue(
          this.data.targetId
        );
      this.cdr.markForCheck();
    }

    this.featuresService
      .uploadFileForSomedus(
        this.selectedFileInfo,
        this.data.targetId,
        this.platform
      )
      .subscribe();
  }

  public removeFile(): void {
    this.status = 'EMPTY';
    this.selectedFileInfo = undefined;
    this.estimatedTimeUpload = '';
  }
}
