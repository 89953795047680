<mat-form-field
  #languageSelector
  class="language-selector"
  appearance="outline"
>
  <mat-select
    class="select-language"
    [value]="selectedLanguage"
    (selectionChange)="emitLanguageChange($event)"
    [ngClass]="selectedLanguage"
  >
    <mat-option
      *ngFor="let dropdownLanguage of languages"
      [value]="dropdownLanguage.value"
    >
      <img
        with="18"
        class="image"
        height="18"
        [src]="dropdownLanguage?.image"
      />
      <span>{{ dropdownLanguage.display }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
