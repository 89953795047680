<app-leaflet-map
  *ngIf="requestedMap === mapType.Leaflet"
  [center]="center"
  [zoom]="zoom"
  [markers]="markers"
  [circles]="circles"
  [polygon]="polygon"
  [trafficEnabled]="trafficEnabled"
  [heatmapEnabled]="heatmapEnabled"
  [historyTimelineEnabled]="historyTimelineEnabled"
  [clusteringEnabled]="clusteringEnabled"
  [enableDrawing]="enableDrawing"
  [enableSearching]="enableSearching"
  [freeze]="freeze"
  [buttons]="buttons"
  [feature]="feature"
>
</app-leaflet-map>

<app-vanilla-google-map
  #googleMap
  *ngIf="requestedMap === mapType.VanillaGoogle"
  [mapOptions]="mapOptions"
  [center]="center"
  [zoom]="zoom"
  [points]="points"
  [loading]="loading"
  [height]="height"
  [showExpandBtn]="showExpandBtn"
  [maximized]="maximized"
  [showLoaderOnInit]="showLoaderOnInit"
  [rectangles]="rectangles"
  [polyLines]="polyLines"
  [showMarkerInfoWindow]="showMarkerInfoWindow"
  (circleDrawn)="onCircleDrawn($event)"
  [heatMaps]="heatMaps"
  [legendIcons]="legendIcons"
  [markers]="markers"
  [circles]="circles"
  [polygons]="polygons"
  [trafficEnabled]="trafficEnabled"
  [nearbyLocationsEnabled]="nearbyLocationsEnabled"
  [heatmapEnabled]="heatmapEnabled"
  [historyTimelineEnabled]="historyTimelineEnabled"
  [enableClustering]="enableClustering"
  [enableDrawing]="enableDrawing"
  [enableSearching]="enableSearching"
  [freeze]="freeze"
  [buttons]="buttons"
  [feature]="feature"
  [callLogClusteringData]="callLogClusteringData"
  [mapTypeId]="mapTypeId"
  [maxZoomClusterDistance]="maxZoomClusterDistance"
  [styledMapTypeCustomOptions]="styledMapTypeCustomOptions"
  (areaOfInterestDrawn)="onAreaOfInterestDrawn($event)"
  (rectangleDrawn)="onRectangleDrawn($event)"
  (userLocation)="onUserLocation($event)"
  (removeDrawingMode)="onStopDrawing($event)"
  (renderingCompleted)="onRenderingCompleted($event)"
  (markerDrawn)="onMarkerDrawn($event)"
  (clusterClicked)="onClusterClicked($event)"
  (markerClicked)="onMarkerClicked($event)"
  (stopDrawing)="onStopDrawing($event)"
  (zoomChanged)="zoomChanged.emit($event)"
  (markersAdded)="markersAdded.emit()"
  (streetViewMode)="streetViewMode.emit($event)"
  (tilesLoadedChanges)="tilesLoadedChanges.emit()"
  (visibleMarkers)="visibleMarkers.emit($event)"
  [mapFitBounds]="mapFitBounds"
  [clustererStyles]="clustererStyles"
>
</app-vanilla-google-map>
