<app-dialog>
  <div header>
    <span class="title">
      {{ data.title | translate }}
    </span>
  </div>

  <div body>
    <p class="body-description">{{ data.bodyDescription | translate }}.</p>

    <section>
      <h4 class="section-title">{{ 'Choose Duration' | translate }}</h4>
      <mat-radio-group
        class="section-group"
        [(ngModel)]="selectedDuration"
        (ngModelChange)="onDurationChange()"
      >
        <mat-radio-button
          *ngFor="let durationOption of durationOptions"
          [value]="durationOption.value"
          >{{ durationOption.displayValue }}</mat-radio-button
        >
      </mat-radio-group>
    </section>

    <section>
      <h4 class="section-title">{{ 'Choose Frequency' | translate }}</h4>
      <mat-radio-group class="section-group" [(ngModel)]="selectedFrequency">
        <mat-radio-button
          *ngFor="let frequencyOption of filteredFrequencyOptions"
          [value]="frequencyOption.value"
          >{{ frequencyOption.displayValue | translate }}</mat-radio-button
        >
      </mat-radio-group>
    </section>
  </div>

  <div footer>
    <div class="btn-wrp">
      <button
        mat-button
        class="cp-btn-primary cp-search-btn active"
        [disabled]="!selectedDuration || !selectedFrequency"
      >
        <span (click)="onSuccess()">
          <span class="btn-txt">{{ 'Start Scheduler' | translate }}</span>
        </span>
      </button>
    </div>
  </div>
</app-dialog>
