<app-core-toastr>
  <app-core-toastr-header (close)="removeNotification()">
    <div class="target-toastr__title font-medium flex-wrp">
      <span>{{ 'Purchase call log' | translate }}</span>
    </div>
  </app-core-toastr-header>

  <app-core-toastr-body>
    <div class="target-toastr__toasts">
      <ng-container>
        <div
          class="target-toastr__toast-item flex-wrp"
          *ngFor="let toast of toasts$ | async; let i = index"
        >
          <ng-container *ngIf="i === 0 && !inProgress">
            <div class="target-toastr__source">
              {{ 'Importing data' | translate }}
            </div>
            <div class="target-toastr__progress">
              <mat-progress-bar
                mode="indeterminate"
                color="accent"
              ></mat-progress-bar>
            </div>
          </ng-container>
          <ng-container *ngIf="i === 0 && inProgress">
            <div class="target-toastr__osint-done flex-vert-center">
              <mat-icon class="target-toastr__done-icon">check_circle</mat-icon>
              {{ 'Completed successfully' | translate }}
              <a
                class="target-toastr__overview-link cursor-pointer"
                *ngIf="toast?.content?.msisdn"
                (click)="navigateToClOverview(toast?.content?.callLogRequestId)"
                [matTooltip]="'View call log analysis' | translate"
                >{{ 'View call log analysis' | translate }}</a
              >
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </app-core-toastr-body>
</app-core-toastr>
