<span class="doughnut-title" *ngIf="chartTitle">{{
  chartTitle | translate
}}</span>

<div class="doughnut-content">
  <canvas
    baseChart
    [data]="doughnutChartData"
    [options]="chartOptions"
    (chartHover)="chartHovered($event)"
    (chartClick)="chartClicked($event)"
    [type]="doughnutChartType"
  ></canvas>
</div>
