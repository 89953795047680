export enum AvatarActivityLogState {
  reserve = 'Reserved',
  release = 'release',
  openBrowser = 'Opening browser',
  preRegWarmUp = 'Pre-registering',
  initialPhotosFill = 'Filling photos',
  initialDetailsFill = 'Filling personal info',
  register = 'Registering FB acc.',
  registerInitialFill = 'Registering FB acc.',
  registerGetOtpCode = 'Getting OTP',
  registerFillOtp = 'Filling OTP',
  login = 'Logging in',
  scrapPhotos = 'Scrapping photos',
  test = 'test',
  configureAccount = 'Configuring acc.',
  proxyCollectCaptchaStat = 'proxyCollectCaptchaStat',
  saveForLater = 'Configuring acc.',
  // fb
  likePosts = 'Liking posts',
  inviteFriends = 'Accepting requests',
  joinGroups = 'Joining groups',
  followPages = 'Following pages',
  getLoggedInCookies = 'Getting cookies',
  acceptFriends = 'Accept friends',
  // gmail
  gmailinitSetRecoveryEmail = 'Configuring Gmail',
  getRecoveryEmailOtp = 'Configuring Gmail',
  fillRecoveryEmailOtp = 'Configuring Gmail',
}
