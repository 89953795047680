<div class="bar-container" *ngIf="creditUsage$ | async as creditUsage">
  <p class="bar-label">
    {{ creditUsage.title | translate }}:
    <b>{{ creditUsage.total | number: '1.1' }} {{ 'credits' | translate }}</b>
  </p>

  <div
    #bar
    class="bar"
    (mouseover)="onMouseOver($event)"
    (mouseout)="onMouseLeave($event)"
  >
    <div class="first-val" [ngStyle]="{ width: creditUsage.startValW }"></div>
    <div class="second-val" [ngStyle]="{ width: creditUsage.endValW }"></div>
  </div>
</div>

<ng-container *ngIf="showTooltip$ | async">
  <app-credit-bar-tooltip></app-credit-bar-tooltip>
</ng-container>
