import { ApplicationRef, EmbeddedViewRef, TemplateRef } from '@angular/core';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { MapConstants } from '../../models/map-constants';
import { AnchorPosition } from '../../models/map.models';

/*
 Hack to fix a 'google' is not defined error.
 Read more :
 https://stackoverflow.com/questions/40104288/angular2-how-to-setup-a-google-maps-overlayview-translate-js-prototype-into?noredirect=1&lq=1
  */
export class OverlayFactory {
  constructor(
    private translationService: TranslationService,
    private appRef: ApplicationRef
  ) {}

  HtmlMarker = class extends google.maps.OverlayView {
    position: google.maps.LatLng;
    html: string;
    el: any;
    infoWindow: google.maps.InfoWindow;
    map: google.maps.Map;
    template: TemplateRef<any>;
    private clickListener: MouseEvent;

    constructor(
      private appRef: ApplicationRef,
      private options: {
        position: google.maps.LatLng;
        template: TemplateRef<any> | any;
        getPopupEmbeddedView: () => EmbeddedViewRef<unknown>;
        isPopupWindowOpen: boolean;
        anchorPosition: AnchorPosition;
      }
    ) {
      super();
      this.template = options.template;
      this.position = options.position;
    }

    createMarkerElementAndAppendToDom() {
      this.el = document.createElement('div');
      this.getPanes().overlayMouseTarget.appendChild(this.el);
      this.el.className = 'marker';
      this.el.style.position = 'absolute';
      this.el.style.cursor = 'pointer';
      this.el.style.zIndex = MapConstants.OVERLAYS_ZINDEX;
      this.el.appendChild(this.template);
    }

    draw() {
      const point = this.getProjection().fromLatLngToDivPixel(this.position);
      if (point) {
        const [x, y] = this.returnAnchorPosition(this.options.anchorPosition);
        this.el.style.left = `${point.x - x}px`;
        this.el.style.top = `${point.y - y}px`;
      }
    }

    getPosition(): google.maps.LatLng {
      return this.position;
    }

    remove() {
      if (this.el) {
        this.el.removeEventListener('click', this.clickListener);
        this.el.parentNode.removeChild(this.el);
        if (this.el.infoWindow) {
          this.el.infoWindow.close();
        }
        this.el = null;
      }
    }

    onAdd(): void {
      this.createMarkerElementAndAppendToDom();
      if (this.options.getPopupEmbeddedView) {
        this.addInfoWindow();
      }

      this.clickListener = this.el.addEventListener('click', () => {
        this.el.initInfoWindow();
        this.el.infoWindow.open(this.map);
      });
    }

    private addInfoWindow() {
      this.el.initInfoWindow = () => {
        if (!this.el.infoWindow) {
          const [, y] = this.returnAnchorPosition(this.options.anchorPosition);
          this.el.infoWindow = new google.maps.InfoWindow({
            content: '',
            position: this.position,
            pixelOffset: new google.maps.Size(0, -y),
          });
          const embeddedViewRef = this.options.getPopupEmbeddedView();
          embeddedViewRef.markForCheck();
          this.el.infoWindow.setContent(embeddedViewRef.rootNodes[0]);
          this.appRef.attachView(embeddedViewRef);
        }
      };
      if (this.options.isPopupWindowOpen) {
        this.el.initInfoWindow();
        this.el.infoWindow.open(this.map);
      }
    }

    setMap(map: google.maps.Map) {
      this.map = map;
      super.setMap(map);
    }

    returnAnchorPosition(anchorPosition: AnchorPosition): [number, number] {
      switch (anchorPosition) {
        case AnchorPosition.MIDDLE: {
          return [this.el.offsetWidth / 2, this.el.offsetHeight / 2];
        }
        default: {
          return [this.el.offsetWidth / 2, this.el.offsetHeight];
        }
      }
    }
  };

  RadiusWidget = class extends google.maps.MVCObject {
    sizer: google.maps.Marker;
    infoWindow: google.maps.InfoWindow;

    constructor(radius: number) {
      super();

      const circle = new google.maps.Circle({
        radius: radius,
        strokeWeight: 2,
        strokeColor: '#545453',
        fillColor: 'transparent',
      });

      // Set the distance property value, default to 50km.
      this.set('distance', radius / 1000);
      this.set('radius', this.get('distance') * 1000);

      // Bind the RadiusWidget bounds property to the circle bounds property.
      this.bindTo('bounds', circle);

      // Bind the circle center to the RadiusWidget center property
      circle.bindTo('center', this);

      // Bind the circle map to the RadiusWidget map
      circle.bindTo('map', this);

      // Bind the circle radius property to the RadiusWidget radius property
      circle.bindTo('radius', this);

      this.sizer = new google.maps.Marker({
        draggable: true,
        icon: {
          url: 'https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle.png',
          size: new google.maps.Size(7, 7),
          anchor: new google.maps.Point(4, 4),
        },
        title: 'Drag to change radius',
      });

      this.sizer.bindTo('map', this);
      this.sizer.bindTo('position', this, 'sizer_position');

      this.infoWindow = new google.maps.InfoWindow();

      this.sizer.addListener('click', () => {
        this.setInfoWindowContent(this.get('distance'));
        this.infoWindow.open(this.sizer.getMap(), this.sizer);
      });

      google.maps.event.addListener(this.sizer, 'drag', () => {
        this.setDistance();
      });
    }

    center_changed() {
      const bounds = this.get('bounds');

      // Bounds might not always be set so check that it exists first.
      if (bounds) {
        const lng = bounds.getNorthEast().lng();

        // Put the sizer at center, right on the circle.
        const position = new google.maps.LatLng(this.get('center').lat(), lng);
        this.set('sizer_position', position);
      }
    }

    distance_changed() {
      this.set('radius', this.get('distance') * 1000);
      if (this.sizer) {
        this.setInfoWindowContent(this.get('distance'));
        this.infoWindow.open(this.sizer.getMap(), this.sizer);
      }
    }

    private setInfoWindowContent(distance: number) {
      this.infoWindow.setContent(
        `<div style="min-width:100px"> Radius :<strong> ${distance.toFixed(
          2
        )} km</strong> </div>`
      );
    }

    distanceBetweenPoints(p1, p2) {
      if (!p1 || !p2) {
        return 0;
      }

      const R = 6371; // Radius of the Earth in km
      const dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
      const dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((p1.lat() * Math.PI) / 180) *
          Math.cos((p2.lat() * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      return d;
    }

    /**
     * Set the distance of the circle based on the position of the sizer.
     */
    setDistance() {
      // As the sizer is being dragged, its position changes.  Because the
      // RadiusWidget's sizer_position is bound to the sizer's position, it will
      // change as well.
      const pos = this.get('sizer_position');
      const center = this.get('center');
      const distance = this.distanceBetweenPoints(center, pos);

      // Set the distance property for any objects that are bound to it
      this.set('distance', distance);
    }
  };

  EditableCircle = class extends google.maps.Circle {
    onDrawFinished: () => void;
    infoWindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, 0),
      disableAutoPan: true,
    });

    constructor(
      private translationService: TranslationService,
      options: {
        circleOptions?: google.maps.CircleOptions;
        onDrawFinished?: () => void;
      }
    ) {
      super(options.circleOptions);
      this.onDrawFinished = options.onDrawFinished;

      this.setInfoWindowPosition();
      this.infoWindow.open(this.getMap());

      this.setInfoWindowContent();
      this.registerEvents();
    }

    registerEvents() {
      google.maps.event.addListener(this, 'bounds_changed', () => {
        this.setInfoWindowPosition();
        this.setInfoWindowContent();
        this.infoWindow.open(this.getMap());
      });
    }

    setMap(map: google.maps.Map) {
      super.setMap(map);

      if (!map) {
        this.infoWindow.close();
      }
    }

    setInfoWindowContent() {
      const button = document.createElement('button');
      button.className = 'mt-5';
      button.innerHTML = this.translationService.translate('Done');

      button.onclick = this.onDrawFinished.bind(this);

      const radius = this.getRadius();
      const content = document.createElement('div');

      content.innerHTML = `
      <div class="editable-overlay">
        <div class="row">
          <div class="col-9 p-0">
            <div> LatLng: ${this.getCenter().toUrlValue(3)}</div>
            <div class="mt-5"> ${this.translationService.translate(
              'Radius'
            )}: ${radius.toFixed(0)}m</div>
         </div>
        <div id="btn" class="col-3"></div>
        </div>
      </div>
    `;

      content.querySelector('#btn').append(button);
      this.infoWindow.setContent(content);
    }

    setInfoWindowPosition() {
      const infoWindowLatLng = google.maps.geometry.spherical.computeOffset(
        this.getCenter(),
        this.getRadius(),
        0
      );
      this.infoWindow.setPosition(infoWindowLatLng);
    }
  };

  createEditableCircle(options: {
    circleOptions?: google.maps.CircleOptions;
    onDrawFinished?: () => void;
  }): google.maps.Circle {
    return new this.EditableCircle(this.translationService, options);
  }

  createRadiusWidget(options: { radius: number }): google.maps.MVCObject {
    return new this.RadiusWidget(options.radius);
  }

  createHtmlMarker(options: {
    position: google.maps.LatLng;
    template: TemplateRef<any> | any;
    getPopupEmbeddedView: () => EmbeddedViewRef<unknown>;
    isPopupWindowOpen: boolean;
    anchorPosition: AnchorPosition;
  }): google.maps.OverlayView {
    return new this.HtmlMarker(this.appRef, options);
  }
}
