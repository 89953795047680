import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClSchema } from '../models/call-log-schema.model';
@Injectable({
  providedIn: 'root',
})
export class ClSchemaService {
  constructor(private httpClient: HttpClient) {}

  getClSchemas() {
    return this.httpClient.get<ClSchema[]>('/server-ts/call-logs/schemas');
  }
}
