<script src="../../../modules/visual-investigation/models/crypto.models.ts"></script>
<div class="navbar-menu">
  <ul class="primary-menu">
    <ng-container *ngrxLet="activeMenuItems$ as activeMenuItems">
      <ng-container *ngFor="let menu of activeMenuItems | slice: 0:5">
        <ng-container *ngIf="menu.children?.length > 0">
          <li
            [matMenuTriggerFor]="submenu.childMenu"
            #subMenuTrigger="matMenuTrigger"
            (mouseover)="subMenuTrigger.openMenu()"
            (mouseenter)="mouseEnter(subMenuTrigger)"
            (mouseleave)="mouseLeave(subMenuTrigger)"
            [ngClass]="{ 'width-100': isMobile }"
          >
            <ng-container
              [ngTemplateOutlet]="menuLink"
              [ngTemplateOutletContext]="{ menu: menu }"
            ></ng-container>
          </li>
          <app-nav-menu-sub-item
            #submenu
            [items]="menu.children"
            [menuTrigger]="subMenuTrigger"
            (onMouseEnter)="mouseEnter($event)"
            (onMouseLeave)="mouseLeave($event)"
          ></app-nav-menu-sub-item>
        </ng-container>

        <ng-container *ngIf="!menu.children || menu.children.length === 0">
          <li
            (mouseover)="onMenuItemMouseOver()"
            [ngClass]="{ 'width-100': isMobile }"
          >
            <ng-container
              [ngTemplateOutlet]="menuLink"
              [ngTemplateOutletContext]="{ menu: menu }"
            ></ng-container>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="activeMenuItems?.length >= 6">
        <li
          [matMenuTriggerFor]="submenu.childMenu"
          #subMenuTrigger="matMenuTrigger"
        >
          <div class="more-item-menu">
            <mat-icon class="navbar-more"> arrow_drop_down_circle </mat-icon>
          </div>
          <app-nav-menu-sub-item
            #submenu
            [items]="activeMenuItems | slice: 5"
            [menuTrigger]="subMenuTrigger"
            (onMouseEnter)="mouseEnter($event)"
            (onMouseLeave)="mouseLeave($event)"
          ></app-nav-menu-sub-item>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>

<ng-template #menuLink let-menu="menu">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <a
      (click)="handleClick(menu)"
      [ngClass]="{ 'router-link-active': menu.routeActive }"
      *ngIf="menu.visible"
      [routerLink]="menu.link"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="router-link-active"
      [angulartics2On]="menu.angulartics2On"
      [angularticsAction]="menu.angularticsAction"
      [angularticsCategory]="menu.angularticsCategory"
    >
      <mat-icon
        class="svg-icon"
        [class]="menu.svgIcon"
        *ngIf="menu.svgIcon"
        [svgIcon]="menu.svgIcon"
      ></mat-icon>
      <fe-platform-fa-pro-icon
        *ngIf="menu.faIcon"
        [name]="menu.faIcon.name"
        [family]="menu.faIcon.family"
      ></fe-platform-fa-pro-icon>
      <p class="name" [class.is-mobile]="isMobile">
        {{ menu.name | translate }}
      </p>
      <span
        *ngIf="menu.demoSign && actualTheme === themes.UNLIMITED"
        class="demo-sign"
        >DEMO</span
      >
      <span
        [ngClass]="menu.betaSign && menu.demoSign ? 'both-sign' : ''"
        *ngIf="menu.betaSign"
        class="beta-sign-label"
        >BETA</span
      >
    </a>
    <div #toggleButton id="{{ menu.name }}" *ngIf="menu.isToggleMenu"></div>
  </div>
</ng-template>
