import { BaseDTO } from 'datalayer/services/base';
import { Group } from 'datalayer/models/social/group';
import {
  EntityType,
  DataSource,
  Media,
} from 'datalayer/models/platform-models';

export class GroupDTO extends BaseDTO<Group, GroupDTO> {
  private id: any;
  private type: EntityType;
  private source: DataSource;
  private created_at: Date;
  private updated_at?: Date;
  private source_entity?: {
    id: string;
    created_at: Date;
    parent_id: string;
  };
  private target_id?: string;
  private username?: string;
  private owner?: string;
  private description?: string;
  private members_count?: number;
  private group_id?: string;
  private image?: Partial<Media>;
  private name?: string;
  private url?: string;

  constructor(model?: Partial<GroupDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Group): GroupDTO {
    this.id = model.id;
    this.type = model.type;
    this.source = model.source;
    this.created_at = model.createdAt;
    this.updated_at = model.updatedAt;
    this.source_entity = {
      id: model.sourceEntity.id,
      created_at: model.sourceEntity.createdAt,
      parent_id: model.sourceEntity.parentId,
    };
    this.target_id = model.targetId;
    this.username = model.username;
    this.owner = model.owner;
    this.description = model.description;
    this.members_count = model.membersCount;
    this.group_id = model.groupId;
    this.image = model.image;
    this.name = model.name;
    this.url = model.url;
    return this;
  }

  toModel(): Group {
    const post: Group = new Group({
      id: this.id,
      type: this.type,
      source: this.source,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      sourceEntity: {
        id: this.source_entity.id,
        createdAt: this.source_entity.created_at,
        parentId: this.source_entity.parent_id,
      },
      targetId: this.target_id,
      username: this.username,
      owner: this.owner,
      membersCount: this.members_count,
      description: this.description,
      groupId: this.group_id,
      image: this.image,
      name: this.name,
      url: this.url,
    });

    return post;
  }
}
