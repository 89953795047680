import { BaseDTO } from '../../services/base';
import { Image } from './image';

export class ImageDTO extends BaseDTO<Image, ImageDTO> {
  private id: string;
  private platform: string;
  private content: string;
  private url: string;
  private title: string;
  private description: string;
  private image: string;
  private feedback_stats: unknown;
  private valid_at: string;
  private published_at: string;
  private album_id: string;
  private source_entity: {
    id: string;
    created_at: string;
    parent_id: string;
  };

  constructor(model: Partial<ImageDTO>) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(): ImageDTO {
    return this;
  }

  toModel(): Image {
    return new Image({
      id: this.id,
      platform: this.platform,
      content: this.content,
      url: this.url,
      title: this.title,
      description: this.description,
      image: this.image,
      feedbackStats: this.feedback_stats,
      validAt: new Date(this.valid_at),
      publishedAt: new Date(this.published_at),
      albumId: this.album_id,
      sourceEntity: {
        id: this.source_entity.id,
        createdAt: new Date(this.source_entity.created_at),
        parentId: this.source_entity.parent_id,
      },
    });
  }
}
