<div class="group-details-section">
  <div class="d-flex justify-content-between group-details-header">
    <div class="d-flex flex-column">
      <div class="dialog-section back" *ngIf="!hideBackButton">
        <span>
          <a (click)="goBack()" class="link"
            >< {{ 'Back' | translate | lowercase }}</a
          >
        </span>
      </div>
      <div class="group-chat-title">
        <div class="d-flex align-items-center">
          <div class="common-groups-title">
            {{ 'Common Groups' | translate }}
          </div>
          <div class="group-length">{{ groupChats.length }}</div>
        </div>
      </div>
    </div>
    <div class="icon">
      <img src="assets/static/images/profiles-multiples.svg" />
    </div>
  </div>

  <div class="group-item-wrapper">
    <div *ngFor="let group of communityInformationGroup">
      <div class="d-flex justify-content-between group-item">
        <div class="content d-flex flex-column">
          <div class="group-title-container">
            <div class="group-title" appOverflowTooltip>
              {{ group.name }}
            </div>
          </div>
          <div class="group-internal-statistics row d-flex">
            <div class="stat-column">
              <div class="stat-item">
                <div class="stat-number">
                  {{ group.communityInfo.messagesCount }}
                </div>
                <div class="stat-text">{{ 'messages' | translate }}</div>
              </div>
              <div class="stat-item">
                <strong class="stat-number">{{
                  group.communityInfo.membersCount
                }}</strong>
                <div class="stat-text">{{ 'members' | translate }}</div>
              </div>
            </div>
            <div class="stat-column">
              <div class="stat-item">
                <strong class="stat-number">{{
                  group.communityInfo.mediaCount
                }}</strong>
                <div class="stat-text">{{ 'media' | translate }}</div>
              </div>
              <div class="stat-item">
                <strong class="stat-number">{{
                  group.communityInfo.activeMembersCount
                }}</strong>
                <div class="stat-text">{{ 'active members' | translate }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="group-status">
          <div class="group-status-box">
            {{
              group.isPrivate ? ('Private' | translate) : ('Public' | translate)
            }}
          </div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </div>
</div>
