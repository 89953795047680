import { HttpClient, HttpParams } from '@angular/common/http';
import { Type } from '@angular/core';
import { Entity } from 'datalayer/models/platform-models';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseDTO } from './base-dto';
import {
  DataChangeNotification,
  DataChangeType,
} from './data-change-notification';
import { RequestOptions } from './request-options.interface';

export abstract class BaseApiService<
  TYPE_MODEL extends Entity,
  TYPE_DTO extends BaseDTO<TYPE_MODEL, TYPE_DTO>,
  TYPE_CHANGE_TYPE = DataChangeType
> {
  protected baseRoute: string;
  private httpClient: HttpClient;
  private dtoType: new (model?: Partial<TYPE_DTO>) => TYPE_DTO;

  constructor(httpClient: HttpClient, dtoType: Type<TYPE_DTO>) {
    this.baseRoute = `${environment.serverAPIUri}/${this.sanitizeRouteName(
      'entities'
    )}`;

    this.httpClient = httpClient;
    this.dtoType = dtoType;
  }

  public resourceChanged = new ReplaySubject<
    DataChangeNotification<TYPE_MODEL, TYPE_CHANGE_TYPE>
  >(1);

  protected optionsToHttpParams(options: RequestOptions): HttpParams {
    let params = new HttpParams();
    for (const [key, value] of Object.entries(options.filters)) {
      params = params.append(key, encodeURIComponent(String(value)));
    }
    return params;
  }

  public get(id: string): Observable<TYPE_MODEL> {
    const route = `${this.baseRoute}/${id}`;
    return this.request<TYPE_DTO>('GET', route).pipe(
      map((response: TYPE_DTO) => {
        return this.decode(response);
      })
    );
  }

  public getAll(options?: RequestOptions): Observable<TYPE_MODEL[]> {
    const route = `${this.baseRoute}`;
    return this.request<TYPE_DTO[]>('GET', route, null, options).pipe(
      map((response: TYPE_DTO[]) => {
        return response.map((dto: TYPE_DTO) => {
          return this.decode(dto);
        });
      })
    );
  }

  public delete(model: TYPE_MODEL): Observable<TYPE_MODEL> {
    const route = `${this.baseRoute}/${model.id}`;
    return this.request<TYPE_DTO>('DELETE', route).pipe(
      map((response: any) => {
        return this.decode(response);
      })
    );
  }

  public update(model: TYPE_MODEL): Observable<TYPE_MODEL> {
    const route = `${this.baseRoute}/${model.id}`;
    return this.request<TYPE_DTO>('PUT', route, this.encode(model)).pipe(
      map((response: any) => {
        return this.decode(response);
      })
    );
  }

  public create(model: TYPE_MODEL): Observable<TYPE_MODEL> {
    const route = `${this.baseRoute}`;
    return this.request<TYPE_DTO>('POST', route, this.encode(model)).pipe(
      map((response: any) => {
        return this.decode(response);
      })
    );
  }

  private sanitizeRouteName(modelName: string): string {
    modelName = modelName.charAt(0).toLowerCase() + modelName.slice(1);
    return modelName.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
  }

  protected request<TYPE_RESULT>(
    method: string,
    url: string,
    body?: TYPE_RESULT,
    options?: RequestOptions
  ): Observable<TYPE_RESULT> {
    const params: HttpParams = options
      ? this.optionsToHttpParams(options)
      : null;
    return this.httpClient.request<TYPE_RESULT>(method, url, {
      body,
      params,
    });
  }

  public decode(dto): TYPE_MODEL {
    return new this.dtoType(dto).toModel();
  }

  public encode(model: TYPE_MODEL): TYPE_DTO {
    return new this.dtoType().fromModel(model);
  }

  public abstract getModelName(): string;

  public onResourceChanged(data: {
    type: TYPE_CHANGE_TYPE;
    dto: TYPE_DTO;
    message?: string;
  }): void {
    this.resourceChanged.next({
      type: data.type,
      models: [this.decode(data.dto)],
      message: data.message,
    });
  }
}
