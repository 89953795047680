import { RequestCl } from 'src/app/services/call-logs/request-cl.model';

export interface RequestClDialogModel {
  clParameters?: RequestCl;
  editable?: boolean;
  hasUploadCapabilities?: boolean;
  hasOnlyUploadCapabilities?: boolean;
  uploadClArgs?: {
    callLogStatus?: string;
    requestCallLogId?: string;
    targetId?: string;
  };
}

export enum ClRequestType {
  REQUEST_CALL_LOG = 'request-call-log',
  FULFILL_CALL_LOG = 'fulfill-call-log-request',
  UPLOAD_CALL_LOG = 'upload-call-log',
}
