<app-base-modal>
  <!-- FEEDBACK -->
  <div header class="title-wrapper">
    <ng-container *ngIf="state === feedbackStates.Feedback">
      <h2 class="questionnaire-dialog-title" mat-dialog-title>
        {{ 'Your feedback is valuable!' | translate }}
      </h2>
    </ng-container>
    <ng-container *ngIf="state === feedbackStates.SendFeedback">
      <h2 class="questionnaire-dialog-title" mat-dialog-title>
        {{ 'Send us your feedback!' | translate }}
      </h2>
    </ng-container>
    <ng-container *ngIf="state === feedbackStates.CloseFeedback">
      <h2 class="questionnaire-dialog-title" mat-dialog-title>
        {{ 'Are you sure you want to leave the program?' | translate }}
      </h2>
    </ng-container>
  </div>
  <div body class="questionnaire-dialog-body">
    <ng-container *ngIf="state === feedbackStates.Feedback">
      <div class="questionnaire-dialog-icon">
        <img
          [src]="getQuestionnaireIcon(feedbackStates.Feedback)"
          alt="questionnaire-icon"
        />
      </div>
      <div class="questionnaire-text-wrapper">
        <p class="questionnaire-dialog-name">
          {{ 'Dear' | translate }} {{ data.identity }},
        </p>
        <p class="questionnaire-dialog-desc">
          {{
            'We are always looking for the ways to improve our product for it to be even more useful in your daily work.'
              | translate
          }}
          {{
            'To achieve this we are asking you to participate in the user feedback generation program where every now-and-then we would ask you simple questions about how you use Intellectus.'
              | translate
          }}
        </p>
        <p class="questionnaire-dialog-collection">
          {{
            'All the data collected will be completely anonymous.' | translate
          }}
        </p>
        <p class="questionnaire-dialog-collection">
          {{ 'Would you like to help us become even better?' | translate }}
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="state === feedbackStates.SendFeedback">
      <div class="questionnaire-text-wrapper">
        <p class="questionnaire-dialog-name">
          {{ 'Please provide a short description of your work.' | translate }}
        </p>
        <p class="questionnaire-dialog-desc">
          {{ 'All the answers are anonymous.' | translate }}
        </p>
        <form [formGroup]="sendFeedbackForm" (ngSubmit)="onSubmitFeedback()">
          <mat-form-field class="full-width" appearance="outline">
            <textarea
              matInput
              class="feedback-textarea"
              formControlName="feedback"
              placeholder="{{
                'E.g., \'I work with drug cases in Panama\' or \'I work with kidnapping investigations in Mexico\'.'
                  | translate
              }}"
              rows="6"
            ></textarea>
            <mat-error
              *ngIf="
                sendFeedbackForm.controls.feedback.hasError('minlength') ||
                sendFeedbackForm.controls.feedback.hasError('required') ||
                sendFeedbackForm.controls.feedback.errors?.pattern
              "
            >
              {{
                'Must be at least 10 characters long and special characters are not allowed'
                  | translate
              }}.
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </ng-container>

    <ng-container *ngIf="state === feedbackStates.CloseFeedback">
      <div class="questionnaire-dialog-icon">
        <img
          [src]="getQuestionnaireIcon(feedbackStates.CloseFeedback)"
          alt="questionnaire-icon"
        />
      </div>
      <div class="questionnaire-text-wrapper">
        <p class="questionnaire-dialog-name">
          {{ 'Dear' | translate }} {{ data.identity }},
        </p>
        <p class="questionnaire-dialog-desc">
          {{
            'Your feedback is very valuable and will help us to improve Intellectus. We would appreciate if you stayed!'
              | translate
          }}
        </p>
        <p class="questionnaire-dialog-collection">
          {{ 'Are you sure you want to leave the program?' | translate }}
        </p>
      </div>
    </ng-container>
  </div>

  <div footer class="btn-wrp questionnaire-btn-wrp">
    <ng-container *ngIf="state === feedbackStates.Feedback">
      <button class="cp-btn-ghost" mat-button (click)="closeFeedback()">
        {{ 'No, thank you' | translate }}
      </button>

      <button class="cp-btn-primary" mat-button (click)="goToSendFeedback()">
        {{ 'Yes, I want to participate' | translate }}
      </button>
    </ng-container>

    <ng-container *ngIf="state === feedbackStates.SendFeedback">
      <button class="cp-btn-ghost" mat-button (click)="closeFeedback()">
        {{ 'Cancel' | translate }}
      </button>

      <button class="cp-btn-primary" (click)="onSubmitFeedback()" mat-button>
        {{ 'Submit' | translate }}
      </button>
    </ng-container>

    <ng-container *ngIf="state === feedbackStates.CloseFeedback">
      <button class="cp-btn-ghost" mat-button (click)="closeFeedback()">
        {{ 'Yes, I want to cancel' | translate }}
      </button>

      <button class="cp-btn-primary" (click)="dialog.close()" mat-button>
        {{ 'No, I want to continue' | translate }}
      </button>
    </ng-container>
  </div>
</app-base-modal>
