import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-credits-container',
  templateUrl: './credits-container.component.html',
  styleUrls: ['./credits-container.component.scss'],
})
export class CreditsContainerComponent {
  @Input()
  public hasConcurrentLimits: boolean;

  @Input()
  public readonly isMobile: boolean;
}
