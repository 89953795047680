<ngx-hm-carousel
  [(ngModel)]="index"
  (ngModelChange)="moveSlider($event)"
  [autoplay-speed]="speed"
  [autoplay]="autoplay"
  [autoplay-direction]="direction"
  [infinite]="infinite"
  [between-delay]="1000"
  [data]="photos"
  [aniTime]="200"
  class="carousel c-accent"
>
  <section ngx-hm-carousel-container class="photo-carousel-modal-content">
    <article
      class="item cursor-pointer fade_animation"
      ngx-hm-carousel-item
      *ngFor="let photo of photos; let i = index"
      [ngClass]="{ visible: index === i }"
    >
      <div class="photo-img" *ngIf="i === index">
        <div
          class="photo-img"
          [hidden]="imgWidth < 250"
          *ngIf="photo.image"
          [style.backgroundImage]="'url(' + photo.image + ')'"
          onerror="this.style.display='none'"
          loading="lazy"
        ></div>
        <span class="small-img" *ngIf="imgWidth < 250">
          <div
            class="blur-img"
            [style.backgroundImage]="'url(' + photo.image + ')'"
          ></div>
          <img
            class="photo-img"
            [src]="photo.image"
            onerror="this.style.display='none'"
            loading="lazy"
          />
        </span>
      </div>
    </article>
    <ng-template #infiniteContainer></ng-template>
  </section>

  <ng-template #carouselContent let-photo let-i="index">
    <article
      class="item cursor-pointer fade_animation"
      [ngClass]="{ visible: index === i }"
    >
      <div
        class="photo-img"
        [style.backgroundImage]="'url(' + photo.image + ')'"
      ></div>
    </article>
  </ng-template>

  <ng-template #carouselPrev>
    <div class="click-area" *ngIf="index > 0">
      <i class="material-icons">keyboard_arrow_left</i>
    </div>
  </ng-template>
  <ng-template #carouselNext>
    <div class="click-area" *ngIf="index !== photos.length - 1">
      <i class="material-icons">keyboard_arrow_right</i>
    </div>
  </ng-template>
</ngx-hm-carousel>
