export enum QueryParamType {
  Photos = 'photos',
  Msisdns = 'msisdns',
  Imeis = 'imeis',
  Imsis = 'imsis',
  Names = 'names',
  UserNames = 'userNames',
  SourceEntityIds = 'sourceEntityIds',
  Emails = 'emails',
  Urls = 'urls',
  WebDomains = 'webDomains',
  Keywords = 'keywords',
}
