import { BaseDTO } from 'datalayer/services/base';
import {
  ParentSeed,
  RecoveryAccount,
} from 'src/app/shared/models/recovery-account.model';
import { Platform } from 'src/app/shared/schemas/common/platforms';

export class RecoveryAccountDTO extends BaseDTO<
  RecoveryAccount,
  RecoveryAccountDTO
> {
  private platform: Platform;
  private emails?: string[];
  private telnos?: string[];
  private parent_seed?: ParentSeed & { alternative_seeds: ParentSeed[] };

  constructor(model?: Partial<RecoveryAccountDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: RecoveryAccount): RecoveryAccountDTO {
    if (!model) {
      return;
    }
    this.platform = model.platform;
    this.emails = model.emails;
    this.telnos = model.telnos;

    const { alternativeSeeds: alternative_seeds, ...parentSeed } =
      model.parentSeed;
    this.parent_seed = {
      ...parentSeed,
      alternative_seeds,
    };
    return this;
  }

  toModel(): RecoveryAccount {
    if (!this.parent_seed) {
      return;
    }
    const { alternative_seeds: alternativeSeeds, ...parentSeed } =
      this.parent_seed;

    return {
      platform: this.platform,
      emails: this.emails,
      telnos: this.telnos,
      parentSeed: {
        ...parentSeed,
        alternativeSeeds,
      },
    };
  }
}
