import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { QueryService } from 'src/app/services/query/query.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { QueryType } from 'src/app/shared/models/query-item.model';
import {
  MatSnackBar,
  MatSnackBarVerticalPosition,
  MatSnackBarHorizontalPosition,
} from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/services/translation/translation.service';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import { Angulartics2 } from 'angulartics2';

@Component({
  selector: 'app-logon-logoff',
  templateUrl: './logon-logoff.component.html',
  styleUrls: ['./logon-logoff.component.scss'],
})
export class LogonLogoffComponent implements OnInit, OnDestroy {
  types = [
    { label: 'Log On', queryType: 'LOGON' },
    { label: 'Log Off', queryType: 'LOGOFF' },
  ];
  selectedType: string;
  date: Date[];
  logonFrequency: number;
  logonForm: UntypedFormGroup;
  numbersToBeQueried;
  public todayDate = new Date();
  showError = false;
  skin;
  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';
  subscriptions: Subscription[] = [];

  constructor(
    private queryService: QueryService,
    private translationService: TranslationService,
    private dashboardService: DashboardService,
    private applicationStateService: ApplicationStateService,
    private angulartics2: Angulartics2,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.initForm();
    const dateSubscription = this.queryService.dateRange.subscribe(
      (dateRange) => {
        this.date = dateRange;
        this.logonForm.controls['startAt'].setValue(this.date[0]);
        this.logonForm.controls['endAt'].setValue(this.date[1]);
      }
    );
    const frequencySubscription = this.queryService.frequency.subscribe(
      (frequency) => {
        this.logonFrequency = frequency;
        this.logonForm.controls['frequency'].setValue(this.logonFrequency);
      }
    );
    const formSubmissionSubscription =
      this.queryService.advancedDataFlag.subscribe((flag) => {
        if (
          flag &&
          this.queryService.advancedQueryType.value === QueryType.LOGON
        ) {
          this.onSubmit();
        }
      });
    const skinSubscription = this.applicationStateService.skin.subscribe(
      (skin: string) => {
        this.skin = skin;
      }
    );

    this.subscriptions.push(
      dateSubscription,
      frequencySubscription,
      formSubmissionSubscription,
      skinSubscription
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  initForm() {
    this.logonForm = new UntypedFormGroup({
      queryType: new UntypedFormControl(QueryType.LOGON),
      startAt: new UntypedFormControl('', Validators.required),
      endAt: new UntypedFormControl('', Validators.required),
      frequency: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit() {
    const queriesSubmission = this.queryService.numbersToBeQueried.subscribe(
      (numbers) => {
        if (numbers) {
          this.numbersToBeQueried = numbers;
        }
      }
    );
    this.subscriptions.push(queriesSubmission);

    if (this.logonForm.valid && this.numbersToBeQueried.length) {
      const logonQuerySubscription = this.queryService
        .createLogonQuery(this.logonForm.value)
        .subscribe(
          () => {
            this.showMessage(
              this.translationService.translate('Query created successfully!')
            );
            this.changeView('logView');
          },
          () => {
            this.showMessage(
              this.translationService.translate('Query has not been created')
            );
          },
          () => {
            this.angulartics2.eventTrack.next({
              action: matomoActions.submitLogOnQuery,
              properties: {
                category: matomoCategories.gioAdvancedQuery,
              },
            });
          }
        );
      this.subscriptions.push(logonQuerySubscription);
      // clear form
      this.queryService.advancedDataFlag.next(false);
    } else {
      this.showError = true;
      this.queryService.queriesFlag.next(false);
    }
  }

  changeView(view) {
    this.dashboardService.componentsView.next(view);
  }

  protected showMessage(
    msg: string,
    okText = 'OK',
    preferredDuration?: number,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition
  ) {
    this.snackBar.open(msg, okText, {
      duration: preferredDuration || 3000,
      horizontalPosition: preferredHorizontalPosition,
      verticalPosition: preferredVerticalPosition,
      panelClass: ['custom-snackbar'],
    });
  }
}
