import { Person } from 'datalayer/models/person/person';
import {
  BackendDevice,
  DataSource,
  Device,
  EntityType,
  Gender,
  Media,
} from 'datalayer/models/platform-models';
import { BaseDTO } from 'datalayer/services/base';
import { CheckIn } from 'src/app/modules/search-intel/models/search-intel.model';

export class PersonDTO extends BaseDTO<Person, PersonDTO> {
  private id: any;
  private type: EntityType;
  private source: DataSource;
  private created_at: Date;
  private updated_at?: Date;
  private source_entity?: {
    id: string;
    created_at: Date;
    parent_id: string;
  };
  private target_id?: string;
  private names?: string[];
  private emails?: string[];
  private gender?: Gender;
  private addresses?: {}[];
  private ethnicities?: string[];
  private languages?: string[];
  private urls?: string[];
  private origin_countries?: string[];
  private telnos?: string[];
  private usernames?: string[];
  private user_ids?: string[];
  private land_telnos?: string[];
  private person_id?: string;
  private image: Media;
  private cities?: string[];
  private postcode?: string;
  private voter_id?: string;
  private jobs?: string[];
  private mother_names?: string[];
  private father_names?: string[];
  private ife?: string[];
  private curp?: string[];
  private nik?: string[];
  private rfc?: string[];
  private date_of_birth?: string;
  private place_of_birth?: string[];
  private groups?: string[];
  private skills?: string[];
  private courses?: string[];
  private industry?: string[];
  private volunteering?: string[];
  private vehicle_id_number?: string[];
  private vehicle_make?: string[];
  private vehicle_model?: string[];
  private vehicle_year?: string[];
  private property_type?: string[];
  private home_last_sale_date?: string[];
  private about?: string[];
  private associated_domain?: string[];
  private followers_count?: number;
  private connections_count?: number;
  private following_count?: number;
  private fan_of?: string[];
  private other_tags?: string[];
  private relation?: string;
  private relationship_status?: string;
  private sub_relation?: string;
  private ip_addresses?: string[];
  private hair_color?: string;
  private eyes_color?: string;
  private height?: string;
  private weight?: string;
  private devices_info?: BackendDevice[];
  private date_added?: string;
  private check_in?: CheckIn[];
  private educations?: string[];

  constructor(model?: Partial<PersonDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Person): PersonDTO {
    this.id = model.id;
    this.type = model.type;
    this.source = model.source;
    this.created_at = model.createdAt;
    this.updated_at = model.updatedAt;
    this.source_entity = {
      id: model.sourceEntity.id,
      created_at: model.sourceEntity.createdAt,
      parent_id: model.sourceEntity.parentId,
    };
    this.target_id = model.targetId;
    this.names = model.names;
    this.emails = model.emails;
    this.gender = model.gender;
    this.addresses = model.addresses;
    this.ethnicities = model.ethnicities;
    this.languages = model.languages;
    this.urls = model.urls;
    this.origin_countries = model.originCountries;
    this.telnos = model.telnos;
    this.usernames = model.usernames;
    this.user_ids = model.userIds;
    this.land_telnos = model.landTelnos;
    this.person_id = model.personId;
    this.image = model.image;
    this.cities = model.cities;
    this.postcode = model.postcode;
    this.voter_id = model.voterId;
    this.jobs = model.jobs;
    this.mother_names = model.motherNames;
    this.father_names = model.fatherNames;
    this.ife = model.ife;
    this.curp = model.curp;
    this.nik = model.nik;
    this.rfc = model.rfc;
    this.date_of_birth = model.dateOfBirth;
    this.place_of_birth = model.placeOfBirth;
    this.vehicle_id_number = model.vehicleIdNumber;
    this.vehicle_make = model.vehicleMake;
    this.vehicle_model = model.vehicleModel;
    this.vehicle_year = model.vehicleYear;
    this.volunteering = model.volunteering;
    this.groups = model.groups;
    this.about = model.about;
    this.skills = model.skills;
    this.associated_domain = model.associatedDomain;
    this.courses = model.courses;
    this.fan_of = model.fanOf;
    this.other_tags = model.otherTags;
    this.industry = model.industry;
    this.followers_count = model.followersCount;
    this.following_count = model.followingCount;
    this.connections_count = model.connectionsCount;
    this.property_type = model.propertyType;
    this.home_last_sale_date = model.homeLastSaleDate;
    this.relation = model.relation;
    this.sub_relation = model.subRelation;
    this.ip_addresses = model.ipAddresses;
    this.hair_color = model.hairColor;
    this.weight = model.weight;
    this.height = model.height;
    this.eyes_color = model.eyesColor;
    this.devices_info = this.getBackendDevices(model.devicesInfo);
    this.date_added = model.dateAdded;
    this.check_in = model.checkIn;
    this.relationship_status = model.relationshipStatus;
    this.educations = model.educations;

    return this;
  }

  toModel(): Person {
    const person: Person = new Person({
      id: this.id,
      type: this.type,
      source: this.source,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      sourceEntity: {
        id: this.source_entity.id,
        createdAt: this.source_entity.created_at,
        parentId: this.source_entity.parent_id,
      },
      targetId: this.target_id,
      names: this.names,
      emails: this.emails,
      gender: this.gender,
      addresses: this.addresses,
      ethnicities: this.ethnicities,
      languages: this.languages,
      urls: this.urls,
      originCountries: this.origin_countries,
      telnos: this.telnos,
      usernames: this.usernames,
      userIds: this.user_ids,
      landTelnos: this.land_telnos,
      personId: this.person_id,
      image: this.image,
      cities: this.cities,
      postcode: this.postcode,
      voterId: this.voter_id,
      jobs: this.jobs,
      motherNames: this.mother_names,
      fatherNames: this.father_names,
      ife: this.ife,
      curp: this.curp,
      nik: this.nik,
      rfc: this.rfc,
      dateOfBirth: this.date_of_birth,
      placeOfBirth: this.place_of_birth,
      vehicleIdNumber: this.vehicle_id_number,
      vehicleMake: this.vehicle_make,
      vehicleModel: this.vehicle_model,
      vehicleYear: this.vehicle_year,
      industry: this.industry,
      followersCount: this.followers_count,
      followingCount: this.following_count,
      connectionsCount: this.connections_count,
      propertyType: this.property_type,
      groups: this.groups,
      courses: this.courses,
      skills: this.skills,
      volunteering: this.volunteering,
      fanOf: this.fan_of,
      otherTags: this.other_tags,
      homeLastSaleDate: this.home_last_sale_date,
      associatedDomain: this.associated_domain,
      about: this.about,
      relation: this.relation,
      subRelation: this.sub_relation,
      relationshipStatus: this.relationship_status,
      ipAddresses: this.ip_addresses,
      hairColor: this.hair_color,
      eyesColor: this.eyes_color,
      weight: this.weight,
      height: this.height,
      devicesInfo: this.getDeviceObject(this.devices_info),
      devicesInfoTransformed: this.getDevices(this.devices_info),
      dateAdded: this.date_added,
      checkIn: this.check_in,
      educations: this.educations,
    });

    return person;
  }

  private getDevices(devices: BackendDevice[]): string[] {
    return devices?.map((device) => {
      return device.os
        ? `${device.device} - ${device.os}, ${device.system_version}`
        : `${device.device}, ${device.system_version}`;
    });
  }

  private getDeviceObject(devices: BackendDevice[]): Device[] {
    if (!devices) {
      console.error('devices is undefined');
      return [];
    }

    return devices
      .map((singleDevice) => {
        if (!singleDevice?.session) {
          console.error(
            'singleDevice or singleDevice.session is undefined',
            singleDevice
          );
          return null;
        }

        return {
          device: singleDevice.device?.trim() || null,
          os: singleDevice.os?.trim(),
          systemVersion: singleDevice.system_version?.trim(),
          session: {
            ip: singleDevice.session.ip,
            location: singleDevice.session.location,
            sessionStart: singleDevice.session.session_start,
            sessionEnd: singleDevice.session.session_end,
            sessionLength: singleDevice.session.session_length,
            ipLocationInfo: singleDevice.session.ip_location_info,
          },
        };
      })
      .filter((device) => device !== null);
  }

  private getBackendDevices(devices: Device[]): BackendDevice[] {
    return (
      devices?.map((device) => {
        return {
          device: device.device,
          os: device.os,
          system_version: device.system_version,
          session: device.session
            ? {
                ip: device.session.ip,
                location: device.session.location,
                session_start: device.session.sessionStart,
                session_end: device.session.sessionEnd,
                session_length: device.session.sessionLength,
                ip_location_info: device.session.ipLocationInfo,
              }
            : null,
        };
      }) || []
    );
  }
}
