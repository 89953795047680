<ng-container *ngrxLet="isMobile$ as isMobile">
  <div class="wrapper" [class.is-mobile]="isMobile">
    <div class="modal">
      <intellectus-modal-header
        [config]="config"
        [isMobile]="isMobile"
        (closeClick)="close()"
        (breadcrumbClick)="breadcrumbClick.emit($event)"
      ></intellectus-modal-header>

      <div
        class="content"
        [style.height]="
          !!config.footerHeight &&
          'calc(100% - 60px - ' + config.footerHeight + 'px)'
        "
      >
        <div class="scrollable-content" #scrollableContent>
          <ng-content select="[modal-body]"></ng-content>
        </div>
      </div>
      <div class="modal-footer" *ngIf="!!config?.footerHeight">
        <ng-content select="[modal-footer]"></ng-content>
      </div>
    </div>

    <div class="backdrop" (click)="close()"></div>
  </div>
</ng-container>
