import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { DynamicElementRefService } from '../services/dynamic-element-ref/dynamic-element-ref.service';

@Directive({
  selector: '[dynamicElementRef]',
})
export class DynamicElementRefDirective implements OnInit, OnDestroy {
  @Input() dynamicElementRef: string;

  constructor(
    private dynRefService: DynamicElementRefService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit(): void {
    // Publish the view container so that foreign modules can optionally overwrite it
    this.dynRefService.addContainer(
      this.dynamicElementRef,
      this.viewContainer,
      this.templateRef
    );
  }

  ngOnDestroy(): void {
    this.dynRefService.removeContainer(this.dynamicElementRef);
  }
}
