import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import {
  DashboardService,
  DashboardView,
} from 'src/app/services/dashboard/dashboard.service';
import { QueryService } from 'src/app/services/query/query.service';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';
import { UserService } from 'src/app/services/user/user.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { Query } from 'src/app/shared/models/query-item.model';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';

@Component({
  selector: 'app-extra-location-information-dialog',
  templateUrl: './extra-location-information-dialog.component.html',
  styleUrls: ['./extra-location-information-dialog.component.scss'],
})
export class ExtraLocationInformationDialogComponent
  extends BaseComponent
  implements OnInit
{
  counterValue = 60;
  progress = 0;
  intervalId;
  counterInterval;
  popUpTimer;
  showLoader = false;
  credits: number;
  profilerView = false;
  titles = {
    gps: {
      h1: 'Advanced geolocation',
      h2: 'Would you like to obtain AGPS/GPS location for',
      extraContent: null,
      action: BillingActions.GPS,
    },
    nmr: {
      h1: 'Advanced geolocation',
      h2: 'Would you like to obtain more accurate location for',
      extraContent: null,
      action: BillingActions.NMR,
    },
    activeLocate: {
      h1: 'Active Locate',
      h2: 'The Cell ID cannot be extracted for',
      extraContent:
        'Do you want to perform an Active Locate query? This action might leave anonymous traces on target’s phone.',
      action: BillingActions.QUERY_LOCATION,
    },
  };
  accurateLocation: boolean;

  constructor(
    public dialogRef: MatDialogRef<ExtraLocationInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { type: extraLocationInformationTypes; query: Query },
    private router: Router,
    private dashboardService: DashboardService,
    private queryService: QueryService,
    private angulartics2: Angulartics2,
    private userService: UserService,
    private roleManagementService: RoleManagementService,
    private userBillingService: UserBillingService
  ) {
    super();
    this.profilerView = this.router.url.includes('targets');
  }

  ngOnInit() {
    this.startCountdown();
    this.handleCounterValue();
    this.accurateLocation =
      this.userService.handlingAccurateLocation.getValue();
  }

  private changeAccurateLocation(flag: boolean): void {
    this.subscriptions.push(
      this.userService
        .changeAccurateLocation(
          flag,
          this.roleManagementService.currentUser.identity
        )
        .subscribe((accurateLocation: boolean) => {
          this.userService.handlingAccurateLocation.next(flag);
        })
    );
  }

  private handleCounterValue(): void {
    this.counterInterval = setInterval(() => {
      this.counterValue = this.counterValue - 1;

      this.progress = (this.counterValue / 60) * 100;
    }, 1000);
  }

  private startCountdown(): void {
    this.popUpTimer = setTimeout(() => {
      this.closeDialog();
    }, 60000);
  }

  public closeDialog(): void {
    if (this.counterInterval) {
      clearInterval(this.counterInterval);
    }
    this.dialogRef.close(this.accurateLocation);
  }

  public getData() {
    clearInterval(this.counterInterval);
    clearTimeout(this.popUpTimer);
    if (
      this.userBillingService.userHasEnoughCredits([
        this.titles[this.data.type].action,
      ])
    ) {
      this.showLoader = true;
      setTimeout(() => {
        switch (this.data?.type) {
          case extraLocationInformationTypes.GPS:
            this.queryService.chargeGPSGeoQuery(this.data.query.id).subscribe();
            break;
          case extraLocationInformationTypes.NMR:
            this.queryService.chargeNMRGeoQuery(this.data.query.id).subscribe();
            if (this.accurateLocation) {
              this.changeAccurateLocation(this.accurateLocation);
            }
            break;
          case extraLocationInformationTypes.ACTIVE_LOCATE:
            this.angulartics2.eventTrack.next({
              action: matomoActions.activeLocateQuery,
              properties: {
                category: matomoCategories.extraLocationInformation,
              },
            });
            this.queryService
              .submitActiveLocateQuery(this.data.query.id)
              .subscribe();
            break;
          default:
            break;
        }
        if (this.profilerView && this.data.query.targetId) {
          this.router.navigate([
            'targets',
            this.data.query.targetId,
            'overview',
            { gps: true },
          ]);
        } else {
          this.navigateToLog(this.data.query);
        }

        this.subscriptions.push(
          this.dialogRef
            .afterClosed()
            .subscribe(() => (this.showLoader = false))
        );
        this.closeDialog();
      }, this.getRandomLoaderDuration());
    }
  }

  private navigateToLog(query: Query): void {
    this.router.navigate(['discovery']);
    this.dashboardService.componentsView.next(DashboardView.LOG);
  }

  private getRandomLoaderDuration(): number {
    return Math.random() * 3000 + 2000;
  }
}

export enum extraLocationInformationTypes {
  GPS = 'gps',
  NMR = 'nmr',
  ACTIVE_LOCATE = 'activeLocate',
}
