import { Injectable } from '@angular/core';
import {
  LocationProbabilityInsight,
  QueryCommand,
} from '@trg-commons/data-models-ts';
import { Subject } from 'rxjs';
import { bufferTime, filter, map, switchMap } from 'rxjs/operators';
import { BaseStore } from 'src/app/shared/classes/base-store.class';
import { InsightResults } from '../models/insights.model';

@Injectable({ providedIn: 'root' })
export class InvestigationInsightsStore extends BaseStore<InsightResults> {
  correlationId: string;

  private filteredEntityStream: Subject<InsightResults> = new Subject();
  filteredStream$ = this.filteredEntityStream.asObservable().pipe(
    bufferTime(1000),
    switchMap((x) => x),
    filter((v) => (Array.isArray(v) ? v.length > 0 : true)),
    map((batch) => {
      return batch;
    })
  );

  locationProbabilityInsights: Subject<{
    type: QueryCommand;
    insights: LocationProbabilityInsight[];
    msisdns: string[];
  }> = new Subject();

  constructor() {
    super({});
  }

  public reset() {
    this.value = {};
  }

  public isEmpty(): boolean {
    return Object.keys(this.value).length === 0;
  }

  public append(insightResutls: InsightResults) {
    this.value = { ...this.value, ...insightResutls };
    this.filteredEntityStream.next(this.value);
  }

  clear() {
    this.value = {};
  }
}
