<p>
  <span class="intel-snackbar" [innerHTML]="htmlTemplate.start"></span>&nbsp;
  <ng-container *ngTemplateOutlet="link"></ng-container>&nbsp;
  <span class="intel-snackbar" [innerHTML]="htmlTemplate.end"></span>
</p>

<ng-template #link>
  <ng-container *ngIf="htmlTemplate.linkText">
    <span (click)="navigate()" class="intel-snackbar intel-snackbar-link">{{
      htmlTemplate.linkText
    }}</span>
  </ng-container>
  <ng-container *ngIf="targetId">
    <span (click)="navigate()" class="intel-snackbar intel-snackbar-link">{{
      'here' | translate
    }}</span>
  </ng-container>
</ng-template>
