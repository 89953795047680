<div class="row proximity-row">
  <div class="col-12 col-md-4 remove-padding-from-left-col">
    <div>
      <app-datetimepicker [minDate]="todayDate"></app-datetimepicker>
    </div>
  </div>
  <div class="col-12 col-md-4 remove-padding-from-left-col">
    <div>
      <app-frequency></app-frequency>
    </div>
  </div>
  <div class="col-12 col-md-4 remove-padding-from-left-col">
    <div>
      <form [formGroup]="proximityForm" (ngSubmit)="onSubmit()">
        <div class="global-select-label">{{ 'Distance' | translate }} (km)</div>
        <input
          class="global-input"
          formControlName="radius"
          matInput
          type="number"
          autocomplete="off"
          min="0"
          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
        />
      </form>
    </div>
  </div>
</div>
<div *ngIf="showError" class="mat-error">
  {{ 'Please fill in all fields' | translate }}
</div>
<div *ngIf="showLengthError" class="mat-error">
  {{
    'Please insert more than one phones to perform a proximity query'
      | translate
  }}
</div>
