import { AvatarPerson } from 'datalayer/models/avatar/avatar-person';
import { Gender } from 'datalayer/models/platform-models';
import { BaseDTO } from '../base';

export class AvatarPersonDTO extends BaseDTO<AvatarPerson, AvatarPersonDTO> {
  private countryCode: string;
  private gender: Gender;
  private lastname: string;
  private name: string;
  private avatarUrlSuffix: string;
  private featuredImageUrlSuffixes: string[];
  private birthdate: string;
  private id: string;
  private cityName: string;

  constructor(model?: Partial<AvatarPersonDTO>) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: AvatarPerson): AvatarPersonDTO {
    if (model.id) {
      this.id = model.id;
    }
    if (model.cityName) {
      this.cityName = model.cityName;
    }
    this.countryCode = model.countryCode;
    this.name = model.name;
    this.lastname = model.lastName;
    this.gender = model.gender;
    return this;
  }

  toModel(imageBaseRoute?: string): AvatarPerson {
    return new AvatarPerson({
      id: this.id,
      name: this.name,
      lastName: this.lastname,
      fullName: this.name + ' ' + this.lastname,
      ...(this.gender ? { gender: this.gender } : {}),
      ...(this.countryCode ? { countryCode: this.countryCode } : {}),
      ...(this.avatarUrlSuffix
        ? {
            image: `${imageBaseRoute}/persons/${this.avatarUrlSuffix}`.replace(
              'featuredImages',
              'featuredImagesThumb'
            ),
          }
        : {}),
      ...(this.featuredImageUrlSuffixes
        ? {
            featureImages: this.featuredImageUrlSuffixes.map((image) =>
              `${imageBaseRoute}/persons/${image}`.replace(
                'featuredImages',
                'featuredImagesThumb'
              )
            ),
          }
        : {}),
      ...(this.birthdate ? { birthDate: new Date(this.birthdate) } : {}),
      ...(this.cityName ? { cityName: this.cityName } : {}),
    });
  }
}
