import { Note } from './note.model';
import { PaginationMeta } from '@shared/models/pagination.model';

// TODO: Revise feed after BE is completed
export interface FeedItem {
  feedType: FeedType;
  createdAt: Date;
  createdBy: string;
  feed: any;
  targetId: string;
  queryDate: Date;
  collectedDate: Date;
  queryKey: string;
  updatedAt: Date;
  referenceCollection: string;
  referenceId: string;
  notes?: Note[];
  mediaBackground?: string;
  startOfMonth?: boolean;
  sourceEntity?: SourceEntity;
  id: string;
  authorId?: string;
}

export enum FeedType {
  ALBUM = 'album',
  POST = 'post',
  COMMENT = 'comment',
  IMAGE = 'image',
  VIDEO = 'video',
  NOTE = 'note',
  LOCATION = 'location',
}

export interface SourceEntity {
  createdAt?: Date;
  id?: string;
  parentId?: string;
}

export interface FeedListQueryFilters {
  from_date: string;
  to_date: string;
  south_west_lat: number;
  south_west_lng: number;
  north_east_lat: number;
  north_east_lng: number;
  inside: boolean;
  keywords: string;
  menu_options: string;
  related?: boolean;
}

export interface FeedListResult {
  result: FeedItem[];
  meta: PaginationMeta;
  links: { [key: string]: string };
}
