import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ModalDataService {
  private readonly snapshot$: Subject<ActivatedRouteSnapshot> = new Subject();

  private readonly outsideNavigation$: Subject<void> = new Subject();

  private readonly locate$: Subject<void> = new Subject();

  public get snapshot(): Observable<ActivatedRouteSnapshot> {
    return this.snapshot$.asObservable();
  }

  public emit(data: ActivatedRouteSnapshot): void {
    this.snapshot$.next(data);
  }

  public get outsideNavigation(): Observable<void> {
    return this.outsideNavigation$.asObservable();
  }

  public onNavigation(): void {
    this.outsideNavigation$.next();
  }

  public onLocate(): void {
    this.locate$.next();
  }

  public get locate(): Observable<void> {
    return this.locate$.asObservable();
  }
}
