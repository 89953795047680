import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlTruncate',
})
export class UrlTruncatePipe implements PipeTransform {
  transform(value: any, length?: number): any {
    if (value && (value.includes('http') || value.includes('https'))) {
      return value.trim().substr(0, length || 25) + '...';
    }
    return value;
  }
}
