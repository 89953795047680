import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  FormControlStatus,
  Validators,
} from '@angular/forms';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
} from '@fe-platform/shared-ui/intellectus';
import {
  otpChannelLabel,
  OTPChannels,
} from 'src/app/components/user-settings/user-settings.models';
import { User } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { BaseComponent } from '../../classes/base.component';
import { InputStatus } from './../../../components/user-settings/user-settings.models';

@Component({
  selector: 'app-activate-otp-code-input',
  templateUrl: './activate-otp-code-input.component.html',
  styleUrls: ['./activate-otp-code-input.component.scss'],
})
export class ActivateOtpCodeInputComponent
  extends BaseComponent
  implements OnInit
{
  @Input() errorMsg: string;
  @Input() otpChannel: OTPChannels = OTPChannels.None;
  @Input() otpRecipient?: string;
  @Input() oldChannel?: string;
  @Input() showMessageWithOldChannel = false;
  @Output() otpControlChanged = new EventEmitter<InputStatus>();
  otpChannelLabel = otpChannelLabel;
  channels = OTPChannels;
  otpControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(4),
    Validators.maxLength(6),
  ]);
  currentUser: User;
  visibility = false;
  userSelectionMessage = '';
  public fAavailableIcons: { [name: string]: FontAwesomeIcon } = {
    eyeVisible: {
      name: 'eye',
      family: FontAwesomeFamily.REGULAR,
    },
    eyeHidden: {
      name: 'eye-slash',
      family: FontAwesomeFamily.REGULAR,
    },
  };
  constructor(
    private userService: UserService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.userSelectionMessage = `You selected to change OTP method from ${
      otpChannelLabel[this.oldChannel]
    } to the following ${otpChannelLabel[this.otpChannel]}`;

    if (!this.otpRecipient) {
      this.subscriptions.push(
        this.userService.getCurrentUser().subscribe((user) => {
          this.currentUser = user;
          this.cdRef.markForCheck();
        })
      );
    }
    this.subscriptions.push(
      this.otpControl.statusChanges.subscribe((val: FormControlStatus) => {
        this.otpControlChanged.emit({
          value: this.otpControl.value,
          valid: val === 'VALID',
        });
        this.cdRef.markForCheck();
      })
    );
  }
}
