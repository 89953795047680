import { Component, Input } from '@angular/core';
import { TelephoneNumberProvider } from '../type/telephone-number-provider';

@Component({
  selector: 'intellectus-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent {
  @Input() phoneNumber!: TelephoneNumberProvider;
}
