<app-base-modal id="create-investigation-modal">
  <div header class="ubuntu-font header-txt">
    {{ 'Create case investigation' | translate }}
  </div>

  <div body>
    <form [formGroup]="createInvestigationForm" autocomplete="off" novalidate>
      <ng-container>
        <div class="field-group">
          <label class="assistant-font"
            >{{ 'Name' | translate }}<span class="warn-text">*</span></label
          >
          <div class="input-style-round">
            <input
              matInput
              type="text"
              id="title"
              formControlName="name"
              placeholder="{{ 'Enter investigation name' | translate }}"
              maxlength="255"
            />
          </div>
          <label class="assistant-font">{{ 'Description' | translate }}</label>
          <div class="input-style-round">
            <textarea
              matInput
              type="text"
              id="description"
              placeholder="{{
                'Enter a short description of your investigation' | translate
              }}"
              maxlength="1024"
              rows="8"
              formControlName="description"
            >
            </textarea>
          </div>
        </div>
        <span *ngIf="!data?.withInformation" class="note-txt assistant-font"
          ><b>{{
            'Please select at least 1 entity to create a new investigation'
              | translate
          }}</b></span
        >
      </ng-container>

      <div *ngIf="!data?.withInformation" class="seed-tabs">
        <mat-tab-group class="features-tabs">
          <mat-tab
            label="{{ 'Targets' | translate }} {{
              selectedSeedQueue?.targets?.length
                ? '(' + selectedSeedQueue.targets.length + ')'
                : ''
            }}"
          >
            <div class="input-style-round searchInput">
              <input
                matInput
                type="text"
                id="searchText"
                placeholder="{{ 'Search name, MSISDNs or cases' | translate }}"
                maxlength="30"
                formControlName="targetFilter"
              />
            </div>
            <div class="seed-list" *ngIf="(targetsList$ | async)?.length">
              <div
                class="seed-item row"
                *ngFor="let target of targetsList$ | async"
              >
                <div>
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectTargetItem($event.checked, target)"
                    [(ngModel)]="target.selected"
                    [ngModelOptions]="{ standalone: true }"
                  >
                  </mat-checkbox>
                </div>
                <div class="profile-img">
                  <app-target-img
                    [imageUrl]="target.photos?.length ? target.photos[0] : ''"
                    [fullName]="
                      target?.names?.length ? target.names[0] : target.alias
                    "
                  ></app-target-img>
                </div>
                <div class="seed-details">
                  <span>{{ target.alias }}</span>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab
            label="{{ 'Cases' | translate }} {{
              selectedSeedQueue?.cases?.length
                ? '(' + selectedSeedQueue.cases.length + ')'
                : ''
            }}"
          >
            <div class="input-style-round searchInput">
              <input
                matInput
                type="text"
                id="searchText"
                placeholder="{{ 'Search name, MSISDNs or cases' | translate }}"
                maxlength="30"
                formControlName="caseFilter"
              />
            </div>
            <div class="seed-list" *ngIf="(casesList$ | async)?.length">
              <div
                class="seed-item row"
                *ngFor="let case of casesList$ | async"
              >
                <div>
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectCaseItem($event.checked, case)"
                    [(ngModel)]="case.selected"
                    [ngModelOptions]="{ standalone: true }"
                  >
                  </mat-checkbox>
                </div>
                <div class="profile-img">
                  <app-target-img
                    [imageUrl]="case?.photos?.length ? case.photos[0] : ''"
                    [fullName]="case.caseName"
                  >
                  </app-target-img>
                </div>
                <div class="seed-details">
                  <span>{{ case.caseName }}</span>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab
            label="{{ 'MSISDNs' | translate }} {{
              selectedSeedQueue?.msisdns?.length
                ? '(' + selectedSeedQueue.msisdns.length + ')'
                : ''
            }}"
          >
            <div class="input-style-round searchInput">
              <input
                matInput
                type="text"
                id="searchText"
                placeholder="{{ 'Search MSISDNs' | translate }}"
                maxlength="30"
                formControlName="msisdnsFilter"
              />
            </div>
            <div class="seed-list" *ngIf="(msisdnsList$ | async)?.length">
              <div
                class="seed-item row"
                *ngFor="let msisdn of msisdnsList$ | async"
              >
                <div>
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectMsisdnItem($event.checked, msisdn)"
                    [(ngModel)]="msisdn.selected"
                    [ngModelOptions]="{ standalone: true }"
                  >
                  </mat-checkbox>
                </div>
                <div class="profile-img">
                  <app-target-img
                    [imageUrl]="''"
                    [fullName]="msisdn.msisdns[0]"
                  >
                  </app-target-img>
                </div>
                <div class="seed-details">
                  <span>{{ msisdn.msisdns[0] }}</span>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'Selected queue' | translate }}">
            <div *ngIf="selectedSeedQueue?.targets?.length">
              <div class="m-1 ubuntu-font">Targets:</div>
              <div class="seed-list">
                <div
                  class="seed-item row"
                  *ngFor="let target of selectedSeedQueue.targets"
                >
                  <div class="profile-img">
                    <app-target-img
                      [imageUrl]="target.photos?.length ? target.photos[0] : ''"
                      [fullName]="
                        target?.names?.length ? target.names[0] : target.alias
                      "
                    ></app-target-img>
                  </div>
                  <div class="seed-details">
                    <span>{{ target.alias }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedSeedQueue?.cases?.length">
              <div class="m-1 ubuntu-font">Cases:</div>
              <div class="seed-list">
                <div
                  class="seed-item row"
                  *ngFor="let case of selectedSeedQueue.cases"
                >
                  <div class="profile-img">
                    <app-target-img
                      [imageUrl]="case?.photos?.length ? case.photos[0] : ''"
                      [fullName]="case.caseName"
                    >
                    </app-target-img>
                  </div>
                  <div class="seed-details">
                    <span>{{ case.caseName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </form>
  </div>

  <div footer class="row action-btn">
    <div class="col-12 end-xs btn-wrp">
      <button mat-button class="cp-btn-ghost" (click)="close()">
        {{ 'Cancel' | translate }}
      </button>
      <button
        mat-button
        class="cp-btn-primary"
        type="submit"
        [disabled]="!createInvestigationForm.valid"
        (click)="createInvestigation()"
      >
        {{ 'Create case investigation' | translate }}
      </button>
    </div>
  </div>
</app-base-modal>
