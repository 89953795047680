<app-base-modal *ngIf="userService.currentUser$ | async; let currentUser">
  <div
    *ngIf="
      (state === OTPDialogStates.ActivateCode ||
        state === OTPDialogStates.ActivateQR ||
        state === OTPDialogStates.PasswordScreenChangeMethod) &&
        data.isPasswordChange;
      else activateDeactivateHeader
    "
    header
  >
    {{ 'Change OTP method' | translate }}
  </div>
  <ng-template #activateDeactivateHeader>
    <div header>
      {{
        data.isActivated
          ? ('Activate 2-step verification' | translate)
          : ('Deactivate 2-step verification' | translate)
      }}
    </div>
  </ng-template>
  <div body class="modal-body">
    <ng-container
      *ngIf="
        state === OTPDialogStates.PasswordScreen ||
        state === OTPDialogStates.PasswordScreenChangeMethod
      "
    >
      <p class="body-desc">
        {{
          "A second step after entering your password verifies it's you signing in."
            | translate
        }}
      </p>
      <div class="form-wrp">
        <div class="field-group">
          <label>{{ 'Password' | translate }}</label>
          <div class="input-style-round relative">
            <input
              class="assistant-font"
              matInput
              [formControl]="passwordControl"
              [type]="visibility ? 'text' : 'password'"
              required
            />

            <mat-icon
              class="icon eye-icon"
              (click)="this.visibility = !this.visibility"
            >
              {{ visibility ? 'visibility_off' : 'visibility' }}</mat-icon
            >
          </div>
        </div>
        <mat-error class="activate-otp-modal-error" *ngIf="errorMsg">{{
          errorMsg | translate
        }}</mat-error>
      </div>
    </ng-container>

    <ng-container *ngIf="state === OTPDialogStates.OTPSelectionScreen">
      <app-otp-selection-radio
        (channelChange)="onChannelChanged($event)"
        (phoneChange)="onPhoneChanged($event)"
        (emailChange)="onEmailChange($event)"
      ></app-otp-selection-radio>
    </ng-container>

    <ng-container *ngIf="state === OTPDialogStates.ActivateCode">
      <app-activate-otp-code-input
        [otpChannel]="otpChannel"
        [showMessageWithOldChannel]="data.isPasswordChange"
        [oldChannel]="data.user.otpChannel"
        [errorMsg]="errorMsg"
        (otpControlChanged)="onOTPCodeChanged($event)"
      ></app-activate-otp-code-input>
    </ng-container>

    <ng-container *ngIf="state === OTPDialogStates.ActivateQR">
      <app-activate-qr-otp-code-input
        [errorMsg]="errorMsg"
        [oldChannel]="data.user.otpChannel"
        [showMessageWithOldChannel]="data.isPasswordChange"
        [isPartOfUserSettings]="true"
        (otpControlChanged)="onOTPCodeChanged($event)"
      ></app-activate-qr-otp-code-input>
    </ng-container>
  </div>
  <div footer class="modal-footer">
    <ng-container *ngIf="state === OTPDialogStates.PasswordScreenChangeMethod">
      <button
        *ngIf="data.isActivated"
        class="cp-btn-primary"
        mat-button
        [disabled]="!passwordControl.valid"
        (click)="navigateStates()"
      >
        {{ 'Continue' | translate }}
      </button>
    </ng-container>
    <ng-container
      *ngIf="
        state === OTPDialogStates.PasswordScreen ||
        state === OTPDialogStates.OTPSelectionScreen
      "
    >
      <button
        *ngIf="data.isActivated"
        class="cp-btn-primary"
        mat-button
        [disabled]="
          (state === OTPDialogStates.PasswordScreen &&
            !passwordControl.valid) ||
          (state === OTPDialogStates.OTPSelectionScreen &&
            otpChannel === channels.None) ||
          (state === OTPDialogStates.OTPSelectionScreen &&
            otpChannel === channels.Email &&
            !(emailControlValue.valid || currentUser.email)) ||
          (state === OTPDialogStates.OTPSelectionScreen &&
            otpChannel === channels.OtpPhone &&
            !(phoneControlValue.valid || currentUser.otpPhone)) ||
          isLoading
        "
        (click)="navigateStates()"
      >
        {{ 'Continue' | translate }}
      </button>
      <button
        *ngIf="!data.isActivated"
        class="cp-btn-primary"
        mat-button
        [disabled]="!passwordControl.valid || isLoading"
        (click)="deactivateOTP()"
      >
        {{ 'Deactivate' | translate }}
      </button>
    </ng-container>
    <ng-container
      *ngIf="
        state === OTPDialogStates.ActivateCode ||
        state === OTPDialogStates.ActivateQR
      "
    >
      <button
        class="cp-btn-primary"
        mat-button
        [disabled]="!otpControlValue.valid || isLoading"
        (click)="activateOTP()"
      >
        {{
          data.isPasswordChange
            ? ('Save' | translate)
            : ('Activate' | translate)
        }}
      </button>
    </ng-container>
  </div>
</app-base-modal>
