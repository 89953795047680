import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControlStatus,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MobileActivateOtpModalComponent } from 'src/app/components/navbar/mobile-user-settings/mobile-otp-section/mobile-activate-otp-modal/mobile-activate-otp-modal.component';
import { AuthDataService } from 'src/app/services/authentication/auth-data.service';
import { BaseDialogComponent } from 'src/app/shared/classes/base-dialog.component';
import {
  InputStatus,
  OTPChannels,
  OTPCodeRequestActivateCode,
  OTPCodeRequestDeactivate,
  OTPDialogState,
  OTPModalsData,
} from '../../user-settings.models';
import { AuthResponse } from './../../../../services/authentication/dto';
import { User } from './../../../../services/user/user.model';
import { UserService } from './../../../../services/user/user.service';
import {
  OTPCodeRequest,
  OTPCodeRequestChangeState,
  OTPCodeRequestHandlePasswordValidity,
} from './../../user-settings.models';

@Component({
  selector: 'app-activate-otp-modal',
  templateUrl: './activate-otp-modal.component.html',
  styleUrls: ['./activate-otp-modal.component.scss'],
})
export class ActivateOtpModalComponent
  extends BaseDialogComponent
  implements OnInit
{
  visibility = false;
  state: OTPDialogState;
  channels = OTPChannels;
  OTPDialogStates = OTPDialogState;
  showPhoneInput = false;
  showEmailInput = false;
  passwordControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  otpChannel: OTPChannels;
  errorMsg: string;
  isLoading = false;
  statuses: FormControlStatus;
  otpControlValue: InputStatus = { value: '', valid: false };
  phoneControlValue: InputStatus = { value: '', valid: false };
  emailControlValue: InputStatus = { value: '', valid: false };

  constructor(
    public userService: UserService,
    protected authService: AuthDataService,
    protected dialogRef: MatDialogRef<
      MobileActivateOtpModalComponent | ActivateOtpModalComponent
    >,
    @Inject(MAT_DIALOG_DATA)
    protected data: OTPModalsData
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    if (this.data.state) {
      this.state = this.data.state;
      this.otpChannel = this.data.channel || OTPChannels.None;
    }
  }

  navigateStates(): void {
    switch (this.state) {
      case OTPDialogState.PasswordScreen:
        this.handlePasswordState();
        break;
      case OTPDialogState.OTPSelectionScreen:
        this.handleOTPSelectionState();
        break;
      case OTPDialogState.PasswordScreenChangeMethod:
        this.handleOTPChangeState();
        break;
      default:
        return;
    }
  }

  activateOTP(): void {
    const activateOTPParams: OTPCodeRequestActivateCode = {
      otp_status: true,
      password: this.passwordControl.value,
      otp_code: this.otpControlValue.value,
      otp_channel: this.otpChannel,
    };

    this.sendCode(activateOTPParams, this.state);
  }

  onChannelChanged($event: OTPChannels): void {
    this.otpChannel = $event;
  }

  onOTPCodeChanged($event: InputStatus): void {
    this.otpControlValue = $event;
  }

  onPhoneChanged($event: InputStatus): void {
    this.phoneControlValue = $event;
  }

  onEmailChange($event: InputStatus): void {
    this.emailControlValue = $event;
  }

  deactivateOTP(): void {
    const deactivateOTPParams: OTPCodeRequestDeactivate = {
      otp_status: false,
      password: this.passwordControl.value,
    };

    this.sendCode(deactivateOTPParams, this.state);
  }

  checkIfUserNeedsUpdating(
    sendCodeFunction: (
      requestParams: OTPCodeRequest,
      state: OTPDialogState
    ) => void
  ): void {
    const activateOTPParams: OTPCodeRequestActivateCode = {
      password: this.passwordControl.value,
      otp_channel: this.otpChannel,
      otp_status: true,
    };

    const userParams: Partial<User> = {};

    if (
      this.phoneControlValue.valid &&
      !this.data.user.otpPhone &&
      this.otpChannel === OTPChannels.OtpPhone
    ) {
      userParams.otpPhone = this.phoneControlValue.value;
    }
    if (
      this.emailControlValue.valid &&
      !this.data.user.email &&
      this.otpChannel === OTPChannels.Email
    ) {
      userParams.email = this.emailControlValue.value;
    }

    if (Object.keys(userParams).length) {
      this.userService
        .editUser(this.userService.currentUser$.value, userParams, true)
        .subscribe((user: User) => {
          sendCodeFunction(activateOTPParams, OTPDialogState.ActivateCode);
        });
    } else {
      sendCodeFunction(activateOTPParams, OTPDialogState.ActivateCode);
    }
  }

  private handlePasswordState(): void {
    if (this.passwordControl.valid) {
      const checkPasswordParam: OTPCodeRequestHandlePasswordValidity = {
        password: this.passwordControl.value,
      };

      this.sendCode(checkPasswordParam, OTPDialogState.OTPSelectionScreen);
    }
  }

  private handleOTPSelectionState(): void {
    if (this.otpChannel === OTPChannels.App) {
      this.state = OTPDialogState.ActivateQR;
    } else if (
      this.otpChannel === OTPChannels.Email ||
      this.otpChannel === OTPChannels.OtpPhone
    ) {
      this.checkIfUserNeedsUpdating(this.sendCode.bind(this));
    }
  }

  private handleOTPChangeState(): void {
    const changeChannelsParams: OTPCodeRequestChangeState = {
      password: this.passwordControl.value,
      otp_channel: OTPChannels.None,
    };
    if (this.otpChannel === OTPChannels.Email) {
      changeChannelsParams.otp_channel = OTPChannels.Email;
      this.sendCode(changeChannelsParams, OTPDialogState.ActivateCode);
    } else if (this.otpChannel === OTPChannels.OtpPhone) {
      changeChannelsParams.otp_channel = OTPChannels.OtpPhone;
      this.sendCode(changeChannelsParams, OTPDialogState.ActivateCode);
    } else {
      changeChannelsParams.otp_channel = OTPChannels.App;
      this.sendCode(changeChannelsParams, OTPDialogState.ActivateQR);
    }
  }

  private sendCode(
    sendCodeRequest: OTPCodeRequest,
    state: OTPDialogState
  ): void {
    this.isLoading = true;

    this.authService.sendCode(sendCodeRequest).subscribe(
      (result: { status: string } | AuthResponse) => {
        this.errorMsg = '';
        this.isLoading = false;

        if (
          this.state === OTPDialogState.ActivateCode ||
          this.state === OTPDialogState.ActivateQR
        ) {
          if (!(result as AuthResponse).otp_code_required) {
            this.dialogRef.close(true);
          } else {
            this.errorMsg = (result as AuthResponse).message;
          }
        }

        if (!this.data.isActivated) {
          if ((result as { status: string }).status) {
            this.dialogRef.close(true);
          }
        }

        this.state = state;
      },
      (error: AuthResponse) => {
        this.isLoading = false;
        this.errorMsg = error.message;
      }
    );
  }
}
