<div
  class="box social-tooltip-wrapper"
  *ngIf="entityEventSocialPost"
  [ngStyle]="{ backgroundColor: entityEventSocialPost.color }"
>
  <div class="social-tooltip">
    <div class="social-tooltip-header">
      <div class="social-icon">
        <span class="icon {{ entityEventSocialPost.platform }}"></span>
        {{ entityEventSocialPost.platform | humanize }}
      </div>
      <div *ngIf="entityEventSocialPost.date">
        {{ entityEventSocialPost.date }}
      </div>
    </div>
    <div class="social-tooltip-content">
      <div class="post-content-parent">
        <div class="post-content-parent-image">
          <img
            loading="lazy"
            [src]="
              entityEventSocialPost.ownerImage ||
              'assets/static/images/search-intel/no-avatar-user.svg'
            "
          />
        </div>
        <div>
          <h3>
            {{ entityEventSocialPost.owner }}
            <span>{{ entityEventSocialPost.action }} </span>
          </h3>
        </div>
      </div>
      <div
        class="post-content"
        [ngClass]="entityEventSocialPost.text ? 'text' : 'no-text'"
      >
        <p *ngIf="entityEventSocialPost.text">
          {{ entityEventSocialPost.text }}
        </p>
        <div class="post-image" *ngIf="entityEventSocialPost.image">
          <img loading="lazy" [src]="entityEventSocialPost.image" />
        </div>
      </div>
    </div>
    <div class="social-tooltip-footer" *ngIf="entityEventSocialPost.url">
      <div class="footer">
        <button class="tooltip-button">
          <a (click)="showItem()">
            {{ 'View post' | translate }}
          </a>
        </button>
      </div>
    </div>
  </div>
</div>
