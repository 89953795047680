<button
  class="map-btn"
  (click)="click()"
  [class.discovery-logo]="icon === 'discovery-logo.svg'"
>
  <img
    src="assets/static/images/{{ icon }}"
    width="37"
    height="37"
    [title]="title"
  />
</button>
