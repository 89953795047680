<div
  class="bullets-container"
  *ngIf="creditDetailsService.getCreditUsageBullets() | async as bullets"
>
  <div class="bullet-row" *ngFor="let bullet of bullets">
    <span
      class="bullet"
      [ngStyle]="{ 'background-color': bullet.color }"
    ></span>
    <div class="label">
      {{ bullet.title | translate }} <br />
      <span
        >{{ bullet.value | number: '1.1' }} {{ 'credits' | translate }}</span
      >
    </div>
  </div>
</div>
