import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ComponentType } from 'ngx-toastr';
import { DrawerOptions, DrawerState } from './drawer.model';
import { Observable, Subject } from 'rxjs';
import { FontAwesomeIcon } from '../icons/fa-icon.interface';
import { FontAwesomeFamily } from '../icons/fa-icon-family.enum';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'intellectus-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements AfterViewInit {
  expanded$: Subject<boolean> = new Subject<boolean>();
  closed$: Subject<void> = new Subject<void>();
  stateToggled$: Subject<DrawerState> = new Subject<DrawerState>();
  component: ComponentType<unknown> | undefined;
  options: DrawerOptions | undefined;

  readonly closeIcon: FontAwesomeIcon = {
    name: 'xmark',
    family: FontAwesomeFamily.SOLID,
  };
  readonly expandIcon: FontAwesomeIcon = {
    name: 'up-right-and-down-left-from-center',
    family: FontAwesomeFamily.SOLID,
  };
  readonly minimizeIcon: FontAwesomeIcon = {
    name: 'window-minimize',
    family: FontAwesomeFamily.REGULAR,
  };

  @ViewChild('container', { read: ViewContainerRef }) container:
    | ViewContainerRef
    | undefined;
  state = DrawerState.DEFAULT;
  States = DrawerState;

  public readonly isMobile$: Observable<boolean> = this.layoutService.isMobile$;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly layoutService: LayoutService
  ) {}

  ngAfterViewInit(): void {
    if (this.component) {
      this.container?.createComponent(this.component);
    }
    if (this.options?.state) {
      this.state = this.options.state;
    }
    this.changeDetector.detectChanges();
  }

  toggleState() {
    this.state =
      this.state === this.States.MINIMIZED
        ? this.States.DEFAULT
        : this.States.MINIMIZED;
    this.stateToggled$.next(this.state);
  }

  expand() {
    this.expanded$.next(true);
  }

  close() {
    this.closed$.next();
  }
}
