import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { DataSource } from 'datalayer/models/platform-models/enums/data.source';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { Message } from 'src/app/services/websocket/websocket.class';
import { Case } from 'src/app/shared/models/case.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { environment } from 'src/environments/environment';

enum FastApiDataSource {
  opensearch = 'OpenSearch',
  socialsearch = 'SocialSearch',
  facebook = 'Facebook',
  instagram = 'Instagram',
  twitter = 'Twitter',
  linkedin = 'LinkedIn',
  whatsapp = 'WhatsApp',
  telegram = 'Telegram',
  skype = 'Skype',
  truecaller = 'Truecaller',
  tinder = 'Tinder',
  darkweb = 'Darkweb',
  person = 'SocialSearch',
  youtube = 'Youtube',
  dea = 'Dea',
  FBI = 'FBI',
  INE = 'INE',
  interpol = 'INTERPOL',
  viber = 'Viber',
  wechat = 'Wechat',
  tiktok = 'Tiktok',
  dbsearch = 'DBSearch',
  database = 'DBSearch',
  callerid = 'CallerID',
  getcontact = 'GetContact',
  somedus = 'Somedus',
  google = 'Google',
  'indo-db' = 'IndoDB',
}

@Injectable({
  providedIn: 'root',
})
export class RefreshLocalCacheService {
  public refreshData: Subject<boolean> = new Subject<boolean>();
  private refreshObservables: { [key: string]: Subject<DataSource> } = {};

  constructor(private http: HttpClient) {
    Object.values(FastApiDataSource).forEach((value: string) => {
      const dataLayerSourceKey = DataSource[value];
      this.refreshObservables[dataLayerSourceKey] = new Subject<DataSource>();
    });
  }

  public timerEnds: Subject<string> = new Subject<string>();

  public getSearchEntities(caseObject: Case): Observable<{ case: Case }> {
    return new Observable((observer) => {
      const targetIds = caseObject.assignedTargets.map(
        (target: TargetItem) => target.id
      );
      this.http
        .post(`${environment.serverAPIUri}/search-entities`, { targetIds })
        .subscribe(
          (res: { result: { target_id: string; created_at: string }[] }) => {
            const data = res.result;
            data.forEach((e) => {
              const searchDate = moment(e.created_at);
              caseObject.assignedTargets.forEach((target) => {
                if (target.id === e.target_id) {
                  target['osintStartTime'] = searchDate.valueOf();
                }
              });
            });
            observer.next({
              case: caseObject,
            });
            observer.complete();
          }
        );
    });
  }

  public refreshSource(msg: Message): void {
    if (msg.body.status === JobStatus.DONE) {
      Object.values(DataSource).forEach((source) => {
        this.emitRefreshObservables(source);
      });
    }
  }

  public getRefreshObservables(keys: DataSource[]): {
    [key: string]: Subject<DataSource>;
  } {
    return keys.reduce((acc, key: DataSource) => {
      acc[key] = this.refreshObservables[key];
      return acc;
    }, {});
  }

  public emitRefreshObservables(dataSourcePlatform: DataSource): void {
    const observable = this.refreshObservables[dataSourcePlatform];
    if (observable) {
      this.refreshObservables[dataSourcePlatform].next(dataSourcePlatform);
    }
  }
}
