import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { CallLogRequestsService } from 'src/app/modules/call-logs/services/call-log-requests.service';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { EmailTemplateService } from 'src/app/services/email-template.service';
import { RedirectSnackBarService } from 'src/app/services/snack-bar.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Action } from 'src/app/shared/classes/action.class';
import { RequestClPayload } from 'src/app/shared/models/call-log-request.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ActionService } from 'src/app/shared/services/action.service';
import { BaseComponent } from '../../../../shared/classes/base.component';
import { AnalysisActionsListModel } from '../models/analysis-actions.model';
import { AnalysisService } from './analysis.service';
import { TargetEnhanceService } from './target-enhance.service';
import { allowCallLogsFromThirdParty } from '../../shared/models/cl-request-from-third-party';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TableActionsUtilService extends BaseComponent {
  constructor(
    private translationService: TranslationService,
    private trgFeatureService: AnalysisService,
    private localStorageService: LocalStorageService,
    private callLogRequestsService: CallLogRequestsService,
    private actionService: ActionService,
    protected router: Router,
    private profilerService: ProfilerService,
    private targetService: TargetService,
    private targetEnhanceService: TargetEnhanceService,
    private emailTemplateService: EmailTemplateService,
    private redirectSnackBarService: RedirectSnackBarService
  ) {
    super();
  }

  requestCallLog(requestCl: RequestClPayload) {
    this.trgFeatureService
      .requestCallLog({ ...requestCl.typeAndValue, period: requestCl.period })
      .pipe(
        switchMap((clRequest) => {
          return allowCallLogsFromThirdParty(requestCl.countryCode)
            ? of({})
            : this.sendEmail(requestCl, clRequest.id);
        })
      )
      .subscribe(
        () => {
          this.showMessage(
            this.translationService.translate(`Request sent successfully!`)
          );
          this.actionService.publishAction(
            new Action({
              key: AnalysisActionsListModel.REFRESH_DATA,
              data: { disableCache: true },
            })
          );
        },
        () => {
          this.showMessage(
            this.translationService.translate('Request has not been sent')
          );
        }
      );
  }

  private sendEmail(requestCl: RequestClPayload, requestId: string) {
    return this.callLogRequestsService.sendCallLogRequestToSupport(
      this.emailTemplateService.callLogsRequestEmailFormat(requestCl, {
        appUrl: `call-log-analysis/${requestId}/`,
        alias: undefined,
      }),
      requestCl.typeAndValue.msisdn
    );
  }

  advancedOsintForMsisdn(msisdns: string[]) {
    this.profilerService.targetData.next(null);
    const newTarget: TargetItem = {
      alias: msisdns[0],
      telnos: msisdns,
      user: this.localStorageService.getCurrentUser().identity,
    };

    this.targetService
      .createTargetProfiler(newTarget, {
        createCase: false,
        addImProfiles: true,
      })
      .subscribe(
        (target: TargetItem) => {
          this.targetEnhanceService.advancedOsintForTarget(false, target);
        },
        (error: any) => {
          const errorMessage = error?.messages;

          if (
            this.redirectSnackBarService.shouldShowRedirectSnackBar(
              errorMessage
            )
          ) {
            this.redirectSnackBarService.showRedirectSnackBar(errorMessage);
          } else {
            this.showMessage(
              this.translationService.translate(
                error.messages ? error.messages : 'Target has not been created'
              )
            );
          }
        }
      );
  }
}
