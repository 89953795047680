import { EntityType } from '@trg-commons/data-models-ts';
import { DataGroupingId } from '@trg-commons/gio-data-models-ts';
import { Platform } from 'src/app/shared/schemas/common/platforms';

export interface OsintQueryEvent {
  operationRequest: OsintQueryEventOperationRequest;
}

export interface OsintQueryEventOperationRequest {
  status: string;
  type: string;
  correlationId: string;
  dataGroupingId: {
    id: string;
    requestId?: string;
  };
  body: OsintQueryBody;
}

export interface OsintQueryBody {
  params: {
    queryParam: {
      userId: string;
    };
    queryRoute: string;
  };
}
export interface OsintQueryGraphRequest {
  entityId: string;
  relationEntityTypeFrom: EntityType;
  relationEntityTypeTo: EntityType;
  relationshipType: string;
  source: Platform;
  depth: number;
}

export const GraphRouteToEntityType = {
  ['posts']: EntityType.Post,
};

export interface DataGrouping extends DataGroupingId {
  investigationId?: string;
}

export enum IntelEntityType {
  Posts = 'posts',
}
