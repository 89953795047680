<div class="paginator-wrapper" intellectusIsMobile>
  <mat-paginator
    #matPaginator
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
    [length]="length"
    showFirstLastButtons
  ></mat-paginator>
</div>
