import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';
import { BaseCacheService } from './base-cache.service';
import { Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PendingHttpCacheService extends BaseCacheService<
  Subscriber<HttpEvent<unknown>>[]
> {
  constructor() {
    super();

    this.initialize();
  }
}
