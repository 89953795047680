import {
  BaseService,
  EmptyCacheService,
  ModelStore,
  RequestOptions,
} from 'datalayer/services/base';
import { Education } from 'datalayer/models/social/education';
import { EducationApiService } from 'src/app/modules/data-layer/services/social/education/education-api.service';
import { Injectable } from '@angular/core';
import { EducationDTO } from 'src/app/modules/data-layer/services/social/education/education-dto';
import { Observable } from 'rxjs';
import { Company } from 'datalayer/models';

@Injectable({
  providedIn: 'root',
})
export class EducationService extends BaseService<
  Education,
  EducationDTO,
  EducationApiService,
  EmptyCacheService<Education>
> {
  constructor(
    apiService: EducationApiService,
    localCacheService: EmptyCacheService<Education>
  ) {
    super(apiService, localCacheService);
  }

  getAll(options?: RequestOptions): Observable<ModelStore<Company>> {
    options.filters = {
      sort: 'started_at',
      sortBy: -1,
      ...options.filters,
    };
    return super.getAll(options);
  }
}
