import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { SearchIntelFields } from '../../models/search-intel-fields.enum';
import { sortStatuses } from '../../../services/intel-search-tracker/intel-search-tracker.service';
import { TranslationService } from '../../../services/translation/translation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-intel-search-drawer-content',
  templateUrl: './intel-search-drawer-content.component.html',
  styleUrls: ['./intel-search-drawer-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntelSearchDrawerContentComponent {
  @Input('statuses')
  public set setSearchState(statuses: Record<string, boolean>) {
    this.statuses = sortStatuses(statuses);
  }

  public statuses: Array<[string, boolean]>;

  protected readonly searchIntelFields: typeof SearchIntelFields =
    SearchIntelFields;

  constructor(
    private readonly translationService: TranslationService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.translationService.languageChange
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cdRef.markForCheck());
  }
}
