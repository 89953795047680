import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber',
})
export class ShortNumberPipe implements PipeTransform {
  transform(value: number, precision = 1) {
    if (isNaN(value)) {
      return value;
    }
    if (value >= 1000 && value < 1000000) {
      return (value / 1000).toFixed(precision) + 'k';
    }
    if (value >= 1000000 && value < 1000000000) {
      return (value / 1000000).toFixed(precision) + 'm';
    }
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(precision) + 'b';
    }
    return value.toString();
  }
}
