<div
  class="no-notifications d-flex flex-column justify-content-center align-items-center"
  *ngIf="totalNotificationsCount === 0"
>
  <img
    src="assets/static/images/notification_bell.svg"
    height="120"
    width="120"
  />

  <span class="no-notifications-title">{{
    'No notifications' | translate | uppercase
  }}</span>

  <span class="no-notifications-subtitle">{{
    'We will notify you right after the next will arive.' | translate
  }}</span>
</div>

<div
  *ngIf="totalNotificationsCount > 0"
  class="notifications-list"
  [ngClass]="{ mobile: isMobileResolution }"
>
  <app-notification-item
    *ngFor="let sortedNotification of sortedNotifications$ | async | all"
    [notification]="sortedNotification"
  >
  </app-notification-item>
</div>

<div class="row notification-footer" [ngClass]="{ mobile: isMobileResolution }">
  <span
    class="notification-footer-action"
    [ngClass]="{ 'disable-link': !notificationsCount }"
    (click)="markAllRead()"
    >{{ 'Mark all as read' | translate }}</span
  >
  <span
    class="notification-footer-action"
    angulartics2On="click"
    [angularticsAction]="matomo.actions.clearNotifications"
    [angularticsCategory]="matomo.categories.navBar"
    [ngClass]="{ 'disable-link': !totalNotificationsCount }"
    (click)="clearNotifications()"
    >{{ 'Clear all' | translate }}</span
  >
</div>
