<div class="tooltip-container">
  <div class="tooltip-header">
    <intellectus-platform-icon
      class="icon"
      [platform]="checkin.platform"
    ></intellectus-platform-icon>
    <span class="platform-title"> {{ checkin.platform }} </span>
    {{ 'check in' | translate }}
  </div>
  <div class="tooltip-content">
    <div class="info-container">
      <div class="image-resource" *ngIf="targetImageThumbnailUrl">
        <img loading="lazy" [src]="targetImageThumbnailUrl" />
      </div>
      <h3>{{ target.names[0] || target.alias || target.id }}</h3>
      <span>{{ 'is in' | translate }} {{ checkin.name }}</span>
    </div>
    <div
      class="location-info"
      [ngClass]="{ 'with-image': targetImageThumbnailUrl }"
    >
      <span>{{ checkin.image?.description }}</span>
      <div class="thumb-container" *ngIf="checkinImageThumbnailUrl">
        <img [src]="checkinImageThumbnailUrl" />
      </div>
    </div>
  </div>
  <div class="tooltip-footer" *ngIf="checkin.url">
    <a class="tooltip-button" [href]="checkin.url" target="_blank">
      {{ 'View place' | translate }}</a
    >
  </div>
</div>
