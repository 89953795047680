<app-core-toastr [class.open]="!isHidden">
  <app-core-toastr-header (close)="removeGroup()">
    <div class="target-toastr__title font-medium flex-wrp">
      <span>SOMEDUS</span>
      <span
        class="target-alias-name"
        *ngIf="target$ | async as target"
        (click)="navigateToTarget(target)"
        >{{ target.alias }}</span
      >
    </div>
    <mat-icon class="arrow" (click)="isHidden = !isHidden">play_arrow</mat-icon>
  </app-core-toastr-header>

  <app-core-toastr-body [class.hidden]="isHidden">
    <div class="target-toastr__toasts">
      <ng-container>
        <div
          class="target-toastr__toast-item flex-wrp"
          *ngFor="let toast of notificationsList"
        >
          <ng-container
            *ngIf="
              toast.status === 'PENDING' ||
              toast.status === 'REISSUED' ||
              toast.status === 'UPLOADING'
            "
          >
            <div class="target-toastr__source">
              {{ toast.platform }}
            </div>
            <div class="target-toastr__progress">
              <mat-progress-bar
                mode="indeterminate"
                color="accent"
              ></mat-progress-bar>
            </div>
          </ng-container>
          <ng-container *ngIf="toast.status === 'DONE'">
            <div class="target-toastr__osint-done flex-vert-center">
              <mat-icon class="target-toastr__done-icon">check_circle</mat-icon>
              <ng-container
                *ngIf="toast.job_type === 'UPLOADING'; else requestTempl"
              >
                {{ 'Target Enriched successfully' | translate }} &nbsp;
              </ng-container>
              <ng-template #requestTempl>
                {{ toast.platform | titlecase }}
                {{ 'Completed Successfully' | translate }} &nbsp;
              </ng-template>
              <a
                class="target-toastr__overview-link cursor-pointer"
                *ngIf="showTargetLink"
                (click)="goToTargetPage()"
                >{{ 'View' | translate }}</a
              >
            </div>
          </ng-container>
          <ng-container *ngIf="toast.status === 'EXPIRED'">
            <div
              class="target-toastr__osint-done flex-vert-center flex-space-between w-100"
            >
              <div class="flex-vert-center">
                <mat-icon class="target-toastr__done-icon"
                  >check_circle</mat-icon
                >
                {{ 'Request expired for' | translate }}
                {{ toast.platform | titlecase }}
              </div>
              <div>
                <mat-icon
                  data-title="{{ 'Abandon' | translate }}"
                  class="target-toastr__cancel-icon"
                  (click)="abandonRequest(toast.request_id)"
                  >cancel</mat-icon
                >
                <mat-icon
                  data-title="{{ 'Resubmit' | translate }}"
                  class="target-toastr__reissue-icon"
                  (click)="reissueRequest(toast.request_id)"
                  >settings_backup_restore</mat-icon
                >
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="toast.status === 'ABANDONED'">
            <div class="target-toastr__osint-done flex-vert-center">
              <mat-icon class="target-toastr__cancel-icon">cancel</mat-icon>
              {{ 'Failed to retrieve' | translate }}
              {{ toast.platform | titlecase }}
              {{ 'details' | translate }} &nbsp;
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </app-core-toastr-body>
</app-core-toastr>
