<form>
  <div class="global-select-label">{{ 'Select frequency' | translate }}</div>
  <mat-select class="global-select" [(value)]="selected">
    <mat-option
      *ngFor="let frequency of frequencies"
      (onSelectionChange)="selectedFrequency($event)"
      [value]="frequency.value"
      [class.disabled]="frequency.disabled"
    >
      {{ frequency.label }}
    </mat-option>
  </mat-select>
</form>
