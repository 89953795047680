<div [ngClass]="['content', size]" [class.inline-block]="inline">
  <ng-container [ngSwitch]="platform">
    <ng-container *ngSwitchDefault>
      <div class="icon {{ platform }}">
        <fe-platform-fa-pro-icon
          [family]="availableFaBrandIcons[platform]?.family"
          [name]="availableFaBrandIcons[platform]?.name"
        ></fe-platform-fa-pro-icon>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'google'">
      <div class="icon {{ platform }}"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'microsoft'">
      <div class="icon {{ platform }}"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'indo-db'">
      <div class="icon database"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'imei-to-msisdn'">
      <div class="icon database"></div>
    </ng-container>
  </ng-container>
</div>
