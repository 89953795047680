import { TranslationService } from 'src/app/services/translation/translation.service';
import { Injectable } from '@angular/core';
import { MarkerCollection } from './../../../modules/profiler-map/models/marker-collection.model';
import { LanguagesEnum } from '@report-service-ts/data-models';
import {
  TargetSummary,
  TargetSummaryInfo,
  TargetSummaryLocationData,
  TargetSummarySectionIdentifier,
  TargetSummarySocialNetworkAssociates,
  ThemeEnum,
} from '@report-service-ts/data-models';
import { DatePipe } from '@angular/common';
import { ClAssociate } from 'src/app/shared/modules/call-logs-shared/models/cl-associate';
import { ReportSectionImage } from 'src/app/shared/models/report-section-image.model';
import { Router } from '@angular/router';
import { TargetSummaryTopAssociates } from '@report-service-ts/data-models/dist/target-summary/target-top-associate.model';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ProfilerIconMarker } from '../../../shared/models/profiler-icon-marker';

@Injectable({
  providedIn: 'root',
})
export class TargetSummaryHelper {
  constructor(
    private router: Router,
    private translationService: TranslationService,
    private appConfigService: AppConfigService,
    private datePipe: DatePipe
  ) {}

  generateTargetSummary(
    targetSummaryInfo: TargetSummaryInfo,
    topAssociates: ClAssociate[],
    sectionImages: ReportSectionImage<TargetSummarySectionIdentifier>[],
    locationData: MarkerCollection,
    socialNetworkAssociates: TargetSummarySocialNetworkAssociates[],
    createdBy: string,
    note: string,
    filters?: { [key: string]: boolean }
  ): TargetSummary {
    // temporary type workaround until we remove unused themes and flavors
    const theme = (<unknown>(
      this.appConfigService.getConfigVariable('theme')
    )) as ThemeEnum;
    const partial: TargetSummary = new TargetSummary({
      lang: LanguagesEnum[this.appConfigService.getLanguage().toUpperCase()],
      title: 'Target summary report',
      theme,
      tenant: this.appConfigService.getConfigVariable('tenantId'),
      fileManagerPath:
        this.appConfigService.getConfigVariable('fileManagerUri'),
      createdDate: this.datePipe.transform(new Date(), 'dd/MM/yyyy H:mm'),
      createdBy: createdBy,
      qrCodeUrl: window.location.origin + this.router.url,
      target: targetSummaryInfo,
      sections: [],
      locationData: [],
    });
    if (note) {
      partial.note = note;
    }
    if (!filters || (filters && filters['socialNetworkTopAssociates'])) {
      if (socialNetworkAssociates) {
        partial.socialNetworkAssociates = socialNetworkAssociates.slice(0, 5);
      }
    }
    if (!filters || (filters && filters['callLogTopAssociates'])) {
      if (topAssociates) {
        partial.callTopAssociates = topAssociates.map((item) => {
          return new TargetSummaryTopAssociates({
            totalDuration: item.totalDuration ? item.totalDuration : 0,
            totalFrequency: item.totalFrequency ? item.totalFrequency : 0,
            name: item.names[0] || '',
            msisdn: [item.msisdn] || [],
          });
        });
      }
    }
    if (!filters || (filters && filters['locations'])) {
      if (locationData) {
        if (locationData.all && locationData.all.value.length > 0) {
          const lastSeenValue = locationData.all.value.splice(-1).pop();
          const lastSeen = new TargetSummaryLocationData({
            name: this.translationService.translate('Last Seen'),
            lat: lastSeenValue.lat,
            lng: lastSeenValue.lng,
            source: (lastSeenValue as ProfilerIconMarker).source.toLowerCase(),
            date: lastSeenValue.date,
          });
          partial.locationData = partial.locationData.concat([lastSeen]);
        }
      }
      const locationImage = sectionImages.find(
        (item) =>
          item.sectionIdentifier === TargetSummarySectionIdentifier.locations
      );
      if (locationImage) {
        partial.sections.push(locationImage);
      }
    }
    if (!filters || (filters && filters['socialWordCloud'])) {
      const socialWorldCloudmage = sectionImages.find(
        (item) =>
          item.sectionIdentifier ===
          TargetSummarySectionIdentifier.socialWordCloud
      );
      if (socialWorldCloudmage) {
        partial.sections.push(socialWorldCloudmage);
      }
    }
    if (!filters || (filters && filters['activityPatterns'])) {
      const activityPatternImage = sectionImages.find(
        (item) =>
          item.sectionIdentifier ===
          TargetSummarySectionIdentifier.activityPatterns
      );
      if (activityPatternImage) {
        partial.sections.push(activityPatternImage);
      }
    }
    if (!filters || (filters && filters['linkAnalysis'])) {
      const linkAnalysisImage = sectionImages.find(
        (item) =>
          item.sectionIdentifier === TargetSummarySectionIdentifier.linkAnalysis
      );
      if (linkAnalysisImage) {
        partial.sections.push(linkAnalysisImage);
      }
    }
    return new TargetSummary(partial);
  }
}
