<intellectus-modal [config]="config" (modalClose)="onClose.emit()">
  <app-platform-settings
    modal-body
    [isLocatingDisabled]="isLocatingDisabled"
    [accurateLocation]="accurateLocation"
    [siteSettings]="siteSettings"
    [availableExtractPeerActions]="availableExtractPeerActions"
    [language]="language"
    (onChangeAccurateLocation)="onChangeAccurateLocation.emit($event)"
    (onChangeExtractPeerAction)="onChangeExtractPeerAction.emit($event)"
    (onLanguageChange)="onLanguageChange.emit($event)"
  ></app-platform-settings>
</intellectus-modal>
