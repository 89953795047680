<div class="mobile-gauge" [class.distributed]="distributed">
  <ngx-gauge
    [type]="gaugeType"
    [value]="gaugeValue"
    [thick]="3"
    [size]="53"
    [cap]="'round'"
    [append]="gaugeAppendText"
    [foregroundColor]="foregroundColor"
    [backgroundColor]="backgroundColor"
  ></ngx-gauge>

  <p class="mobile-description" [class.unlimited-text]="isUnlimitedTheme">
    <ng-content></ng-content>
  </p>
</div>
