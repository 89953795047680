import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ModalConfig } from '@fe-platform/shared-ui/intellectus';
import { ExportPeerAction } from '@shared/components/extract-peer-dialog/extract-peer-dialog.component';
import { SiteSettings } from '@shared/models/site-settings.model';
import { Subscription } from 'rxjs';
import { PlatformSettingsOutputData } from 'src/app/components/navbar/platform-settings-modal/type';
import { TranslationService } from 'src/app/services/translation/translation.service';

@Component({
  selector: 'app-platform-settings-modal',
  templateUrl: './platform-settings-modal.component.html',
  styleUrls: ['./platform-settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformSettingsModalComponent implements OnDestroy {
  public config: ModalConfig = {
    title: this.translationService.translate('Settings'),
    centeredTitle: true,
  };

  @Input()
  public readonly isLocatingDisabled: boolean;

  @Input()
  public accurateLocation: boolean;

  @Input()
  public readonly siteSettings: SiteSettings;

  @Input()
  public readonly language: string;

  @Input()
  public readonly availableExtractPeerActions: Record<string, ExportPeerAction>;

  @Output()
  public readonly onClose: EventEmitter<PlatformSettingsOutputData> = new EventEmitter<PlatformSettingsOutputData>();

  @Output()
  public readonly onChangeAccurateLocation: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public readonly onChangeExtractPeerAction: EventEmitter<{
    value: boolean;
    action: ExportPeerAction;
  }> = new EventEmitter<{ value: boolean; action: ExportPeerAction }>();

  @Output()
  public readonly onLanguageChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

  private subscriptions: Subscription = new Subscription();

  constructor(protected readonly translationService: TranslationService) {
    this.createLanguageChangeSubscription();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private createLanguageChangeSubscription(): void {
    this.subscriptions.add(
      this.translationService.languageChange.subscribe((): void => {
        this.config = {
          ...this.config,
          title: this.translationService.translate('Settings'),
        };
      })
    );
  }
}
