<div
  class="email-confirmation-popup assistant-font"
  id="confirmationPopupResetPassword"
>
  <div class="dialog-header flex-wrp">
    <p class="title">{{ data.label }}</p>
    <mat-icon (click)="dialogRef.close()">close</mat-icon>
  </div>
  <div class="dialog-body">
    <p>{{ data.content }}</p>
  </div>

  <div class="btn-wrp">
    <button
      class="cp-btn-ghost"
      mat-button
      (click)="onRemoveEmail.emit($event)"
    >
      {{ data.cancelText }}
    </button>
    <button class="cp-btn-primary" mat-button (click)="onConfirm.emit($event)">
      {{ data.okText }}
    </button>
  </div>
</div>
