import { BaseDTO } from '../../services/base';
import { GeoQuery } from './geo-query';

export class GeoQueryDTO extends BaseDTO<GeoQuery, GeoQueryDTO> {
  private roaming: boolean;
  private age_of_location_in_minutes: unknown;
  private azimuth: unknown;
  private interception: unknown;
  private created_at: string;
  private id: string;
  private tag: unknown;
  private device: {
    network_status: string;
    imei: unknown;
    phone_model: string;
    software_info: unknown;
  };
  private support_active_query: boolean;
  private schedule: unknown;
  private updated_at: string;
  private query_args: {
    billing_id: unknown;
    imsi: unknown;
    status: unknown;
    telno: string;
    advanced_location_method: boolean;
  };
  private address: unknown;
  private gps: unknown;
  private status: string;
  private type: unknown;
  private accuracy_meters: number;
  private parent_id: unknown;
  private group: unknown;
  private provider: {
    mcc: string;
    subscription_status: string;
    imsi: unknown;
    mnc: string;
    name: string;
    telno: string;
    roaming_name: unknown;
  };
  private feature_type: unknown;
  private deleted_at: unknown;
  private last_known_location: boolean;
  private updated_by: unknown;
  private instant_message_profiles: unknown;
  private call_info: {
    billing_id: unknown;
    call_direction: string;
    oncall_number: string;
  };
  private cell: {
    cell_id: unknown;
    mcc: string;
    lac: unknown;
    zone: string;
    lcid: unknown;
    mnc: string;
    enbid: unknown;
    cell_type: unknown;
  };
  private location: unknown;
  private ip_address: unknown;
  private nmr: {
    billing_id: unknown;
    distance: unknown;
    trialteration_location: unknown;
    combas_bearing: unknown;
    trialteration_accuracy_meters: unknown;
    bearing_direction: unknown;
    sector_location: unknown;
    sector_size: unknown;
  };
  private do_not_process: unknown;
  private created_by: string;
  private request_id: unknown;
  private location_received_at: string;

  constructor(model: Partial<GeoQueryDTO>) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(): GeoQueryDTO {
    return this;
  }

  toModel(): GeoQuery {
    return new GeoQuery({
      roaming: this.roaming,
      ageOfLocationInMinutes: this.age_of_location_in_minutes,
      azimuth: this.azimuth,
      interception: this.interception,
      createdAt: this.created_at,
      id: this.id,
      tag: this.tag,
      device: {
        networkStatus: this.device.network_status,
        imei: this.device.imei,
        phoneModel: this.device.phone_model,
        softwareInfo: this.device.software_info,
      },
      supportActiveQuery: this.support_active_query,
      schedule: this.schedule,
      updatedAt: this.updated_at,
      queryArgs: {
        billingId: this.query_args.billing_id,
        imsi: this.query_args.imsi,
        status: this.query_args.status,
        telno: this.query_args.telno,
        advancedLocationMethod: this.query_args.advanced_location_method,
      },
      address: this.address,
      gps: this.gps,
      status: this.status,
      type: this.type,
      accuracyMeters: this.accuracy_meters,
      parentId: this.parent_id,
      group: this.group,
      provider: {
        mcc: this.provider.mcc,
        subscriptionStatus: this.provider.subscription_status,
        imsi: this.provider.imsi,
        mnc: this.provider.mnc,
        name: this.provider.name,
        telno: this.provider.telno,
        roamingName: this.provider.roaming_name,
      },
      featureType: this.feature_type,
      deletedAt: this.deleted_at,
      lastKnownLocation: this.last_known_location,
      updatedBy: this.updated_by,
      instantMessageProfiles: this.instant_message_profiles,
      callInfo: {
        billingId: this.call_info.billing_id,
        callDirection: this.call_info.call_direction,
        oncallNumber: this.call_info.oncall_number,
      },
      cell: {
        cellId: this.cell.cell_id,
        mcc: this.cell.mcc,
        lac: this.cell.lac,
        zone: this.cell.zone,
        lcid: this.cell.lcid,
        mnc: this.cell.mnc,
        enbid: this.cell.enbid,
        cellType: this.cell.cell_type,
      },
      location: this.location,
      ipAddress: this.ip_address,
      nmr: {
        billingId: this.nmr.billing_id,
        distance: this.nmr.distance,
        trialterationLocation: this.nmr.trialteration_location,
        combasBearing: this.nmr.combas_bearing,
        trialterationAccuracyMeters: this.nmr.trialteration_accuracy_meters,
        bearingDirection: this.nmr.bearing_direction,
        sectorLocation: this.nmr.sector_location,
        sectorSize: this.nmr.sector_size,
      },
      doNotProcess: this.do_not_process,
      createdBy: this.created_by,
      requestId: this.request_id,
      locationReceivedAt: this.location_received_at,
    });
  }
}
