<div class="upload-container">
  <ng-container *ngIf="!clUploadResults">
    <p class="mb-20">
      {{
        'Call logs should be uploaded as a single .csv or .xlsx file'
          | translate
      }}
      <br />
      <span class="red-font">{{ fileValidatorMessage }}</span>
    </p>
    <app-upload-file-selector
      (selectedFile)="onSelectedFile($event)"
    ></app-upload-file-selector>
    <ng-container *ngrxLet="clSchemas$ as schemas">
      <mat-form-field
        *ngIf="schemas && schemas?.length > 0"
        [formGroup]="schemaSelector"
      >
        <mat-label>CDR Format: </mat-label>
        <select matNativeControl formControlName="schema">
          <option selected value="">{{ 'Auto detect' | translate }}</option>
          <option *ngFor="let schema of schemas" [value]="schema.id">
            {{ schema.displayName }}
          </option>
        </select>
      </mat-form-field>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="clUploadResults">
    <ul>
      <li>
        <strong>{{ 'Total Parsed' | translate }}: </strong>
        {{ clUploadResults?.totalParsed }}
      </li>
      <li>
        <strong>{{ 'Successfully inserted' | translate }}: </strong
        >{{ clUploadResults?.successfullyInserted }}
      </li>
      <li>
        <strong>{{ 'Parsing errors' | translate }}: </strong
        >{{ clUploadResults?.parsingErrors?.length }}
      </li>
      <li class="text-ellipsis" *ngIf="clUploadResults?.parsingErrors?.length">
        <strong>{{ 'Parsing error lines' | translate }}: </strong
        >{{ clUploadResults?.parsingErrors }}
      </li>
      <li>
        <strong>{{ 'Duplicated' | translate }}: </strong
        >{{ clUploadResults?.duplicates }}
      </li>
      <li *ngIf="clUploadResults?.isBillingError">
        <strong>{{ 'Billing error' | translate }}: </strong
        >{{ clUploadResults?.billingError }}
      </li>
      <li *ngIf="clUploadResults?.isfileError">
        <strong>{{ 'File error' | translate }}: </strong
        >{{ clUploadResults?.fileError }}
      </li>
    </ul>
  </ng-container>
  <div class="action-buttons-wrapper btn-wrp flex-hor-center">
    <ng-container [ngSwitch]="clUploadResults">
      <ng-container *ngSwitchCase="undefined">
        <app-credit-cost-button
          [action]="'importCdr'"
          [buttonText]="'Upload call log'"
          *ngIf="!loading"
          [active]="validFile"
          [stylingClasses]="'cp-btn-ghost cp-search-btn'"
          (clicked)="uploadCallLog()"
        >
        </app-credit-cost-button>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <button mat-button class="cp-btn-ghost" (click)="hideResults()">
          {{ 'Upload another file' | translate }}
        </button>
        <button mat-button class="cp-btn-primary" (click)="close()">
          {{ 'Close' | translate }}
        </button>
      </ng-container>
    </ng-container>
  </div>

  <div *ngIf="loading" class="progress">
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
  </div>
</div>
