import { BaseApiService } from '../../services/base';
import { DataLayerModule } from '../../data-layer.module';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Chat } from '../../models/platform-models/chat/chat';
import { ChatDTO } from './chat-dto';

@Injectable({
  providedIn: DataLayerModule,
})
export class ChatApiService extends BaseApiService<Chat, ChatDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient, ChatDTO);
  }

  public getModelName(): string {
    return Chat.name;
  }
}
