<div *ngIf="showEntitiesPanel" class="entities-panel">
  <mat-icon class="clickable close" (click)="toggleEntitiesPanel()"
    >close</mat-icon
  >
  <div class="entity-item clickable" *ngFor="let entity of entities">
    <mat-icon
      class="icon"
      [ngStyle]="{ color: entity.color }"
      cdkDragBoundary=".kl-graph"
      cdkDrag
      cdkDragLockAxis="x"
      [cdkDragData]="entity"
      >{{ entity.icon }}</mat-icon
    >
    <div class="name">{{ entity.label | translate }}</div>
  </div>
</div>

<!-- New link relationship select -->
<div
  class="context-menu new-link-type-select"
  *ngIf="newLinkTypeOptions.length > 1"
  [ngStyle]="{
    left: newLinkTypeProperties.position.left,
    top: newLinkTypeProperties.position.top
  }"
>
  <mat-select
    class="global-select option-dropdown"
    (selectionChange)="onNewLinkTypeChange($event)"
    [placeholder]="'Select relationship' | translate"
  >
    <mat-option *ngFor="let option of newLinkTypeOptions" [value]="option">
      {{ linkTypeToText[option] }}
    </mat-option>
  </mat-select>
</div>
