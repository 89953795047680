<div
  *ngIf="userService.currentUser$ | async; let currentUser"
  class="otp-section-wrapper"
>
  <div class="otp-section-header">
    <p class="title">{{ 'One Time Password' | translate }} (OTP)</p>
  </div>

  <div class="otp-section-body">
    <div class="activated-wrapper">
      <p class="activated label">{{ 'Activated' | translate }}</p>
      <mat-slide-toggle
        #otpToggle
        [checked]="currentUser.otpEnabled"
        (change)="handleOTPChange(otpToggle)"
        class="activated checkbox"
      >
      </mat-slide-toggle>
    </div>
    <div *ngIf="currentUser.otpEnabled" class="method-radio-wrapper">
      <p class="method-radio-title">{{ 'Method' | translate }}</p>
      <div class="method-wrapper">
        <mat-radio-group
          aria-labelledby="otp-elements-group"
          [(ngModel)]="otpChannel"
          (change)="changeOTPChannel($event)"
        >
          <div class="mat-radio-wrapper">
            <label class="radio-button label">{{ 'Email' | translate }}</label>
            <div class="email-control-wrapper">
              <mat-radio-button
                class="otp-popup-list-element"
                [value]="'email'"
                [disabled]="!currentUser.email"
              >
                <div class="otp-popup-element">
                  <span
                    *ngIf="currentUser.email"
                    class="otp-popup-element-name"
                    >{{ currentUser.email }}</span
                  >
                  <span
                    *ngIf="!currentUser.email"
                    class="otp-popup-element-name"
                    >{{ 'Unavailable' | translate }}.
                  </span>
                  <span
                    *ngIf="!currentUser.email"
                    class="otp-popup-element-name add-phone-number"
                    (click)="showEmailInput = true"
                    >{{ 'Add email' | translate }}</span
                  >
                </div>
              </mat-radio-button>

              <div class="input-wrapper">
                <div
                  *ngIf="showEmailInput"
                  class="input-style-round relative email-input"
                >
                  <input
                    class="assistant-font"
                    [formControl]="emailControl"
                    matInput
                    required
                    type="email"
                  />
                  <mat-icon
                    *ngIf="
                      emailControl && emailControl.dirty && emailControl.valid
                    "
                    class="icon success-icon"
                    >check_circle</mat-icon
                  >
                </div>
                <mat-error
                  class="otp-error"
                  *ngIf="emailControl.dirty && emailControl.invalid"
                  >{{ 'Enter a valid email' | translate }}</mat-error
                >
                <div *ngIf="showEmailInput" class="button-wrapper">
                  <button
                    mat-button
                    class="margin-right10 cp-btn-ghost"
                    (click)="cancelEdit()"
                  >
                    {{ 'Cancel' | translate }}
                  </button>
                  <button
                    mat-button
                    class="margin-right10 cp-btn-primary"
                    (click)="submitForm()"
                  >
                    {{ 'Save' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="mat-radio-wrapper">
            <label class="radio-button label">{{
              'Phone number' | translate
            }}</label>
            <div class="phone-control-wrapper">
              <mat-radio-button
                [disabled]="!currentUser.otpPhone"
                class="otp-popup-list-element"
                [value]="'sms'"
                ><div class="otp-popup-element">
                  <span
                    *ngIf="currentUser.otpPhone"
                    class="otp-popup-element-name"
                    >{{ currentUser.otpPhone }}</span
                  >
                  <span
                    *ngIf="!currentUser.otpPhone"
                    class="otp-popup-element-name"
                    >{{ 'Unavailable' | translate }}.
                  </span>
                  <span
                    *ngIf="!currentUser.otpPhone"
                    class="otp-popup-element-name add-phone-number"
                    (click)="showPhoneInput = true"
                    >{{ 'Add phone number' | translate }}</span
                  >
                </div></mat-radio-button
              >
              <div class="input-wrapper">
                <div
                  *ngIf="showPhoneInput"
                  class="input-style-round relative phone-input"
                >
                  <input
                    class="assistant-font"
                    [formControl]="phoneControl"
                    matInput
                    required
                  />
                  <mat-icon
                    *ngIf="
                      phoneControl && phoneControl.dirty && phoneControl.valid
                    "
                    class="icon success-icon"
                    >check_circle</mat-icon
                  >
                </div>
                <mat-error
                  class="otp-error"
                  *ngIf="
                    phoneControl.dirty && phoneControl.hasError('invalidPhone')
                  "
                  >{{ 'Enter a valid phone' | translate }}</mat-error
                >
                <div *ngIf="showPhoneInput" class="button-wrapper">
                  <button
                    mat-button
                    class="margin-right10 cp-btn-ghost"
                    (click)="cancelEdit()"
                  >
                    {{ 'Cancel' | translate }}
                  </button>
                  <button
                    mat-button
                    class="margin-right10 cp-btn-primary"
                    (click)="submitForm()"
                  >
                    {{ 'Save' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="mat-radio-wrapper">
            <label class="radio-button label">{{
              'Application' | translate
            }}</label>
            <mat-radio-button
              class="otp-popup-list-element"
              [value]="'app'"
            ></mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>
