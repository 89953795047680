import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppConfigService } from '../../providers/app-config.service';
import { BaseService } from '../../services/base.service';

export interface NavbarConstants {
  enableTactical: boolean;
  enabledLandingScreen: boolean;
  enableGeolocationQuery: boolean;
  enableUserManual: boolean;
  adintFlavourEnabled: boolean;
  enableKnowledgeBase: boolean;
  enableUserSettingsV2: boolean;
  isLocatingDisabled: boolean;
  hasConcurrentLimits: boolean;
  enableInvestigationModule: boolean;
  isCDROnlyEnabled: boolean;
  enableLicense: boolean;
}

@Injectable()
export class NavbarService extends BaseService {
  enableIntelResultsV2: boolean;

  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private appConfigService: AppConfigService
  ) {
    super(router, snackBar);
  }

  getNavbarConstants(): NavbarConstants {
    const enableTactical =
      this.appConfigService.getConfigVariable('enableTactical');
    const enabledLandingScreen = this.appConfigService.getConfigVariable(
      'enabledLandingScreen'
    );
    const enableGeolocationQuery = this.appConfigService.getConfigVariable(
      'enableGeolocationQuery'
    );
    const enableUserManual =
      this.appConfigService.getConfigVariable('enableUserManual');
    const adintFlavourEnabled =
      this.appConfigService.getConfigVariable('enableAdintFlavour');
    const enableKnowledgeBase = this.appConfigService.getConfigVariable(
      'enableKnowledgeBase'
    );
    const enableUserSettingsV2 = this.appConfigService.getConfigVariable(
      'enableUserSettingsV2'
    );
    const isLocatingDisabled = this.appConfigService.getConfigVariable(
      'disableLocatingFunctionality'
    );
    const hasConcurrentLimits = this.appConfigService.getConfigVariable(
      'hasConcurrentLimits'
    );
    const enableInvestigationModule = this.appConfigService.getConfigVariable(
      'enableInvestigationModule'
    );
    const isCDROnlyEnabled =
      this.appConfigService.getConfigVariable('isCDROnlyEnabled');

    const enableLicense =
      this.appConfigService.getConfigVariable('enableLicense');

    return {
      enableTactical,
      enabledLandingScreen,
      enableGeolocationQuery,
      enableUserManual,
      adintFlavourEnabled,
      enableKnowledgeBase,
      enableUserSettingsV2,
      isLocatingDisabled,
      hasConcurrentLimits,
      enableInvestigationModule,
      isCDROnlyEnabled,
      enableLicense,
    };
  }
}
