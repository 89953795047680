<div class="input-container" [style.width]="width">
  <div
    class="input-label-container"
    [class.flex-end]="!label && optionalLabel"
    [class.flex-start]="label && !optionalLabel"
    [class.space-between]="label && optionalLabel"
  >
    <label *ngIf="label" class="input-label">{{ label }}</label>
    <span *ngIf="optionalLabel" class="optional">{{ optionalLabel }}</span>
  </div>

  <div
    class="input-field-container"
    [class.disabled]="disabled"
    [class.small]="size === inputSizeEnum.SMALL"
    [style.width]="width"
  >
    <input
      [(ngModel)]="inputValue"
      [class.error-state]="state?.type === inputStateTypeEnum.ERROR"
      [class.min-width-0]="!!width"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [style.width]="width"
      (change)="onValueChange()"
      (keyup)="onKeyUp()"
    />
  </div>

  <div *ngIf="hint" class="hint-msg">{{ hint }}</div>

  <ng-container *ngIf="state">
    <div
      class="state-message-container"
      [class.error-msg]="state.type === inputStateTypeEnum.ERROR"
      [class.success-msg]="state.type === inputStateTypeEnum.SUCCESS"
      [class.warning-msg]="state.type === inputStateTypeEnum.WARNING"
    >
      <fe-platform-fa-pro-icon
        *ngIf="stateIcon"
        [family]="'regular'"
        [name]="stateIcon"
      ></fe-platform-fa-pro-icon>
      <span>{{ state.message }}</span>
    </div>
  </ng-container>
</div>
