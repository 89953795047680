<div class="toaster-wrapper">
  <div
    class="icon-wrapper d-flex align-items-center justify-content-center"
    *ngIf="options.payload?.icon"
    [ngStyle]="{
      'background-color': options.payload?.icon.iconBackgroundColor
    }"
  >
    <fe-platform-fa-pro-icon
      [family]="options.payload.icon.family"
      [name]="options.payload.icon.name"
      [spin]="options.payload.icon.iconRotate"
    ></fe-platform-fa-pro-icon>
  </div>
  <h1 class="title" *ngIf="title">{{ title }}</h1>
  <intellectus-icon-text-button
    [buttonIcon]="closeIcon"
    [buttonSize]="buttonSizes.Small"
    (buttonClick)="close()"
  ></intellectus-icon-text-button>

  <div
    class="actions-wrapper"
    [ngClass]="
      isTheTopToast && currentlyVisibleToasts.length > 1 ? 'is-only-toast' : ''
    "
    *ngIf="message || options.payload?.actions?.length"
  >
    <h2 class="message" *ngIf="message && isTheTopToast">{{ message }}</h2>
    <!-- 
       TODO
       These buttons will have to be changed with the newly created button component
     -->
    <ng-container *ngIf="options.payload?.actions?.length">
      <button
        *ngFor="let action of options.payload.actions"
        (click)="onAction(action)"
        [class.hidden]="!isTheTopToast"
      >
        {{ action.label }}
        <span class="credits-number" *ngIf="action.credits">{{
          action.credits
        }}</span>
      </button>
    </ng-container>
  </div>
  <span
    *ngIf="isTheTopToast && currentlyVisibleToasts.length > 1"
    class="toaster-counter"
    >1 / {{ currentlyVisibleToasts.length }}</span
  >
</div>
