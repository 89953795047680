<div class="search-input-container link-analysis-search">
  <img
    class="search-icon"
    src="{{ imagesPath }}search_icon_global_query.svg"
    height="15"
  />
  <input
    placeholder="{{ 'Search' | translate }}"
    class="search-input"
    matInput
    #nodeSearchInput
    type="search"
  />
</div>
<mat-accordion>
  <mat-expansion-panel
    *ngFor="let filterEl of nodeFilterElements"
    class="la-custom-panel"
    [expanded]="filterEl.expanded"
  >
    <mat-expansion-panel-header class="la-custom-panel-header">
      <!-- Hide select all checkbox until fixed -->
      <!-- <mat-checkbox
        class="la-panel-checkbox la-section-checkbox block"
        [class.few-selected-checkmark]="showSomeCheckedIndication(filterEl)"
        (change)="assignFilter($event, filterEl)"
        (click)="$event.stopPropagation()">
      </mat-checkbox> -->
      {{ filterEl.label | translate }}
      <span
        class="la-custom-panel-subheader global-text-overflow-wrap"
        tooltip="{{ filterEl.matchedSearchData.length }} {{
          'items found' | translate
        }}"
      >
        {{ filterEl.matchedSearchData.length }}
        {{ 'items found' | translate }}</span
      >
    </mat-expansion-panel-header>
    <div class="la-checked-items-container">
      <mat-checkbox
        *ngFor="let checkedItem of filterEl.checkedData"
        [checked]="true"
        (change)="
          addToFilteredData(
            $event,
            checkedItem,
            filterEl.matchedSearchData,
            filterEl.checkedData
          )
        "
        class="la-panel-checkbox block"
      >
        <img
          *ngIf="checkedItem.icon"
          [src]="checkedItem.icon"
          height="12"
          class="la-panel-checkbox-img"
        />{{ checkedItem.label }}
      </mat-checkbox>
    </div>
    <div class="filters-separator" *ngIf="filterEl.checkedData.length"></div>
    <div
      class="la-all-items-container-full"
      [class.la-all-items-container]="filterEl.checkedData.length > 2"
    >
      <mat-checkbox
        *ngFor="let item of filterEl.matchedSearchData"
        class="la-panel-checkbox block"
        (change)="
          addToFilteredData(
            $event,
            item,
            filterEl.matchedSearchData,
            filterEl.checkedData
          )
        "
        [disabled]="item.disabled"
        [checked]="item.disabled"
      >
        <img
          *ngIf="item.icon"
          [src]="item.icon"
          height="12"
          class="la-panel-checkbox-img"
        />{{ item.label }}
        <span *ngIf="item.disabled">({{ 'target' | translate }})</span>
      </mat-checkbox>
    </div>
  </mat-expansion-panel>
</mat-accordion>
