import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  FormControlStatus,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
} from '@fe-platform/shared-ui/intellectus';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AuthDataService } from 'src/app/services/authentication/auth-data.service';
import {
  PasswordValidationResult,
  PasswordValidationType,
} from '@shared/models/login.model';
import { InputStatus } from '../../user-settings/user-settings.models';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Input() currentPassword = '';
  @Output() onPasswordChange = new EventEmitter<void>();
  @Output() passwordFormValid? = new EventEmitter<InputStatus>();

  changePasswordForm: UntypedFormGroup;
  strengthBar: number;
  visibility = false;
  errorMsg: string;
  isValid = false;
  errorMessage: string;
  fAavailableIcons: { [name: string]: FontAwesomeIcon } = {
    eyeVisible: {
      name: 'eye',
      family: FontAwesomeFamily.REGULAR,
    },
    eyeHidden: {
      name: 'eye-slash',
      family: FontAwesomeFamily.REGULAR,
    },
  };

  private destroy$ = new Subject<void>();

  constructor(
    protected authService: AuthDataService,
    protected router: Router
  ) {}

  ngOnInit() {
    this.initForm();
    this.onKeyupCheckPassword();

    this.changePasswordForm.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((changePasswordFormStatus: FormControlStatus) => {
        this.passwordFormValid.emit({
          value: this.changePasswordForm.controls['newPassword'].value,
          valid: this.isValid && this.validate.match.exact,
        });
      });
  }

  get validate() {
    const newPassword = this.changePasswordForm.controls['newPassword'].value;
    const confirmPassword =
      this.changePasswordForm.controls['confirmPassword'].value;

    return {
      match: {
        start: newPassword.indexOf(confirmPassword) === 0,
        exact: newPassword === confirmPassword,
      },
    };
  }

  initForm() {
    this.changePasswordForm = new UntypedFormGroup({
      currentPassword: new UntypedFormControl(''),
      newPassword: new UntypedFormControl(''),
      confirmPassword: new UntypedFormControl(''),
    });
  }

  onKeyupCheckPassword() {
    this.changePasswordForm
      .get('newPassword')
      .valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((newPassword: string) => {
        if (newPassword.length) {
          this.checkPasswordPolicy(newPassword);
        }
      });
  }

  private checkPasswordPolicy(newPassword: string) {
    this.authService
      .checkPasswordPolicy(newPassword)
      .pipe(takeUntil(this.destroy$))
      .subscribe((password: PasswordValidationResult) => {
        this.isValid = password.valid;
        this.calculatePasswordStrength(password.validated_policies);
        this.passwordValidation(password.validated_policies);
      });
  }

  private calculatePasswordStrength(validationType: PasswordValidationType) {
    const validatePoliciesList = Object.values(validationType);
    this.strengthBar = validatePoliciesList.reduce((score, item) => {
      return score + (item ? 100 / validatePoliciesList.length : 0);
    }, 0);
  }

  private passwordValidation(validationType: PasswordValidationType) {
    if (!validationType.length) {
      this.errorMsg = 'Password must be at least 8 characters.';
    } else if (!validationType.uppercase) {
      this.errorMsg = 'Password does not contain upper case letters.';
    } else if (!validationType.lowercase) {
      this.errorMsg = 'Password does not contain lower case letters.';
    } else if (!validationType.digits) {
      this.errorMsg = 'Password does not contain any digits.';
    } else {
      this.errorMsg = '';
    }
  }
}
