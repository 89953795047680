import { Injectable } from '@angular/core';
import { RecoveryAccount } from 'src/app/shared/models/recovery-account.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { Profile } from 'datalayer/models/social/profile';
import { omit, pick, uniq, uniqBy } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class RecoveryAccountsService {
  public joinParentSeeds(
    recoveryAccount: RecoveryAccount
  ): RecoveryAccount['parentSeed']['alternativeSeeds'] {
    const { parentSeed } = recoveryAccount;

    if (!parentSeed) {
      return [];
    }

    return [...(parentSeed.alternativeSeeds || []), parentSeed];
  }

  public mergeRecoveryAccounts(
    leftRecoveryAccount: RecoveryAccount,
    rightRecoveryAccount: RecoveryAccount
  ): boolean {
    if (leftRecoveryAccount.platform !== rightRecoveryAccount.platform) {
      return false;
    }

    const leftParentSeeds = this.joinParentSeeds(leftRecoveryAccount);
    const rightParentSeeds = this.joinParentSeeds(rightRecoveryAccount);

    let merges = false;

    for (const leftParentSeed of leftParentSeeds) {
      for (const rightParentSeed of rightParentSeeds) {
        if (leftParentSeed.platform !== rightParentSeed.platform) {
          continue;
        }
        if (leftParentSeed.value === rightParentSeed.value) {
          merges = true;
          break;
        }
      }
      if (merges) {
        leftRecoveryAccount.parentSeed.alternativeSeeds = uniqBy(
          [
            ...(leftRecoveryAccount.parentSeed.alternativeSeeds || []),
            ...rightParentSeeds
              .filter(
                ({ value }) => leftRecoveryAccount.parentSeed.value !== value
              )
              .map((parentSeed) => omit(parentSeed, 'alternativeSeeds')),
          ],
          (seed) => seed.value
        );

        leftRecoveryAccount.emails = uniq([
          ...(leftRecoveryAccount.emails || []),
          ...(rightRecoveryAccount.emails || []),
        ]);
        leftRecoveryAccount.telnos = uniq([
          ...(leftRecoveryAccount.telnos || []),
          ...(rightRecoveryAccount.telnos || []),
        ]);
        return true;
      }
    }
    return false;
  }

  public transformTargetRecoveryAccountToProfile(
    target: TargetItem,
    recoveryAccount: RecoveryAccount
  ): Profile {
    if (!target || !recoveryAccount) {
      return;
    }

    const { id: targetId, socialProfiles = [] } = target;

    const profile: Profile = new Profile({ targetId, recoveryAccount });

    const parentSeeds = this.joinParentSeeds(recoveryAccount);

    for (const parentSeed of parentSeeds) {
      if (!parentSeed) {
        continue;
      }

      if (!profile[parentSeed.type]) {
        profile[parentSeed.type] = parentSeed.value;
      }

      for (const socialProfile of socialProfiles) {
        if (socialProfile.platform !== recoveryAccount.platform) {
          continue;
        }

        if (socialProfile.userId.indexOf(parentSeed.value) === 0) {
          profile.profileId = socialProfile.userId;
        }

        if (socialProfile.link.indexOf(parentSeed.value) !== -1) {
          profile.url = socialProfile.link;
        }
      }
    }

    profile.platform = recoveryAccount?.platform;

    return profile;
  }

  public mergeRecoveryProfiles(profiles: Profile[]): Profile[] {
    const recoveryProfiles = profiles.filter(
      (profile) => profile.recoveryAccount
    );
    const unmergedRecoveryProfiles: Profile[] = [];

    for (const recoveryProfile of recoveryProfiles) {
      let merges = false;

      for (const unmergedRecoveryProfile of unmergedRecoveryProfiles) {
        if (
          (merges = this.mergeRecoveryAccounts(
            recoveryProfile.recoveryAccount,
            unmergedRecoveryProfile.recoveryAccount
          ))
        ) {
          break;
        }
      }

      if (!merges) {
        unmergedRecoveryProfiles.push(recoveryProfile);
      }
    }

    return [
      ...unmergedRecoveryProfiles,
      ...profiles.filter((profile) => !profile.recoveryAccount),
    ];
  }

  public mergeRecoveryProfilesWithProfiles(profiles: Profile[]): Profile[] {
    profiles = this.mergeRecoveryProfiles(profiles);

    const recoveryProfiles = profiles.filter(
      (profile) => profile.recoveryAccount
    );
    const otherProfiles = profiles.filter(
      (profile) => !profile.recoveryAccount
    );

    const unmergedRecoveryProfiles: Profile[] = [];

    for (const recoveryProfile of recoveryProfiles) {
      let merges = false;

      const parentSeeds = this.joinParentSeeds(recoveryProfile.recoveryAccount);

      for (const profile of otherProfiles) {
        const mergingValues = Object.values(
          pick(profile, [
            'profileId',
            'url',
            'email',
            'telno',
            'username',
            'userFriendlyUrl',
          ])
        ).filter((v) => v);

        if (
          mergingValues.includes(recoveryProfile.profileId) ||
          mergingValues.includes(recoveryProfile.url) ||
          mergingValues.includes(recoveryProfile.userFriendlyUrl) ||
          mergingValues.includes(recoveryProfile.username)
        ) {
          merges = true;
        } else {
          for (const parentSeed of parentSeeds) {
            if (mergingValues.includes(parentSeed.value)) {
              merges = true;
              break;
            }
          }
        }

        if (merges) {
          profile.recoveryAccount = recoveryProfile.recoveryAccount;
          break;
        }
      }

      if (!merges) {
        unmergedRecoveryProfiles.push(recoveryProfile);
      }
    }

    return [...otherProfiles, ...unmergedRecoveryProfiles];
  }

  public mergeTargetRecoveryAccounts(target: TargetItem): RecoveryAccount[] {
    const { recoveryAccounts = [] } = target;

    const unmergedRecoveryAccounts: RecoveryAccount[] = [];

    for (const recoveryAccount of recoveryAccounts) {
      let merges = false;
      for (const unmergedRecoveryAccount of unmergedRecoveryAccounts) {
        if (
          (merges = this.mergeRecoveryAccounts(
            recoveryAccount,
            unmergedRecoveryAccount
          ))
        ) {
          break;
        }
      }

      if (!merges) {
        unmergedRecoveryAccounts.push(recoveryAccount);
      }
    }
    return unmergedRecoveryAccounts;
  }

  public getEmailRecoveryAccountsMap(target: TargetItem): {
    [email: string]: RecoveryAccount[];
  } {
    const emailRecoveryProfilesMap: { [email: string]: RecoveryAccount[] } = {};

    for (const recoveryAccount of this.mergeTargetRecoveryAccounts(target)) {
      for (const parentSeed of this.joinParentSeeds(recoveryAccount)) {
        if (parentSeed.type === 'email') {
          emailRecoveryProfilesMap[parentSeed.value] = [
            ...(emailRecoveryProfilesMap[parentSeed.value] || []),
            recoveryAccount,
          ];
        }
      }
    }
    return emailRecoveryProfilesMap;
  }
}
