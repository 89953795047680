<button
  mat-button
  [ngClass]="[size, type, isLoading ? 'loading' : '']"
  disabled="{{ isDisabled }}"
  onclick="this.blur()"
>
  <!-- icons -->
  <!-- icons appear ONLY if credits is not defined -->
  <ng-container *ngIf="!credits">
    <i *ngIf="iconStyles && !isLoading" [class]="iconStyles"></i>
    <!-- icon spinner -->
    <mat-spinner
      *ngIf="isLoading"
      class="mat-spinner-white ml-5 mr-5"
      [diameter]="14"
    ></mat-spinner
  ></ng-container>

  <!-- button content -->
  <ng-content></ng-content>

  <!-- credit cost -->
  <ng-container *ngIf="credits">
    <mat-spinner
      *ngIf="isLoading"
      class="mat-spinner-white ml-5 mr-5"
      [diameter]="14"
    ></mat-spinner>
    <span *ngIf="!isLoading" class="ml-5 credits" [ngClass]="[type, size]">{{
      credits
    }}</span>
  </ng-container>
</button>
