import { Component, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-header-content-footer-dialog',
  templateUrl: './header-content-footer-dialog.component.html',
  styleUrls: ['./header-content-footer-dialog.component.scss'],
})
export class HeaderContentFooterDialogComponent {
  @Input() title: string;
  @Input() showClose: boolean;
  @Input() showFooter: boolean;
  constructor(
    public dialogRef: MatDialogRef<HeaderContentFooterDialogComponent>
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
