import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';
import {
  IntelQueryKeys,
  TelnoToIntelQueryMap,
} from 'src/app/shared/models/intel-query-keys';
import { Skins } from 'src/app/shared/models/skins.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {
  skin = new BehaviorSubject<string>(Skins[environment.flavor]);

  isMobileResolution: boolean;
  isSmallDeviceResolution: boolean;
  geoQueryToIntelQueryMap: TelnoToIntelQueryMap = {}; // TODO: move to base intel state?
  theme: string;
  waitToSaveGraphOnLogout = new BehaviorSubject<{
    logout: boolean;
    proceed: boolean;
  }>({
    logout: false,
    proceed: false,
  });

  constructor(
    private router: Router,
    private appConfigService: AppConfigService
  ) {
    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
    } else if (window.innerWidth > 768 && window.innerWidth < 1600) {
      this.isSmallDeviceResolution = true;
      this.isMobileResolution = false;
    } else {
      this.isMobileResolution = false;
      this.isSmallDeviceResolution = false;
    }
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  public getIsMobileResolution(): boolean {
    return this.isMobileResolution;
  }

  public getIsSmallDeviceResolution(): boolean {
    return this.isSmallDeviceResolution;
  }

  public getSkin() {
    return this.skin.value;
  }

  public updateIntelQueryArgs(telno, queryArgs: IntelQueryKeys) {
    const existingTelno = this.geoQueryToIntelQueryMap[telno];
    if (existingTelno) {
      this.geoQueryToIntelQueryMap[telno].userIds.concat(queryArgs.userIds);
      this.geoQueryToIntelQueryMap[telno].names.concat(queryArgs.names);
      this.geoQueryToIntelQueryMap[telno].usernames.concat(queryArgs.usernames);
      this.geoQueryToIntelQueryMap[telno].screenNames.concat(
        queryArgs.screenNames
      );
      this.geoQueryToIntelQueryMap[telno].telnos.concat(queryArgs.telnos);
    } else {
      this.geoQueryToIntelQueryMap[telno] = {
        userIds: queryArgs.userIds || [],
        names: queryArgs.names || [],
        usernames: queryArgs.usernames || [],
        screenNames: queryArgs.screenNames || [],
        telnos: [telno].concat(queryArgs.telnos || []),
      } as IntelQueryKeys;
    }
  }

  getDefaultMarker() {
    let defaultMarkerSrc = 'geo_default_pin.svg';
    if (this.theme === 'GEOLOC') {
      defaultMarkerSrc = 'geoloc_default_pin.svg';
    }
    return defaultMarkerSrc;
  }

  getDefaultSelectedMarker() {
    let selectedMarkerSrc = 'geo_default_pin_selected.svg';
    if (this.theme === 'GEOLOC') {
      selectedMarkerSrc = 'geoloc_default_pin_selected.svg';
    }
    return selectedMarkerSrc;
  }

  isProfilerTarget() {
    return (
      this.router.url.includes('instant-messaging') ||
      this.router.url.includes('social-activity') ||
      this.router.url.includes(`${ApplicationMainPageUrls.WEBINT}/`) ||
      this.router.url.includes(`${ApplicationMainPageUrls.INVESTIGATION}`)
    );
  }

  isPage(pageUrl: ApplicationMainPageUrls): boolean {
    return this.router.url.includes(pageUrl);
  }
}
