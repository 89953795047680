<ng-container *ngIf="!mandatoryEnablement">
  <div class="toggle-btn flex-wrp">
    <span>
      {{ 'Turn ' + (radioVisibility ? 'Off' : 'On') | translate }}
    </span>
    <mat-slide-toggle
      [checked]="radioVisibility"
      (change)="changeRadioVisibility()"
    >
    </mat-slide-toggle>
  </div>
</ng-container>

<ng-container *ngIf="radioVisibility">
  <p class="body-desc">
    {{ 'Please select the type of OTP you want to use.' | translate }}
  </p>
  <div class="method-radio-wrapper">
    <div class="method-wrapper">
      <mat-radio-group
        [(ngModel)]="otpChannel"
        (ngModelChange)="changeChannel($event)"
        aria-labelledby="otp-elements-group"
      >
        <div class="mat-radio-wrapper email-wrapper">
          <mat-radio-button
            class="otp-popup-list-element"
            [disabled]="!currentUser?.email && !emailControl.valid"
            [value]="channels.Email"
          >
            <div class="otp-popup-element">
              <span
                *ngIf="!currentUser?.email && !emailControl.valid"
                class="otp-popup-element-name"
              >
                <span class="otp-unavailable"
                  >{{ 'Unavailable' | translate }}.
                </span>
                <span class="add-email" (click)="showEmailInput = true">
                  {{ 'Add email' | translate }}
                </span>
              </span>
              <span
                *ngIf="emailControl.valid && !currentUser?.email"
                class="otp-popup-element-name"
              >
                <span>{{ 'Valid email entered' | translate }}</span
                >.
              </span>
              <span *ngIf="currentUser?.email" class="otp-popup-element-name"
                >{{ currentUser.email }}
              </span>

              <ng-container *ngIf="enableEdit">
                <span
                  *ngIf="currentUser?.email && !emailControl.valid"
                  class="otp-popup-element-name"
                >
                  <span
                    *ngIf="!showEmailInput"
                    class="add-email"
                    (click)="showEmailInput = true"
                    >{{ 'Edit' | translate }}
                  </span>
                  <span
                    *ngIf="showEmailInput"
                    class="add-email"
                    (click)="removeEmailInput()"
                    >{{ 'Remove' | translate }}
                  </span>
                </span>
              </ng-container>
            </div>
          </mat-radio-button>
          <div *ngIf="showEmailInput" class="relative phone-input">
            <mat-form-field class="input-round">
              <input [formControl]="emailControl" matInput required />
              <fe-platform-fa-pro-icon
                *ngIf="emailControl.valid"
                family="regular"
                name="circle-check"
                class="success-icon"
              ></fe-platform-fa-pro-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="mat-radio-wrapper phone-wrapper">
          <mat-radio-button
            class="otp-popup-list-element"
            [disabled]="!currentUser?.otpPhone && !phoneControl.valid"
            [value]="channels.OtpPhone"
            ><div class="otp-popup-element">
              <span
                *ngIf="!currentUser?.otpPhone && !phoneControl.valid"
                class="otp-popup-element-name"
                ><span class="otp-unavailable"
                  >{{ 'Unavailable' | translate }}.
                </span>
                <span
                  class="add-phone-number"
                  (click)="showPhoneInput = true"
                  >{{ 'Add phone number' | translate }}</span
                ></span
              >
              <span
                *ngIf="phoneControl.valid && !currentUser?.otpPhone"
                class="otp-popup-element-name"
                ><span>{{
                  'Valid phone number entered.' | translate
                }}</span></span
              >
              <span *ngIf="currentUser?.otpPhone" class="otp-popup-element-name"
                >{{ currentUser.otpPhone || currentUser.otpPhone }}
              </span>
              <ng-container *ngIf="enableEdit">
                <span
                  *ngIf="
                    currentUser?.otpPhone && !phoneControl.valid && enableEdit
                  "
                  class="otp-popup-element-name"
                >
                  <span
                    *ngIf="!showPhoneInput"
                    class="add-phone-number"
                    (click)="showPhoneInput = true"
                    >{{ 'Edit' | translate }}
                  </span>
                  <span
                    *ngIf="showPhoneInput"
                    class="add-phone-number"
                    (click)="removePhoneInput()"
                    >{{ 'Remove' | translate }}
                  </span>
                </span>
              </ng-container>
            </div></mat-radio-button
          >

          <div *ngIf="showPhoneInput" class="relative phone-input">
            <mat-form-field class="input-round">
              <input
                class="assistant-font"
                [formControl]="phoneControl"
                matInput
                required
              />
              <fe-platform-fa-pro-icon
                *ngIf="phoneControl.valid"
                family="regular"
                name="circle-check"
                class="success-icon"
              ></fe-platform-fa-pro-icon>
            </mat-form-field>
          </div>
        </div>

        <div class="mat-radio-wrapper">
          <mat-radio-button
            class="otp-popup-list-element"
            [value]="channels.App"
            ><div class="otp-popup-element">
              <span class="otp-popup-element-name">{{
                'Application' | translate
              }}</span>
            </div></mat-radio-button
          >
        </div>
      </mat-radio-group>
    </div>
  </div>
</ng-container>
