import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private roleManagementService: RoleManagementService,
    private router: Router,
    private appConfigService: AppConfigService
  ) {}

  canActivate(): boolean {
    if (
      !this.roleManagementService.userIsAdmin() &&
      !this.roleManagementService.userIsSupportUser() &&
      !this.roleManagementService.userIsPowerUser()
    ) {
      if (this.appConfigService.getConfig()?.enabledLandingScreen) {
        this.router.navigate(['webint']);
      } else {
        this.router.navigate(['discovery']);
      }
      return false;
    }
    return true;
  }
}
