<app-base-modal>
  <div header>{{ 'Create target' | translate }}</div>

  <div body>
    <form [formGroup]="createTargetForm">
      <ng-container *ngIf="!nextStep">
        <div class="field-group">
          <label>{{ 'Alias' | translate }}</label>
          <div class="input-style-round">
            <input
              matInput
              type="text"
              formControlName="alias"
              name="alias"
              [placeholder]="'Enter alias' | translate"
              (keydown)="disableNext = true"
            />
          </div>
          <!-- Alias Edit Related Errors -->
          <div class="alias-errors">
            <mat-error class="error" *ngIf="aliasError">{{
              'Alias exists. Please use another alias' | translate
            }}</mat-error>

            <mat-error
              class="error"
              *ngIf="
                createTargetForm.controls['alias'].value &&
                createTargetForm.controls['alias'].invalid
              "
            >
              {{
                'Must be at least 3 characters long and maximum characters allowed are 20, special charactes and preceding or following spaces are not allowed'
                  | translate
              }}
            </mat-error>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="nextStep">
        <!-- Msisdn -->
        <div class="field-group">
          <label>{{ 'MSISDN' | translate }}</label>
          <mat-form-field class="input-wrp">
            <mat-chip-list #msisdnList>
              <mat-chip
                class="my-list-chip"
                [class.invalid-value]="
                  !editValidationPhone(msisdn.telno) || !isMsisdnFormatValid
                "
                *ngFor="let msisdn of msisdnChips"
                [removable]="true"
                (removed)="removeMsisdn(msisdn.telno)"
              >
                <div
                  *ngIf="
                    editValidationPhone(msisdn.telno) && msisdn.countryCode
                  "
                  [ngClass]="msisdn.countryCode + ' flag'"
                ></div>
                {{ msisdn.telno }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                class="chip-list-input"
                #msisdnInput
                formControlName="msisdn"
                [matChipInputAddOnBlur]="true"
                [matChipInputFor]="msisdnList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addMsisdn($event)"
              />
            </mat-chip-list>
          </mat-form-field>
        </div>

        <!-- Country -->
        <div class="field-group">
          <label>{{ 'Select country' | translate }}</label>
          <mat-form-field class="input-wrp">
            <mat-chip-list #countryList>
              <mat-chip
                class="my-list-chip"
                *ngFor="let country of countryChips"
                [removable]="true"
                (removed)="removeCountry(country)"
              >
                {{ country }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                class="chip-list-input"
                #countryInput
                formControlName="countries"
                [matAutocomplete]="autoCountries"
                [matChipInputAddOnBlur]="true"
                [matChipInputFor]="countryList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              />
            </mat-chip-list>
            <mat-autocomplete
              #autoCountries="matAutocomplete"
              (optionSelected)="addCountry($event)"
            >
              <mat-option
                *ngFor="let country of filteredCountries | async"
                [value]="country.countryName"
              >
                <span
                  class="iti__flag iti__{{ country.countryCode | lowercase }}"
                ></span>
                <span>{{ country.countryName }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </ng-container>
    </form>

    <div class="col-md-12" *ngIf="targetCreditsChargesEnabled && nextStep">
      <p class="ubuntu-font note-txt">{{ targetCreditsMessage }}</p>
    </div>

    <section class="info-message" *ngIf="!hasTargetsSlotsAvailable">
      <span class="title">
        <fe-platform-fa-pro-icon
          [family]="'regular'"
          [name]="'circle-info'"
        ></fe-platform-fa-pro-icon>
        <span>{{
          'You have reached the limit of the Targets used in the system.To add a new one, please go to the list of targets and delete one.'
            | translate
        }}</span>
      </span>
      <button class="view-dashboard-btn" (click)="navigateToTargets()">
        {{ 'View Targets' | translate }}
        <fe-platform-fa-pro-icon
          [family]="'regular'"
          [name]="'arrow-up-right-from-square'"
        ></fe-platform-fa-pro-icon>
      </button>
    </section>
  </div>

  <div footer class="btn-wrp">
    <button
      class="cp-btn-primary"
      mat-button
      (click)="nextStep = true"
      [disabled]="
        createTargetForm.controls['alias'].invalid ||
        aliasError ||
        disableNext ||
        !hasTargetsSlotsAvailable
      "
      *ngIf="!nextStep"
    >
      {{ 'Next step' | translate }}
    </button>

    <ng-container *ngIf="nextStep">
      <button class="cp-btn-ghost" mat-button (click)="nextStep = false">
        {{ 'Back' | translate }}
      </button>

      <button
        class="cp-btn-primary"
        mat-button
        (click)="createTarget()"
        [disabled]="!isMsisdnValid"
      >
        {{ 'Create target' | translate }}
      </button>
    </ng-container>
  </div>
</app-base-modal>
