export function appendExternalScript(
  srcPath: string,
  customAttributes?: { name: string; value: string }[]
): void {
  const script = document.createElement('script');

  if (customAttributes?.length) {
    customAttributes.forEach((attr) =>
      script.setAttribute(attr.name, attr.value)
    );
  }

  script.src = srcPath;
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  document.getElementsByTagName('head')[0].appendChild(script);
}
