<div class="ip-location-popup">
  <div class="title">
    <span>{{ 'Get Cell Level Location' | translate }}</span>
  </div>
  <div class="body">
    <p>{{ 'Do you want to get cell level location?' | translate }}</p>
  </div>
  <div class="btn-wrp">
    <app-credit-cost-button
      [action]="'queryLocation'"
      [countOfActionsCharged]="1"
      [buttonText]="'Locate'"
      [active]="true"
      [stylingClasses]="'cp-btn-primary cp-search-btn active'"
      (click)="onLocateWithIp()"
    ></app-credit-cost-button>
  </div>
</div>
