<div *ngIf="node">
  <!-- Top action buttons -->

  <!-- HIDE FOR RELEASE-->
  <!-- <button
    mat-button
    class="la-node-details-btn la-node-details-btn-fill la-btn-spacer"
    *ngIf="showMutualFriendsButton() && !caseView"
    (click)="getMutualFriends()"
  >
    {{ 'Common relations' | translate }}
    <span class="beta-sign-label">BETA</span>
  </button> -->

  <button
    mat-button
    class="la-node-details-btn la-node-details-btn-fill"
    [disabled]="loader"
    *ngIf="node.d.companyUrl || (node.d.companyId && !caseView)"
    (click)="getLinkedinCompanyEmployees()"
  >
    {{ 'Company Employees' | translate }}
    <span class="beta-sign-label">BETA</span>
  </button>
  <!-- OSINT button -->
  <div class="custom-hover-dropdown inline-flex" *ngIf="showOsintButton">
    <app-credit-cost-button
      *ngIf="!loader"
      [action]="'advancedOsint'"
      [matTooltip]="
        'Create a new target and find possible associates, visited places, connections on social media, groups and more.'
          | translate
      "
      [buttonText]="'Enhance Profile'"
      [active]="true"
      [stylingClasses]="'cp-btn-primary cp-search-btn active'"
      (clicked)="openAdvancedOsintDialog()"
    ></app-credit-cost-button>

    <button
      *ngIf="loader"
      mat-button
      class="profiler-locate-item-button cp-btn-primary capitalize-none"
      [disabled]="loader"
    >
      <span class="btn-txt">{{ 'In progress' | translate }}</span>
      <span>|</span>
      <img
        src="assets/static/images/profiler/loader.svg"
        alt="loading"
        width="30"
      />
    </button>
  </div>
  <!-- OSINT button end-->

  <!-- Hide groups for now -->
  <!-- <button mat-button class="la-node-details-btn la-node-details-btn-fill" [disabled]="loader" *ngIf="node.d.type === 'Facebook Group'" (click)="getFacebookGroupMembers()">{{'Group Members' | translate}}</button> -->

  <div class="la-node-details-container">
    <!-- Person card-->
    <div *ngIf="node.d.type === 'Social Profile' || node.d.type === 'Person'">
      <img
        *ngIf="node.d.image"
        [src]="node.d.image"
        height="75"
        width="75"
        class="center-element la-node-person-image"
        onerror="this.src='assets/static/images/user_kl.svg'"
      />
      <div class="inline-flex width-100">
        <div class="la-node-detail-labels global-text-overflow-wrap">
          <div *ngIf="node.d.alias">Alias:</div>
          <div *ngIf="node.d.names">{{ 'Names' | translate }}:</div>
          <div *ngIf="node.d.username">{{ 'Username' | translate }}:</div>
          <div *ngIf="node.d.url">Url:</div>
          <div *ngIf="node.d.source">{{ 'Source' | translate }}:</div>
          <ng-container
            *ngIf="
              node.d?.relation === platformTypes.INSTAGRAM ||
              node.d?.relation === platformTypes.TIKTOK
            "
          >
            <div>{{ 'Verified' | translate }}:</div>
            <div>{{ 'Private profile' | translate }}:</div>
          </ng-container>
        </div>
        <div class="la-node-detail-values">
          <div *ngIf="node.d.alias">{{ node.d.alias || '' }}</div>
          <div *ngIf="node.d.names">{{ node.d.names || '' }}</div>
          <div *ngIf="node.d.username">{{ node.d.username || '' }}</div>
          <div *ngIf="node.d.url" class="width-100">
            <img
              *ngIf="node.d?.relation"
              src="assets/static/images/link-analysis/{{ node.d.relation }}.svg"
              height="12"
            />
            <div
              class="inline-block la-node-details-url global-text-overflow-wrap"
              [matTooltip]="node.d.url || ''"
            >
              {{ node.d.url || '' }}
            </div>
            <img
              src="assets/static/images/copy_all.svg"
              height="10"
              class="clickable"
              [matTooltip]="'Copy' | translate"
              (click)="copyToClipboard(node.d.url)"
            />
          </div>
          <div *ngIf="node.d.source">{{ node.d.source || '' }}</div>
          <ng-container
            *ngIf="
              node.d?.relation === platformTypes.INSTAGRAM ||
              node.d?.relation === platformTypes.TIKTOK
            "
          >
            <div>{{ !node.d.verified ? 'No' : ('Yes' | translate) }}</div>
            <div>
              <ng-container *ngIf="node.d.isPublic; else privateProfile">
                {{ 'No' | translate }}
                <img
                  class="value-icon"
                  [src]="'assets/static/images/lock-open.svg'"
                />
              </ng-container>
              <ng-template #privateProfile>
                {{ 'Yes' | translate }}
                <img
                  class="value-icon"
                  [src]="'assets/static/images/lock.svg'"
                />
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
      <div
        *ngIf="node.d.type === 'Person' && node.d.targetId"
        class="redirect-to-target"
        (click)="redirectToTargetProfile(node.d.targetId)"
      >
        {{ 'Open target profile' | translate }}
      </div>
    </div>

    <!-- Target card-->
    <!-- <div *ngIf="target && node.id === target.id" class="inline-flex width-100">
      <div class="la-node-detail-labels global-text-overflow-wrap">
        <div>{{'Alias' | translate}}:</div>
      </div>
      <div class="la-node-detail-values">
        <div>{{target.alias || ''}}</div>
      </div>
    </div> -->

    <!-- MSISDN card-->
    <div *ngIf="node.d.type === 'MSISDN'">
      <div class="inline-flex width-100">
        <div class="la-node-detail-labels global-text-overflow-wrap">
          <div>MSISDN:</div>
          <div *ngIf="node.d.source">{{ 'Source' | translate }}:</div>
        </div>
        <div class="la-node-detail-values">
          <div>{{ node.d.label }}</div>
          <div *ngIf="node.d.source">{{ node.d.source }}</div>
        </div>
      </div>
    </div>

    <!-- Phone card-->
    <div *ngIf="node.d.type === 'Phone'">
      <div class="inline-flex width-100">
        <div class="la-node-detail-labels global-text-overflow-wrap">
          <div>IMEI:</div>
          <div *ngIf="node.d.source">{{ 'Source' | translate }}:</div>
        </div>
        <div class="la-node-detail-values">
          <div>{{ node.d.label }}</div>
          <div *ngIf="node.d.source">{{ node.d.source }}</div>
        </div>
      </div>
    </div>

    <!-- Facebook group card-->
    <div *ngIf="node.d.type === 'Facebook Group'">
      <img
        *ngIf="node.d.image"
        [src]="node.d.image"
        height="75"
        width="75"
        class="center-element la-node-group-image"
        onerror="this.style.display = 'none'"
      />
      <div class="inline-flex width-100">
        <div class="la-node-detail-labels global-text-overflow-wrap">
          <div *ngIf="node.d.label">{{ 'Group Name' }}:</div>
          <div *ngIf="node.d.url">Url:</div>
          <div *ngIf="node.d.membersCount">{{ 'Members' }}:</div>
          <div *ngIf="node.d.description">{{ 'Description' }}:</div>
        </div>
        <div class="la-node-detail-values">
          <div class="global-text-overflow-wrap" [matTooltip]="node.d.label">
            {{ node.d.label || '' }}
          </div>
          <div *ngIf="node.d.url" class="inline-flex width-100">
            <img *ngIf="node.g && node.g[0]" [src]="node.g[0].u" height="12" />
            <div
              class="la-node-details-url global-text-overflow-wrap"
              [matTooltip]="node.d.url"
            >
              {{ node.d.url }}
            </div>
            <img
              src="assets/static/images/copy_all.svg"
              height="10"
              class="clickable"
              [matTooltip]="'Copy' | translate"
              (click)="copyToClipboard(node.d.url)"
            />
          </div>
          <div *ngIf="node.d.membersCount">{{ node.d.membersCount }}</div>
          <div class="group-description" *ngIf="node.d.description">
            {{ node.d.description }}
          </div>
        </div>
      </div>
    </div>

    <!-- Organization: Company card-->
    <div
      *ngIf="node.d.type === 'Organization' && node.d.subtype === 'workplace'"
    >
      <img
        *ngIf="node.d.image"
        [src]="node.d.image"
        height="75"
        width="75"
        class="center-element la-node-organization-image"
        onerror="this.style.display = 'none'"
      />
      <div class="inline-flex width-100">
        <div class="la-node-detail-labels global-text-overflow-wrap">
          <div *ngIf="node.d.label">{{ 'Name' | translate }}:</div>
          <div *ngIf="node.d.industry">{{ 'Industry' | translate }}:</div>
          <div *ngIf="node.d.url">Url:</div>
        </div>
        <div class="la-node-detail-values">
          <div class="global-text-overflow-wrap" [matTooltip]="node.d.label">
            {{ node.d.label || '' }}
          </div>
          <div *ngIf="node.d.industry">{{ node.d.industry }}</div>
          <div *ngIf="node.d.url" class="inline-flex width-100">
            <div
              class="la-node-details-url global-text-overflow-wrap"
              [matTooltip]="node.d.url"
            >
              {{ node.d.url }}
            </div>
            <img
              src="assets/static/images/copy_all.svg"
              height="10"
              class="clickable"
              [matTooltip]="'Copy' | translate"
              (click)="copyToClipboard(node.d.url)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- INSTANT MESSAGING-->
    <div *ngIf="node.d.type === 'Instant messaging'">
      <img
        *ngIf="node.d.image"
        [src]="node.d.image"
        height="75"
        width="75"
        class="center-element la-node-person-image"
        onerror="this.src='assets/static/images/user_kl.svg'"
      />
      <img
        src="assets/static/images/search-intel/{{ node.d.platform }}-colour.svg"
        height="30"
      />
      <div class="inline-flex width-100">
        <div class="la-node-detail-labels-auto global-text-overflow-wrap">
          <div *ngIf="node.d.label">{{ 'Name' | translate }}:</div>
          <div *ngIf="node.d.status">{{ 'Status' | translate }}:</div>
        </div>
        <div class="la-node-detail-values">
          <div *ngIf="node.d.label">{{ node.d.label || '' }}</div>
          <div *ngIf="node.d.status">{{ node.d.status }}</div>
        </div>
      </div>
    </div>

    <!-- LOCATION -->
    <div *ngIf="node.d.type === 'Location'">
      <div class="inline-flex width-100">
        <div class="la-node-detail-labels-auto global-text-overflow-wrap">
          <div *ngIf="node.pos">Lat:</div>
          <div *ngIf="node.pos">Lon:</div>
          <div *ngIf="node.d.source">{{ 'Source' | translate }}:</div>
        </div>
        <div class="la-node-detail-values">
          <div>{{ node?.pos?.lat }}</div>
          <div>{{ node?.pos?.lng }}</div>
          <div>{{ node?.d?.source }}</div>
        </div>
      </div>
    </div>

    <!-- Person result -->
    <div class="la-node-details-analyse-results">
      <div *ngIf="noSocialResultsMessage">{{ 'No data' | translate }}</div>
      <div *ngFor="let person of socialSearchResults | slice: 0:1">
        <img
          *ngIf="person.image && person.image.url"
          [src]="getPhoto(person.image.url)"
          height="60"
          onerror="this.style.display = 'none'"
        />
        <div class="inline-flex width-100">
          <div class="la-node-detail-labels global-text-overflow-wrap">
            <div *ngIf="person.names.length">{{ 'Names' | translate }}:</div>
            <div *ngIf="person.telnos.length">MSISDNs:</div>
            <div *ngIf="person.gender">{{ 'Gender' | translate }}:</div>
            <!-- <div *ngIf="person.dob">{{'Date of birth' | translate}}:</div> -->
            <div *ngIf="person.usernames.length">
              {{ 'Usernames' | translate }}:
            </div>
            <div *ngIf="person.urls.length">
              {{ 'Profile URLs' | translate }}:
            </div>
            <div *ngIf="person.emails.length">{{ 'Emails' | translate }}:</div>
            <div *ngIf="person.originCountries.length">
              {{ 'Countries' | translate }}:
            </div>
            <div *ngIf="person.addresses.length">
              {{ 'Addresses' | translate }}:
            </div>
          </div>
          <div class="la-node-detail-values">
            <div *ngIf="person.names.length">{{ person.names.join(', ') }}</div>
            <div *ngIf="person.telnos.length">
              {{ person.telnos.join(', ') }}
            </div>
            <div *ngIf="person.gender">{{ getGenderLabel(person.gender) }}</div>
            <!-- <div *ngIf="person.dob">{{person.dob}}</div> -->
            <div *ngIf="person.usernames.length">
              {{ person.usernames.join(', ') }}
            </div>
            <div *ngIf="person.urls.length">{{ person.urls.join(', ') }}</div>
            <div *ngIf="person.emails.length">
              {{ person.emails.join(', ') }}
            </div>
            <div *ngIf="person.originCountries.length">
              {{ person.originCountries.join(', ') }}
            </div>
            <div *ngIf="person.addresses.length">
              <span *ngFor="let address of person.addresses"
                >{{ address.display }},{{ address.country }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bottom action buttons -->
    <div
      class="la-node-details-bottom-actions"
      *ngIf="
        !node.d.isTargetNode &&
        node.d.type !== 'Facebook Group' &&
        !(node.d.targetId && node.d.alias)
      "
    >
      <button
        mat-button
        class="cp-btn-primary capitalize-none"
        (click)="openTargetDialog()"
      >
        {{ 'Add to target' | translate }}
      </button>
    </div>
  </div>
</div>
