import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByTime',
})
export class SortByTimePipe implements PipeTransform {
  transform(value: { key: string }[], ...args: any[]): any {
    return value.sort(
      (a, b) =>
        parseInt(a.key.split('-')[0], 10) - parseInt(b.key.split('-')[0], 10)
    );
  }
}
