import { Injectable } from '@angular/core';
import { isCountryValid } from '@app/validators';
import {
  EntityRelationType,
  EntityType,
  Target,
} from '@trg-commons/data-models-ts';
import { Person } from 'datalayer/models/platform-models';
import { DataSource } from 'datalayer/models/platform-models/enums/data.source';
import { RequestOptions } from 'datalayer/services/base';
import { RefreshLocalCacheService } from 'datalayer/services/base/refresh-data.service';
import { PersonService } from 'datalayer/services/person/person/person.service';
import { TargetRecycleBinService } from 'datalayer/services/recycle-bin/target-recycle-bin.service';
import { ProfileService } from 'datalayer/services/social/profile/profile.service';
import { uniq } from 'lodash-es';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeAll, reduce, switchMap } from 'rxjs/operators';
import { TargetService } from 'src/app/services/target/target.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ProfilerService } from '../../services/profiler.service';
import { BreachData } from '../../shared/models/breach-data.model';
import { IMDataService } from './../../services/im-data.service';
import { MergeDataHelperService } from './../../services/merge-data-helper.service';

@Injectable({
  providedIn: 'root',
})
export class AboutTargetService {
  constructor(
    private profilerService: ProfilerService,
    private profileService: ProfileService,
    private personService: PersonService,
    private targetService: TargetService,
    private targetRecycleBinService: TargetRecycleBinService,
    private refreshLocalCacheService: RefreshLocalCacheService,
    private imDataService: IMDataService,
    private mergeDataHelperService: MergeDataHelperService
  ) {}

  updateAboutTarget(
    targetId: string,
    aboutData: TargetItem
  ): Observable<TargetItem> {
    return this.targetService.updateAboutSectionTarget(targetId, aboutData);
  }

  getAboutTargetData(targetId: string): Observable<TargetItem> {
    this.profileService.clearAboutData();
    return this.profilerService
      .getTargetData(targetId)
      .pipe(
        switchMap((currentAboutData) =>
          this.getSocialProfilesData(currentAboutData).pipe(
            switchMap((profileMergedToAboutData) =>
              this.imDataService
                .getIMData(profileMergedToAboutData, targetId)
                .pipe(
                  switchMap((imProfileMergedToAboutData) =>
                    this.getPersonData(imProfileMergedToAboutData, targetId)
                  )
                )
            )
          )
        )
      );
  }

  getSocialProfilesData(target: TargetItem): Observable<Partial<TargetItem>> {
    return this.profileService.aboutData.pipe(
      map((aboutData) =>
        this.mergeDataHelperService.mergeData(target, aboutData)
      )
    );
  }

  getPersonData(
    target: TargetItem,
    targetId: string
  ): Observable<Partial<TargetItem>> {
    const personFilter: RequestOptions = {
      filters: {
        source: [
          DataSource.SocialSearch,
          DataSource.DBSearch,
          DataSource.Somedus,
        ],
        targetId,
        type: EntityType.Person,
        relationType: [EntityRelationType.Plain],
      },
    };

    return this.personService.getAll(personFilter).pipe(
      map((res) => Object.values(res)),
      map((personData: Person[]) => {
        return personData.reduce((acc: Partial<TargetItem>, data: Person) => {
          acc = this.mergeDataHelperService.mergeData(acc, data);
          if (
            data.source === DataSource.Somedus &&
            data?.originCountries &&
            data.originCountries.length
          ) {
            acc = this.parseOriginCountries(acc);
          }
          return acc;
        }, target);
      })
    );
  }

  parseOriginCountries(aboutData?: {
    addresses?: string[];
    countries?: string[];
    originCountries?: string[];
  }) {
    aboutData.addresses = aboutData?.addresses || [];
    aboutData.countries = aboutData?.countries || [];

    // originCountries could be "city, country", or just "country" from SM response
    for (const country of aboutData['originCountries'] || []) {
      if (isCountryValid(country)) {
        aboutData.countries.push(country);
      } else {
        const possibleCityCountryArray = country.split(', ');
        for (const p of possibleCityCountryArray) {
          if (!isCountryValid(p)) {
            aboutData.addresses.push(country);
          } else {
            aboutData.countries.push(p);
          }
        }
      }
    }

    aboutData.countries = uniq(aboutData.countries);
    aboutData.addresses = uniq(aboutData.addresses);
    return aboutData;
  }

  getRecycleBin(targetId: string): Observable<Partial<Target>> {
    return this.targetRecycleBinService.getTargetRecycleBin(targetId).pipe(
      map((data) => {
        const addresses = data?.result?.addresses || [];

        if (addresses?.length) {
          data.result.addresses = this.mergeDataHelperService.getAddresses(
            data.result.addresses
          );
        }

        return data.result;
      })
    );
  }

  saveToRecycleBin(
    targetId: string,
    recycleDataCollection: Partial<TargetItem>,
    deletedValues: Partial<TargetItem>,
    recoveredSeeds: Partial<TargetItem>
  ): Observable<unknown> {
    return this.targetRecycleBinService.saveToTargetRecycleBin(targetId, {
      recycleData: recycleDataCollection,
      values: Object.entries(deletedValues)
        .filter(([_k, e]) => Array.isArray(e) && e.length)
        .reduce((acc: any, [key, values]: any) => {
          values.forEach((val) => {
            acc.push([val, key]);
          });

          return acc;
        }, []),

      reEnabledSeeds: Object.entries(recoveredSeeds).filter(
        ([_k, e]) => Array.isArray(e) && e.length
      ),
    });
  }

  getBreachData(target: TargetItem): Observable<{ [k: string]: BreachData[] }> {
    if (!Array.isArray(target?.emails)) {
      return of({});
    }

    const queries = target.emails.map((email) =>
      this.profilerService.getBreachData(email)
    );

    return forkJoin(queries).pipe(
      mergeAll(),
      reduce((acc: any, breachData: BreachData[]) => {
        if (!Array.isArray(breachData)) {
          return acc;
        }

        breachData.forEach((breach) => {
          if (!Array.isArray(acc[breach.email])) {
            acc[breach.email] = [];
          }

          acc[breach.email].push(breach);
        });

        return acc;
      }, {})
    );
  }
}
