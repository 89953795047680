import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActiveToast } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { IntelResultsNotificationsStore } from './intel-results-notification.store';
import 'src/app/shared/util/uniqueBy';
@Component({
  selector: 'app-intel-results-notification',
  templateUrl: './intel-results-notification.component.html',
  styleUrls: ['./intel-results-notification.component.scss'],
})
export class IntelResultsNotificationComponent implements OnInit {
  public toasts$: Observable<any[]>;
  public toast: ActiveToast<any>;
  id: string;
  isCompleted: boolean;

  constructor(
    private intelResultsNotificationsStore: IntelResultsNotificationsStore,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.toasts$ = this.intelResultsNotificationsStore.notifications$.pipe(
      map((ids) => {
        return ids[this.id].uniqueBy('status');
      }),
      tap((ids: any[]) => {
        this.isCompleted = ids.some((n) => n.status === 'complete');
      }),
      shareReplay()
    );
  }

  public removeNotification() {
    this.toast.toastRef.close();
    this.cdRef.markForCheck();
  }
}
