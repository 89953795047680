import { BaseApiService } from 'datalayer/services/base';
import { DataLayerModule } from 'datalayer/data-layer.module';
import { Media } from 'datalayer/models';
import { MediaDTO } from 'src/app/modules/data-layer/services/media/media/media-dto';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: DataLayerModule,
})
export class MediaApiService extends BaseApiService<Media, MediaDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient, MediaDTO);
  }

  public getModelName(): string {
    return Media.name;
  }
}
