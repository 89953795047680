<div class="mobile-sidenav-top">
  <img
    *ngIf="!isWhiteTheme"
    class="logo"
    [src]="getMobileViewLogo()"
    (click)="navigateToDashboard()"
  />
  <img
    class="back-arrow"
    src="assets/static/images/back_arrow2.svg"
    height="20"
    (click)="closeSidenav()"
  />
</div>
<div class="row center-xs mobile-sidenav-routes">
  <div
    *ngIf="theme !== 'UNLIMITED'; else changePasswordTemplate"
    class="route"
    (click)="navigateToOptions()"
  >
    {{ 'User options' | translate }}
  </div>
  <div class="route" (click)="navigateToSettings()">
    {{ 'User settings' | translate }}
  </div>
  <div class="route" (click)="navigateToTasks('false')">
    {{ 'Tasks' | translate }}
  </div>
  <div class="route" (click)="logout()">{{ 'Logout' | translate }}</div>
</div>

<ng-template #changePasswordTemplate>
  <div class="route" (click)="openChangePasswordDialog()">
    {{ 'Change password' | translate }}
  </div>
</ng-template>
