import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, HostBinding, HostListener, Input } from '@angular/core';

@Component({
  selector: '[intellectus-nav-bar-item]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nav-bar-item.component.html',
  styleUrls: ['./nav-bar-item.component.scss'],
  animations: [
    trigger('bgState', [
      state(
        'gray',
        style({
          'background-color': 'var(--gray-87, #dedede)',
        })
      ),
      state(
        'default',
        style({
          'background-color': 'inherit',
        })
      ),
      transition('gray <=> default', animate('100ms ease-in')),
    ]),
  ],
})
export class NavBarItemComponent {
  @Input() split = false;
  @Input() active = true;
  @Input() disabled = false;
  _index = 0;
  _animationState = false;

  /**
   * Attach split class if instructed by the `split` input.
   * This results to a vertical separator depiction |
   */
  @HostBinding('class.split') get shouldSplit() {
    return this.split;
  }

  /**
   * Attach active class if unstructed by the `active` input
   * This results to a highlighted just to let the user know
   * that the current item has been selected/routed.
   */
  @HostBinding('class.active') get shouldActivate() {
    return this.active;
  }

  /**
   * Attach disabled class if instructed by the `disabled` input.
   * This will basically disable any click event originating from
   * this current item.
   */
  @HostBinding('class.disabled') get isDisabled() {
    return this.disabled;
  }

  /**
   * Attach a unique identifier for self element. This can help
   * us write integration tests in the future.
   */
  @HostBinding('attr.id') get itemIdentifier() {
    return `intellectus-nav-bar-item-${this._index}`;
  }

  /**
   * Attach aria-disabled attribute upon truthy disabled input.
   * This will improve our a11y readiness.
   */
  @HostBinding('attr.aria-disabled') get isAriaDisabled() {
    return this.disabled;
  }

  /**
   * Mousedown listener to pick the gray animation state.
   */
  @HostListener('mousedown') onMouseDown() {
    this._animationState = true;
  }

  /**
   * Mouseup listener to pick the default animation state.
   */
  @HostListener('mouseup')
  @HostListener('mouseout')
  onMouseUp() {
    this._animationState = false;
  }
}
