<div class="queries-input-top">
  <div class="phones hide-flag">
    <input
      #phoneInput
      [formControl]="queryControl"
      [ngClass]="{ hasError: errorMsg, mobile: isMobileResolution }"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
      (ngModelChange)="checkInput()"
      ng2TelInput
      [ng2TelInputOptions]="{
        initialCountry: 'auto',
        allowDropdown: false,
        autoPlaceholder: 'off'
      }"
      (countryChange)="onCountryChange($event)"
      placeholder="{{
        'Insert a single or multiple MSISDNs or IMSIs separated by comma'
          | translate
      }}..."
      class="phones-input"
    />

    <div
      class="error"
      *ngIf="queryControl?.touched && queryControl?.errors?.required"
    >
      {{ 'Enter a number' | translate }}
    </div>
    <div class="error" *ngIf="queryControl?.errors?.validatePhone">
      {{
        'Insert a single or multiple MSISDNs or IMSIs separated by comma'
          | translate
      }}
    </div>
  </div>
  <mat-chip-list #chipList>
    <div class="entry" *ngFor="let target of targets; let i = index">
      <mat-chip [removable]="removable" (removed)="remove(target)">
        <span
          *ngIf="target.country"
          matPrefix
          class="{{ target.country }}"
        ></span>
        {{ target.value }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        class="imsi-input"
        #imsi
        [(ngModel)]="inputValue[i]"
        (mouseout)="inputInArray(imsi.value, i)"
        matInput
        [placeholder]="
          target.chipIsImsi
            ? ('Insert MSISDN' | translate)
            : ('Insert IMSI' | translate)
        "
        autocomplete="off"
      />
    </div>
  </mat-chip-list>
</div>
