<div
  class="gauge-area"
  [class.not-distributed]="!userBillingService.isDistributedBalance()"
  *ngIf="isMobile"
>
  <ng-container *ngrxLet="tenantBalanceType$ as tenantBalanceType">
    <ng-container *ngIf="tenantBalanceType">
      <ng-container
        *ngIf="
          !userBillingService.isDistributedBalance();
          else distributedGauge
        "
      >
        <app-mobile-gauge-item
          [gaugeType]="gaugeType"
          [isUnlimitedTheme]="isUnlimitedTheme"
          [gaugeValue]="gaugeValue"
          [gaugeAppendText]="gaugeAppendText"
          [foregroundColor]="mobileForegroundColor"
        >
          <ng-container *ngIf="!isUnlimitedTheme; else unlimitedText">
            <ng-container *ngIf="gaugeValue">
              {{ 'You have' | translate }} {{ gaugeValue }}%
              {{ 'available of your queries quota' | translate }}
            </ng-container>
          </ng-container>
          <ng-template #unlimitedText>{{
            'Unlimited' | translate
          }}</ng-template>
        </app-mobile-gauge-item>
      </ng-container></ng-container
    >
    <ng-container *ngIf="!tenantBalanceType">
      <div class="gauge-loader">
        <div class="animated-background-gauge"></div>
        <div class="animated-background-text"></div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="!isMobile">
  <div
    class="gauge-area"
    angulartics2On="click"
    [angularticsAction]="matomo.actions.viewCredits"
    [angularticsCategory]="matomo.categories.navBar"
    [matMenuTriggerFor]="!isMobile && creditMenu"
  >
    <ngx-gauge
      class="credit-meter"
      [size]="size"
      [thick]="strokeWeight"
      [type]="gaugeType"
      [backgroundColor]="backgroundColor"
      [foregroundColor]="foregroundColor"
      [value]="isUnlimitedTheme ? 0 : gaugeValue"
      [thresholds]="thresholdConfig"
      [append]="gaugeAppendText"
      [thick]="thick"
      [duration]="800"
    >
      <ngx-gauge-value>
        {{ isUnlimitedTheme ? '' : gaugeValue }}
      </ngx-gauge-value>
      <ngx-gauge-append class="label-size">
        {{ isUnlimitedTheme ? ('Unlimited' | translate) : gaugeAppendText }}
      </ngx-gauge-append>
    </ngx-gauge>
  </div>

  <mat-menu class="credit-menu" #creditMenu="matMenu">
    <ng-container
      *ngIf="
        (userBillingService.isTenantExpired() | async) === false;
        else creditsExpired
      "
    >
      <ng-container
        *ngIf="
          userBillingService.isDistributedBalance();
          then distributedGauge;
          else singleGauge
        "
      ></ng-container>

      <ng-template #singleGauge>
        <ngx-gauge
          [class.unlimited]="isUnlimitedTheme"
          [size]="140"
          [type]="gaugeType"
          [backgroundColor]="backgroundColor"
          [foregroundColor]="foregroundColor"
          [value]="isUnlimitedTheme ? 0 : gaugeValue"
          [thresholds]="thresholdConfig"
          [duration]="800"
          [append]="gaugeAppendText"
          [thick]="thick"
          [label]="gaugeLabel"
        >
        </ngx-gauge>
        <p *ngIf="!isUnlimitedTheme">
          {{ 'You have' | translate }} <span>{{ gaugeValue }}%</span>
          {{ 'available of your queries quota' | translate }}
        </p>
        <p [class.unlimited-text]="isUnlimitedTheme" *ngIf="isUnlimitedTheme">
          {{ 'Unlimited' | translate }}
        </p>
      </ng-template>
    </ng-container>
  </mat-menu>
</ng-container>

<ng-template #distributedGauge>
  <app-credit-pools-gauge
    *ngIf="creditPoolsGaugeSeries.length"
    [series]="creditPoolsGaugeSeries"
    [labels]="creditPoolsGaugeLabels"
    [gaugeLabel]="gaugeLabel"
    [gaugeValue]="gaugeValue"
    [maxSeries]="creditPoolsGaugeMaxSeries"
    [isMobile]="isMobile"
  ></app-credit-pools-gauge>

  <p class="no-quota-message" *ngIf="creditPoolsGaugeSeries.length === 0">
    {{ 'No credits assigned' | translate }}
  </p>
</ng-template>

<ng-template #creditsExpired>
  <span class="no-quota-message">{{ 'Credits are expired' | translate }}</span>
</ng-template>
