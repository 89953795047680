import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseToasterService } from '@fe-platform/shared-ui/intellectus';
import { TranslationService } from 'src/app/services/translation/translation.service';
import {
  AdIntSessionQueryEvent,
  AdIntSessionQueryStatus,
  AdIntWSMessage,
} from '../models/AdIntWSMessage.model';
import { BehaviorSubject } from 'rxjs';

const SESSION_COMPLETED_TOASTER_DURATION = 30000; // 30 seconds

@Injectable({
  providedIn: 'root',
})
export class AdIntWSService {
  private wsMsgSrc = new BehaviorSubject<AdIntWSMessage<any>>(null);
  wsMsg$ = this.wsMsgSrc.asObservable();

  constructor(
    private router: Router,
    private toasterService: BaseToasterService,
    private translationService: TranslationService
  ) {}

  handleWSMsg(msg: AdIntWSMessage<any>) {
    this.wsMsgSrc.next(msg);
    switch (msg.type) {
      case 'ADINT_SESSION_QUERY':
        this.handleSessionQuery(msg as AdIntWSMessage<AdIntSessionQueryEvent>);
        break;
      default:
        break;
    }
  }

  private handleSessionQuery(msg: AdIntWSMessage<AdIntSessionQueryEvent>) {
    if (msg.payload.status === AdIntSessionQueryStatus.Complete) {
      const toasterTitle = this.translationService.interpolate(
        `Session results for "#{sessionName}" are ready`,
        {
          sessionName: msg.payload.sessionName,
        }
      );

      this.toasterService.show(
        {
          title: toasterTitle,
          actions: [
            {
              label: this.translationService.translate('View'),
              callback: () => this.navigateToSession(msg),
            },
          ],
        },
        {
          timeOut: SESSION_COMPLETED_TOASTER_DURATION,
        }
      );
    }
  }

  private navigateToSession(msg: AdIntWSMessage<AdIntSessionQueryEvent>) {
    this.router
      .navigateByUrl('/account', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(
          ['account', 'adint', msg.payload.caseId ?? 'draft'],
          {
            queryParams: {
              selected: msg.payload.sessionId,
            },
          }
        );
      });
  }
}
