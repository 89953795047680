import { Media as PlatformMedia } from 'datalayer/models/platform-models';
import { BaseTargetEntity } from 'datalayer/models/platform-models/base/base-target-entity';
import {
  IMPlatformImages,
  PlatformImages,
} from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';

export class Media extends PlatformMedia implements BaseTargetEntity {
  targetId: string;
  profileId: string;
  platformImage?: PlatformImages | IMPlatformImages;
  authorId?: string;

  constructor(model?: Partial<Media>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }
}
