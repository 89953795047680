import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TransientUserWithJWTClaims } from '../authentication/types';
import { UserRoles } from '../user/user.model';

@Injectable({
  providedIn: 'root',
})
export class RoleManagementService {
  currentUser: TransientUserWithJWTClaims;
  currentUserIsAdmin: boolean;

  constructor(private localStorageService: LocalStorageService) {}

  //TODO Antonis - Remove old JWT compatibility stuff after AUTH transition is finished

  private hasRole(role: UserRoles): boolean {
    if (Array.isArray(this.currentUser.roles)) {
      // old JWT
      return this.currentUser.roles.indexOf(role) > -1;
    } else {
      return this.currentUser.realm_access.roles.indexOf(role) > -1;
    }
  }

  private setCurrentUser() {
    this.currentUser = this.localStorageService.getCurrentUser();
    if (Array.isArray(this.currentUser.roles)) {
      // old JWT, we do nothing
    } else {
      // new JWT has some values inside roles
      // that don't map to our roles, so we
      // filter them out.
      const roles = Object.keys(UserRoles).map((key) => UserRoles[key]);
      this.currentUser.roles = this.currentUser.realm_access.roles.filter((x) =>
        roles.includes(x)
      );
    }
  }

  userIsAdmin() {
    this.setCurrentUser();
    return this.hasRole(UserRoles.ADMIN);
  }

  userIsPowerUser() {
    this.setCurrentUser();
    return this.hasRole(UserRoles.POWERUSER);
  }

  userIsSupportUser() {
    this.setCurrentUser();
    return this.hasRole(UserRoles.SUPPORT);
  }

  userIsStandardUser() {
    this.setCurrentUser();
    return this.hasRole(UserRoles.USER);
  }
}
