export class GeoQuery {
  public roaming: boolean;
  public ageOfLocationInMinutes: unknown;
  public azimuth: unknown;
  public interception: unknown;
  public createdAt: string;
  public id: string;
  public tag: unknown;
  public device: {
    networkStatus: string;
    imei: unknown;
    phoneModel: string;
    softwareInfo: unknown;
  };
  public supportActiveQuery: boolean;
  public schedule: unknown;
  public updatedAt: string;
  public queryArgs: {
    billingId: unknown;
    imsi: unknown;
    status: unknown;
    telno: string;
    advancedLocationMethod: boolean;
  };
  public address: unknown;
  public gps: unknown;
  public status: string;
  public type: unknown;
  public accuracyMeters: number;
  public parentId: unknown;
  public group: unknown;
  public provider: {
    mcc: string;
    subscriptionStatus: string;
    imsi: unknown;
    mnc: string;
    name: string;
    telno: string;
    roamingName: unknown;
  };
  public featureType: unknown;
  public deletedAt: unknown;
  public lastKnownLocation: boolean;
  public updatedBy: unknown;
  public instantMessageProfiles: unknown;
  public callInfo: {
    billingId: unknown;
    callDirection: string;
    oncallNumber: string;
  };
  public cell: {
    cellId: unknown;
    mcc: string;
    lac: unknown;
    zone: string;
    lcid: unknown;
    mnc: string;
    enbid: unknown;
    cellType: unknown;
  };
  public location: unknown;
  public ipAddress: unknown;
  public nmr: {
    billingId: unknown;
    distance: unknown;
    trialterationLocation: unknown;
    combasBearing: unknown;
    trialterationAccuracyMeters: unknown;
    bearingDirection: unknown;
    sectorLocation: unknown;
    sectorSize: unknown;
  };
  public doNotProcess: unknown;
  public createdBy: string;
  public requestId: unknown;
  public locationReceivedAt: string;
  constructor(model?: Partial<GeoQuery>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}
