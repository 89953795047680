import { Entity } from '../base';

export class Education extends Entity {
  public name: string;
  public degree?: string;
  public startDate?: string;
  public endDate?: string;
  public url?: string;
  public logo?: string;
}
