import { Injectable } from '@angular/core';
import { ApplicationMainPageUrls } from '../shared/models/application-main-page-urls.enum';
import {
  ClAvailablePeriodsMapper,
  ClParameterType,
  RequestClPayload,
} from '../shared/models/call-log-request.model';
import { LocalStorageService } from './storage/local-storage.service';
import { TranslationService } from './translation/translation.service';

const ClParameterTypeEmailForMapper = {
  [ClParameterType.MSISDN]: 'Msisdn',
  [ClParameterType.IMSI]: 'Imsi',
  [ClParameterType.IMEI]: 'Imei',
};

interface EmailDataFormat {
  appUrl: string;
  alias: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateService {
  constructor(
    private localStorageService: LocalStorageService,
    private translationService: TranslationService
  ) {}

  callLogsRequestEmailFormat(
    requestCl: RequestClPayload,
    emailDataFormat: EmailDataFormat
  ): FormData {
    const currentUser = this.localStorageService.getCurrentUser();
    const fd = new FormData();
    const path = `${window.location.origin}/${ApplicationMainPageUrls.CORE}`;
    fd.append('title', this.translationService.translate('Call Log Request'));

    const key = Object.keys(requestCl.typeAndValue)[0];
    let parameterText = `${ClParameterTypeEmailForMapper[key]}: ${
      requestCl.typeAndValue[key]
    } <br /> Period: ${ClAvailablePeriodsMapper[requestCl.period]} months`;
    if (emailDataFormat.alias) {
      parameterText =
        parameterText +
        `<br />
      Alias: ${emailDataFormat.alias}<br />`;
    }
    fd.append(
      'content',
      `${parameterText} <br />
      Username: ${currentUser.identity}<br>
          Tenant ID: {{tenantid}}<br>
          Message: Sent call log request<br>
          URL: ${path}/${emailDataFormat.appUrl}<br>`
    );
    fd.append('username', `${currentUser.identity}<br>`);
    fd.append('message', `Sent call log request`);
    return fd;
  }
}
