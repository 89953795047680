<div class="activity-pattern">
  <div
    class="calendar-section-list"
    *ngIf="activityLists && activityLists.length > 0"
  >
    <ul>
      <li *ngFor="let section of activityLists">
        <i class="material-icons">check</i>
        {{ section.name | translate }}
      </li>
    </ul>
  </div>

  <div class="calendar">
    <div class="calendar-container">
      <div class="calendar-header">
        <ng-container *ngFor="let hour of hoursInterval">
          <div [ngClass]="['cell-size', cellClass]">{{ hour }}</div>
        </ng-container>
      </div>

      <div class="calendar-content">
        <div class="calendar-day-repeat">
          <div
            class="calendar-content-body"
            *ngFor="let activity of activities | keyvalue | sortByDay"
          >
            <div
              class="cell-body"
              *ngFor="let time of activity.value | keyvalue | sortByTime"
            >
              <div
                [ngClass]="['cell', 'cell-size', cellClass]"
                (mouseover)="onMouseOver($event, time.value)"
                (mouseleave)="onMouseLeave()"
                [ngStyle]="{
                  opacity: time.value.value | clCalendarValueToOpacity: max
                }"
              ></div>
            </div>
          </div>
        </div>

        <div class="calendar-legend-content">
          <div class="calendar-days" *ngIf="showCalendarDays">
            <div
              [ngClass]="['cell-size', cellClass]"
              *ngFor="let day of weekDays"
            >
              <div>
                {{ day | lowercase | translate | titlecase | slice: 0:3 }}
              </div>
            </div>
          </div>

          <div
            class="calendar-legend"
            [ngClass]="{ 'calendar-margin': !showCalendarDays }"
          >
            <ng-container *ngFor="let item of legend; let i = index">
              <div
                [ngClass]="['calendar-legend-item', cellClass]"
                [ngStyle]="{ opacity: item | clCalendarValueToOpacity: max }"
              ></div>
            </ng-container>
          </div>

          <div class="calendar-legend-value">
            <ng-container *ngFor="let item of legend; let i = index">
              <span [ngClass]="['zero', cellClass]" *ngIf="i === 0">0</span>
              <span [ngClass]="['legend-item-value', cellClass]">
                {{ shortNumbers ? (item | shortNumber: 0) : item }}
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-tooltip
  class="activity-popup"
  *ngIf="activityCell"
  (clickOutside)="activityCell = null"
  [ngStyle]="{ left: activityCell.left, top: activityCell.top }"
  [bodyTpl]="cellActivityInfo"
>
</app-tooltip>

<ng-template #cellActivityInfo>
  <app-generic-activity-cell
    *ngIf="activityCell.cell.type === enumActivityCellType.generic"
    [activityCell]="activityCell.cell"
  >
  </app-generic-activity-cell>

  <app-template-activity-cell
    *ngIf="activityCell.cell.type === enumActivityCellType.template"
    [activityCell]="activityCell.cell"
  >
  </app-template-activity-cell>
</ng-template>
