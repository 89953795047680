<div *ngIf="showHeader" class="header">
  <ng-content select="[header]"></ng-content>
</div>

<div
  class="body"
  [ngStyle]="{ height: bodyHeight ? bodyHeight + 'px' : 'auto' }"
>
  <ng-content select="[body]"></ng-content>
</div>

<div *ngIf="showFooter" class="footer">
  <ng-content select="[footer]"></ng-content>
</div>
