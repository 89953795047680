import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Profile } from 'datalayer/models/social';
import { isEmpty } from 'src/app/shared/util/isEmpty';
import { ImageService } from 'src/app/services/image/image.service';
import { BaseModalHeader } from 'src/app/shared/components/base-modal-component/base-modal-component.component';
import { PlatformImages } from '../../shared/models/profiler-dashboard-sections.model';

@Component({
  selector: 'app-social-profile-image-dialog',
  templateUrl: './social-profile-image-dialog.component.html',
  styleUrls: ['./social-profile-image-dialog.component.scss'],
})
export class SocialProfileImageDialogComponent implements OnInit {
  imageUrl: string;
  public platformImages = PlatformImages;
  headerDetails: BaseModalHeader;
  trimmedProfileName: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public profile: Profile,
    public dialogRef: MatDialogRef<SocialProfileImageDialogComponent>,
    private imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.imageUrl = this.getPhoto(this.profile);
    this.trimmedProfileName = this.profile.name?.trim();
    const { username, telno, email, source, url } = this.profile;
    this.headerDetails = {
      label: `${this.trimmedProfileName || username || telno || email || ''}`,
      platform: {
        id: source,
        url: url,
      },
    };
  }

  getPhoto(media, isThumbnail = false): string {
    return <string>(
      (!isEmpty(media.image)
        ? this.imageService.getPhotoUrl(media.image.url, isThumbnail)
        : media.imageUrl
        ? this.imageService.getPhotoUrl(media.imageUrl, isThumbnail)
        : 'assets/static/images/user.svg')
    );
  }

  close() {
    this.dialogRef.close();
  }
}
