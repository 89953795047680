<app-base-modal>
  <div header>
    {{ 'Create case' | translate }}
  </div>

  <div body>
    <form
      [formGroup]="createCaseForm"
      autocomplete="off"
      novalidate
      (ngSubmit)="newCase(createCaseForm.value)"
    >
      <ng-container *ngIf="!showAddTarget">
        <div class="profiler-target-form-input-group">
          <div class="profiler-target-form-label">
            {{ 'Name' | translate }}<span class="warn-text">*</span>
          </div>
          <input
            class="global-input profiler-target-form-input input-style-round"
            matInput
            type="text"
            formControlName="title"
            id="title"
            maxlength="255"
          />
          <div class="error-wrp">
            <mat-error *ngIf="titleError">{{ titleError }}</mat-error>
          </div>
        </div>

        <div class="profiler-target-form-input-group">
          <div class="profiler-target-form-label">
            {{ 'Description' | translate }}
          </div>
          <textarea
            matInput
            class="global-textarea profiler-create-case-textarea input-style-round"
            type="text"
            formControlName="description"
            id="description"
            maxlength="1024"
            rows="10"
          >
          </textarea>
          <mat-error *ngIf="descriptionError">{{ descriptionError }}</mat-error>
        </div>
      </ng-container>

      <ng-container *ngIf="showAddTarget">
        <div class="add-target-input">
          <label class="add-target-label">{{
            'Add targets' | translate
          }}</label>
          <mat-form-field class="example-chip-list input-box m-0">
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let target of selectedTargets"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeTargetChips(target)"
                [class.expired]="targetCreditsChargesEnabled && target.expired"
                tooltip="{{
                  targetCreditsChargesEnabled && target.expired
                    ? ('Target is expired' | translate)
                    : return
                }}"
                [tooltipDisabled]="
                  !targetCreditsChargesEnabled && !target.expired
                "
              >
                {{ target.alias }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>

              <input
                class="assistant-font"
                #targetInput
                formControlName="targetCtrl"
                placeholder="{{
                  'Add targets by searching for target\'s Alias.' | translate
                }}"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              />
            </mat-chip-list>

            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selectedTargetChips($event)"
            >
              <mat-option
                class="target-options"
                *ngFor="let target of filteredTargets | async"
                [value]="target"
              >
                <span class="target-img">
                  <ng-container *ngIf="target">
                    <app-target-img
                      [imageUrl]="target.photos[0]"
                      [fullName]="
                        target.names.length && target.names[0]
                          ? target.names[0]
                          : target.alias
                      "
                    >
                    </app-target-img>
                  </ng-container>
                </span>
                {{ target.alias }}</mat-option
              >
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </ng-container>
    </form>

    <section class="info-message" *ngIf="!hasCasesSlotsAvailable">
      <span class="title">
        <fe-platform-fa-pro-icon
          [family]="'regular'"
          [name]="'circle-info'"
        ></fe-platform-fa-pro-icon>
        <span>{{
          'You have reached the limit of the Cases used in the system.To add a new one, please go to the list of Cases and delete one.'
            | translate
        }}</span>
      </span>
      <button class="view-dashboard-btn" (click)="navigateToCases()">
        {{ 'View Cases' | translate }}
        <fe-platform-fa-pro-icon
          [family]="'regular'"
          [name]="'arrow-up-right-from-square'"
        ></fe-platform-fa-pro-icon>
      </button>
    </section>
  </div>

  <div footer class="row action-btn">
    <div class="col-xs-12 end-xs btn-wrp">
      <button
        mat-button
        [ngClass]="showAddTarget ? 'cp-btn-ghost' : 'cp-btn-primary'"
        [disabled]="!createCaseForm.valid || !hasCasesSlotsAvailable"
        (click)="toggleShowAddtarget()"
      >
        {{ (showAddTarget ? 'Back' : 'Next step') | translate }}
      </button>

      <button
        mat-button
        class="cp-btn-primary"
        [disabled]="!createCaseForm.valid"
        type="submit"
        angulartics2On="click"
        [angularticsAction]="matomo.actions.createNewCase"
        [angularticsCategory]="matomo.categories.caseAndTargets"
        (click)="newCase(createCaseForm.value)"
        *ngIf="showAddTarget"
      >
        {{ 'Create case' | translate }}
        <span *ngIf="isUnlimitedTheme" class="demo-sign">DEMO</span>
        <ng-container
          *ngIf="targetCreditsChargesEnabled && creditsForExpired > 0"
        >
          <span *ngIf="!isUnlimitedTheme">|</span>
          <span *ngIf="!isUnlimitedTheme" class="credits">{{
            creditsForExpired
          }}</span>
        </ng-container>
      </button>

      <div
        class="col-md-12"
        *ngIf="targetCreditsChargesEnabled && creditsForExpired > 0"
      >
        <p class="ubuntu-font note-txt">
          {{ 'Expired targets will be renewed and added to case' | translate }}
        </p>
      </div>
    </div>
  </div>
</app-base-modal>
