import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { INTELLECTUS_STEPPER_OPTIONS, StepperOptions } from '../stepper.conf';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

export enum StepperButtonAction {
  NEXT = 'next',
  PREV = 'prev',
  CANCEL = 'cancel',
}

const actionMap = {
  [StepperButtonAction.CANCEL]: null,
  [StepperButtonAction.NEXT]: 1,
  [StepperButtonAction.PREV]: -1,
};

@Directive({
  selector: '[intellectusStepperBtn]',
  standalone: true,
})
export class StepperBtnDirective implements OnInit, OnDestroy {
  @Input('intellectusStepperBtn') type!: StepperButtonAction;
  clickEmitter = new EventEmitter<number | null>();

  disabled$ = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();

  @HostListener('click')
  onClick() {
    this.clickEmitter.emit(actionMap[this.type]);
  }

  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    @Optional()
    @Inject(INTELLECTUS_STEPPER_OPTIONS)
    public stepperOptions?: StepperOptions
  ) {}

  ngOnInit(): void {
    if (this.stepperOptions?.enableFormValidation) {
      this.watchFormValidity();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private watchFormValidity() {
    this.disabled$.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      const btnContainer: HTMLElement = this._elRef.nativeElement;
      const btnNode = btnContainer.querySelector('button') || this._elRef;
      if (!btnNode) {
        console.warn('Could not find child button to disable/enable');
        return;
      }
      if (v) {
        this._renderer.setAttribute(btnNode, 'disabled', 'true');
      } else {
        this._renderer.removeAttribute(btnNode, 'disabled');
      }
    });
  }
}
