<div
  *ngIf="userService.currentUser$ | async; let currentUser"
  class="otp-section-wrapper"
>
  <div
    class="otp-section-header d-flex align-items-center justify-content-between"
  >
    <p class="title">{{ 'One Time Password' | translate }} (OTP)</p>
  </div>

  <div class="otp-section-body">
    <div class="activated-wrapper justify-content-between">
      <p class="activated label">{{ 'Activated' | translate }}</p>
      <mat-slide-toggle
        #otpSlider
        (change)="handleOTPChange($event)"
        class="activated checkbox"
      >
      </mat-slide-toggle>
    </div>
    <div class="divider"></div>

    <div *ngIf="currentUser.otpEnabled" class="method-radio-wrapper">
      <p class="method-radio-title">{{ 'Method' | translate }}</p>
      <div class="method-wrapper">
        <div class="mat-radio-wrapper">
          <label class="radio-button label">{{ 'Email' | translate }}</label>
          <div class="email-control-wrapper">
            <div class="email-control-info">
              <div class="otp-popup-element">
                <span
                  *ngIf="currentUser.email"
                  class="otp-popup-element-name"
                  >{{ currentUser.email }}</span
                >
                <span *ngIf="!currentUser.email" class="otp-popup-element-name"
                  >{{ 'Unavailable' | translate }}.
                </span>
                <span
                  *ngIf="!currentUser.email"
                  class="otp-popup-element-name add-phone-number"
                  (click)="showEmailInput = true"
                  >{{ 'Add' | translate }}</span
                >
              </div>

              <mat-slide-toggle
                #emailSlider
                [checked]="currentUser.otpChannel === 'email'"
                (change)="changeMobileOtpChannel($event, 'email')"
                [disabled]="!currentUser.email"
                class="otp-popup-list-element"
              >
              </mat-slide-toggle>
            </div>
            <div class="input-wrapper">
              <div
                *ngIf="showEmailInput"
                class="input-style-round relative email-input"
              >
                <input
                  class="assistant-font"
                  [formControl]="emailControl"
                  matInput
                  required
                  type="email"
                />
                <mat-icon
                  *ngIf="
                    emailControl && emailControl.dirty && emailControl.valid
                  "
                  class="icon success-icon"
                  >check_circle</mat-icon
                >
              </div>
              <mat-error
                class="otp-error"
                *ngIf="emailControl.dirty && emailControl.invalid"
                >{{ 'Enter a valid email' | translate }}</mat-error
              >
              <div *ngIf="showEmailInput" class="button-wrapper">
                <button
                  mat-button
                  class="margin-right10 cp-btn-ghost"
                  (click)="cancelEdit()"
                >
                  {{ 'Cancel' | translate }}
                </button>
                <button
                  mat-button
                  class="margin-right10 cp-btn-primary"
                  (click)="submitForm()"
                >
                  {{ 'Save' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mat-radio-wrapper">
          <label class="radio-button label">{{
            'Phone Number' | translate
          }}</label>
          <div class="phone-control-wrapper">
            <div class="phone-control-info">
              <div class="otp-popup-element">
                <span
                  *ngIf="currentUser.otpPhone"
                  class="otp-popup-element-name"
                  >{{ currentUser.otpPhone }}</span
                >
                <span
                  *ngIf="!currentUser.otpPhone"
                  class="otp-popup-element-name"
                  >{{ 'Unavailable' | translate }}.
                </span>
                <span
                  *ngIf="!currentUser.otpPhone"
                  class="otp-popup-element-name add-phone-number"
                  (click)="showPhoneInput = true"
                  >{{ 'Add' | translate }}</span
                >
              </div>
              <mat-slide-toggle
                #phoneSlider
                [checked]="currentUser.otpChannel === 'sms'"
                (change)="changeMobileOtpChannel($event, 'sms')"
                [disabled]="!currentUser.otpPhone"
                class="otp-popup-list-element"
              >
              </mat-slide-toggle>
            </div>
            <div class="input-wrapper">
              <div
                *ngIf="showPhoneInput"
                class="input-style-round relative phone-input"
              >
                <input
                  class="assistant-font"
                  [formControl]="phoneControl"
                  matInput
                  required
                />
                <mat-icon
                  *ngIf="
                    phoneControl && phoneControl.dirty && phoneControl.valid
                  "
                  class="icon success-icon"
                  >check_circle</mat-icon
                >
              </div>
              <mat-error
                class="otp-error"
                *ngIf="
                  phoneControl.dirty && phoneControl.hasError('invalidPhone')
                "
                >{{ 'Enter a valid phone' | translate }}</mat-error
              >
              <div *ngIf="showPhoneInput" class="button-wrapper">
                <button
                  mat-button
                  class="margin-right10 cp-btn-ghost"
                  (click)="cancelEdit()"
                >
                  {{ 'Cancel' | translate }}
                </button>
                <button
                  mat-button
                  class="margin-right10 cp-btn-primary"
                  (click)="submitForm()"
                >
                  {{ 'Save' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mat-radio-wrapper app-control-wrapper">
          <label class="radio-button label">{{
            'Application' | translate
          }}</label>
          <mat-slide-toggle
            #appSlider
            [checked]="currentUser.otpChannel === 'app'"
            (change)="changeMobileOtpChannel($event, 'app')"
            class="otp-popup-list-element"
          >
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</div>
