import {
  BalanceType,
  BillingLimitType,
  BillingPolicies,
} from 'src/app/shared/models/billing-action.model';
import { CreditPools } from 'src/app/shared/models/credit-pools.model';
import { OTPChannels } from './../../components/user-settings/user-settings.models';
export interface User extends UserBalance {
  id?: string;
  username: string;
  roles: Array<UserRoles>;
  email?: string;
  identity?: string;
  otpEnabled?: boolean;
  otpPhone?: string;
  otpChannel?: OTPChannels;
  isLocked?: boolean;
  isTemporary?: boolean;
  temporaryPassword?: string;
  passwordExpiresAt?: Date;
  firstLoginAt?: Date;
  usedActiveTimeMs?: number;
  smsNotifications?: boolean;
  features?: string[];
  accurateLocation?: boolean;
  emailNotifications?: boolean;
  userAttributes?: { key: string; value: string }[];
}

export interface UserBalance {
  policies?: BillingPolicies[];
  balanceType: BalanceType;
  currentBalance?: number | CreditPools;
  initialBalance: number | CreditPools;
  limitType: BillingLimitType;
  defaultPool?: number;
}

export enum UserRoles {
  ADMIN = 'admin',
  POWERUSER = 'poweruser',
  USER = 'user',
  SUPPORT = 'support',
}
