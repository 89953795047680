export enum IntelSearchArgTypes {
  HASHTAG = 'hashtag',
  EMAIL = 'email',
  VOTER_ID = 'voterId',
  URL = 'url',
  TELNO = 'telno',
  POSSIBLE_TELNO = 'possibleTelno',
  USER_ID = 'userId',
  USERNAME = 'username',
  NAME = 'name',
  PHOTO_URL = 'photoUrl',
  PHOTO = 'photo',
  NAME_WITH_MIDDLE_AND_SURNAME = 'nameWithMiddleAndSurname',
  NAME_WITH_SURNAME = 'nameWithSurname',
  CURP = 'curp',
  NIK = 'nik',
  IMEI = 'imei',
}
