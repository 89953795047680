import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-core-toastr-header',
  templateUrl: './core-toastr-header.component.html',
  styleUrls: ['./core-toastr-header.component.scss'],
})
export class CoreToastrHeaderComponent {
  @Output() close = new EventEmitter();
}
