import { Chat as PlatformChat } from '../platform-models/chat';

export class Chat extends PlatformChat {
  constructor(model?: Partial<Chat>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }
}
