<div class="row end-xs">
  <mat-icon
    class="clickable"
    matTooltip="{{ 'Close' | translate }}"
    (click)="onClose()"
    >close</mat-icon
  >
</div>
<div class="row center-xs request-location-history-dialog">
  <div class="location-history-title">
    {{ 'Location History Request' | translate }}
  </div>
  <div class="col-12">
    <div>
      <div *ngIf="requestData.target">
        <div class="label">{{ 'Target' | translate }}:</div>
        <div class="value">{{ requestData.target }}</div>
      </div>
      <mat-form-field>
        <mat-label>{{ 'Select period' | translate }}</mat-label>
        <mat-select
          [(value)]="selectedPeriod"
          (selectionChange)="selectPeriod($event)"
        >
          <mat-option
            class="lang-option-container"
            *ngFor="let period of periods"
            [value]="period"
          >
            {{ period | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="row center-xs request-location-history-dialog-actions">
  <button class="cancel" type="button" (click)="onClose()" mat-raised-button>
    {{ 'Cancel' | translate }}
  </button>
  <button
    class="ok"
    type="button"
    [disabled]="!selectedPeriod"
    (click)="sendEmail()"
    mat-raised-button
  >
    {{ 'Send' | translate }}
  </button>
</div>
