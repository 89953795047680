<div
  class="imsi-catcher-item"
  [class.selected-tactical-item]="
    selectedImsiCatcher && imsiCatcher.id === selectedImsiCatcher.id
  "
  (click)="selectImsiCatcher(imsiCatcher)"
  [class.imsi-catcher-item-even]="even"
  [class.imsi-catcher-item-odd]="!even"
>
  <div class="column checkbox-column">
    <mat-checkbox
      [checked]="checked"
      class="checkbox-tactical"
      (change)="multiselect($event, imsiCatcher)"
      (click)="$event.stopPropagation()"
    ></mat-checkbox>
  </div>
  <div class="column">
    {{ imsiCatcher.msisdn || '' }}
  </div>
  <div class="column">
    {{ imsiCatcher.imsi || '' }}
  </div>
  <div class="column">
    {{ imsiCatcher.mac || '' }}
  </div>
  <div class="column">
    {{
      imsiCatcher.createdAt
        ? (imsiCatcher.createdAt | date: 'dd.MM.yyyy HH:mm')
        : ''
    }}
  </div>
  <div class="column">
    {{ imsiCatcher.vendor || '' }}
  </div>
  <div class="column">
    {{ imsiCatcher.model || '' }}
  </div>
  <div class="column">
    {{ imsiCatcher.device || '' }}
  </div>
  <div class="column">
    {{ imsiCatcher.os || '' }}
  </div>
  <div class="column">
    {{ imsiCatcher.browser || '' }}
  </div>
  <div class="column">
    {{
      imsiCatcher.location &&
      imsiCatcher.location.coordinates &&
      imsiCatcher.location.coordinates.latitude
        ? imsiCatcher.location.coordinates.latitude.toFixed(4)
        : ''
    }}
    /
    {{
      imsiCatcher.location &&
      imsiCatcher.location.coordinates &&
      imsiCatcher.location.coordinates.longitude
        ? imsiCatcher.location.coordinates.longitude.toFixed(4)
        : ''
    }}
  </div>
  <div class="column">
    {{ imsiCatcher.target || '' }}
    <img
      [src]="
        selectedImsiCatcher && imsiCatcher.id === selectedImsiCatcher.id
          ? 'assets/static/images/add_target_inverted.svg'
          : 'assets/static/images/add_target.svg'
      "
      height="20"
      *ngIf="!imsiCatcher.target"
      class="clickable"
      matTooltip="{{ 'Add new Target' | translate }}"
      (click)="addTargetDialog(imsiCatcher)"
    />
  </div>
</div>
