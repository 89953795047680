import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthDataService } from 'src/app/services/authentication/auth-data.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { TranslationService } from 'src/app/services/translation/translation.service';

@Component({
  selector: 'app-two-step-verification',
  templateUrl: './two-step-verification.component.html',
  styleUrls: ['./two-step-verification.component.scss'],
})
export class TwoStepVerificationComponent extends BaseComponent {
  @Output() OTPvalue = new EventEmitter<string>();
  @Output() onUserChange = new EventEmitter<void>();
  @Input() userCredentials: { username: string; password: string };
  @Input() invalidOTPMsg: string;
  @Input() canReset2FA = true;

  visibility = false;
  otpInput = new UntypedFormControl();
  resetVerification = false;
  userEmail: string;
  loader: boolean;

  constructor(
    protected authService: AuthDataService,
    protected translationService: TranslationService
  ) {
    super();
  }

  navigateForm() {
    this.onUserChange.emit();
  }

  continueOTPAuthentication() {
    this.OTPvalue.emit(this.otpInput.value);
  }

  onResetVerification() {
    this.resetVerification = true;
    this.loader = true;
    this.authService
      .requestEmailAuthenticatorQrCode(this.userCredentials)
      .subscribe(
        (resetEmail) => {
          this.userEmail = resetEmail;
          this.loader = false;
        },
        (error) => {
          this.showMessage(
            this.translationService.translate(
              'Something went wrong. Please try OTP reset again.'
            )
          );
          this.onUserChange.emit();
        }
      );
  }
}
