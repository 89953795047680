import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { TranslationService } from 'src/app/services/translation/translation.service';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translateService: TranslationService) {
    super();

    this.getAndInitTranslations();
  }

  spanishRangeLabel(page: number, pageSize: number, length: number) {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.translateService.translate('of')} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.translateService.translate(
      'of'
    )} ${length}`;
  }

  getAndInitTranslations() {
    this.itemsPerPageLabel = this.translateService.translate('Items per page');
    this.nextPageLabel = this.translateService.translate('Next page');
    this.previousPageLabel = this.translateService.translate('Previous page');
    this.getRangeLabel = this.spanishRangeLabel;
    this.changes.next();
  }
}
