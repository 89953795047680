import { Component, Input } from '@angular/core';

@Component({
  selector: 'intellectus-text-link-button',
  templateUrl: './text-link-button.component.html',
  styleUrls: ['./text-link-button.component.scss'],
})
export class TextLinkButtonComponent {
  // TODO add icons and behaviour for hrefs.
  @Input() size: 'large' | 'default' | 'small' | 'xsmall' = 'default';
  @Input() isDisabled = false;
}
