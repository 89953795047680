interface Entry<T> {
  time: number;
  item: T;
}

export abstract class BaseCacheService<T> {
  private evictionTimeoutSeconds: number;

  private store: { [key: string]: Entry<T> } = {};

  private evictionInterval;

  constructor() {
    this.initialize();
  }

  public initialize(evictionTimeoutSeconds?: number) {
    this.evictionTimeoutSeconds = evictionTimeoutSeconds;
    this.store = {};
    if (this.evictionInterval) {
      clearInterval(this.evictionInterval);
    }

    if (undefined !== evictionTimeoutSeconds) {
      this.evictionInterval = setInterval(() => {
        this.evictExpired();
      }, 10 * evictionTimeoutSeconds * 1000);
    }
  }

  put(key: string, item: T): void {
    this.store[key] = { time: this.time(), item: item };
  }

  remove(key: string): void {
    delete this.store[key];
  }

  get(key: string): T | undefined {
    if (!this.store[key]) {
      return undefined;
    }

    const entry: Entry<T> = this.store[key];

    if (entry && this.entryHasExpired(entry)) {
      this.remove(key);
      return undefined;
    }

    return entry.item;
  }

  private entryHasExpired(entry: Entry<T>) {
    return entry.time < this.time() - this.evictionTimeoutSeconds;
  }

  private time(): number {
    return new Date().getTime() / 1000;
  }

  private evictExpired() {
    for (const key in this.store) {
      if (!this.entryHasExpired(this.store[key])) {
        continue;
      }

      this.remove(key);
    }
  }
}
