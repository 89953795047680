import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(array: Array<unknown>, separator = ','): string {
    if (!Array.isArray(array)) {
      return array;
    }

    return array.join(separator);
  }
}
