<div class="progress-bar">
  <div class="title-section">
    <h2 *ngIf="title">
      {{ title }}
    </h2>
    <p *ngIf="query">{{ query }}</p>
  </div>
  <div *ngIf="showResultsCount" class="results-count">
    <span>{{ 'Searched sources' | translate }}: {{ resultsCount }}</span>
  </div>
  <mat-progress-bar
    *ngIf="progressBarValue !== undefined"
    mode="determinate"
    [value]="progressBarValue"
    color="accent"
  ></mat-progress-bar>
  <div class="sections-progress">
    <ng-container *ngFor="let item of progressBarElements">
      <div *ngIf="item.name" class="title">
        <mat-icon *ngIf="item.value">check_circle</mat-icon>
        <img
          *ngIf="!item.value"
          src="assets/static/images/search-intel/loader.svg"
          alt="loading"
          width="24"
          height="24"
        />
        <span>{{ item.name | translate }}</span>
      </div>
    </ng-container>
  </div>
</div>
