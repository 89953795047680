import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProxyWsService } from 'src/app/modules/ad-ids/shared/proxy-ws.service';
import { EventChannel } from '@trg-commons/gio-data-models-ts';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';

@Injectable({
  providedIn: 'root',
})
export class WsService {
  constructor(private serverTsWsService: ProxyWsService) {}

  public onEvent<T>(event: string): Observable<T> {
    return this.serverTsWsService.getMessage().pipe(
      filter((message: { channel: EventChannel; body: [string, T] }) => {
        return this.messageIsFromGeofront2(event, message);
      }),
      map((message: { channel: string; body: [string, T] }) => {
        return transformSnakeToCamel(message.body[1]);
      })
    );
  }

  private messageIsFromGeofront2<T>(
    event: string,
    message: { channel: EventChannel; body: [string, T] }
  ): boolean {
    const channelsFromGeofront2 = [
      EventChannel.GeolocationQueries,
      EventChannel.EventLog,
      EventChannel.LiveSession,
      EventChannel.Ledger,
      EventChannel.GeofrontNotification,
      EventChannel.Satellite,
      EventChannel.RadicalMonitoring,
    ];

    return (
      channelsFromGeofront2.includes(message.channel) &&
      !!message.body &&
      Array.isArray(message.body) &&
      message.body.length >= 2 &&
      message.body[0].startsWith(event)
    );
  }
}
