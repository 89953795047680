<app-base-modal>
  <div header class="title-wrapper">
    <ng-container>
      <h2 class="webint-dialog-title" mat-dialog-title>
        {{ 'Disabled Feature' | translate }}
      </h2>
    </ng-container>
  </div>
  <div body class="webint-dialog-body">
    <ng-container>
      <div class="webint-text-wrapper">
        <p class="webint-dialog-desc">
          {{
            'Webint features are disabled. Please contact your customer success representative.'
              | translate
          }}
        </p>
      </div>
    </ng-container>
  </div>

  <div footer class="btn-wrp webint-btn-wrp">
    <ng-container>
      <button class="cp-btn-ghost" mat-button (click)="closeFeedback()">
        {{ 'Cancel' | translate }}
      </button>

      <button
        class="cp-btn-primary"
        mat-button
        (click)="contactCustomerSuccess()"
      >
        {{ 'Contact Customer Success' | translate }}
      </button>
    </ng-container>
  </div>
</app-base-modal>
