import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Place } from 'datalayer/models';
import { ImageService } from 'src/app/services/image/image.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';

@Component({
  selector: 'app-checkin-tooltip',
  templateUrl: './checkin-tooltip.component.html',
  styleUrls: ['./checkin-tooltip.component.scss'],
})
export class CheckinTooltipComponent implements OnInit {
  @Input() target: TargetItem;
  @Input() checkin: Place;
  public targetImageThumbnailUrl: string | SafeResourceUrl;
  public checkinImageThumbnailUrl: string | SafeResourceUrl;

  constructor(private imageService: ImageService) {}

  ngOnInit(): void {
    if (this.target.photos?.length) {
      this.targetImageThumbnailUrl = this.imageService.getPhotoUrl(
        this.target.photos[0],
        true
      );
    }
    this.checkinImageThumbnailUrl = this.imageService.getPhotoUrl(
      this.checkin.image?.url,
      true
    );
  }
}
