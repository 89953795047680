import { BaseService, EmptyCacheService } from 'datalayer/services/base';
import { Injectable } from '@angular/core';
import { Chat } from '../../models/chat';
import { ChatDTO } from './chat-dto';
import { ChatApiService } from './chat-api.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends BaseService<
  Chat,
  ChatDTO,
  ChatApiService,
  EmptyCacheService<Chat>
> {
  constructor(
    apiService: ChatApiService,
    localCacheService: EmptyCacheService<Chat>
  ) {
    super(apiService, localCacheService);
  }
}
