import { ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { BaseComponent } from 'src/app/base/base.component';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';

@Component({
  selector: 'app-simple-query',
  templateUrl: './simple-query.component.html',
  styleUrls: ['./simple-query.component.scss'],
})
export class SimpleQueryComponent extends BaseComponent implements OnInit {
  imsi = '';
  imsis = [];
  inputValue = [];
  targets = [];
  invalidValues = [];
  separatorKeysCodes: number[] = [ENTER];
  removable = true;
  addOnBlur = true;
  errorMsg = false;
  errorMsgForEmptyNumber = false;
  queryCountry: string;
  skin;
  isMobileResolution;
  phoneNumberUtil = PhoneNumberUtil.getInstance();

  queryControl = new UntypedFormControl(null, [
    Validators.required,
    (control: UntypedFormControl) => this.validatePhone(control),
  ]);

  @Output() validInput: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private queryService: QueryService,
    private targetService: TargetService,
    private applicationStateService: ApplicationStateService,
    private translationService: TranslationService
  ) {
    super();
  }

  ngOnInit() {
    this.invalidValues = [];
    this.isMobileResolution =
      this.applicationStateService.getIsMobileResolution();
    const queriesSubmission = this.queryService.queriesFlag.subscribe(
      (flag) => {
        if (flag) {
          this.submitQuery();
        }
      }
    );

    const autoFillSubscription = this.queryService.autoFillQueries.subscribe(
      (queries) => {
        if (this.queryService.isAutoFillQueries.value) {
          this.targets = [];
          queries.map(({ queryArgs }) => {
            const { telno, imsi } = queryArgs;
            if (telno && !this.chipExists(telno)) {
              const parsedNumber = this.phoneNumberUtil.parse(telno, '');
              const countryFlag = this.phoneNumberUtil
                .getRegionCodeForNumber(parsedNumber)
                ?.toLocaleLowerCase();

              this.targets.push({
                value: telno,
                chipIsImsi: false,
                imsi,
                telno,
                country: countryFlag ? `iti__${countryFlag}` : undefined,
              });
              this.errorMsg = false;
            }

            if (imsi && !this.chipExists(imsi)) {
              this.targets.push({
                value: imsi,
                chipIsImsi: true,
                imsi,
                telno,
              });
              this.errorMsg = false;
            }
            this.checkValues();
          });
        }
      }
    );

    const clearInputSubscription = this.queryService.clearQueryInput.subscribe(
      (flag) => {
        if (flag) {
          this.targets = [];
        }
      }
    );

    const skinSubscription = this.applicationStateService.skin.subscribe(
      (skin: string) => {
        this.skin = skin;
      }
    );

    this.subscriptions.push(
      queriesSubmission,
      autoFillSubscription,
      clearInputSubscription,
      skinSubscription
    );
  }

  add(event: MatChipInputEvent): void {
    if (this.queryControl.errors) {
      return;
    }

    const { input, value } = event;
    const array = value.split(',');

    array.forEach((value) => this.addChip(value, input));
  }

  addChip(value, input) {
    const trimmedValue = value.trim();

    if (trimmedValue) {
      if (this.queryService.isValidImsi(trimmedValue)) {
        if (!this.chipExists(trimmedValue)) {
          this.targets.push({
            value: trimmedValue,
            imsi: trimmedValue,
            chipIsImsi: true,
            telno: '',
          });
          this.errorMsg = false;
          this.checkValues();
        }

        input.value = '';
      } else {
        this.isValidPhone(input, trimmedValue);
      }
    }
  }

  checkValues() {
    if (this.targets.length) {
      this.validInput.emit(true);
    } else {
      this.validInput.emit(false);
    }
  }

  checkInput() {
    if (!this.targets.length && this.queryControl.value === '') {
      this.validInput.emit(false);
    }
  }

  inputInArray(value, index) {
    if (this.targets[index].chipIsImsi) {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      try {
        const validPhone = this.targetService.getValidPhone(
          phoneNumberUtil,
          value
        );
        if (validPhone) {
          this.targets[index].telno = validPhone;
        } else {
          this.inputValue[index] = '';
        }
      } catch (e) {
        this.inputValue[index] = '';
      }
    } else if (this.queryService.isValidImsi(value)) {
      this.imsis[index] = value;
      this.targets[index].imsi = value;
    } else {
      this.inputValue[index] = '';
    }
  }

  isValidPhone(input, value) {
    try {
      const validTelno = this.targetService.getValidPhone(
        this.phoneNumberUtil,
        value
      );
      let countryFlag = '';

      if (validTelno) {
        this.errorMsg = false;
        this.errorMsgForEmptyNumber = false;
        if (!this.chipExists(validTelno)) {
          const parsedNumber = this.phoneNumberUtil.parse(validTelno, '');
          countryFlag =
            this.phoneNumberUtil.getRegionCodeForNumber(parsedNumber);

          this.targets.push({
            value: validTelno,
            country: countryFlag
              ? `iti__flag iti__${countryFlag.toLocaleLowerCase()}`
              : undefined,
            telno: validTelno,
          });
          this.errorMsg = false;
          this.checkValues();
        }
        input.value = '';
      } else {
        this.showMessage(
          `${value} ${this.translationService.translate(
            'is not a valid MSISDN.'
          )}`
        );
        this.errorMsg = true;
        this.remove(value);

        if (this.invalidValues.includes(value)) {
          return;
        }
        this.invalidValues.push(value);
      }
    } catch (e) {
      this.showMessage(
        `${value} ${this.translationService.translate(
          'is not a valid MSISDN.'
        )}`
      );
      this.errorMsg = true;

      if (this.invalidValues.includes(value)) {
        return;
      }
      this.invalidValues.push(value);
    }
  }

  chipExists(value) {
    let entryExists = false;
    for (const entry of this.targets) {
      if (entry.value === value) {
        entryExists = true;
        break;
      }
    }
    return entryExists;
  }

  onCountryChange(event) {
    // get country code
    this.queryCountry = event.iso2;
  }

  remove(target: string): void {
    const index = this.targets.indexOf(target);

    if (index >= 0) {
      this.targets.splice(index, 1);
    }

    if (!this.targets.length) {
      this.validInput.emit(false);
    }
  }

  submitQuery() {
    this.invalidValues = [];
    const targetsValue = [];
    for (let i = 0; i < this.targets.length; i++) {
      targetsValue.push({
        telno: this.targets[i].telno ? this.targets[i].telno : '',
        imsi: this.targets[i].imsi ? this.targets[i].imsi : '',
      });
    }

    if (targetsValue.length) {
      this.errorMsgForEmptyNumber = false;
      this.queryService.numbersToBeQueried.next(targetsValue);
      this.queryService.queriesFlag.next(false);
      this.queryService.isAutoFillQueries.next(false);
    } else {
      this.errorMsgForEmptyNumber = true;
    }
  }

  private validatePhone(control: UntypedFormControl) {
    const arrayValue =
      control.value?.split(',').map((item) => item.trim()) || [];

    if (!arrayValue.length) {
      return;
    }

    try {
      arrayValue.forEach((value) => {
        if (this.queryService.isValidImsi(value)) {
          return;
        }

        if (!this.targetService.getValidPhone(this.phoneNumberUtil, value)) {
          throw 'Phone number not valid';
        }
      });
    } catch (e) {
      return {
        validatePhone: {
          valid: false,
        },
      };
    }
  }
}
