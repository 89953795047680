import { Component, Inject, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { User } from 'src/app/services/user/user.model';
@Component({
  selector: 'app-reset-password-admin',
  templateUrl: './reset-password-admin.component.html',
  styleUrls: ['./reset-password-admin.component.scss'],
})
export class ResetPasswordAdminComponent extends BaseComponent {
  onReset: EventEmitter<User> = new EventEmitter<User>();

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) {
    super();
  }

  onResetPassword() {
    this.onReset.emit(this.user);
  }
}
