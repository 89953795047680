import { DataSource, EntityRelationType } from '../enums';

/*
 * EntityRelation model used as payload on the CollectedData Event Body
 *
 */
export class EntityRelation {
  public id?: string;
  public createdAt: Date;
  public updatedAt?: Date;
  public type: EntityRelationType;
  public source: DataSource;
  public fromEntity?: any; // Type should be RawEntity
  public toEntity?: any; // Type should be RawEntity
}
