const mexicoCoords = { lat: 19.451054, lon: -99.125519 };

const defaultCountries = [
  {
    name: 'mexico',
    lat: 19.451054,
    lon: -99.125519,
  },
  {
    name: 'argentina',
    lat: -38.4193,
    lon: -63.5989,
  },
];

export { defaultCountries, mexicoCoords };
