<app-dialog>
  <div header>
    {{ title }}
  </div>

  <div body>
    <p>{{ message }}</p>
  </div>

  <div footer>
    <div class="btn-wrp">
      <button mat-button class="cp-btn-ghost" (click)="close()">
        {{ 'Cancel' | translate }}
      </button>
      <app-credit-cost-button
        [action]="action"
        [countOfActionsCharged]="countOfActionsCharged"
        [buttonText]="'Renew'"
        [active]="true"
        [stylingClasses]="'cp-btn-primary cp-search-btn active'"
        (clicked)="onSuccess()"
      ></app-credit-cost-button>
    </div>
  </div>
</app-dialog>
