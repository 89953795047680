<mat-accordion>
  <mat-expansion-panel
    *ngFor="let filterEl of relationFilterElements"
    class="la-custom-panel relations-panel"
  >
    <mat-expansion-panel-header class="la-custom-panel-header">{{
      filterEl.label | translate
    }}</mat-expansion-panel-header>
    <div *ngFor="let filter of filterEl.filters" class="relative">
      <mat-checkbox
        [checked]="filteredTypes.includes(filter.linkType)"
        *ngIf="data[filter.linkType].length"
        (change)="
          addToFilteredTypes(
            $event.checked,
            filter.value,
            filter.relation ? filter.relation : false
          )
        "
        class="inline-block la-panel-checkbox"
        (change)="
          prepareSliderData(
            filter.linkType,
            filter.label,
            filter.filterBySlider,
            $event
          )
        "
        >{{ filter.label | translate }}
      </mat-checkbox>
      <img
        *ngIf="filteredTypes.includes(filter.linkType) && filter.filterBySlider"
        class="clickable slider-filter-icon"
        src="{{ imagesPath }}/link-analysis/slider_filter_enabled.svg"
        height="12"
        (click)="prepareSliderData(filter.linkType, filter.label, true)"
      />
      <div
        *ngIf="showFilterSlider && filterSliderData.type === filter.linkType"
      >
        <ng-template *ngTemplateOutlet="filterSlider"></ng-template>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #filterSlider>
  <div class="relation-filter-slider">
    <div class="slider-title">
      {{ filterSliderConfig.titleStart | translate }}
      <b>{{ filterSliderConfig.value }} </b
      >{{ filterSliderConfig.titleEnd | translate }}.
    </div>
    <mat-slider
      #slider
      class="width-100 slider"
      [invert]="filterSliderConfig.invert"
      [max]="filterSliderConfig.max"
      [min]="filterSliderConfig.min"
      [step]="filterSliderConfig.step"
      [thumbLabel]="filterSliderConfig.thumbLabel"
      [value]="filterSliderConfig.value"
      (slideend)="onFilterSliderChange(slider.value)"
      (click)="onFilterSliderChange(slider.value)"
    >
    </mat-slider>
    <div class="row">
      <div class="col-4 start-xs">{{ filterSliderConfig.min }}</div>
      <div class="col-4 center-xs slider-value-border">
        {{ filterSliderConfig.value }}
      </div>
      <div class="col-4 end-xs">{{ filterSliderConfig.max }}</div>
    </div>
  </div>
</ng-template>
