<div class="d-flex flex-column more-details-section">
  <div class="dialog-section back" *ngIf="!hideBackButton">
    <span
      ><a (click)="goBack()" class="link"
        >< {{ 'Back' | translate | lowercase }}</a
      ></span
    >
  </div>
  <div class="dialog-section chat-summary">
    <span class="d-flex justify-content-between"
      ><strong>{{ 'Chat summary' | translate }}</strong>
      <span *ngIf="dateRangeFrom && dateRangeTo"
        >{{ dateRangeFrom | date: 'dd MMM yyyy' }} -
        {{ dateRangeTo | date: 'dd MMM yyyy' }}</span
      ></span
    >
    <div>{{ relatedPersonChat?.chatSummary }}</div>
  </div>
  <div class="dialog-section top-keywords">
    <span
      ><strong>{{ 'Top Keywords' | translate }}</strong></span
    >
    <div>
      <div *ngFor="let keyword of keywords" class="chips">
        {{ keyword }}
      </div>
    </div>
  </div>
  <div class="dialog-section chat-summary">
    <span
      ><strong>{{ 'Chat Sentiment' | translate }}</strong></span
    >
    <div>{{ relatedPersonChat?.chatSentimentAnalysis }}</div>
  </div>
</div>
