<ng-container
  *ngIf="creditDetailsService.getCreditBarTooltip() | async as tooltipData"
>
  <div
    class="bar-tooltip"
    [ngStyle]="{ top: tooltipData.positionY, left: tooltipData.positionX }"
  >
    <p class="tooltip-title">{{ tooltipData.title | translate }}</p>
    <div class="row" *ngFor="let usageRow of tooltipData.creditsUsage">
      <div *ngIf="usageRow.poolName" class="col-6 attribute">
        {{ usageRow.poolName | translate }}
      </div>
      <div class="col-6 value">
        <ng-container *ngIf="usageRow.limitType !== LimitTypeUsageRestricted"
          >{{ usageRow.usage | number: '1.1' }} {{ 'of' | translate }}
        </ng-container>
        <ng-container>{{ usageRow.from | number: '1.1' }}</ng-container>
      </div>
    </div>
  </div>
</ng-container>
