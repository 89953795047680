<div
  *ngrxLet="isMobile$ as isMobile"
  class="drawer"
  [class.mobile-hidden]="state === States.MINIMIZED"
  [style.width]="options?.width"
  intellectusIsMobile
>
  <div class="header">
    <div class="empty-block" *ngIf="isMobile"></div>
    <div (click)="toggleState()">
      <ng-container
        *ngIf="
          !isMobile &&
          options?.headerIcon &&
          (!options?.badge ||
            (options?.showLoadingOnMinimized && state !== States.MINIMIZED) ||
            (state === States.MINIMIZED &&
              options?.badge &&
              (options.badge | async) === 0))
        "
        [ngTemplateOutlet]="icon"
        [ngTemplateOutletContext]="{
          icon: options?.headerIcon || minimizeIcon
        }"
      ></ng-container>

      <fe-platform-fa-pro-icon
        *ngIf="
          options?.showLoadingOnMinimized &&
          state === States.MINIMIZED &&
          options?.badge &&
          (options.badge | async) > 0 &&
          !isMobile
        "
        [spin]="true"
        [family]="'duotone'"
        [name]="'spinner-third'"
        [primaryColor]="'#3365FF'"
        [primaryOpacity]="1"
        [secondaryColor]="'#BECDF8'"
      ></fe-platform-fa-pro-icon>
      <p class="drawer-title" *ngIf="options?.title">
        {{ options.title | async }}
      </p>
      <span
        class="badge"
        *ngIf="options?.badge && (options.badge | async) > 0 && !isMobile"
        >{{ options.badge | async }}</span
      >
    </div>
    <div>
      <ng-container *ngIf="!isMobile">
        <intellectus-icon-text-button
          [buttonIcon]="minimizeIcon"
          [buttonSize]="'small'"
          [rotate]="state === States.MINIMIZED"
          [rotateWithTransition]="false"
          (buttonClick)="toggleState()"
        ></intellectus-icon-text-button>
        <intellectus-icon-text-button
          [buttonIcon]="options?.expandIcon || expandIcon"
          [buttonSize]="'xsmall'"
          (buttonClick)="expand()"
        ></intellectus-icon-text-button>
        <intellectus-icon-text-button
          [buttonIcon]="closeIcon"
          [buttonSize]="'small'"
          (buttonClick)="close()"
        ></intellectus-icon-text-button>
      </ng-container>

      <intellectus-icon-text-button
        *ngIf="isMobile"
        [buttonIcon]="closeIcon"
        [buttonSize]="'small'"
        (buttonClick)="toggleState()"
      ></intellectus-icon-text-button>
    </div>
  </div>
  <div [hidden]="state === States.MINIMIZED && !isMobile">
    <ng-template #container> </ng-template>
  </div>
</div>
<ng-template #icon let-icon="icon">
  <fe-platform-fa-pro-icon
    [family]="icon.family"
    [name]="icon.name"
  ></fe-platform-fa-pro-icon>
</ng-template>
