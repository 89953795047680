import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, map, skipWhile } from 'rxjs';
import { AuthState } from './auth-state.enum';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.authState$.pipe(
      skipWhile((state) => state === AuthState.unknown),
      map((state) => {
        if (state === AuthState.authenticated) {
          this.router.navigate([this.authService.getDefaultRedirectRoute()]);
          return false;
        }
        return true;
      })
    );
  }
}
