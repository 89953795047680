import { AvailableConcurrentCredits } from '../gauge-types';

export const getWarningMessageForCasesAndTargets = (
  concurrentLimitBalance: AvailableConcurrentCredits[]
): string | null => {
  const targetBalance = concurrentLimitBalance[0].currentBalance;
  const caseBalance = concurrentLimitBalance[1].currentBalance;
  if (targetBalance === 0 && caseBalance === 0) {
    return 'You ran out of available targets and cases. Contact your local administrator.';
  } else if (targetBalance === 0) {
    return 'You ran out of available targets. Contact your local administrator.';
  } else if (caseBalance === 0) {
    return 'You ran out of available cases. Contact your local administrator.';
  } else {
    return null;
  }
};
