import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { PlatformSettingsModalComponent } from './../platform-settings-modal/platform-settings-modal.component';

@Component({
  selector: 'app-mobile-user-settings',
  templateUrl: './mobile-user-settings.component.html',
  styleUrls: ['./mobile-user-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileUserSettingsComponent extends PlatformSettingsModalComponent {
  constructor(protected readonly translationService: TranslationService) {
    super(translationService);
    this.config.title = this.translationService.translate('Account');
  }
}
