<!-- actions, filter -->
<div class="row end-md">
  <div class="li-actions">
    <img
      *ngIf="groupByView"
      src="assets/static/images/back_arrow.svg"
      height="20"
      class="clickable refresh"
      (click)="refreshLawEnforcementData()"
      matTooltip="{{ 'Refresh' | translate }}"
    />
    <img
      src="assets/static/images/add.svg"
      height="20"
      class="clickable refresh"
      (click)="openDialog()"
      matTooltip="{{ 'Query' | translate }}"
    />
    <div class="group-by">
      <div class="global-select-label">{{ 'Group By' | translate }}</div>
      <mat-select
        [(value)]="groupBy"
        class="global-select"
        (selectionChange)="filterView()"
      >
        <mat-option
          *ngFor="let filter of groupByFields"
          [value]="filter.value"
          >{{ filter.label }}</mat-option
        >
      </mat-select>
    </div>
    <div>
      <div class="global-select-label">{{ 'Filter' | translate }}</div>
      <div class="expanding-search">
        <input #searchInput matInput type="search" (keyup)="onKeyUp($event)" />
      </div>
    </div>
  </div>
</div>

<div class="law-enforcement-list">
  <div class="row labels-row">
    <div class="column checkbox-column">
      <!-- comment out select all until we find a solution for the multiple map pins crush
      <mat-checkbox (change)="multiselect($event)"></mat-checkbox> -->
    </div>
    <div class="column">
      <span class="col-label">{{ 'MSISDN' | translate }}</span>
      <span class="sort-icon clickable" (click)="sort('telno')">
        <img
          src="assets/static/images/sort.svg"
          height="15"
          width="15"
          matTooltip="{{ 'Sort' | translate }}"
        />
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'IMSI' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('imsi')">
        <img
          src="assets/static/images/sort.svg"
          height="15"
          width="15"
          matTooltip="{{ 'Sort' | translate }}"
        />
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'IMEI' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('imei')">
        <img
          src="assets/static/images/sort.svg"
          height="15"
          width="15"
          matTooltip="{{ 'Sort' | translate }}"
        />
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Lat/Lon' | translate }} </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Direction' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('isIncoming')">
        <img
          src="assets/static/images/sort.svg"
          height="15"
          width="15"
          matTooltip="{{ 'Sort' | translate }}"
        />
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Duration (s)' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('durationSeconds')">
        <img
          src="assets/static/images/sort.svg"
          height="15"
          width="15"
          matTooltip="{{ 'Sort' | translate }}"
        />
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Date' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('date')">
        <img
          src="assets/static/images/sort.svg"
          height="15"
          width="15"
          matTooltip="{{ 'Sort' | translate }}"
        />
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Type' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('type')">
        <img
          src="assets/static/images/sort.svg"
          height="15"
          width="15"
          matTooltip="{{ 'Sort' | translate }}"
        />
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Target' | translate }} </span>
    </div>
  </div>
  <mat-progress-bar
    *ngIf="loader"
    [color]="'accent'"
    [mode]="'query'"
    [value]="50"
    [bufferValue]="75"
  >
  </mat-progress-bar>
  <!-- Default View Data-->
  <ng-container *ngIf="!groupByView">
    <app-li-log-table-item
      *ngFor="let lawEnforcement of paginatedLawEnforcementData; let i = index"
      (emittedLawEnforcement)="onSelectedLawEnforcement($event)"
      (emittedMultiselectLawEnforcement)="onMultiselectLawEnforcement($event)"
      [lawEnforcement]="lawEnforcement"
      [even]="i % 2 === 0"
      [checked]="checked"
    >
    </app-li-log-table-item>
  </ng-container>
  <!-- Group View Data-->
  <ng-container *ngIf="groupByView">
    <mat-accordion class="groups">
      <mat-expansion-panel *ngFor="let group of groupedData">
        <mat-expansion-panel-header>
          <mat-panel-title
            >{{ group.groupName === 'null' ? 'Unknown' : group.groupName }} ({{
              group.items.length
            }})
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="group-panel" [style.max-height]="getGroupHeight()">
          <app-li-log-table-item
            *ngFor="let lawEnforcement of group.items; let i = index"
            (emittedLawEnforcement)="onSelectedLawEnforcement($event)"
            (emittedMultiselectLawEnforcement)="
              onMultiselectLawEnforcement($event)
            "
            [lawEnforcement]="lawEnforcement"
            [even]="i % 2 === 0"
            [checked]="checked"
          >
          </app-li-log-table-item>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
  <div
    *ngIf="!lawEnforcementData || !lawEnforcementData.length"
    class="col-12 center-xs"
  >
    {{ 'No Data' | translate }}.
  </div>
</div>
<mat-paginator
  *ngIf="!groupByView"
  [length]="paginator.totalSize"
  [pageIndex]="paginator.currentPage"
  [pageSize]="paginator.pageSize"
  (page)="onPaginatePageChange($event)"
  class="li-paginator"
>
</mat-paginator>
