import { Entity } from '../platform-models';
import { AvatarPerson } from './avatar-person';
import { AvatarProxy } from './avatar-proxy';
import { AvatarSocialType } from './avatar-social-type.enum';
import { AvatarSocial } from './avatar-social.interface';
import { AvatarVisibleSocialState } from './avatar-visible-social-state.enum';

export class Avatar extends Entity {
  state: AvatarVisibleSocialState;
  latestActivityInfo: { name: string; startedAt: Date };
  phone?: number;
  email: string;
  socials: AvatarSocialType[];
  google?: AvatarSocial;
  proton?: AvatarSocial;
  youtube?: AvatarSocial;
  facebook?: AvatarSocial;
  mamba?: AvatarSocial;
  proxy: AvatarProxy;
  person: AvatarPerson;
  notes?: string;
  tags?: Array<string>;
  countryCode: string;
  updatedAgo: string;
  deleted?: boolean;
  ownerId?: string;
  ownerName?: string;
  expiresOn?: Date;
  expiresLessThanADay?: boolean;
  isExpired?: boolean;

  constructor(model: Partial<Avatar>) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }
}
