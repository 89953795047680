<ng-container *ngIf="!isMobile; else mobilePool">
  <app-apx-radial-bar-chart
    [series]="series"
    [labels]="labels"
    [colors]="colors"
    [totalLabel]="gaugeLabel"
    [totalValue]="gaugeValue"
  ></app-apx-radial-bar-chart>

  <div class="bullets-container">
    <div class="bullet-row" *ngFor="let bullet of bullets">
      <span
        class="bullet"
        [ngStyle]="{ 'background-color': bullet.color }"
      ></span>
      <span class="bullet-text">{{ bullet.label }} - {{ bullet.value }}%</span>
    </div>
  </div>
</ng-container>

<ng-template #mobilePool>
  <div class="mobile-pool" [class.more-than-4]="bullets?.length > 4">
    <div class="mobile-item" *ngFor="let item of bullets">
      <app-mobile-gauge-item
        [gaugeType]="'full'"
        [isUnlimitedTheme]="false"
        [gaugeValue]="item.value"
        [gaugeAppendText]="'%'"
        [foregroundColor]="item.color"
        [distributed]="true"
        >{{ item.label }}</app-mobile-gauge-item
      >
    </div>
  </div>
</ng-template>
