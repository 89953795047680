import { BaseDTO } from '../../services/base';
import { Note } from './note';

export class NoteDTO extends BaseDTO<Note, NoteDTO> {
  private article_content: string;
  private article_title: string;
  private created_at: string;
  private created_by: string;
  private deleted_at: string;
  private description: string;
  private entity_id: string;
  private entity_type: string;
  private favorite_Count: string;
  private feature_request_id: string;
  private id: string;
  private image: string;
  private location: { coordinates: number[] };
  private media: unknown[];
  private note_date: string;
  private place: unknown;
  private published_at: string;
  private retweet_Count: string;
  private title: string;
  private updated_at: string;
  private updated_by: string;
  private url: string;
  private valid_at: unknown;
  private video: unknown;
  private targetId: string;

  constructor(model: Partial<NoteDTO>) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(): NoteDTO {
    return this;
  }

  toModel(): Note {
    return new Note({
      id: this.id,
      articleContent: this.article_content,
      articleTitle: this.article_title,
      createdAt: new Date(this.created_at),
      createdBy: this.created_by,
      deletedAt: new Date(this.deleted_at),
      description: this.description,
      entityId: this.entity_id,
      entityType: this.entity_type,
      favoriteCount: this.favorite_Count,
      featureRequestId: this.feature_request_id,
      image: this.image,
      ...(this.location?.coordinates?.length && {
        location: {
          lat: this.location.coordinates[1],
          lng: this.location.coordinates[0],
        },
      }),
      media: this.media,
      noteDate: new Date(this.note_date),
      place: this.place,
      publishedAt: new Date(this.published_at),
      retweetCount: this.retweet_Count,
      title: this.title,
      updatedAt: new Date(this.updated_at),
      updatedBy: new Date(this.updated_by),
      url: this.url,
      validAt: this.valid_at,
      video: this.video,
      targetId: this.targetId,
    });
  }
}
