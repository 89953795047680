import { Entity } from '../base';

export class Company extends Entity {
  public name: string;
  public industry?: string;
  public jobTitle?: string;
  public startDate?: string;
  public endDate?: string;
  public location?: string;
  public companyUrl?: string;
  public companyId?: string;
  public logo?: string;
}
