import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EntityType, Media } from 'datalayer/models/platform-models';
import { Post } from 'datalayer/models/platform-models/social';
import { head } from 'lodash-es';
import {
  EntityDetailsDialogComponent,
  EntityDetailsDialogModel,
} from 'src/app/shared/components/entity-details-dialog/entity-details-dialog.component';
import { FeedType } from 'src/app/shared/models/feed.model';
import { Platform } from 'src/app/shared/schemas/common/platforms';

@Component({
  selector: 'app-social-tooltip',
  templateUrl: './social-tooltip.component.html',
  styleUrls: ['./social-tooltip.component.scss'],
})
export class SocialTooltipComponent {
  @Input() entityEventSocialPost: { [key: string]: string };
  @Output() closeTooltip = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  public showItem(): void {
    this.closeTooltip.emit();

    this.dialog.open(EntityDetailsDialogComponent, {
      data:
        this.entityEventSocialPost?.data['type'] === EntityType.Post
          ? this.getEntityDataFromPost()
          : this.getEntityDataFromMedia(),
      panelClass: 'social-profile-image-dialog',
    });
  }

  getEntityDataFromPost(): EntityDetailsDialogModel {
    const postDetails = <Post>this.entityEventSocialPost?.data['post'];
    return {
      feed: null,
      type: FeedType.POST,
      imgWidth: 0,
      platform: <Platform>this.entityEventSocialPost.platform,
      url: postDetails?.url,
      image: head(postDetails?.images)?.imageUrl as string,
      media: head(postDetails?.images)?.imageUrl as string,
      targetId: this.entityEventSocialPost?.data['targetId'],
      publishedAt: postDetails?.publishedAt,
      description: postDetails?.body,
      feedbackStats: postDetails?.feedbackStats,
      title: postDetails?.title,
      queryDate: postDetails?.createdAt,
      sourceEntity: postDetails?.sourceEntity,
    };
  }

  getEntityDataFromMedia(): EntityDetailsDialogModel {
    const mediaDetails = <Media>this.entityEventSocialPost?.data['media'];
    return {
      feed: null,
      type: FeedType.IMAGE,
      imgWidth: 0,
      platform: <Platform>this.entityEventSocialPost.platform,
      url: mediaDetails?.sourceUrl,
      image: mediaDetails?.url,
      media: mediaDetails?.url,
      targetId: this.entityEventSocialPost?.data['targetId'],
      publishedAt: mediaDetails?.publishedAt,
      description: mediaDetails?.description,
      content: mediaDetails?.content,
      feedbackStats: {
        likesCount: mediaDetails?.countLikes,
        commentsCount: mediaDetails?.countComments,
      },
      title: mediaDetails?.title,
      queryDate: mediaDetails?.createdAt,
      sourceEntity: mediaDetails?.sourceEntity,
    };
  }
}
