import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unread',
})
export class UnreadNotificationPipe implements PipeTransform {
  transform(notifications: any[]): any {
    return notifications
      ? notifications.filter((n) => !n.isRead && !n.deletedAt)
      : [];
  }
}

@Pipe({
  name: 'all',
})
export class AllNotificationPipe implements PipeTransform {
  transform(notifications: any[]): any {
    return notifications || [];
  }
}
