<div class="loader-position" *ngIf="showLoader">
  <mat-spinner class="spinner-loader" color="accent"></mat-spinner>
</div>

<app-base-modal
  *ngIf="billingPlan$ | async as billingPlan"
  [footerWithBorder]="true"
>
  <div header>{{ 'Search by image' | translate }}</div>

  <div class="search-by-image-wrapper" body>
    <div class="mat-typography">
      <div
        class="drop-section"
        [ngClass]="{ disabled: form.get(fields.base64File).disabled }"
      >
        <div class="drop-container" appDnd (fileDropped)="onFileChange($event)">
          <input
            type="file"
            #fileDropRef
            id="fileDropRef"
            accept="image/x-png,image/jpg,image/jpeg"
            (change)="onFileChange($event.target.files)"
          />
          <img src="assets/img/dnd/ic-upload-file.svg" alt="" />

          <h3 class="drop-description">
            {{ 'Drag and Drop file here or click to browse' | translate }}
          </h3>

          <h4 class="sub-description" *ngIf="!selectedImageBase64">
            {{ 'the extension supported are: .jpg, jpg, .png' | translate }}
          </h4>

          <h4 class="sub-description" *ngIf="!!selectedImageBase64">
            {{ files[0].name }}
          </h4>

          <intellectus-text-button
            class="file-button"
            *ngIf="!selectedImageBase64"
            (click)="fileDropRef.click()"
            [type]="'secondary'"
            [size]="'small'"
            >{{ 'Browse' | translate }}
          </intellectus-text-button>

          <intellectus-text-button
            class="file-button"
            *ngIf="!!selectedImageBase64"
            (click)="deleteFile(0)"
            [type]="'secondary-danger'"
            [size]="'small'"
            >{{ 'Remove file' | translate }}
          </intellectus-text-button>
        </div>
      </div>

      <form class="form-field col-md-12" [formGroup]="form">
        <label>URL</label>
        <input
          type="text"
          class="url-input"
          formControlName="imageUrl"
          [placeholder]="'Copy and paste the url of an image here' | translate"
          [disabled]="imageUrlControl.disabled"
        />

        <label>{{ 'Name' | translate }}</label>
        <input
          formControlName="name"
          [placeholder]="
            'Add the name of the person that depict in the uploaded image'
              | translate
          "
        />
      </form>
      <!-- BUTTON -->
    </div>
  </div>

  <div class="d-flex justify-content-center" footer>
    <intellectus-text-button
      class="mr-15"
      (click)="search(true)"
      [type]="'primary'"
      [size]="'large'"
      [credits]="billingPlan.intelSearch.cost"
      [isDisabled]="
        fileControl.invalid || imageUrlControl.invalid || nameControl.invalid
      "
      >{{ 'Find profiles' | translate }}
    </intellectus-text-button>

    <intellectus-text-button
      (click)="search(false)"
      [type]="'secondary'"
      [size]="'large'"
      [credits]="billingPlan.intelSearch.cost"
      [isDisabled]="fileControl.invalid || imageUrlControl.invalid"
      >{{ 'Search image' | translate }}
    </intellectus-text-button>
  </div>
</app-base-modal>
