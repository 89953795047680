import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clCalendarValueToOpacity',
})
export class ClCalendarValueToOpacityPipe implements PipeTransform {
  /**
   *
   * @param value number
   * @param max number
   */
  transform(value: number, max: number): number {
    return (value || 0) / (max || 1);
  }
}
