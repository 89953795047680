import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageOptions } from '@shared/models/site-settings.model';
import { environment } from 'environment/environment';
import { Environment, Tenant, TenantList } from 'environment/models';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { BehaviorSubject, catchError, mergeMap, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private baseURL: string = environment.tenantAPIUri;
  private config: Partial<Environment>;
  private multiTenantConfig: Tenant;
  private _authenticatedConfigLoaded: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public authenticatedConfigLoaded$ =
    this._authenticatedConfigLoaded.asObservable();
  private _anonConfigLoaded: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private _multiTenantConfigLoaded: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public anonConfigLoaded$ = this._anonConfigLoaded.asObservable();
  private anonConfig: Partial<Environment>;

  constructor(private http: HttpClient) {}

  fetchAuthenticatedConfig(): void {
    if (!this._authenticatedConfigLoaded.getValue()) {
      this.http
        .get<Partial<Environment>>(environment.proxyAPIUri + '/app/config')
        .pipe(
          tap((config) => {
            this.config = this.config ? { ...this.config, ...config } : config;
            this._authenticatedConfigLoaded.next(true);
          }),
          mergeMap(() => this.authenticatedConfigLoaded$),
          catchError((e) => throwError(() => e))
        )
        .subscribe();
    }
  }

  fetchAnonConfig() {
    if (!this._anonConfigLoaded.getValue()) {
      this.http
        .get<Partial<Environment>>(environment.proxyAPIUri + '/app/anon-config')
        .pipe(
          tap((config) => {
            this.anonConfig = { ...config };
            this.config = config;
            this._anonConfigLoaded.next(true);
          }),
          catchError((e) => throwError(() => e))
        )
        .subscribe();
    }
  }

  fetchMultiTenantConfig() {
    if (!this._multiTenantConfigLoaded.getValue()) {
      this.http
        .get<TenantList>(`${this.baseURL}/tenants`)
        .pipe(
          tap((multiTenantConfig) => {
            this.multiTenantConfig = multiTenantConfig.items[0];
            this._multiTenantConfigLoaded.next(true);
          }),
          catchError((e) => throwError(() => e))
        )
        .subscribe();
    }
  }

  getConfig() {
    return this.config;
  }

  getMultiTenantconfig() {
    return this.multiTenantConfig;
  }

  public getConfigVariable<T extends keyof Environment>(variable: T) {
    return this.config?.[variable] ?? environment[variable];
  }

  public getLanguage(): LanguageOptions {
    return <LanguageOptions>this.getConfigVariable('defaultLanguage');
  }

  isCallLogPermitted(msisdn: string): boolean {
    try {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      const callLogsEnabledCountries = this.getConfigVariable(
        'callLogsEnabledCountries'
      );

      const countryCode = phoneNumberUtil
        .parse(msisdn, '')
        .getCountryCode()
        .toString();

      return callLogsEnabledCountries.includes(countryCode);
    } catch (e) {
      return false;
    }
  }

  clearConfigValue(): void {
    this._anonConfigLoaded.next(false);
    this._authenticatedConfigLoaded.next(false);
    // setting the config to the simplistic anonFonfig
    this.config = { ...this.anonConfig };
  }
}
