<div class="import-data-request-dialog">
  <h1 mat-dialog-title class="dialog-title">
    {{ 'Not enough information' | translate }}!
  </h1>
  <div mat-dialog-content class="dialog-content">
    <div *ngIf="canRequestImport(); else createSchedule">
      <p>{{ 'Would you like to import more data' | translate }}?</p>
    </div>
    <ng-template #createSchedule>
      <div>
        <p>
          {{
            'Not enough geolocation data or call logs data to predict locations. Would you like to create a schedule?'
              | translate
          }}?
        </p>
      </div>
    </ng-template>
  </div>
  <div mat-dialog-actions></div>
  <button mat-button class="cancel request-btn" (click)="onNoClick()">
    {{ 'Cancel' | translate }}
  </button>
  <button
    mat-button
    color="accent"
    class="ok request-btn"
    (click)="onImportOrSchedule()"
    cdkFocusInitial
  >
    {{ 'Yes' | translate }}
  </button>
</div>
