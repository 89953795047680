import { Point } from 'src/app/modules/mapV2/models/map.models';
import { Entity } from '../base';

export class Place extends Entity {
  public url?: string;
  public name?: string;

  // extra fields
  public placeId?: string;
  public hours?: string;
  public phone?: string;
  public email?: string;
  public address?: string;
  public coordinates?: Point;
  public rating?: string;
  public visitedAt?: Date;
}
