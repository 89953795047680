import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
} from '@fe-platform/shared-ui/intellectus';
import { forkJoin, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { User, UserRoles } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import {
  CamelCaseUserFieldsHelper,
  MyDetailsParams,
  OTPChannels,
  UserFormState,
} from '../user-settings.models';
import { MyDetailsService } from './my-details.service';

@Component({
  selector: 'app-my-details',
  templateUrl: './my-details.component.html',
  styleUrls: ['./my-details.component.scss'],
})
export class MyDetailsComponent extends BaseComponent implements OnInit {
  private readonly otpMethodToUserAttribute = {
    [OTPChannels.Email]: CamelCaseUserFieldsHelper.EMAIL_NOTIFICATIONS,
    [OTPChannels.OtpPhone]: CamelCaseUserFieldsHelper.SMS_NOTIFICATIONS,
  };

  visibility = false;
  isLoading = false;

  userForm: UntypedFormGroup = new UntypedFormGroup({});
  formState: UserFormState;
  userFormState = UserFormState;

  notificationsAreDisabled = false;

  public readonly fAavailableIcons: { [id: string]: FontAwesomeIcon } = {
    edit: { name: 'pen-to-square', family: FontAwesomeFamily.REGULAR },
  };

  constructor(
    public userService: UserService,
    protected translationService: TranslationService,
    protected myDetailsService: MyDetailsService,
    protected applicationStateService: ApplicationStateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.notificationsAreDisabled = this.userService.currentUser$
      .getValue()
      ?.roles?.includes(UserRoles.SUPPORT);
  }

  setForm(formState: UserFormState, currentUser: User): void {
    this.formState = formState;
    this.userForm = this.myDetailsService.initForm(formState, currentUser);
  }

  cancelEdit(): void {
    this.userForm.reset();
    Object.keys(this.userForm.controls).forEach((key) => {
      this.userForm.get(key).setErrors(null);
    });
    this.userForm = new UntypedFormGroup({});
  }

  submitForm(): void {
    if (
      !this.userForm.controls['password']?.value &&
      !this.userForm.controls['newPassword']?.value
    ) {
      this.userForm.removeControl('password');
      this.userForm.removeControl('newPassword');
    }

    if (!this.userForm.valid || this.userForm.pristine) {
      return;
    }

    const params = {
      ...this.userForm.value,
    };

    this.prepareParams(params);
  }

  handleNotificationUpdate(row: User, value: Partial<User>): void {
    this.subscriptions.push(
      this.userService.editUser(row, value, true).subscribe({
        next: (User) => {
          this.showMessage(this.translationService.translate('Saved!'));
        },
        error: (error) =>
          this.showMessage(
            this.translationService.translate(error ?? 'Could not save')
          ),
      })
    );
  }

  private prepareParams(params: MyDetailsParams): void {
    this.isLoading = true;
    const passwordPayload = {
      password: params.password,
      newPassword: params.newPassword,
    };
    const userPayload: MyDetailsParams = this.buildUserPayload(params);
    const requestParams: Partial<MyDetailsParams> = {};
    Object.keys(userPayload).forEach(
      (key) => (requestParams[key] = userPayload[key])
    );

    this.prepareCalls(requestParams, passwordPayload);
  }

  private buildUserPayload(params: MyDetailsParams): MyDetailsParams {
    let userPayload: MyDetailsParams = {};
    if (params.email !== undefined) {
      userPayload = {
        email: params.email,
        ...this.disableNotifications(params.email, OTPChannels.Email),
      };
    }

    if (params.otpPhone !== undefined) {
      userPayload = {
        ...userPayload,
        ...this.disableNotifications(params.otpPhone, OTPChannels.OtpPhone),
        otpPhone: params.otpPhone,
      };
    }
    return userPayload;
  }

  private disableNotifications(value: string, otpMethod: OTPChannels): object {
    return value.length === 0
      ? { [this.otpMethodToUserAttribute[otpMethod]]: false }
      : {};
  }

  private prepareCalls(
    requestParams: Partial<MyDetailsParams>,
    passwordPayload: { [key: string]: string }
  ): void {
    const editUser$ = this.userService
      .editUser(this.userService.currentUser$.value, requestParams, true)
      .pipe(tap((val) => this.userService.currentUser$.next(val)));
    const resetPassword$ = this.userService.resetPassword(
      passwordPayload.password,
      passwordPayload.newPassword
    );

    const requests: Observable<User>[] = [];
    if (this.formState === UserFormState.Passwords) {
      requests.push(resetPassword$);
    } else if (this.formState === UserFormState.Phone) {
      requests.push(editUser$);
    } else {
      if (!passwordPayload.password && !passwordPayload.newPassword) {
        requests.push(editUser$);
      } else {
        requests.push(editUser$, resetPassword$);
      }
    }

    this.updateUser(requests);
  }

  private updateUser(requests: Observable<User | string>[]): void {
    this.subscriptions.push(
      forkJoin(requests).subscribe(
        (results: User[]) => {
          this.showMessage(
            `${this.translationService.translate('User edited successfully!')}`
          );
          this.isLoading = false;
          this.userForm = new UntypedFormGroup({});
        },
        (error) => {
          this.showMessage(
            this.translationService.translate(
              error.message ? error.message : error
            )
          );
          this.isLoading = false;
          this.userForm = new UntypedFormGroup({});
        }
      )
    );
  }

  areControlsEnabled(): boolean {
    return (
      this.userForm.contains('password') ||
      this.userForm.contains('otpPhone') ||
      this.userForm.contains('email')
    );
  }
}
