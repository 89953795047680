import { AvatarActivityLogState } from 'datalayer/models/avatar/avatar-activity-log-state.enum';
import { AvatarVisibleSocialState } from 'datalayer/models/avatar/avatar-visible-social-state.enum';
import { AvatarSocial } from 'src/app/modules/data-layer//models/avatar/avatar-social.interface';
import { Avatar } from 'src/app/modules/data-layer/models';
import { AvatarSocialState } from 'src/app/modules/data-layer/models/avatar/avatar-social-state.enum';
import { AvatarSocialType } from 'src/app/modules/data-layer/models/avatar/avatar-social-type.enum';
import { BaseDTO } from '../base';
import { AvatarPersonDTO } from './avatar-person-dto';

export class AvatarDTO extends BaseDTO<Avatar, AvatarDTO> {
  private id: string;
  private person: AvatarPersonDTO;
  private socials: AvatarSocialType[];
  private facebook: {
    state: AvatarSocialState;
    lastActivity: {
      name: AvatarActivityLogState;
      startedAt: string;
    };
  };
  private userId: string;
  private expireAt: string;
  constructor(model: Partial<AvatarDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Avatar): AvatarDTO {
    this.socials = model.socials;
    this.person = new AvatarPersonDTO().fromModel(model.person);
    return this;
  }

  toModel(imageBaseRoute?: string): Avatar {
    const paidUntil = this.expireAt ? new Date(this.expireAt) : null;
    const isExpired = paidUntil?.getTime() < new Date().getTime();
    const avatar: Avatar = new Avatar({
      id: this.id,
      socials: this.socials,
      state: this.getLatestState(),
      latestActivityInfo: this.getLatestActivityInfo(),
      ownerId: this.userId,
      ...(this.person
        ? { person: new AvatarPersonDTO(this.person).toModel(imageBaseRoute) }
        : {}),
      ...(this.facebook ? { facebook: this.fillFacebook() } : {}),
      ...(paidUntil && {
        expiresOn: paidUntil,
        expiresLessThanADay:
          paidUntil.getTime() - new Date().getTime() < 24 * 60 * 60 * 1000 &&
          !isExpired,
        isExpired,
      }),
    });

    return avatar;
  }

  private fillFacebook(): AvatarSocial {
    return {
      state: this.facebook.state,
      lastActivityInfo: {
        name: this.facebook.lastActivity
          ? this.facebook.lastActivity.name
          : null,
        startedAt:
          this.facebook.lastActivity && this.facebook.lastActivity.startedAt
            ? new Date(this.facebook.lastActivity.startedAt)
            : null,
      },
    };
  }

  getLatestState(): AvatarVisibleSocialState {
    //TODO
    //when multiple social accounts are present return latest state, for now only facebook is available
    let state: AvatarVisibleSocialState;
    if (!this.facebook) {
      return AvatarVisibleSocialState.errors;
    }
    switch (this.facebook.state) {
      case AvatarSocialState.error:
      case AvatarSocialState.invalidCredentials:
      case AvatarSocialState.automationError:
        state = AvatarVisibleSocialState.errors;
        break;
      case AvatarSocialState.resting:
        state = AvatarVisibleSocialState.idle;
        break;
      case AvatarSocialState.new:
      case AvatarSocialState.registering:
      case AvatarSocialState.initialPhotosFilling:
      case AvatarSocialState.initialDetailsFilling:
      case AvatarSocialState.gettingOtpCode:
        state = AvatarVisibleSocialState.register;
        break;
      case AvatarSocialState.banned:
        state = AvatarVisibleSocialState.banned;
        break;
      default:
        state = AvatarVisibleSocialState.active;
        break;
    }
    return state;
  }

  getLatestActivityInfo(): { name: AvatarActivityLogState; startedAt: Date } {
    //TODO
    //when multiple social accounts are present return latest activity info based dates, for now only facebook is available
    return {
      name:
        this.facebook && this.facebook.lastActivity
          ? AvatarActivityLogState[this.facebook.lastActivity.name]
          : AvatarActivityLogState.register,
      startedAt:
        this.facebook &&
        this.facebook.lastActivity &&
        this.facebook.lastActivity.startedAt
          ? new Date(this.facebook.lastActivity.startedAt)
          : null,
    };
  }
}
