import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import {
  NodeFilterElement,
  NodeFilterItem,
  nodeFilterSections,
  nodeFilterSectionsToLabel,
} from 'src/app/modules/link-analysis/shared/link-analysis.model';

@Component({
  selector: 'app-la-node-filters',
  templateUrl: './la-node-filters.component.html',
  styleUrls: ['./la-node-filters.component.scss'],
})
export class LaNodeFiltersComponent
  extends BaseComponent
  implements AfterViewInit, OnChanges
{
  @Input() allItems: NodeFilterItem[];
  @Input() checkedItemsIds: string[];

  @Output() addToFilterData = new EventEmitter<{
    event: MatCheckboxChange;
    item: NodeFilterItem;
  }>();

  @ViewChild('nodeSearchInput') nodeSearchInput: ElementRef<HTMLInputElement>;

  imagesPath = 'assets/static/images/';
  nodeFilterElements: NodeFilterElement[] = [];

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.handleNodeInputSearch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checkedItemsIds?.currentValue?.length === 1) {
      this.removeCheckedFilters();
    }
    if (changes.allItems?.currentValue) {
      this.setupNodeFilterElements();
    }
  }

  public addToFilteredData(
    event: MatCheckboxChange,
    item: NodeFilterItem,
    existingItems: NodeFilterItem[],
    checkedData: NodeFilterItem[]
  ) {
    if (event.checked) {
      checkedData.push(item);
      const index = existingItems.findIndex(
        (listedNode) => item.id === listedNode.id
      );
      existingItems.splice(index, 1);
    } else {
      existingItems.push(item);
      const checkedIndex = checkedData.findIndex(
        (checkedNode) => item.id === checkedNode.id
      );
      checkedData.splice(checkedIndex, 1);
    }
    this.addToFilterData.emit({ event, item });
  }

  //  TODO: check with product if we still need this functionality or remove it completely
  // private applyNodeFilterChangesOnRemovedNode(
  //   filterElement: NodeFilterElement,
  //   removedNode: NodeFilterItem
  // ): NodeFilterElement {
  //   if (!filterElement.checkedData.length) {
  //     filterElement.matchedSearchData = [];
  //     filterElement.data.forEach(node => {
  //       if (node.id === removedNode.id) {
  //         return;
  //       }
  //       filterElement.checkedData.push(node);
  //     });
  //   }
  //   filterElement.matchedSearchData.push(removedNode);
  //   return filterElement;
  // }

  private handleNodeInputSearch() {
    const events = ['keyup', 'paste'];
    events.forEach((event) => {
      this.subscriptions.push(
        fromEvent(this.nodeSearchInput.nativeElement, event)
          .pipe(
            debounceTime(150),
            distinctUntilChanged(),
            tap((text) => {
              this.searchFilters(this.nodeSearchInput.nativeElement.value);
            })
          )
          .subscribe()
      );
    });
  }

  private searchFilters(value: string) {
    if (value.length) {
      this.nodeFilterElements.forEach((section) => {
        section.matchedSearchData = section.data.filter((node) =>
          node.label.toLowerCase().includes(value.toLowerCase())
        );
      });
    } else {
      this.removeCheckedFilters(true);
    }
  }

  private removeCheckedFilters(keepChecked?: boolean) {
    this.nodeFilterElements.forEach((section: NodeFilterElement) => {
      if (keepChecked) {
        section.matchedSearchData = [];
        section.data.forEach((item) => {
          if (
            section.checkedData.findIndex((entry) => entry.id === item.id) ===
            -1
          ) {
            section.matchedSearchData.push(item);
          }
        });
      } else {
        section.matchedSearchData = section.data.slice();
        section.checkedData = [];
      }
    });
  }

  private setupNodeFilterElements() {
    this.nodeFilterElements = [];
    Object.values(nodeFilterSections).forEach((section) => {
      const data = this.allItems.filter((item) => item.section === section);
      if (data.length) {
        this.nodeFilterElements.push({
          label: nodeFilterSectionsToLabel[section],
          data,
          checkedData: [],
          matchedSearchData: data.slice() || [],
        });
      }
    });
  }
}
