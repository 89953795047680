import { Platforms } from 'src/app/shared/models/radical-monitoring-options.model';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Entity, Event, Timeline } from '@trg-ui/timeline-visualization';
import { InvestigationEntitySocialPost } from '../models/investigation-event-social.model';
import { ImProfile } from 'datalayer/models/platform-models/im-profiles/im-profile';
import { nodeTypes } from '../../link-analysis/shared/link-analysis.model';
import { InvestigationDataSourceType } from 'src/app/modules/analysis/shared/models/case-investigations.model';
import { ImageService } from 'src/app/services/image/image.service';

@Injectable()
export class InvestigationTooltipHelpers {
  entityCallLogs: { [key: string]: string };
  constructor(private datepipe: DatePipe, private imageService: ImageService) {}

  entityMSISDN(
    profiles: ImProfile[],
    msisdn: string
  ): InvestigationEntitySocialPost {
    const socialProfiles: {
      imageUrl: string;
      source: string;
      sourceId: string;
      msisdn: string;
      name: string;
      index: number;
    }[] = [];
    let index = 1;
    profiles.forEach((profile) => {
      socialProfiles.push({
        imageUrl: profile.image,
        source: profile.platform,
        sourceId: profile.usernames[0],
        msisdn: msisdn,
        name: profile.usernames[0],
        index: index++,
      });
    });
    return {
      socialProfiles: socialProfiles,
      type: nodeTypes.MSISDN,
    };
  }

  entitySocialPost(entity: Entity): { [key: string]: string } {
    return {
      name: entity.data.profileName,
      image: entity.data.profileImage,
      msisdn: entity.data.profileId,
      relation: entity.data.profileRelation,
      type: nodeTypes.SOCIAL_PROFILE,
    };
  }

  getEventColor(eventId: string, timeline: Timeline): string {
    const entity = timeline.getEntity(eventId);
    const color = entity.color;
    return color;
  }

  buildEntityEventSocialPost(
    event: Event,
    timeline: Timeline
  ): { [key: string]: string } {
    let ownerName: string;
    let ownerImage: string;
    if (Array.isArray(event.entityIds) && event.entityIds.length) {
      const entity = timeline?.getEntity(event.entityIds[0]);
      ownerName = entity.data.profileName || entity.label;
      ownerImage = <string>(
        this.imageService.getPhotoUrl(entity.data?.profileImage)
      );
    }
    return {
      owner: ownerName,
      action: this.checkEventActionType(event.type),
      text: event.data?.text || event?.data?.name || '',
      ownerImage: ownerImage || '',
      image: <string>this.imageService.getPhotoUrl(event?.data?.image) || '',
      url: event?.data?.url,
      date: this.datepipe.transform(
        event?.data?.date || (event?.time as Date),
        'd MMMM y, HH:mm'
      ),
      platform:
        event.type === InvestigationDataSourceType.geo_query
          ? event?.type.replace('_', ' ')
          : event?.data?.platform ||
            this.checkPossibleSocialAccount(event.entityIds[0]),
      color: this.getEventColor(event.entityIds[0], timeline),
      data: event.data,
      platformType: event.type,
    };
  }

  private checkPossibleSocialAccount(account: string): string {
    switch (true) {
      case account.includes(Platforms.FACEBOOK):
        return Platforms.FACEBOOK;
      case account.includes(Platforms.INSTAGRAM):
        return Platforms.INSTAGRAM;
      case account.includes(Platforms.LINKEDIN):
        return Platforms.LINKEDIN;
      case account.includes(Platforms.TIKTOK):
        return Platforms.TIKTOK;
      case account.includes(Platforms.TWITTER):
        return Platforms.TWITTER;
      case account.includes(Platforms.YOUTUBE):
        return Platforms.YOUTUBE;
      default:
        return '';
    }
  }

  checkEventActionType(event: string): string {
    switch (event) {
      case InvestigationDataSourceType.photo:
        return 'added a new picture';
      case InvestigationDataSourceType.post:
        return 'added a new post';
      case InvestigationDataSourceType.checkin:
        return 'added a new checkin';
      case InvestigationDataSourceType.geo_query:
        return 'added a new geo query';
      default:
        return '';
    }
  }
}
