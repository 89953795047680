<!-- Two Factor Verification -->
<div class="verify-wrp assistant-font">
  <div class="verify-content" *ngIf="!resetVerification">
    <p class="flex-wrp title">{{ '2-Step Verification' | translate }}</p>

    <div class="user-login">
      <span>{{ 'User' | translate }}: {{ userCredentials.username }}</span>
      <span class="change" (click)="navigateForm()">{{
        'Change' | translate
      }}</span>
    </div>

    <div class="assistant-font">
      <div class="field-group">
        <label>{{ 'OTP code' | translate }}</label>
        <mat-form-field class="input-round">
          <input
            class="assistant-font"
            matInput
            [type]="visibility ? 'text' : 'password'"
            [formControl]="otpInput"
            required
            (keydown.enter)="continueOTPAuthentication()"
          />

          <mat-icon
            class="icon eye-icon"
            (click)="this.visibility = !this.visibility"
          >
            {{
              this.visibility === true ? 'visibility_off' : 'visibility'
            }}</mat-icon
          >
        </mat-form-field>
        <span class="error" *ngIf="invalidOTPMsg">{{
          invalidOTPMsg | translate
        }}</span>
      </div>
      <p *ngIf="canReset2FA" class="input-desc">
        {{
          'If you lost your device please reset the 2-Step Verification by pressing the reset button. An email will be sent to guide you to add a new device.'
            | translate
        }}
      </p>
    </div>
  </div>

  <div class="assistant-font" *ngIf="resetVerification">
    <p class="flex-wrp title">{{ '2-Step Verification' | translate }}</p>
    <div class="email-content" *ngIf="!loader">
      <p class="sent">{{ 'Email sent.' | translate }}</p>
      <p class="input-desc">
        {{ 'An email with the QR was sent to' | translate }}
        <span>{{ userEmail }}</span>
      </p>
    </div>
    <div class="loader" *ngIf="loader">
      <app-spinner-loader [loading]="loader"></app-spinner-loader>
    </div>
  </div>

  <div class="action-wrp">
    <div
      class="{{ canReset2FA ? 'flex-wrp' : 'flex-hor-center' }}"
      *ngIf="!resetVerification"
    >
      <button
        *ngIf="canReset2FA"
        class="cp-btn-ghost"
        mat-button
        (click)="onResetVerification()"
      >
        {{ 'Reset' | translate }}
      </button>
      <button
        class="cp-btn-primary"
        mat-button
        [disabled]="!otpInput.value"
        (click)="continueOTPAuthentication()"
      >
        {{ 'Continue' | translate }}
      </button>
    </div>
    <button
      class="cp-btn-primary"
      mat-button
      *ngIf="resetVerification"
      (click)="navigateForm()"
    >
      {{ 'Back to login' | translate }}
    </button>
  </div>
</div>
