import { BreakpointObserver } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  skipWhile,
  take,
} from 'rxjs';
import { AbstractAppConfigService } from './abstract-app-config.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private readonly isMobileSrc: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public readonly isMobile$: Observable<boolean> =
    this.isMobileSrc.asObservable();

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject('AppConfigService')
    private appConfigService: AbstractAppConfigService
  ) {
    this.appConfigService.anonConfigLoaded$
      .pipe(
        skipWhile((v) => !v),
        take(1)
      )
      .subscribe((): void => {
        const enabledMobileScreens: boolean =
          this.appConfigService.getConfigVariable('enabledMobileScreens');
        this.breakpointObserver
          .observe(['(max-width: 768px)'])
          .pipe(distinctUntilChanged())
          .subscribe((isMobile) => {
            this.isMobileSrc.next(
              enabledMobileScreens ? isMobile.matches : false
            );
          });
      });
  }

  public isMobileDevice(): boolean {
    return window.navigator.maxTouchPoints > 1;
  }
}
