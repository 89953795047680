import { Provider } from '@angular/core';
import {
  FAST_API_URL,
  GEO_URL,
  RECAPTCHA_KEY,
  REPORTS_URL,
  REPORTS_V2_URL,
  WEBSOCKETS_URL,
} from '@fe-platform/core/config';
import { environment } from 'environment/environment';

// In order to share configuration that bootstraps on app level to library level, we need to share them via injection tokens and not environment vars.
// Dependencies should always flow inwards (app -> depends on -> lib) and never vice versa.
export const ConfigProviders: Provider[] = [
  {
    provide: GEO_URL,
    useValue: environment.serverAPIUri,
  },
  {
    provide: REPORTS_URL,
    useValue: environment.reportAPIUri,
  },
  {
    provide: REPORTS_V2_URL,
    useValue: environment.reportV2APIUri,
  },
  {
    provide: RECAPTCHA_KEY,
    useValue: environment.recaptchaV3SiteKey,
  },
  {
    provide: WEBSOCKETS_URL,
    useValue: environment.proxyAPIUWebSocketUri,
  },
  {
    provide: FAST_API_URL,
    useValue: environment.fastAPIUri,
  },
];
