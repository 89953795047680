import { Component, Input } from '@angular/core';

@Component({
  selector: 'intellectus-card-loader',
  templateUrl: './card-loader.component.html',
  styleUrls: ['./card-loader.component.scss'],
})
export class CardLoaderComponent {
  public numberOfRowsArray: number[] = [1];
  @Input() set numberOfRows(number: number) {
    this.numberOfRowsArray = Array(number)
      .fill(1)
      .map((x, i) => x * i);
  }
}
