import { Pipe, PipeTransform } from '@angular/core';
import { MapService } from 'src/app/modules/mapV2/vanilla-google-map/shared/map.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'reverseGeo',
})
export class ReverseGeoPipe implements PipeTransform {
  constructor(private mapService: MapService) {}

  transform(coords: number[]): Observable<string | null> {
    if (!Array.isArray(coords)) {
      return of(null);
    }

    const [lng, lat] = coords;
    return this.mapService.reverseGeocoding({
      lat,
      lng,
    });
  }
}
