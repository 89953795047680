import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HighlightClassEnum } from 'src/app/services/app-highlighter/enum/highlight-class.enum';

@Injectable({ providedIn: 'root' })
export class AppHighlighterService {
  private stateScr: BehaviorSubject<HighlightClassEnum> =
    new BehaviorSubject<HighlightClassEnum>(null);

  public state$: Observable<HighlightClassEnum> = this.stateScr.asObservable();

  public update(cssClass: HighlightClassEnum): void {
    this.stateScr.next(cssClass);
  }

  public reset(): void {
    this.update(null);
  }
}
