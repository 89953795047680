export enum AvatarSocialState {
  new = 'new',
  registering = 'registering',
  initialPhotosFilling = 'initialPhotosFilling',
  initialDetailsFilling = 'initialDetailsFilling',
  activity = 'activity',
  resting = 'resting',
  gettingOtpCode = 'gettingOtpCode',
  error = 'error',
  banned = 'banned',
  invalidCredentials = 'invalidCredentials',
  automationError = 'automationError',
  reserved = 'reserved',
}
