import KeyLines, {
  ChartOptions,
  Glyph,
  MapOptions,
  TimeBarOptions,
} from '@trg-ui/link-analysis';
import { Person } from 'src/app/modules/data-layer/models/platform-models';
import { Platform } from 'src/app/shared/schemas/common/platforms';
import { InvestigationDataSourceType } from 'src/app/modules/analysis/shared/models/case-investigations.model';
import { BillingActions } from 'src/app/shared/models/billing-action.model';

export const DEFAULT_LINK_COLOR = '#3c4144';
export const LIGHTER_LINK_COLOR = '#92989c';
export const BLUE_LINK_COLOR = 'rgba(57,106,255,0.5)';
export const DEFAULT_LINK_WIDTH = 0.4;
export const DEFAULT_COMBO_GLYPH_COLOR = '#292826';
export const DEFAULT_SELECTION_COLOR = '#bacbfc';
const CUSTOM_ENTITY_INITIAL_COLOR = '#616161';
const DARK_THEME_COMBO_GLYPH_COLOR = '#fafafa';

export enum nodeTypes {
  PERSON = 'Person',
  ORGANIZATION = 'Organization',
  PLACE = 'Place',
  MSISDN = 'MSISDN',
  PHONE = 'Phone',
  TOPIC = 'Topic',
  SOCIAL_PROFILE = 'Social Profile',
  CASE = 'Case',
  FACEBOOK_GROUP = 'Facebook Group',
  AD_ID = 'AdId',
  CUSTOM_CLUSTER = 'Custom Cluster',
  INSTANT_MESSAGING = 'Instant messaging',
  LOCATION = 'Location',
  EMAIL = 'email',
  POST = 'Post',
  IMAGE = 'Image',
  PROBABILITY = 'Probability',
  FILE = 'file',
  FILE_UPLOADER = 'file uploader',
  IMSI = 'imsi',
  USERNAME = 'username',
  LICENSE_PLATE = 'License Plate',
  ID = 'Id',
  OTHER = 'Other',
  HASHTAG = 'Hashtag',
  NAMED_ENTITY = 'Named entity',
  URL = 'Url',
  EVENT_ENTITY = 'EventEntity',
  CHAT_CHANNEL = 'Chat Channel;',
  CRYPTO_ACCOUNT = 'Crypto Account',
  CRYPTO_WALLET = 'Crypto Wallet',
}

export const nodeTypeToIcons = {
  [nodeTypes.PERSON]: 'fa-solid fa-user',
  [nodeTypes.SOCIAL_PROFILE]: 'fa-solid fa-person',
  [nodeTypes.MSISDN]: 'fa-solid fa-phone',
  [nodeTypes.PLACE]: 'fa-solid fa-location-dot',
  [nodeTypes.PHONE]: 'fa-solid fa-mobile',
  [nodeTypes.CASE]: 'fa-solid fa-folder-open',
  [nodeTypes.FACEBOOK_GROUP]: 'fa-brands fa-facebook',
  [nodeTypes.CHAT_CHANNEL]: 'fa-solid fa-people-group',
  [nodeTypes.CUSTOM_CLUSTER]: 'fa-solid fa-people-group',
  [nodeTypes.LOCATION]: 'fa-solid fa-mobile-signal',
  [nodeTypes.EMAIL]: 'fa-solid fa-envelope',
  [nodeTypes.IMAGE]: 'fa-solid fa-image',
  [nodeTypes.POST]: 'fa-solid fa-square-poll-horizontal',
  [nodeTypes.INSTANT_MESSAGING]: 'fa-solid fa-user',
  [nodeTypes.FILE]: 'fa-solid fa-file-lines',
  [nodeTypes.FILE_UPLOADER]: 'fa-solid fa-file-arrow-up',
  [nodeTypes.IMSI]: 'fa-solid fa-sim-cards',
  [nodeTypes.USERNAME]: 'fa-solid fa-user',
  [nodeTypes.HASHTAG]: 'fa-solid fa-hashtag',
  [nodeTypes.URL]: 'fa-solid fa-link-simple',
  [nodeTypes.EVENT_ENTITY]: 'fa-solid fa-calendar',
  [nodeTypes.CRYPTO_WALLET]: 'fa-solid fa-wallet',
};

export const FontAwesomeLibs = {
  STANDARD: 'Font Awesome 6 Pro',
  BRANDS: 'Font Awesome 6 Brands',
  KIT: 'Font Awesome Kit',
};

export const FontAwesomeCustomIcons = [
  'fa-kit fa-aol',
  'fa-kit fa-binance',
  'fa-kit fa-call-logs',
  'fa-kit fa-callapp',
  'fa-kit fa-caller-id',
  'fa-kit fa-getcontact',
  'fa-kit fa-copy-outline',
  'fa-kit fa-find-n-locate',
  'fa-kit fa-global-search',
  'fa-kit fa-incoming',
  'fa-kit fa-kurcoin',
  'fa-kit fa-minimize-dock',
  'fa-kit fa-okx',
  'fa-kit fa-outgoing',
  'fa-kit fa-post',
  'fa-kit fa-tinder',
  'fa-kit fa-transportation',
  'fa-kit fa-trucaller',
  'fa-kit fa-twitter-new',
];

export const chartOptions: ChartOptions = {
  overview: { p: 'sw', size: 200 },
  zoom: { adaptiveStyling: true },
  handMode: true,
  selectedLink: { c: DEFAULT_SELECTION_COLOR },
  selectedNode: {
    b: DEFAULT_SELECTION_COLOR,
    bs: 'solid',
    bw: 12,
  },
  aggregateLinks: { aggregateBy: 'isAggregated', aggregateByDirection: true },
  selfLinks: true,
  navigation: { y: 100 },
  fontFamily: 'Roboto',
  defaultStyles: {
    comboGlyph: {
      b: DEFAULT_COMBO_GLYPH_COLOR,
      c: DEFAULT_COMBO_GLYPH_COLOR,
      p: 'nw',
      fb: false,
    },
  },
  linkEnds: { spacing: 'loose' },
  iconFontFamily: FontAwesomeLibs.STANDARD,
  drag: { links: false, panAtBoundary: true },
  linkStyle: {
    path: 'horizontal',
    inline: true,
  },
  imageAlignment: {
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.PERSON])]: { e: 0.6 },
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.LOCATION])]: { e: 0.7 },
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.CASE])]: { e: 0.7 },
    [KeyLines.getFontIcon('fa-facebook')]: { e: 0.7 },
    [KeyLines.getFontIcon('fa-twitter')]: { e: 0.7 },
    [KeyLines.getFontIcon('fa-linkedin')]: { e: 0.6 },
    [KeyLines.getFontIcon('fa-instagram')]: { e: 0.7 },
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.EMAIL])]: { e: 0.5 },
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.IMAGE])]: { e: 0.7 },
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.MSISDN])]: { e: 0.7 },
    [KeyLines.getFontIcon('fa-buildings')]: { e: 0.7 },
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.PLACE])]: { e: 0.7 },
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.POST])]: { e: 0.7 },
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.IMSI])]: { e: 0.7 },
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.PHONE])]: { e: 0.5 },
    [KeyLines.getFontIcon('fa-viber')]: { e: 0.5 },
    [KeyLines.getFontIcon(nodeTypeToIcons[nodeTypes.CHAT_CHANNEL])]: { e: 0.7 },
  },
  minZoom: 0.002,
};

export const quickResultChartOptions: ChartOptions = {
  ...chartOptions,
  navigation: { shown: false },
  overview: { shown: false },
  iconFontFamily: FontAwesomeLibs.STANDARD,
  imageAlignment: {
    [KeyLines.getFontIcon('fa-solid fa-location-dot')]: { e: 0.5 },
    [KeyLines.getFontIcon('fa-solid fa-phone')]: { e: 0.5 },
  },
};

export const darkThemeOptions: ChartOptions = {
  backColour: '#1f1f1f',
  controlTheme: 'dark',
  selectedNode: {
    b: '#fafafa',
  },
  selectedLink: { c: '#fafafa' },
  defaultStyles: {
    comboGlyph: {
      b: DARK_THEME_COMBO_GLYPH_COLOR,
      c: DARK_THEME_COMBO_GLYPH_COLOR,
    },
  },
};

export const lightThemeOptions: ChartOptions = {
  backColour: '#fff',
  controlTheme: 'light',
  selectedNode: {
    b: DEFAULT_SELECTION_COLOR,
  },
  selectedLink: { c: DEFAULT_SELECTION_COLOR },
  defaultStyles: {
    comboGlyph: {
      b: DEFAULT_COMBO_GLYPH_COLOR,
      c: DEFAULT_COMBO_GLYPH_COLOR,
    },
  },
};

export const timebarOptions: TimeBarOptions = {
  histogram: {
    colour: '#799eb0',
    highlightColour: '#e6e6e6',
  },
  backColour: '#363636',
  type: 'histogram',
};

export const investigationTimeBarOptions: TimeBarOptions = {
  area: { colour: '#666' }, // TODO: change colors after mockups are provided
  backColour: 'transparent',
  playSpeed: 50,
  sliders: 'fixed',
  type: 'histogram',
};

export enum TimebarClickActions {
  PLAY = 'play',
  PAUSE = 'pause',
  START = 'start',
  END = 'end',
}

export const mapOptions: MapOptions = {
  // google maps tiles
  tiles: {
    url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    maxZoom: 20,
  },
};

export const filterSliderConfig = {
  min: 0,
  max: 0,
  invert: false,
  step: 1,
  thumbLabel: false,
  value: 0,
  titleStart: '',
  titleEnd: '',
};

export const degreesSliderConfig = {
  min: 1,
  max: 50,
  invert: false,
  step: 1,
  thumbLabel: true,
  value: 2, // 4, changed to 2 for the demo,
};

export const mutualFriendsSliderConfig = {
  start: [30, 300],
  step: 40,
  behaviour: 'drag-fixed',
  connect: true,
  range: {
    min: 10,
    max: 300,
  },
};

export enum LabelSize {
  SMALL = 8,
  MEDIUM = 10,
  LARGE = 12,
}

export enum GlyphSize {
  SMALL = 0.6,
  MEDIUM = 1,
  LARGE = 1.5,
}

export enum linkTypes {
  INCOMING_CALL = 'incomingCall',
  OUTGOING_CALL = 'outgoingCall',
  INCOMING_SMS = 'incomingSms',
  OUTGOING_SMS = 'outgoingSms',
  // TODO: handle the unknown types and remove this
  UNKNOWN = 'unknownType',
  TWITTER_FOLLOWING = 'twitterFollowing',
  TWITTER_FOLLOWER = 'twitterFollower',
  INSTAGRAM_FOLLOWING = 'instagramFollowing',
  INSTAGRAM_FOLLOWER = 'instagramFollower',
  FACEBOOK_FOLLOWING = 'facebookFollowing',
  FACEBOOK_FOLLOWER = 'facebookFollower',
  FACEBOOK_FRIEND = 'facebookFriend',
  FACEBOK_FAMILY = 'facebookFamily',
  FACEBOK_TAGGED_IN_PHOTO = 'facebookTaggedInPhoto',
  POST_AUTHOR = 'post',
  POSTED_PHOTO = 'photo',
  DEVICE = 'device',
  SIM = 'sim',
  SOCIAL_PROFILE = 'socialProfile',
  ORGANIZATION = 'organization',
  CASE_TARGET = 'caseTarget',
  LINKEDIN_EMPLOYEE = 'linkedinEmployee',
  FACEBOOK_GROUP = 'facebookGroup',
  FACEBOOK_GROUP_MEMBER = 'facebookGroupMember',
  AD_ID = 'adId',
  PLACE = 'place',
  FACEBOOK_SAME_PLACE = 'facebookSamePlace',
  FACEBOOK_SAME_INSTITUTION = 'facebookSameInstitution',
  FACEBOOK_LIKED_PHOTO = 'facebookLikedPhoto',
  FACEBOOK_COMMENTED_PHOTO = 'facebookCommentedPhoto',
  FACEBOOK_LIKED_POST = 'facebookLikedPost',
  FACEBOOK_COMMENTED_POST = 'facebookCommentedPost',
  FACEBOOK_SIGNIFICANT_OTHER = 'facebookSignificantOther',
  FACEBOOK_SHARED_POST = 'facebookSharedPost',
  FACEBOOK_SHARED_PHOTO = 'facebookSharedPhoto',
  INSTANT_MESSAGING = 'instantMessagingProfile',
  LOCATED_AT = 'LocatedAt',
  FACEBOOK_TAGGED_IN_AUTHOR_POST = 'facebookTaggedInAuthorPost',
  USED_BY = 'UsedBy',
  CALLED = 'Called',
  SMSED = 'Smsed',
  DATA = 'Data',
  FACEBOOK_MUTUAL_FRIENDS = 'facebookMutualFriends',
  CALL_LOG_TOP_ASSOCIATE = 'callLogTopAssociate',
  EMAIL = 'email',
  GROUP_BELONGS_TO_PROFILE = 'groupBelongsToProfile',
  LOCATION_PROBABILITY = 'locationProbability',
  IMSI = 'imsi',
  EXTRACTED_ENTITY = 'extractedEntity',
  EXTRACTED_EVENT = 'extractedEvent',
  TELEGRAM_CONNECTION = 'telegramConnection',
  CHANNEL_MEMBER = 'channelMember',
  TIKTOK_FOLLOWER = 'tiktokFollower',
  TIKTOK_FOLLOWING = 'tiktokFollowing',
  HAS_WALLET = 'hasWallet',
  TRANSACTION = 'transaction',
}

export enum relationTypes {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  FAMILY = 'family',
  LINKEDIN = 'linkedin',
  TIKTOK = 'tiktok',
  SKYPE = 'skype',
  TELEGRAM = 'telegram',
  VIBER = 'viber',
  TINDER = 'tinder',
  WHATSAPP = 'whatsapp',
  TRUECALLER = 'truecaller',
  WECHAT = 'wechat',
  GETCONTACT = 'getcontact',
  CALLERID = 'callerid',
}

export enum nodeTypesColors {
  PERSON = '#396aff', // '#ff632f',
  ORGANIZATION = '#396aff', // '#016095',
  PLACE = '#396aff', // '#fda63c',
  MSISDN = '#396aff', // '#bd5286',
  IMSI = '#396aff',
  PHONE = '#396aff', // '#599700',
  TOPIC = '#396aff', // '#74081e',
  SOCIAL_PROFILE = '#396aff', // '#33adff',
  INSTANT_MESSAGING = '#396aff', // '#33adff',
  CASE = '#396aff', // '#2c4d2d',
  FACEBOOK_GROUP = '#396aff', // '#2c4047',
  AD_ID_TARGET = '#396aff', // '#294066',
  AD_ID = '#396aff', // '#524f49',
  CUSTOM_CLUSTER = '#396aff', // '#818f84',
  LOCATION = '#396aff', // '#fda63c',
  EMAIL = '#396aff', // '#fda63c',
  QUICK_RESULTS_PERSON_BORDER = '#016ba5',
  QUICK_RESULTS_PERSON_BGCOLOR = 'rgb(3,169,244)',
  FILE = 'rgba(57, 106, 255, 0.34)',
  USERNAME = '#396aff',
  LICENSE_PLATE = '#396aff',
  ID = '#396aff',
  NAMED_ENTITY = '#396aff',
  EVENT_ENTITY = '#396aff',
  POST = '#396aff',
  CRYPTO_WALLET = 'rgba(94, 186, 152, 1)',
  CRYPTO_ACCOUNT = '#f1b90a',
  CRYPTO_ACCOUNT_BLACKLISTED = '#333333',
}

export enum nodeTypesIcons {
  PERSON = 'fa-solid fa-user',
  ORGANIZATION = 'fa-solid fa-buildings',
  PLACE = 'fa-solid fa-location-dot',
  MSISDN = 'fa-solid fa-phone-volume',
  PHONE = 'fa-solid fa-mobile',
  TOPIC = 'topic',
  SOCIAL_PROFILE = 'account_circle',
  CASE = 'fa-solid fa-folder-open',
  FACEBOOK_GROUP = 'fa-solid fa-people-group',
  CUSTOM_CLUSTER = 'folder',
  INSTANT_MESSAGING = 'account_circle',
  LOCATION = 'fa-solid fa-location-dot',
  EMAIL = 'fa-solid fa-envelope',
  FILE = 'fa-solid fa-file-lines',
  FILE_UPLOADER = 'fa-solid fa-file-arrow-up',
  FILE_UPLOADER_READY = 'fa-solid fa-file-lines',
  FILE_UPLOADER_UPLOADING = 'fa-solid fa-loader',
  FILE_UPLOADER_URL = 'fa-solid fa-link-simple',
  FILE_UPLOADER_FAILED = 'fa-solid fa-exclamation',
}

export enum nodeSubtypes {
  EDUCATION = 'education',
  WORKPLACE = 'workplace',
  HOME_LOCATION = 'homeLocation',
  WORKPLACE_LOCATION = 'workplaceLocation',
  CRYPTO_ACCOUNT_BINANCE = 'Dubai',
  CRYPTO_ACCOUNT_OKX = 'okx',
  CRYPTO_ACCOUNT_KUROCOIN = 'kurocoin',
}

export const nodeTypeToColor = {
  [nodeTypes.PERSON]: nodeTypesColors.PERSON,
  [nodeTypes.SOCIAL_PROFILE]: nodeTypesColors.SOCIAL_PROFILE,
  [nodeTypes.ORGANIZATION]: nodeTypesColors.ORGANIZATION,
  [nodeTypes.MSISDN]: nodeTypesColors.MSISDN,
  [nodeTypes.PLACE]: nodeTypesColors.PLACE,
  [nodeTypes.PHONE]: nodeTypesColors.PHONE,
  [nodeTypes.CASE]: nodeTypesColors.CASE,
  [nodeTypes.EMAIL]: nodeTypesColors.EMAIL,
  [nodeTypes.FACEBOOK_GROUP]: nodeTypesColors.FACEBOOK_GROUP,
  [nodeTypes.CUSTOM_CLUSTER]: nodeTypesColors.CUSTOM_CLUSTER,
  [nodeTypes.LOCATION]: nodeTypesColors.LOCATION,
  [nodeTypes.NAMED_ENTITY]: nodeTypesColors.NAMED_ENTITY,
  [nodeTypes.POST]: nodeTypesColors.POST,
  [nodeTypes.CRYPTO_WALLET]: nodeTypesColors.CRYPTO_WALLET,
  [nodeTypes.CRYPTO_ACCOUNT]: nodeTypesColors.CRYPTO_ACCOUNT,
};

export const entitiesTypeToLabel = {
  [nodeTypes.PERSON]: 'PERSON',
  [nodeTypes.SOCIAL_PROFILE]: 'PERSON',
  [nodeTypes.ORGANIZATION]: 'ORGANIZATION',
  [nodeTypes.MSISDN]: 'DEVICE',
  [nodeTypes.PLACE]: 'PLACE',
  [nodeTypes.PHONE]: 'PHONE',
  [nodeTypes.CUSTOM_CLUSTER]: 'CUSTOM',
  [nodeTypes.LOCATION]: 'LOCATION',
};

export enum nodeState {
  EXPANDED = 'EXPANDED',
  COLLAPSED = 'COLLAPSED',
}

export enum msisdnFilters {
  INCOMING_CALL = 'incomingCall',
  OUTGOING_CALL = 'outgoingCall',
  INCOMING_SMS = 'incomingSms',
  OUTGOING_SMS = 'outgoingSms',
  // TODO: handle the unknown types and remove this
  UNKNOWN = 'unknownType',
  // TODO: change these filters to something more generic
  CASE_CONFIRMED_INFECTION = 'confirmedInfection',
  CASE_POTENTIAL_INFECTION = 'potentialInfection',
}

export enum personFilters {
  TWITTER_FOLLOWING = 'twitterFollowing',
  TWITTER_FOLLOWER = 'twitterFollower',
  INSTAGRAM_FOLLOWING = 'instagramFollowing',
  INSTAGRAM_FOLLOWER = 'instagramFollower',
  FACEBOOK_FRIEND = 'facebookFriend',
  FACEBOK_FAMILY = 'facebookFamily',
  LINKEDIN_EMPLOYEE = 'linkedinEmployee',
  FACEBOOK_GROUP_MEMBER = 'facebookGroupMember',
  TIKTOK_FOLLOWING = 'tiktokFollowing',
  TIKTOK_FOLLOWER = 'tiktokFollower',
}

export enum nodeFilterSections {
  PERSON = 'person',
  MSISDN = 'msisdn',
  SOCIAL_PROFILES = 'social_profiles',
  PLACE = 'place',
  PHONE = 'phone',
  ORGANIZATION = 'organization',
  OTHER = 'other',
}

export const nodeFilterSectionsToLabel = {
  [nodeFilterSections.PERSON]: 'Person',
  [nodeFilterSections.PLACE]: 'Place',
  [nodeFilterSections.MSISDN]: 'MSISDN',
  [nodeFilterSections.PHONE]: 'Phone',
  [nodeFilterSections.OTHER]: 'Other',
  [nodeFilterSections.SOCIAL_PROFILES]: 'Social profile',
  [nodeFilterSections.ORGANIZATION]: 'Organization',
};

export interface NodeFilterItem {
  id: string;
  type: nodeTypes;
  icon?: string;
  label: string;
  section: nodeFilterSections;
  disabled: boolean;
}

export enum nodeSize {
  XSMALL = 0.75,
  SMALL = 1,
  MEDIUM = 1.25,
  LARGE = 1.5,
  XLARGE = 2,
}

export const nodeTypeToNodeFilterSection = {
  [nodeTypes.PERSON]: nodeFilterSections.PERSON,
  [nodeTypes.ORGANIZATION]: nodeFilterSections.ORGANIZATION,
  [nodeTypes.PLACE]: nodeFilterSections.PLACE,
  [nodeTypes.MSISDN]: nodeFilterSections.MSISDN,
  [nodeTypes.PHONE]: nodeFilterSections.PHONE,
  [nodeTypes.SOCIAL_PROFILE]: nodeFilterSections.SOCIAL_PROFILES,
  [nodeTypes.FACEBOOK_GROUP]: nodeFilterSections.OTHER,
  [nodeTypes.LOCATION]: nodeFilterSections.PLACE,
  [nodeTypes.INSTANT_MESSAGING]: nodeFilterSections.SOCIAL_PROFILES,
};

export interface NodeFilterElement {
  label: string;
  data: NodeFilterItem[];
  checkedData: NodeFilterItem[];
  matchedSearchData: NodeFilterItem[];
  expanded?: boolean;
}

export enum directionType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  UNKNOWN = 'unknown',
}

export const DEGREES_EXPLANATION =
  'It tells us how many direct, ‘one hop’ connections each node has to other nodes within the network. It is used for finding very connected individuals, popular individuals, individuals who are likely to hold most information or individuals who can quickly connect with the wider network.';

export enum quickFilters {
  FAMILY = 'family',
  TOP_CONNECTIONS_ALL_NETWORKS = 'top social connections (all social networks)',
  TOP_FACEBOOK_CONNECTIONS = 'top facebook connections',
  FACEBOOK_MUTUAL_FRIENDS = 'mutual facebook friends',
  TAGGED_PHOTO = 'tagged in same photo',
  TOP_PHOTO_LIKERS = 'Top likers in facebook photos',
  TOP_PHOTO_COMMENTERS = 'Top commenteers in facebook photos',
  TOP_POST_LIKERS = 'Top likers in facebook posts',
  TOP_POST_COMMENTERS = 'Top commenteers in facebook posts',
  TOP_POST_SHARED = 'Top facebook post shares',
  TOP_PHOTO_SHARED = 'Top facebook photo shares',
  SAME_PLACE = 'same hometown or current city',
  SAME_INSITUTION = 'attended same institution',
  WORKPLACES = 'current and previous workplaces (target)',
  EDUCATION = 'education (target)',
}

export interface TargetViewCluster {
  label: string;
  id: targetViewClusterIds;
  color: nodeTypesColors;
}

export enum targetViewClusterIds {
  GROUPS = 'facebookGroups',
}

export interface entityPanelTemplate {
  type: nodeTypes;
  label: string;
  tooltip: string;
  color: string;
  icon: nodeTypesIcons;
  placeholderText: string;
}

const entitiesIcons = {
  [nodeTypes.PERSON]: 'fa-regular fa-user',
  [nodeTypes.MSISDN]: 'fa-regular fa-phone',
  [nodeTypes.CASE]: 'fa-regular fa-folder-open',
  [nodeTypes.FILE_UPLOADER]: 'fa-regular fa-file-arrow-up',
};

export const entities: entityPanelTemplate[] = [
  {
    type: nodeTypes.PERSON,
    label: 'Target',
    tooltip: 'Drag and drop to load a target to the investigation',
    color: CUSTOM_ENTITY_INITIAL_COLOR,
    icon: entitiesIcons[nodeTypes.PERSON] as nodeTypesIcons,
    placeholderText: 'Select a target from the list',
  },
  {
    type: nodeTypes.MSISDN,
    label: 'Sim',
    tooltip: 'Drag and drop to add an MSISDN to the investigation',
    color: CUSTOM_ENTITY_INITIAL_COLOR,
    icon: entitiesIcons[nodeTypes.MSISDN] as nodeTypesIcons,
    placeholderText: 'Enter MSISDN',
  },
  {
    type: nodeTypes.CASE,
    label: 'Case',
    tooltip: 'Drag and drop to load a case to the investigation',
    color: CUSTOM_ENTITY_INITIAL_COLOR,
    icon: entitiesIcons[nodeTypes.CASE] as nodeTypesIcons,
    placeholderText: 'Select a case from the list',
  },
  {
    type: nodeTypes.FILE_UPLOADER,
    label: 'File',
    tooltip: 'Drag and drop to upload file for Text analysis',
    color: CUSTOM_ENTITY_INITIAL_COLOR,
    icon: entitiesIcons[nodeTypes.FILE_UPLOADER] as nodeTypesIcons,
    placeholderText: 'Upload file',
  },
];

export enum contextMenuActions {
  CALL_LOGS = 'call_logs',
  OSINT_SEARCH = 'osint_search',
  SOCIAL_PROFILES = 'social_profiles',
  SOCIAL_CONNECTIONS = 'social_connections',
  CREATE_TARGET = 'create_target',
  CREATE_CUSTOM_CLUSTER = 'create_custom_cluster',
  INSTANT_MESSAGING = 'instant_messaging',
  MSISDN = 'msisdn',
  DEVICE = 'device',
  REDIRECT_TO_TARGET = 'redirect_to_target',
  REDIRECT_TO_CALL_LOGS = 'redirect_to_call_logs',
  CLEAR_DATA = 'clear_data',
  RESTORE_DATA = 'restore_data',
  LOCATE = 'locate',
  WEBINT = 'webint',
  FACEBOOK_POSTS = 'get_facebook_posts',
  REQUEST_CALL_LOGS = 'request_call_logs',
  DOWNLOAD_FILE = 'download_file',
  DELETE_FILE = 'delete_file',
  ANALYSE_FILE = 'analyse_file',
  FACEBOOK_RELATIONS = 'get_facebook_relations',
  CRYPTO_ACCOUNT_INFO = 'crypto_account_info',
  ADD_TO_TARGET = 'add_to_target',
  COPY_ID = 'copy_id',
  EXPAND_GRAPH_FROM_CRYPTO_WALLET_ID = 'expand_graph_from_crypto_wallet_id',
  FETCH_TARGET = 'fetch_target',
}

export interface contextMenuOption {
  name: string;
  action: contextMenuActions;
  enabled: boolean;
  allowBatch: boolean;
  billingAction?: BillingActions;
  icon?: ContextMenuItemIcons;
}

enum ContextMenuItemIcons {
  DOWNLOAD_FILE = 'cloud_download',
  TEXT_ANALYSIS = 'manage_search',
  REMOVE_FILE = 'delete',
}

export const actionToContextMenuOption: {
  [key in contextMenuActions]: contextMenuOption;
} = {
  [contextMenuActions.INSTANT_MESSAGING]: {
    name: 'Fetch instant messaging profiles',
    action: contextMenuActions.INSTANT_MESSAGING,
    enabled: true,
    allowBatch: true,
  },
  [contextMenuActions.CALL_LOGS]: {
    name: 'Fetch call logs',
    action: contextMenuActions.CALL_LOGS,
    enabled: true,
    allowBatch: true,
  },
  [contextMenuActions.SOCIAL_PROFILES]: {
    name: 'Fetch social profiles',
    action: contextMenuActions.SOCIAL_PROFILES,
    enabled: true,
    allowBatch: true,
  },
  [contextMenuActions.OSINT_SEARCH]: {
    name: 'Search person details',
    action: contextMenuActions.OSINT_SEARCH,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.SOCIAL_CONNECTIONS]: {
    name: 'Get social profile connections',
    action: contextMenuActions.SOCIAL_CONNECTIONS,
    enabled: true,
    allowBatch: true,
  },
  [contextMenuActions.CREATE_TARGET]: {
    name: 'Create new target',
    action: contextMenuActions.CREATE_TARGET,
    enabled: false,
    allowBatch: false,
  },
  [contextMenuActions.CREATE_CUSTOM_CLUSTER]: {
    name: 'Create group',
    action: contextMenuActions.CREATE_CUSTOM_CLUSTER,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.MSISDN]: {
    name: 'Get sim',
    action: contextMenuActions.MSISDN,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.DEVICE]: {
    name: 'Get device',
    action: contextMenuActions.DEVICE,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.REDIRECT_TO_TARGET]: {
    name: 'Go to target page',
    action: contextMenuActions.REDIRECT_TO_TARGET,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.REDIRECT_TO_CALL_LOGS]: {
    name: 'View call log',
    action: contextMenuActions.REDIRECT_TO_CALL_LOGS,
    enabled: false,
    allowBatch: false,
  },
  [contextMenuActions.CLEAR_DATA]: {
    name: 'Clear all data',
    action: contextMenuActions.CLEAR_DATA,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.RESTORE_DATA]: {
    name: 'Restore graph data',
    action: contextMenuActions.RESTORE_DATA,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.LOCATE]: {
    name: 'Locate',
    action: contextMenuActions.LOCATE,
    enabled: true,
    allowBatch: true,
    billingAction: BillingActions.QUERY_LOCATION,
  },
  [contextMenuActions.WEBINT]: {
    name: 'Enhance Profile',
    action: contextMenuActions.WEBINT,
    enabled: true,
    allowBatch: false,
    billingAction: BillingActions.ADVANCED_OSINT,
  },
  [contextMenuActions.REQUEST_CALL_LOGS]: {
    name: 'Request call log',
    action: contextMenuActions.REQUEST_CALL_LOGS,
    enabled: false,
    allowBatch: false,
  },
  [contextMenuActions.FACEBOOK_POSTS]: {
    name: 'Get Facebook Posts',
    action: contextMenuActions.FACEBOOK_POSTS,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.DOWNLOAD_FILE]: {
    name: 'Download file',
    icon: ContextMenuItemIcons.DOWNLOAD_FILE,
    action: contextMenuActions.DOWNLOAD_FILE,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.DELETE_FILE]: {
    name: 'Remove node',
    action: contextMenuActions.DELETE_FILE,
    enabled: true,
    allowBatch: false,
    icon: ContextMenuItemIcons.REMOVE_FILE,
  },
  [contextMenuActions.ANALYSE_FILE]: {
    name: 'Text analysis the file',
    action: contextMenuActions.ANALYSE_FILE,
    enabled: true,
    allowBatch: false,
    billingAction: BillingActions.FILE_TEXT_ANALYSIS,
    icon: ContextMenuItemIcons.TEXT_ANALYSIS,
  },
  [contextMenuActions.FACEBOOK_RELATIONS]: {
    name: 'Get Facebook Relations',
    action: contextMenuActions.FACEBOOK_RELATIONS,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.CRYPTO_ACCOUNT_INFO]: {
    name: 'Account Info',
    action: contextMenuActions.CRYPTO_ACCOUNT_INFO,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.ADD_TO_TARGET]: {
    name: 'Add to target',
    action: contextMenuActions.ADD_TO_TARGET,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.COPY_ID]: {
    name: 'Copy id',
    action: contextMenuActions.COPY_ID,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.EXPAND_GRAPH_FROM_CRYPTO_WALLET_ID]: {
    name: 'Expand graph',
    action: contextMenuActions.EXPAND_GRAPH_FROM_CRYPTO_WALLET_ID,
    enabled: true,
    allowBatch: false,
  },
  [contextMenuActions.FETCH_TARGET]: {
    name: 'Bring Osint data',
    action: contextMenuActions.FETCH_TARGET,
    enabled: true,
    allowBatch: false,
  },
};

export const contextMenuAllowedTypes = [
  nodeTypes.MSISDN,
  nodeTypes.PERSON,
  nodeTypes.SOCIAL_PROFILE,
  nodeTypes.FILE,
  nodeTypes.CRYPTO_ACCOUNT,
  nodeTypes.CRYPTO_WALLET,
];

// TODO: add the rest of the actions when they will be implemented
export const contextMenuOptionsByType: {
  [key in nodeTypes | string]: contextMenuOption[];
} = {
  [nodeTypes.MSISDN]: [
    actionToContextMenuOption[contextMenuActions.REDIRECT_TO_CALL_LOGS],
    actionToContextMenuOption[contextMenuActions.LOCATE],
    actionToContextMenuOption[contextMenuActions.REQUEST_CALL_LOGS],
  ],
  [nodeTypes.PERSON]: [
    actionToContextMenuOption[contextMenuActions.REDIRECT_TO_TARGET],
    actionToContextMenuOption[contextMenuActions.OSINT_SEARCH],
    actionToContextMenuOption[contextMenuActions.FETCH_TARGET],
  ],
  background: [
    actionToContextMenuOption[contextMenuActions.CLEAR_DATA],
    actionToContextMenuOption[contextMenuActions.RESTORE_DATA],
  ],
  [nodeTypes.FILE]: [
    actionToContextMenuOption[contextMenuActions.DOWNLOAD_FILE],
    actionToContextMenuOption[contextMenuActions.DELETE_FILE],
    actionToContextMenuOption[contextMenuActions.ANALYSE_FILE],
  ],
  [nodeTypes.SOCIAL_PROFILE]: [
    actionToContextMenuOption[contextMenuActions.WEBINT],
  ],
  [nodeTypes.CRYPTO_ACCOUNT]: [
    actionToContextMenuOption[contextMenuActions.CRYPTO_ACCOUNT_INFO],
    actionToContextMenuOption[contextMenuActions.COPY_ID],
  ],
  [nodeTypes.CRYPTO_WALLET]: [
    actionToContextMenuOption[contextMenuActions.COPY_ID],
    actionToContextMenuOption[
      contextMenuActions.EXPAND_GRAPH_FROM_CRYPTO_WALLET_ID
    ],
  ],
};

export const contextMenuOptionsByTypeAndSocialProfile: {
  [key in nodeTypes]?: {
    [key in relationTypes]?: contextMenuOption[];
  };
} = {
  [nodeTypes.SOCIAL_PROFILE]: {
    [relationTypes.FACEBOOK]: [
      actionToContextMenuOption[contextMenuActions.FACEBOOK_POSTS],
      actionToContextMenuOption[contextMenuActions.FACEBOOK_RELATIONS],
    ],
  },
};

export const instantMessagingPlatformsToFetch = [
  Platform.WHATSAPP,
  Platform.TELEGRAM,
  Platform.SKYPE,
  Platform.TRUECALLER,
];

export interface EntityAttributes {
  msisdn?: string;
  lat?: number;
  lon?: number;
  source?: string;
  sourceId?: string;
  url?: string;
  imei?: string;
}

export const linkTypeToText = {
  [linkTypes.CALLED]: 'Called',
  [linkTypes.LOCATED_AT]: 'Located at',
  [linkTypes.USED_BY]: 'Used by',
  [linkTypes.DATA]: 'Data',
  [linkTypes.SMSED]: 'SMS',
  [linkTypes.SOCIAL_PROFILE]: 'Associated with',
};

export interface NodeData {
  type: nodeTypes;
  label?: string;
  url?: string;
  username?: string;
  relation?: relationTypes;
  image?: string;
  isTargetNode?: boolean;
  state?: nodeState;
  parent?: string | string[];
  targetId?: string;
  userId?: string;
  key?: string;
  source?: string;
  entityType?: string;
  caseData?: {
    targetsIds: string[];
    parentProfileIds: string[];
  };
  model?: string;
  brand?: string;
  isCustomGroup?: boolean;
  disabled?: boolean;
  companyId?: string;
  description?: string;
  membersCount?: number;
  sourceEntityId?: string;
  socialSearchResult?: Person;
  verified?: boolean;
  isPublic?: boolean;
  subtype?: nodeSubtypes;
}

export interface EventNodeData extends NodeData {
  uploadedAt?: Date;
  createdAt?: Date;
  action?: string;
  object?: string;
  when?: string[];
  where?: string[];
  who?: string;
}

export interface FileNodeData extends NodeData {
  uploadedAt?: Date;
}

export interface NodeAnimationProperties {
  id: string;
  e?: number;
  b?: string;
  w?: number;
  c?: string;
  c2?: string;
  bg?: boolean;
  g?: Glyph[];
  oc?: any;
  customCombo?: boolean;
  bw?: number;
}

export interface DegreesAnimation {
  id: string;
  e?: number;
  b?: string;
  w?: number;
  c?: string;
  bg?: boolean;
  g?: Glyph[];
  oc?: any;
}

export const commonCustomGroups = [
  {
    type: 'following',
    label: 'Following',
  },
  {
    type: 'follower',
    label: 'Followers',
  },
  {
    type: 'post',
    label: 'Posts',
  },
  {
    type: 'photo',
    label: 'Photos',
  },
];

export const facebookSpecificCustomGroups = [
  {
    type: 'family',
    label: 'Family',
  },
  {
    type: 'friend',
    label: 'Friends',
  },
  {
    type: 'place',
    label: 'Check-ins',
  },
  {
    type: 'authored',
    label: 'Tagged in posts of',
  },
  {
    type: 'published',
    label: 'Tagged in photos of',
  },
  {
    type: 'group',
    label: 'Groups',
  },
];

export enum centralityAnalysis {
  NONE = 'none',
  DEGREES = 'degrees',
  BETWEENNESS = 'betweenness',
  EIGEN_CENTRALITY = 'eigenCentrality',
}

export const graphAnalysisOptions = [
  {
    type: centralityAnalysis.NONE,
    label: 'None',
    description: 'Restore',
  },
  {
    type: centralityAnalysis.DEGREES,
    label: 'Degrees',
    description:
      'Highlight nodes based on number and type of direct connections',
  },
  // Hide for now
  // {
  //   type: centralityAnalysis.BETWEENNESS,
  //   label: 'Betweenness',
  //   description: 'Highlight nodes that act as "bridges" between the connected network'
  // },
  // {
  //   type: centralityAnalysis.EIGEN_CENTRALITY,
  //   label: 'Eigen centrality',
  //   description:
  //     'Calculates the extended connections of a node. Can identify nodes with influence over the whole network, and not just those directly connected to it.'
  // }
];

export const linkTypeWeights = {
  [linkTypes.UNKNOWN]: 0.1,
  [linkTypes.FACEBOOK_FRIEND]: 0.7,
  [linkTypes.INSTAGRAM_FOLLOWING]: 0.5,
  [linkTypes.TWITTER_FOLLOWING]: 0.5,
  [linkTypes.INSTAGRAM_FOLLOWER]: 0.4,
  [linkTypes.TWITTER_FOLLOWER]: 0.4,
  [linkTypes.FACEBOK_FAMILY]: 0.8,
  [linkTypes.DEVICE]: 0.5,
  [linkTypes.SIM]: 0.8,
  [linkTypes.ORGANIZATION]: 0.4,
  [linkTypes.SOCIAL_PROFILE]: 0.8,
  [linkTypes.LINKEDIN_EMPLOYEE]: 0.4,
  [linkTypes.FACEBOOK_GROUP]: 0.4,
  [linkTypes.FACEBOOK_GROUP_MEMBER]: 0.3,
  [linkTypes.PLACE]: 0.6,
  [linkTypes.FACEBOK_TAGGED_IN_PHOTO]: 0.8,
  [linkTypes.FACEBOOK_LIKED_PHOTO]: 0.8,
  [linkTypes.FACEBOOK_COMMENTED_PHOTO]: 0.9,
  [linkTypes.FACEBOOK_SIGNIFICANT_OTHER]: 0.9,
  [linkTypes.FACEBOOK_LIKED_POST]: 0.8,
  [linkTypes.FACEBOOK_COMMENTED_POST]: 0.9,
  [linkTypes.LOCATED_AT]: 0.9,
  [linkTypes.FACEBOOK_TAGGED_IN_AUTHOR_POST]: 0.9,
  [linkTypes.CALLED]: 0.8,
  [linkTypes.SMSED]: 0.7,
  [linkTypes.DATA]: 0.4,
  [linkTypes.FACEBOOK_FOLLOWER]: 0.4,
  [linkTypes.FACEBOOK_FOLLOWING]: 0.5,
  [linkTypes.FACEBOOK_MUTUAL_FRIENDS]: 0.7,
  [linkTypes.CALL_LOG_TOP_ASSOCIATE]: 0.9,
  [linkTypes.EMAIL]: 0.7,
  [linkTypes.GROUP_BELONGS_TO_PROFILE]: 0.1,
  [linkTypes.POSTED_PHOTO]: 0.3,
  [linkTypes.POST_AUTHOR]: 0.3,
};

export const convertTimelineEventTypesToLinkTypes = {
  [InvestigationDataSourceType.voice]: linkTypes.CALLED,
  [InvestigationDataSourceType.sms]: linkTypes.SMSED,
  [InvestigationDataSourceType.data]: linkTypes.DATA,
  [InvestigationDataSourceType.geo_query]: linkTypes.LOCATED_AT,
  [InvestigationDataSourceType.checkin]: linkTypes.PLACE,
  [InvestigationDataSourceType.photo]: linkTypes.POSTED_PHOTO,
  [InvestigationDataSourceType.post]: linkTypes.POST_AUTHOR,
};

export const notSavedNodes = [nodeTypes.FILE_UPLOADER];

export const LinkColors = {
  [linkTypes.HAS_WALLET]: 'black',
};
