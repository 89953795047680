import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { isValidPhoneFormValidator, passwordValidator } from '@app/validators';
import { User } from 'src/app/services/user/user.model';
import { UserFormState } from '../user-settings.models';
import { AuthDataService } from '../../../services/authentication/auth-data.service';

@Injectable({
  providedIn: 'root',
})
export class MyDetailsService {
  emailPattern = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$';

  constructor(private authService: AuthDataService) {}

  initForm(formState: UserFormState, currentUser: User): UntypedFormGroup {
    const email = new UntypedFormControl(currentUser.email || '', [
      Validators.pattern(this.emailPattern),
    ]);
    const otpPhone = new UntypedFormControl(currentUser.otpPhone || '', [
      isValidPhoneFormValidator(),
    ]);
    const password = new UntypedFormControl('');
    const newPassword = new UntypedFormControl('', null, [
      passwordValidator(this.authService),
    ]);

    switch (formState) {
      case UserFormState.All:
        return new UntypedFormGroup({
          email,
          otpPhone,
          password,
          newPassword,
        });
      case UserFormState.Passwords:
        return new UntypedFormGroup({
          password,
          newPassword,
        });
      case UserFormState.Phone:
        return new UntypedFormGroup({
          otpPhone,
        });
      case UserFormState.Email:
        return new UntypedFormGroup({
          email,
        });
      default:
        return;
    }
  }
}
