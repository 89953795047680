import { EMPTY, interval, merge, Observable, Subject } from 'rxjs';
import { mapTo, startWith, switchMap } from 'rxjs/operators';
export class HistoryPlayback {
  private pause$: Subject<void> = new Subject();
  private timer$: Observable<number>;
  private resume$: Subject<void> = new Subject();
  private intervalInMillis: number;
  private stopped = false;

  constructor(intervalInMillis = 1500) {
    this.intervalInMillis = intervalInMillis;
    this.createTimerInterval();
  }

  start() {
    if (!this.timer$) {
      this.createTimerInterval();
    }
    this.stopped = false;
    this.resume$.next();
  }

  stop() {
    this.stopped = true;
    this.timer$ = null;
    this.pause();
  }

  pause() {
    this.pause$.next();
  }

  onInterval(): Observable<number> {
    return this.timer$;
  }

  private createTimerInterval() {
    const interval$ = interval(this.intervalInMillis);
    this.timer$ = merge(
      this.pause$.pipe(mapTo(false)),
      this.resume$.pipe(mapTo(true))
    ).pipe(
      startWith(false),
      switchMap((val) => {
        return !this.stopped && val ? interval$ : EMPTY;
      })
    );
  }
}
