import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { get, head, union } from 'lodash-es';
import moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { TargetFormComponent } from 'src/app/modules/profiler/components/target-form/target-form.component';
import { TargetNoteFormComponent } from 'src/app/modules/profiler/components/target-note-form/target-note-form.component';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { CaseService } from 'src/app/services/case/case.service';
import { ImageService } from 'src/app/services/image/image.service';
import { QueryService } from 'src/app/services/query/query.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Action } from 'src/app/shared/classes/action.class';
import { ExpiredDialogComponent } from 'src/app/shared/components/expired-dialog/expired-dialog.component';
import { Case } from 'src/app/shared/models/case.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ActionService } from 'src/app/shared/services/action.service';
import { TargetDeleteComponent } from '../target-delete/target-delete.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import {
  TargetItemInfo,
  TargetCardInfoService,
} from 'src/app/modules/profiler/services/target-card-info.service';

@Component({
  selector: 'app-target-list-item',
  templateUrl: './target-list-item.component.html',
  styleUrls: ['./target-list-item.component.scss'],
})
export class TargetListItemComponent
  extends BaseComponent
  implements OnInit, OnChanges, AfterViewChecked
{
  // inputs
  @Input() target: TargetItem;
  @Input() color: string;

  @Input() isCurrentTarget: boolean;
  @Input() caseView: boolean;
  @Input() geoDetailsView: boolean; // in geolocation details view
  @Input() updateExistingTargetView: boolean;
  @Input() currentCheckedTarget: TargetItem;

  // outputs
  @Output() removedTargetFromCase: EventEmitter<{
    data: TargetItem;
    deleted: boolean;
  }> = new EventEmitter<{
    data: TargetItem;
    deleted: boolean;
  }>();
  @Output() checkedTarget = new EventEmitter<TargetItem>();
  @Output() uncheckedTarget = new EventEmitter<TargetItem>();
  @ViewChild('aliasInput') aliasInput: ElementRef;

  targetCases: Case[] = [];
  nameInitials: string;
  urlImage: string = undefined;
  targetProfilePhoto: any;
  targetActiveQuarantine = false;
  enableDeleteTarget: boolean;
  enableCaseOverview: boolean;
  enableLinkAnalysis: boolean;
  targetId: string;
  allTargetTelnos: string[] = [];
  sharedTargetTelnos: string[] = [];

  aliasEdit = true;
  isTargetChecked = false;
  aliasInputCtrl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(20),
    Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$'),
  ]);
  errorMsg: boolean;
  loader: boolean;
  targetExpired: boolean;
  showTargetExpiration: boolean;
  targetCreditsChargesEnabled = false;
  diff: number;
  expirationDaysLeft: number;
  expireTargetDays = 0;
  imagePlaceholder = 'assets/static/images/user.svg';
  targetImage = this.imagePlaceholder;
  caseCreditsChargesEnabled = false;
  expireCaseDays = 0;
  targetItemInfo: TargetItemInfo;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public _DomSanitizationService: DomSanitizer,
    private router: Router,
    private targetService: TargetService,
    private caseService: CaseService,
    private queryService: QueryService,
    private profilerService: ProfilerService,
    private appConfigService: AppConfigService,
    private imageService: ImageService,
    private actionService: ActionService,
    private translationService: TranslationService,
    private localStorageService: LocalStorageService,
    private cdRef: ChangeDetectorRef,
    private targetItemInfoService: TargetCardInfoService
  ) {
    super();
    this.enableDeleteTarget =
      this.appConfigService.getConfigVariable('enableDeleteTarget');
    this.enableCaseOverview =
      this.appConfigService.getConfigVariable('enableCaseOverview');
    this.enableLinkAnalysis =
      this.appConfigService.getConfigVariable('enableLinkAnalysis');
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
    this.expireTargetDays =
      this.appConfigService.getConfigVariable('expireTargetDays');
    this.caseCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForCase'
    );
    this.expireCaseDays =
      this.appConfigService.getConfigVariable('expireCaseDays');
    if (!this.router.url.includes('case')) {
      this.targetId = this.route.snapshot.paramMap.get('id');
    }
  }

  private createNameInitials(): void {
    if (
      this.target &&
      this.target.photos.length === 0 &&
      this.urlImage === undefined
    ) {
      const targetName =
        get(this.target.names, 'length') && head(this.target.names)
          ? head(this.target.names)
          : this.target.alias;
      if (targetName && targetName !== '') {
        this.nameInitials = '';
        targetName.indexOf(' ') >= 0
          ? targetName
              .split(' ')
              .forEach((initial) => (this.nameInitials += initial.charAt(0)))
          : (this.nameInitials = targetName.charAt(0));
      }
      this.queryService.targetProfilePhoto.next(this.urlImage);
    }
  }

  ngOnInit() {
    if (!this.caseView) {
      this.paramsChangeListener();
    } else {
      this.setTargetImage();
    }

    this.subscriptions.push(
      this.profilerService.targetData.subscribe((target: TargetItem) => {
        if (!target || this.targetId !== target.id) {
          return;
        }
        this.target = target;
        this.setTargetImage();

        if (this.target['telnos']) {
          this.collectAllTargetTelnos();
        }
        if (head(this.target.photos)) {
          this.urlImage = head(this.target.photos);
          this.queryService.targetProfilePhoto.next(this.urlImage);
          this.nameInitials = '';
        }
      })
    );

    this.subscriptions.push(
      this.targetService.quarantineGeofenceData.subscribe((data: any) => {
        this.targetActiveQuarantine =
          data &&
          data.payload &&
          this.target &&
          this.target.telnos.includes(data.payload.queryArgs.telno)
            ? true
            : false;
      })
    );

    this.subscriptions.push(
      this.targetService.shareTargetInfo.subscribe(
        (item: Partial<TargetItem>) => {
          if (this.targetId && this.targetId === item.id) {
            if ('telnos' in item) {
              this.sharedTargetTelnos = this.targetService.splitByComma(
                item.telnos
              );
              this.collectAllTargetTelnos();
            }
          }
        }
      )
    );

    this.subscriptions.push(
      this.actionService
        .onAction('profiler-edit-target')
        .subscribe(() => this.editTarget())
    );

    if (this.targetId) {
      this.onKeyupCheckAlias();
      this.showTargetExpiration = this.targetCreditsChargesEnabled;
    }
  }

  ngAfterViewChecked() {
    if (this.target) {
      this.targetExpiration();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.target && changes.target.currentValue) {
      if ('telnos' in changes.target.currentValue) {
        this.collectAllTargetTelnos(
          <string[]>changes.target.currentValue.telnos
        );
      }
      this.createNameInitials();
      if (!this.caseView && this.targetId) {
        this.getQuarantineGeofence();
      }
      this.isTargetChecked = this.isCheckedTarget() || this.target.checked;
      this.generateCompleteTargetData();
    }
  }

  private generateCompleteTargetData(): void {
    this.subscriptions.push(
      this.targetItemInfoService
        .generateCompleteTargetData(this.targetId)
        .subscribe((targetItemInfo: TargetItemInfo) => {
          if (!targetItemInfo) {
            return;
          }
          this.targetItemInfo = targetItemInfo;
        })
    );
  }

  private populateTargetCases() {
    this.targetCases = [];
    this.target.assignedCases.forEach((caseId) => {
      this.caseService.getCase(caseId, false).subscribe((data) => {
        this.targetCases.push(data);
        this.cdRef.markForCheck();
      });
    });
  }

  collectAllTargetTelnos(telnos: string[] = []) {
    this.allTargetTelnos = [];
    this.allTargetTelnos = union(
      this.target['telnos'] ? this.target.telnos : [],
      this.sharedTargetTelnos,
      telnos
    );
  }

  public generateProfilerPdfReport() {
    const profilerReportSubscription = this.queryService
      .createProfilerPdfReport({
        target_id: this.targetId,
        target_profile_photo: this.targetProfilePhoto,
      })
      .subscribe((data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const formattedDate = format(new Date(), 'DDMMYYYY_HHmmss');
        saveAs(blob, `profilerReport_${formattedDate}.pdf`);
        profilerReportSubscription.unsubscribe();
      });
  }

  public handleAvatarError() {
    const targetName =
      get(this.target.names, 'length') && head(this.target.names)
        ? head(this.target.names)
        : this.target.alias;
    if (targetName && targetName !== '') {
      this.nameInitials = '';
      targetName.indexOf(' ') >= 0
        ? targetName
            .split(' ')
            .forEach((initial) => (this.nameInitials += initial.charAt(0)))
        : (this.nameInitials = targetName.charAt(0));
    }
  }

  private setTargetImage() {
    let image;

    if (this.target && this.target.photos && head(this.target.photos)) {
      image = <string>(
        this.imageService.getPhotoUrl(head(this.target.photos), true)
      );
    } else {
      this.urlImage = undefined;
    }

    this.targetImage = image;
    this.createNameInitials();
    this.cdRef.markForCheck();
  }

  public openMenu(event: any): void {
    event.stopPropagation();
  }

  public openLinkedCases(event) {
    event.stopPropagation();
    this.populateTargetCases();
  }

  public deleteTarget(): void {
    this.dialog
      .open(TargetDeleteComponent, {
        width: '30vw',
        height: 'auto',
        disableClose: true,
        data: { type: 'delete-target' },
        panelClass: 'delete-profiler-target-dialog',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.targetService.removeTarget(this.target).subscribe(() => {
            if (this.caseView) {
              this.removedTargetFromCase.next({
                data: this.target,
                deleted: true,
              });
            }
            this.targetService
              .fetchPaginatedTargets({ limit: 22, page: 1, filterArg: '' })
              .subscribe();
          });
        }
      });
  }

  public removeFromCase() {
    this.dialog
      .open(TargetDeleteComponent, {
        width: '30vw',
        height: 'auto',
        disableClose: true,
        data: { type: 'removeCase' },
        panelClass: 'delete-profiler-target-dialog',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.removedTargetFromCase.next({
            data: this.target,
            deleted: false,
          });
        }
      });
  }

  public editTarget(): void {
    this.dialog.open(TargetFormComponent, {
      width: '30vw',
      height: 'auto',
      data: this.target,
      disableClose: true,
    });
  }

  public addNote(): void {
    this.dialog.open(TargetNoteFormComponent, {
      width: '30vw',
      height: 'auto',
      data: this.target,
    });
  }

  public navigateToTarget() {
    if (this.targetCreditsChargesEnabled && this.target.expired) {
      return this.dialog
        .open(ExpiredDialogComponent, {
          width: '450px',
          data: {
            title: this.translationService.translate('Target is expired'),
            message: this.translationService.interpolate(
              'Click on the button below to renew for #{days} days',
              {
                days: this.expireTargetDays.toString(),
              }
            ),
            action: BillingActions.TARGET_MANAGEMENT,
            countOfActionsCharged: 1,
          },
          panelClass: 'expired-credit',
        })
        .afterClosed()
        .subscribe((result: Action) => {
          if (result.key.includes('success')) {
            this.subscriptions.push(
              this.targetService.renewTarget(this.target?.id).subscribe(
                () => {
                  this.target.expired = false;
                  if (this.updateExistingTargetView) {
                    this.isTargetChecked = true;
                    this.emitCheckedTargetsList(this.isTargetChecked);
                  }
                  this.showMessage(
                    this.translationService.translate(
                      'Target renewed successfully!'
                    )
                  );
                },
                (error) => {
                  if (this.updateExistingTargetView) {
                    this.isTargetChecked = false;
                    this.emitCheckedTargetsList(this.isTargetChecked);
                  }
                  this.showMessage(
                    this.translationService.translate(
                      error ? error : 'Target has not been renewed'
                    )
                  );
                },
                () =>
                  this.targetService
                    .getTarget(this.target?.id)
                    .subscribe((updatedTarget) => {
                      this.target = updatedTarget;
                      this.setTargetImage();
                    })
              )
            );
          } else if (this.updateExistingTargetView) {
            this.isTargetChecked = false;
            this.emitCheckedTargetsList(this.isTargetChecked);
          }
        });
    }
    if (this.updateExistingTargetView) {
      return;
    }
    this.profilerService.restoreDashboardSections();
    if (this.isCurrentTarget) {
      return;
    }
    this.router.navigate(['targets', this.target?.id, 'overview']);
    this.profilerService.targetData.next(null);
  }

  private renewCaseDialog(caseItem: Case, routeTo: string): void {
    this.dialog
      .open(ExpiredDialogComponent, {
        data: {
          title: this.translationService.translate('Case is expired'),
          message: this.translationService.interpolate(
            'Click on the button below to renew for #{days} days',
            {
              days: this.expireCaseDays.toString(),
            }
          ),
          action: BillingActions.CASE_MANAGEMENT,
          countOfActionsCharged: 1,
        },
        panelClass: 'expired-credit',
      })
      .afterClosed()
      .subscribe((result: Action) => {
        if (result.key.includes('success')) {
          this.renewCaseItem(caseItem, routeTo);
        }
      });
  }

  renewCaseItem(caseItem: Case, routeTo: string): void {
    this.subscriptions.push(
      this.caseService.renewCase(caseItem.id).subscribe(
        () => {
          this.router.navigateByUrl(routeTo);
          this.showMessage(
            this.translationService.translate('Case renewed successfully!')
          );
        },
        (error) =>
          this.showMessage(
            this.translationService.translate(
              error.messages ? error.messages : 'Case has not been renewed'
            )
          ),
        () =>
          this.caseService
            .updateCase(caseItem)
            .subscribe((updatedCase) => (caseItem = updatedCase))
      )
    );
  }

  addToCheckedTargets(event: MatCheckboxChange) {
    if (this.caseCreditsChargesEnabled && this.target.expired) {
      setTimeout(() => {
        this.isTargetChecked = false;
      }, 0);
      return;
    }
    this.emitCheckedTargetsList(event.checked);
  }

  emitCheckedTargetsList(checked: boolean): void {
    if (checked) {
      this.checkedTarget.emit(this.target);
    } else {
      this.uncheckedTarget.emit(this.target);
    }
  }

  goToCaseDashboard(caseObj: Case): void {
    if (caseObj && (this.enableCaseOverview || this.enableLinkAnalysis)) {
      const tab = this.enableCaseOverview ? 'overview' : 'link-analysis';
      if (this.caseCreditsChargesEnabled && caseObj.expired) {
        this.renewCaseDialog(caseObj, `/case/${caseObj.id}/${tab}`);
      } else {
        this.router.navigateByUrl(`/case/${caseObj.id}/${tab}`);
      }
    }
  }

  public isCheckedTarget(): boolean {
    return (
      this.currentCheckedTarget &&
      this.currentCheckedTarget.id === this.targetId
    );
  }

  private getQuarantineGeofence() {
    this.targetService
      .getTargetQuarantineGeofence(this.targetId)
      .subscribe((data: any) => {
        this.targetActiveQuarantine =
          data &&
          data.payload &&
          this.target.telnos.includes(data.payload.queryArgs.telno)
            ? true
            : false;
      });
  }

  onEditAlias(alias: string) {
    this.aliasEdit = false;
    this.aliasInputCtrl.setValue(alias);
    this.cdRef.markForCheck();
    this.aliasInput?.nativeElement.focus();
  }

  onKeyupCheckAlias() {
    this.subscriptions.push(
      this.aliasInputCtrl.valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((newAlias: string) => {
          this.validateNewAlias(newAlias);
        })
    );
  }

  private paramsChangeListener(): void {
    this.subscriptions.push(
      this.route.paramMap.subscribe((params) => {
        this.targetId = params.get('id');
        this.target = null;
        this.targetImage = null;
      })
    );
  }

  private validateNewAlias(newAlias: string) {
    const subscription = this.targetService.paginatedTargets.subscribe({
      next: (data: {
        result: TargetItem[];
        meta: {
          totalPages: number;
          totalResults: number;
          pageSize: number;
          page: number;
        };
      }) => {
        if (1 !== data.meta.totalResults) {
          this.errorMsg = false;
          return;
        }

        if (data.result[0].id === this.targetId) {
          this.errorMsg = false;
          return;
        }

        this.errorMsg =
          data.result[0].alias.toLowerCase() === newAlias.toLowerCase();

        subscription.unsubscribe();
      },
    });

    this.targetService
      .fetchPaginatedTargets({ limit: 1, page: 1, filterArg: newAlias })
      .subscribe();
  }

  updateTargetAlias(targetAlias: string) {
    this.loader = true;
    this.subscriptions.push(
      this.targetService
        .editTargetAlias(this.target, {
          alias: targetAlias,
          user: this.localStorageService.getCurrentUser().identity,
        })
        .subscribe(
          () => {
            this.loader = false;
            this.errorMsg = false;
            this.aliasEdit = true;
            this.showMessage(
              this.translationService.translate('Target edited successfully!')
            );
            this.profilerService.refreshAboutAlias.next({ alias: targetAlias });
          },
          (error: any) => {
            this.loader = false;
            this.showMessage(
              this.translationService.translate(
                error.messages ? error.messages : 'Target has not been edited'
              )
            );
          }
        )
    );
  }

  private targetExpiration() {
    const startDate = moment(new Date());
    const endDate = moment(this.target.expiresAt);
    this.expirationDaysLeft = endDate.diff(startDate, 'days');
  }
}
