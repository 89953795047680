<app-cl-export-base [title]="'Target summary report'">
  <ng-container ngProjectAs="leftContent">
    <div
      class="container-export"
      [sectionIdentifier]="targetSummarySectionIdentifier.linkAnalysis"
      [reportSectionAttribute]="'Link Analysis' | translate"
    >
      <ng-template #linkAnalysisContainer></ng-template>
    </div>

    <div
      class="container-export container-export-location"
      [sectionIdentifier]="targetSummarySectionIdentifier.locations"
      [reportSectionAttribute]="'Locations' | translate"
    >
      <ng-template #locationsContainer></ng-template>
    </div>

    <div
      class="container-export container-export-activity"
      [sectionIdentifier]="targetSummarySectionIdentifier.activityPatterns"
      [reportSectionAttribute]="'Activity patterns' | translate"
    >
      <ng-template #activityPatternContainer></ng-template>
    </div>
    <div
      class="container-export content"
      [sectionIdentifier]="targetSummarySectionIdentifier.socialWordCloud"
      [reportSectionAttribute]="'Social word cloud' | translate"
    >
      <ng-template #textAnalysisContainer></ng-template>
    </div>

    <ng-container *ngIf="loading">
      <div class="progress-bar-container">
        <app-multi-stage-loader
          [title]="title"
          [query]="target?.alias"
          [progressBarValue]="progressBarValue"
          [progressBarElements]="progressBarElements"
        ></app-multi-stage-loader>
      </div>
    </ng-container>
    <embed
      [attr.src]="embedUrl"
      height="100%"
      width="100%"
      *ngIf="embedUrl && !loading"
    />
  </ng-container>

  <ng-container ngProjectAs="rightContent">
    <form [formGroup]="exportForm" class="export-form">
      <fieldset [disabled]="loading" [class.form-disabled]="loading">
        <section>
          <mat-radio-group formControlName="options">
            <mat-radio-button value="1">{{
              'Full report' | translate
            }}</mat-radio-button>
            <mat-radio-button value="2">{{
              'Custom report' | translate
            }}</mat-radio-button>
          </mat-radio-group>
        </section>
        <section [formGroup]="sections">
          <ul class="section-options">
            <ng-container *ngFor="let section of sections.controls | keyvalue">
              <li>
                <mat-checkbox [formControlName]="section.key">{{
                  section.key | humanizeTitleCase | translate
                }}</mat-checkbox>
              </li>
            </ng-container>
          </ul>
        </section>
        <section>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ 'Note' | translate }}</mat-label>
            <textarea
              matInput
              class="note"
              formControlName="note"
              cols="2"
              rows="3"
            ></textarea>
          </mat-form-field>
          <div
            *ngIf="
              !exportForm.controls.note.valid && exportForm.controls.note.dirty
            "
          >
            <mat-error
              *ngIf="exportForm.controls['note'].hasError('minlength')"
              >{{ 'Please enter 3 or more characters' | translate }}</mat-error
            >
            <mat-error
              *ngIf="exportForm.controls['note'].hasError('maxlength')"
              >{{ 'You have more than 255 characters' | translate }}</mat-error
            >
          </div>
        </section>
      </fieldset>
    </form>
  </ng-container>

  <ng-container ngProjectAs="button">
    <button
      mat-button
      class="cp-btn-primary"
      type="submit"
      (click)="onSubmit()"
      [disabled]="!exportForm.valid || loading"
    >
      {{ 'Export' | translate }}
    </button>
  </ng-container>
</app-cl-export-base>
