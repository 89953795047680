import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { SearchIntelModel } from 'src/app/modules/search-intel/models/search-intel.model';
import { SearchIntelService } from 'src/app/modules/search-intel/services/search-intel.service';
import { BaseService } from 'src/app/services/base.service';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';

@Injectable()
export class SearchIntelNavbarHelperService extends BaseService {
  enableIntelResultsV2: boolean;

  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private searchIntelService: SearchIntelService,
    private appConfigService: AppConfigService
  ) {
    super(router, snackBar);
    this.enableIntelResultsV2 = this.appConfigService.getConfigVariable(
      'enableIntelResultsV2'
    );
  }

  navigateToResultsView(search: SearchIntelModel): void {
    if (this.enableIntelResultsV2) {
      this.router.navigateByUrl(
        `/${ApplicationMainPageUrls.WEBINT}/results-view`
      );
    } else if (search?.searchByHashtag) {
      this.searchIntelService.currentSearchIntel.next({
        search,
        noResultSearch: false,
      });
      this.router.navigateByUrl(
        `/${ApplicationMainPageUrls.WEBINT}/post-results`
      );
    } else {
      this.router.navigateByUrl(
        `/${ApplicationMainPageUrls.WEBINT}/results/${search.id}`
      );
    }
  }

  routeToHistory() {
    this.router.navigateByUrl(`/${ApplicationMainPageUrls.WEBINT}/history`);
  }
}
