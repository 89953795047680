import { Component, Input } from '@angular/core';

@Component({
  selector: 'intellectus-panel-title',
  template: `
    <div class="icon-title">
      <i class="fa-regular" [ngClass]="faIconClass"></i>
      <span class="panel-title">{{ panelTitle }}</span>
    </div>
  `,
  styles: [
    `
      @import 'src/styles/palette';
      .icon-title {
        display: flex;
        gap: 5px;
      }
      :host {
        font-size: 1.1rem;
        font-weight: 400;
        color: $dark-blue;
        line-height: 20px;
      }
    `,
  ],
})
export class PanelTitleComponent {
  @Input() panelTitle = '';
  @Input() faIconClass = 'fa-grip-dots-vertical';
}
