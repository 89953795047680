import { DataSource } from '@trg-commons/data-models-ts';
import { SearchIntelModel } from './search-intel.model';

export class IntelResults {
  filters?: IntelFilters;
  persons?: IntelResultsPerson[];
  queryArgs?: { argValue: string; argType: string }[];
  targetFilter?: true;
  searchId?: string;
  searchIntel?: SearchIntelModel;
  filteredSmartFilters?: SelectedSmartFilter[];
}

export class IntelFilters {
  companySmartFilter?: SmartFilter[];
  haveSocialProfilesSmartFilter?: SmartFilter[];
  locationSmartFilter?: SmartFilter[];
  educationSmartFilter?: SmartFilter[];
  id?: string;
  targetFilter?: boolean;
}

export class SmartFilter {
  entityId?: string;
  name?: string;
  persons?: string[];
  source: string;
  fileId: string;
  url: string;
  active?: FilterStatus = FilterStatus.INACTIVE;
  selected?: boolean;
}

export enum FilterStatus {
  INITIAL,
  ACTIVE,
  INACTIVE,
}

class PersonName {
  type?: string;
  first?: string;
  middle?: string;
  last?: string;
  prefix?: string;
  suffix?: string;
  raw?: string;
  display?: string;
}

class PersonEmail {
  type?: string;
  disposable?: boolean;
  address?: string;
}

class PersonUsername {
  content?: string;
  source?: string;
  url?: string;
}

class PersonPhone {
  type?: string;
  countryCode?: number;
  number?: number;
  raw?: string;
  display?: string;
  displayInternational?: string;
}

class PersonGender {
  content?: string;
}

class PersonDateOfBirth {
  dateRange?: Date;
  display?: string;
}

class PersonLanguage {
  language?: string;
  region?: string;
  display?: string;
}

class PersonAddress {
  type?: string;
  country?: string;
  state?: string;
  city?: string;
  street?: string;
  house?: string;
  apartment?: string;
  zipCode?: string;
  poBox?: string;
  raw?: string;
  latidude?: string;
  longitude?: string;
  display?: string;
}

class PersonOriginCountry {
  country: string;
}

class PersonJob {
  title: string;
  organization: string;
  industry: string;
  dateRange: Date;
  display: string;
}

class PersonEducation {
  degree: string;
  school: string;
  dateRange: Date;
  display: string;
}

class PersonRelationship {
  type: string;
  names: PersonName[];
}

class PersonUserId {
  content?: string;
  source?: string;
  url?: string;
}
class PersonImage {
  fileId: string;
}

class PersonUrl {
  sourceId: string;
  name: string;
  url: string;
}

export class TargetDetails {
  entityId?: string;
  name?: string;
}

export class IntelResultsPerson {
  names: PersonName[];
  emails: PersonEmail[];
  usernames: PersonUsername[];
  phones: PersonPhone[];
  gender: PersonGender[];
  dob: PersonDateOfBirth[];
  languages: PersonLanguage[];
  originCountries: PersonOriginCountry[];
  addresses: PersonAddress[];
  jobs: PersonJob[];
  educations: PersonEducation[];
  relationships: PersonRelationship[];
  userIds: PersonUserId[];
  images: PersonImage[];
  urls: PersonUrl[];
  color?: string;
  mergedPersonKeys?: string[];
  sources?: DataSource[];
  targets?: TargetDetails[];
  score?: number;
  selected?: boolean = false;
}

export class SelectedSmartFilter {
  id: string;
  value: string;
  persons?: string[];
  active?: FilterStatus;
  selected?: boolean;
  constructor(partial: Partial<SelectedSmartFilter> = {}) {
    Object.assign(this, partial);
  }
}

export class ResultsSmartFilter {
  filters?: SelectedSmartFilter[];
  id?: string;
}

export enum PlatformNames {
  FB = 'Facebook',
  IG = 'Instagram',
  TW = 'Twitter',
  TG = 'Telegram',
  WA = 'Whatsapp',
  SK = 'Skype',
  TC = 'Truecaller',
  VB = 'Viber',
  WC = 'Wechat',
  TIN = 'Tinder',
  TT = 'Tiktok',
  SS = 'Social Search',
}

export enum DefaultTabs {
  ALL = 'ALL',
}

export enum InvestigationNavigatedFrom {
  TARGETS = 'Targets',
  CASES = 'Cases',
  CALL_LOGS = 'Call Logs',
  DISCOVERY = 'Discovery',
  INVESTIGATION = 'Investigations',
  TARGET_OVERVIEW = 'Overview',
  TARGET_CALL_LOGS = 'Call logs',
  CRYPTO = 'Crypto',
}
