import { Point } from 'src/app/modules/mapV2/models/map.models';

export class Note {
  public id: string;
  public articleContent: string;
  public articleTitle: string;
  public createdAt: Date;
  public deletedAt: Date;
  public createdBy: string;
  public description: string;
  public entityId: string;
  public entityType: string;
  public favoriteCount: string;
  public featureRequestId: string;
  public image: string;
  public publishedAt: Date;
  public retweetCount: string;
  public title: string;
  public updatedAt: Date;
  public updatedBy: Date;
  public url: string;
  public location: Point;
  //TODO find the type
  public media: unknown[];
  public noteDate: Date;
  public place: unknown;
  public validAt: unknown;
  public video: unknown;
  public targetId: string;

  constructor(model: Partial<Note>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}
