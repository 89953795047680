import {
  DataSource,
  EntityType,
  Media,
} from 'datalayer/models/platform-models';
import { Company } from 'datalayer/models/social/company';
import { BaseDTO } from 'datalayer/services/base';

export class CompanyDTO extends BaseDTO<Company, CompanyDTO> {
  private id: any;
  private type: EntityType;
  private source: DataSource;
  private created_at: Date;
  private updated_at?: Date;
  private source_entity?: {
    id: string;
    created_at: Date;
    parent_id: string;
  };
  private target_id?: string;
  private name: string;
  private industry?: string;
  private job_title?: string;
  private start_date?: string;
  private end_date?: string;
  private location?: string;
  private company_url?: string;
  private company_id?: string;
  private logo?: string;
  private image?: Partial<Media>;

  constructor(model?: Partial<CompanyDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Company): CompanyDTO {
    this.id = model.id;
    this.type = model.type;
    this.source = model.source;
    this.created_at = model.createdAt;
    this.updated_at = model.updatedAt;
    this.source_entity = {
      id: model.sourceEntity.id,
      created_at: model.sourceEntity.createdAt,
      parent_id: model.sourceEntity.parentId,
    };
    this.target_id = model.targetId;
    this.name = model.name;
    this.industry = model.industry;
    this.job_title = model.jobTitle;
    this.end_date = model.endDate;
    this.location = model.location;
    this.company_url = model.companyUrl;
    this.company_id = model.companyId;
    this.logo = model.logo;
    this.image = model.image;
    return this;
  }

  toModel(): Company {
    const company: Company = new Company({
      id: this.id,
      type: this.type,
      source: this.source,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      sourceEntity: {
        id: this.source_entity.id,
        createdAt: this.source_entity.created_at,
        parentId: this.source_entity.parent_id,
      },
      targetId: this.target_id,
      name: this.name,
      industry: this.industry,
      jobTitle: this.job_title,
      startDate: this.start_date,
      endDate: this.end_date,
      location: this.location,
      logo: this.logo,
      companyUrl: this.company_url,
      companyId: this.company_id,
      image: this.image,
    });

    return company;
  }
}
