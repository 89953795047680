export enum InputSize {
  SMALL = 'small',
  STANDARD = 'standard',
}

export type InputState = {
  type: InputStateType;
  message?: string;
};

export enum InputStateIconType {
  CIRCLE_CHECK = 'circle-check',
  CIRCLE_EXCLAMATION = 'circle-exclamation',
}

export enum InputStateType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}
