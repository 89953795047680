import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ExportPeerAction } from '@shared/components/extract-peer-dialog/extract-peer-dialog.component';
import { SiteSettings } from '@shared/models/site-settings.model';
import { Observable } from 'rxjs';
import { SiteSettingsService } from 'src/app/services/site-settings.service';

@Component({
  selector: 'app-platform-settings',
  templateUrl: './platform-settings.component.html',
  styleUrls: ['./platform-settings.component.scss'],
})
export class PlatformSettingsComponent {
  @Input()
  public readonly isLocatingDisabled: boolean;

  @Input()
  public readonly accurateLocation: boolean;

  @Input()
  public readonly siteSettings: SiteSettings;

  @Input()
  public readonly availableExtractPeerActions: Record<string, ExportPeerAction>;

  @Input()
  public language: string;

  @Output()
  public readonly onChangeAccurateLocation: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public readonly onChangeExtractPeerAction: EventEmitter<{
    value: boolean;
    action: ExportPeerAction;
  }> = new EventEmitter<{ value: boolean; action: ExportPeerAction }>();

  @Output()
  public readonly onLanguageChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

  public readonly siteSettingsStore: Observable<SiteSettings> =
    this.siteSettingsService.getStoreSiteSettings();

  constructor(private readonly siteSettingsService: SiteSettingsService) {}
}
