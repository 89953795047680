import { Component, Input } from '@angular/core';

@Component({
  selector: 'intellectus-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss'],
})
export class TextButtonComponent {
  @Input() type:
    | 'primary'
    | 'secondary'
    | 'primary-alt'
    | 'secondary-alt'
    | 'secondary-danger' = 'primary';
  @Input() size: 'large' | 'default' | 'small' | 'xsmall' = 'default';
  @Input() isLoading = false;
  @Input() isDisabled = false;
  @Input() iconStyles: string | null = null;
  @Input() credits: number | null = null;
}
