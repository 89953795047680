import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';

@Directive({ selector: '[trgTemplateOutlet]' })
export class TrgTemplateOutlet implements OnChanges {
  private _viewRef: ViewRef = null;
  @Input() public trgTemplateOutletContext: Object | null = null;
  @Input() public trgTemplateOutlet: EmbeddedViewRef<unknown> | null = null;

  constructor(private _viewContainerRef: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['trgTemplateOutlet']) {
      const viewContainerRef = this._viewContainerRef;

      if (this._viewRef) {
        viewContainerRef.remove(viewContainerRef.indexOf(this._viewRef));
      }
      this._viewRef = this.trgTemplateOutlet
        ? viewContainerRef.insert(this.trgTemplateOutlet)
        : null;
    }
  }
}
