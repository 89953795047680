<svg
  role="img"
  width="1440"
  height="600"
  aria-labelledby="loading-aria"
  viewBox="0 0 1440 600"
  preserveAspectRatio="none"
>
  <title id="loading-aria">Loading...</title>
  <rect
    x="0"
    y="0"
    width="100%"
    height="100%"
    clip-path="url(#clip-path)"
    style="fill: url('#fill')"
  ></rect>
  <defs>
    <clipPath id="clip-path">
      <rect x="27" y="39" rx="4" ry="4" width="20" height="20" />
      <rect x="67" y="40" rx="10" ry="10" width="85" height="19" />
      <rect x="188" y="41" rx="10" ry="10" width="169" height="19" />
      <rect x="402" y="40" rx="10" ry="10" width="85" height="19" />
      <rect x="523" y="41" rx="10" ry="10" width="169" height="19" />
      <rect x="731" y="39" rx="10" ry="10" width="85" height="19" />
      <rect x="852" y="38" rx="10" ry="10" width="85" height="19" />

      <rect x="26" y="96" rx="4" ry="4" width="20" height="20" />

      <rect x="66" y="97" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="98" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="97" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="98" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="96" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="95" rx="10" ry="10" width="85" height="19" />

      <rect x="26" y="158" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="159" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="160" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="159" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="160" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="158" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="157" rx="10" ry="10" width="85" height="19" />

      <rect x="26" y="216" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="217" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="218" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="217" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="218" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="216" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="215" rx="10" ry="10" width="85" height="19" />

      <rect x="26" y="279" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="280" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="281" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="280" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="281" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="279" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="278" rx="10" ry="10" width="85" height="19" />

      <rect x="978" y="38" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="95" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="157" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="215" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="278" rx="10" ry="10" width="169" height="19" />
      <rect x="1183" y="39" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="96" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="158" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="216" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="279" rx="10" ry="10" width="85" height="19" />
      <rect x="1305" y="37" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="94" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="156" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="214" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="277" rx="10" ry="10" width="85" height="19" />
    </clipPath>
    <linearGradient id="fill">
      <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
        <animate
          attributeName="offset"
          values="-2; -2; 1"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
      <stop offset="1.59996" stop-color="#dedede" stop-opacity="1">
        <animate
          attributeName="offset"
          values="-1; -1; 2"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
      <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
        <animate
          attributeName="offset"
          values="0; 0; 3"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
    </linearGradient>
  </defs>
</svg>
