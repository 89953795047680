import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AppConfigService } from 'src/app/providers/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class InvestigationModuleGuard implements CanActivate {
  constructor(
    private appConfigService: AppConfigService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (this.appConfigService.getConfigVariable('enableInvestigationModule')) {
      return true;
    }
    this.router.navigate(['/discovery']);
    return false;
  }
}
