import { Component, OnInit } from '@angular/core';
import {
  NavigationEnd,
  NavigationExtras,
  Router,
  RouterEvent,
} from '@angular/router';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { JobType } from 'datalayer/models/background-jobs/background-job-type';
import { OsintSearchNotificationResponse } from 'datalayer/models/background-jobs/osint-notifications';
import { head, uniqBy } from 'lodash-es';
import { ActiveToast } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { InvestigationHelperService } from 'src/app/modules/visual-investigation/services/investigation-helper.service';
import { TargetService } from 'src/app/services/target/target.service';
import { OsintNotificationsStore } from 'src/app/shared/components/osint-group-notifications/osint-notifications.store';
import { ModalDataService } from 'src/app/modules/search-intel/services/modal-data.service';

@Component({
  selector: 'app-group-toastr',
  templateUrl: './osint-group-notifications.component.html',
  styleUrls: ['./osint-group-notifications.component.scss'],
})
export class OsintGroupNotificationsComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    private osintNotificationsStore: OsintNotificationsStore,
    private router: Router,
    private targetService: TargetService,
    private investigationService: InvestigationHelperService,
    private readonly modalDataService: ModalDataService
  ) {
    super();
  }

  public toast: ActiveToast<any>;
  public id: string;
  public targetAlias$: Observable<string>;
  public toasts$: Observable<OsintSearchNotificationResponse[]>;
  public isHidden = false;
  public inProgress$: Observable<boolean>;
  public showTargetLink: boolean;
  public osintType$: Observable<string>;
  private osintType = '';
  private displayOsintTypes: { [key: string]: string } = {
    [JobType.DEEP_OSINT]: 'Deep Webint',
    [JobType.INTEL_SEARCH]: 'Intel OSINT',
    [JobType.ADVANCED_OSINT]: 'Enhance Profile',
    [JobType.BASIC_OSINT]: 'Enhance Profile',
    [JobType.SOMEDUS]: 'SOMEDUS',
  };
  redirectionText$: Observable<string>;

  ngOnInit() {
    this.showTargetLink =
      this.notOnTargetPage(window.location.pathname) &&
      !this.osintFromLinkAnalysis();

    this.toasts$ = this.osintNotificationsStore.notifications$.pipe(
      map((notifications) => {
        return uniqBy(
          notifications[this.id],
          'platform'
        ) as OsintSearchNotificationResponse[];
      })
    );
    this.osintType$ = this.toasts$.pipe(
      startWith('Webint'),
      map((notificationResponses: OsintSearchNotificationResponse[]) => {
        const osintType = head(
          uniqBy(notificationResponses, 'osint_type').map(
            (id) => this.displayOsintTypes[id.osint_type]
          )
        );
        this.osintType = osintType;
        return osintType || 'Webint';
      })
    );
    this.inProgress$ = this.toasts$.pipe(
      map((notificationResponses: OsintSearchNotificationResponse[]) => {
        return notificationResponses.every(
          (n) => n.status === JobStatus.PENDING
        );
      })
    );
    this.redirectionText$ = this.toasts$.pipe(
      map(() => {
        return this.getText();
      })
    );
    if (!this.osintFromLinkAnalysis()) {
      this.targetAlias$ = this.targetService
        .getTarget(this.id)
        .pipe(map((target) => target.alias));
    }

    this.subscriptions.push(this.routerSub());
  }

  public removeGroup() {
    this.osintNotificationsStore.cleanNotificationsById(this.id);
    this.toast.toastRef.close();
  }

  /**
   *
   * @param url string
   */
  private notOnTargetPage(url: string) {
    return !url.includes(this.id);
  }

  private routerSub() {
    return this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ url }: RouterEvent) => {
        this.showTargetLink =
          this.notOnTargetPage(url) && !this.osintFromLinkAnalysis();
      });
  }

  goToTargetPage() {
    if (this.osintType === this.displayOsintTypes[JobType.DEEP_OSINT]) {
      this.navigateToTarget(['targets', this.id, 'link-analysis']);
    } else if (this.osintFromLinkAnalysis()) {
      const index = this.id.indexOf('___');
      const targetId = this.id.substring(0, index);
      const nodeId = this.id.substring(index + 3, this.id.length);
      this.navigateToTarget(['targets', targetId, 'link-analysis'], {
        queryParams: { nodeId },
      });
    } else if (this.router.url.includes('investigation')) {
      this.investigationService.completedOsintId.next(this.id);
    } else {
      this.navigateToTarget(['targets', this.id, 'overview']);
    }

    this.removeGroup();
  }

  private osintFromLinkAnalysis(): boolean {
    // includes '@' --> the osint in link analysis is made on social profiles (@platform) and not targets
    return this.id.includes('@');
  }

  private getText(): string {
    if (this.osintType === this.displayOsintTypes[JobType.DEEP_OSINT]) {
      return 'View in link analysis';
    } else {
      return this.osintFromLinkAnalysis()
        ? 'Update link analysis'
        : this.osintFromInvestigation()
        ? 'Update the investigation'
        : 'View';
    }
  }

  private osintFromInvestigation(): boolean {
    return (
      this.investigationService.pendingActionIds.getValue()[this.id]?.length > 0
    );
  }

  private navigateToTarget(commands: any[], extras?: NavigationExtras): void {
    this.router.navigate(commands, extras).then((): void => {
      this.modalDataService.onNavigation();
    });
  }
}
