export interface FilterParams {
  sort_by: SortBy;
  page: number;
  telno?: string;
  imsi?: string;
}
export enum SortBy {
  ASC = 'ASC',
  DESC = 'DESC',
}
