import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { LayoutService } from '../services/layout.service';
@UntilDestroy()
@Directive({
  selector: '[intellectusIsMobile]',
})
export class IsMobileDirective {
  constructor(
    private el: ElementRef,
    private layoutService: LayoutService,
    private renderer: Renderer2
  ) {
    this.layoutService.isMobile$
      .pipe(
        tap((isMobile) => {
          if (isMobile) {
            this.renderer.addClass(this.el.nativeElement, 'is-mobile');
            return;
          }
          this.renderer.removeClass(this.el.nativeElement, 'is-mobile');
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
