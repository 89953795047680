export class Image {
  public id: string;
  public platform: string;
  public content: string;
  public url: string;
  public title: string;
  public description: string;
  public image: string;
  public feedbackStats: unknown;
  public validAt: Date;
  public publishedAt: Date;
  public albumId: string;
  public sourceEntity: {
    id: string;
    createdAt: Date;
    parentId: string;
  };

  constructor(model: Partial<Image>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}
