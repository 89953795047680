import { CdrType } from '@trg-commons/data-models-ts';

export enum ClTimelineEntityType {
  TARGET = 'target',
  TOP_ASSOCIATE = 'topAssociate',
  OTHER = 'other',
  TELEGRAMLOCATION = 'telegramLocation',
  GEOQUERYLOCATION = 'geoQueryLocation',
  ADDINT = 'addInt',
  SOCIAL_POSTS_NEARBY = 'socialPostsNearby',
}

export const eventTypeOptions: { label: string; type: CdrType | 'all' }[] = [
  {
    label: 'Calls',
    type: CdrType.Voice,
  },
  {
    label: 'SMS',
    type: CdrType.Sms,
  },
  {
    label: 'Data',
    type: CdrType.Data,
  },
  {
    label: 'All',
    type: 'all',
  },
];

export const smartFilters: {
  label: string;
  filterType: 'entity' | 'event';
  type: string;
}[] = [
  {
    label: 'Top associates',
    filterType: 'entity',
    type: ClTimelineEntityType.TOP_ASSOCIATE,
  },
  {
    label: 'Pinned associates',
    filterType: 'entity',
    type: 'pinned',
  },
  {
    label: 'None',
    filterType: 'entity',
    type: 'all',
  },
];

export enum contextMenuActions {
  MARK_EVENT = 'mark_event',
  PIN_ENTITY = 'pin_entity',
  FOCUS_ENTITY = 'focus_entity',
  REMOVE_ENTITY = 'remove_entity',
  ZOOM_TO_FIT = 'zoom_to_fit',
  RESET_TIMELINE = 'reset_timeline',
  FOCUS_EVENT = 'focus_event',
  CREATE_CUSTOM_MARKER = 'create_custom_marker',
  UNMARK_EVENT = 'unmark_event',
}

// TODO: Implement commented out actions
export const contextMenuOptions: Record<
  string,
  { label: string; action: contextMenuActions }[]
> = {
  event: [
    {
      label: 'Mark event as important',
      action: contextMenuActions.MARK_EVENT,
    },
    // {
    //   label: 'Focus event', //TODO: handle also unfocus event
    //   action: contextMenuActions.FOCUS_EVENT
    // }
  ],
  entity: [
    {
      label: 'Pin Entity',
      action: contextMenuActions.PIN_ENTITY,
    },
    {
      label: 'Focus Entity',
      action: contextMenuActions.FOCUS_ENTITY,
    },
    {
      label: 'Remove Entity',
      action: contextMenuActions.REMOVE_ENTITY,
    },
  ],
  background: [
    {
      label: 'Zoom to fit',
      action: contextMenuActions.ZOOM_TO_FIT,
    },
    {
      label: 'Reset timeline',
      action: contextMenuActions.RESET_TIMELINE,
    },
    // {
    //   label: 'Create custom event',
    //   action: contextMenuActions.CREATE_CUSTOM_MARKER
    // }
  ],
  marker: [
    {
      label: 'Unmark event',
      action: contextMenuActions.UNMARK_EVENT,
    },
  ],
};
