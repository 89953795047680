import { CdrType, GeoLocation } from '@trg-commons/data-models-ts';
import { isEmpty } from 'src/app/shared/util/isEmpty';
import { CdrTarget } from 'src/app/modules/call-logs/components/cl-main-map-view/cl-table/models/cdr-target.model';
import { ClCdrTypes } from 'src/app/shared/modules/call-logs-shared/models/cl-cdr-types.value';
import { RequestClParameters } from 'src/app/modules/analysis/shared/models/request-cl-parameters.model';

/**
 * If you have a questions regarding to below logic (ownedCdr, isPeer, belongsToTarget ,hasImei)
 * ask Kyriakos Fragkeskos -_-
 */
export const hasImei = (cdr: CdrTarget): boolean => {
  return !!cdr.imei?.trim() && cdr.imei?.trim() !== '0';
};

export const ownedCdr = (
  cdr: CdrTarget,
  availableValues: string[]
): boolean => {
  return (
    availableValues?.includes(cdr.cdrTelno) ||
    availableValues?.includes(cdr.imei)
  );
};

export const isPeer = (cdr: CdrTarget, telnos: string[]): boolean => {
  return (
    !ownedCdr(cdr, telnos) &&
    [cdr.msisdn, cdr.receiverMsisdn].some((str) => str && telnos?.includes(str))
  );
};

export const getCdrType = (
  cdr: CdrTarget,
  requestClParameters: RequestClParameters
): ClCdrTypes | undefined => {
  let cdrType: ClCdrTypes | undefined;
  if (cdr['filteredType']) {
    cdrType = cdr['filteredType'] as ClCdrTypes;
    return cdrType;
  }

  const cdrValuesAreIncluded = Object.keys(requestClParameters).some((key) => {
    const properties = requestClParameters[key];
    return properties.includes(cdr.msisdn) || properties.includes(cdr.imei);
  });
  switch (cdr.cdrType) {
    case CdrType.Voice: {
      cdrType = cdrValuesAreIncluded
        ? ClCdrTypes.OutgoingCall
        : ClCdrTypes.IncomingCall;
      break;
    }
    case CdrType.Sms: {
      cdrType = cdrValuesAreIncluded
        ? ClCdrTypes.OutgoingSMS
        : ClCdrTypes.IncomingSMS;
      break;
    }
    case CdrType.Data: {
      cdrType = ClCdrTypes.Data;
      break;
    }
    case CdrType.Udr: {
      cdrType = ClCdrTypes.UDR;
      break;
    }
    default: {
      console.warn(`Unrecognised CDR Type: ${cdr.cdrType}`, cdr);
      break;
    }
  }
  return cdrType;
};

export const msisdnBelongsToTarget = (
  msisdn: string,
  telnos: string[]
): boolean => {
  return telnos.includes(msisdn);
};

export const getFormattedLocation = (
  lat: number,
  lng: number
): string | '-' => {
  if (lat === 0 && lng === 0) {
    return '-';
  }

  return `${lat.toFixed(4)},${lng.toFixed(4)}`;
};

export const getLocationForMsisdnFromCdr = (
  cdr: CdrTarget
): { geo: GeoLocation; msisdn: string } | undefined => {
  const location = getAvailableLocationOfCdr(cdr);
  if (isEmpty(location)) {
    console.warn('Cdr location is not available', cdr);
    return;
  }
  return {
    geo: location,
    msisdn: location === cdr.geoMsisdn ? cdr.msisdn : cdr.receiverMsisdn,
  };
};

const getAvailableLocationOfCdr = (cdr: CdrTarget): GeoLocation | undefined => {
  return !isEmpty(cdr.geoMsisdn)
    ? cdr.geoMsisdn
    : !isEmpty(cdr.geoReceiverMsisdn)
    ? cdr.geoReceiverMsisdn
    : undefined;
};
