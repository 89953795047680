import { AvatarActivityLogState } from './avatar-activity-log-state.enum';
import { AvatarSocialType } from './avatar-social-type.enum';

export class AvatarActivityLog {
  activity: AvatarActivityLogState;
  social: AvatarSocialType;
  startedAt: Date;
  completedAt: Date;

  constructor(model: Partial<AvatarActivityLog>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}
