import {
  BalanceType,
  LimitType,
} from 'src/app/shared/models/billing-action.model';
import {
  CreditPools,
  PoolFeatures,
} from 'src/app/shared/models/credit-pools.model';
import { CreditBarTooltip } from '../components/credit-bar-tooltip/credit-bar-tooltip.component';
import { CreditUsageBar } from '../components/credit-usage-bar/credit-usage-bar.component';
import { CreditUsageBullet } from '../components/credit-usage-bullets/credit-usage-bullets.component';

export interface CreditUsage {
  poolName: PoolFeatures | string;
  usage: number;
  from: number;
  limitType: LimitType;
}

export interface CreditDetails {
  unassignCredits: CreditPools | number;
  currentCredits: CreditPools | number;
  limitType?: LimitType;
  creditUsageLabel: string;
  balanceType: BalanceType;
  detailsType: DetailsType;
}

export enum DetailsType {
  TENANT = 'tenant',
  USER = 'user',
}

export enum CreditDetailTitles {
  TENANT = 'Tenant balance',
  USER = 'Credit usage',
}

export interface InitDetailsResult {
  bulletsInfo: CreditUsageBullet[];
  tooltipInfo: CreditBarTooltip;
  barInfo: CreditUsageBar;
}
