import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Chat } from 'datalayer/models/chat';
import { ChatType } from 'datalayer/models/platform-models';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { BaseService } from 'src/app/services/base.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { CostInfoActionDialogComponent } from 'src/app/shared/components/cost-info-action-dialog/cost-info-action-dialog.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { CostInfoActionDialogModel } from 'src/app/shared/models/cost-info-action-dialog.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { TargetEnhanceService } from '../../analysis/shared/services/target-enhance.service';
import { ProfilerService } from './profiler.service';
import { BehaviorSubject } from 'rxjs';
import { Profile } from 'datalayer/models/social/profile';

@Injectable({
  providedIn: 'root',
})
export class RelatedPeopleService extends BaseService {
  expireTargetDays = 0;
  targetCreditsChargesEnabled = false;
  private relatedPeopleChatMessagesExchangedStore$ = new BehaviorSubject<{
    [profileId: string]: {
      messagesExchanged: number;
      groupMessagesExchanged: number;
    };
  }>({});

  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private translationService: TranslationService,
    private targetService: TargetService,
    private localStorageService: LocalStorageService,
    private profilerService: ProfilerService,
    private appConfigService: AppConfigService,
    public dialog: MatDialog,
    private targetEnhanceService: TargetEnhanceService
  ) {
    super(router, snackBar);
    this.expireTargetDays =
      this.appConfigService.getConfigVariable('expireTargetDays');
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
  }

  createTargetAdvancedOsint(
    target,
    alias,
    aliasCounter?: number,
    currentTarget?: TargetItem,
    profile?: Profile
  ) {
    if (target) {
      const newTarget: TargetItem = { ...target };
      if (aliasCounter) {
        newTarget.alias = `${alias} ${aliasCounter}`;
      } else {
        newTarget.alias = alias;
      }
      newTarget.user = this.localStorageService.getCurrentUser().identity;

      this.targetService
        .createTargetProfiler(newTarget, {
          createCase: false,
          addImProfiles: true,
        })
        .subscribe(
          (target: TargetItem) => {
            this.showMessage(
              this.translationService.translate('Target created successfully!')
            );
            if (currentTarget) {
              this.profilerService.targetData.next(target);
            }
            this.targetEnhanceService.advancedOsintForTarget(false, target);
            if (profile) {
              this.targetService
                .saveHiddenRelationsToTarget(profile, currentTarget.id)
                .subscribe();
            }
          },
          (error: any) => {
            if (error.messages === 'Alias exists. Please use another alias') {
              if (aliasCounter) {
                aliasCounter += 1;
              } else {
                aliasCounter = 1;
              }
              this.createTargetAdvancedOsint(
                target,
                alias,
                aliasCounter,
                currentTarget,
                profile
              );
            } else {
              this.showMessage(
                this.translationService.translate(
                  error.messages
                    ? error.messages
                    : 'Target has not been created'
                )
              );
            }
          }
        );
    }
  }

  openAdvancvedOSINTDialog(
    target,
    currentTarget: TargetItem,
    profile: Profile
  ) {
    let alias = 'Unknown';
    if (target.names.length > 0) {
      alias = target.names[0];
    }

    const targetCreditsMessage = this.translationService.interpolate(
      'Management for a new target is free of charge for #{days} days',
      { days: this.expireTargetDays.toString() }
    );
    const title = 'Enhance Profile';

    const message = `${this.translationService.translate(
      'Find possible associates, common visited places, connections on social media, groups and more. Credits will be deducted only if accurate information is found.'
    )}`;

    const intelDialog = this.dialog.open(CostInfoActionDialogComponent, {
      data: <CostInfoActionDialogModel>{
        title: title,
        message: `${message} `,
        action: BillingActions.ADVANCED_OSINT,
        actionBtnLabel: 'Enhance Profile',
        footerText: this.targetCreditsChargesEnabled
          ? targetCreditsMessage
          : '',
      },
      panelClass: 'renew-credit',
    });

    intelDialog.afterClosed().subscribe((action) => {
      if (action.key === 'success') {
        this.createTargetAdvancedOsint(
          target,
          alias,
          undefined,
          currentTarget,
          profile
        );
      }
    });
  }

  generateTelegramChatData(profile: Profile): {
    messagesExchanged: number;
    groupMessagesExchanged: number;
  } {
    const messagesExchanged = profile.chatsInfo?.personal_messages_count || 0;
    const groupMessagesExchanged = profile.chatsInfo?.group_messages_count || 0;

    return { messagesExchanged, groupMessagesExchanged };
  }

  getGroups(profile: Profile, chats: Chat[]): Chat[] {
    const groupChats = [];
    chats.forEach((chat) => {
      if (
        chat.chatType === ChatType.PUBLIC_SUPERGROUP ||
        chat.chatType === ChatType.PRIVATE_GROUP
      ) {
        if (chat.members && chat.members.includes(profile.name)) {
          groupChats.push(chat);
        }
      }
    });

    return groupChats;
  }

  public setRelatedPeopleChatMessagesExchanged(messagesExchangedObj: {
    [profileId: string]: {
      messagesExchanged: number;
      groupMessagesExchanged: number;
    };
  }): void {
    this.relatedPeopleChatMessagesExchangedStore$.next(messagesExchangedObj);
  }

  public getRelatedPeopleChatMessagesExchanged(profileId: string): {
    messagesExchanged: number;
    groupMessagesExchanged: number;
  } {
    const storeValue = this.relatedPeopleChatMessagesExchangedStore$.getValue();
    if (profileId in storeValue) {
      return storeValue[profileId];
    }
    return { messagesExchanged: 0, groupMessagesExchanged: 0 };
  }
}
