import { Observable } from 'rxjs';
import { Person, Profile } from 'datalayer/models';
import { RequestOptions } from 'datalayer/services/base';
import { DataSource, EntityType } from 'datalayer/models/platform-models';
import { map } from 'rxjs/operators';
import { personToProfile } from 'src/app/modules/profiler/helpers/person-to-profile';
import { PersonService } from 'datalayer/services/person/person/person.service';

export function getGoogleProfiles(
  personService: PersonService,
  targetId: string
): Observable<Profile[]> {
  const googleReqOptions: RequestOptions = {
    filters: {
      source: [DataSource.DBSearch],
      targetId,
      type: EntityType.Person,
    },
  };

  return personService.getAll(googleReqOptions).pipe(
    map((result: Record<string, Person>): Profile[] => {
      return Object.values(result)
        .filter((item: Person): boolean => !!item.checkIn)
        .map((person: Person): Profile => personToProfile(person, targetId));
    })
  );
}
