import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ProxyWsService } from 'src/app/modules/ad-ids/shared/proxy-ws.service';
import { CQRSBaseEvent, EventChannel } from '@trg-commons/gio-data-models-ts';
import { filter, map } from 'rxjs/operators';
import { Message } from './websocket.class';

@Injectable({
  providedIn: 'root',
})
export class ServerPyWsService {
  constructor(private proxyService: ProxyWsService) {
    this.initListeners();
  }

  private listeners: { [key: string]: Subject<Message> } = {};

  private addListener<S extends Message>(
    listenerId: string = uuidv4()
  ): Observable<S> {
    this.listeners[listenerId] = new Subject<S>();
    return this.listeners[listenerId].asObservable() as Observable<S>;
  }

  public call<Q extends Message, S extends Message>(message: Q): Observable<S> {
    message.body.correlationId = uuidv4();
    this.proxyService.sendMessage(message);
    return this.addListener<S>(message.body.correlationId);
  }

  public getMessage<M extends Message>(): Observable<M> {
    return this.proxyService.getMessage().pipe(
      filter(({ channel }: CQRSBaseEvent<M>) => this.validateChannel(channel)),
      map(({ body: message }: CQRSBaseEvent<M>) => message)
    );
  }

  private initListeners(): void {
    this.getMessage()
      .pipe(
        filter(
          (message: Message) => !!this.listeners[message.body?.correlationId]
        )
      )
      .subscribe((message: Message) =>
        this.listeners[message.body.correlationId].next(message)
      );
  }

  private validateChannel(channel: EventChannel): boolean {
    return [
      EventChannel.TextAnalysis,
      EventChannel.DeepOSINT,
      EventChannel.CommonRelation,
      EventChannel.Somedus,
      EventChannel.Nearby,
      EventChannel.Avatar,
      EventChannel.Radical_Monitoring,
      EventChannel.Recommendations,
      EventChannel.Import,
      EventChannel.OSINT_Search,
      EventChannel.Avatar,
      EventChannel.Notify,
      EventChannel.Trilateration,
      EventChannel.Ledger,
    ].includes(channel);
  }
}
