import { DataSource } from 'datalayer/models/platform-models';
import { IconMarker } from 'src/app/modules/mapV2/models/map.models';

export class ProfilerIconMarker extends IconMarker {
  constructor(model: Partial<ProfilerIconMarker>) {
    super(model);
    Object.assign(this, model);
  }

  source: DataSource;
}
