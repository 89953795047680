import { Associate } from '@trg-commons/data-models-ts';
import { AvatarWithPlatform } from 'src/app/shared/components/multiple-avatars/multiple-avatars.component';

export class ClAssociate extends Associate {
  names: string[] = [];
  photos: string[] = [];
  hasAssociatedTarget?: boolean;
  associatedTargetAlias?: string;
  associatedTargetId?: string;
  image?: string[];
  thumbnails?: ClAvatarWithPlatform[];

  constructor(props: Partial<ClAssociate>) {
    super();
    Object.assign(this, props);
  }
}

interface ClAvatarWithPlatform extends AvatarWithPlatform {
  sourceUrl: string;
}
