<div class="reset-password-admin assistant-font">
  <div class="dialog-header flex-wrp">
    <p class="title">{{ 'Reset Password' | translate }}</p>
    <mat-icon (click)="dialogRef.close()">close</mat-icon>
  </div>

  <div class="dialog-body">
    <p>
      {{ 'Are you sure you want to reset the user password for' | translate }}
      {{ user.username }} ?
    </p>
  </div>

  <div class="btn-wrp">
    <button class="cp-btn-ghost" mat-button (click)="dialogRef.close()">
      {{ 'Cancel' | translate }}
    </button>
    <button class="cp-btn-primary" mat-button (click)="onResetPassword()">
      {{ 'Reset' | translate }}
    </button>
  </div>
</div>
