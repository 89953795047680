<div class="wrapper" *ngIf="bars && bars.length">
  <div
    class="bar {{ bar.cssClass }}"
    *ngFor="let bar of bars"
    [ngStyle]="{
      width: 100 / bars.length + '%',
      height: ((percentage * bar.value) / maxValue) * 100 + '%',
      border: '1px solid transparent',
      'background-clip': 'padding-box',
      'box-sizing': 'border-box'
    }"
  >
    <span class="tooltiptext">{{ bar.value }} {{ 'Items' | translate }}</span>
  </div>
</div>
