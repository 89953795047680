import { Pagination } from '../../modules/search-intel/models/search-intel.model';
import { Platform } from '../../shared/schemas/common/platforms';

export enum AlertStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
}

export enum AlertType {
  TARGET_INVESTIGATION_NEW_POST = 'target_investigation_new_post',
  TARGET_INVESTIGATION_KEYWORD_SEARCH = 'target_investigation_keyword_search',
}

export enum AlertLabel {
  target_investigation_new_post = 'new post',
  target_investigation_keyword_search = 'keyword',
}

export interface AlertSocialProfile {
  platform: Platform;
  name: string;
  profileId: string;
  lastPostCheckedAt?: Date;
}

export interface Alert {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  targetId: string;
  socialProfiles: AlertSocialProfile[];
  periodInDays: number;
  expirationDate: Date;
  status: AlertStatus;
  keywords?: string[];
  postsTriggered?: string[];
  type: AlertType;
}

export interface CreateAlertDTO {
  targetId: string;
  periodInDays: number;
  type: AlertType;
  socialProfiles: AlertSocialProfile[];
  keywords?: string[];
}

export interface AlertsResponse {
  pagination: Pagination;
  alerts: Alert[];
}
