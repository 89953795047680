import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppConfigService } from '../../providers/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class CdrGuard implements CanActivate {
  constructor(
    private appConfigService: AppConfigService,
    private router: Router
  ) {}

  canActivate() {
    const isCDROnlyEnabled =
      this.appConfigService.getConfigVariable('isCDROnlyEnabled');

    if (isCDROnlyEnabled) {
      this.router.navigate(['/core/call-logs']);
    }

    return !isCDROnlyEnabled;
  }
}
