<nav
  *ngIf="navbarConstants"
  id="app-navbar"
  class="navbar"
  [ngClass]="themeClasses[theme]"
  [class.is-mobile]="isMobile"
  [class.menu-opened]="mobileMenuState"
  [class.notifications-opened]="notificationsMenuState"
  #targetHeight
>
  <app-mobile-navbar
    *ngIf="isMobile"
    [title]="title"
    [highlightClass]="highlightClass"
    [showKnowledgeBase]="navbarConstants.enableKnowledgeBase"
    (onMenuClick)="setMobileMenuState(true)"
    (onSearchIconClick)="onSearchIconClick.emit()"
    (onNotificationListClicked)="openNotificationList(true)"
    (onShowKnowledgeBaseClicked)="openKnowledgeBaseTab()"
  ></app-mobile-navbar>

  <div class="containerFixed">
    <app-platform-settings-modal
      *ngIf="mobilePlatformSettingsOpened"
      [isLocatingDisabled]="navbarConstants.isLocatingDisabled"
      [accurateLocation]="accurateLocation"
      [siteSettings]="siteSettings"
      [language]="translationService.language"
      [availableExtractPeerActions]="availableExtractPeerActions"
      (onChangeAccurateLocation)="changeAccurateLocation($event)"
      (onChangeExtractPeerAction)="
        changeExtractPeerAction($event.value, $event.action)
      "
      (onLanguageChange)="changeLanguage($event)"
      (onClose)="onPlatformSettingsClose()"
    >
    </app-platform-settings-modal>

    <app-mobile-user-settings
      *ngIf="mobileUserSettingsOpened"
      (onClose)="onUserSettingsClose()"
    ></app-mobile-user-settings>

    <div
      *ngIf="!notificationsMenuState"
      class="wrapper"
      [class.scrollable]="isScrollable"
    >
      <mat-toolbar [class.fit-content-height]="isScrollable">
        <!-- LOGO -->
        <div
          #logoContainer
          class="clickable logo-container"
          *ngIf="navbarBehaviour || isMobile"
          (click)="redirectTo()"
        >
          <img
            *ngIf="!isGeolocTheme && !isWhiteTheme"
            class="navbar-logo"
            [src]="getLogo()"
          />
          <img
            *ngIf="isGeolocTheme"
            src="assets/static/images/geoloc_logo.svg"
            height="30"
          />
          <span
            *ngIf="!isGeolocTheme && !isWhiteTheme"
            class="logo-text"
            [innerHTML]="getLogoText()"
          ></span>
          <img
            *ngIf="isWhiteTheme"
            src="assets/static/images/world-white-logo.svg"
            height="30"
          />

          <intellectus-icon-text-button
            *ngIf="isMobile"
            [buttonIcon]="closeIcon"
            (buttonClick)="setMobileMenuState(false)"
          ></intellectus-icon-text-button>
        </div>

        <div
          #usernameContainer
          class="mobile-username-container"
          *ngIf="usernameDisplayValue && isMobile"
        >
          @{{ usernameDisplayValue }}
        </div>

        <!-- NEW QUERY -->
        <div
          class="search-intel-query"
          *ngIf="
            navbarBehaviour && !navbarConstants.isCDROnlyEnabled && !isMobile
          "
          [class.full-navbar]="fullNavbar"
        >
          <app-search-intel-navbar
            (emitIntelNavbarClick)="fullNavbar = $event"
            [navBarIdentifier]="navBarIdentifier"
          >
          </app-search-intel-navbar>
        </div>
        <span class="nav-spacer"></span>

        <div
          #routeWrpContainer
          *ngIf="
            !navbarConstants.adintFlavourEnabled &&
            !navbarConstants.isCDROnlyEnabled
          "
          class="route-wrp flex-wrp"
          [class.move-navigations]="navbarBehaviour && !isMobile"
        >
          <app-navbar-menu
            [isMobile]="isMobile"
            (onMenuItemClick)="setMobileMenuState(false)"
          ></app-navbar-menu>
        </div>

        <div
          #mobileNavContainer
          class="mobile-nav-wrapper"
          [style.margin-top]="mobileNavMarginTop + 'px'"
        >
          <div class="delimiter"></div>

          <div class="mobile-nav-actions">
            <!-- <div
              class="mobile-nav-item"
              *ngIf="enableKnowledgeBase"
              (click)="openKnowledgeBaseTab()"
            >
              <div class="text">{{ 'Knowledge base' | translate }}</div>
              <div class="icon">
                <fe-platform-fa-pro-icon
                  [name]="'chevron-right'"
                  [family]="'regular'"
                ></fe-platform-fa-pro-icon>
              </div>
            </div> -->

            <div class="mobile-nav-item" (click)="openPlatformSettings()">
              <div class="text">{{ 'Settings' | translate }}</div>
              <div class="icon">
                <fe-platform-fa-pro-icon
                  [name]="'chevron-right'"
                  [family]="'regular'"
                ></fe-platform-fa-pro-icon>
              </div>
            </div>

            <div
              *ngIf="navbarConstants.enableUserSettingsV2"
              class="mobile-nav-item"
              (click)="openUserSettings()"
            >
              <div class="text">{{ 'Account' | translate }}</div>
              <div class="icon">
                <fe-platform-fa-pro-icon
                  [name]="'chevron-right'"
                  [family]="'regular'"
                ></fe-platform-fa-pro-icon>
              </div>
            </div>
          </div>

          <div class="delimiter"></div>

          <div class="sign-out-button">
            <intellectus-icon-text-button
              [buttonIcon]="signOutIcon"
              [buttonText]="'Sign out' | translate"
              (buttonClick)="logout()"
            ></intellectus-icon-text-button>
          </div>
        </div>

        <div class="delimiter first"></div>

        <div #navActions class="nav-actions flex-wrp">
          <div class="credit-guage nav-items flex-wrp">
            <app-credits-container
              [hasConcurrentLimits]="navbarConstants.hasConcurrentLimits"
              [isMobile]="isMobile"
            >
            </app-credits-container>
          </div>
          <div
            *ngIf="navbarConstants.enableKnowledgeBase"
            class="nav-items flex-wrp"
            angulartics2On="click"
            [angularticsAction]="matomo.actions.knowledgeBase"
            [angularticsCategory]="matomo.categories.navBar"
            (click)="openKnowledgeBaseTab()"
            [matTooltip]="knowledgeBaseTooltip | translate"
          >
            <span>?</span>
          </div>
          <div
            class="nav-items flex-wrp platform-setting"
            [matMenuTriggerFor]="platformMenu"
            (click)="enableCurrentAccurateLocationSetting()"
          >
            <mat-icon class="icon">settings</mat-icon>
          </div>
          <div class="notifications nav-items flex-wrp">
            <app-notifications-list
              [isMobile]="isMobile"
            ></app-notifications-list>
          </div>
          <div class="account-user nav-items flex-wrp">
            <button
              *ngIf="user"
              mat-icon-button
              class="user-icon account-btn"
              [matMenuTriggerFor]="userMenu"
            >
              <img src="./assets/static/images/user.svg" alt="account-user" />
            </button>
          </div>

          <mat-menu #userMenu="matMenu" class="user-menu assistant-font">
            <p class="flex-wrp user-name">
              <img
                src="./assets/static/images/user.svg"
                alt="account-user"
                width="30"
              />
              <span
                [matTooltip]="usernameDisplayValue"
                class="capitalize-none"
                *ngIf="usernameDisplayValue"
                >{{ usernameDisplayValue }}</span
              >
            </p>
            <button
              mat-menu-item
              *ngIf="isAdmin || isSupport || isPower"
              routerLink="admin"
            >
              {{ 'Manager' | translate }}
            </button>
            <button
              mat-menu-item
              *ngIf="theme !== 'UNLIMITED'"
              routerLink="user-options"
            >
              {{ 'User options' | translate }}
            </button>
            <button
              mat-menu-item
              *ngIf="navbarConstants.enableUserSettingsV2"
              routerLink="user-settings"
            >
              {{ 'Account' | translate }}
            </button>
            <button
              *ngIf="!navbarConstants.isLocatingDisabled && oldDiscoveryEnabled"
              mat-menu-item
              (click)="openTasksDialog('false')"
            >
              {{ 'Tasks' | translate }}
            </button>
            <button
              *ngIf="!navbarConstants.isLocatingDisabled"
              mat-menu-item
              routerLink="statistics"
            >
              {{ 'Statistics' | translate }}
            </button>
            <button
              *ngIf="enableSupportRequest"
              mat-menu-item
              (click)="openSupportForm()"
            >
              {{ 'Support request' | translate }}
            </button>
            <button
              mat-menu-item
              *ngIf="
                !navbarConstants.isLocatingDisabled &&
                navbarConstants.enableUserManual
              "
              (click)="userManual()"
            >
              {{ 'User manual' | translate }}
            </button>
            <button
              mat-menu-item
              *ngIf="navbarConstants.enableLicense"
              (click)="openLicenseTab()"
            >
              {{ 'License' | translate }}
            </button>
            <button mat-menu-item (click)="logout()">
              {{ 'Sign out' | translate }}
            </button>
          </mat-menu>

          <mat-menu
            #platformMenu="matMenu"
            class="platform-menu user-menu assistant-font"
          >
            <app-platform-settings
              [isLocatingDisabled]="navbarConstants.isLocatingDisabled"
              [accurateLocation]="accurateLocation"
              [siteSettings]="siteSettings"
              [availableExtractPeerActions]="availableExtractPeerActions"
              [language]="translationService.language"
              (onChangeAccurateLocation)="changeAccurateLocation($event)"
              (onChangeExtractPeerAction)="
                changeExtractPeerAction($event.value, $event.action)
              "
              (onLanguageChange)="changeLanguage($event)"
            ></app-platform-settings>
          </mat-menu>

          <div class="floating-msg capitalize-none" *ngIf="showfloatingMsg">
            <p>
              <span>{{ 'You can turn off' | translate }}</span>
              <b>{{
                'Always obtain accurate geolocation information when available'
                  | translate
              }}</b>
              <span>{{ 'from Platform settings.' | translate }}</span>
            </p>
          </div>
          <div class="bg-blur" *ngIf="showfloatingMsg"></div>
        </div>

        <div class="delimiter second"></div>
      </mat-toolbar>
    </div>
  </div>

  <div *ngIf="notificationsMenuState" class="containerFixed">
    <div class="wrapper notifications-wrapper" [class.scrollable]="false">
      <div class="title-container notifications-title">
        <div class="title">{{ 'Notifications' | translate }}</div>
        <intellectus-icon-text-button
          *ngIf="isMobile"
          class="close-icon"
          [buttonIcon]="closeIcon"
          (buttonClick)="openNotificationList(false)"
        ></intellectus-icon-text-button>
      </div>

      <app-notifications-content></app-notifications-content>
    </div>
  </div>
</nav>

<ng-template #changePasswordTemplate>
  <button mat-menu-item (click)="openChangePasswordDialog()">
    {{ 'Change Password' | translate }}
  </button>
</ng-template>
