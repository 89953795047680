import {
  BaseToasterIcon,
  FontAwesomeFamily,
} from '@fe-platform/shared-ui/intellectus';

export const DiscoveryToasterIcons: { [id: string]: BaseToasterIcon } = {
  ['locate']: {
    name: 'spinner-third',
    family: FontAwesomeFamily.REGULAR,
    iconRotate: true,
  },
  ['msisdn']: {
    name: 'phone',
    family: FontAwesomeFamily.SOLID,
    iconRotate: false,
  },
  ['imsi']: {
    name: 'sim-card',
    family: FontAwesomeFamily.REGULAR,
    iconRotate: false,
  },
};
