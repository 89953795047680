<svg [attr.width]="WIDTH" [attr.height]="HEIGHT" class="wordcloud">
  <g [attr.transform]="'translate(' + WIDTH / 2 + ',' + HEIGHT / 2 + ')'">
    <g
      [ngClass]="word.id"
      class="wc-group"
      [attr.fill-opacity]="word.opacity"
      [attr.transform]="'translate(' + word.x + ',' + word.y + ')'"
      *ngFor="let word of processedWordCloudItems; let i = index"
      (click)="onWordCloudClick($event, word)"
    >
      <text
        class="wc-text"
        #text
        text-anchor="middle"
        [attr.font-size]="word.size"
        [attr.fill]="word.textFill"
      >
        {{ word.text }}
      </text>
      <circle
        class="wc-circle"
        *ngIf="!simpleCloud"
        [attr.r]="word.getPointRadius()"
        [attr.cx]="word.calcPointXPosition(text)"
        [attr.cy]="word.calcPointYPosition(text)"
        [attr.fill]="colors[i]"
      ></circle>
    </g>
  </g>
</svg>
