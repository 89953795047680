import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AppConfigService } from '@app/config';
import { Observable, map, skipWhile, take } from 'rxjs';
import { TermsOfUseService } from './terms-of-use.service';

@Injectable({
  providedIn: 'root',
})
export class TermsOfUseGuard implements CanActivate {
  constructor(
    private readonly termsOfUseService: TermsOfUseService,
    private router: Router,
    private appConfigService: AppConfigService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.appConfigService.authenticatedConfigLoaded$.pipe(
      skipWhile((v) => !v),
      take(1),
      map(() => {
        if (
          this.appConfigService.getConfigVariable('enableTermsOfUse') &&
          !this.termsOfUseService.agreedToTermsOfUse
        ) {
          this.router.navigate(['terms-of-use'], {
            state: { data: { redirectUrl: state.url } },
          });

          return false;
        }

        return true;
      })
    );
  }
}
