import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Subject,
  filter,
  take,
  takeUntil,
  timer,
  merge,
  fromEvent,
  Subscription,
  interval,
  throwError,
  combineLatestWith,
} from 'rxjs';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { AuthService } from './auth.service';
import { formatTime, isLoginPage } from './utils';
import { catchError, scan, switchMap, throttleTime } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TranslationService } from '../translation/translation.service';
import { AuthState } from './auth-state.enum';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private idleAfterMinutesInactive: number;
  private onLogout$ = new Subject<void>();
  private idleTimer: Subscription;
  private expireAlertOpen = false;

  private readonly activityEvents: string[] = [
    'click',
    'mousewheel',
    'mousemove',
    'mousedown',
    'keydown',
    'keypress',
    'resize',
  ];
  private readonly lastActivityTimeKey = 'lastActivityTime';
  private readonly expireAlertDuration = 30;

  constructor(
    private router: Router,
    private authSessionService: AuthService,
    private appConfigService: AppConfigService,
    private translationService: TranslationService
  ) {
    this.authSessionService.authState$
      .pipe(combineLatestWith(this.appConfigService.authenticatedConfigLoaded$))
      .subscribe(([state, configLoaded]) => {
        if (state === AuthState.authenticated && configLoaded) {
          this.initialize();
        } else if (state !== AuthState.authenticated) {
          this.onLogout$.next();
        }
      });
  }

  private initialize() {
    this.idleAfterMinutesInactive = this.appConfigService.getConfigVariable(
      'sessionIdleAfterMinutesInactive'
    );
    this.startActivitySubscription();
    this.startRefreshTokenTimer();
    this.resetActivityTimeout();
  }

  public isValid() {
    const { count: errorCount } = this.authSessionService.errors;
    const valid = this.authSessionService.token.valid && !errorCount;
    if (!valid) {
      this.authSessionService.logout();
    }
    return valid;
  }

  private resetActivityTimeout(): void {
    localStorage.setItem(this.lastActivityTimeKey, Date.now().toString());
    this.startIdleTimer();
  }

  private startIdleTimer(): void {
    this.idleTimer?.unsubscribe();
    this.idleTimer = timer(this.getIdleTimeout() - Date.now())
      .pipe(takeUntil(this.onLogout$))
      .subscribe(() => this.onIdle());
  }

  private getIdleTimeout(): number {
    return (
      Number(localStorage.getItem(this.lastActivityTimeKey)) +
      this.idleAfterMinutesInactive * 60000
    );
  }

  private async onIdle() {
    if (isLoginPage(this.router.url)) {
      return;
    }
    if (!this.authSessionService.token.valid) {
      this.authSessionService.logout();
      return;
    }
    if (this.getIdleTimeout() > Date.now()) {
      this.startIdleTimer();
      return;
    }
    // Sync expireIn across multiple tabs by evaluating it based on last activity time
    const expireIn =
      Math.ceil((this.getIdleTimeout() - Date.now()) / 1000) +
      this.expireAlertDuration;
    await this.showSessionWillExpireAlert(expireIn);

    this.resetActivityTimeout();
  }

  private startActivitySubscription(): void {
    merge(...this.activityEvents.map((ev) => fromEvent(document, ev)))
      .pipe(
        takeUntil(this.onLogout$),
        throttleTime(30000),
        filter(() => !this.expireAlertOpen) // Do not reset activity when expire alert is open
      )
      .subscribe(() => {
        // Check if user is logged out in another tab
        if (!isLoginPage(this.router.url) && this.isValid()) {
          this.resetActivityTimeout();
        }
      });
  }

  private startRefreshTokenTimer(): void {
    // set a timeout to refresh the token a minute before it expires
    const timeoutOneMinuteBeforeInMilliseconds =
      (this.authSessionService.token.expiresInMinutes - 1) * 60000;

    timer(timeoutOneMinuteBeforeInMilliseconds)
      .pipe(
        takeUntil(this.onLogout$),
        switchMap(() => this.authSessionService.refresh()),
        catchError(() => {
          this.authSessionService.logout();
          return throwError(() => 'Logout due to refresh error');
        })
      )
      .subscribe(() => this.startRefreshTokenTimer());
  }

  private showSessionWillExpireAlert(expireIn: number) {
    expireIn = Math.min(this.expireAlertDuration, Math.max(0, expireIn));
    this.expireAlertOpen = true;
    const stopTimeout = new Subject<void>();
    return Swal.fire<boolean>({
      title: `${this.translationService.translate(
        'Session will expire in'
      )}&thinsp;<b>${formatTime(expireIn)}</b>`,
      text: this.translationService.translate(
        'Your session is about to expire click "Continue" to keep the session active.'
      ),
      confirmButtonText: this.translationService.translate('Continue'),
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      didOpen: () => {
        interval(1000)
          .pipe(
            scan((accumulator, _current) => accumulator - 1, expireIn + 1),
            take(expireIn + 1),
            takeUntil(stopTimeout)
          )
          .subscribe({
            next: (seconds: number) => {
              if (this.getIdleTimeout() > Date.now()) {
                // Idle timeout has been updated in another tab
                Swal.close();
                return;
              }
              if (seconds <= 0) {
                return this.showSessionExpiredAlert();
              }
              const header = Swal.getTitle();
              if (header !== null) {
                const counter = header.querySelector('b');
                if (counter !== null) {
                  counter.innerText = formatTime(seconds);
                }
              }
            },
          });
      },
      willClose: () => {
        this.expireAlertOpen = false;
        stopTimeout.next();
      },
    });
  }

  private showSessionExpiredAlert() {
    return Swal.fire({
      title: this.translationService.translate('Session has expired!'),
      text: this.translationService.translate(
        'You will be redirected to login screen.'
      ),
      icon: 'error',
      confirmButtonText: 'OK',
      timer: 6000,
      didClose: () => this.authSessionService.logout(true),
    });
  }
}
