import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslationService } from 'src/app/services/translation/translation.service';

interface UploadFileLoaderStore {
  [targetId: string]: {
    estimatedTimeUpload: string;
    isLoading: boolean;
    file: File;
  };
}

@Injectable({
  providedIn: 'root',
})
export class UploadFileDialogStore {
  constructor(private translationService: TranslationService) {}

  private loadersStateStore$: BehaviorSubject<UploadFileLoaderStore> =
    new BehaviorSubject({});

  public loaderAlreadyStarted(targetId: string): boolean {
    const storeValue = this.loadersStateStore$.getValue();
    return targetId in storeValue;
  }

  public addNewLoader(
    targetId: string,
    estimatedTimeUpload: string,
    file: File
  ) {
    const storeValue = this.loadersStateStore$.getValue();
    this.loadersStateStore$.next({
      ...storeValue,
      [targetId]: { estimatedTimeUpload, isLoading: true, file },
    });
  }

  public getTargetSpecificLoaderStateValue(targetId: string): {
    estimatedTimeUpload: string;
    isLoading: boolean;
    file: File;
  } {
    const storeValue = this.loadersStateStore$.getValue();
    return targetId in storeValue
      ? storeValue[targetId]
      : { estimatedTimeUpload: null, isLoading: false, file: null };
  }

  public removeLoaderStateByTargetId(targetId: string) {
    const storeValue = this.loadersStateStore$.getValue();
    if (targetId in storeValue) {
      storeValue[targetId];
      delete storeValue[targetId];
      this.loadersStateStore$.next(storeValue);
    }
  }

  public calculateUploadTime(fileSizeInBytes): string {
    const uploadSpeedInMbps = this.estimateUploadSpeedBasedOnNetworkType();
    // Convert upload speed from Mbps to bits per second
    const uploadSpeedInBitsPerSecond = uploadSpeedInMbps * 1000000;
    // Calculate upload time in seconds
    const uploadTimeInSeconds =
      (fileSizeInBytes * 8) / uploadSpeedInBitsPerSecond;
    // Convert upload time to minutes and seconds
    const minutes = Math.floor(uploadTimeInSeconds / 60);
    const seconds = Math.ceil(uploadTimeInSeconds % 60);

    if (minutes === 0) {
      return `${seconds} ${this.translationService.translate(
        seconds > 1 ? 'seconds' : 'second'
      )}`;
    } else {
      return `${minutes} ${this.translationService.translate(
        minutes > 1 ? 'minutes' : 'minute'
      )}`;
    }
  }

  public removeLoadingEstimationByTargetId(targetId: string): void {
    const storeValue = this.loadersStateStore$.getValue();
    if (targetId in storeValue) {
      storeValue[targetId].estimatedTimeUpload = null;
      this.loadersStateStore$.next(storeValue);
    }
  }

  private estimateUploadSpeedBasedOnNetworkType() {
    const connection = navigator['connection'];
    if (connection) {
      const effectiveType = connection['effectiveType'];
      switch (effectiveType) {
        case 'slow-2g':
          return 0.1; // Mbps
        case '2g':
          return 0.2; // Mbps
        case '3g':
          return 1; // Mbps
        case '4g':
          return 10; // Mbps
        case '5g':
          return 100; // Mbps
        default:
          return 5; // Mbps (a reasonable default)
      }
    }
    return 5;
  }
}
