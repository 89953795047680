export enum MarkerCollectionOptions {
  LASTSEEN = 'lastSeen',
  PLACESOFINTEREST = 'placesOfInterest',
  TARGETLOCATIONS = 'targetLocations',
  FILTEREDTIMELINE = 'filteredTimeline',
  CALLLOGS = 'callLogs',
  ADIDS = 'adIds',
  GEOLOCATIONPROBABILITIES = 'geolocationProbabilities',
  CDRPROBABILITIES = 'cdrProbabilities',
  TIMELINECDRPROBABILITIES = 'timelinecdrProbabilities',
  ALL = 'all',
  NEARBYPROFILELOCATIONS = 'nearbyProfileLocations',
  FEED = 'feed',
  CHECKINS = 'checkins',
}
