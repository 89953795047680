import { Injectable } from '@angular/core';
import { skipWhile, take, tap } from 'rxjs/operators';
import { AppConfigService } from '../providers/app-config.service';
import { environment } from 'environment/environment';
import { appendExternalScript } from '@shared/util/append-external-script';
import { firstValueFrom } from 'rxjs';

export function initApp(appInitService: AppInitService) {
  return (): Promise<boolean> => appInitService.init();
}

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(private appConfigService: AppConfigService) {}

  public init() {
    this.appConfigService.fetchAnonConfig();
    const source$ = this.appConfigService.anonConfigLoaded$.pipe(
      skipWhile((loaded) => !loaded),
      take(1),
      tap(() => this.installHotjar())
    );

    return firstValueFrom(source$);
  }

  private installHotjar() {
    const hotjarKey = this.appConfigService.getConfigVariable('hotjarKey');
    try {
      if (hotjarKey?.length) {
        appendExternalScript('assets/scripts/hotjar.js', [
          { name: 'hotjarKey', value: hotjarKey },
        ]);
      }
    } catch (e) {
      if (!environment.production) {
        console.warn('failed to configure hotjar');
        console.error(e);
      }
    }
  }
}
