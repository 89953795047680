import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FontAwesomeIcon } from '../../icons/fa-icon.interface';
import { FontAwesomeFamily } from '../../icons/fa-icon-family.enum';

@Component({
  selector: 'intellectus-accordion-header',
  templateUrl: './accordion-header.component.html',
  styleUrls: ['./accordion-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionHeaderComponent {
  @Input()
  public state = false;

  @Input()
  public withIcon = true;

  @Output()
  public readonly stateToggled: EventEmitter<void> = new EventEmitter<void>();

  public readonly icon: FontAwesomeIcon = {
    name: 'angle-down',
    family: FontAwesomeFamily.SOLID,
  };
}
