<div mat-dialog-title class="law-enforcement-title">
  {{ 'Whitelist Numbers' | translate }}
</div>
<div mat-dialog-content class="law-enforcement-dialog">
  <form [formGroup]="lawEnforcmentDialogForm">
    <mat-form-field class="msisdns">
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let msisdn of msisdns"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(msisdn)"
        >
          {{ msisdn }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          #msisdnsInput
          [placeholder]="'MSISDN' | translate"
          [formControl]="msisdnsCtrl"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-list>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="law-enforcement-actions">
  <button
    mat-raised-button
    class="cancel law-btn"
    (click)="onClose()"
    type="button"
  >
    {{ 'Cancel' | translate }}
  </button>
  <button
    mat-raised-button
    class="ok law-btn"
    color="accent"
    type="submit"
    [disabled]="!msisdns.length"
    (click)="onSubmit()"
  >
    {{ 'Submit' | translate }}
  </button>
</div>
