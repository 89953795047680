import { BaseApiService } from 'datalayer/services/base';
import { DataLayerModule } from 'datalayer/data-layer.module';
import { Post } from 'datalayer/models';
import { PostDTO } from 'src/app/modules/data-layer/services/social/post/post-dto';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: DataLayerModule,
})
export class PostApiService extends BaseApiService<Post, PostDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient, PostDTO);
  }

  public getModelName(): string {
    return Post.name;
  }
}
