import { ColorMarker } from '../../models/map.models';
import { IMapService } from 'src/app/modules/mapV2/models/map-service.interface';
import { Injectable, Inject, ApplicationRef } from '@angular/core';
import {
  IconMarker,
  Marker,
  RadiusMarker,
  TemplateMarker,
} from 'src/app/modules/mapV2/models/map.models';
import { OverlayFactory } from './overlay.factory';
import { AppMarkerProvider } from './app-marker-provider.interface';
import { GoogleRadiusMarker } from './google-radius-marker';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { GoogleMarker } from './google-marker';

@Injectable({
  providedIn: 'root',
})
export class MarkerFactory {
  map: google.maps.Map;
  overlayFactory: OverlayFactory;
  currentMarkerClick: google.maps.Marker;
  constructor(
    @Inject('mapService') private mapService: IMapService,
    private appRef: ApplicationRef,
    private translationService: TranslationService
  ) {
    this.overlayFactory = new OverlayFactory(
      this.translationService,
      this.appRef
    );
  }

  static isInfoWindowOpen(infoWindow: google.maps.InfoWindow | any) {
    const map = infoWindow.getMap();
    return map !== null && typeof map !== 'undefined';
  }

  setMap(map: google.maps.Map) {
    this.map = map;
  }

  create(marker: Marker): google.maps.Marker {
    if (marker instanceof RadiusMarker) {
      return this.createRadiusMarker(marker);
    } else if (marker instanceof IconMarker) {
      return this.createIconMarker(marker);
    } else if (marker instanceof ColorMarker) {
      return this.createColorMarker(marker);
    } else if (marker instanceof TemplateMarker) {
      return this.createTemplateMarker(marker) as any;
    } else if (marker instanceof Marker) {
      return this.createBasicMarker(marker);
    }
  }

  update(appMarker: GoogleMarker): void {
    if (!(appMarker instanceof IconMarker)) {
      return;
    }
    if (!this.markerSizeHasChanged(appMarker)) {
      return;
    }
    this.updateMarkerIcon(appMarker);

    const gMarkerRef: any = appMarker.nativeMarkerObject;
    if (gMarkerRef.infoWindow && appMarker.getPopupEmbeddedView) {
      this.updateMarkerPopup(appMarker, gMarkerRef);
    }
    appMarker.nativeMarkerObject.setZIndex(appMarker.zIndex || 1);
  }

  private markerSizeHasChanged(marker: GoogleMarker & IconMarker): boolean {
    const markerNativeIconSize = (
      marker.nativeMarkerObject?.getIcon() as google.maps.Icon
    )?.size;
    if (!markerNativeIconSize) {
      return false;
    }
    const markerNativeIconUrl = (
      marker.nativeMarkerObject?.getIcon() as google.maps.Icon
    )?.url;

    const sizeChanged =
      markerNativeIconSize?.width !== marker.iconWidth ||
      markerNativeIconSize?.height !== marker.iconHeight;
    const urlChanged = markerNativeIconUrl !== marker.iconUrl;

    return sizeChanged || urlChanged;
  }

  private updateMarkerIcon(appMarker: GoogleMarker & IconMarker) {
    if (!appMarker.iconWidth || !appMarker.iconHeight) {
      console.warn('Marker icon missing width pr height', appMarker);
      return;
    }
    const iconSize = new google.maps.Size(
      appMarker.iconWidth,
      appMarker.iconHeight,
      'px',
      'px'
    );
    appMarker.nativeMarkerObject.setIcon({
      url: appMarker.iconUrl,
      size: iconSize,
      scaledSize: iconSize,
      anchor: new google.maps.Point(
        appMarker.iconWidth / 2,
        appMarker.iconHeight / 2
      ),
    });
  }

  private updateMarkerPopup(
    appMarker: GoogleMarker & IconMarker,
    gMarkerRef: any
  ) {
    const embeddedViewRef = appMarker.getPopupEmbeddedView();
    appMarker.popupHTML = embeddedViewRef.rootNodes[0];
    gMarkerRef.infoWindow.setContent(appMarker.popupHTML);
    this.appRef.attachView(embeddedViewRef);
  }

  createBasicMarker(marker: Marker): google.maps.Marker {
    const gMarker = new google.maps.Marker({
      position: new google.maps.LatLng(marker.lat, marker.lng),
      zIndex: marker.zIndex || 1,
      visible: false,
    });

    (gMarker as unknown as AppMarkerProvider).appMarker = marker;

    if (marker.popupHTML || marker.getPopupEmbeddedView) {
      this.addInfoWindow(marker, gMarker);
    }

    return gMarker;
  }

  createColorMarker(marker: ColorMarker): google.maps.Marker {
    const gMarker = this.createBasicMarker(marker);
    const icon = `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="35px" height="41px" viewBox="0 0 23 29" version="1.1" xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icons_pin" transform="translate(-1444.000000, -548.000000)" fill="${
              marker.color || '#3F3A34'
            }">
                <path d="M1455.20569,565.42681 C1451.46636,565.401993 1448.45469,562.350439 1448.47862,558.611111
                C1448.50344,554.870897 1451.55499,551.859227 1455.29432,551.883157 C1459.03453,551.907974 1462.0462,554.959528
                1462.02227,558.699742 C1461.99746,562.43907 1458.9459,565.45074 1455.20569,565.42681 Z M1444,559.17303
                C1443.95934,565.384267 1455.13478,576.264569 1455.13478,576.264569 C1455.13478,576.264569 1466.45203,565.531394
                1466.4928,559.320157 C1466.53357,553.108919 1461.53126,548.041018 1455.32002,548 C1449.10878,547.959477
                1444.04088,552.961792 1444,559.17303 Z"></path>
            </g>
        </g>
    </svg>
    `;

    gMarker.setIcon({
      url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(icon),
    });

    return gMarker;
  }

  createIconMarker(marker: IconMarker): google.maps.Marker {
    const gMarker = this.createBasicMarker(marker);

    if (!marker.iconUrl) {
      marker.iconUrl = this.mapService.getDefaultMarkerIcon();
    }

    return gMarker;
  }

  createRadiusMarker(marker: GoogleRadiusMarker): google.maps.Marker {
    const gMarker = this.createIconMarker(marker);
    const radiusWidget = this.overlayFactory.createRadiusWidget({
      radius: marker.radius,
    });

    google.maps.event.addListener(radiusWidget, 'distance_changed', () => {
      if (marker.radiusChanged) {
        marker.radiusChanged(
          marker,
          gMarker,
          radiusWidget.get('distance').toFixed(3)
        );
      }
    });

    if (marker.alwaysShowRadius) {
      radiusWidget.bindTo('map', gMarker);
    } else {
      // show radius only on click event
      gMarker.addListener('click', () => {
        if (!radiusWidget.get('showCircle')) {
          radiusWidget.set('map', gMarker.getMap());
          radiusWidget.set('showCircle', true);
        } else {
          radiusWidget.set('map', null);
          radiusWidget.set('showCircle', false);
        }
      });
    }

    // Bind the radiusWidget center to the DistanceWidget position
    radiusWidget.bindTo('center', gMarker, 'position');

    // Bind to the radiusWidgets' distance property
    gMarker.bindTo('distance', radiusWidget);

    // Bind to the radiusWidgets' bounds property
    gMarker.bindTo('bounds', radiusWidget);

    return gMarker;
  }

  createTemplateMarker(marker: TemplateMarker): google.maps.OverlayView {
    if (marker.getEmbeddedView) {
      const embeddedViewRef = marker.getEmbeddedView();
      marker.elementRef = embeddedViewRef.rootNodes[0];
      this.appRef.attachView(embeddedViewRef);
    } else if (marker.componentRef) {
      marker.elementRef = marker.componentRef.location.nativeElement;
    }

    const gMarker = this.overlayFactory.createHtmlMarker({
      position: new google.maps.LatLng(marker.lat, marker.lng),
      template: marker.elementRef,
      getPopupEmbeddedView: marker.getPopupEmbeddedView,
      isPopupWindowOpen: marker.isPopupWindowOpen,
      anchorPosition: marker.anchorPosition,
    });

    return gMarker;
  }

  private addInfoWindow(marker: Marker, gMarker: google.maps.Marker) {
    (gMarker as any).initInfoWindow = () => {
      const gMarkerRef: any = gMarker;

      if (!gMarkerRef.infoWindow) {
        gMarkerRef.infoWindow = new google.maps.InfoWindow({
          content: marker.popupHTML || '',
        });
        gMarkerRef.set('infoWindow', gMarkerRef.infoWindow);

        if (marker.getPopupEmbeddedView && !marker.popupHTML) {
          const embeddedViewRef = marker.getPopupEmbeddedView();
          embeddedViewRef.markForCheck();
          marker.popupHTML = embeddedViewRef.rootNodes[0];
          gMarkerRef.infoWindow.setContent(marker.popupHTML);
          this.appRef.attachView(embeddedViewRef);
        }
      }
    };

    gMarker.addListener('click', () => {
      const gMarkerRef: any = gMarker;

      gMarkerRef.initInfoWindow();

      if (marker.click) {
        marker.click(marker, gMarker);
      }

      if (this.currentMarkerClick) {
        this.currentMarkerClick['infoWindow'].close();
        this.currentMarkerClick = null;
      }

      gMarkerRef.infoWindow.open(this.map, gMarker);
      this.currentMarkerClick = gMarkerRef;
    });

    (gMarker as any).openInfoWindow = (show: boolean) => {
      const gMarkerRef: any = gMarker;

      gMarkerRef.initInfoWindow();

      if (show) {
        gMarkerRef.infoWindow.open(gMarkerRef.map, gMarker);
      } else {
        gMarkerRef.infoWindow.close();
      }
    };
  }
}
