import { Observable } from 'rxjs';
import { FontAwesomeIcon } from '../icons/fa-icon.interface';

export enum DrawerState {
  MINIMIZED = 1,
  DEFAULT = 2,
}

export interface DrawerOptions {
  title: Observable<string>;
  onClose: () => void;
  onExpand?: () => void;
  onStateToggled?: (state: DrawerState) => void;
  width: string;
  positionClass?: string;
  headerIcon?: FontAwesomeIcon;
  badge?: Observable<string | number>;
  expandIcon?: FontAwesomeIcon;
  showLoadingOnMinimized?: boolean;
  state?: DrawerState;
}
