<mat-toolbar class="mobile-navbar mat-elevation-z3" [ngClass]="highlightClass">
  <intellectus-icon-text-button
    [buttonIcon]="showBackButtonOnMobile ? backCloseIcon : hamburgerIcon"
    (buttonClick)="showBackButtonOnMobile ? goBack() : onMenuClick.emit()"
  ></intellectus-icon-text-button>

  <div class="title-container">
    <ng-container *dynamicElementRef="'navtitle'">
      <div class="title">{{ title | translate }}</div>
    </ng-container>

    <intellectus-icon-text-button
      *ngIf="clickableTitle"
      [buttonIcon]="collapseIcon"
    ></intellectus-icon-text-button>
  </div>

  <div
    class="d-flex align-items-center"
    *ngrxLet="activeSearchCount$ as activeSearchCount"
  >
    <div
      class="intel-search-button-container"
      *ngIf="(allSearchCount$ | async) > 0"
      (click)="onSearchIconClick.emit()"
    >
      <div
        class="progress-bar d-flex align-items-center"
        [class.mobile-device]="isMobileDevice"
        *ngIf="(allSearchCount$ | async) > 0"
      >
        <ngx-gauge
          [type]="'full'"
          [value]="activeSearchCount > 0 ? 20 : 0"
          [thick]="3"
          [size]="28"
          [cap]="'round'"
          [foregroundColor]="activeSearchCount > 0 ? '#3365FF' : '#FFFFFF'"
          [backgroundColor]="'#FFFFFF'"
          [class.spin]="activeSearchCount > 0"
        >
          <ngx-gauge-value class="progress-value">
            <ng-container>{{ allSearchCount$ | async }}</ng-container>
          </ngx-gauge-value>
        </ngx-gauge>
      </div>
    </div>

    <intellectus-icon-text-button
      *ngIf="showKnowledgeBase"
      [buttonIcon]="knowledgeBaseIcon"
      (buttonClick)="knowledgeBaseClicked()"
      class="mr-5"
    ></intellectus-icon-text-button>

    <intellectus-icon-text-button
      [buttonIcon]="notificationIcon"
      (buttonClick)="notificationClicked()"
      [badge]="notificationsCount"
    ></intellectus-icon-text-button>
  </div>
</mat-toolbar>
