<ng-container *ngIf="skin === 'GEO4' || skin === 'CVTP'">
  <mat-icon
    angulartics2On="click"
    [angularticsAction]="matomo.actions.viewNotifications"
    [angularticsCategory]="matomo.categories.navBar"
    [matMenuTriggerFor]="notificationsMenu"
    class="clickable bell-icon"
    [class.mobile-geo-icon]="isMobile"
    [matBadge]="notificationsCount || ''"
    matBadgeColor="warn"
    >notifications</mat-icon
  >
  <mat-menu
    #notificationsMenu="matMenu"
    class="notifications-menu container-width"
    [class.geoloc-theme]="isGeolocTheme"
    [class.white-theme]="isWhiteTheme"
  >
    <div>
      <div class="row notification-header">
        <p>{{ 'Notifications' | translate }}</p>
      </div>
    </div>

    <app-notifications-content></app-notifications-content>
  </mat-menu>
</ng-container>
