import { Component, OnInit } from '@angular/core';
import { QueryService } from 'src/app/services/query/query.service';
import { BaseComponent } from 'src/app/base/base.component';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { TranslationService } from 'src/app/services/translation/translation.service';

@Component({
  selector: 'app-frequency',
  templateUrl: './frequency.component.html',
  styleUrls: ['./frequency.component.scss'],
})
export class FrequencyComponent extends BaseComponent implements OnInit {
  frequencies = [
    // {value: 1, label: '1 min'},
    { value: 15, label: '15 min', disabled: false },
    { value: 30, label: '30 min', disabled: false },
    {
      value: 60,
      label: `1 ${this.translationService.translate('hour')} `,
      disabled: false,
    },
    { value: 120, label: '2 hrs', disabled: false },
    { value: 180, label: '3 hrs', disabled: false },
    { value: 240, label: '4 hrs', disabled: false },
    { value: 300, label: '5 hrs', disabled: false },
    { value: 360, label: '6 hrs', disabled: false },
    { value: 720, label: '12 hrs', disabled: false },
    { value: 1440, label: '24 hrs', disabled: false },
  ];
  testingFrequencies = [
    { value: 2.5, label: '2.5 min', disabled: false },
    { value: 4, label: '4 min', disabled: false },
    { value: 6, label: '6 min', disabled: false },
  ];
  selected = '';
  skin;

  constructor(
    private queryService: QueryService,
    private applicationStateService: ApplicationStateService,
    private appConfigService: AppConfigService,
    private translationService: TranslationService
  ) {
    super();
  }

  ngOnInit() {
    if (this.appConfigService.getConfigVariable('enableTestingFrequencies')) {
      this.frequencies = this.testingFrequencies.concat(this.frequencies);
    }
    const clearFrequencySubscription =
      this.queryService.clearFrequency.subscribe((flag) => {
        if (flag) {
          this.clearFrequency();
        }
      });

    const skinSubscription = this.applicationStateService.skin.subscribe(
      (skin: string) => {
        this.skin = skin;
      }
    );

    if (this.appConfigService.getConfigVariable('forceLowFrequencySchedules')) {
      this.subscriptions.push(
        this.queryService.dateRange.subscribe((dates: Date[]) => {
          if (dates.length) {
            this.formatFrequenciesBasedOnQueriesPerSchedulerLimit(dates);
          }
        })
      );
    }

    this.subscriptions.push(clearFrequencySubscription, skinSubscription);
  }

  selectedFrequency(event) {
    if (event.isUserInput) {
      this.selected = event.source.value;
      this.queryService.frequency.next(event.source.value);
      this.queryService.clearFrequency.next(false);
    }
  }

  clearFrequency() {
    this.selected = null;
  }

  private formatFrequenciesBasedOnQueriesPerSchedulerLimit(dates: Date[]) {
    this.clearFrequency();
    this.queryService.frequency.next(null);
    const limit = this.appConfigService.getConfigVariable('themeQueriesLimit');
    const from = dates[0];
    const to = dates[1];
    const diff = to.getTime() - from.getTime();
    const hours = diff / (1000 * 3600);
    this.frequencies.forEach((option) => {
      option.disabled = option.value / 60 < hours / limit;
    });

    if (!this.frequencies.some((option) => !option.disabled)) {
      this.showMessage(
        `${this.translationService.translate(
          'Maximum amount of robot queries is'
        )} ${limit}.
        ${this.translationService.translate(
          'Please choose another daterange.'
        )}`
      );
    }
  }
}
