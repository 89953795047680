import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validator',
})
export class ValidatorPipe implements PipeTransform {
  transform(
    value: string,
    validators: (((item: string) => boolean) | RegExp)[] | undefined
  ): boolean {
    if (!validators) {
      return true;
    }
    return validators?.every((validator) => {
      if (typeof validator === 'function') {
        return validator(value);
      }
      return new RegExp(validator).test(value);
    });
  }
}
