import { Component, Input } from '@angular/core';
import { DiscoveryVersionService } from 'src/app/services/discovery/discovery-version.service';

@Component({
  selector: 'new-discovery-toggle',
  templateUrl: './new-discovery-toggle.component.html',
  styleUrls: ['./new-discovery-toggle.component.scss'],
})
export class NewDiscoveryToggleComponent {
  @Input() displayLabel = true;
  discoveryV2Enabled$ = this.discoveryVersionService.discoveryV2Enabled$;

  constructor(public discoveryVersionService: DiscoveryVersionService) {}
}
