import { AvatarRemoteViewStatus } from './avatar-remote-view-status.enum';

export class AvatarRemoteView {
  status: AvatarRemoteViewStatus;
  url: string;
  error?: { message: string[] };
  ttl?: number;
  constructor(model?: Partial<AvatarRemoteView>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}
