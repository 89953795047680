<app-card [showFooter]="showFooter">
  <div class="header" header>
    <button class="close-btn" (click)="close()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
    <ng-content select="[header]"></ng-content>
  </div>

  <div body>
    <ng-content select="[body]"></ng-content>
  </div>

  <div footer>
    <ng-content select="[footer]"></ng-content>
  </div>
</app-card>
