import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  QueryList,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarItemComponent } from './nav-bar-item.component';

/**
 * The following component allows you to render a navigation bar 
 * based on the intellectus layout/stylesheet
 * Here is an example use of it:
 * <intellectus-nav-bar [style.--nav-bar-height]="'50px'">
      <a intellectus-nav-bar-item [active]="true">
        <span>{{ 'History' | translate | uppercase }}</span>
      </a>
      <a intellectus-nav-bar-item [active]="false" [disabled]="true">
        <span>{{ 'TRACKERS' | translate | uppercase }}</span>
      </a>
      <a intellectus-nav-bar-item disabled [split]="true" [active]="false">
        <span>{{ 'TRACKERS' | translate | uppercase }}</span>
      </a>
  </intellectus-nav-bar>
 */
@Component({
  selector: 'intellectus-nav-bar',
  standalone: true,
  imports: [CommonModule, NavBarItemComponent],
  template: `
    <nav class="nav-bar">
      <div #navItemsContainer class="nav-bar__items">
        <ng-content></ng-content>
      </div>
    </nav>
  `,
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements AfterContentInit {
  @ContentChildren(forwardRef(() => NavBarItemComponent), { descendants: true })
  _items!: QueryList<NavBarItemComponent>;
  @ViewChild('navItemsContainer') itemContainer!: ElementRef;

  ngAfterContentInit(): void {
    this._items.forEach((item, index) => (item._index = index));
  }
}
