import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/services/translation/translation.service';

@Pipe({ name: 'querytypeui' })
export class QueryTypeUiPipe implements PipeTransform {
  constructor(public translationService: TranslationService) {}

  transform(query_type: string) {
    switch (query_type) {
      case 'emergency':
        return this.translationService.translate('Emergency');
      case 'scheduled':
        return this.translationService.translate('Scheduler');
      case 'fenced_area':
        return this.translationService.translate('Geo AoI');
      case 'fenced_quarantine':
        return this.translationService.translate('Geo Follow');
      case 'logon':
        return this.translationService.translate('Log on');
      case 'fenced_country':
        return this.translationService.translate('Geo Country');
      case 'proximity':
        return this.translationService.translate('Proximity');
      case 'imported':
        return this.translationService.translate('Imported');
      case 'tactical':
        return this.translationService.translate('Tactical');
      case 'radical-monitoring':
        return this.translationService.translate('Radical monitoring');
    }
  }
}
