import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { CallLogsListResponseModel } from '../../analysis/shared/models/targets-list-request.model';
import { ApplicationMainPageUrls } from '@shared/models/application-main-page-urls.enum';
import { head } from 'lodash-es';
import { ProfilerDashboardSectionsRoutes } from '../../profiler/shared/models/profiler-dashboard-sections.model';
import { TargetRenewalService } from 'src/app/services/target/target-renewal.service';
import { ProfilerService } from '../../profiler/services/profiler.service';

@Injectable({
  providedIn: 'root',
})
export class CallLogRequestsService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private targetRenewalService: TargetRenewalService,
    private profilerService: ProfilerService
  ) {
    super(router, snackBar);
  }

  getCallLogRequestsByTarget(targetId: string): Observable<any> {
    return this.httpClient
      .get<any>(`${this.proxyApiUrl}/call-log-request/${targetId}`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   *
   * @param targetId string
   * @param msisdn string
   */
  newCallRequest(targetId: string, msisdn: string): Observable<any> {
    return this.httpClient
      .put<any>(`${this.proxyApiUrl}/call-log-request`, { targetId, msisdn })
      .pipe(catchError((error) => this.handleError(error)));
  }

  sendCallLogRequestToSupport(emailData: any, msisdn: string) {
    return this.httpClient
      .post<any>(`${this.url}/call_log_request/email/${msisdn}`, emailData)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  navigateToClOverview(clRequest: CallLogsListResponseModel): void {
    if (!clRequest) {
      return;
    }
    if (
      !clRequest.msisdns?.length &&
      !clRequest.imsis?.length &&
      !clRequest.imeis?.length
    ) {
      return;
    }
    this.router.navigate([
      `/${ApplicationMainPageUrls.CORE}/call-log-analysis`,
      clRequest.id,
    ]);
  }
}
