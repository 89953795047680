import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'warn',
})
export class WarnPipe implements PipeTransform {
  transform(value: any, type?: string): null {
    switch (type) {
      case 'log':
        console.log(value);
        break;
      case 'warn':
        console.warn(value);
        break;
      default:
        console.info(value);
        break;
    }
    console.warn(value);
    return null;
  }
}
