import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Chat } from 'datalayer/models/chat';

@Component({
  selector: 'app-chat-summary-details',
  templateUrl: './chat-summary-details.component.html',
  styleUrls: ['./chat-summary-details.component.css'],
})
export class ChatSummaryDetailsComponent implements OnInit {
  @Input() relatedPersonChat: Chat;
  @Input() hideBackButton = false;
  @Output() goBackEvent = new EventEmitter();
  dateRangeFrom: Date = null;
  dateRangeTo: Date = null;
  keywords: string[] = [];

  ngOnInit(): void {
    this.keywords = this.relatedPersonChat?.keywords?.slice(0, 25);
    this.retrieveDateRanges();
  }

  retrieveDateRanges(): void {
    if (this.relatedPersonChat?.messages) {
      this.dateRangeFrom = new Date(this.relatedPersonChat.messages[0].date);
      this.dateRangeTo = new Date(
        this.relatedPersonChat.messages[
          this.relatedPersonChat.messages.length - 1
        ].date
      );
    }
  }

  goBack() {
    this.goBackEvent.emit();
  }
}
