<mat-chip-list
  class="multi-select-chip"
  selectable
  multiple
  [disabled]="disabled"
>
  <mat-chip
    #chipValue="matChip"
    *ngFor="let option of options"
    [class.active-class]="option?.selected"
    [hidden]="option?.active === filterStatuses.INACTIVE && option.id !== 'All'"
    (selectionChange)="onSelectionChange($event)"
    (click)="toggleSelection(chipValue)"
    [value]="option.id"
    [disabled]="disabled"
  >
    <span class="txt">
      {{ option.value }}
    </span>
  </mat-chip>
</mat-chip-list>
